import i18next from "@app/i18n";

const enResources = {
    insert_objectives: "Insert objectives",
    insert_objectives_modal_title: "Insert objectives in the document",
    insert_objectives_modal_subtitle:
        "Insert commitments and targets for each material subtheme.",
    objectives_progression_insufficient:
        "This subtheme has no objective attached. You must write 2-3 objectives per subtheme to create a solid policy.",
    objectives_progression_weak:
        "This subtheme has only one objective attached. It is recommended to write 2-3 objectives per subtheme for a solid policy.",
    objectives_progression_good:
        "This subtheme has two objectives attached. Adding one more objective would make your policy more comprehensive.",
    objectives_progression_excellent:
        "Great job! This subtheme has {{count}} objectives attached. This meets the ideal standard for an outstanding policy.",
    objectives_display_button: "{{count}} defined",
    cta: "Insert {{selected}} / {{total}} subthemes",
    objectives_insert_title: "Objectives on the theme of : {{ subthemeName }}",
    objectives_insert_objective_header_title: "Objective",
    objectives_insert_description_header_title: "Description",
    objectives_insert_indicator_header_title: "Indicator",
    objectives_insert_targets_header_title: "Targets",
    objectives: "Objectives",
    objectives_insert_tooltip: "Insert objectives in current document",
    no_objectives: "No defined objective for this theme",
    objectives_insert_example_description:
        "Example: As a major player in raw material manufacturing, [Company Name] is committed to environmental preservation through responsible energy management. We pledge, in line with the principles of the United Nations Global Compact, to continually assess performance and implement strategies for continuous improvement.",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    insert_objectives: "Insérer des objectifs",
    insert_objectives_modal_title: "Insérer des objectifs dans le document",
    insert_objectives_modal_subtitle:
        "Insérer des engagements et des cibles pour chaque sous-thème matériel.",
    objectives_progression_insufficient:
        "Ce sous-thème n'a aucun objectif associé. Vous devez rédiger 2 à 3 objectifs par sous-thème pour créer une politique solide.",
    objectives_progression_weak:
        "Ce sous-thème n'a qu'un seul objectif associé. Il est recommandé de rédiger 2 à 3 objectifs par sous-thème pour une politique solide.",
    objectives_progression_good:
        "Ce sous-thème a deux objectifs associés. Ajouter un objectif supplémentaire rendrait votre politique plus complète.",
    objectives_progression_excellent:
        "Bravo ! Ce sous-thème comporte {{count}} objectifs associés. Cela correspond au standard idéal pour une excellente politique.",
    objectives_display_button: "{{count}} définis",
    cta: "Insérer {{selected}} / {{total}} sous-thèmes",
    objectives_insert_title: "Objectifs sur le thème de : {{ subthemeName }}",
    objectives_insert_objective_header_title: "Objectif",
    objectives_insert_description_header_title: "Description",
    objectives_insert_indicator_header_title: "Indicateur",
    objectives_insert_targets_header_title: "Chiffrage",
    objectives: "Objectifs",
    objectives_insert_tooltip: "Insérer les objectifs dans le document",
    no_objectives: "Aucun objectif défini pour ce thème",
    objectives_insert_example_description:
        "Exemple : En tant qu'acteur majeur du secteur des matières premières, [Nom de l'entreprise] s'engage à préserver l'environnement grâce à une gestion énergétique responsable. Nous nous engageons, en adhérant aux principes du Pacte mondial des Nations Unies, à évaluer régulièrement les performances et à mettre en œuvre des stratégies pour une amélioration continue.",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "DocumentEditor";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
