import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { FlexCol } from "@design-system/Layout/Flex";
import { GetObjectiveQuery, ResponseStatus } from "@generated/client/graphql";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableText } from "../EditableText";
import { RESOURCE_NAME, keys } from "./i18n";

type ObjectiveDescriptionProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveDescription: FC<ObjectiveDescriptionProps> = ({
    objective,
}) => {
    const [description, setDescription] = useState<string | undefined>(
        objective?.description || undefined,
    );
    const { mutate } = useUpdateObjective(objective?.id, objective?.entityId);
    const { setToastSuccess, setToastError } = useSetToast();
    const handleDescriptionSave = () => {
        if (description !== objective.description) {
            const newDescription = description || null;
            mutate(
                { id: objective.id, description: newDescription },
                {
                    onSettled: (data) => {
                        if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Error
                        ) {
                            setToastError(
                                `${data.updateObjective.error?.message}`,
                            );
                        } else if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Success
                        ) {
                            setToastSuccess(t(keys.update_success));
                        }
                    },
                    onError: () => {
                        setToastError(t(keys.update_error));
                    },
                },
            );
        }
    };

    const onInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value || undefined);
    };
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol w="full">
            <EditableText
                value={description}
                onInput={onInput}
                onBlur={handleDescriptionSave}
                placeholder={t(keys.description_placeholder)}
                variant="body"
                className="min-h-40"
            />
        </FlexCol>
    );
};
