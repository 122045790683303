import { RadioGroup } from "@design-system/Inputs/RadioGroup";

import { type FC } from "react";

export const SingleSelectSection: FC<{
    options: string[];
    value?: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}> = ({
    options,
    value: selectedOption,

    onChange,
    disabled = false,
}) => {
    return (
        <RadioGroup value={selectedOption} onChange={onChange}>
            {options.map((option, index) => (
                <RadioGroup.Field
                    key={index}
                    w="full"
                    gap="2"
                    alignItems="center"
                    disabled={disabled}
                >
                    <RadioGroup.Radio value={index} />
                    <RadioGroup.Label>{option}</RadioGroup.Label>
                </RadioGroup.Field>
            ))}
        </RadioGroup>
    );
};
