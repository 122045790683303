import { GraphQLUserRepository } from "@app/repositories/GraphQLRepositories/UserRepository";
import { useQuery } from "@tanstack/react-query";

const userRepository = new GraphQLUserRepository();

export const useGetIntercomToken = () => {
    return useQuery({
        queryKey: ["getUserIntercom"],
        queryFn: () => userRepository.getUserIntercom(),
    });
};
