import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "questionnaires-dashboard";

const keys = addTranslations(RESOURCE_NAME, {
    page_title: {
        en: "Fill out your questionnaires faster and easier",
        fr: "Remplissez vos questionnaires plus rapidement et plus facilement",
    },
    page_subtitle: {
        en: "Save valuable time on questionnaires with smart tools that leverage your existing content for faster responses",
        fr: "Gagnez du temps sur les questionnaires avec des outils intelligents qui exploitent votre contenu existant pour des réponses plus rapides",
    },
    quick_answer_card_title: {
        en: "Get a quick answer",
        fr: "Obtenir une réponse rapide",
    },
    quick_answer_card_description: {
        en: "Copy-paste any question from your questionnaire, and let our AI generate a reliable response in one click.",
        fr: "Copiez-collez n'importe quelle question de votre questionnaire, et laissez notre IA générer une réponse fiable en un clic.",
    },
    upload_file_card_title: {
        en: "Upload a file",
        fr: "Téléverser un fichier",
    },
    upload_file_card_description: {
        en: "Upload your file, and our AI will automatically extract and integrate your questionnaire into the platform.",
        fr: "Téléversez votre fichier, et notre IA extraira et intégrera votre questionnaire dans la plateforme.",
    },
    upload_file_card_button_label: {
        en: "Upload file",
        fr: "Téléverser un fichier",
    },
    upload_file_modal_title: {
        en: "Create a questionnaire from an uploaded file",
        fr: "Créer un questionnaire à partir d'un fichier téléchargé",
    },
    upload_file_modal_button_label: {
        en: "Browse",
        fr: "Parcourir",
    },
    upload_file_modal_placeholder: {
        en: "Upload your questionnaire",
        fr: "Téléversez votre questionnaire",
    },
    upload_file_modal_accepted_file_types: {
        en: "We accept pdf, excel and docx files",
        fr: "Nous acceptons les fichiers pdf, excel et docx",
    },
    analyzing_file: {
        en: "Analyzing file...",
        fr: "Analyse du fichier...",
    },
    uploaded_file_name: {
        en: "Questionnaire from: {{name}}",
        fr: "Questionnaire issu de: {{name}}",
    },
    template_card_title: {
        en: "See all templates",
        fr: "Voir tous les modèles",
    },
    template_card_description: {
        en: "Create a questionnaire from the template {{templateName}}.",
        fr: "Créer un questionnaire à partir du template {{templateName}}.",
    },
    all_templates_card_description: {
        en: "Choose a template from our library.",
        fr: "Choisissez un modèle de notre bibliothèque.",
    },
    template_card_button_label: {
        en: "Choose template",
        fr: "Choisir un modèle",
    },
});
export function useQuestionnairesDashboardTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
