import { Navigate, Outlet, RouteObject } from "react-router-dom";

import { SupNavbar } from "@app/screens/Global/SupNavbar/index.tsx";
import { UserRole } from "../../generated/client/graphql.ts";
import Loading from "../components/Loading/index.tsx";
import {
    CustomerChurnedErrorPage,
    GeneralErrorPage,
    InternalServerErrorPage,
} from "./admin.tsx";
import { useNonPublicRoutes } from "./utils.ts";
import { supPaths } from "./supPaths.tsx";
import { Sup, Questionnaires, Users, Questionnaire } from "@app/screens/Sup";
import Background from "@app/components/Background/index.tsx";
import ErrorBoundary from "@app/shared/components/ErrorBoundary/index.tsx";

export const SupLayout = () => {
    const {
        copyErrorToClipboard,
        currentUserRole,
        error,
        goToHomepage,
        handleChurnedErrorPageCTA,
        isLoading,
        isRefetching,
        orgIsLoading,
        showCustomerChurnedErrorPage,
        showInternalServerErrorPage,
    } = useNonPublicRoutes();

    if (showCustomerChurnedErrorPage) {
        return (
            <CustomerChurnedErrorPage onCTAClick={handleChurnedErrorPageCTA} />
        );
    }

    if (isLoading || isRefetching || orgIsLoading) {
        return <Loading size="large" />;
    }

    if (
        currentUserRole &&
        [UserRole.Admin, UserRole.BeavrAdmin, UserRole.Supplier].includes(
            currentUserRole,
        )
    ) {
        return (
            <div className="min-h-screen antialiased flex">
                <SupNavbar />
                <div className="min-h-screen w-full min-w-[960px]">
                    <Background topBar={<>Add CTA</>} hasBgBars>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </Background>
                </div>
            </div>
        );
    }

    if (showInternalServerErrorPage) {
        return <InternalServerErrorPage onCTAClick={goToHomepage} />;
    }

    if (error) {
        return <GeneralErrorPage onCTAClick={copyErrorToClipboard} />;
    }

    return <></>;
};

export const supRoutes: RouteObject[] = [
    {
        index: true,
        element: <Sup />,
    },
    {
        path: supPaths.sup_id_param,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate to={supPaths.questionnaires} replace />,
            },
            {
                path: supPaths.questionnaires,
                children: [
                    {
                        index: true,
                        element: <Questionnaires />,
                    },
                    {
                        path: supPaths.questionnaire_id_param,
                        element: <Questionnaire />,
                    },
                ],
            },
            {
                path: supPaths.users,
                element: <Users />,
            },
        ],
    },
];
