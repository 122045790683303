import { ReactComponent as AddUsersIllustration } from "@app/assets/illustrations/add-users.svg";
import { ReactComponent as DefineResponsiblesIllustration } from "@app/assets/illustrations/define-responsibles.svg";
import { ReactComponent as DiscoverIllustration } from "@app/assets/illustrations/discover.svg";
import { ReactComponent as SetupIllustration } from "@app/assets/illustrations/setup.svg";
import { ReactComponent as UploadDocumentsIllustration } from "@app/assets/illustrations/upload-documents.svg";
import { type OnboardingProgress } from "@app/repositories/LocalStorageRepositories/OnboardingProgressRepository";
import {
    AdminPath,
    ReportingPath,
    adminPaths,
    reportingPaths,
} from "@app/routing/adminPaths";
import { type ClassValue } from "@design-system/Utilities";
import { type FC, type SVGProps } from "react";
import { createSearchParams } from "react-router-dom";
import { DashboardPlanStepType } from "../../../generated/client/graphql";

export const modals = {
    uploadDocuments: "uploadDocuments",
};

const configurationCards: {
    id: keyof OnboardingProgress;
    Illustration: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    bgClassname: ClassValue;
    titleI18nKey: string;
    descriptionI18nKey: string;
    readingTimeI18nKey: string;
    learnMoreCb: {
        fr: () => void;
        en: () => void;
    };
    path?: AdminPath | string;
}[] = [
    {
        id: "discoverBeavr",
        Illustration: DiscoverIllustration,
        bgClassname: "bg-[#5de394]",
        titleI18nKey: "configuration_cards_discover_beavr_title",
        descriptionI18nKey: "configuration_cards_discover_beavr_description",
        readingTimeI18nKey: "configuration_cards_discover_beavr_reading_time",
        learnMoreCb: {
            fr: () =>
                window.open(
                    "https://www.loom.com/share/5c99632a4be741bdbc8627ddc4d71f17?sid=ce265b5e-ea45-4bc0-bbb1-651d3fcdce02",
                    "_blank",
                ),
            en: () =>
                window.open(
                    "https://www.loom.com/share/a556c975dff442108633c1060b93ac93",
                    "_blank",
                ),
        },
    },
    {
        id: "addUsers",
        Illustration: AddUsersIllustration,
        bgClassname: "bg-[#ffc700]",
        titleI18nKey: "configuration_cards_add_users_title",
        descriptionI18nKey: "configuration_cards_add_users_description",
        readingTimeI18nKey: "configuration_cards_add_users_reading_time",
        learnMoreCb: {
            fr: () =>
                window.open(
                    "https://26313404.hs-sites-eu1.com/fr/knowledge/comment-ajouter-des-utilisateurs",
                    "_blank",
                ),
            en: () =>
                window.open(
                    "https://26313404.hs-sites-eu1.com/knowledge/how-can-i-add-users-1",
                    "_blank",
                ),
        },
    },
    {
        id: "assignResponsibles",
        Illustration: DefineResponsiblesIllustration,
        bgClassname: "bg-[#f492e4]",
        titleI18nKey: "configuration_cards_assign_responsibles_title",
        descriptionI18nKey:
            "configuration_cards_assign_responsibles_description",
        readingTimeI18nKey:
            "configuration_cards_assign_responsibles_reading_time",
        learnMoreCb: {
            fr: () =>
                window.open(
                    "https://26313404.hs-sites-eu1.com/fr/knowledge/comment-puis-je-attribuer-un-indicateur-%C3%A0-un-contributeur",
                    "_blank",
                ),
            en: () =>
                window.open(
                    "https://26313404.hs-sites-eu1.com/fr/knowledge/comment-puis-je-attribuer-un-indicateur-%C3%A0-un-contributeur",
                    "_blank",
                ),
        },
    },
    {
        id: "configureSustainabilityProfile",
        Illustration: SetupIllustration,
        bgClassname: "bg-[#89b3d0]",
        titleI18nKey:
            "configuration_cards_configure_sustainability_profile_title",
        descriptionI18nKey:
            "configuration_cards_configure_sustainability_profile_description",
        readingTimeI18nKey:
            "configuration_cards_configure_sustainability_profile_reading_time",
        learnMoreCb: {
            fr: () =>
                window.open(
                    "https://intercom.help/beavrtech/fr/articles/10802892-inscriptions-aux-prochains-ateliers-de-travail-webinars",
                    "_blank",
                ),
            en: () =>
                window.open(
                    "https://26313404.hs-sites-eu1.com/knowledge",
                    "_blank",
                ),
        },
    },
    {
        id: "uploadDocuments",
        Illustration: UploadDocumentsIllustration,
        bgClassname: "bg-[#ec825a]",
        titleI18nKey: "configuration_cards_upload_documents_title",
        descriptionI18nKey: "configuration_cards_upload_documents_description",
        readingTimeI18nKey: "configuration_cards_upload_documents_reading_time",
        learnMoreCb: {
            fr: () => {
                return void 0;
            },
            en: () => {
                return void 0;
            },
        },
        path:
            ".?" +
            createSearchParams({ modal: modals.uploadDocuments }).toString(),
    },
];

export const beavrAdminConfigurationCards = configurationCards;
export const userConfigurationCards = configurationCards.filter(
    (card) => card.id !== "uploadDocuments",
);

type AgendaCards = {
    id: DashboardPlanStepType;
    titleI18nKey: string;
    descriptionI18nKey: string;
    path: AdminPath | `${AdminPath}/${ReportingPath}`;
    learnMoreCb: {
        fr: () => void;
        en: () => void;
    };
}[];

export const getAgendaCards = (
    shouldUseLegacyObjectives?: boolean,
): AgendaCards => {
    return [
        {
            id: DashboardPlanStepType.Reporting,
            titleI18nKey: "agenda_cards_reporting_title",
            descriptionI18nKey: "agenda_cards_reporting_description",
            path: `${adminPaths.reporting}/${reportingPaths.overview}`,
            learnMoreCb: {
                fr: () =>
                    window.open(
                        "https://www.loom.com/share/98a5a5960c72490495a5526e240c05b8?sid=9d718ce4-e0a8-44db-94a3-d347c40eb01f",
                        "_blank",
                    ),
                en: () =>
                    window.open(
                        "https://www.loom.com/share/8dae28cff8664e1e9909f8b37fe85d65?sid=0950872a-1c5f-4422-adb6-6fe3d12d8c5d",
                        "_blank",
                    ),
            },
        },
        {
            id: DashboardPlanStepType.Engagements,
            titleI18nKey: "agenda_cards_engagements_title",
            descriptionI18nKey: "agenda_cards_engagements_description",
            path: shouldUseLegacyObjectives
                ? adminPaths.policies
                : adminPaths.objectives,
            learnMoreCb: {
                fr: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/fr/knowledge/quest-ce-que-sont-des-engagements-et-objectifs",
                        "_blank",
                    ),
                en: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/knowledge/what-are-commitments-and-targets",
                        "_blank",
                    ),
            },
        },
        {
            id: DashboardPlanStepType.Policies,
            titleI18nKey: "agenda_cards_policies_title",
            descriptionI18nKey: "agenda_cards_policies_description",
            path: adminPaths.policies,
            learnMoreCb: {
                fr: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/fr/knowledge/quest-ce-que-sont-des-politiques",
                        "_blank",
                    ),
                en: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/knowledge/what-are-policies",
                        "_blank",
                    ),
            },
        },
        {
            id: DashboardPlanStepType.Measures,
            titleI18nKey: "agenda_cards_measures_title",
            descriptionI18nKey: "agenda_cards_measures_description",
            path: adminPaths.measures,
            learnMoreCb: {
                fr: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/fr/knowledge/guides-pour-mesures",
                        "_blank",
                    ),
                en: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/knowledge/what-is-a-measure",
                        "_blank",
                    ),
            },
        },
        {
            id: DashboardPlanStepType.Followup,
            titleI18nKey: "agenda_cards_followup_title",
            descriptionI18nKey: "agenda_cards_followup_description",
            path: adminPaths.frameworks,
            learnMoreCb: {
                fr: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/fr/knowledge/comment-soumettre-son-questionnaire-ecovadis",
                        "_blank",
                    ),
                en: () =>
                    window.open(
                        "https://26313404.hs-sites-eu1.com/knowledge/how-to-submit-your-ecovadis-questionnaire",
                        "_blank",
                    ),
            },
        },
    ];
};
