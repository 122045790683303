import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "place-holder";

const keys = addTranslations(RESOURCE_NAME, {
    title: {
        en: "Instant AI-powered answers for CSR questions",
        fr: "Des réponses instantanées grâce à notre intelligence artificielle",
    },
    text: {
        en: "Copy-paste any question from your questionnaire, and let our AI generate a response in one click.",
        fr: "Copiez-collez une question de votre questionnaire, et laissez notre IA générer une réponse en un clic.",
    },
});

export function usePlaceHolderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
