import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportingKeys } from "../../data";

const keys = {
    createIndicator: () => [...reportingKeys.all, "createIndicator"] as const,
};

const indicatorRepository = new GraphQLIndicatorRepository();

export const useCreateIndicator = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: keys.createIndicator(),
        mutationFn: indicatorRepository.createCustomIndicator,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: reportingKeys.all });
        },
    });
};
