import { useSetToast } from "@app/components/Toast";
import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { currentUserIsBeavrAtom } from "@app/store/userStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { ResponseStatus } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useCreateFrameworkQuestionnaire,
    useReleasedCmsFrameworks,
} from "../../data";

const CreateFrameworkQuestionnaireModal = () => {
    const { isBeavrUser } = useAtomValue(currentUserIsBeavrAtom);
    const legacyFrameworkIds = ["csrd", "evs-2023"];
    const [open, setOpen] = useState(false);
    const { releasedCmsFrameworks } = useReleasedCmsFrameworks();
    const [year, setYear] = useState(new Date().getFullYear());
    const [locale, setLocale] = useState<string | undefined>(undefined);
    const { mutate: createFrameworkQuestionnaire, isPending } =
        useCreateFrameworkQuestionnaire();
    const [frameworkId, setFrameworkId] = useState<string | undefined>(
        undefined,
    );
    const { setToastError, setToastSuccess } = useSetToast();

    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

    const selectedFramework = releasedCmsFrameworks?.find(
        (f) => f.id === frameworkId,
    );
    const value = selectedFramework?.name;

    const disabled = !frameworkId || !year || !locale;

    const handleCreateQuestionnaire = () => {
        if (!frameworkId || !year || !locale) return;
        createFrameworkQuestionnaire(
            {
                frameworkCmsId: frameworkId,
                year,
                tagIds: selectedTagIds,
                locale,
            },
            {
                onSuccess: (data) => {
                    if (
                        data.createFrameworkQuestionnaire?.status ===
                        ResponseStatus.Error
                    ) {
                        setToastError(
                            t(
                                `create_modal.${data.createFrameworkQuestionnaire?.error?.i18key}`,
                            ),
                        );
                    } else {
                        setOpen(false);
                        setToastSuccess(t("create_modal.success"));
                    }
                },
            },
        );
    };

    const { t } = useTranslation("Frameworks");

    const isSelected = (tagId: string) => {
        return selectedTagIds.includes(tagId);
    };

    const handleTagClick = (tagId: string) => {
        if (isSelected(tagId)) {
            setSelectedTagIds((prev) => prev.filter((id) => id !== tagId));
        } else {
            setSelectedTagIds((prev) => [...prev, tagId]);
        }
    };

    const tags = useMemo(() => {
        return releasedCmsFrameworks?.find((f) => f.id === frameworkId)?.tags;
    }, [releasedCmsFrameworks, frameworkId]);

    useEffect(() => {
        const availableLocales = releasedCmsFrameworks?.find(
            (f) => f.id === frameworkId,
        )?.locale?.availableLocales;
        if (!locale || !availableLocales?.includes(locale)) {
            setLocale(availableLocales?.[0]);
        }
    }, [releasedCmsFrameworks, frameworkId]);

    if (!isBeavrUser) {
        return <></>;
    }
    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger asChild>
                <Button variant="tonal">
                    <Icon name="plus" />
                    <Text variant="body-bold">
                        {t("create_modal.open_button")}
                    </Text>
                </Button>
            </Modal.Trigger>
            <Modal.Overlay centerContent className="py-5">
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{t("create_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="flex flex-col gap-4 items-start max-h-[500px] overflow-y-scroll">
                        <Dropdown
                            value={[frameworkId ?? ""]}
                            onValueChange={(value) => setFrameworkId(value[0])}
                            selectType="single"
                        >
                            <Dropdown.Trigger asChild className="w-fit">
                                <Button variant="tonal">
                                    <FlexRow gap="2" alignItems="center">
                                        <Icon name="file" />
                                        <Text variant="body-bold">
                                            {value
                                                ? value
                                                : t(
                                                      "create_modal.framework_placeholder",
                                                  )}
                                        </Text>
                                        <Icon name="angleDown" />
                                    </FlexRow>
                                </Button>
                            </Dropdown.Trigger>
                            <Dropdown.Content>
                                {releasedCmsFrameworks?.map((f) => (
                                    <Dropdown.SelectableItem
                                        value={f.id}
                                        key={f.id}
                                    >
                                        {f.name}
                                    </Dropdown.SelectableItem>
                                ))}
                            </Dropdown.Content>
                        </Dropdown>
                        <Dropdown
                            value={[year.toString()]}
                            onValueChange={(value) => setYear(Number(value))}
                        >
                            <Dropdown.Trigger asChild className="w-fit">
                                <Button variant="outlined">
                                    <FlexRow gap="2" alignItems="center">
                                        <Icon name="calendar" />
                                        <Text variant="body-bold">
                                            {year ||
                                                t(
                                                    "create_modal.year_placeholder",
                                                )}
                                        </Text>
                                        <Icon name="angleDown" />
                                    </FlexRow>
                                </Button>
                            </Dropdown.Trigger>
                            <Dropdown.Content>
                                <Dropdown.SelectableItem value={"2025"}>
                                    2025
                                </Dropdown.SelectableItem>
                            </Dropdown.Content>
                        </Dropdown>
                        {frameworkId !== undefined &&
                            !legacyFrameworkIds.includes(frameworkId) &&
                            locale && (
                                <FlexCol gap="2">
                                    <LocaleSelect
                                        value={locale as SupportedLocales}
                                        onChange={setLocale}
                                        options={
                                            selectedFramework?.locale
                                                ?.availableLocales as SupportedLocales[]
                                        }
                                    />
                                    <Text variant="body" color="secondary">
                                        {t("create_modal.locale_description")}
                                    </Text>
                                </FlexCol>
                            )}
                        {frameworkId !== undefined &&
                            !legacyFrameworkIds.includes(frameworkId) &&
                            !!tags?.length && (
                                <FlexCol gap="2" w="full">
                                    <Text variant="body-lg-bold">
                                        {t("create_modal.select_tags")}
                                    </Text>
                                    <Text variant="body" color="secondary">
                                        {t("create_modal.tags_description")}
                                    </Text>

                                    <Box
                                        className="grid grid-cols-4"
                                        gap="2"
                                        w="full"
                                    >
                                        {tags?.map((t) => (
                                            <Button
                                                key={t.id}
                                                variant="outlined"
                                                size="sm"
                                                className={cn(
                                                    "rounded-full shadow w-[150px] h-7",
                                                    isSelected(t.id) &&
                                                        "bg-beavrGreen hover:bg-beavrGreen",
                                                )}
                                                onClick={() =>
                                                    handleTagClick(t.id)
                                                }
                                            >
                                                <Ellipsis
                                                    className="font-bold"
                                                    hasTooltip
                                                    asChild
                                                >
                                                    <Text variant="body-bold">
                                                        {t.name}
                                                    </Text>
                                                </Ellipsis>
                                            </Button>
                                        ))}
                                    </Box>
                                </FlexCol>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="flatPrimary"
                            loading={isPending}
                            disabled={disabled}
                            onClick={handleCreateQuestionnaire}
                        >
                            Create questionnaire
                        </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export default CreateFrameworkQuestionnaireModal;
