import { Box } from "@design-system/Layout/Box";
import { ClassValue, cn } from "@design-system/Utilities";
import {
    type FC,
    type HTMLAttributes,
    type Ref,
    type TdHTMLAttributes,
    type ThHTMLAttributes,
} from "react";

export type Variant = "regular" | "compact" | "grid";
export const Table: FC<
    HTMLAttributes<HTMLTableElement> & {
        containerClassName?: ClassValue;
        containerRef?: Ref<HTMLDivElement | null>;
    } & { ref?: Ref<HTMLTableElement | null> }
> = ({ className, containerClassName, containerRef, ...props }) => (
    <Box
        className={cn(
            "border border-tertiary",
            "rounded-lg",
            "overflow-y-hidden overflow-x-auto",
            containerClassName,
        )}
        br="lg"
        w="full"
        // This is necessary because we may wanna interact with the container e.g. for scroll reasons.
        ref={containerRef}
    >
        <table
            className={cn(
                "htmltable",
                "w-full rounded-lg overflow-auto",
                "border-separate border-spacing-0",
                "text-sm",
                className,
            )}
            {...props}
        />
    </Box>
);

export const TableHeader: FC<
    HTMLAttributes<HTMLTableSectionElement> & {
        ref?: Ref<HTMLTableSectionElement | null>;
    }
> = ({ className, ref, ...props }) => (
    <thead ref={ref} className={className} {...props} />
);

export const TableBody: FC<
    HTMLAttributes<HTMLTableSectionElement> & {
        ref?: Ref<HTMLTableSectionElement | null>;
    }
> = ({ className, ref, ...props }) => (
    <tbody ref={ref} className={className} {...props} />
);

export const TableRow: FC<
    HTMLAttributes<HTMLTableRowElement> & {
        ref?: Ref<HTMLTableRowElement | null>;
    }
> = ({ className, ref, ...props }) => (
    <tr
        ref={ref}
        className={cn(
            "group/tablerow",
            "border-b border-tertiary last:border-b-0 transition-colors",
            className,
        )}
        {...props}
    />
);

export const TableHead: FC<
    ThHTMLAttributes<HTMLTableCellElement> & { variant?: Variant } & {
        ref?: Ref<HTMLTableCellElement | null>;
    }
> = ({ className, variant = "regular", ref, ...props }) => (
    <th
        className={cn(
            "border-b border-tertiary",
            variant === "grid" && "border-r last:border-r-0",
            "h-[40px] bg-secondary text-left text-sm align-middle [&:has([role=checkbox])]:pr-0",
            variant === "compact" ? "px-2 py-1.5" : "px-4",
            className,
        )}
        ref={ref}
        {...props}
    />
);

export const TableCell: FC<
    TdHTMLAttributes<HTMLTableCellElement> & { variant?: Variant } & {
        ref?: Ref<HTMLTableCellElement | null>;
    }
> = ({ className, variant = "regular", ref, ...props }) => (
    <td
        className={cn(
            "relative align-middle [&:has([role=checkbox])]:pr-0",
            "border-b border-tertiary group-last/tablerow:border-b-0",
            variant === "grid" && "border-r last:border-r-0",
            "bg-white group-hover/tablerow:bg-secondary data-[state=selected]:bg-secondary",
            variant === "compact"
                ? "px-2 py-1 h-5 text-sm"
                : "px-4 py-3 text-base",
            className,
        )}
        {...props}
    />
);
