import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { useFragment } from "@generated/client";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import {
    IndicatorToFillFieldsFragmentDoc,
    RequestBlockFieldsFragment,
    SupplierQuestionnaire,
    SupplierQuestionnaireStatus,
} from "../../../../generated/client/graphql";
import ActionWithConfirmation from "../../../components/Modal/ActionWithConfirmation";
import { useUpdateSupplierQuestionnaire } from "./hooks/updateSupplierQuestionnaire";

const CloseQuestionnaireButton = ({
    supplierQuestionnaire,
    color,
    unansweredRequests,
}: {
    supplierQuestionnaire?: SupplierQuestionnaire;
    color: chroma.Color;
    unansweredRequests?: RequestBlockFieldsFragment[];
}) => {
    const { t } = useTranslation("SupplierContribution");

    const { updateQuestionnaire, isPending } = useUpdateSupplierQuestionnaire(
        supplierQuestionnaire?.id,
    );
    const navgateToBeavr = () => {
        window.location.href = "https://beavr.tech";
    };
    const showClose =
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.InProgress ||
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.NotStarted;

    const indicatorsNotAnswered = unansweredRequests?.map((request) =>
        useFragment(IndicatorToFillFieldsFragmentDoc, request.indicator),
    );

    const disabled = !!indicatorsNotAnswered?.length;
    return showClose ? (
        <FlexRow gap="2">
            <Button variant="outlined" onClick={navgateToBeavr}>
                {t("close_questionnaire_save_and_close")}
            </Button>
            <ActionWithConfirmation
                TriggerButton={
                    <Button
                        loading={isPending}
                        style={{ backgroundColor: color.css() }}
                    >
                        {t("send_questionnaire_for_review")}
                    </Button>
                }
                confirmButton={
                    <Button
                        onClick={() =>
                            updateQuestionnaire({
                                status: SupplierQuestionnaireStatus.Closed,
                            })
                        }
                        disabled={disabled}
                    >
                        {t("send_questionnaire_for_review")}
                    </Button>
                }
                title={t("send_questionnaire_for_review_title")}
            >
                {!!indicatorsNotAnswered?.length ? (
                    <>
                        <div className="text-danger">
                            {t("not_answered_indicators")}
                        </div>
                        {indicatorsNotAnswered
                            ?.slice(0, 10)
                            .map((indicator) => (
                                <div className="text-danger">
                                    {indicator?.name}
                                </div>
                            ))}
                        {indicatorsNotAnswered?.length &&
                            indicatorsNotAnswered?.length > 10 && (
                                <div className="text-danger">
                                    {t("and_more", {
                                        count:
                                            indicatorsNotAnswered.length - 10,
                                    })}
                                </div>
                            )}
                        <div className="text-danger">
                            {t("finish_questionnaire")}
                        </div>
                    </>
                ) : (
                    <div className="text-danger">
                        {t("send_questionnaire_for_review_content")}
                    </div>
                )}
            </ActionWithConfirmation>
        </FlexRow>
    ) : (
        <></>
    );
};

export default CloseQuestionnaireButton;
