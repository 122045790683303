import * as Select from "@radix-ui/react-select";
import { Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import SelectItem from "../../Dropdown/SelectItem";
import SelectTrigger from "../SelectTrigger";
import "./i18n";

interface SelectInputProps {
    success?: boolean;
    disabled?: boolean;
    optionId: string | undefined;
    onValueChange: (value: string | null) => void;
    options?: {
        id: string;
        name: string;
    }[];
}
const SelectInput = forwardRef(
    (
        {
            success,
            disabled,
            optionId,
            onValueChange,
            options,
        }: SelectInputProps,
        ref: Ref<HTMLButtonElement>,
    ) => {
        const { t } = useTranslation("InputSelect");

        const selectedOption = options?.find((o) => o.id === optionId);
        return (
            <Select.Root
                value={optionId}
                onValueChange={(val) => {
                    if (val === "") {
                        onValueChange(null);
                    } else {
                        onValueChange(val);
                    }
                }}
            >
                <SelectTrigger
                    stringValue={selectedOption?.name}
                    placeholder={t("placeholder")}
                    value={optionId}
                    success={success}
                    ref={ref}
                    disabled={disabled}
                    className="w-full"
                />

                <Select.Portal className="w-full">
                    <Select.Content
                        position="popper"
                        sideOffset={4}
                        className="border bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto"
                        style={{ width: "var(--radix-select-trigger-width)" }}
                    >
                        <Select.Viewport>
                            <SelectItem value={""}>{t("unset")}</SelectItem>
                            {options?.map((option) => (
                                <SelectItem value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        );
    },
);

export default SelectInput;
