import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "sup-questionnaires";

const keys = addTranslations(RESOURCE_NAME, {
    description: {
        en: "See the list of questionnaires, inclusing the current one or the closed ones.",
        fr: "Voir la liste des questionnaires, y compris le questionnaire actuel ou les questionnaires fermés.",
    },
});

export function useSupplierQuestionnairesTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
