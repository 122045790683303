import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import {
    getFlagByLocale,
    RESOURCE_NAME,
    SupportedLocales,
} from "@app/shared/utils/locales";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { TagMultiSelect } from "@design-system/Inputs/TagMultiSelect";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateSupplierTemplate } from "../../data";
import { useCreateQuestionnaireModalTranslations } from "./i18n";

export const CreateQuestionnaireModal: FC<{
    open: boolean;
    setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
    const { createSupplierTemplate } = useCreateSupplierTemplate();

    const availableLocales = Object.values(SupportedLocales);
    const [selectedLocales, setSelectedLocales] = useState<
        SupportedLocales[] | undefined
    >([SupportedLocales.EN]);
    const filteredLocales = availableLocales.filter(
        (locale) => !selectedLocales?.includes(locale),
    );

    const [defaultLocale, setDefaultLocale] = useState<SupportedLocales>(
        SupportedLocales.EN,
    );

    const handleSelectedLocalesChange = (value: string | string[] | null) => {
        if (!value || !Array.isArray(value)) return;
        setSelectedLocales(value as SupportedLocales[]);
    };

    const unselectLocale = (locale: SupportedLocales) => {
        return () => {
            setSelectedLocales((prev) => {
                if (!prev) return undefined;
                return prev.filter((l) => l !== locale);
            });
        };
    };

    const handleDefaultLocaleChange = (value: SupportedLocales) => {
        setDefaultLocale(value);
        if (selectedLocales?.includes(value)) {
            return;
        }
        setSelectedLocales((prev) => {
            return [value, ...(prev ?? [])];
        });
    };

    const handleConfirmClick = () => {
        if (!selectedLocales?.length) return;
        createSupplierTemplate({
            availableLocales: selectedLocales,
            default: defaultLocale,
        });
    };

    const { t: tLocale } = useTranslation(RESOURCE_NAME);
    const { t, keys } = useCreateQuestionnaireModalTranslations();

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger asChild>
                <Button>{t(keys.empty_state_button)}</Button>
            </Modal.Trigger>
            <Modal.Overlay scrollable>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            <FlexCol gap="2">
                                <Text color="primary">
                                    {t(keys.select_languages)}
                                </Text>
                                <FlexCol>
                                    <TagMultiSelect
                                        multiple
                                        value={selectedLocales}
                                        onChange={handleSelectedLocalesChange}
                                    >
                                        <TagMultiSelect.Trigger>
                                            <FlexRow gap="2">
                                                {selectedLocales?.map(
                                                    (locale) => (
                                                        <TagMultiSelect.Tag
                                                            key={locale}
                                                            variant="tonal"
                                                            size="xs"
                                                            onClick={unselectLocale(
                                                                locale,
                                                            )}
                                                        >
                                                            <Button.Icon
                                                                className="text-primary"
                                                                name={getFlagByLocale(
                                                                    locale,
                                                                )}
                                                                size="xs"
                                                            />
                                                            {tLocale(locale)}
                                                            <Button.Icon
                                                                name="close"
                                                                size="xs"
                                                            />
                                                        </TagMultiSelect.Tag>
                                                    ),
                                                )}
                                            </FlexRow>
                                            <TagMultiSelect.Button className="w-full flex items-center justify-between">
                                                <Text
                                                    variant="body-sm"
                                                    color="secondary"
                                                >
                                                    {t(keys.select_locales)}
                                                </Text>
                                                <Icon
                                                    className="text-primary"
                                                    name="angleDown"
                                                    size="sm"
                                                />
                                            </TagMultiSelect.Button>
                                        </TagMultiSelect.Trigger>
                                        <TagMultiSelect.Options className="border empty:invisible">
                                            {filteredLocales.map((locale) => (
                                                <TagMultiSelect.Option
                                                    key={locale}
                                                    value={locale}
                                                >
                                                    {tLocale(locale)}
                                                </TagMultiSelect.Option>
                                            ))}
                                        </TagMultiSelect.Options>
                                    </TagMultiSelect>
                                </FlexCol>
                            </FlexCol>
                            <FlexCol gap="2">
                                <Text color="primary">
                                    {t(keys.default_language)}
                                </Text>
                                <LocaleSelect
                                    value={defaultLocale}
                                    onChange={handleDefaultLocaleChange}
                                    options={availableLocales}
                                />
                            </FlexCol>
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            disabled={!selectedLocales?.length}
                            onClick={handleConfirmClick}
                        >
                            {t(keys.create)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
