import { AddUsersModal } from "@app/screens/Supplier/Suppliers/supplier/components/AddUsersModal";
import { useSupplier } from "@app/screens/Supplier/Suppliers/supplier/data";
import BodyHeader from "@app/shared/components/BodyHeader";
import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { type FC } from "react";
import { useParams } from "react-router-dom";
import { UserTable } from "./components/UserTable";
import { useSupplierUsersTranslations } from "./i18n";

export const Users: FC = () => {
    const { sup_id } = useParams();
    const { supplier } = useSupplier(sup_id);
    const { t, keys } = useSupplierUsersTranslations();

    return (
        <PageContainer>
            <FlexCol gap="4">
                <BodyHeader
                    title={t(keys.header_title)}
                    subTitle={t(keys.header_description, {
                        supplier_name: supplier?.name,
                    })}
                    action={<AddUsersModal supplierId={sup_id} />}
                />
                <FlexCol gap="4" p="4">
                    <UserTable supplierId={sup_id} />
                </FlexCol>
            </FlexCol>
        </PageContainer>
    );
};
