import { GetThemesAndSubthemesQuery } from "@generated/client/graphql";
import { FC } from "react";
import { useObjectivesWithDetails } from "../../../data";
import { ThemeAccordionItem } from "./ThemeAccordionItem";

interface ThemeAccordionWrapperProps {
    theme: GetThemesAndSubthemesQuery["themes"][number];
    entityId: string;
}

export const ThemeAccordionWrapper: FC<ThemeAccordionWrapperProps> = ({
    theme,
    entityId,
}) => {
    const { objectives } = useObjectivesWithDetails({
        themeId: theme.id || "",
        entityId,
    });

    if (!theme.id) return null;

    return <ThemeAccordionItem theme={theme} objectives={objectives || []} />;
};
