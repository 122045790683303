import { Pagination } from "@app/shared/types/API.types";

export const supplierQuestionnairesKeys = {
    all: ["supplierQuestionnaires"] as const,
    oneAll: () => [...supplierQuestionnairesKeys.all, "one"] as const,
    one: (questionnaireId?: string, supplierId?: string) =>
        [
            ...supplierQuestionnairesKeys.oneAll(),
            questionnaireId,
            supplierId,
        ] as const,
    questionsAll: () => [...supplierQuestionnairesKeys.oneAll(), "questions"],
    questions: (
        questionnaireId?: string,
        supplierId?: string,
        pagination?: Pagination,
    ) =>
        [
            ...supplierQuestionnairesKeys.questionsAll(),
            "questions",
            questionnaireId,
            supplierId,
            pagination,
        ] as const,
};
