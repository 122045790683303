import i18next from "../../../i18n";

const enResources = {
    approved: "Document approved",
    re_send_for_review: "Re-send for review ({{- ratio }})",
    send_for_review_zero: "Send for review",
    send_for_review_other: "Send for review ({{- ratio }})",
    send_for_review: "for type safety",
    send_for_review_tooltip_checks:
    "You need to validate the checks to be able to send for review. Once sent, the process takes up to 4 days.",
    send_for_review_tooltip_essential:
    "We only review essential documents. You can directly approve this one.",
    request_review: "Request review",
    force_approve: "Force approval",
    under_review: "Document under review...",
    under_review_tooltip:
    "Thanks for submitting your document, the review process takes up to 4 days. You ll receive notifications as we comment on your doc.",
    cancel_review_request: "Cancel review",
    reject: "Reject",
    approve: "Approve now",
    approve_tooltip: "To make sure your document is correct, please check the template if there is one, or the document description.",
    download: "Download",
} as const;

type ResourcesKeys = keyof typeof enResources;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResources("en", "VersionValidation", enResources);
i18next.addResources("fr", "VersionValidation", {
    approved: "Document approuvé",
    re_send_for_review: "Re-envoyer pour révision ({{- ratio }})",
    send_for_review_zero: "Envoyer pour révision",
    send_for_review_other: "Envoyer pour révision ({{- ratio }})",
    send_for_review_tooltip_checks:
        "Vous devez valider les vérifications pour pouvoir les envoyer en révision. Une fois envoyé, le processus prend jusqu'à 4 jours.",
    send_for_review_tooltip_essential:
        "Nous ne révisons que les documents essentiels. Vous pouvez l'approuver directement.",
    force_approve: "Forcer l'approbation",
    request_review: "Demander révision",
    under_review: "En cours de révision...",
    under_review_tooltip:
        "Merci d'avoir soumis votre document, le processus de révision prend jusqu'à 4 jours. Vous recevrez des notifications lorsque nous commenterons votre document.",
    cancel_review_request: "Annuler la demande de révision",
    reject: "Rejeter",
    approve: "Approuver",
    approve_tooltip: "Pour être sûr que votre document est correct, veuillez vérifier le modèle si il y en a un, ou la description du document.",
    download: "Télécharger",
});
