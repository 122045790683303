import { reportingKeys } from "@app/pages/Reporting/data";
import { useGraphQL } from "@app/QueryClientWithHeaders";
import { GraphQLTagRepository } from "@app/repositories/GraphQLRepositories/TagRepository";
import {
    CreateTagInput,
    GetDocumentsV2Document,
    GetSuppliers_DeprecatedDocument,
    TagType,
} from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const tagKeys = {
    tags: () => ["tags"] as const,
    tagsByType: (type: TagType) => [...tagKeys.tags(), type] as const,
    update: (id: string) => ["updateTag", id] as const,
    create: () => ["createTag"] as const,
    delete: (id: string) => ["deleteTag", id] as const,
};

const tagRepository = new GraphQLTagRepository();

export const useUpdateTag = (id: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: tagKeys.update(id),
        mutationFn: async (name: string) => {
            await tagRepository.update(id, name);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: tagKeys.tags(),
            });
        },
    });

    return mutation;
};

export const useCreateTag = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: tagKeys.create(),
        mutationFn: (input: CreateTagInput) =>
            tagRepository.create(input.type, input.name),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: tagKeys.tags(),
            });
        },
    });

    return mutation;
};

export const useDeleteTag = (id: string, type: TagType) => {
    const queryClient = useQueryClient();

    const getQueryKeyToInvalidate = () => {
        switch (type) {
            case TagType.Indicator:
                return reportingKeys.indicators();
            case TagType.Document:
                return useGraphQL.getRootKey(GetDocumentsV2Document);
            case TagType.Supplier:
                return useGraphQL.getKey(GetSuppliers_DeprecatedDocument);
        }
    };

    const mutation = useMutation({
        mutationKey: tagKeys.delete(id),
        mutationFn: tagRepository.delete,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: tagKeys.tags(),
            });
        },
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: getQueryKeyToInvalidate(),
            }),
    });

    return mutation;
};

export const useTags = (type: TagType) => {
    const { data } = useQuery({
        queryKey: tagKeys.tagsByType(type),
        queryFn: () => tagRepository.get(type),
    });

    return data?.tags;
};
