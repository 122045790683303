import { useParams } from "react-router-dom";
import Container from "../../../components/Container";

import { useTranslation } from "react-i18next";
import {
    Framework,
    FrameworkGroup,
} from "../../../../generated/client/graphql";
import AssignementCard from "../../../components/BodyAnalytics/AssignementCard";
import ProgressCard from "../../../components/BodyAnalytics/ProgressCard";
import RequirementStatusFilter from "../../../components/Dropdown/dropdowns/RequirementStatus/filter";
import UserTableFilter from "../../../components/Dropdown/dropdowns/User/tableFilter";
import SearchFilter from "../../../components/Filters/Search";

import { ExternalQuestionnaire } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire";
import { useQuestionnaireHeaderData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { ExportSection } from "@app/screens/Frameworks/framework/components/ExportSection";
import GroupBlocks from "@app/screens/Frameworks/framework/components/GroupBlocks";
import Navigation from "@app/screens/Frameworks/framework/components/Navigation";
import RequirementLayout from "@app/screens/Frameworks/framework/components/RequirementLayout";
import {
    Header,
    HeaderFullSkeleton,
} from "@app/shared/components/BackgroundedHeader";
import { HelpButton } from "@app/shared/components/Chameleon/HelpButton";
import { usePageviewTrack } from "@app/shared/planhat";
import { useRef, type FC, type RefObject } from "react";
import RequirementModal from "../../../pages/Requirements/components/modal";
import { getFrameworkColor } from "../services";
import {
    useFilteredFramework,
    useFrameworkBreadcrumb,
} from "./hooks/framework";
import { useFrameworkPageState } from "./hooks/pageState";
import "./i18n";
const LegacyFrameworkPage = () => {
    const { framework_id } = useParams();
    const { framework, stats, isPending } = useFilteredFramework(framework_id);
    const { state, setState } = useFrameworkPageState();

    const requirementLayoutRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation("Framework");
    return (
        <Container>
            {framework ? (
                <Header bgColor="#03A365">
                    <Header.LeftColumn>
                        <Header.Title>{framework?.name}</Header.Title>
                        <Header.Description>
                            {framework?.description}
                            <HelpButton
                                className="align-middle ml-2 text-white"
                                dataChameleonKey={`framework_${framework_id ?? ""}`}
                                theme="dark"
                            />
                        </Header.Description>
                    </Header.LeftColumn>
                    <Header.RightColumn cover="ecovadis" />
                </Header>
            ) : (
                <HeaderFullSkeleton />
            )}
            <ExportSection framework={framework as Framework} />
            <div className="p-4 space-y-4 flex flex-col flex-grow">
                <div className="w-full flex space-x-4">
                    <AssignementCard {...stats} />
                    <ProgressCard {...stats} />
                </div>
                <div className="w-full flex space-x-4">
                    <SearchFilter
                        searchString={state.searchFilter}
                        setSearchString={(searchFilter) =>
                            setState({ searchFilter })
                        }
                    />
                    <RequirementStatusFilter
                        status={state.statusFilter}
                        onStatusChange={(statusFilter) =>
                            setState({ statusFilter })
                        }
                    />
                    <UserTableFilter
                        selectedUserIds={state.ownerIdsFilter}
                        setSelectedUserIds={(ownerIdsFilter) =>
                            setState({ ownerIdsFilter })
                        }
                        placeholder={t("owner")}
                    />
                </div>
                <div className="w-full flex space-x-4 flex-grow ">
                    <Navigation
                        framework={framework as Framework | undefined}
                        isLoading={isPending}
                    />
                    <div className="relative min-h-0 flex-grow flex-shrink over">
                        <div
                            className="absolute h-full overflow-auto w-full scrollbar-hide "
                            ref={requirementLayoutRef}
                        >
                            <RequirementLayout>
                                {framework?.groups?.flatMap((group) =>
                                    GroupBlocks({
                                        group: group as FrameworkGroup,
                                        containerAnchorRef:
                                            requirementLayoutRef as unknown as RefObject<HTMLElement>,
                                    }),
                                )}
                            </RequirementLayout>
                        </div>
                    </div>
                </div>
            </div>
            <RequirementModal />
        </Container>
    );
};

const FrameworkQuestionnaire: FC<{ frameworkId: string }> = ({
    frameworkId,
}) => {
    const { questionnaireHeaderData } = useQuestionnaireHeaderData(frameworkId);

    if (!questionnaireHeaderData) {
        return <HeaderFullSkeleton />;
    }

    return (
        <ExternalQuestionnaire
            id={frameworkId}
            options={{
                canEditQuestions: false,
                canEditQuestionnaire: false,
                canDeleteQuestionnaire: false,
                themeColor: getFrameworkColor(
                    questionnaireHeaderData.cmsId ?? "",
                ),
                isFrameworkQuestionnaire: true,
            }}
        />
    );
};

export default function FrameworkPage() {
    const { framework_id } = useParams();
    usePageviewTrack("pageview_framework", framework_id ?? "");
    useFrameworkBreadcrumb();

    if (framework_id === "csrd" || framework_id === "evs-2023") {
        return <LegacyFrameworkPage />;
    } else {
        return <FrameworkQuestionnaire frameworkId={framework_id ?? ""} />;
    }
}
