import { Button } from "@design-system/Inputs/Button";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useErrorBoundaryTranslations } from "./i18n";

interface ErrorBoundaryProps {
    children: ReactNode;
    fallback?: ReactNode;
}

const DefaultFallback = () => {
    const { t, keys } = useErrorBoundaryTranslations();

    const reloadPage = () => window.location.reload();

    return (
        <FlexCol
            className="border justify-center items-center"
            h="full"
            w="full"
            gap="4"
        >
            <Text variant="header1">{t(keys.error)}</Text>
            <Text variant="body-bold">{t(keys.errorDescription)}</Text>
            <Button onClick={reloadPage}>{t(keys.reloadPage)}</Button>
        </FlexCol>
    );
};

const ErrorBoundary = ({ children, fallback }: ErrorBoundaryProps) => {
    return (
        <ReactErrorBoundary
            fallbackRender={() => fallback ?? <DefaultFallback />}
        >
            {children}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;
