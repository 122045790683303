import { graphql } from "@generated/client";
import {
    CreateObjectiveInput,
    ObjectivesFilter,
    UpdateObjectiveInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const getObjective = graphql(`
    query getObjective($id: String!) {
        objective(id: $id) {
            id
            name
            description
            actionPlan
            status
            entityId
            themeId
            subthemeId
            indicatorId
            indicator {
                id
                name
                unit {
                    shortName
                }
                datapoints {
                    id
                    value
                    datapointGroup {
                        id
                        entityId
                        period
                    }
                }
            }
            ownerId
            targets {
                id
                value
                status
                date
                source
            }
        }
    }
`);

const getObjectives = graphql(`
    query getObjectives($filter: ObjectivesFilter) {
        objectives(filter: $filter) {
            id
            entityId
            name
            description
            subthemeId
        }
    }
`);

const getObjectivesWithDetails = graphql(`
    query getObjectivesWithDetails($filter: ObjectivesFilter) {
        objectives(filter: $filter) {
            id
            entityId
            name
            status
            description
            actionPlan
            themeId
            theme {
                name
                color
            }
            subthemeId
            subtheme {
                name
            }
            owner {
                id
                firstName
                lastName
            }
            indicatorId
            indicator {
                id
                name
                unit {
                    shortName
                }
            }
            targets {
                id
                value
                status
                date
                source
            }
        }
    }
`);

const CreateObjectiveMutation = graphql(`
    mutation createObjective($input: CreateObjectiveInput!) {
        createObjective(input: $input) {
            id
        }
    }
`);

const ChooseIndicatorMutation = graphql(`
    mutation chooseIndicator($entityId: String!) {
        chooseIndicator(entityId: $entityId) {
            id
            name
            unitShortName
            themeId
            subthemeName
        }
    }
`);

const GenerateObjectiveMutation = graphql(`
    mutation generateObjective($entityId: String!, $indicatorId: String!) {
        generateObjective(entityId: $entityId, indicatorId: $indicatorId) {
            name
            description
            actionPlan
            targets {
                targetBoolean
                targetNumber
                targetString
                date
            }
        }
    }
`);

const CreateObjectivesMutation = graphql(`
    mutation createObjectives($input: [CreateObjectiveInput!]!) {
        createObjectives(input: $input) {
            id
        }
    }
`);

const UpdateObjectiveMutation = graphql(`
    mutation updateObjective($input: UpdateObjectiveInput!) {
        updateObjective(input: $input) {
            status
            error {
                code
                message
            }
            data {
                id
            }
        }
    }
`);

const UpdateObjectivesMutation = graphql(`
    mutation updateObjectives($inputs: [UpdateObjectiveInput!]!) {
        updateObjectives(inputs: $inputs) {
            status
            error {
                code
                message
            }
        }
    }
`);

const DeleteObjectiveMutation = graphql(`
    mutation deleteObjective($id: String!) {
        deleteObjective(id: $id) {
            status
            error {
                code
                message
            }
        }
    }
`);

const DeleteManyObjectivesMutation = graphql(`
    mutation deleteObjectives($ids: [String!]!) {
        deleteObjectives(ids: $ids) {
            status
            error {
                code
                message
            }
        }
    }
`);

export class GraphQLObjectiveRepository {
    getObjective = async (id: string) => {
        return graphqlClientKoyeb.request(
            getObjective,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getObjectives = async (filter?: ObjectivesFilter) => {
        return graphqlClientKoyeb.request(
            getObjectives,
            { filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getObjectivesWithDetails = async (filter?: ObjectivesFilter) => {
        return graphqlClientKoyeb.request(
            getObjectivesWithDetails,
            { filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    create = async (input: CreateObjectiveInput) => {
        return graphqlClientKoyeb.request(
            CreateObjectiveMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    chooseIndicator = async (entityId: string) => {
        return graphqlClientKoyeb.request(
            ChooseIndicatorMutation,
            { entityId },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    generate = async (input: { entityId: string; indicatorId: string }) => {
        return graphqlClientKoyeb.request(
            GenerateObjectiveMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createMany = async (input: CreateObjectiveInput[]) => {
        return graphqlClientKoyeb.request(
            CreateObjectivesMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    update = async (input: UpdateObjectiveInput) => {
        return graphqlClientKoyeb.request(
            UpdateObjectiveMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateMany = async (inputs: UpdateObjectiveInput[]) => {
        return graphqlClientKoyeb.request(
            UpdateObjectivesMutation,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    delete = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteObjectiveMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteMany = async (ids: string[]) => {
        return graphqlClientKoyeb.request(
            DeleteManyObjectivesMutation,
            { ids },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
