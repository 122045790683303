import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLSupplierRepository } from "@app/repositories/GraphQLRepositories/SupplierRepository";
import { questionnairesKeys } from "@app/screens/ExternalQuestionnaires/data";
import { type NewS3File } from "@app/usecases/FileUseCases";
import {
    SupplierTemplateQuestionFilterInput,
    SupplierTemplateQuestionUpdateInput,
} from "@generated/client/graphql";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { supplierKeys } from "../Suppliers/data";

const supplierRepository = new GraphQLSupplierRepository();

export const useSupplierTemplateQuestionnaire = (
    options: {
        pollingInterval?: number;
    } = {},
) => {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.templateQuestionnaire(),
        queryFn: supplierRepository.getSupplierTemplateQuestionnaire,
        ...(options.pollingInterval
            ? {
                  refetchInterval: options.pollingInterval,
              }
            : {}),
    });

    return {
        templateQuestionnaire: data?.supplierTemplate,
        ...query,
    };
};

export const useShouldPollTemplateQuestions = () => {
    const [pollingIntervalOptions, setPollingIntervalOptions] = useState<
        | {
              pollingInterval?: number;
          }
        | undefined
    >(undefined);
    const { templateQuestionnaire, refetch } = useSupplierTemplateQuestionnaire(
        pollingIntervalOptions,
    );

    useEffect(() => {
        const isGenerating = !!templateQuestionnaire?.isGenerating;
        setPollingIntervalOptions(
            isGenerating
                ? {
                      pollingInterval: 1000,
                  }
                : undefined,
        );
    }, [templateQuestionnaire?.isGenerating]);

    /**
     * We need to refetch the template questionnaire
     * on mount, just in case it is used in components
     * that are mounted before the last refetch
     */
    useEffect(() => {
        refetch();
    }, []);

    return !!templateQuestionnaire?.isGenerating;
};

export const useCreateSupplierTemplate = () => {
    const { mutate: createSupplierTemplate, ...mutation } = useMutation({
        mutationFn: supplierRepository.createSupplierTemplate,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.templateQuestionnaire(),
            });
        },
    });

    return {
        createSupplierTemplate,
        ...mutation,
    };
};

export const useUpdateSupplierTemplateQuestion = () => {
    const { mutate: updateQuestion, ...mutation } = useMutation({
        mutationFn: (input: {
            id: string;
            input: SupplierTemplateQuestionUpdateInput;
        }) =>
            supplierRepository.updateSupplierTemplateQuestion(
                input.id,
                input.input,
            ),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.templateQuestionsListAll(),
            });
        },
    });

    return {
        updateQuestion,
        ...mutation,
    };
};

export const useTemplateQuestions = (
    filter: SupplierTemplateQuestionFilterInput = {},
    pagination: {
        skip?: number;
        take?: number;
    } = {},
    options: {
        pollingInterval?: number;
    } = {},
) => {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.templateQuestionsList(filter, pagination),
        queryFn: () =>
            supplierRepository.getTemplateQuestions(filter, pagination),
        ...(options.pollingInterval
            ? {
                  refetchInterval: options.pollingInterval,
              }
            : {}),
    });

    return {
        templateQuestions: data?.supplierTemplateQuestions,
        ...query,
    };
};

export const useCreateTemplateQuestion = () => {
    const { mutate: createQuestion, ...mutation } = useMutation({
        mutationFn: supplierRepository.createSupplierTemplateQuestion,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.templateQuestionsListAll(),
            });
            // we need this because the question create modal is using some queries from the external questionnaires module
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
        },
    });

    return {
        createQuestion,
        ...mutation,
    };
};

export const useDeleteTemplateQuestion = () => {
    const { mutate: deleteQuestion, ...mutation } = useMutation({
        mutationFn: supplierRepository.deleteSupplierTemplateQuestion,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.templateQuestionsListAll(),
            });
            // we need this because the question create modal is using some queries from the external questionnaires module
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
        },
    });

    return {
        deleteQuestion,
        ...mutation,
    };
};

export const useCreateSupplierTemplateQuestionsFromFile = () => {
    const { mutateAsync: createQuestionsFromFileAsync, ...mutation } =
        useMutation({
            mutationFn: ({
                s3File: { signedUrl: _, ...s3File },
                tagIds,
            }: {
                s3File: NewS3File;
                tagIds: string[];
            }) =>
                supplierRepository.createSupplierTemplateQuestionsFromFile({
                    s3File,
                    tagIds,
                }),
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: supplierKeys.templateQuestionsListAll(),
                });
                queryClient.invalidateQueries({
                    queryKey: supplierKeys.templateQuestionnaire(),
                });
            },
        });

    return {
        createQuestionsFromFileAsync,
        ...mutation,
    };
};
