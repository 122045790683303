import { useSetToast } from "@app/components/Toast";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button, ButtonProps } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import {
    FlexCol,
    FlexRow,
    type OrientedFlexProps,
} from "@design-system/Layout/Flex";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    createContext,
    type FC,
    type PropsWithChildren,
    useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";

const Header: FC<
    TextProps & { title?: string; className?: string; isError?: boolean }
> = ({ title, className, isError, ...props }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const context = useContext(AiSuggestionContext);
    return (
        <FlexRow
            alignItems="center"
            justifyContent="between"
            gap="1"
            as="header"
            className={cn("p-4", className)}
        >
            <FlexRow gap="1">
                <Box
                    className="flex"
                    alignItems="center"
                    justifyContent="center"
                    w="5"
                    h="5"
                >
                    <Icon
                        name={isError ? "warning" : "stars"}
                        size="sm"
                        className={cn(
                            isError ? "text-[#DBA600]" : "text-accent-1-400",
                            context?.isLoading && "animate-pulse",
                        )}
                    />
                </Box>

                <Text
                    {...props}
                    variant="body-sm-bold"
                    className={cn(
                        isError
                            ? "text-primary"
                            : "bg-gradient-to-r from-[#C238CC] to-[#B554F2] bg-clip-text text-transparent",
                        context?.isLoading && "animate-pulse",
                    )}
                >
                    {title ?? t(keys.defaultTitle)}
                </Text>
            </FlexRow>

            {props.children}
        </FlexRow>
    );
};

const Body: FC<OrientedFlexProps> = ({ className, ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        context?.isOpen && (
            <FlexCol className={cn("px-4", className)} gap="5" {...props} />
        )
    );
};

const Block: FC<{
    title: string;
    content: string;
    withCopy?: boolean;
    withInsert?: boolean;
    handleInsertLinkClick?: () => void;
}> = ({ title, content, withCopy, withInsert, handleInsertLinkClick }) => {
    const { setToastSuccess } = useSetToast();
    const { t } = useTranslation(RESOURCE_NAME);
    const handleCopyLinkCLick = () => {
        navigator.clipboard.writeText(content);
        setToastSuccess(t(keys.copy_to_clipboard));
    };

    return (
        <FlexCol gap="2">
            <FlexRow alignItems="center" justifyContent="between">
                <Text
                    variant="body-sm-bold"
                    color="secondary"
                    className="uppercase"
                >
                    {title}
                </Text>
                <FlexRow gap="2">
                    {withCopy && (
                        <Box
                            onClick={handleCopyLinkCLick}
                            className="cursor-pointer text-primary"
                        >
                            <Tooltip>
                                <Tooltip.Trigger asChild>
                                    <Box>
                                        <Icon name="duplicate" size="sm" />
                                    </Box>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {t(keys.copy_to_clipboard_tooltip)}
                                </Tooltip.Content>
                            </Tooltip>
                        </Box>
                    )}
                    {withInsert && (
                        <Box
                            onClick={handleInsertLinkClick}
                            className="cursor-pointer text-primary"
                        >
                            <Tooltip>
                                <Tooltip.Trigger asChild>
                                    <Box>
                                        <Icon name="check" size="sm" />
                                    </Box>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {t(keys.insert_tooltip)}
                                </Tooltip.Content>
                            </Tooltip>
                        </Box>
                    )}
                </FlexRow>
            </FlexRow>

            <Text className="whitespace-pre-wrap" color="primary">
                {content}
            </Text>
        </FlexCol>
    );
};

const ReferenceBlock: FC<
    PropsWithChildren<OrientedFlexProps & { title: string }>
> = ({ children, title, ...props }) => {
    return (
        <FlexCol gap="3" {...props}>
            <Text
                variant="body-sm-bold"
                color="secondary"
                className="uppercase"
            >
                {title}
            </Text>
            {children}
        </FlexCol>
    );
};

const Footer: FC<OrientedFlexProps> = ({ className, ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        context?.isOpen && (
            <FlexRow
                {...props}
                as="footer"
                className={className}
                gap="4"
                p="4"
                justifyContent="end"
                w="full"
            />
        )
    );
};

const Confirm: FC<ButtonProps> = ({ ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        <Button
            {...props}
            variant="flatAccent"
            size="sm"
            disabled={context?.isLoading || props.disabled}
        />
    );
};

const Loader: FC<{
    progress: number;
    setProgress?: (progress: number) => void;
    maxProgress?: number;
    totalDuration?: number;
    slowdownFactor?: number;
}> = ({
    maxProgress = 100,
    totalDuration = 10000,
    slowdownFactor = 1,
    progress,
    setProgress,
}) => {
    const weights = Array.from(
        { length: maxProgress },
        (_, i) => (i / maxProgress) ** slowdownFactor,
    );
    const weightSum = weights.reduce((sum, w) => sum + w, 0);
    const delays = weights.map((w) => (w / weightSum) * totalDuration);

    const startLoader = (currentProgress: number) => {
        if (currentProgress >= maxProgress) return;

        setTimeout(() => {
            setProgress?.(currentProgress + 1);
            startLoader(currentProgress + 1);
        }, delays[currentProgress]);
    };

    if (progress === 0 && setProgress) startLoader(0);

    return (
        <FlexRow gap="2" w="full" alignItems="center">
            <progress
                className="w-full h-3 [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:bg-accent-1-300 [&::-moz-progress-bar]:bg-accent-1-300"
                value={progress}
                max="100"
            />
            <Box minW="6" alignItems="center" justifyContent="center">
                <Text variant="body-sm">{Math.round(progress)}%</Text>
            </Box>
        </FlexRow>
    );
};

type AiSuggestionType = FC<
    OrientedFlexProps & {
        isOpen: boolean;
        isLoading?: boolean;
        isError?: boolean;
    }
> & {
    Header: typeof Header;
    Body: typeof Body;
    Block: typeof Block;
    ReferenceBlock: typeof ReferenceBlock;
    Footer: typeof Footer;
    Confirm: typeof Confirm;
    Loader: typeof Loader;
};

const AiSuggestionContext = createContext<
    | {
          isOpen: boolean;
          isLoading?: boolean;
      }
    | undefined
>(undefined);

export const AiSuggestion: AiSuggestionType = ({
    className,
    isOpen,
    isLoading,
    isError,
    ...props
}) => {
    const borderColor = isError
        ? "bg-[#DBA600]"
        : "bg-gradient-to-r from-[#C238CC] to-[#B554F2]";

    const backgroundColor = isError ? "bg-[#FFF8CC]" : "bg-accent-1-50";
    return (
        <AiSuggestionContext.Provider value={{ isOpen, isLoading }}>
            <FlexCol
                className={cn(
                    borderColor,
                    "rounded-xl p-[1px] w-full",
                    className,
                )}
            >
                <FlexCol
                    {...props}
                    className={cn(
                        backgroundColor,
                        "rounded-xl max-h-[5000px] transition-max-height duration-500 ease-in-out overflow-hidden",
                        className,
                    )}
                    gap="1"
                />
            </FlexCol>
        </AiSuggestionContext.Provider>
    );
};

AiSuggestion.Header = Header;
AiSuggestion.Body = Body;
AiSuggestion.Block = Block;
AiSuggestion.ReferenceBlock = ReferenceBlock;
AiSuggestion.Footer = Footer;
AiSuggestion.Confirm = Confirm;
AiSuggestion.Loader = Loader;
