import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "IrrelevantDocumentsModal", {
    title: "Documents marked as not relevant",
    set_relevant: "Mark {{count}} as relevant",
    success_message: "Documents updated successfully",
    search_placeholder: "Search",
});

i18next.addResourceBundle("fr", "IrrelevantDocumentsModal", {
    title: "Documents définis comme non pertinents",
    set_relevant: "Définir {{count}} comme pertinent",
    success_message: "Documents mis à jour avec succès",
    search_placeholder: "Rechercher",
});
