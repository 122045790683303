import i18next from "@app/i18n";

const enResources = {
    apply: "Apply",
    default_title_placeholder: "Your objective title",
    no_subtheme_placeholder: "No subtheme",
    no_owner_placeholder: "Unassigned",
    no_target: "No target defined",
    objective_table_action_select_action: "Select an action",
    objective_table_action_selection: "Selection ({{count}})",
    objective_table_action_assign_owner: "Assign owner ({{count}})",
    objective_table_action_assign_status: "Assign status ({{count}})",
    objective_table_action_delete: "Delete ({{count}})",
    objective_table_action_assign_owner_action: "Assign owner",
    objective_table_action_assign_status_action: "Assign status",
    objective_table_action_delete_action: "Delete",
    header_name: "Objective",
    header_subtheme: "Subtheme",
    header_owner: "Owner",
    header_target: "Target",
    header_date: "Date",
    empty_state_initial_title: "No objective",
    empty_state_initial_subtitle: "Create an objective to get started",
    empty_state_search_title: "No objective found",
    empty_state_search_subtitle: "Try changing search parameters",
    update_success: "Objective updated",
    update_error: "Error updating objective",
    delete_objectives_modal_title: "Delete objectives",
    delete_objectives_modal_description_one:
        "Are you sure you want to delete {{count}} objective ?",
    delete_objectives_modal_description_other:
        "Are you sure you want to delete {{count}} objectives ?",

    delete_objectives_modal_confirm: "Delete",
    cancel: "Cancel",
    delete_success: "Objectives deleted successfully",
    delete_error: "Failed to delete objectives",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    apply: "Enregistrer",
    default_title_placeholder: "Votre objectif",
    no_subtheme_placeholder: "Aucun sous-thème",
    no_owner_placeholder: "Non attribué",
    no_target: "Aucun objectif défini",
    objective_table_action_select_action: "Choisir une action",
    objective_table_action_selection: "Sélection ({{count}})",
    objective_table_action_assign_owner: "Assigner un propriétaire ({{count}})",
    objective_table_action_assign_status: "Assigner un statut ({{count}})",
    objective_table_action_delete: "Supprimer ({{count}})",
    objective_table_action_assign_owner_action: "Assigner un propriétaire",
    objective_table_action_assign_status_action: "Assigner un statut",
    objective_table_action_delete_action: "Supprimer",
    header_name: "Objectif",
    header_subtheme: "Sous-thème",
    header_owner: "Propriétaire",
    header_target: "Chiffrage",
    header_date: "Date",
    empty_state_initial_title: "Pas d’objectif",
    empty_state_initial_subtitle: "Créez un objectif pour commencer",
    empty_state_search_title: "Aucun objectif trouvé",
    empty_state_search_subtitle: "Modifiez les paramètres de la recherche",
    update_success: "Objectif mis à jour",
    update_error: "Erreur lors de la mise à jour de l'objectif",
    delete_objectives_modal_title: "Supprimer les objectifs",
    delete_objectives_modal_description_one:
        "Êtes-vous sûr de vouloir supprimer {{count}} objectif ?",
    delete_objectives_modal_description_other:
        "Êtes-vous sûr de vouloir supprimer {{count}} objectifs ?",
    delete_objectives_modal_confirm: "Supprimer",
    cancel: "Annuler",
    delete_success: "Objectifs supprimés avec succès",
    delete_error: "Erreur lors de la suppression des objectifs",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectiveTable";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
