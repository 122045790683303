import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardGrid, FaqList } from "./utils";

const FaqFrameworkModal = ({
    frameworkName,
    frameworkDescription,
    logoUrl,
    faq,
    articleLink,
    demoLink,
    websiteLink,
}: {
    frameworkName: string;
    frameworkDescription: string;
    logoUrl: string;
    faq: {
        question: string;
        answer: string;
    }[];
    articleLink: string;
    demoLink: string;
    websiteLink: string;
}) => {
    const { t } = useTranslation("Frameworks");
    const [open, setOpen] = useState(false);
    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger asChild>
                <Button size="sm" variant="outlined" className="w-140 h-32">
                    {t("see_more")}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay centerContent className="py-5">
                <Modal.Content className="max-h-[90vh] overflow-y-auto">
                    <Modal.Header>
                        <FlexRow>
                            {logoUrl !== "logoURL" && (
                                <img
                                    src={logoUrl}
                                    alt={frameworkName}
                                    className="w-7 h-7 rounded-full left-0"
                                />
                            )}
                            <FlexCol className="px-6">
                                <Modal.Title className="text-neutral-700">
                                    {frameworkName}
                                </Modal.Title>
                                <Text
                                    variant="body"
                                    color="secondary"
                                    className="text-neutral-700 "
                                >
                                    {frameworkDescription}
                                </Text>
                            </FlexCol>
                        </FlexRow>
                        <Button
                            variant="text"
                            size="sm"
                            className="absolute right-4 top-4"
                            onClick={() => setOpen(false)}
                        >
                            <Icon name="close" />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Text variant="header2" className="text-neutral-700">
                            {t("access_framework")}
                        </Text>

                        <CardGrid
                            demoLink={demoLink}
                            articleLink={articleLink}
                            websiteLink={websiteLink}
                        />
                        {faq.length > 0 && (
                            <>
                                <Text
                                    variant="header2"
                                    className="text-neutral-700"
                                >
                                    {t("faq")}
                                </Text>
                                <FaqList faq={faq} />
                            </>
                        )}
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export { FaqFrameworkModal };
