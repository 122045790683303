import { Button } from "@design-system/Inputs/Button";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexRow } from "@design-system/Layout/Flex";
import { FormEvent, RefObject } from "react";
import { useQuestionInputTranslations } from "./i18n";

type QuestionInputProps = {
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    inputRef: RefObject<HTMLInputElement | null>;
    disabled?: boolean;
};

export const QuestionInput = ({
    onSubmit,
    inputRef,
    disabled = false,
}: QuestionInputProps) => {
    const { t, keys } = useQuestionInputTranslations();
    return (
        <form className="[all:unset] w-full" onSubmit={onSubmit}>
            <FlexRow w="full" className="flex h-14">
                <Textfield
                    placeholder={t(keys.ask_me_anything)}
                    type="text"
                    className="w-full"
                    ref={inputRef}
                />
                <Button
                    className="rounded-[120px] relative right-8 top-3 min-h-[32px] min-w-[32px]"
                    size="sm"
                    type="submit"
                    disabled={disabled}
                    variant="flatAccent"
                >
                    <Button.Icon name="arrowUp" />
                </Button>
            </FlexRow>
        </form>
    );
};
