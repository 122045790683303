import { useFrameworkPageState } from "@app/screens/Frameworks/framework/hooks/pageState";
import {
    canBeAccessed,
    isQuestionnaireFramework,
    releaseStatus,
} from "@app/screens/Frameworks/services";
import { currentUserIsBeavrAdminAtom } from "@app/store/userStore";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import chroma from "chroma-js";
import { useAtomValue } from "jotai";
import { memo, type FC } from "react";
import { Link } from "react-router-dom";
import { FrameworkCardProps, useFrameworkCard } from "./useFrameworkCard";
import { isCsrdFramework } from "./utils";

const FrameworkCardContent: FC<FrameworkCardProps> = memo(function CardContent({
    framework,
}) {
    const {
        betaOrReleased,
        headerClass,
        headerStyle,
        progress,
        subtitleClass,
        t,
        titleClass,
        wrapperClass,
        completionStats,
    } = useFrameworkCard({ framework });

    const currentUserIsBeavrAdmin = useAtomValue(currentUserIsBeavrAdminAtom);

    const shouldUseDatapointsStats =
        isCsrdFramework(framework) || isQuestionnaireFramework(framework.cmsId);

    const progressStart = shouldUseDatapointsStats
        ? (completionStats?.completed ?? 0)
        : framework.stats.reqOk;

    const progressEnd = shouldUseDatapointsStats
        ? (completionStats?.total ?? 0)
        : framework.stats.reqTotal;

    return (
        <FlexCol
            as="article"
            br="lg"
            className={wrapperClass}
            elevation="b-200"
            h="full"
        >
            {/* Header */}
            <FlexCol
                as="header"
                h="fit"
                py="6"
                px="4"
                className={cn(
                    headerClass,
                    currentUserIsBeavrAdmin && "relative",
                )}
                style={headerStyle}
            >
                <Text as="h6" variant="hero6" className={titleClass}>
                    {framework.name}
                </Text>
                <Text className={subtitleClass}>{framework.description}</Text>
            </FlexCol>

            {/* Body */}
            <FlexCol p="4" gap="2">
                {betaOrReleased ? (
                    <>
                        <Text variant="header2">
                            {framework.releaseStatus === releaseStatus.released
                                ? canBeAccessed(framework)
                                    ? `${progress.toFixed(0)}%`
                                    : t("available_sales")
                                : t("coming_soon_beta")}
                        </Text>
                        <Box w="full" py="1">
                            <ProgressBar
                                className="w-full rounded-sm"
                                segments={[
                                    {
                                        color: framework.color?.primary
                                            ? chroma(framework.color.primary)
                                            : chroma("#000"),
                                        value: progress,
                                        labelValue: `${progress.toFixed(0)}%`,
                                        label: t("datapoints_ok", {
                                            count: progressStart,
                                        }),
                                    },
                                ]}
                                total={100}
                                noDataPlaceholder={t("no_data")}
                            />
                        </Box>
                        <FlexRow justifyContent="between">
                            <Text>
                                {t(
                                    shouldUseDatapointsStats
                                        ? "datapoints_ok"
                                        : "reqs_ok",
                                    {
                                        count: progressStart,
                                    },
                                )}
                            </Text>
                            <Text>
                                {t(
                                    shouldUseDatapointsStats
                                        ? "datapoints_total"
                                        : "reqs_total",
                                    {
                                        count: progressEnd,
                                    },
                                )}
                            </Text>
                        </FlexRow>
                    </>
                ) : (
                    <Text variant="header2" color="secondary">
                        {framework.releaseStatus === releaseStatus.comingSoon
                            ? t("coming_soon_Q2_2025")
                            : t("coming_soon")}
                    </Text>
                )}
            </FlexCol>
        </FlexCol>
    );
});

export const FrameworkCard = ({ framework }: FrameworkCardProps) => {
    const { resetDefaultState } = useFrameworkPageState();

    const to = ["csrd", "evs-2023"].includes(framework.cmsId)
        ? framework.cmsId
        : framework.id;

    if (canBeAccessed(framework)) {
        return (
            <Link
                className="[all:unset] no-underline ![text-decoration:none]"
                onClick={resetDefaultState}
                to={to}
            >
                <FrameworkCardContent framework={framework} />
            </Link>
        );
    }
    return (
        <div>
            <FrameworkCardContent framework={framework} />
        </div>
    );
};

export { FrameworkCardSkeleton } from "./FrameworkCardSkeleton";
