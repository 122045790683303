import { Accordion } from "@design-system/DataDisplay/Accordion";
import {
    GetObjectivesWithDetailsQuery,
    GetThemesAndSubthemesQuery,
} from "@generated/client/graphql";
import { FC } from "react";
import { useThemeProgress } from "../hooks/useThemeProgress";
import { ObjectivesTable } from "./ObjectivesTable";
import { SubthemeObjectivesList } from "./SubthemeObjectivesList";
import { ThemeHeader } from "./ThemeHeader";

interface ThemeAccordionItemProps {
    theme: GetThemesAndSubthemesQuery["themes"][number];
    objectives: NonNullable<
        GetObjectivesWithDetailsQuery["objectives"]
    >[number][];
}

export const ThemeAccordionItem: FC<ThemeAccordionItemProps> = ({
    theme,
    objectives,
}) => {
    const { progress, completedSubthemes, totalSubthemes } = useThemeProgress(
        theme,
        objectives,
    );

    if (!theme.id) return null;

    return (
        <Accordion.Item
            value={theme.id}
            className="border bg-neutral-25 rounded-lg m-2 border-neutral-200"
        >
            <Accordion.Trigger className="w-full p-2">
                <ThemeHeader
                    theme={theme}
                    progress={progress}
                    completedSubthemes={completedSubthemes}
                    totalSubthemes={totalSubthemes}
                />
            </Accordion.Trigger>

            <Accordion.Content>
                <ObjectivesTable>
                    {theme.subthemes?.map((subtheme) => (
                        <SubthemeObjectivesList
                            key={subtheme.id}
                            subtheme={subtheme}
                            objectives={objectives}
                            themeColor={theme.color}
                        />
                    ))}
                </ObjectivesTable>
            </Accordion.Content>
        </Accordion.Item>
    );
};
