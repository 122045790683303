import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Text } from "@design-system/Typography/Text";
import { Trash, UserPlus } from "iconoir-react";
import {
    CreateUserInput,
    UserRole,
} from "../../../../../generated/client/graphql";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { Tooltip } from "../../../../components/Tooltip";
import InputSelectUserRole from "../../../../components/inputs/InputSelect/UserRole";
import ChooseLocale from "../../../../components/inputs_v2/ChooseLocale";
import { validateEmail } from "../../../../utils/emailRegex";
import { useCreateUsers } from "../../data";
import "./i18n";
import { getDepartmentsList } from "./services";
export const newUsersModalOpenAtom = atom<boolean>(false);

export default function NewUserModal() {
    const { t } = useTranslation("NewUserModal");

    const { mutate, isPending } = useCreateUsers();
    const [open, setOpen] = useAtom(newUsersModalOpenAtom);
    const [newUserInputs, setNewUserInputs] = useState<CreateUserInput[]>([
        { email: "", firstName: "", lastName: "", role: UserRole.Contributor },
    ]);
    const [locale, setLocale] = useState<string>();

    const canSave =
        newUserInputs.length > 0 &&
        locale &&
        newUserInputs.every((input) => {
            return (
                input.email &&
                validateEmail(input.email) &&
                input.firstName &&
                input.lastName &&
                input.role &&
                input.department
            );
        });
    const handleSave = () => {
        newUserInputs.length > 0 &&
            mutate(
                newUserInputs.map((u) => ({ locale, ...u })),
                {
                    onSuccess() {
                        setNewUserInputs([]);
                        setOpen(false);
                    },
                },
            );
    };
    const handlePlus = () => {
        const users = [...newUserInputs];
        users.push({
            email: "",
            firstName: "",
            lastName: "",
            role: UserRole.Contributor,
        });
        setNewUserInputs(users);
    };
    const handleMinus = (idx: number) => {
        const users = [...newUserInputs];
        users.splice(idx, 1);
        setNewUserInputs(users);
    };
    const handleChange = (idx: number, patch: Partial<CreateUserInput>) => {
        const users = [...newUserInputs];
        users[idx] = { ...users[idx], ...patch };
        setNewUserInputs(users);
    };

    const makeHandleDepartmentChange = (idx: number) => (value: string[]) => {
        if (!value.length) return;
        handleChange(idx, { department: value[0] });
    };

    const departments = getDepartmentsList();

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <Tooltip
                            tooltipContent={
                                canSave
                                    ? ""
                                    : newUserInputs.length == 0
                                      ? t("tooltip_help_no_user")
                                      : !locale
                                        ? t("tooltip_help_no_locale")
                                        : t("tooltip_help_wrong_user")
                            }
                        >
                            <ButtonLarge
                                text={t("create", {
                                    count: newUserInputs.length,
                                })}
                                variant={ButtonType.ELEVATED}
                                disabled={!canSave}
                                onClick={() => {
                                    handleSave();
                                }}
                                loading={isPending}
                            />
                        </Tooltip>
                    </>
                }
            >
                <div className="space-y-4">
                    {newUserInputs.map((user, idx) => (
                        <div className="flex space-x-3" key={idx}>
                            <InputSimple
                                id={`email-${idx}`}
                                title={t("email")}
                                className="w-[200px]"
                                value={user.email || ""}
                                onChange={(e) => {
                                    handleChange(idx, {
                                        email: e.target.value,
                                    });
                                }}
                            />
                            <InputSelectUserRole
                                value={user.role as string}
                                onValueChange={(role) => {
                                    handleChange(idx, {
                                        role: role as UserRole,
                                    });
                                }}
                            />
                            <InputSimple
                                id={`firstname-${idx}`}
                                title={t("firstname")}
                                className="w-[100px]"
                                onChange={(e) => {
                                    handleChange(idx, {
                                        firstName: e.target.value,
                                    });
                                }}
                            />
                            <InputSimple
                                id={`lastname-${idx}`}
                                title={t("lastname")}
                                className="w-[100px]"
                                onChange={(e) => {
                                    handleChange(idx, {
                                        lastName: e.target.value,
                                    });
                                }}
                            />
                            <Dropdown
                                value={[user.department ?? t("department")]}
                                onValueChange={makeHandleDepartmentChange(idx)}
                                selectType="single"
                            >
                                <Dropdown.Trigger asChild>
                                    <Button
                                        size="lg"
                                        variant="outlined"
                                        className="w-[350px]"
                                    >
                                        <Text
                                            variant="body"
                                            className="truncate"
                                        >
                                            {user.department
                                                ? t(user.department)
                                                : t("department")}
                                        </Text>
                                        <Button.Icon name="angleDown" />
                                    </Button>
                                </Dropdown.Trigger>
                                <Dropdown.Content
                                    className="w-[250px]"
                                    hasPortal={true}
                                >
                                    {departments.map((department) => (
                                        <Dropdown.SelectableItem
                                            key={department}
                                            value={department}
                                            className="text-sm"
                                        >
                                            {t(department)}
                                        </Dropdown.SelectableItem>
                                    ))}
                                </Dropdown.Content>
                            </Dropdown>

                            <ButtonLarge
                                IconLeft={Trash}
                                variant={ButtonType.TEXT}
                                onClick={() => {
                                    handleMinus(idx);
                                }}
                            />
                        </div>
                    ))}
                    <ButtonRegular
                        text={t("add_user")}
                        variant={ButtonType.TONAL}
                        IconRight={UserPlus}
                        onClick={handlePlus}
                    />
                    <div className="space-y-2">
                        <div className="text-regular">
                            {t("choose_language")}
                        </div>
                        <ChooseLocale
                            locale={locale}
                            setLocale={setLocale}
                            availableLocales={["en", "fr"]}
                        />
                    </div>
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
