import { cn } from "@design-system/Utilities";
import {
    type FC,
    type InputHTMLAttributes,
    type Ref,
    useImperativeHandle,
    useRef,
} from "react";

import { FieldWrapper, type SharedProps } from "./FieldWrapper";

export interface TextfieldProps
    extends InputHTMLAttributes<HTMLInputElement>,
        SharedProps {}
export const Textfield: FC<
    TextfieldProps & { ref?: Ref<HTMLInputElement> }
> = ({ className, hint, success, error, placeholder, ref, ...props }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    return (
        <FieldWrapper
            inputRef={inputRef}
            placeholder={placeholder}
            hint={hint}
            success={success}
            error={error}
            disabled={props.disabled}
            htmlFor={props.id}
            initialPlaceholderUp={!!props.value || !!props.defaultValue}
        >
            <input
                ref={inputRef}
                className={cn(
                    "bg-transparent w-full p-0 font-default text-base font-regular leading-[20px] border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500",
                    className,
                )}
                {...props}
            />
        </FieldWrapper>
    );
};
