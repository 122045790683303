import { Accordion } from "@design-system/DataDisplay/Accordion";
import { FlexCol } from "@design-system/Layout/Flex";
import { FC } from "react";
import { useThemes } from "../../data";
import { ThemeAccordionWrapper } from "./components/ThemeAccordionWrapper";

interface ObjectivesWorkPlanProps {
    entityId: string;
}

export const ObjectivesWorkPlan: FC<ObjectivesWorkPlanProps> = ({
    entityId,
}) => {
    const { themes } = useThemes();

    return (
        <FlexCol w="full" gap="3">
            <Accordion type="single" collapsible>
                {themes?.map(
                    (theme) =>
                        theme.id && (
                            <ThemeAccordionWrapper
                                key={theme.id}
                                theme={theme}
                                entityId={entityId}
                            />
                        ),
                )}
            </Accordion>
        </FlexCol>
    );
};
