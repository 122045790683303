import { useSetToast } from "@app/components/Toast";
import { browserDownload } from "@app/lib/utils-browser";
import { GraphQLQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/QuestionnaireRepository";
import { useCurrentUserLocale } from "@app/shared/utils/currentUserLocale";
import { isSupportedLocale, SupportedLocales } from "@app/shared/utils/locales";
import {
    tanstackUnitaryOptimisticUpdate,
    tanstackUnitaryOptimisticUpdateOptionsMaker,
} from "@app/shared/utils/optimisticUpdates";
import { type NewS3File } from "@app/usecases/FileUseCases";
import {
    GetQuestionnaireOverviewDataQuery,
    type GetQuestionsQuery,
    type QuestionCreateInput,
    type QuestionUpdateInput,
} from "@generated/client/graphql";
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { questionnairesKeys } from "../data";
import { questionnaireLocaleAtom } from "./ctx";
import { keys, RESOURCE_NAME } from "./i18n";
import {
    getQuestionnaireStats,
    isQuestionGenerating,
    optimisticUpdateQuestion,
} from "./services";
import { QuestionFilter, QuestionnaireFilter } from "./types";
const externalQuestionnairesRepository = new GraphQLQuestionnaireRepository();

export function useQuestionnaireHeaderData(id?: string) {
    const { data, ...query } = useQuery({
        queryKey: questionnairesKeys.headerData(id),
        queryFn: id
            ? () =>
                  externalQuestionnairesRepository.getQuestionnaireHeaderData(
                      id,
                  )
            : skipToken,
    });
    const stats = useMemo(() => {
        return getQuestionnaireStats(data?.questionnaire);
    }, [data?.questionnaire]);

    return { questionnaireHeaderData: data?.questionnaire, stats, ...query };
}

export function useQuestionnaireDetails(
    id?: string,
    opts?: { enabled?: boolean },
) {
    const { data: { questionnaire } = {}, ...query } = useQuery({
        queryKey: questionnairesKeys.details(id),
        queryFn: id
            ? () => externalQuestionnairesRepository.getQuestionnaireDetails(id)
            : skipToken,
        ...opts,
    });

    return { questionnaire: questionnaire ?? null, ...query };
}

export const useQuestionnaireLocale = (id?: string) => {
    const { questionnaireHeaderData } = useQuestionnaireHeaderData(id);
    const { availableLocales, default: defaultLocale } =
        questionnaireHeaderData?.locale ?? {
            availableLocales: [SupportedLocales.EN],
            default: SupportedLocales.EN,
        };

    const areAvailableLocalesSupported =
        availableLocales.every(isSupportedLocale);
    const isDefaultLocaleSupported = isSupportedLocale(defaultLocale);

    // Necessary controls, available locales are frontend-handled
    if (!isDefaultLocaleSupported) {
        throw new Error("Invalid default locale");
    }
    if (!areAvailableLocalesSupported) {
        throw new Error("Invalid available locales");
    }

    return {
        availableLocales,
        defaultLocale,
    };
};

export const useCreateQuestion = (questionnaireId?: string) => {
    const queryClient = useQueryClient();
    const { mutate: createQuestion, ...mutation } = useMutation({
        mutationFn: (question: QuestionCreateInput) =>
            externalQuestionnairesRepository.createQuestion(question),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { createQuestion, ...mutation };
};

export function useQuestionnaireOverviewData(filter: QuestionFilter) {
    const currentLocale = useAtomValue(questionnaireLocaleAtom);

    const { data: { questions } = {}, ...query } = useQuery({
        queryKey: questionnairesKeys.overViewData(
            filter.questionnaireId,
            currentLocale,
        ),
        queryFn: filter.questionnaireId
            ? () =>
                  externalQuestionnairesRepository.getQuestionnaireOverviewData(
                      {
                          questionnaireId: filter.questionnaireId,
                          groupId: filter.groupId,
                          searchString: filter.searchString,
                      },
                      currentLocale,
                  )
            : skipToken,
        ...(filter.opts?.pollingInterval
            ? {
                  refetchInterval: filter.opts.pollingInterval,
              }
            : {}),
    });
    return {
        questions: questions?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)),
        ...query,
    };
}

export function useQuestionnaireGenerationState(filter: QuestionnaireFilter) {
    const { data: { questionnaire } = {}, ...query } = useQuery({
        queryKey: questionnairesKeys.generationState(filter.questionnaireId),
        queryFn: filter.questionnaireId
            ? () =>
                  externalQuestionnairesRepository.getQuestionnaireGenerationState(
                      filter.questionnaireId ?? "",
                  )
            : skipToken,
        ...(filter.opts?.pollingInterval
            ? {
                  refetchInterval: filter.opts.pollingInterval,
              }
            : {}),
    });
    return {
        questionnaire,
        ...query,
    };
}

export function useQuestions(filter: QuestionFilter) {
    const currentLocale = useAtomValue(questionnaireLocaleAtom);

    const { data: { questions } = {}, ...query } = useQuery({
        queryKey: questionnairesKeys.questionsFiltered(
            filter.questionnaireId,
            currentLocale,
            filter.searchString,
            filter.groupId,
            filter.ownerIds,
            filter.statuses,
            filter.aiAnswerStatuses,
        ),
        queryFn: filter.questionnaireId
            ? () =>
                  externalQuestionnairesRepository.getQuestions(
                      filter,
                      currentLocale,
                  )
            : skipToken,
    });
    return {
        questions: questions?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)),
        ...query,
    };
}

export function useQuestion(questionnaireId?: string, id?: string) {
    const { data: { question } = {}, ...query } = useQuery({
        queryKey: questionnairesKeys.question(questionnaireId, id),
        queryFn: id
            ? () => externalQuestionnairesRepository.getQuestion(id)
            : skipToken,
    });
    return { question, ...query };
}

export function useDeleteQuestion(questionnaireId?: string) {
    const client = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { mutate: deleteQuestion, ...mutation } = useMutation({
        mutationFn: externalQuestionnairesRepository.deleteQuestion,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () =>
                questionnairesKeys.overViewData(questionnaireId, currentLocale),
            (id: string, previousQuery: GetQuestionnaireOverviewDataQuery) => {
                return {
                    ...previousQuery,
                    questions: previousQuery.questions.filter(
                        (q) => q.id !== id,
                    ),
                };
            },
        ),
        onSettled: () => {
            client.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { deleteQuestion, ...mutation };
}

export function useQuestionUpdate(questionnaireId: string, id?: string) {
    const queryClient = useQueryClient();
    const { mutate: updateQuestion, ...mutation } = useMutation({
        mutationFn: ({
            id,
            input,
        }: {
            id: string;
            input: QuestionUpdateInput;
        }) => externalQuestionnairesRepository.updateQuestion(id, input),
        onMutate: async (variables) => {
            Promise.all([
                tanstackUnitaryOptimisticUpdate(
                    variables,
                    () => questionnairesKeys.question(questionnaireId, id),
                    optimisticUpdateQuestion,
                ),
            ]);
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { updateQuestion, ...mutation };
}

export function useQuestionAssignmentUpdate(questionnaireId?: string) {
    const queryClient = useQueryClient();
    const { mutate: updateQuestion, ...mutation } = useMutation({
        mutationFn: ({
            id,
            input,
        }: {
            id: string;
            input: QuestionUpdateInput;
        }) => externalQuestionnairesRepository.updateQuestion(id, input),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { updateQuestion, ...mutation };
}

export function useQuestionsUpdate(questionnaireId: string) {
    const queryClient = useQueryClient();
    const { mutate: updateQuestions, ...mutation } = useMutation({
        mutationFn: ({
            ids,
            input,
        }: {
            ids: string[];
            input: QuestionUpdateInput;
        }) => externalQuestionnairesRepository.updateQuestions(ids, input),
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => questionnairesKeys.overViewData(questionnaireId),
            (
                { ids, input }: { ids: string[]; input: QuestionUpdateInput },
                previousQuery: GetQuestionnaireOverviewDataQuery,
            ) => {
                const questions = previousQuery.questions.map((question) => {
                    if (ids.includes(question.id)) {
                        return {
                            ...question,
                            ...input,
                        } as GetQuestionsQuery["questions"][number];
                    }

                    return question;
                });

                return {
                    ...previousQuery,
                    questions,
                };
            },
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { updateQuestions, ...mutation };
}

export function useDeleteQuestions(questionnaireId: string) {
    const queryClient = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { mutate: deleteQuestions, ...mutation } = useMutation({
        mutationFn: externalQuestionnairesRepository.deleteQuestions,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () =>
                questionnairesKeys.overViewData(questionnaireId, currentLocale),
            (
                ids: string[],
                previousQuery: GetQuestionnaireOverviewDataQuery,
            ) => {
                return {
                    ...previousQuery,
                    questions: previousQuery.questions.filter(
                        (q) => !ids.includes(q.id),
                    ),
                };
            },
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { deleteQuestions, ...mutation };
}

export const useImportQuestionsFromFile = (questionnaireId?: string) => {
    const queryClient = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { setToastError } = useSetToast();
    const { t } = useTranslation(RESOURCE_NAME);

    const { mutateAsync: importQuestionsFromFileAsync, ...mutation } =
        useMutation({
            mutationFn: ({
                questionnaireId,
                s3File: { signedUrl: _, ...s3File },
            }: {
                questionnaireId: string;
                s3File: NewS3File;
            }) =>
                externalQuestionnairesRepository.importQuestionsFromFile(
                    questionnaireId,
                    { s3File },
                ),
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: questionnairesKeys.overViewData(
                        questionnaireId,
                        currentLocale,
                    ),
                });
            },
            onError: () => {
                setToastError(t(keys.import_questions_from_file_error));
            },
        });

    return { importQuestionsFromFileAsync, ...mutation };
};

export const useGenerateAiSuggestions = (questionnaireId?: string) => {
    const queryClient = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { mutate: generateAiSuggestions, ...mutation } = useMutation({
        mutationFn: externalQuestionnairesRepository.generateAiSuggestions,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.overViewData(
                    questionnaireId,
                    currentLocale,
                ),
            });
        },
    });

    return { generateAiSuggestions, ...mutation };
};

export const useShouldPollQuestions = (questionnaireId?: string) => {
    const { questions } = useQuestionnaireOverviewData({ questionnaireId });
    const questionsGenerating = questions?.filter((q) =>
        isQuestionGenerating(q),
    );

    return !!questionsGenerating?.length;
};

export const useShouldPollQuestionnaire = (questionnaireId?: string) => {
    const { questionnaire } = useQuestionnaireGenerationState({
        questionnaireId,
    });

    return questionnaire?.isGenerating ?? false;
};

export const useExportQuestionnaire = () => {
    const userLocale = useCurrentUserLocale(SupportedLocales.EN);
    const currentLocale = useAtomValue(questionnaireLocaleAtom) ?? userLocale;

    return useMutation({
        mutationFn: ({ questionnaireId }: { questionnaireId: string }) =>
            externalQuestionnairesRepository.export(
                questionnaireId,
                currentLocale?.toString() ?? "",
            ),
        onSettled: (res) => {
            if (res?.exportQuestionnaire?.data?.signedUrl) {
                browserDownload(res.exportQuestionnaire.data.signedUrl, true);
            }
        },
    });
};
