import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { SupplierBody } from "./components/SupplierBody";
import { SupplierHeader } from "./components/SupplierHeader";

export const SupplierPage = () => {
    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                <SupplierHeader />
                <SupplierBody />
            </FlexCol>
        </PageContainer>
    );
};
