import { SupportedLocales } from "@app/shared/utils/locales";
import { atom } from "jotai";
import { StringParam } from "use-query-params";

export const questionnaireParamsArgs = {
    group: ["group", StringParam] as const,
    tab: ["tab", StringParam] as const,
    questionId: ["questionId", StringParam] as const,
} as const;

//We need to know if we get on a question from a click in the table or from scrolling:
//when you scroll from a question to another, the queryParam questionId changes
//in order to be able to scroll, then send the link of a question to someone and get the right scroll.
//If you don't have the condition on "LastUrlUpdateByClick", the scroll will look weird
//(stick to the top of each question you pass by).
export const lastUrlUpdateByClickTimestampAtom = atom<Date | undefined>(
    new Date(new Date().getTime() + 5000),
);

export const questionnaireLocaleAtom = atom<SupportedLocales | undefined>(
    undefined,
);
