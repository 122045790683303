import { GraphQLUnitRepository } from "@app/repositories/GraphQLRepositories/UnitRepository";
import { useQuery } from "@tanstack/react-query";
import { unitKeys } from "./queryKeys";

const unitRepository = new GraphQLUnitRepository();

export const useUnits = () => {
    const { data, isPending } = useQuery({
        queryKey: [unitKeys.units],
        queryFn: unitRepository.getAll,
    });

    return {
        units: data?.units,
        isPending,
    };
};
