import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { TagsMultiSelect } from "@app/shared/components/TagsMultiSelect";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import {
    Document,
    DocumentActivationStatus,
    TagType,
} from "../../../../../../../generated/client/graphql";
import UserSelect from "../../../../../../components/Dropdown/dropdowns/User";
import Floatbar from "../../../../../../components/Floatbar";
import { useUpdateDocuments } from "../../../../hooks";
import "./i18n";

enum Actions {
    ASSIGN_OWNER = "ASSIGN_OWNER",
    MARK_NOT_RELEVANT = "MARK_NOT_RELEVANT",
    ASSIGN_TAGS = "ASSIGN_TAGS",
}
const ActionBar = ({
    documents,
    unset,
}: {
    documents: Document[];
    unset: () => void;
}) => {
    const { t } = useTranslation("DocumentTableSavebar");

    const [activeAction, setActiveAction] = useState<Actions>();

    const [ownerId, setOwnerId] = useState<string | null>();

    const [tagsIds, setTagsIds] = useState<(string | null)[]>();

    const { mutate, isPending } = useUpdateDocuments();

    const onOwnerApply = () => {
        mutate(
            documents.map((d) => d.id),
            { assignedToId: ownerId },
            unset,
        );
    };

    const onRelevanceApply = () => {
        mutate(
            documents.map((d) => d.id),
            { activationStatus: DocumentActivationStatus.NotRelevant },
            unset,
        );
    };

    const onTagsApply = () => {
        mutate(
            documents.map((d) => d.id),
            { tagIds: tagsIds?.filter(Boolean) as string[] },
            unset,
        );
    };

    const onSelectedActionValueChange = (value: string) => {
        setActiveAction(value as Actions);
    };

    useEffect(() => {
        setActiveAction(undefined);
    }, []);
    return (
        <Floatbar
            unset={unset}
            open={documents.length > 0}
            primary={<></>}
            secondary={
                <Select
                    value={activeAction}
                    onValueChange={onSelectedActionValueChange}
                >
                    <Select.Trigger asChild>
                        <Button size="sm" variant="outlined">
                            {t("document_table_savebar_actions")}
                            <Icon size="sm" name="angleDown" />
                        </Button>
                    </Select.Trigger>
                    <Select.Content>
                        <Select.Item value={Actions.ASSIGN_OWNER}>
                            <Icon
                                size="sm"
                                name="users"
                                className="text-green"
                            />
                            {t("set_owner_button")}
                        </Select.Item>
                        <Select.Item value={Actions.MARK_NOT_RELEVANT}>
                            <Icon
                                size="sm"
                                name="eyeOff"
                                className="text-green"
                            />
                            {t("mark_not_relevant")}
                        </Select.Item>
                        <Select.Item value={Actions.ASSIGN_TAGS}>
                            <Icon size="sm" name="tag" className="text-green" />
                            {t("set_tags_button")}
                        </Select.Item>
                    </Select.Content>
                </Select>
            }
            actions={[
                {
                    id: Actions.ASSIGN_OWNER,
                    text: t("set_owner_text", { count: documents.length }),
                    selector: (
                        <UserSelect
                            selectedUserId={ownerId || undefined}
                            onChange={(id) => setOwnerId(id || null)}
                        />
                    ),
                    apply: (
                        <Button
                            size="sm"
                            loading={isPending}
                            onClick={onOwnerApply}
                            disabled={ownerId === undefined}
                        >
                            {t("apply")}
                        </Button>
                    ),
                },
                {
                    id: Actions.MARK_NOT_RELEVANT,
                    text: t("document_table_action_mark_not_relevant", {
                        count: documents.length,
                    }),
                    apply: (
                        <Button
                            size="sm"
                            onClick={onRelevanceApply}
                            loading={isPending}
                        >
                            {t("apply")}
                        </Button>
                    ),
                },
                {
                    id: Actions.ASSIGN_TAGS,
                    text: t("set_tags_text", { count: documents.length }),
                    selector: (
                        <TagsMultiSelect
                            tagsIds={tagsIds}
                            setTagsIds={setTagsIds}
                            type={TagType.Document}
                            side="top"
                        />
                    ),
                    apply: (
                        <Button
                            size="sm"
                            onClick={onTagsApply}
                            loading={isPending}
                            disabled={!tagsIds?.length}
                        >
                            {t("apply")}
                        </Button>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default ActionBar;
