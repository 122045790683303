import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { TagType } from "@generated/client/graphql";
import { FC, useEffect, useRef, useState } from "react";
import { useDeleteTag, useUpdateTag } from "./data";

const TagDropdownItem: FC<{
    tagId: string;
    tagName: string;
    tagType: TagType;
    checked: boolean | undefined;
    onToggle: (id: string) => void;
}> = ({ tagId, tagName, checked, onToggle, tagType }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newTagName, setNewTagName] = useState<string>(tagName);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { mutate } = useUpdateTag(tagId);
    const { mutate: mutateDelete } = useDeleteTag(tagId, tagType);
    const handleNewTagNameChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setNewTagName(e.target.value?.trimStart());
        textareaRef.current?.focus();
    };

    const handleSaveNewTagName = () => {
        if (!newTagName) {
            mutateDelete(tagId, {
                onSettled: () => setIsEditing(false),
            });
        }
        if (newTagName === tagName) return;
        mutate(newTagName, {
            onSettled: () => {
                if (checked) {
                    onToggle(tagId);
                }
                setIsEditing(false);
            },
        });
    };

    const onEditClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsEditing(true);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            textareaRef.current?.blur();
            handleSaveNewTagName();
        }
        if (e.key === "Escape") {
            e.preventDefault();
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            textareaRef.current?.focus();
        }
    }, [isEditing]);

    return (
        <>
            {isEditing ? (
                <FlexRow
                    h="9"
                    px="0"
                    py="0.5"
                    alignItems="center"
                    justifyContent="center"
                    className="relative rounded-lg focus:outline-none"
                >
                    <textarea
                        value={newTagName}
                        onChange={handleNewTagNameChange}
                        onKeyDown={handleKeyDown}
                        ref={textareaRef}
                        className="h-full w-full resize-none rounded-lg border-secondary focus:border-secondary focus:ring-0"
                    ></textarea>
                    <Button
                        variant={newTagName ? "flatPrimary" : "flatDanger"}
                        size="xs"
                        className="absolute right-2"
                        disabled={newTagName === tagName}
                        onClick={handleSaveNewTagName}
                    >
                        {newTagName ? (
                            <Icon name="arrowRight" size="xs" />
                        ) : (
                            <Icon name="trash" size="xs" />
                        )}
                    </Button>
                </FlexRow>
            ) : (
                <Dropdown.SelectableItem
                    key={tagId}
                    value={tagId}
                    onClick={() => onToggle(tagId)}
                >
                    <Dropdown.Checkbox checked={checked} />
                    <Dropdown.ItemText>
                        <Ellipsis asChild>
                            <span>{tagName}</span>
                        </Ellipsis>
                    </Dropdown.ItemText>
                    <Button variant="text" size="xs" onClick={onEditClick}>
                        <Icon name="edit" size="xs" />
                    </Button>
                </Dropdown.SelectableItem>
            )}
        </>
    );
};

export default TagDropdownItem;
