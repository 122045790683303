import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-archived-questionnaires-table";

const keys = addTranslations(RESOURCE_NAME, {
    archived_questionnaires: {
        en: "Archived questionnaires",
        fr: "Questionnaires archivés",
    },
    progress: {
        en: "Progress:",
        fr: "Progression:",
    },
    sent: {
        en: "Sent:",
        fr: "Envoyé:",
    },
    sent_by: {
        en: "Sent by:",
        fr: "Envoyé par:",
    },
    copy_link: {
        en: "Copy link to questionnaire",
        fr: "Copier le lien vers le questionnaire",
    },
    copy_link_success: {
        en: "Link copied to clipboard",
        fr: "Lien copié dans le presse-papiers",
    },
    copy_link_error: {
        en: "Failed to copy link",
        fr: "Erreur lors de la copie du lien",
    },
});

export function useQuestionnairesTableTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
