import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import { useMutation } from "@tanstack/react-query";

const DocumentRepository = new GraphQLDocumentRepository();

export const useCreateDocument = () => {
    return useMutation({
        mutationFn: DocumentRepository.createCustomDocument,
    });
};
