import i18next from "@app/i18n";

export const RESOURCE_NAME = "CommitmentBlock";

i18next.addResources("en", RESOURCE_NAME, {
    title_commitment_block: "Commitment and targets",
    subtitle_commitment_block:
        "Committing to objectives is essential in your policies. You need to define and commit to 2-3 quantitative objectives for each material sub-theme.",
    button_commitment_block: "Go to objectives",
    subtheme_name: "Commitment",
    objectives_count: "Defined objectives",
    progression: "Progression",
    insufficient_progression: "Insufficient",
    weak_progression: "Weak",
    good_progression: "Good",
    excellent_progression: "Excellent",
    go_to_objectives: "Go to objectives",
    see_objectives_tooltip: "See objectives",
    title_commitment_no_results: "No commitment found",
    no_commitment_found_subtitle:
        "You have not yet committed to any objectives. Commit to 2-3 objectives for each material sub-theme to start.",
    insufficient_tooltip:
        "This subtheme has no objective attached. You must write 2-3 objectives per subtheme to create a solid policy.",
    weak_tooltip:
        "This subtheme has only one objective attached. It is recommended to write 2-3 objectives per subtheme for a solid policy.",
    good_tooltip:
        "This subtheme has two objectives attached. Adding one more objective would make your policy more comprehensive.",
    excellent_tooltip:
        "Great job! This subtheme has {{count}} objectives attached. This meets the ideal standard for an outstanding policy.",
});
i18next.addResources("fr", RESOURCE_NAME, {
    title_commitment_block: "Engagement et objectifs",
    subtitle_commitment_block:
        "Il est essentiel de s'engager sur des objectifs dans le cadre de vos politiques. Vous devez définir et vous engager sur 2 ou 3 objectifs quantitatifs pour chaque sous-thème matériel.",
    button_commitment_block: "Aller aux objectifs",
    subtheme_name: "Commitment",
    objectives_count: "Objectives définis",
    progression: "Progression",
    insufficient_progression: "Insuffisant",
    weak_progression: "Faible",
    good_progression: "Bon",
    excellent_progression: "Excellent",
    go_to_objectives: "Aller aux objectifs",
    see_objectives_tooltip: "Voir les objectifs",
    title_commitment_no_results: "Aucun engagement trouvé",
    no_commitment_found_subtitle:
        "Vous n'avez pas encore aucun objectif associé à cette politique. Commencez par créer 2 à 3 objectifs pour chaque sous-thème.",
    insufficient_tooltip:
        "Ce sous-thème n'a aucun objectif associé. Vous devez rédiger 2 à 3 objectifs par sous-thème pour créer une politique solide.",
    weak_tooltip:
        "Ce sous-thème n'a qu'un seul objectif associé. Il est recommandé de rédiger 2 à 3 objectifs par sous-thème pour une politique solide.",
    good_tooltip:
        "Ce sous-thème a deux objectifs associés. Ajouter un objectif supplémentaire rendrait votre politique plus complète.",
    excellent_tooltip:
        "Bravo ! Ce sous-thème comporte {{count}} objectifs associés. Cela correspond au standard idéal pour une excellente politique.",
});
