import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";

const GetAiThreadsQuery = graphql(`
    query GetAiThreads {
        aiThreads {
            id
            openAiThreadId
            updatedAt
        }
    }
`);

const GetAiThreadQuery = graphql(`
    query GetAiThread($id: String!) {
        aiThread(id: $id) {
            id
            openAiThreadId
            updatedAt
            runStatus
            messages {
                openAiId
                role
                content {
                    text {
                        value
                        annotations {
                            text
                            openAiFileId
                        }
                    }
                }
            }
        }
    }
`);

const CreateThreadMutation = graphql(`
    mutation CreateAiThread {
        createAiThread {
            openAiThreadId
            updatedAt
            id
        }
    }
`);

export class GraphQLAiRepository {
    createThread = async () => {
        return graphqlClientKoyeb.request(
            CreateThreadMutation,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getUserThreads = async () => {
        return graphqlClientKoyeb.request(
            GetAiThreadsQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getThread = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetAiThreadQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
