import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-last-questionnaire-box";

const keys = addTranslations(RESOURCE_NAME, {
    last_questionnaire: {
        en: "Last questionnaire",
        fr: "Dernier questionnaire",
    },
    progress: {
        en: "Progress:",
        fr: "Progression:",
    },
    sent: {
        en: "Sent:",
        fr: "Envoyé:",
    },
    due_date: {
        en: "Due date:",
        fr: "Date limite:",
    },
    sent_by: {
        en: "Sent by:",
        fr: "Envoyé par:",
    },
    answered: {
        en: "Answered:",
        fr: "Répondu:",
    },
    close: {
        en: "Close",
        fr: "Fermer",
    },
    questionnaire_closed: {
        en: "Questionnaire closed successfully",
        fr: "Questionnaire fermé avec succès",
    },
    questionnaire_closed_error: {
        en: "Error closing questionnaire",
        fr: "Erreur lors de la fermeture du questionnaire",
    },
    empty_state_title: {
        en: "This supplier does not have any open questionnaires",
        fr: "Aucun questionnaire ouvert trouvé",
    },
    copy_link: {
        en: "Copy link to questionnaire",
        fr: "Copier le lien vers le questionnaire",
    },
    copy_link_success: {
        en: "Link copied to clipboard",
        fr: "Lien copié dans le presse-papiers",
    },
    copy_link_error: {
        en: "Failed to copy link",
        fr: "Erreur lors de la copie du lien",
    },
});

export function useLastQuestionnaireBoxTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
