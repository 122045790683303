import i18next from "@app/i18n";

const enResources = {
    modal_title: "Generate Objective",
    title: "AI generated objective",
    title_loading: "Generating objective...",
    regenerate: "Regenerate objective",
    template_warning:
        "[This content was generated by AI. It is an example and should be reviewed and adapted before use.] \n",
    ai_explanation: "AI explanation",
    suggested_indicators:
        "Suggested indicators - pick one to build an objective on",
    choosing_indicator: "Identifying some relevant indicators...",
    indicator: "Indicator",
    description: "Description",
    action_plan: "Action plan",
    targets: "Targets",
    targets_disclosure:
        "The AI-generated targets may not be accurate. Please validate and adjust them as needed.",
    create_button: "Create objective",
    indicator_description:
        "These indicators were selected because they are attached to subthemes on which your objective coverage can be improved: {{- subthemeNames}}.",
    confirm_button: "Generate objective",
    cancel_button: "Cancel",
    no_indicator_found:
        "We couldn't find any suitable indicators for this entity. Please provide additional reporting data and try again.",
    go_to_reporting: "Go to Reporting",
    toast_success: "Objective created successfully",
    toast_error_unknown: "An error occurred while creating the objective",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    modal_title: "Générer un objectif",
    title: "Objectif généré par l'IA",
    title_loading: "Génération de l'objectif...",
    regenerate: "Régénérer l'objectif",
    template_warning:
        "[Ce contenu a été généré par l'IA. Il s'agit d'un exemple et doit être examiné et adapté avant utilisation.] \n",
    ai_explanation: "Explication de l'IA",
    suggested_indicators: "Indicateurs suggérés",
    choosing_indicator: "Recherche d'indicateurs pertinents...",
    indicator: "Indicateur",
    description: "Description",
    action_plan: "Plan d'action",
    targets: "Objectifs",
    targets_disclosure:
        "Les objectifs générés par l'IA peuvent ne pas être précis. Vous devrez les valider et les modifier ultérieurement.",
    create_button: "Créer l'objectif",
    indicator_description:
        "Ces indicateurs ont été sélectionnés car ils sont attachés à des sous-thèmes sur lesquels votre couverture d'objectif peut être améliorée : {{- subthemeNames}}.",
    confirm_button: "Générer l'objectif",
    cancel_button: "Annuler",
    no_indicator_found:
        "Nous n'avons pas trouvé d'indicateurs appropriés pour cette entité. Veuillez fournir des données de reporting supplémentaires et réessayer.",
    go_to_reporting: "Aller à la section Reporting",
    toast_success: "Objectif créé avec succès",
    toast_error_unknown:
        "Une erreur est survenue lors de la création de l'objectif",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "GenerateObjectiveModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
