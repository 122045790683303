import EntitySelect from "@app/shared/components/EntitySelect";
import { useEntities } from "@app/store/entityStore";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexRow } from "@design-system/Layout/Flex";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { activeEntityIdAtom, activeTabAtom } from "../../ctx";
import { keys, RESOURCE_NAME } from "./i18n";

export const ObjectivesPageTopbar = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { entities } = useEntities();
    const [activeEntityId, setActiveEntityId] = useAtom(activeEntityIdAtom);
    const [urlEntity, setUrlEntity] = useQueryParam("entity", StringParam);
    const activeTab = useAtomValue(activeTabAtom);

    // Initialize the entity
    useEffect(() => {
        if (
            !!entities?.length &&
            (!activeEntityId ||
                !entities.map((e) => e.id).includes(activeEntityId))
        ) {
            const urlMatchedEntity = entities.find(
                (entity) => entity.name === urlEntity,
            );
            const defaultEntity = entities.find((entity) => !!entity.isDefault);
            setActiveEntityId(
                urlMatchedEntity
                    ? urlMatchedEntity.id
                    : defaultEntity
                      ? defaultEntity.id
                      : entities[0].id,
            );
        }
    }, [entities, setActiveEntityId]);

    // Update the url entity
    useEffect(() => {
        const entity = entities?.find((entity) => entity.id === activeEntityId);
        if (entity) setUrlEntity(entity.name);
    }, [entities, activeEntityId]);

    const [urlSearchString, setUrlSearchString] = useQueryParam(
        "search",
        StringParam,
    );
    return (
        <FlexRow alignItems="center" justifyContent="start" w="full" gap="4">
            {!!entities?.length && (
                <EntitySelect
                    entityId={activeEntityId}
                    setEntityId={setActiveEntityId}
                />
            )}
            {activeTab === "list" && (
                <SearchBar
                    searchString={urlSearchString || undefined}
                    setSearchString={setUrlSearchString}
                    placeholder={t(keys.search_placeholder)}
                    className="w-[210px]"
                />
            )}
        </FlexRow>
    );
};
