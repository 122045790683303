import { Header } from "@app/shared/components/BackgroundedHeader";
import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Gauge } from "@design-system/DataDisplay/Gauge";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import { type FC } from "react";
import { type Stats } from "../../types";
import { useSupplierQuestionnaireHeaderTranslations } from "./i18n";

type QuestionnaireHeaderProps = {
    title: string;
    stats: Stats;
    locale: {
        availableLocales: SupportedLocales[];
        currentLocale: SupportedLocales;
        onLocaleChange: (locale: SupportedLocales) => void;
    };
    loading?: boolean;
};

export const QuestionnaireHeader: FC<QuestionnaireHeaderProps> = ({
    title,
    stats,
    locale,
}) => {
    const { t, keys } = useSupplierQuestionnaireHeaderTranslations();

    return (
        <Header bgColor="#FAFAFA" className="border border-tertiary rounded-xl">
            <Header.LeftColumn className="pl-6">
                <FlexCol gap="2" h="full" pt="2" pb="6">
                    <LocaleSelect
                        value={locale.currentLocale}
                        onChange={locale.onLocaleChange}
                        options={locale.availableLocales}
                        triggerButtonProps={{
                            variant: "text",
                        }}
                    />
                    <FlexCol gap="2" pl="2">
                        <Header.Title color="primary">{title}</Header.Title>
                        <Header.Description color="primary">
                            {t(keys.description_default)}
                        </Header.Description>
                    </FlexCol>
                </FlexCol>
            </Header.LeftColumn>
            <Header.RightColumn>
                <FlexCol h="full" w="full" justifyContent="center" p="5">
                    <FlexRow alignItems="center" justifyContent="between">
                        <Text color="primary" variant="header2">
                            {stats.answered.percentage}%
                        </Text>
                    </FlexRow>
                    <Gauge>
                        <Gauge.List>
                            <Gauge.Item
                                value={stats.answered.percentage}
                                color={chroma.hex("#03A365")}
                                label={t(keys.stats_answered, {
                                    count: stats.answered.count,
                                })}
                            />
                            <Gauge.Item
                                value={100 - stats.answered.percentage}
                                color={chroma.hex("#E6E6E6")}
                                label={t(keys.stats_not_answered, {
                                    count:
                                        stats.total.count -
                                        stats.answered.count,
                                })}
                            />
                        </Gauge.List>
                    </Gauge>
                    <FlexRow justifyContent="between">
                        <Text color="primary">
                            {t(keys.stats_answered, {
                                count: stats.answered.count,
                            })}
                        </Text>
                        <Text color="primary">
                            {t(keys.stats_total, {
                                count: stats.total.count,
                            })}
                        </Text>
                    </FlexRow>
                </FlexCol>
            </Header.RightColumn>
        </Header>
    );
};
