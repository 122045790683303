import i18next from "@app/i18n";

const enResources = {
    page_title: "Questionnaires",
    search_bar_placeholder: "Search questionnaires",
    page_subtitle: "Manage your company’s external questionnaires",
    table_header_name: "Questionnaire",
    table_header_requester: "Requester's email",
    table_header_company: "Requested by",
    table_header_progress: "Completion",
    table_header_created_by: "Created by",
    table_header_deadline: "Due date",
    create_questionnaire_modal_title: "Create questionnaire",
    create_questionnaire_modal_subtitle_templates: "Start from a template",
    create_questionnaire_modal_subtitle_from_scratch:
        "Build your own questionnaire",
    create_questionnaire_modal_trigger_label: "New questionnaire",
    create_questionnaire_from_template_modal_trigger_label:
        "Create with a template",
    create_questionnaire_modal_cancel_label: "Cancel",
    create_questionnaire_modal_confirm_label: "Create",
    create_questionnaire_modal_tooltip_no_name:
        "You must add a name, chose the available locales and the default one",
    create_questionnaire_modal_name_placeholder: "Questionnaire name",
    create_questionnaire_modal_description_placeholder: "Description",
    create_questionnaire_modal_requester_email_placeholder: "Requester email",
    create_questionnaire_modal_company_name_placeholder: "Company name",
    create_questionnaire_modal_deadline_placeholder: "Deadline",
    create_questionnaire_modal_locale_placeholder:
        "Questionnaire default language",

    delete_questionnaire_modal_title: "Delete questionnaire",
    delete_questionnaire_modal_description:
        "Are you sure you want to delete this questionnaire?",
    delete_questionnaire_modal_confirm: "Delete",
    cancel: "Cancel",
    set_a_deadline: "Set a deadline",
    select_template: "Select template",
    start: "Start",
    build_my_own: "Build my own",
    available_soon: "Available soon",
    empty_state_title: "No questionnaire found",
    empty_state_subtitle: "Create a new questionnaire to get started",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    page_title: "Questionnaires externes",
    search_bar_placeholder: "Rechercher un questionnaire",
    page_subtitle: "Gérez les questionnaires externes de votre entreprise",
    table_header_name: "Questionnaire",
    table_header_requester: "Email du demandeur",
    table_header_company: "Demandé par",
    table_header_progress: "Progression",
    table_header_created_by: "Créé par",
    table_header_deadline: "Date limite",
    create_questionnaire_modal_title: "Créer un questionnaire",
    create_questionnaire_modal_subtitle_templates:
        "Commencer à partir d'un modèle",
    create_questionnaire_modal_subtitle_from_scratch:
        "Créer un questionnaire personnalisé",
    create_questionnaire_modal_trigger_label: "Nouveau questionnaire",
    create_questionnaire_from_template_modal_trigger_label:
        "Créer avec un modèle",
    create_questionnaire_modal_cancel_label: "Annuler",
    create_questionnaire_modal_confirm_label: "Créer",
    create_questionnaire_modal_tooltip_no_name:
        "Vous devez ajouter un nom, choisir les langues disponibles et la langue par défaut",
    create_questionnaire_modal_name_placeholder: "Nom du questionnaire",
    create_questionnaire_modal_description_placeholder: "Description",
    create_questionnaire_modal_requester_email_placeholder:
        "Email du demandeur",
    create_questionnaire_modal_company_name_placeholder: "Nom de l'entreprise",
    create_questionnaire_modal_deadline_placeholder: "Date limite",
    create_questionnaire_modal_locale_placeholder:
        "Langue par défaut du questionnaire",
    delete_questionnaire_modal_title: "Supprimer le questionnaire",
    delete_questionnaire_modal_description:
        "Êtes-vous sûr de vouloir supprimer ce questionnaire ?",
    delete_questionnaire_modal_confirm: "Supprimer",
    cancel: "Annuler",
    set_a_deadline: "Définir une date limite",
    select_template: "Sélectionner un modèle",
    start: "Commencer",
    build_my_own: "Créer mon propre questionnaire",
    available_soon: "Bientôt disponible",
    empty_state_title: "Aucun questionnaire trouvé",
    empty_state_subtitle: "Créez un nouveau questionnaire pour commencer",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "ExternalQuestionnaires";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
