import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-questions-page";

const keys = addTranslations(RESOURCE_NAME, {
    title: {
        en: "Questions",
        fr: "Questions",
    },
    subTitle: {
        en: "Manage your questions and send them to suppliers",
        fr: "Gérez vos questions et envoyez-les aux fournisseurs",
    },
    addQuestionBtn: {
        en: "Add Question",
        fr: "Ajouter une question",
    },
});

export function useSupplierQuestionsPageTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
