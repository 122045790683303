import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLObjectiveRepository } from "@app/repositories/GraphQLRepositories/ObjectiveRepository";
import { GraphQLVersionRepository } from "@app/repositories/GraphQLRepositories/VersionRepository";
import { objectiveKeys } from "@app/screens/Objectives/data";
import { versionKeys } from "@app/store/queryKeys";
import { ObjectivesFilter } from "@generated/client/graphql";
import { useMutation, useQuery } from "@tanstack/react-query";

const objectiveRepository = new GraphQLObjectiveRepository();
const versionRepository = new GraphQLVersionRepository();
export const useObjectivesWithDetails = (filter?: ObjectivesFilter) => {
    const { data, isPending } = useQuery({
        queryKey: [
            ...objectiveKeys.all(),
            "filterWithDetails",
            filter,
        ] as const,
        queryFn: () => objectiveRepository.getObjectivesWithDetails(filter),
    });
    return { objectives: data?.objectives, isPending };
};

export const useCheckDocument = (versionId: string) => {
    const { mutate: checkDocument, ...mutation } = useMutation({
        mutationFn: () => versionRepository.aiCheck(versionId),
        mutationKey: [...versionKeys.aiCheck(versionId)],
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [versionKeys.versionByDocumentURL],
            });
        },
    });
    return { checkDocument, ...mutation };
};
