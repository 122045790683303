import { useThreads } from "@app/screens/Document/Edit/hooks/useComments";
import {
    EditorMode,
    EditorModeType,
    RegularTabType,
    RegularTabs,
    useEditorContext,
} from "@app/screens/Document/Edit/hooks/useEditor";
import {
    versionChecksAtom,
    versionChecksRatioAtom,
} from "@app/store/versionStore";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";

export function useSidebarHeader() {
    const {
        editorMode,
        setEditorMode,
        regularModeActiveTab,
        setRegularModeActiveTab,
        commentModeActiveTab,
        setCommentModeActiveTab,
        showValidationChecklist,
        version,
    } = useEditorContext();

    const { t } = useTranslation("DocumentEditorSideBar");
    const { activeThreads } = useThreads();
    const activeThreadsNumber = activeThreads.length;
    const {
        data: { versionChecks },
    } = useAtomValue(versionChecksAtom);
    const {
        data: { ratio },
    } = useAtomValue(versionChecksRatioAtom);

    const checkBadgeVariant =
        ratio.num > 0.3 ? ("success" as const) : ("error" as const);
    const hasActiveThreads = !!activeThreadsNumber;

    const handleValueChange = (value: string) => {
        if (!value) {
            return;
        }
        if (isEditorMode(value)) {
            return setEditorMode(value);
        }

        if (isRegularTab(value)) {
            return setRegularModeActiveTab(value);
        }
    };

    return {
        editorMode,
        handleValueChange,
        activeThreadsNumber,
        hasActiveThreads,
        showValidationChecklist,
        checkBadgeVariant,
        totalChecks: versionChecks.length,
        checksRatio: ratio.str,
        t,
        RegularTabs,
        commentModeActiveTab,
        regularModeActiveTab,
        setEditorMode,
        setCommentModeActiveTab,
        withEditor: !!version?.withEditor,
        version,
    };
}

function isEditorMode(value: string): value is EditorModeType {
    return Object.values(EditorMode).includes(value as EditorModeType);
}

function isRegularTab(value: string): value is RegularTabType {
    return Object.values(RegularTabs).includes(value as RegularTabType);
}
