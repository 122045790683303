import { useSelect } from "@design-system/internal/select";
import { Box, type BoxProps } from "@design-system/Layout/Box";
import {
    createContext,
    useContext,
    useMemo,
    type FC,
    type PropsWithChildren,
    type Ref,
} from "react";
import { useGroupStyles } from "../RadioGroup/styles";
import { Field, Label } from "../RadioGroup";
import { Checkbox as CheckboxComponent, type CheckboxProps } from "../Checkbox";

const CtxType = createContext<{
    selected: string[];
    onSelect: (value: string) => void;
}>({
    selected: [],
    onSelect: () => {},
});

const Checkbox: FC<CheckboxProps & { value: string }> = ({
    checked: checkedProp,
    onChange,
    value,
    ...props
}) => {
    const { selected, onSelect } = useContext(CtxType);
    const handleChange = (checked: boolean) => {
        onChange?.(checked);
        onSelect(value);
    };

    const checked = useMemo(
        () =>
            typeof checkedProp === "boolean"
                ? checkedProp
                : selected.includes(value),
        [selected, value, checkedProp],
    );

    return (
        <CheckboxComponent
            {...props}
            checked={checked}
            onChange={handleChange}
        />
    );
};

interface CheckboxGroupProps extends Omit<BoxProps, "onChange"> {
    defaultValue?: string[];
    onChange?: (value: string[]) => void;
    value?: string[];
    ref?: Ref<HTMLDivElement>;
}

type CheckboxGroupComponent = FC<PropsWithChildren<CheckboxGroupProps>> & {
    Field: typeof Field;
    Label: typeof Label;
    Checkbox: typeof Checkbox;
};

export const CheckboxGroup: CheckboxGroupComponent = ({
    onChange,
    value: valueProp,
    defaultValue,
    className,
    ...props
}) => {
    const { handleSelect, value } = useSelect({
        defaultValue,
        onValueChange: onChange,
        selectType: "multi",
        value: valueProp,
    });
    const styles = useGroupStyles(className);
    return (
        <CtxType.Provider
            value={{ selected: value ?? [], onSelect: handleSelect }}
        >
            <Box {...props} className={styles} />
        </CtxType.Provider>
    );
};

CheckboxGroup.Field = Field;
CheckboxGroup.Label = Label;
CheckboxGroup.Checkbox = Checkbox;
