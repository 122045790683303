import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-send-questionnaire-modal";

const keys = addTranslations(RESOURCE_NAME, {
    send_new_questionnaire_button: {
        en: "Send new questionnaire",
        fr: "Envoyer un nouveau questionnaire",
    },
    modal_title: {
        en: "Assign a questionnaire",
        fr: "Assigner un questionnaire",
    },
    select_questionnaire_placeholder: {
        en: "Select a questionnaire",
        fr: "Sélectionner un questionnaire",
    },
    cancel_button: {
        en: "Cancel",
        fr: "Annuler",
    },
    send_button: {
        en: "Send",
        fr: "Envoyer",
    },
});

export function useSendQuestionnaireModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
