import Dashboard from "@app/pages/Dashboard";
import {
    Link,
    Navigate,
    Outlet,
    RouteObject,
    useParams,
} from "react-router-dom";
import Profile from "../screens/Profile/index.tsx";

import { ReactComponent as BarrierIllustration } from "@app/assets/illustrations/barrier.svg";
import { ReactComponent as LockIllustration } from "@app/assets/illustrations/lock.svg";
import ErrorPage from "@app/shared/components/ErrorPage";
import "@app/shared/components/ErrorPage/i18n.tsx";

import ReportingV2 from "@app/pages/Reporting/index.tsx";
import ReportingProgress from "@app/pages/ReportingProgress/index.tsx";
import Requirements from "@app/pages/Requirements/index.tsx";
import AllFiles from "@app/screens/AllFiles/index.tsx";
import { ExternalQuestionnaires } from "@app/screens/ExternalQuestionnaires";
import { ExternalQuestionnairePage } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire";
import { Frameworks } from "@app/screens/Frameworks";
import { CsrdGeneral } from "@app/screens/Frameworks/framework/Csrd/index.tsx";
import { Pillar } from "@app/screens/Frameworks/framework/Csrd/pillar/index.tsx";
import Framework from "@app/screens/Frameworks/framework/index.tsx";
import TopBar from "@app/screens/Global/TopBar/index.tsx";
import Objectives from "@app/screens/Objectives/index.tsx";
import { ObjectivePage } from "@app/screens/Objectives/objective/index.tsx";
import { Questions } from "@app/screens/Supplier/Questions/index.tsx";
import { SupplierPage } from "@app/screens/Supplier/Suppliers/supplier/index.tsx";
import ErrorBoundary from "@app/shared/components/ErrorBoundary/index.tsx";
import { useSyncDocumentURL } from "@app/shared/utils/storeRouterSync.ts";
import { useTranslation } from "react-i18next";
import { DocumentType, UserRole } from "../../generated/client/graphql.ts";
import AdminNavbar from "../components/AdminNavbar/index.tsx";
import Background from "../components/Background/index.tsx";
import Loading from "../components/Loading/index.tsx";
import EditDocument from "../screens/Document/Edit/index.tsx";
import Document from "../screens/Document/index.tsx";
import Documents2 from "../screens/Documents2/index.tsx";
import General from "../screens/Settings/General/index.tsx";
import Load from "../screens/Settings/Load/index.tsx";
import Sites from "../screens/Settings/Sites/index.tsx";
import Units from "../screens/Settings/Units/index.tsx";
import SupplierDatapoints from "../screens/Supplier/Datapoints/index.tsx";
import Questionnaires from "../screens/Supplier/Questionnaires/index.tsx";
import Supplier from "../screens/Supplier/Supplier/index.tsx";
import Suppliers from "../screens/Supplier/Suppliers/index.tsx";
import SustainabilityCenterDashboard from "../screens/SustainabilityCenter/Dashboard/index.tsx";
import Themes from "../screens/Themes/index.js";
import Users from "../screens/Users/index.tsx";
import {
    adminPaths,
    documentPaths,
    reportingPaths,
    settingPaths,
    supplierPaths,
} from "./adminPaths.tsx";
import { isEditMode, useNonPublicRoutes } from "./utils.ts";

export const CustomerChurnedErrorPage = ({
    onCTAClick,
}: {
    onCTAClick: () => void;
}) => {
    const { t } = useTranslation("ErrorPage");

    return (
        <ErrorPage>
            <ErrorPage.Header />
            <ErrorPage.Body>
                <ErrorPage.Image Image={LockIllustration} />
                <ErrorPage.Text
                    text={t("customer_churned.status_message")}
                    type="errorMessage"
                />
                <ErrorPage.Text
                    text={t("customer_churned.description")}
                    type="errorDescription"
                />
                <ErrorPage.CTA onClick={onCTAClick}>
                    {t("customer_churned.cta")}
                </ErrorPage.CTA>
            </ErrorPage.Body>
        </ErrorPage>
    );
};

export const InternalServerErrorPage = ({
    onCTAClick,
}: {
    onCTAClick: () => void;
}) => {
    const { t } = useTranslation("ErrorPage");
    return (
        <ErrorPage>
            <ErrorPage.Header />
            <ErrorPage.Body>
                <ErrorPage.Image Image={LockIllustration} />
                <ErrorPage.Text
                    text={t("access_denied.status_code")}
                    type="errorCode"
                />
                <ErrorPage.Text
                    text={t("access_denied.status_message")}
                    type="errorMessage"
                />
                <ErrorPage.Text
                    text={t("access_denied.description")}
                    type="errorDescription"
                />
                <ErrorPage.CTA onClick={onCTAClick}>
                    {t("go_to_homepage")}
                </ErrorPage.CTA>
            </ErrorPage.Body>
        </ErrorPage>
    );
};

export const GeneralErrorPage = ({
    onCTAClick,
}: {
    onCTAClick: () => void;
}) => {
    const { t } = useTranslation("ErrorPage");
    return (
        <ErrorPage>
            <ErrorPage.Header />
            <ErrorPage.Body>
                <ErrorPage.Image Image={BarrierIllustration} />
                <ErrorPage.Text
                    text={t("maintenance.status_message")}
                    type="errorMessage"
                />
                <ErrorPage.Text
                    text={t("maintenance.description")}
                    type="errorDescription"
                />
                <ErrorPage.CTA onClick={onCTAClick}>
                    {t("copy_error_message")}
                </ErrorPage.CTA>
            </ErrorPage.Body>
        </ErrorPage>
    );
};

export const AdminLayout = () => {
    const params = useParams();
    const {
        copyErrorToClipboard,
        currentUserRole,
        error,
        goToHomepage,
        handleChurnedErrorPageCTA,
        isLoading,
        isRefetching,
        orgIsLoading,
        showCustomerChurnedErrorPage,
        showInternalServerErrorPage,
    } = useNonPublicRoutes();

    useSyncDocumentURL();

    if (showCustomerChurnedErrorPage) {
        return (
            <CustomerChurnedErrorPage onCTAClick={handleChurnedErrorPageCTA} />
        );
    }

    if (isLoading || isRefetching || orgIsLoading) {
        return <Loading size="large" />;
    }
    if (
        currentUserRole &&
        [UserRole.Admin, UserRole.BeavrAdmin, UserRole.Contributor].includes(
            currentUserRole,
        )
    ) {
        return (
            <div className="min-h-screen antialiased">
                {isEditMode() ? (
                    <Outlet />
                ) : (
                    <div className="flex">
                        <AdminNavbar />
                        <div className="min-h-screen w-full min-w-[960px]">
                            <Background
                                topBar={<TopBar />}
                                hasBgBars={!params.document_id}
                            >
                                <ErrorBoundary>
                                    <Outlet />
                                </ErrorBoundary>
                            </Background>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (showInternalServerErrorPage) {
        return <InternalServerErrorPage onCTAClick={goToHomepage} />;
    }

    if (error) {
        return <GeneralErrorPage onCTAClick={copyErrorToClipboard} />;
    }

    return <></>;
};

const settingRoutes: RouteObject[] = [
    {
        path: settingPaths.general,
        element: <General />,
    },
    {
        path: settingPaths.themes,
        element: <Themes />,
    },
    {
        path: settingPaths.sites,
        element: <Sites />,
    },
    {
        path: settingPaths.users,
        element: <Users />,
    },
    {
        path: settingPaths.units,
        element: <Units />,
    },
    {
        path: "load",
        element: <Load />,
    },
];

const reportingRoutes: RouteObject[] = [
    {
        index: true,
        path: "*",
        element: <Navigate to={"./" + reportingPaths.overview} />,
    },
    {
        index: true,
        path: reportingPaths.overview,
        element: <ReportingV2 />,
    },
    {
        index: true,
        path: reportingPaths.progress,
        element: <ReportingProgress />,
    },
];

const supplierRoutes: RouteObject[] = [
    {
        path: supplierPaths.questionnaires,
        element: <Questionnaires />,
    },
    {
        path: supplierPaths.questions,
        element: <Questions />,
    },
    {
        path: supplierPaths.suppliers,
        children: [
            {
                index: true,
                element: <Suppliers />,
            },
            {
                path: supplierPaths.supplier_id_param,
                element: <SupplierPage />,
            },
        ],
    },
    {
        path: supplierPaths.datapoints,
        element: <SupplierDatapoints />,
    },
    {
        path: supplierPaths.supplier_id_param,
        element: <Supplier />,
    },
];

const documentRoute: RouteObject = {
    path: documentPaths.document_id_param,
    element: <Outlet />,
    children: [
        {
            index: true,
            element: <Document />,
            handle: {
                crumb: Link,
                page: documentPaths.document_id_param,
            },
        },
        {
            path: "edit",

            element: <EditDocument />,
        },
    ],
};

export const adminRoutes: RouteObject[] = [
    {
        index: true,
        element: <Dashboard />,
    },
    {
        path: adminPaths.policies,
        handle: {
            crumb: Link,
            page: adminPaths.policies,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.Policy} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.objectives,
        handle: {
            crumb: Link,
            page: adminPaths.objectives,
        },
        children: [
            { index: true, element: <Objectives /> },
            {
                path: adminPaths.objective_id_param,
                element: <ObjectivePage />,
                handle: {
                    crumb: Link,
                    page: adminPaths.objective_id_param,
                },
            },
        ],
    },
    {
        path: adminPaths.measures,
        handle: {
            crumb: Link,
            page: adminPaths.measures,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.Procedure} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.custom_documents,
        handle: {
            crumb: Link,
            page: adminPaths.custom_documents,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.CustomDocument} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.all_files,
        children: [
            {
                index: true,
                element: <AllFiles />,
            },
        ],
    },
    {
        path: adminPaths.settings,
        children: settingRoutes,
    },
    {
        path: adminPaths.profile,
        element: <Profile />,
    },
    {
        path: adminPaths.reporting,
        children: reportingRoutes,
    },
    {
        path: adminPaths.supplier,
        children: supplierRoutes,
    },
    {
        path: adminPaths.requirements,
        element: <Requirements />,
    },
    {
        path: adminPaths.frameworks,
        handle: {
            crumb: Link,
            page: adminPaths.frameworks,
        },
        children: [
            { index: true, element: <Frameworks /> },
            {
                path: adminPaths.framework_csrd,
                handle: {
                    crumb: Link,
                    page: adminPaths.framework_csrd,
                },
                children: [
                    {
                        path: adminPaths.framework_csrd_pillar_id_param,
                        element: <Pillar />,
                        handle: {
                            crumb: Link,
                            page: adminPaths.framework_csrd_pillar_id_param,
                        },
                    },
                    {
                        index: true,
                        path: "*",
                        element: <CsrdGeneral />,
                    },
                ],
            },
            {
                path: adminPaths.framework_id_param,
                element: <Framework />,
                handle: {
                    crumb: Link,
                    page: adminPaths.framework_id_param,
                },
            },
        ],
    },
    {
        path: adminPaths.sustainability_center,
        element: <SustainabilityCenterDashboard />,
    },
    {
        path: adminPaths.external_questionnaires,
        handle: {
            crumb: Link,
            page: adminPaths.external_questionnaires,
        },
        children: [
            {
                index: true,
                element: <ExternalQuestionnaires />,
            },
            {
                path: adminPaths.external_questionnaire_id_param,
                children: [
                    {
                        index: true,
                        element: <ExternalQuestionnairePage />,
                    },
                ],
                handle: {
                    crumb: Link,
                    page: adminPaths.external_questionnaire_id_param,
                },
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="." />,
    },
];
