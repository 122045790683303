import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "objective-work-plan";

const keys = addTranslations(RESOURCE_NAME, {
    empty_subtheme_state: {
        en: "No objective defined for this subtheme yet",
        fr: "Aucun objectif défini pour ce sous-thème",
    },
    objective: {
        en: "Objective",
        fr: "Objectif",
    },
    owner: {
        en: "Owner",
        fr: "Propriétaire",
    },
    due_date: {
        en: "Due date",
        fr: "Date d'échéance",
    },
    target: {
        en: "Target",
        fr: "Cible",
    },
    create_objective: {
        en: "Create objective",
        fr: "Créer un objectif",
    },
    subtheme_completed: {
        en: "Subthemes completed: {{completed}}/{{total}}",
        fr: "Sous-thèmes terminés: {{completed}}/{{total}}",
    },
    objectives_completed: {
        en: "Objectives completed:",
        fr: "Objectifs terminés:",
    },
    update_success: {
        en: "Objective updated",
        fr: "Objectif mis à jour",
    },
    update_error: {
        en: "Error updating objective",
        fr: "Erreur lors de la mise à jour de l'objectif",
    },
    no_owner_placeholder: {
        en: "Unassigned",
        fr: "Non assigné",
    },
});
export function useObjectiveWorkPlanTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
