import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import * as Progress from "@radix-ui/react-progress";
import { Color } from "chroma-js";
import { Circle } from "lucide-react";
import { type FC } from "react";
import { Tooltip } from "../Tooltip";

export type Segment = {
    color?: Color;
    value: number;
    label: string;
    labelValue: string;
};
export type ProgressBarProps = {
    segments: Segment[];
    noDataPlaceholder?: string;
    className?: ClassValue;
    total?: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({
    segments,
    noDataPlaceholder,
    className,
    total,
}) => {
    total = total || segments.reduce((acc, segment) => acc + segment.value, 0);

    return (
        <FlexRow alignItems="center" gap="2">
            {total === 0 ? (
                <Text>{noDataPlaceholder}</Text>
            ) : (
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Progress.Root
                            className={cn(
                                "relative overflow-hidden bg-tertiary rounded-full w-[90px] h-[8px]",
                                className,
                            )}
                            style={{
                                transform: "translateZ(0)",
                            }}
                        >
                            {segments.map((segment, index) => {
                                const value = segment.value / total;
                                const offset =
                                    segments
                                        .slice(0, index)
                                        .reduce(
                                            (acc, segment) =>
                                                acc + segment.value,
                                            0,
                                        ) / total;

                                return (
                                    <Progress.Indicator
                                        key={index}
                                        className={cn(
                                            `absolute h-full`,
                                            segment.color?.css()
                                                ? ""
                                                : "bg-beavrGreen ",
                                        )}
                                        style={{
                                            ...(segment.color
                                                ? {
                                                      backgroundColor:
                                                          segment.color.css(),
                                                  }
                                                : {}),
                                            width: `${value * 100}%`,
                                            left: `${offset * 100}%`,
                                        }}
                                    />
                                );
                            })}
                        </Progress.Root>
                    </Tooltip.Trigger>
                    <Tooltip.Content className="p-4">
                        {segments.map((segment, index) => (
                            <FlexRow key={index} gap="2">
                                <Circle color={segment.color?.css()} />
                                <Text>{segment.label}</Text>
                                <Text>{segment.labelValue}</Text>
                            </FlexRow>
                        ))}
                    </Tooltip.Content>
                </Tooltip>
            )}
        </FlexRow>
    );
};
