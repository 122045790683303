import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "AddDocumentModal", {
    title: "Add document",
    subtitle:
        "Upload, store and share all the documents associated with your CSR or impact strategy.",
    document_name: "Document name",
    document_description: "Document description",
    owner: "Owner",
    document_create_success: "Document created successfully",
    document_create_error: "Document creation error",
    document_name_required: "Document name is required",
    document_name_already_taken: "Document name is already taken",
});

i18next.addResourceBundle("fr", "AddDocumentModal", {
    title: "Ajouter un document",
    subtitle:
        "Téléchargez, stockez et partagez tous les documents associés à votre stratégie RSE.",
    document_name: "Nom du document",
    document_description: "Description du document",
    owner: "Propriétaire",
    document_create_success: "Document créé avec succès",
    document_create_error: "Erreur lors de la création du document",
    document_name_required: "Le nom du document est requis",
    document_name_already_taken: "Le nom du document est déjà pris",
});
