import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { QuestionUpdateInput } from "@generated/client/graphql";
import { useState, type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteQuestion, useQuestionUpdate } from "../../../../data";
import { keys, RESOURCE_NAME } from "../../../../i18n";

import { QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { useAnswerDelete } from "../../../QuestionsTab/data";
import { QuestionModal } from "../QuestionModal";

export const EditOrDeleteDropdown: FC<
    PropsWithChildren<{
        questionId?: string;
        questionAnswer?: QuestionnaireOverviewData[number]["answer"];
        canAnswer?: boolean;
        questionnaireId: string;
    }>
> = ({
    questionId,
    questionAnswer,
    canAnswer = true,
    questionnaireId,
    children,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const deleteQuestionMutation = useDeleteQuestion(questionnaireId);
    const updateQuestionMutation = useQuestionUpdate(
        questionnaireId,
        questionId,
    );
    const answerDeleteMutation = useAnswerDelete(questionnaireId);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const openEditModalOnClick = () => {
        setOpenEditModal(true);
    };

    const openDeleteModalOnClick = () => {
        setOpenDeleteModal(true);
    };

    const deleteAnswer = () => {
        if (!questionAnswer?.id) return;
        answerDeleteMutation.deleteAnswer(questionAnswer.id);
    };

    const deleteQuestion = () => {
        if (!questionId) return;
        deleteQuestionMutation.deleteQuestion(questionId);
    };
    const updateQuestion = (input: QuestionUpdateInput) => {
        if (!questionId) return;
        updateQuestionMutation.updateQuestion({
            id: questionId,
            input,
        });
    };

    return (
        <Box display="grid" className="place-items-center">
            <Dropdown>
                <Dropdown.Trigger asChild>{children}</Dropdown.Trigger>
                <Dropdown.Content hasPortal>
                    {questionId && (
                        <Dropdown.Item onClick={openEditModalOnClick}>
                            <Dropdown.ItemIcon name="edit" />
                            <Dropdown.ItemText>
                                {t(keys.table_actions_dd_edit)}
                            </Dropdown.ItemText>
                        </Dropdown.Item>
                    )}
                    {canAnswer && questionId && questionAnswer && (
                        <Dropdown.Item onClick={deleteAnswer}>
                            <Dropdown.ItemIcon name="refresh" />
                            <Dropdown.ItemText>
                                {t(keys.table_actions_dd_delete_answer)}
                            </Dropdown.ItemText>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        variant="negative"
                        onClick={openDeleteModalOnClick}
                    >
                        <Dropdown.ItemIcon name="trash" />
                        <Dropdown.ItemText>
                            {t(keys.table_actions_dd_delete)}
                        </Dropdown.ItemText>
                    </Dropdown.Item>
                </Dropdown.Content>
            </Dropdown>
            <Modal open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
                <Modal.Overlay centerContent>
                    <Modal.Content>
                        <Modal.Header>
                            <Modal.Title>
                                {t(keys.delete_modal_title)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Text>{t(keys.delete_modal_description)}</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                            <Modal.ConfirmCTA
                                onClick={deleteQuestion}
                                variant="flatDanger"
                            >
                                {t(keys.table_actions_dd_delete)}
                            </Modal.ConfirmCTA>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Overlay>
            </Modal>
            <QuestionModal
                title={t(keys.edit_question_modal_title)}
                loading={updateQuestionMutation.isPending}
                onConfirm={updateQuestion}
                open={openEditModal}
                setOpen={setOpenEditModal}
                questionId={questionId}
                questionnaireId={questionnaireId}
            />
        </Box>
    );
};
