import {
    type FC,
    type PropsWithChildren,
    type RefObject,
    createContext,
    useRef,
    useState,
} from "react";
import CommentPopover from "./CommentPopover";
import ValueInput from "./ValueInput";
import { useFocusWithin } from "./utils";

type InputContext = {
    focuswithin: boolean;
    containerRef: RefObject<HTMLDivElement | null>;
    commentIconHovering: boolean;
    setCommentIconHovering: (hovering: boolean) => void;
    popoverOpen: boolean;
    setPopoverOpen: (open: boolean) => void;
};

export const InputContext = createContext<InputContext | null>(null);

export type InputComponentType = FC<PropsWithChildren> & {
    ValueInput: typeof ValueInput;
    CommentPopover: typeof CommentPopover;
};

const Input: InputComponentType = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const focuswithin = useFocusWithin(containerRef);
    const [commentIconHovering, setCommentIconHovering] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    return (
        <InputContext.Provider
            value={{
                containerRef,
                focuswithin,
                commentIconHovering,
                setCommentIconHovering,
                popoverOpen,
                setPopoverOpen,
            }}
        >
            <div ref={containerRef} className="relative w-full h-full">
                {children}
            </div>
        </InputContext.Provider>
    );
};

Input.ValueInput = ValueInput;
Input.CommentPopover = CommentPopover;

export default Input;
