import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "reference-section";

const keys = addTranslations(RESOURCE_NAME, {
    references: {
        en: "References for this answer",
        fr: "Références pour cette réponse",
    },
});

export function useReferenceSectionTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
