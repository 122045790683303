import { useGetDocuments } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/QuestionsTab/data";
import { useGetIndicators } from "@app/screens/Frameworks/framework/Csrd/pillar/data";
import { FlexCol } from "@design-system/Layout/Flex";
import { useRef, useState, type FormEvent } from "react";
import { useGenerateQuickAnswer } from "../data";
import { AiErrorMessage } from "./AiError";
import { AnswerSection } from "./AnswerSection";
import { Loader } from "./Loader";
import { NoResultFound } from "./NoResultFound";
import { AskAiPlaceholder } from "./PlaceHolder";
import { QuestionInput } from "./QuestionInput";
import { QuestionSection } from "./QuestionSection";
import { ReferencesSection } from "./ReferenceSection";

export const AiChat = () => {
    const { generateQuickAnswer, data, isPending, error } =
        useGenerateQuickAnswer();

    const { value, documentCitations, indicatorCitations, success } =
        data?.generateQuickAnswer ?? {};
    const { documents: suggestedDocuments } = useGetDocuments(
        documentCitations?.map((c: { documentId: string }) => c.documentId) ??
            [],
    );

    const { indicators: suggestedIndicators } = useGetIndicators(
        indicatorCitations?.map((i) => i.indicatorId) ?? [],
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const [question, setQuestion] = useState("");

    const clearInput = () => {
        if (!inputRef.current) return;

        inputRef.current.value = "";
        inputRef.current.blur();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const question = inputRef.current?.value?.trim();
        if (!question) return;

        setQuestion(question);
        generateQuickAnswer(question);
        clearInput();
    };

    if (error) {
        return (
            <FlexCol
                className="bg-secondary p-5 transition-all duration-300 w-full justify-end"
                h="full"
                gap="4"
            >
                <AiErrorMessage />
                <QuestionInput
                    onSubmit={handleSubmit}
                    inputRef={inputRef}
                    disabled={isPending}
                />
            </FlexCol>
        );
    }

    if (!question) {
        return (
            <FlexCol
                className="bg-secondary p-5 transition-all duration-300 w-full"
                h="full"
                gap="4"
            >
                <AskAiPlaceholder />
                <QuestionInput onSubmit={handleSubmit} inputRef={inputRef} />
            </FlexCol>
        );
    }

    return (
        <FlexCol
            className="bg-secondary p-5 transition-all duration-300 w-full justify-end"
            h="full"
            gap="4"
        >
            {isPending ? (
                <Loader />
            ) : success ? (
                <FlexCol
                    br="xl"
                    className="border border-tertiary bg-white"
                    h="full"
                >
                    <QuestionSection question={question} />
                    <AnswerSection value={value} />
                    <ReferencesSection
                        suggestedDocuments={suggestedDocuments}
                        documentCitations={documentCitations ?? []}
                        suggestedIndicators={suggestedIndicators}
                        isPending={isPending}
                    />
                </FlexCol>
            ) : (
                <NoResultFound />
            )}

            <QuestionInput
                onSubmit={handleSubmit}
                inputRef={inputRef}
                disabled={isPending}
            />
        </FlexCol>
    );
};
