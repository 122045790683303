import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { useUpdateQuestionnaire } from "../../data";
import { useQuestionnaireDetails } from "../../externalQuestionnaire/data";
import { QuestionnaireField } from "../CreateQuestionnaireModal/QuestionnaireFormStep";
import { useEditOrDeleteDropdownTranslations } from "./i18n";

export const EditQuestionnaireModal: FC<{
    questionnaireId?: string;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}> = ({ questionnaireId, open, onOpenChange }) => {
    const { t, keys } = useEditOrDeleteDropdownTranslations();

    const { questionnaire, isPending } = questionnaireId
        ? useQuestionnaireDetails(questionnaireId, {
              enabled: open,
          })
        : { questionnaire: null, isPending: false };

    const { updateAsync } = useUpdateQuestionnaire();

    const [name, setName] = useState<string | undefined>(questionnaire?.name);
    const [email, setEmail] = useState<string | undefined>(
        questionnaire?.requesterEmail ?? undefined,
    );
    const [companyName, setCompanyName] = useState<string | undefined>(
        questionnaire?.companyName ?? undefined,
    );
    const [deadline, setDeadline] = useState<Date | undefined>(
        questionnaire?.deadline ?? undefined,
    );
    const [defaultLocale, setDefaultLocale] = useState<SupportedLocales>(
        (questionnaire?.locale?.default as SupportedLocales) ??
            SupportedLocales.FR,
    );

    useEffect(() => {
        setName(questionnaire?.name);
        setEmail(questionnaire?.requesterEmail ?? undefined);
        setCompanyName(questionnaire?.companyName ?? undefined);
        setDeadline(questionnaire?.deadline ?? undefined);
        setDefaultLocale(
            (questionnaire?.locale?.default as SupportedLocales) ??
                SupportedLocales.FR,
        );
    }, [questionnaire]);

    const makeEventHandler =
        (setter: Dispatch<SetStateAction<string | undefined>>) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setter(e.target.value);
        };

    const canConfirm = !!name && !!defaultLocale;

    const handleConfirm = async () => {
        if (!canConfirm || !questionnaireId) return;

        await updateAsync({
            id: questionnaireId,
            input: {
                name,
                requesterEmail: email,
                companyName,
                deadline,
                locale: {
                    availableLocales: [defaultLocale],
                    default: defaultLocale,
                },
            },
        });
    };

    return (
        <Modal open={open} onOpenChange={onOpenChange}>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header withClose>
                        <FlexRow gap="4">
                            <FlexCol gap="2">
                                <Modal.Title>
                                    {t(keys.edit_questionnaire_modal_title)}
                                </Modal.Title>
                            </FlexCol>
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            {isPending ? (
                                <Skeleton className="h-6" />
                            ) : (
                                <>
                                    <QuestionnaireField
                                        label={t(
                                            keys.edit_questionnaire_modal_name_placeholder,
                                        )}
                                    >
                                        <Textfield
                                            className="h-6"
                                            value={name}
                                            onChange={makeEventHandler(setName)}
                                        />
                                    </QuestionnaireField>
                                    <QuestionnaireField
                                        label={t(
                                            keys.edit_questionnaire_modal_requester_email_placeholder,
                                        )}
                                    >
                                        <Textfield
                                            className="h-6"
                                            value={email}
                                            onChange={makeEventHandler(
                                                setEmail,
                                            )}
                                        />
                                    </QuestionnaireField>
                                    <QuestionnaireField
                                        label={t(
                                            keys.edit_questionnaire_modal_company_name_placeholder,
                                        )}
                                    >
                                        <Textfield
                                            className="h-6"
                                            value={companyName}
                                            onChange={makeEventHandler(
                                                setCompanyName,
                                            )}
                                        />
                                    </QuestionnaireField>
                                    <QuestionnaireField
                                        label={t(
                                            keys.edit_questionnaire_modal_deadline_placeholder,
                                        )}
                                    >
                                        <Popover>
                                            <PopoverTrigger
                                                className="w-full flex items-stretch justify-stretch"
                                                asChild
                                            >
                                                <Button
                                                    variant="text"
                                                    className={cn(
                                                        "w-full min-h-8",
                                                        "px-4",
                                                        "font-normal text-primary",
                                                        "border border-tertiary",
                                                        "hover:bg-transparent hover:shadow-none",
                                                        "rounded-lg",
                                                    )}
                                                >
                                                    {deadline ? (
                                                        format(deadline, "PPP")
                                                    ) : (
                                                        <Text
                                                            as="span"
                                                            color="secondary"
                                                        >
                                                            {t(
                                                                keys.edit_questionnaire_modal_deadline_placeholder,
                                                            )}
                                                        </Text>
                                                    )}
                                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                align="start"
                                                alignOffset={-2}
                                                sideOffset={9}
                                                className="absolute transform -translate-x-3 shadow-lg bg-white"
                                            >
                                                <Calendar
                                                    mode="single"
                                                    defaultMonth={
                                                        deadline
                                                            ? new Date(deadline)
                                                            : new Date()
                                                    }
                                                    selected={deadline}
                                                    onSelect={setDeadline}
                                                    className="rounded-md border"
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    </QuestionnaireField>

                                    <QuestionnaireField
                                        label={t(
                                            keys.edit_questionnaire_modal_locale_placeholder,
                                        )}
                                    >
                                        <LocaleSelect
                                            value={defaultLocale}
                                            options={Object.values(
                                                SupportedLocales,
                                            )}
                                            onChange={setDefaultLocale}
                                        />
                                    </QuestionnaireField>
                                </>
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(keys.edit_questionnaire_modal_cancel_label)}
                        </Modal.CancelCTA>
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                {/* This Box below is necessary because the tooltip doesn't open when the button is
                                 * disabled but we want to display the tooltip just when the button is disabled
                                 */}
                                <Box className="w-fit self-end">
                                    <Modal.ConfirmCTA
                                        onClick={handleConfirm}
                                        disabled={!canConfirm}
                                        loading={isPending}
                                    >
                                        {t(
                                            keys.edit_questionnaire_modal_confirm_label,
                                        )}
                                    </Modal.ConfirmCTA>
                                </Box>
                            </Tooltip.Trigger>
                            {!canConfirm && (
                                <Tooltip.Content>
                                    {t(
                                        keys.edit_questionnaire_modal_tooltip_no_name,
                                    )}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
