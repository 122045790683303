import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useNoResultFoundTranslations } from "./i18n";

export const NoResultFound = () => {
    const { t, keys } = useNoResultFoundTranslations();

    return (
        <FlexRow className="h-full w-9/12 items-center m-auto">
            <FlexCol gap="2" className="items-center">
                <Text
                    as="p"
                    variant="body-sm"
                    className="bg-white w-fit p-2 rounded-full border border-secondary"
                >
                    <Icon name="warning" className="text-accent-1-300" />
                </Text>

                <Text variant="body-sm-bold" className="text-center">
                    {t(keys.error_title)}
                </Text>
                <Text
                    variant="body-sm"
                    color="secondary"
                    className="text-center"
                >
                    {t(keys.error_description)}
                </Text>
            </FlexCol>
        </FlexRow>
    );
};
