import { type GraphQLQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/QuestionnaireRepository";
import {
    AnswerStatus,
    FrameworkType,
    QuestionnaireCreateInput,
} from "@generated/client/graphql";
import {
    type ExternalQuestionnairesListItem,
    type QuestionnairesList,
} from "./types";

export const getQuestionnaireProgress = (
    questionnaire: ExternalQuestionnairesListItem,
) => {
    const totalQuestions = questionnaire.questions.length;
    const completedQuestions = questionnaire.questions.filter(
        (question) => question.answer?.status === AnswerStatus.Validated,
    ).length;
    return {
        completedQuestions,
        totalQuestions,
    };
};

export const questionnaireTabs = {
    overview: "overview",
    questions: "questions",
} as const;

export const getQuestionnaireStatus = (
    questionnaire: QuestionnairesList[number],
) => {
    const questionsStatus = questionnaire.questions.map((question) => {
        return question?.answer?.status;
    });

    if (questionsStatus.length === 0) {
        return "todo";
    }
    const allValidated = questionsStatus.every(
        (status) => status === AnswerStatus.Validated,
    );
    if (allValidated) {
        return "validated";
    }
    const allNotValidated = questionsStatus.every(
        (status) => status !== AnswerStatus.Validated,
    );
    if (allNotValidated) {
        return "todo";
    }
    return "inProgress";
};

export async function createQuestionnaire(
    repository: GraphQLQuestionnaireRepository,
    input: QuestionnaireCreateInput,
    templateId?: string,
) {
    if (templateId) {
        const res = await repository.createFromTemplate(templateId, input);
        return {
            createQuestionnaire: res.createQuestionnaireFromTemplate,
        };
    }

    return repository.createExternalQuestionnaire(input);
}

export const frameworkTypeCanBeUsedAsTemplate = (
    type: FrameworkType | null | undefined,
) => {
    return type === FrameworkType.InvestorRequest;
};
