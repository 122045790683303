import { Row } from "@design-system/Table";
import Fuse from "fuse.js";

export const fuzzyGlobalFilterFn = <TData>(
    row: Row<TData>,
    _: string,
    filterValue: string,
) => {
    if (!filterValue) return true; // Show all rows if search is empty

    const values = row.getValue("name");

    const fuse = new Fuse([values], {
        includeScore: true,
        threshold: 0.5,
    });

    return fuse.search(filterValue).length > 0;
};
