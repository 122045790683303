import {
    previewTemplateModalOpenAtom,
    previewTemplateModalTemplatesAtom,
} from "@app/screens/Document/components/CreateVersionBlock/ctx";
import { currentUserRoleAtom } from "@app/store/userStore";
import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentType,
} from "../../../../../generated/client/graphql";
import { useEditorContext } from "../../../../screens/Document/Edit/hooks/useEditor";
import InformationBlock from "./InformationBlock";
import RelatedDocumentList from "./RelatedDocumentsList";
import "./i18n";

export const Relations = {
    CHILDREN: "CHILDREN",
    PARENTS: "PARENTS",
} as const;
export type DocumentRelationType = (typeof Relations)[keyof typeof Relations];

const InfoTab = ({
    documentType,
}: {
    documentType: DocumentType | undefined;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");
    const { editor, version, editable } = useEditorContext();
    const { isContributor } = useAtomValue(currentUserRoleAtom);
    const showRelatedDocuments = !isContributor;

    const relatedDocuments = version?.document?.children?.length
        ? version?.document?.children
        : version?.document?.parents?.length
          ? version?.document?.parents
          : [];

    const relationType = !!version?.document?.children?.length
        ? Relations.CHILDREN
        : Relations.PARENTS;

    const insertDocument = !!editable
        ? (relatedDocument: Document) => {
              if (!editor) return;
              editor.commands.insertContent(
                  relatedDocument?.lastVersion?.tiptapData || "{}",
              );
          }
        : null;

    const hasTemplate = !!version?.document?.templates?.length;
    const setOpenTemplateModal = useSetAtom(previewTemplateModalOpenAtom);
    const setTemplates = useSetAtom(previewTemplateModalTemplatesAtom);
    const handleOpenTemplateViewModalClicl = () => {
        setOpenTemplateModal(true);
        setTemplates(version?.document?.templates ?? undefined);
    };
    return (
        <div className="flex flex-col items-start gap-4 self-stretch px-4 overflow-scroll scrollbar-hide">
            <InformationBlock
                documentDescription={version?.document?.description}
                documentType={documentType}
                versionId={version?.id}
            />

            {hasTemplate && (
                <Button
                    variant="tonal"
                    size="sm"
                    onClick={handleOpenTemplateViewModalClicl}
                >
                    <Text variant="body-sm-bold">{t("view_template")}</Text>
                </Button>
            )}
            {showRelatedDocuments ? (
                <RelatedDocumentList
                    relatedDocuments={relatedDocuments}
                    relationType={relationType}
                    insertDocument={insertDocument}
                />
            ) : null}
        </div>
    );
};

export default InfoTab;
