import BodyHeader from "@app/shared/components/BodyHeader";
import { useThemes } from "@app/store/themeStore";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Tabs } from "@design-system/Tabs";
import { Text } from "@design-system/Typography/Text";
import { useMutationState } from "@tanstack/react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { CreateObjectiveModal } from "./components/CreateObjectiveModal";
import { selectedThemeIdAtom } from "./components/CreateObjectiveModal/ctx";
import { GenerateObjectiveModal } from "./components/GenerateObjectiveModal";
import { isGenerateObjectiveModalOpenAtom } from "./components/GenerateObjectiveModal/ctx";
import { ObjectivesPageTopbar } from "./components/ObjectivesPageTopbar";
import { ObjectivesTable } from "./components/ObjectiveTable/ObjectivesTable";
import { ObjectivesWorkPlan } from "./components/ObjectiveWorkPlan";
import {
    activeEntityIdAtom,
    activeTabAtom,
    isCreateObjectiveModalOpenAtom,
} from "./ctx";
import { objectiveKeys, useChooseIndicator, useCreateObjective } from "./data";
import { RESOURCE_NAME, keys } from "./i18n";

export default function Objectives() {
    const activeEntityId = useAtomValue(activeEntityIdAtom);
    const { isPending: isCreateObjectivePending } = useCreateObjective();
    const { themes } = useThemes();
    const environmentId = themes?.find(
        (theme) => theme.cmsThemeId === "environment",
    )?.id;
    const setIsCreateObjectiveModalOpen = useSetAtom(
        isCreateObjectiveModalOpenAtom,
    );
    const setCreateObjectiveSelectedThemeId = useSetAtom(selectedThemeIdAtom);

    const { chooseIndicator, isPending: isChooseIndicatorPending } =
        useChooseIndicator();

    const generateObjectiveMutationState = useMutationState({
        filters: { mutationKey: [...objectiveKeys.generateObjective()] },
        select: (mutation) => mutation.state,
    });

    const isGenerateObjectivePending =
        !!generateObjectiveMutationState?.length &&
        generateObjectiveMutationState[
            generateObjectiveMutationState.length - 1
        ].status === "pending";

    const setIsGenerateObjectiveModalOpen = useSetAtom(
        isGenerateObjectiveModalOpenAtom,
    );

    const handleCreateObjectiveClick = () => {
        setIsCreateObjectiveModalOpen(true);
        if (environmentId) {
            setCreateObjectiveSelectedThemeId(environmentId);
        }
    };

    const handleGenerateObjectiveClick = () => {
        if (!activeEntityId) return;
        chooseIndicator(activeEntityId);
        setIsGenerateObjectiveModalOpen(true);
    };

    const { t } = useTranslation(RESOURCE_NAME);
    const [activeTab, setActiveTab] = useAtom(activeTabAtom);

    return (
        <PageContainer>
            <BodyHeader
                dataChameleon={{
                    key: "objectives",
                    type: "tutorial",
                }}
                title={t(keys.page_title)}
                subTitle={t(keys.page_subtitle)}
                action={
                    <>
                        <Button
                            loading={
                                isGenerateObjectivePending ||
                                isChooseIndicatorPending
                            }
                            onClick={handleGenerateObjectiveClick}
                            variant="flatAccent"
                        >
                            {t(keys.generate_with_ai)}
                        </Button>
                        <Button
                            size="md"
                            onClick={handleCreateObjectiveClick}
                            loading={isCreateObjectivePending}
                        >
                            <Text variant="body-bold">
                                {t(keys.create_objective)}
                            </Text>
                        </Button>
                    </>
                }
            />
            <FlexCol gap="4" p="4" alignItems="center" w="full" h="full">
                <FlexCol w="full" gap="4">
                    <Tabs
                        activeTab={activeTab}
                        onActiveTabChange={setActiveTab}
                    >
                        <Tabs.Trigger
                            value={"workPlan"}
                            label={t(keys.work_plan)}
                        />
                        <Tabs.Trigger value={"list"} label={t(keys.list)} />
                    </Tabs>
                    <ObjectivesPageTopbar />
                    {activeTab === "workPlan" && (
                        <ObjectivesWorkPlan entityId={activeEntityId} />
                    )}
                    {activeTab === "list" && (
                        <FlexCol w="full" gap="4">
                            <ObjectivesTable entityId={activeEntityId} />
                        </FlexCol>
                    )}
                </FlexCol>
            </FlexCol>
            <CreateObjectiveModal />
            <GenerateObjectiveModal />
        </PageContainer>
    );
}
