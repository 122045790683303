import { FlexCol } from "@design-system/Layout/Flex";
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@design-system/Table/HTMLTable";
import { Text } from "@design-system/Typography/Text";
import { FC, ReactNode } from "react";
import { useObjectiveWorkPlanTranslations } from "../i18n";

interface ObjectivesTableProps {
    children: ReactNode;
}

export const ObjectivesTable: FC<ObjectivesTableProps> = ({ children }) => {
    const { t, keys } = useObjectiveWorkPlanTranslations();
    return (
        <FlexCol gap="2" className="border-t">
            <Table containerClassName="border-none">
                <TableHeader>
                    <TableRow>
                        <TableHead className="border-r w-7/12">
                            <Text
                                variant="body-sm-bold"
                                className="text-secondary"
                            >
                                {t(keys.objective)}
                            </Text>
                        </TableHead>
                        <TableHead className="border-r">
                            <Text
                                variant="body-sm-bold"
                                className="text-secondary"
                            >
                                {t(keys.owner)}
                            </Text>
                        </TableHead>
                        <TableHead className="border-r text-right">
                            <Text
                                variant="body-sm-bold"
                                className="text-secondary"
                            >
                                {t(keys.due_date)}
                            </Text>
                        </TableHead>
                        <TableHead className="text-right">
                            <Text
                                variant="body-sm-bold"
                                className="text-secondary"
                            >
                                {t(keys.target)}
                            </Text>
                        </TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>{children}</TableBody>
            </Table>
        </FlexCol>
    );
};
