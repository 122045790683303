import { useSetToast } from "@app/components/Toast";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { type ColumnDef } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { makeSupplierQuestionnaireLink } from "../../../services";
import { type SupplierQuestionnaireListItem } from "../../types";
import { QuestionnaireLink } from "../QuestionnaireLink";
import { useQuestionnairesTableTranslations } from "./i18n";

const questionnaireCol: ColumnDef<SupplierQuestionnaireListItem> = {
    id: "questionnaire",
    header: () => {
        const { t, keys } = useQuestionnairesTableTranslations();

        return (
            <Text variant="body-sm-bold" color="secondary">
                {t(keys.archived_questionnaires)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const { org_uname, sup_id } = useParams();

        const toQuestionnaireLink =
            org_uname && sup_id
                ? makeSupplierQuestionnaireLink({
                      orgUname: org_uname,
                      supplierId: sup_id,
                      questionnaireId: row.original.id,
                  })
                : undefined;
        return (
            <QuestionnaireLink
                name={row.original.name}
                toQuestionnaireLink={toQuestionnaireLink}
            />
        );
    },
};

const completionCol: ColumnDef<SupplierQuestionnaireListItem> = {
    id: "completion",
    cell: ({ row }) => {
        const questionnaire = row.original;

        const { t, keys } = useQuestionnairesTableTranslations();

        return (
            <FlexRow gap="0.5" w="full" alignItems="center">
                <Text color="secondary">{t(keys.progress)}</Text>
                <Text>{questionnaire.progress}%</Text>
            </FlexRow>
        );
    },
};

const sentCol: ColumnDef<SupplierQuestionnaireListItem> = {
    id: "sent",
    cell: ({ row }) => {
        const questionnaire = row.original;
        dayjs.extend(LocalizedFormat);

        const { t, keys } = useQuestionnairesTableTranslations();

        return (
            <FlexRow gap="0.5" w="full" alignItems="center">
                <Text color="secondary">{t(keys.sent)}</Text>
                <Text>{questionnaire.sentDate ?? "-"}</Text>
            </FlexRow>
        );
    },
};

const sentByCol: ColumnDef<SupplierQuestionnaireListItem> = {
    id: "sentBy",
    cell: ({ row }) => {
        const { t, keys } = useQuestionnairesTableTranslations();

        const user = row.original.sentBy;

        return (
            <FlexRow gap="1.5" w="full" alignItems="center">
                <Text color="secondary">{t(keys.sent_by)}</Text>
                <Avatar
                    imageUrl={user?.avatar?.signedUrl}
                    variant="circle"
                    letter={user?.firstName[0]}
                />
                <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
            </FlexRow>
        );
    },
};

const copyLinkCol: ColumnDef<SupplierQuestionnaireListItem> = {
    id: "copyLink",
    cell: ({ row }) => {
        const { id: questionnaireId } = row.original;
        const params = useParams();
        const { t, keys } = useQuestionnairesTableTranslations();
        const { setToastSuccess, setToastError } = useSetToast();

        const link = makeSupplierQuestionnaireLink({
            orgUname: params.org_uname as string,
            supplierId: params.supplier_id as string,
            questionnaireId,
        });

        const handleCopyClick = () => {
            navigator.clipboard
                .writeText(link)
                .then(() => {
                    setToastSuccess(t(keys.copy_link_success));
                })
                .catch(() => {
                    setToastError(t(keys.copy_link_error));
                });
        };

        return (
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <Button size="sm" variant="tonal" onClick={handleCopyClick}>
                        <Button.Icon name="link" />
                    </Button>
                </Tooltip.Trigger>
                <Tooltip.Content>{t(keys.copy_link)}</Tooltip.Content>
            </Tooltip>
        );
    },
};

export const useColumns = ({
    withCopyLinkCol = true,
}: {
    withCopyLinkCol?: boolean;
}): ColumnDef<SupplierQuestionnaireListItem>[] => {
    const columns = useMemo(
        () => [
            questionnaireCol,
            completionCol,
            sentCol,
            sentByCol,
            ...(withCopyLinkCol ? [copyLinkCol] : []),
        ],
        [withCopyLinkCol],
    );
    return columns;
};
