import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "question-input";

const keys = addTranslations(RESOURCE_NAME, {
    ask_me_anything: {
        en: "Write your CSR question here",
        fr: "Écrivez votre question RSE ici",
    },
});

export function useQuestionInputTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
