import { Button } from "@design-system/Inputs/Button";
import { Modal } from "@design-system/Modal";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import {
    type QuestionnaireCreationModalStep,
    type QuestionnaireTemplate,
} from "../../types";
import { QuestionnaireFormStep } from "./QuestionnaireFormStep";
import { TemplatesStep } from "./TemplatesStep";

export const CreateQuestionnaireModal: FC<{
    isTemplateOnly?: boolean;
    templateToUse?: QuestionnaireTemplate;
}> = ({ isTemplateOnly, templateToUse }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [step, setStep] = useState<QuestionnaireCreationModalStep>(
        templateToUse ? "questionnaireForm" : "templates",
    );
    const [template, setTemplate] = useState<QuestionnaireTemplate | null>(
        templateToUse ?? null,
    );

    return (
        <Modal>
            <Modal.Trigger asChild>
                {templateToUse || isTemplateOnly ? (
                    <Button variant="tonal" size="sm" className="mt-2">
                        {t(
                            keys.create_questionnaire_from_template_modal_trigger_label,
                        )}
                    </Button>
                ) : (
                    <Button variant="outlined">
                        <Button.Icon name="plus" />
                        {t(keys.create_questionnaire_modal_trigger_label)}
                    </Button>
                )}
            </Modal.Trigger>
            <Modal.Overlay>
                {step === "templates" && (
                    <TemplatesStep
                        isTemplateOnly={isTemplateOnly}
                        onStepChange={setStep}
                        onTemplateChange={setTemplate}
                    />
                )}

                {step === "questionnaireForm" && (
                    <QuestionnaireFormStep
                        isTemplateOnly={!!templateToUse}
                        template={template}
                        onStepChange={setStep}
                        onTemplateChange={setTemplate}
                    />
                )}
            </Modal.Overlay>
        </Modal>
    );
};
