import i18next from "../../../../i18n";

const enResources = {
    done: "Approved",
    draft: "Draft",
    ready_for_approval: "Ready for approval",
    start: "Get started",
    status: "Status",
    document: "Document",
    not_started: "Not started",
    pending_bv_review: "Pending review",
    rejected: "Needs improvement",
    master_policy: "Master policy",
    text_document: "text document",
    text_tier_1:
        "This document is essential to your CSR management system. \n Make sure to prioritize it !",
    rocket_header_tooltip: "Filter only essential documents",
    policy: "Chapter",
    preview_template: "Preview template",
    empty_state_initial_title: "No document",
    empty_state_initial_subtitle: "Create a document to get started",
    empty_state_search_title: "No document found",
    empty_state_search_subtitle: "Please try again with different filters",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    done: "Validé",
    draft: "Brouillon",
    ready_for_approval: "Prêt pour approbation",
    start: "Commencer",
    status: "Statut",
    document: "Document",
    not_started: "A faire",
    pending_bv_review: "revue en cours",
    rejected: "Améliorations nécessaires",
    master_policy: "Politique principale",
    text_document: "Document textuel",
    text_tier_1:
        "Ce document est essentiel à votre système de management RSE. \n Assurez-vous de le construire en priorité !",
    rocket_header_tooltip: "Filtrer uniquement les documents essentiels",
    policy: "Chapitre",
    preview_template: "Aperçu du modèle",
    empty_state_initial_title: "Pas de document",
    empty_state_initial_subtitle: "Créez un document pour commencer",
    empty_state_search_title: "Aucun document trouvé",
    empty_state_search_subtitle: "Veuillez réessayer avec différents filtres",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "Documents2Table";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
