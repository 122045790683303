import { GraphQLFrameworkRepository } from "@app/repositories/GraphQLRepositories/FrameworkRepository";
import { GraphQLQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/QuestionnaireRepository";
import {
    CreateFrameworkQuestionnaireInput,
    FrameworkActivation,
} from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFrameworks, isSupportedFrameworkType } from "./services";

const frameworkRepository = new GraphQLFrameworkRepository();
const questionnaireRepository = new GraphQLQuestionnaireRepository();
export const frameworkKeys = {
    all: ["frameworks"] as const,
    list: () => [...frameworkKeys.all, "list"] as const,
    frameworksQuestionnaires: () =>
        [...frameworkKeys.all, "questionnaires"] as const,
    frameworksQuestionnairesDataPage: () =>
        [...frameworkKeys.all, "questionnaires", "data-page"] as const,
    all_cms: ["cms", "frameworks"] as const,
    not_initialized: ["frameworks", "not-initialized"] as const,
};

export const useReleasedCmsFrameworks = () => {
    const { data, ...query } = useQuery({
        queryKey: frameworkKeys.all_cms,
        queryFn: frameworkRepository.getManyReleasedFromCms,
    });
    return {
        releasedCmsFrameworks: data?.releasedCmsFrameworks?.filter((f) =>
            isSupportedFrameworkType(f.type),
        ),
        ...query,
    };
};

export function useNewFrameworksPage() {
    const { data, isPending, error } = useQuery({
        queryKey: frameworkKeys.frameworksQuestionnairesDataPage(),
        queryFn: questionnaireRepository.getQuestionnairesDataPage,
    });
    return {
        activeQuestionnaires:
            data?.frameworkQuestionnairesDataPage?.questionnaireDataPages?.filter(
                (f) => f.active,
            ),
        inactiveQuestionnaires:
            data?.frameworkQuestionnairesDataPage?.questionnaireDataPages?.filter(
                (f) => !f.active,
            ),
        isPending,
        error,
    };
}

export function useFrameworks() {
    const { data, isPending, error } = useQuery({
        queryKey: frameworkKeys.list(),
        queryFn: frameworkRepository.getMany,
    });

    const {
        data: frameworkQuestionnaires,
        isPending: isPendingFrameworkQuestionnaires,
        error: errorFrameworkQuestionnaires,
    } = useQuery({
        queryKey: frameworkKeys.frameworksQuestionnaires(),
        queryFn: frameworkRepository.getManyFrameworkQuestionnaires,
    });

    const {
        data: frameworkQuestionnairesDataPage,
        isPending: isPendingFrameworkQuestionnairesDataPage,
        error: errorFrameworkQuestionnairesDataPage,
    } = useQuery({
        queryKey: frameworkKeys.frameworksQuestionnairesDataPage(),
        queryFn: questionnaireRepository.getQuestionnairesDataPage,
    });

    return {
        frameworks: getFrameworks(data?.frameworks),
        frameworkQuestionnaires:
            frameworkQuestionnaires?.frameworkQuestionnaires,
        frameworkQuestionnairesDataPage:
            frameworkQuestionnairesDataPage?.frameworkQuestionnairesDataPage,
        isPending:
            isPending ||
            isPendingFrameworkQuestionnaires ||
            isPendingFrameworkQuestionnairesDataPage,
        error:
            error ||
            errorFrameworkQuestionnaires ||
            errorFrameworkQuestionnairesDataPage,
    };
}

export const useCreateFrameworkQuestionnaire = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: ({
            frameworkCmsId,
            year,
            tagIds,
            locale,
        }: CreateFrameworkQuestionnaireInput) =>
            questionnaireRepository.createFrameworkQuestionnaire(
                frameworkCmsId,
                year,
                tagIds,
                locale,
            ),
        onSettled: () => {
            client.invalidateQueries({ queryKey: frameworkKeys.all });
        },
    });
};

export const useFrameworkInitialize = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (frameworkId: string) =>
            frameworkRepository.initialize(frameworkId),
        onSettled: () => {
            client.invalidateQueries({ queryKey: frameworkKeys.all });
        },
    });
};

export const useSetFrameworkActivation = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: ({
            frameworkId,
            isActive,
        }: {
            frameworkId: string;
            isActive: boolean;
        }) =>
            frameworkRepository.update(frameworkId, {
                activation: isActive
                    ? FrameworkActivation.Active
                    : FrameworkActivation.Inactive,
            }),
        onSettled: () => {
            client.invalidateQueries({ queryKey: frameworkKeys.all });
        },
    });
};

export const useNotInitializedCmsFrameworks = () => {
    const { data, isPending, error } = useQuery({
        queryKey: frameworkKeys.not_initialized,
        queryFn: frameworkRepository.getNotInitializedCmsFrameworks,
    });

    return {
        notInitializedFrameworks: getFrameworks(data?.notInitializedFrameworks),
        isPending,
        error,
    };
};
