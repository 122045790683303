import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetDocumentByUrlQuery } from "@generated/client/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type KeyElement = NonNullable<
    GetDocumentByUrlQuery["documentByUrl"]
>["versions"][number]["keyElements"][number];

export const VersionKeyElements = ({
    keyElements,
}: {
    keyElements: KeyElement[];
}) => {
    const firstKeyElements = keyElements.slice(0, 3);
    const [showAll, setShowAll] = useState(false);
    const handleClick = () => {
        setShowAll(!showAll);
    };

    const { t } = useTranslation("DocumentVersionBlock");

    const displayedKeyElements = showAll ? keyElements : firstKeyElements;
    if (!displayedKeyElements.length) {
        return null;
    }
    return (
        <FlexCol>
            <FlexCol p="4" gap="2">
                <FlexRow gap="1">
                    <Text variant="body-bold">{t("key_elements")}</Text>
                    <Tooltip>
                        <Tooltip.Trigger asChild>
                            <Box className="grid place-items-center size-5 text-accent-1-300">
                                <Icon name="stars" size="sm" />
                            </Box>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t("key_elements_tooltip")}
                        </Tooltip.Content>
                    </Tooltip>
                </FlexRow>
                <ul className="list-disc ml-5">
                    {displayedKeyElements.map((keyElement, index) => (
                        <li
                            key={keyElement.id}
                            className={index === 0 ? "" : "mt-5"}
                        >
                            <Text variant="body-bold" className="inline">
                                {keyElement.title}
                            </Text>
                            <Text className="inline">{`: ${keyElement.content}`}</Text>
                        </li>
                    ))}
                </ul>
            </FlexCol>
            {keyElements.length > 3 && (
                <Button
                    className="w-full border-t border-t-tertiary rounded-t-none"
                    variant="text"
                    size="sm"
                    onClick={handleClick}
                >
                    {showAll
                        ? t("show_less")
                        : t("show_more", { count: keyElements.length - 3 })}
                    <Button.Icon name={showAll ? "angleUp" : "angleDown"} />
                </Button>
            )}
        </FlexCol>
    );
};
