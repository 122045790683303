import { type SupplierUser } from "@app/screens/Supplier/Suppliers/supplier/types";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { useSupplierUserTableTranslations } from "./i18n";

const emailCol: ColumnDef<SupplierUser> = {
    id: "email",
    meta: {
        colWidth: "30%",
    },
    header: () => {
        const { t, keys } = useSupplierUserTableTranslations();
        return t(keys.user);
    },
    cell: ({ row }) => {
        return (
            <FlexRow gap="2" alignItems="center">
                <Avatar
                    letter={row.original.firstName?.[0]}
                    imageUrl={row.original.avatar?.signedUrl}
                    size="md"
                    variant="circle"
                />
                <Text variant="body-bold" color="primary">
                    {row.original.firstName} {row.original.lastName}
                </Text>
                <Text variant="body-sm" color="secondary">
                    {row.original.email}
                </Text>
            </FlexRow>
        );
    },
};

const departmentCol: ColumnDef<SupplierUser> = {
    id: "department",
    header: () => {
        const { t, keys } = useSupplierUserTableTranslations();
        return t(keys.dep);
    },
    cell: ({ row }) => {
        const { t, keys } = useSupplierUserTableTranslations();
        const department = row.original.department;

        return (
            <Text>
                {t(keys.department, {
                    context: department,
                })}
            </Text>
        );
    },
};

const actionCol: ColumnDef<SupplierUser> = {
    meta: {
        colWidth: "60px",
    },
    id: "action",
    cell: () => {
        const { t, keys } = useSupplierUserTableTranslations();

        return (
            <Dropdown>
                <Dropdown.Trigger asChild>
                    <Button variant="outlined" size="sm" disabled>
                        <Button.Icon name="more" size="sm" />
                    </Button>
                </Dropdown.Trigger>
                <Dropdown.Content hasPortal align="end">
                    <Dropdown.Item>
                        <Dropdown.ItemIcon name="edit" />
                        <Dropdown.ItemText>
                            {t(keys.edit_user)}
                        </Dropdown.ItemText>
                    </Dropdown.Item>
                    <Dropdown.Item variant="negative">
                        <Dropdown.ItemIcon name="trash" />
                        <Dropdown.ItemText>
                            {t(keys.delete_user)}
                        </Dropdown.ItemText>
                    </Dropdown.Item>
                </Dropdown.Content>
            </Dropdown>
        );
    },
};

export const useColumns = (): ColumnDef<SupplierUser>[] => {
    const columns = useMemo(() => [emailCol, departmentCol, actionCol], []);
    return columns;
};
