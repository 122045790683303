import { SupportedLocales } from "@app/shared/utils/locales";
import { currentUserAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";

export function useCurrentUserLocale(fallback: SupportedLocales) {
    const user = useAtomValue(currentUserAtom);
    const locale: SupportedLocales = user.data?.currentUser.locale ?? fallback;

    return locale;
}
