import { GraphQLFrameworkRepository } from "@app/repositories/GraphQLRepositories/FrameworkRepository";
import { GraphQLQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/QuestionnaireRepository";
import {
    QuestionnaireUpdateInput,
    type QuestionnaireCreateInput,
} from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { frameworkKeys } from "../Frameworks/data";
import {
    createQuestionnaire,
    frameworkTypeCanBeUsedAsTemplate,
} from "./services";

const externalQuestionnairesRepository = new GraphQLQuestionnaireRepository();
const frameworkRepository = new GraphQLFrameworkRepository();

export const questionnairesKeys = {
    all: ["questionnaires"] as const,
    headerData: (id?: string) => [...questionnairesKeys.all, id] as const,
    details: (id?: string) =>
        [...questionnairesKeys.all, id, "details"] as const,
    overViewData: (id?: string, locale?: string) =>
        [...questionnairesKeys.headerData(id), "overViewData", locale] as const,
    generationState: (id?: string, locale?: string) =>
        [
            ...questionnairesKeys.overViewData(id, locale),
            "generationState",
        ] as const,
    generateAiSuggestion: (questionnaireId?: string, questionId?: string) =>
        [
            ...questionnairesKeys.headerData(questionnaireId),
            "generateAiSuggestion",
            questionId,
        ] as const,
    groups: (id?: string) =>
        [...questionnairesKeys.headerData(id), "groups"] as const,
    questions: (id?: string) =>
        [...questionnairesKeys.headerData(id), "questions"] as const,
    questionsFiltered: (
        id?: string,
        locale?: string,
        searchString?: string,
        groupId?: string,
        ownerIds?: string[],
        questionStatuses?: string[],
        aiAnswerStatuses?: string[],
    ) =>
        [
            ...questionnairesKeys.questions(id),
            locale,
            searchString,
            groupId,
            ownerIds,
            questionStatuses,
            aiAnswerStatuses,
        ] as const,
    question: (questionnaireId?: string, questionId?: string) =>
        [...questionnairesKeys.questions(questionnaireId), questionId] as const,
    questionnaireGroups: (id?: string) =>
        ["questionnaire", id, "groups"] as const,
    answer: (questionnaireId?: string, questionId?: string) =>
        [
            ...questionnairesKeys.question(questionnaireId, questionId),
            "answer",
        ] as const,
};

export const useQuestionnaires = () => {
    const { data, ...query } = useQuery({
        queryKey: questionnairesKeys.all,
        queryFn: externalQuestionnairesRepository.getQuestionnaires,
    });

    const externalQuestionnaires = useMemo(
        () => data?.questionnaires ?? [],
        [data],
    );

    return { externalQuestionnaires, ...query };
};

export const useCreateExternalQuestionnaire = () => {
    const client = useQueryClient();

    const {
        mutate: create,
        mutateAsync: createAsync,
        ...query
    } = useMutation({
        mutationFn: async ({
            input,
            templateId,
        }: {
            input: QuestionnaireCreateInput;
            templateId?: string;
        }) => {
            return createQuestionnaire(
                externalQuestionnairesRepository,
                input,
                templateId,
            );
        },
        onSettled: () => {
            client.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
        },
    });
    return { create, createAsync, ...query };
};

export const useUpdateQuestionnaire = () => {
    const client = useQueryClient();

    const {
        mutate: update,
        mutateAsync: updateAsync,
        ...query
    } = useMutation({
        mutationFn: async ({
            id,
            input,
        }: {
            id: string;
            input: QuestionnaireUpdateInput;
        }) => {
            return externalQuestionnairesRepository.updateQuestionnaire(
                id,
                input,
            );
        },
        onSettled: () => {
            client.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
        },
    });
    return { update, updateAsync, ...query };
};

export const useDeleteExternalQuestionnaire = () => {
    const client = useQueryClient();
    const { mutate: deleteExternalQuestionnaire, ...query } = useMutation({
        mutationFn:
            externalQuestionnairesRepository.deleteExternalQuestionnaire,
        onSettled: () => {
            client.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
            client.invalidateQueries({
                queryKey: frameworkKeys.list(),
            });
        },
    });
    return { deleteExternalQuestionnaire, ...query };
};

export const useQuestionnaireTemplates = () => {
    const { data, ...query } = useQuery({
        queryKey: frameworkKeys.all_cms,
        queryFn: frameworkRepository.getManyReleasedFromCms,
    });
    return {
        templates:
            data?.releasedCmsFrameworks?.filter((f) =>
                frameworkTypeCanBeUsedAsTemplate(f.type),
            ) ?? [],
        ...query,
    };
};
