import { useSetToast } from "@app/components/Toast";
import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { type FC, useMemo, useState } from "react";
import { useCreateSupplierUsers, useSupplier } from "../../data";
import { getSubmitUsersError } from "../../services";
import { CreateSupplierUserInput } from "../../types";
import { useAddUsersModalTranslations } from "./i18n";
import { UserRow } from "./UserRow";
import { ResponseStatus } from "@generated/client/graphql";

const makeEmptyUser = () => ({
    email: "",
    firstName: "",
    lastName: "",
    department: "",
});

export const AddUsersModal: FC<{ supplierId?: string }> = ({ supplierId }) => {
    const { t, keys } = useAddUsersModalTranslations();
    const { supplier } = useSupplier(supplierId);
    const { setToastSuccess, setToastError } = useSetToast();

    const [open, setOpen] = useState(false);

    const { createSupplierUsers, isPending } = useCreateSupplierUsers();
    const [locale, setLocale] = useState<SupportedLocales>(SupportedLocales.FR);

    const [users, setUsers] = useState<
        Omit<CreateSupplierUserInput, "locale">[]
    >([makeEmptyUser()]);

    const addEmptyUser = () => {
        setUsers([...users, makeEmptyUser()]);
    };

    const handleUserChange = (
        idx: number,
        user: Omit<CreateSupplierUserInput, "locale">,
    ) => {
        setUsers(users.map((u, i) => (i === idx ? user : u)));
    };

    const handleUserDelete = (idx: number) => {
        setUsers(users.filter((_, i) => i !== idx));
    };

    const submitError = useMemo(() => getSubmitUsersError(users), [users]);

    const handleSubmit = () => {
        if (!supplierId) {
            return;
        }
        createSupplierUsers(
            {
                supplierId,
                input: users.map((u) => ({
                    ...u,
                    locale,
                })),
            },
            {
                onSettled: (data, error) => {
                    if (
                        error ||
                        data?.createSupplierUsers?.status !==
                            ResponseStatus.Success
                    ) {
                        setToastError(t(keys.error_message));
                        return;
                    }
                    setToastSuccess(t(keys.success_message));
                    setUsers([]);
                    setOpen(false);
                },
            },
        );
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger>
                <Button>
                    <Button.Icon name="userPlus" />
                    {t(keys.trigger_button)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay>
                <Modal.Content className="min-w-[1000px]">
                    <Modal.Header withClose>
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                        <Modal.Subtitle>
                            {t(keys.description, {
                                supplierName: supplier?.name ?? "",
                            })}
                        </Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body className="max-h-[500px] overflow-y-auto">
                        <FlexCol gap="4">
                            {users.map((user, index) => (
                                <UserRow
                                    id={index}
                                    key={index}
                                    user={user}
                                    onChange={handleUserChange}
                                    onDelete={handleUserDelete}
                                />
                            ))}
                            <Button
                                className="w-fit"
                                variant="outlined"
                                size="md"
                                onClick={addEmptyUser}
                            >
                                <Button.Icon name="userPlus" />
                                {t(keys.add_new_user)}
                            </Button>
                            <FlexCol gap="2">
                                <Text>{t(keys.choose_language)}</Text>
                                <LocaleSelect
                                    value={locale}
                                    onChange={setLocale}
                                    options={Object.values(SupportedLocales)}
                                />
                            </FlexCol>
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Box>
                                    <Modal.ConfirmCTA
                                        loading={isPending}
                                        disabled={!!submitError}
                                        onClick={handleSubmit}
                                        preventClose
                                    >
                                        {t(keys.add_user, {
                                            count: users.length,
                                        })}
                                    </Modal.ConfirmCTA>
                                </Box>
                            </Tooltip.Trigger>
                            {submitError && (
                                <Tooltip.Content>
                                    {t(keys.cannot_submit, {
                                        context: submitError,
                                    })}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
