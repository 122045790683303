import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import InputSimple from "@app/components/InputSimple";
import { useEntities } from "@app/store/entityStore";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetAllEntitiesDocument } from "../../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../../QueryClientWithHeaders";
import { useSetToast } from "../../../../../components/Toast";
import { useUpdateEntity } from "../../data";
import { toUpdateSiteAtom } from "../ctx";
import "./i18n";
import { keys, RESOURCE_NAME } from "./i18n";

export default function UpdateSiteModal() {
    const { t } = useTranslation(RESOURCE_NAME);
    const { entities } = useEntities();

    const [toUpdateSite, setToUpdateSite] = useAtom(toUpdateSiteAtom);

    const [name, setName] = useState<string | undefined>(
        toUpdateSite?.name || undefined,
    );
    const [parentId, setParentId] = useState<string | undefined>(
        toUpdateSite?.parent?.id || undefined,
    );
    const [isParent, setIsParent] = useState<boolean | undefined>(
        toUpdateSite?.isParent || undefined,
    );

    const parentEntity = entities?.find((entity) => entity.isParent);
    const hasChildren = entities?.some(
        (entity) => entity.parent?.id === toUpdateSite?.id,
    );

    const { mutate, isPending } = useUpdateEntity();
    const { setToastSuccess, setToastError } = useSetToast();
    const client = useQueryClient();

    const canSave = true;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleParentChange = (checked: boolean) => {
        setParentId(checked ? parentEntity?.id : undefined);
    };

    const handleSave = () => {
        if (canSave) {
            toUpdateSite &&
                mutate(
                    {
                        id: toUpdateSite.id,
                        name,
                        parentId,
                        isParent,
                    },
                    {
                        onSuccess() {
                            client.invalidateQueries({
                                queryKey: useGraphQL.getKey(
                                    GetAllEntitiesDocument,
                                ),
                            });
                            setToUpdateSite(undefined);
                            setToastSuccess(t("site_update_success"));
                        },
                        onError() {
                            client.invalidateQueries({
                                queryKey: useGraphQL.getKey(
                                    GetAllEntitiesDocument,
                                ),
                            });
                            setToastError(t("site_update_error"));
                        },
                    },
                );
        }
    };

    useEffect(() => {
        setName(toUpdateSite?.name || undefined);
        setParentId(toUpdateSite?.parent?.id || undefined);
        setIsParent(toUpdateSite?.isParent || undefined);
    }, [toUpdateSite]);

    return (
        <Modal
            open={toUpdateSite !== undefined}
            onOpenChange={(open) => {
                !open && setToUpdateSite(undefined);
            }}
        >
            <Modal.Overlay centerContent>
                <Modal.Content className="max-w-[500px]">
                    <Modal.Header className="gap-2" withClose>
                        <Modal.Title className="text-lg font-bold">
                            {t(keys.title)}
                        </Modal.Title>
                        <Modal.Subtitle>{t(keys.subtitle)}</Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol w="full" gap="3">
                            <InputSimple
                                title={t("name")}
                                value={name}
                                onChange={handleNameChange}
                            />
                            {!parentEntity || toUpdateSite?.isParent ? (
                                <FlexRow h="6" alignItems="center" gap="1.5">
                                    <Checkbox
                                        disabled={hasChildren}
                                        checked={isParent}
                                        onChange={setIsParent}
                                    />
                                    <Text className="text-primary">
                                        {t("set_as_parent")}
                                    </Text>
                                </FlexRow>
                            ) : (
                                <FlexRow h="6" alignItems="center" gap="1.5">
                                    <Checkbox
                                        checked={parentId === parentEntity?.id}
                                        onChange={handleParentChange}
                                    />
                                    <Text className="text-primary">
                                        {t("set_as_children", {
                                            entity: parentEntity?.name,
                                        })}
                                    </Text>
                                </FlexRow>
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            onClick={handleSave}
                            disabled={!canSave}
                            loading={isPending}
                        >
                            {t(keys.save)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
}
