import { getDepartmentsList } from "@app/screens/Users/components/NewUserModal/services";
import { validateEmail } from "@app/utils/emailRegex";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type ChangeEvent, type FC, useMemo } from "react";
import { CreateSupplierUserInput } from "../../types";
import { useAddUsersModalTranslations } from "./i18n";

export const UserRow: FC<{
    id: number;
    user: Omit<CreateSupplierUserInput, "locale">;
    onChange: (
        id: number,
        user: Omit<CreateSupplierUserInput, "locale">,
    ) => void;
    onDelete: (id: number) => void;
}> = ({ id, user, onChange, onDelete }) => {
    const { t, keys } = useAddUsersModalTranslations();
    const departments = useMemo(() => getDepartmentsList(), []);

    const emailError = useMemo(() => {
        if (!user.email || validateEmail(user.email)) return;

        return t(keys.email_error);
    }, [user.email]);

    const handleTextfieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(id, { ...user, [e.target.name]: e.target.value });
    };

    const handleDepartmentChange = ([value]: string[]) => {
        onChange(id, { ...user, department: value });
    };

    const handleDelete = () => {
        onDelete(id);
    };

    return (
        <Box className="grid grid-cols-[repeat(4,1fr)_auto] gap-2" w="full">
            <Textfield
                placeholder={t(keys.email)}
                value={user.email}
                onChange={handleTextfieldChange}
                error={emailError}
                name="email"
                type="email"
            />
            <Textfield
                placeholder={t(keys.first_name)}
                className="min-w-0"
                value={user.firstName}
                onChange={handleTextfieldChange}
                name="firstName"
            />
            <Textfield
                placeholder={t(keys.last_name)}
                value={user.lastName}
                onChange={handleTextfieldChange}
                name="lastName"
            />
            <Dropdown
                onValueChange={handleDepartmentChange}
                selectType="single"
                value={user.department ? [user.department] : []}
            >
                <Dropdown.Trigger>
                    <FlexRow
                        className="border border-tertiary"
                        h="full"
                        w="full"
                        alignItems="center"
                        p="2"
                    >
                        <Text
                            align="left"
                            className={cn(
                                user.department
                                    ? "text-primary"
                                    : "text-secondary",
                            )}
                            w="full"
                        >
                            {user.department
                                ? t(
                                      keys[
                                          `dep_${user.department as (typeof departments)[number]}`
                                      ],
                                  )
                                : t(keys.department)}
                        </Text>
                        <Icon name="angleDown" className="text-primary" />
                    </FlexRow>
                </Dropdown.Trigger>
                <Dropdown.Content>
                    {departments.map((department) => (
                        <Dropdown.SelectableItem
                            key={department}
                            value={department}
                        >
                            <Dropdown.ItemText>
                                {t(keys[`dep_${department}`])}
                            </Dropdown.ItemText>
                            <Dropdown.SelectedIndicator />
                        </Dropdown.SelectableItem>
                    ))}
                </Dropdown.Content>
            </Dropdown>
            <Button variant="text" size="lg" onClick={handleDelete}>
                <Button.Icon name="trash" />
            </Button>
        </Box>
    );
};
