import { Icon, type IconName } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import chroma from "chroma-js";
import { forwardRef } from "react";

interface ColorTagProps {
    color: string;
    size?: "xs" | "sm";
    text: string;
    className?: ClassValue;
    backgroundColor?: string;
    borderColor?: string;
    iconName?: IconName;
}

export const ColorTag = forwardRef<HTMLDivElement, ColorTagProps>(
    (
        {
            color,
            size = "xs",
            text,
            backgroundColor,
            borderColor,
            className,
            iconName,
        },
        ref,
    ) => {
        return (
            <Box
                className={cn(
                    "border rounded-lg box-border",
                    size === "xs" ? "h-[20px]" : "py-0.5",
                    className,
                )}
                display="flex"
                alignItems="center"
                maxW="fit"
                ref={ref}
                style={{
                    backgroundColor:
                        backgroundColor ?? chroma(color).alpha(0.1).hex(),
                    borderColor: borderColor ?? chroma(color).alpha(0.3).hex(),
                    color: color,
                }}
                px="2"
            >
                <FlexRow alignItems="center" alignSelf="stretch">
                    <Text
                        className="truncate"
                        variant={size === "sm" ? "body-sm" : "body-xs-bold"}
                    >
                        {text}
                    </Text>
                    {iconName && <Icon name={iconName} size={size} />}
                </FlexRow>
            </Box>
        );
    },
);
