import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    type RefObject,
    type TextareaHTMLAttributes,
    useEffect,
    useRef,
} from "react";

export interface TextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    ref?: RefObject<HTMLTextAreaElement | null>;
    error?: string;
    success?: string;
    hint?: string;
}

const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string | number | undefined | readonly string[],
) => {
    useEffect(() => {
        if (
            textAreaRef &&
            textAreaRef.clientHeight < textAreaRef.scrollHeight
        ) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.style.height = "0px";
            const scrollHeight = textAreaRef.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            textAreaRef.style.height = scrollHeight + 6 + "px";
        }
    }, [textAreaRef, value]);
};

export const Textarea = ({ className, error, ...props }: TextareaProps) => {
    const textAreaClassName = cn(
        "bg-transparent w-full p-0 font-default text-base font-regular leading-[20px] focus:ring-0 placeholder:text-neutral-500 disabled:text-neutral-500",
        "resize-y",
        "w-full group rounded-lg border border-tertiary bg-white focus:border-primary",
        error && "border-danger focus:border-danger",
        "disabled:border-neutral-200 disabled:text-neutral-500",
        "[&:not(:placeholder-shown)]:pt-[26px] focus:pt-[26px]",
        "focus:placeholder:text-transparent",
        "peer",
        "pl-2 pr-6 pt-1.5 pb-[26px]",
        "focus:pt-[26px] focus:pb-1.5 [&:not(:placeholder-shown)]:pt-[26px] [&:not(:placeholder-shown)]:pb-1.5",
        className,
    );
    const labelClassName = cn(
        "absolute left-2 top-1.5 peer-placeholder-shown:invisible peer-focus:visible",
    );

    const localRef = useRef<HTMLTextAreaElement>(null);
    const textareaRef = props.ref || localRef;

    useAutosizeTextArea(textareaRef.current, props.value);

    return (
        <div className="w-full relative">
            <label>
                <textarea
                    className={textAreaClassName}
                    {...props}
                    ref={textareaRef}
                />
                <div className={labelClassName}>
                    <Text className="text-neutral-500" variant="body-sm">
                        {props.placeholder}
                    </Text>
                </div>
            </label>
            {error && (
                <Text variant="body-sm" color="danger">
                    {error}
                </Text>
            )}
        </div>
    );
};
