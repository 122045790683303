import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { usePlaceHolderTranslations } from "./i18n";

export const AskAiPlaceholder = () => {
    const { t, keys } = usePlaceHolderTranslations();

    return (
        <FlexRow w="full" h="full" alignItems="center">
            <FlexCol ml="1">
                <Text variant="header3" color="primary">
                    {t(keys.title)}
                </Text>
                <Text variant="body" color="secondary" mt="0.5">
                    {t(keys.text)}
                </Text>
            </FlexCol>
        </FlexRow>
    );
};
