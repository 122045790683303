import i18next from "../../../../../i18n";

const enResources = {
    site: "Name",
    shortName: "Short name",
    type: "Type",
    parent: "Parent entity",
    no_parent: "No parent",
    edit_site: "Edit",
    delete_site: "Delete",
    delete_tooltip: "This entity is associated with data and cannot be deleted",
    delete_error: "An error occurred while deleting the entity",
    parent_entity_tooltip: "This entity is a parent entity",
};

type ResourcesKeys = keyof typeof enResources;

const frResources = {
    site: "Nom",
    shortName: "Nom court",
    type: "Type",
    parent: "Entité parente",
    no_parent: "Aucun parent",
    edit_site: "Modifier",
    delete_site: "Supprimer",
    delete_tooltip:
        "Cette entité est associé à des données et ne peut pas être supprimé",
    delete_error: "Une erreur est survenue lors de la suppression de l'entité",
    parent_entity_tooltip: "Cette entité est une entité parente",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "SitesTable";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
