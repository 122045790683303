import { TableCell, TableRow } from "@design-system/Table/HTMLTable";
import { FC } from "react";

import {
    GetObjectivesWithDetailsQuery,
    GetThemesAndSubthemesQuery,
    ObjectiveStatus,
} from "@generated/client/graphql";
import { ObjectiveRow } from "./ObjectiveRow";
import { SubthemeHeader } from "./SubthemeHeader";

interface SubthemeObjectivesListProps {
    subtheme: NonNullable<
        GetThemesAndSubthemesQuery["themes"][number]["subthemes"]
    >[number];
    objectives: NonNullable<
        GetObjectivesWithDetailsQuery["objectives"]
    >[number][];
    themeColor: string;
}

export const SubthemeObjectivesList: FC<SubthemeObjectivesListProps> = ({
    subtheme,
    objectives,
    themeColor,
}) => {
    const subthemeObjectives = objectives.filter(
        (objective) => objective.subthemeId === subtheme.id,
    );

    const completedObjectives = subthemeObjectives.filter(
        (objective) => objective.status === ObjectiveStatus.Defined,
    ).length;

    const totalObjectives = 3; // Minimum number of objectives to consider a subtheme completed

    if (!subtheme.themeId) return null;

    return (
        <>
            <TableRow>
                <TableCell colSpan={4} className="bg-secondary">
                    <SubthemeHeader
                        name={subtheme.name}
                        color={themeColor}
                        completedObjectives={completedObjectives}
                        totalObjectives={totalObjectives}
                        themeId={subtheme.themeId}
                        subthemeId={subtheme.id}
                    />
                </TableCell>
            </TableRow>

            {subthemeObjectives.map((objective) => (
                <ObjectiveRow key={objective.id} objective={objective} />
            ))}
        </>
    );
};
