import BodyHeader from "@app/shared/components/BodyHeader/index.tsx";
import { usePageviewTrack } from "@app/shared/planhat/index.ts";
import { currentUserIsBeavrAtom } from "@app/store/userStore.ts";
import { Box } from "@design-system/Layout/Box";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { useAtomValue } from "jotai";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CreateFrameworkQuestionnaireModal from "./components/CreateFrameworkModal/index.tsx";
import {
    FrameworkCard,
    FrameworkCardSkeleton,
} from "./components/FrameworkCard/index.tsx";
import { NewFrameworksPage } from "./components/NewFrameworksPage/index.tsx";
import { QuestionnaireFrameworkCard } from "./components/QuestionnaireFrameworkCard/index.tsx";
import { useFrameworks, useNotInitializedCmsFrameworks } from "./data.ts";
import "./i18n";
export const Frameworks: FC = () => {
    const { org_uname } = useParams();
    const showNewFrameworksPage = org_uname === "dev";
    const { frameworks, frameworkQuestionnaires, isPending } = useFrameworks();
    const { notInitializedFrameworks } = useNotInitializedCmsFrameworks();
    const { t } = useTranslation("Frameworks");

    usePageviewTrack("pageview_frameworks");
    const { isBeavrUser } = useAtomValue(currentUserIsBeavrAtom);
    return (
        <PageContainer>
            <BodyHeader
                title={t("title")}
                subTitle={t("subtitle")}
                action={
                    isBeavrUser ? <CreateFrameworkQuestionnaireModal /> : null
                }
            />

            {showNewFrameworksPage ? (
                <NewFrameworksPage />
            ) : (
                <Box
                    p="4"
                    display="grid"
                    className="grid-cols-2 gap-y-4 gap-x-2"
                >
                    {!isPending ? (
                        <>
                            {frameworks?.map((framework) => (
                                <FrameworkCard
                                    framework={framework}
                                    key={framework.id}
                                />
                            ))}
                            {frameworkQuestionnaires?.map((framework) => (
                                <QuestionnaireFrameworkCard
                                    framework={framework}
                                    key={framework.id}
                                />
                            ))}
                        </>
                    ) : (
                        new Array(7)
                            .fill(null)
                            .map((_, idx) => (
                                <FrameworkCardSkeleton key={idx} />
                            ))
                    )}
                    {notInitializedFrameworks?.map((framework) => (
                        <FrameworkCard
                            framework={framework}
                            key={framework.id}
                        />
                    ))}
                </Box>
            )}
        </PageContainer>
    );
};
