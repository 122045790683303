import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Sidebar } from "@design-system/Navigation/SideBar";
import { cn } from "@design-system/Utilities";
import { Framework, FrameworkGroup } from "@generated/client/graphql";
import { useAtomValue, useSetAtom } from "jotai";
import { atomWithHash } from "jotai-location";

export const activeSubgroupAtom = atomWithHash<string | undefined>(
    "subgroup",
    undefined,
);

const useNavigate = () => {
    const setActiveSubgroup = useSetAtom(activeSubgroupAtom);
    /**
     * This function scrolls the page smoothly to an element with the given `anchor` (its ID) and waits until the scrolling is complete.
     * It returns a Promise that resolves either when the scroll finishes (i.e., the element is fully in view) or after a timeout of 1 second.
     *
     * - The `anchor` parameter is the ID of the target element you want to scroll to.
     * - The function uses `document.getElementById(anchor)` to locate the element and, if found, sets up an IntersectionObserver.
     *
     * The `IntersectionObserver` is used as a way to detect when the scroll has finished by checking if the target element is fully in view.
     * - The observer uses `threshold: 1`, which means the callback will trigger only when 100% of the element is visible in the viewport.
     * - Once the element is fully visible, the observer disconnects and resolves the promise, confirming that the scroll is complete.
     *
     * To ensure the function resolves even if the scroll takes too long or the element doesn't become fully visible, a timeout of 1 second is set.
     * - If the observer hasn't detected full visibility within 1 second, the promise resolves anyway and the observer disconnects.
     *
     * Once the scroll is complete, the function calls `setActiveSubgroup(anchor)`, which highlights the corresponding subgroup in the navbar.
     * - We wait for the scroll to finish before highlighting the subgroup to ensure the visual state matches the scrolled position.
     */
    const scrollToSubGroup = (anchor: string) => {
        const element = document.getElementById(anchor);
        if (element) {
            return new Promise<void>((resolve) => {
                const handleScrollEnd = () => {
                    observer.disconnect();
                    resolve();
                };
                const observer = new IntersectionObserver(
                    (entries) => {
                        if (entries[0].isIntersecting) {
                            handleScrollEnd();
                        }
                    },
                    {
                        threshold: 1,
                    },
                );
                observer.observe(element);
                element.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    observer.disconnect();
                    resolve();
                }, 1000);
            }).then(() => {
                setActiveSubgroup(anchor);
            });
        }
    };
    const scrollToFirstSubgroup = (group: FrameworkGroup) => {
        if (!!group.subgroups?.length) {
            scrollToSubGroup(group.subgroups[0].name);
        }
    };

    return { scrollToSubGroup, scrollToFirstSubgroup };
};

const Navigation = ({
    framework,
    isLoading,
}: {
    framework?: Framework;
    isLoading?: boolean;
}) => {
    const { scrollToSubGroup, scrollToFirstSubgroup } = useNavigate();
    const activeGroup = useAtomValue(activeSubgroupAtom);
    const isLastGroup = (idx: number, subgroups: FrameworkGroup[]) => {
        return !subgroups || idx === subgroups.length - 1;
    };

    return (
        <Sidebar
            selectedId={activeGroup}
            onSelectedIdChange={scrollToSubGroup}
            className="w-[232px]"
            isLoading={isLoading}
        >
            <Sidebar.Content>
                {framework?.groups?.map((group, idx) => (
                    <Sidebar.Group key={idx}>
                        <FlexCol w="full">
                            <Sidebar.GroupTitle
                                key={idx}
                                title={`${idx + 1}. ${group.name}`}
                                onClick={() => scrollToFirstSubgroup(group)}
                            />
                            {group.subgroups?.map((subgroup, idx2) => (
                                <Sidebar.Item key={idx2} id={subgroup.name}>
                                    {({ isSelected }) => (
                                        <FlexRow
                                            p="2"
                                            alignItems="center"
                                            w="full"
                                            className={cn(
                                                "rounded-lg",
                                                isSelected &&
                                                    "bg-tertiary text-green font-bold",
                                            )}
                                        >
                                            <Sidebar.ItemLabel
                                                title={subgroup.name}
                                            />
                                        </FlexRow>
                                    )}
                                </Sidebar.Item>
                            ))}
                        </FlexCol>
                        {!isLastGroup(idx, framework?.groups || []) && (
                            <Sidebar.Divider />
                        )}
                    </Sidebar.Group>
                ))}
            </Sidebar.Content>
        </Sidebar>
    );
};

export default Navigation;
