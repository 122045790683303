import { graphql } from "@generated/client";
import { type TagType } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetTagsQuery = graphql(`
    query GetTags($type: TagType!) {
        tags(type: $type) {
            id
            name
        }
    }
`);

const UpdateTagMutation = graphql(`
    mutation UpdateTag($id: String!, $name: String!) {
        updateTag(id: $id, name: $name) {
            id
            name
        }
    }
`);

const CreateTagMutation = graphql(`
    mutation CreateTag($type: TagType!, $name: String!) {
        createTag(input: { type: $type, name: $name }) {
            id
            name
        }
    }
`);

const DeleteTagMutation = graphql(`
    mutation DeleteTag($id: String!) {
        deleteTag(id: $id) {
            id
            name
        }
    }
`);

export class GraphQLTagRepository {
    get = async (type: TagType) => {
        return graphqlClientKoyeb.request(
            GetTagsQuery,
            { type },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    update = async (id: string, name: string) => {
        return graphqlClientKoyeb.request(
            UpdateTagMutation,
            { id, name },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    create = async (type: TagType, name: string) => {
        return graphqlClientKoyeb.request(
            CreateTagMutation,
            { type, name },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    delete = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteTagMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
