import { IconProps } from "@design-system/Icon";
import { Button, type ButtonProps } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";

type TagProps = Omit<ButtonProps, "asChild" | "variant" | "size"> & {
    interactive?: boolean;
    variant?: "regular" | "success" | "accent";
    size?: "sm" | "xs";
};

const TagIcon = Button.Icon;
const TagColorIcon: FC<IconProps> = ({
    name,
    className,
    color,
    style,
    ...props
}) => {
    return (
        <Button.Icon
            name={name}
            className={className}
            style={{ ...style, ...(color ? { color } : {}) }}
            {...props}
        />
    );
};

export const Tag: FC<TagProps> & {
    Icon: typeof TagIcon;
    ColorIcon: typeof TagColorIcon;
} = ({
    interactive = false,
    variant = "regular",
    className: classNameProp,
    size = "xs",
    children,
    ...props
}) => {
    const Comp = interactive ? "button" : Box;
    const className = cn(
        classNameProp,
        !interactive &&
            "cursor-default !shadow-none hover:!shadow-none active:!shadow-none pointer-events-none",
        variant === "success" && "text-brand-650 border-green-200 bg-green-50",
    );
    return (
        <Button
            asChild
            size={size}
            variant={variant === "accent" ? "flatAccent" : "outlined"}
            className={className}
            {...props}
        >
            <Comp>{children}</Comp>
        </Button>
    );
};

Tag.Icon = TagIcon;
Tag.ColorIcon = TagColorIcon;
