import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-table";

const keys = addTranslations(RESOURCE_NAME, {
    supplier: {
        en: "Supplier",
        fr: "Fournisseur",
    },
    tags: {
        en: "Tags",
        fr: "Tags",
    },
    noQuestionnaire: {
        en: "No questionnaire",
        fr: "Aucun questionnaire",
    },
    lastQuestionnaire: {
        en: "Last questionnaire",
        fr: "Dernier questionnaire",
    },
    lastQuestionnaireProgress: {
        en: "Last questionnaire progress",
        fr: "Progression du dernier questionnaire",
    },
    lastQuestionnaireStatus: {
        en: "Last questionnaire status",
        fr: "Statut du dernier questionnaire",
    },
    lastUpdated: {
        en: "Last updated",
        fr: "Dernière mise à jour",
    },
    answered_questions: {
        en: "Answered questions:",
        fr: "Questions répondues:",
    },
    status: {
        en: "Status",
        fr: "Statut",
    },
    status_OPEN: {
        en: "Open",
        fr: "Ouvert",
    },
    status_CLOSED: {
        en: "Closed",
        fr: "Fermé",
    },
    copy_link: {
        en: "Copy link to questionnaire",
        fr: "Copier le lien vers le questionnaire",
    },
    copy_link_success: {
        en: "Link copied to clipboard",
        fr: "Lien copié dans le presse-papiers",
    },
    copy_link_error: {
        en: "Failed to copy link to clipboard",
        fr: "Erreur lors de la copie du lien dans le presse-papiers",
    },
    action_bar_selected_suppliers: {
        en: "Selected suppliers",
        fr: "Fournisseurs sélectionnés",
    },
    action_bar_selected_suppliers_one: {
        en: "One supplier selected",
        fr: "Un fournisseur sélectionné",
    },
    action_bar_selected_suppliers_other: {
        en: "{{count}} suppliers selected",
        fr: "{{count}} fournisseurs sélectionnés",
    },
    action_bar_select_action: {
        en: "Select an action",
        fr: "Sélectionner une action",
    },
    action_bar_send_questionnaire: {
        en: "Send questionnaire",
        fr: "Envoyer un questionnaire",
    },
    action_bar_send_reminder: {
        en: "Send reminder",
        fr: "Envoyer un rappel",
    },
    action_bar_close_questionnaire: {
        en: "Close questionnaire",
        fr: "Fermer le questionnaire",
    },
    action_bar_send_reminder_one: {
        en: "Send reminder to one supplier",
        fr: "Envoyer un rappel à un fournisseur",
    },
    action_bar_send_reminder_other: {
        en: "Send reminder to {{count}} suppliers",
        fr: "Envoyer un rappel à {{count}} fournisseurs",
    },
    action_bar_send: {
        en: "Send",
        fr: "Envoyer",
    },
    action_bar_close: {
        en: "Close",
        fr: "Fermer",
    },
    action_bar_close_questionnaire_one: {
        en: "Close open questionnaire of one supplier",
        fr: "Fermer le questionnaire ouvert du fournisseur",
    },
    action_bar_close_questionnaire_other: {
        en: "Close open questionnaire of {{count}} suppliers",
        fr: "Fermer le questionnaire ouvert de {{count}} fournisseurs",
    },
    action_bar_close_questionnaire_success: {
        en: "Questionnaires closed successfully",
        fr: "Questionnaires fermés avec succès",
    },
    action_bar_close_questionnaire_error: {
        en: "Failed to close questionnaires",
        fr: "Erreur lors de la fermeture des questionnaires",
    },
    action_bar_send_questionnaire_success: {
        en: "Questionnaires sent successfully",
        fr: "Questionnaires envoyés avec succès",
    },
    action_bar_send_questionnaire_error: {
        en: "Failed to send questionnaires",
        fr: "Erreur lors de l'envoi des questionnaires",
    },
    action_bar_send_questionnaire_disabled: {
        en: "",
        fr: "",
    },
    action_bar_send_questionnaire_disabled_one: {
        en: "One supplier has an open questionnaire. Close it before sending a new questionnaire.",
        fr: "Un fournisseur a un questionnaire ouvert. Fermez-le avant d'envoyer un nouveau questionnaire.",
    },
    action_bar_send_questionnaire_disabled_other: {
        en: "{{count}} suppliers have an open questionnaire. Close them before sending a new questionnaire.",
        fr: "{{count}} fournisseurs ont un questionnaire ouvert. Fermez-les avant d'envoyer un nouveau questionnaire.",
    },
});

export function useSupplierTableTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
