import { useS3FilesManagement } from "@app/hooks/createFileHook";
import { currentUserAtom } from "@app/store/userStore";
import { Button } from "@design-system/Inputs/Button";
import DropZone from "@design-system/Inputs/DropZone";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateExternalQuestionnaire } from "../../data";
import { useImportQuestionsFromFile } from "../../externalQuestionnaire/data";
import { useQuestionnairesDashboardTranslations } from "./i18n";

const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
];

export const CreateQuestionnaireFromUploadModal: FC = () => {
    const { t, keys } = useQuestionnairesDashboardTranslations();
    const { uploadFiletoS3 } = useS3FilesManagement();
    const { createAsync, isPending: isPendingCreateQuestionnaire } =
        useCreateExternalQuestionnaire();
    const {
        importQuestionsFromFileAsync,
        isPending: isImportingFromFilePending,
    } = useImportQuestionsFromFile();
    const currentUser = useAtomValue(currentUserAtom);

    const navigate = useNavigate();

    const handleFileDrop = async (file: File) => {
        const uploadedFile = await uploadFiletoS3(file);

        // create a new questionnaire from the file with dummy data
        const currentUserLocale =
            currentUser?.data?.currentUser?.locale ?? "fr";

        const { createQuestionnaire } = await createAsync({
            input: {
                name: t(keys.uploaded_file_name, {
                    name: decodeURIComponent(uploadedFile.name),
                }),
                requesterEmail: null,
                companyName: null,
                deadline: null,
                locale: {
                    availableLocales: [currentUserLocale],
                    default: currentUserLocale,
                },
            },
        });

        // create questions using the uploaded file
        await importQuestionsFromFileAsync({
            questionnaireId: createQuestionnaire.data?.id ?? "",
            s3File: uploadedFile,
        });

        navigate(`${createQuestionnaire.data?.id}`);
    };

    return (
        <Modal>
            <Modal.Trigger asChild>
                <Button variant="tonal" size="sm" className="mt-2">
                    {t(keys.upload_file_card_button_label)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay>
                <Modal.Content>
                    <Modal.Header withClose>
                        <FlexRow gap="4">
                            <FlexCol gap="2">
                                <Modal.Title>
                                    {t(keys.upload_file_modal_title)}
                                </Modal.Title>
                            </FlexCol>
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body>
                        {isPendingCreateQuestionnaire ||
                        isImportingFromFilePending ? (
                            <FlexCol
                                alignItems="center"
                                justifyContent="center"
                                gap="2"
                                className="min-h-[200px]"
                            >
                                <Text className="animate-pulse" color="brand">
                                    {t(keys.analyzing_file)}
                                </Text>
                                <div className="loader" />
                            </FlexCol>
                        ) : (
                            <DropZone
                                accept={acceptedFileTypes}
                                buttonText={t(
                                    keys.upload_file_modal_button_label,
                                )}
                                multiple={false}
                                onChange={handleFileDrop}
                                size={"lg"}
                                loading={
                                    isPendingCreateQuestionnaire ||
                                    isImportingFromFilePending
                                }
                                className="w-full h-full"
                            >
                                {t(keys.upload_file_modal_placeholder)}
                                <br />
                                {t(keys.upload_file_modal_accepted_file_types)}
                            </DropZone>
                        )}
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
