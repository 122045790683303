import {
    RegularTabs,
    useEditorContext,
} from "@app/screens/Document/Edit/hooks/useEditor";
import {
    approveAtom,
    editValidationTypeAtom,
    rejectDocumentVersionAtom,
    sendForReviewAtom,
    versionChecksRatioAtom,
} from "@app/store/versionStore";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useAtomValue, useSetAtom } from "jotai/react";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys } from "./i18n";

import { useCheckDocument } from "@app/components/TipTap/components/sidebar/data";
import { browserDownload } from "@app/lib/utils-browser";
import {
    ValidationModal,
    validationModalAtom,
} from "@app/screens/Document/Edit/ctx";
import { usePDFGenerator } from "@app/screens/Document/Edit/hooks";
import { versionKeys } from "@app/store/queryKeys";
import { EditValidationType } from "@app/usecases/VersionUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { DocumentVersion } from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { useUnsendReviewRequest } from "../../../screens/Document/Edit/data";
import "./i18n";

export const ValidationButton: FC = () => {
    const { t } = useTranslation("VersionValidation");
    const setModal = useSetAtom(validationModalAtom);
    const {
        setRegularModeActiveTab,
        version,
        showValidationChecklist,
        setAiCheckLoadingProgress,
    } = useEditorContext();
    const { isPending: isRatioPending } = useAtomValue(versionChecksRatioAtom);

    const validationType = useAtomValue(editValidationTypeAtom);

    const { isPending: isRequestForApprovalPending } =
        useAtomValue(sendForReviewAtom);

    const { mutate: rejectMutation, isPending: isRejectRequestPending } =
        useAtomValue(rejectDocumentVersionAtom);
    const reject = () => rejectMutation();

    const { isPending: isUnsendReviewRequestPending } =
        useUnsendReviewRequest();

    const { mutate: approveMutation, isPending: isApproveMutationPending } =
        useAtomValue(approveAtom);
    const approve = () => approveMutation();

    const { checkDocument } = useCheckDocument(version?.id ?? "");
    const aiCheckDocumentMutationState = useMutationState({
        filters: { mutationKey: [...versionKeys.aiCheck(version?.id ?? "")] },
        select: (mutation) => mutation.state.status,
    });
    const isAiCheckPending =
        !!aiCheckDocumentMutationState?.length &&
        aiCheckDocumentMutationState[
            aiCheckDocumentMutationState.length - 1
        ] === "pending";

    const handleSendForReviewClick = () => {
        setModal(ValidationModal.sendForReview);
    };

    const handleUnsendReviewRequestClick = () => {
        setModal(ValidationModal.unsendReviewRequest);
    };

    const openAiCoachIfAvailable = () => {
        if (
            validationType !== EditValidationType.SendForReview ||
            !showValidationChecklist
        ) {
            return;
        }
        setRegularModeActiveTab(RegularTabs.AI_COACH);
        if (version?.id && !isAiCheckPending) {
            setAiCheckLoadingProgress(0);
            checkDocument();
        }
    };

    const isLoading =
        isRatioPending ||
        isRequestForApprovalPending ||
        isRejectRequestPending ||
        isApproveMutationPending ||
        isUnsendReviewRequestPending;
    const showRequestReviewAndApprove =
        validationType === EditValidationType.ApproveDirectly ||
        validationType === EditValidationType.SendForReview;
    const disableReviewRequest =
        validationType === EditValidationType.ApproveDirectly;

    const { generatePdf, isPending } = usePDFGenerator(
        version as DocumentVersion,
    );

    const downloadFile = () => {
        if (version?.withEditor) {
            generatePdf();
        } else {
            if (!version?.finalFile?.signedUrl) return;
            browserDownload(version?.finalFile?.signedUrl, true);
        }
    };

    return showRequestReviewAndApprove ? (
        <FlexRow w="full" gap="2">
            <Tooltip
                delayDuration={0}
                {...(!disableReviewRequest ? { open: false } : {})}
            >
                <Tooltip.Trigger asChild>
                    <Box
                        className="w-full max-w-[140px]"
                        onClick={openAiCoachIfAvailable}
                    >
                        <Button
                            className="w-full"
                            size="sm"
                            loading={isLoading}
                            onClick={handleSendForReviewClick}
                            disabled={disableReviewRequest}
                            variant="tonal"
                        >
                            {t(keys.request_review)}
                        </Button>
                    </Box>
                </Tooltip.Trigger>
                <Tooltip.Content className="w-[200px]">
                    {validationType === EditValidationType.ApproveDirectly
                        ? t(keys.send_for_review_tooltip_essential)
                        : t(keys.send_for_review_tooltip_checks)}
                </Tooltip.Content>
            </Tooltip>
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <Button
                        className="w-full max-w-[140px]"
                        size="sm"
                        loading={isLoading}
                        onClick={approve}
                    >
                        {t(keys.approve)}
                    </Button>
                </Tooltip.Trigger>
                {validationType === EditValidationType.ApproveDirectly && (
                    <Tooltip.Content className="w-[200px]">
                        {t(keys.approve_tooltip)}
                    </Tooltip.Content>
                )}
            </Tooltip>
        </FlexRow>
    ) : validationType === EditValidationType.PendingCsReview ? (
        <Button
            className="w-full"
            size="sm"
            variant="outlined"
            onClick={handleUnsendReviewRequestClick}
        >
            {t(keys.cancel_review_request)}
            <Icon name="close" size="sm" />
        </Button>
    ) : validationType === EditValidationType.Approved ? (
        <FlexCol
            p="1"
            w="full"
            h="fit"
            alignItems="start"
            gap="0.5"
            alignSelf="stretch"
            className="border border-brand_secondary rounded-lg bg-secondary"
        >
            <FlexRow
                p="1"
                justifyContent="center"
                alignSelf="stretch"
                w="full"
                gap="0.5"
            >
                <Text variant="body-sm-bold">{t(keys.approved)}</Text>
                <Icon
                    name="doubleCheck"
                    size="sm"
                    className="text-beavrGreen"
                ></Icon>
            </FlexRow>
            <Button
                size="sm"
                className="w-full"
                onClick={downloadFile}
                loading={isPending}
            >
                <Text variant="body-sm-bold" color="white">
                    {t(keys.download)}
                </Text>
                <Icon name="download" size="sm" className="text-white"></Icon>
            </Button>
        </FlexCol>
    ) : validationType === EditValidationType.CsReview ? (
        <FlexRow w="full" gap="2">
            <Button
                className="w-full"
                size="sm"
                loading={isLoading}
                onClick={reject}
                variant="flatDanger"
            >
                {t(keys.reject)}
            </Button>
            <Button
                className="w-full max-w-[140px]"
                size="sm"
                loading={isLoading}
                onClick={approve}
            >
                {t(keys.approve)}
            </Button>
        </FlexRow>
    ) : null;
};
