import i18next from "@app/i18n";

const enResources = {
    page_title: "Questionnaire",
    page_subtitle: "External questionnaire",

    tabs_tab_overview: "Overview",
    tabs_tab_questions: "Questions",

    table_header_question: "Question",
    table_header_answer: "Answer",
    table_header_status: "Status",
    table_actions_dd_delete: "Delete",
    table_actions_dd_delete_answer: "Reset answer",
    table_actions_dd_edit: "Edit",

    create_questions_modal_trigger: "Add question",
    create_questions_modal_question_type_dd_label: "Type",

    delete_modal_title: "Delete question",
    delete_modal_description: "Are you sure you want to delete this question?",
    cancel: "Cancel",

    search_bar_placeholder: "Search question",

    empty_questionnaire_section_title: "Create your questionnaire",
    empty_questionnaire_section_description: `Upload your questions or create them; you can either paste the raw text or upload a pdf/xlsx/xlsm/docx file.
    Or simply create them one by one.`,
    empty_questionnaire_section_or: "or",

    empty_table_no_questions_title: "There are no questions yet",
    empty_table_no_questions_subtitle:
        "Create or import questions to get started",

    empty_table_no_filtered_questions_title: "No questions found",
    empty_table_no_filtered_questions_subtitle: "Try changing the filters",

    edit_question_modal_title: "Edit question",

    suggest_all: "Suggest all",
    locales: {
        fr: "French",
        en: "English",
    },
    browse: "Browse",
    upload_your_questionnaire: "Upload your questionnaire",
    import_questions_from_file_error: "Error importing questions from file",
    accepted_file_types: "We accept pdf, excel, and docx files",
    analyzing_file: "Analyzing file...",
    loading_questions: "Loading questions...",
    delete_questionnaire: "Delete questionnaire",
    delete_questionnaire_modal_title: "Delete questionnaire",
    delete_questionnaire_modal_description:
        "Are you sure you want to delete this questionnaire?",
    delete_questionnaire_modal_confirm: "Delete",
    delete_questionnaire_success: "Questionnaire deleted successfully",
    delete_questionnaire_error: "Error deleting questionnaire",
    generating_suggestions: "AI suggestions are being generated...",
    export_questionnaire: "Export questionnaire",
    export_questionnaire_error: "Error exporting questionnaire",
    edit_questionnaire: "Edit questionnaire",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string | Record<string, string>> = {
    page_title: "Questionnaire externe",
    page_subtitle: "Questionnaire externe",

    tabs_tab_overview: "Aperçu",
    tabs_tab_questions: "Questions",

    create_questions_modal_trigger: "Add question",
    create_questions_modal_question_type_dd_label: "Type",

    delete_modal_title: "Supprimer la question",
    delete_modal_description:
        "Êtes-vous sûr de vouloir supprimer cette question ?",

    cancel: "Annuler",

    search_bar_placeholder: "Rechercher une question",

    table_header_question: "Question",
    table_header_answer: "Réponse",
    table_header_status: "Status",
    table_actions_dd_delete: "Supprimer",
    table_actions_dd_delete_answer: "Réinitialiser la réponse",
    table_actions_dd_edit: "Modifier",

    empty_questionnaire_section_title: "Créez votre questionnaire",
    empty_questionnaire_section_description: `Téléchargez vos questions ou créez-les; vous pouvez soit coller le texte brut ou télécharger un fichier pdf/xlsx/xlsm/docx.
    Ou créez-les une par une.`,
    empty_questionnaire_section_or: "ou",

    empty_table_no_questions_title: "Il n'y a pas encore de questions",
    empty_table_no_questions_subtitle:
        "Créez ou importez des questions pour commencer",

    empty_table_no_filtered_questions_title: "Aucune question trouvée",
    empty_table_no_filtered_questions_subtitle:
        "Essayez de changer les filtres",

    suggest_all: "Suggérer tout",
    locales: {
        fr: "Français",
        en: "English",
    },
    edit_question_modal_title: "Modifier la question",
    browse: "Parcourir",
    upload_your_questionnaire: "Uploadez votre questionnaire",
    import_questions_from_file_error:
        "Erreur lors de l'importation des questions depuis le fichier",
    accepted_file_types: "Nous acceptons les fichiers pdf, excel et docx",
    analyzing_file: "Analyse du fichier...",
    loading_questions: "Chargement des questions...",
    delete_questionnaire: "Supprimer le questionnaire",
    delete_questionnaire_modal_title: "Supprimer le questionnaire",
    delete_questionnaire_modal_description:
        "Êtes-vous sûr de vouloir supprimer ce questionnaire ?",
    delete_questionnaire_modal_confirm: "Supprimer",
    delete_questionnaire_success: "Questionnaire supprimé avec succès",
    delete_questionnaire_error:
        "Erreur lors de la suppression du questionnaire",
    generating_suggestions: "Les suggestions sont en cours de génération...",
    export_questionnaire: "Exporter le questionnaire",
    export_questionnaire_error: "Erreur lors de l'export du questionnaire",
    edit_questionnaire: "Modifier le questionnaire",
} as const;

export const RESOURCE_NAME = "ExternalQuestionnaire";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
