import { adminPaths } from "@app/routing/adminPaths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionnaires } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";

export function useExternalQuestionnaireBreadcrumb(id?: string) {
    const { externalQuestionnaires } = useQuestionnaires();
    const externalQuestionnaire = externalQuestionnaires.find(
        (q) => q.id === id,
    );
    const setBreadcrumb = useBreadcrumb();
    const { t } = useTranslation(RESOURCE_NAME);

    useEffect(() => {
        const options =
            externalQuestionnaires.map((q) => ({
                name: q.name,
                param: q.id,
            })) ?? [];

        setBreadcrumb(adminPaths.external_questionnaires, t(keys.page_title));

        setBreadcrumb(
            adminPaths.external_questionnaire_id_param,
            externalQuestionnaire?.name ?? "...",
            options,
        );
    }, [externalQuestionnaires, setBreadcrumb]);
}
