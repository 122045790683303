import { UserTag } from "@app/shared/components/UserTag";
import { nameNormalized } from "@app/shared/utils/tableSortingFns";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { type ColumnDef } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditOrDeleteDropdown } from "./components/EditOrDeleteDropdown";
import { keys, RESOURCE_NAME } from "./i18n";
import { getQuestionnaireProgress, getQuestionnaireStatus } from "./services";
import { ExternalQuestionnairesListItem } from "./types";

export const columns: ColumnDef<ExternalQuestionnairesListItem>[] = [
    {
        id: "status",
        accessorKey: "status",
        meta: {
            cellClassName: "min-w-[50px] max-w-[50px] border-r-0",
            headerClassName: "min-w-[50px] max-w-[50px] border-r-0",
            colWidth: "50px",
        },
        sortingFn: (rowA, rowB) => {
            const statusOrder = { todo: 0, inProgress: 1, validated: 2 };
            const statusA = getQuestionnaireStatus(rowA.original);
            const statusB = getQuestionnaireStatus(rowB.original);
            return statusOrder[statusA] - statusOrder[statusB];
        },
        header: () => {
            return "";
        },
        cell: ({ row }) => {
            const questionnaireStatus = getQuestionnaireStatus(row.original);
            return <StatusTagCircular status={questionnaireStatus} size="sm" />;
        },
    },
    {
        id: "name",
        accessorKey: "name",
        sortingFn: nameNormalized,
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_name);
        },
        cell: ({ row }) => {
            return (
                <Link className="[all:unset]" to={row.original.id}>
                    <Text className="cursor-pointer">{row.original.name}</Text>
                </Link>
            );
        },
    },
    {
        id: "company",
        meta: {
            cellClassName: "min-w-[120px]",
            headerClassName: "min-w-[120px]",
        },
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_company);
        },
        cell: ({ row }) => {
            return row.original.companyName ?? "-";
        },
    },
    {
        id: "progress",
        meta: {
            cellClassName: "min-w-[180px]",
            headerClassName: "min-w-[180px]",
            colWidth: "180px",
        },
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_progress);
        },
        cell: ({ row }) => {
            const { completedQuestions, totalQuestions } =
                getQuestionnaireProgress(row.original);

            if (totalQuestions === 0) {
                return "-";
            }
            return (
                <FlexRow gap="5" w="full">
                    <ProgressBar
                        segments={[
                            {
                                value: completedQuestions,
                                labelValue: `${((completedQuestions || 0) / (totalQuestions || 0)) * 100}%`,
                                label: "N/A",
                            },
                        ]}
                        total={totalQuestions}
                        noDataPlaceholder="N/A"
                    />
                    <Text>{`${completedQuestions} / ${totalQuestions}`}</Text>
                </FlexRow>
            );
        },
    },
    {
        id: "createdBy",
        meta: {
            cellClassName: "min-w-[120px]",
            headerClassName: "min-w-[120px]",
            colWidth: "100px",
        },
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_created_by);
        },
        cell: ({ row }) => {
            return row.original.creatorId ? (
                <FlexRow gap="2" alignItems="center">
                    <UserTag userId={row.original.creatorId} noUserText={""} />
                </FlexRow>
            ) : (
                "-"
            );
        },
    },
    {
        id: "deadline",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_deadline);
        },
        cell: ({ row }) => {
            dayjs.extend(LocalizedFormat);

            const displayDate = row.original.deadline
                ? dayjs(row.original.deadline).format("DD MMM YYYY")
                : "-";

            return (
                <FlexRow gap="2" alignItems="center">
                    <Ellipsis asChild hasTooltip>
                        <Text>{displayDate}</Text>
                    </Ellipsis>
                    {row.original.deadline && (
                        <Icon
                            name="calendar"
                            size="sm"
                            className="text-secondary"
                        />
                    )}
                </FlexRow>
            );
        },
    },
    {
        id: "actions",
        cell: ({ row }) => (
            <Box display="grid" className="place-items-center min-w">
                <EditOrDeleteDropdown questionnaireId={row.original.id}>
                    <Button size="sm" variant="outlined">
                        <Button.Icon name="more" />
                    </Button>
                </EditOrDeleteDropdown>
            </Box>
        ),
        maxSize: 80,
        meta: {
            cellClassName: "min-w-[72px] max-w-[72px]",
            headerClassName: "min-w-[72px] max-w-[72px]",
            colWidth: "72px",
        },
    },
];
