import { Radio as RadioComponent } from "@design-system/Inputs/Radio";
import { Box, type BoxProps } from "@design-system/Layout/Box";
import {
    type FieldProps,
    Field as HeadlessField,
    Label as HeadlessLabel,
    Radio as HeadlessRadio,
    RadioGroup as HeadlessRadioGroup,
    type LabelProps,
    type RadioGroupProps,
    RadioProps,
} from "@headlessui/react";
import { type FC, type Ref } from "react";
import { useFieldStyles, useGroupStyles, useLabelStyles } from "./styles";

export const Field: FC<
    FieldProps & BoxProps & { ref?: Ref<HTMLDivElement> }
> = ({ className, ...props }) => {
    const styles = useFieldStyles(className);
    return <HeadlessField as={Box} className={styles} {...props} />;
};

const Radio = <T,>(
    props: RadioProps<"span", T> & { ref?: Ref<HTMLSpanElement> },
) => {
    return (
        <HeadlessRadio {...props}>
            {({ checked, disabled }) => (
                <RadioComponent checked={checked} disabled={disabled} />
            )}
        </HeadlessRadio>
    );
};

export const Label: FC<LabelProps> = ({ className, ...props }) => {
    const styles = useLabelStyles(className);
    return <HeadlessLabel className={styles} {...props} />;
};

export const RadioGroup = <T,>({
    className,
    ...props
}: RadioGroupProps<"div", T> & { ref?: Ref<HTMLDivElement> }) => {
    const styles = useGroupStyles(className);
    return <HeadlessRadioGroup as={Box} className={styles} {...props} />;
};

RadioGroup.Label = Label;
RadioGroup.Field = Field;
RadioGroup.Radio = Radio;
