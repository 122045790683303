import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useEffect, useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import {
    useGenerateAiSuggestions,
    useQuestionnaireGenerationState,
    useQuestionnaireOverviewData,
    useShouldPollQuestionnaire,
    useShouldPollQuestions,
} from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";
import { isQuestionGenerating } from "../../services";
import { ExternalQuestionnaireOptions } from "../../types";
import { EmptyQuestionnairePlaceholder } from "../EmptyQuestionnairePlaceholder";
import { QuestionsTable } from "./components/QuestionsTable";

export const OverviewTab: FC<{
    questionnaireId: string | undefined;
    options: ExternalQuestionnaireOptions;
}> = ({ questionnaireId, options }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const shouldPollQuestions = useShouldPollQuestions(questionnaireId);
    const {
        questions,
        isPending: isQuestionsPending,
        refetch,
    } = useQuestionnaireOverviewData({
        questionnaireId,
        opts: {
            pollingInterval: shouldPollQuestions ? 1000 : undefined,
        },
    });

    const shouldPollQuestionnaire = useShouldPollQuestionnaire(questionnaireId);
    const { questionnaire, isPending: isQuestionnairePending } =
        useQuestionnaireGenerationState({
            questionnaireId,
            opts: {
                pollingInterval: shouldPollQuestionnaire ? 1000 : undefined,
            },
        });

    useEffect(() => {
        refetch();
    }, [questionnaire?.isGenerating]);

    const isGenerating = useMemo(() => {
        const numGeneratingQuestions = questions?.filter((q) =>
            isQuestionGenerating(q),
        )?.length;

        return !!numGeneratingQuestions && numGeneratingQuestions > 0;
    }, [questions, shouldPollQuestions]);

    const {
        generateAiSuggestions,
        isPending: isGeneratingAiSuggestionsPending,
    } = useGenerateAiSuggestions(questionnaireId);

    const handleSuggestAll = () => {
        if (!questionnaireId) return;
        generateAiSuggestions(questionnaireId);
    };

    const isEmptyQuestionnaire = !isQuestionsPending && !questions?.length;

    const isGeneratingQuestions = questionnaire?.isGenerating ?? false;
    const isLoadingQuestions = isQuestionnairePending || isQuestionsPending;

    const shouldShowPlaceholder =
        (isEmptyQuestionnaire && !searchString) || isGeneratingQuestions;

    return (
        <FlexCol w="full" gap="4">
            {shouldShowPlaceholder ? (
                <EmptyQuestionnairePlaceholder
                    isGeneratingQuestions={isGeneratingQuestions}
                />
            ) : (
                <>
                    <FlexRow justifyContent="between" alignItems="center">
                        <SearchBar
                            placeholder={t(keys.search_bar_placeholder)}
                            searchString={searchString}
                            setSearchString={setSearchString}
                            className="w-[250px]"
                        />
                        <Tooltip delayDuration={0}>
                            <Tooltip.Trigger>
                                <Button
                                    variant="flatAccent"
                                    onClick={handleSuggestAll}
                                    size="sm"
                                    disabled={
                                        isGenerating ||
                                        isGeneratingAiSuggestionsPending
                                    }
                                >
                                    {t(keys.suggest_all)}
                                    <Button.Icon name="stars" />
                                </Button>
                            </Tooltip.Trigger>
                            {isGenerating && (
                                <Tooltip.Content>
                                    {t(keys.generating_suggestions)}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </FlexRow>
                    <QuestionsTable
                        editable={options?.canEditQuestions}
                        questionnaireId={questionnaireId}
                        searchString={searchString}
                        showGroups={false}
                        loading={isLoadingQuestions}
                        questions={questions ?? []}
                    />
                </>
            )}
        </FlexCol>
    );
};
