import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { useLastQuestionnaireBoxTranslations } from "./i18n";

export const EmptyState: FC = () => {
    const { t, keys } = useLastQuestionnaireBoxTranslations();

    return (
        <FlexCol br="lg" w="full" className="border border-brand">
            <FlexRow
                px="4"
                h="8"
                alignItems="center"
                className="bg-[#EEFFF2] rounded-t-lg border-b border-secondary"
            >
                <Text variant="body-sm-bold" color="brand">
                    {t(keys.last_questionnaire)}
                </Text>
            </FlexRow>
            <FlexCol px="5" py="4" gap="3" w="full" alignItems="center">
                <Text variant="body">{t(keys.empty_state_title)}</Text>
            </FlexCol>
        </FlexCol>
    );
};
