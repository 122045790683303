import { Plus } from "lucide-react";
import React, { Ref } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../../../lib/utils";
import Avatar from "../../../User/Avatar";

interface AssignButtonProps {
    size: "small" | "regular";
    userName?: string;
}

const AssignButton = React.forwardRef(
    (
        {
            userName,
            size,
            className,
            ...props
        }: AssignButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        ref: Ref<HTMLButtonElement>,
    ) => {
        const { t } = useTranslation("UserDropdown");

        return (
            <button
                ref={ref}
                className={cn(
                    "max-w-full flex border rounded-full border-tertiary bg-tertiary items-center p-0.5 focus:outline-none focus-within:border-primary hover:border-primary hover:bg-quarternary truncate ",
                    size === "small" && "h-[26px]",
                    size === "regular" && "h-[30px]",
                    className,
                )}
                {...props}
            >
                {userName ? (
                    <Avatar
                        size={size === "small" ? "small" : "medium"}
                        initials={userName[0]}
                    />
                ) : (
                    <div
                        className={cn(
                            "rounded-full flex items-center justify-center border bg-white border-secondary ",
                            size === "small" ? "h-5 w-5" : "h-6 w-6",
                        )}
                    >
                        <Plus
                            className={cn(
                                "text-secondary",
                                size === "small"
                                    ? "w-[15px] h-[15px]"
                                    : "w-[18px] h-[18px]",
                            )}
                        />
                    </div>
                )}

                <div
                    className={cn(
                        "px-1.5 truncate font-bold",
                        size === "small" ? "text-sm" : "text-base",
                    )}
                >
                    {userName || t("assign_to")}
                </div>
            </button>
        );
    },
);

export default AssignButton;
