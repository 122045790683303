import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./i18n";

export default function Hubspot() {
    const navigate = useNavigate();
    const { org_uname } = useParams();
    const scriptRef = useRef<HTMLScriptElement | null>(null);

    useEffect(() => {
        if (scriptRef.current) {
            document.body.removeChild(scriptRef.current);
        }

        const script = document.createElement("script");
        script.src = "https://js-eu1.hsforms.net/forms/embed/26313404.js";
        script.defer = true;
        script.async = true;
        scriptRef.current = script;
        document.body.appendChild(script);

        return () => {
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current);
                scriptRef.current = null;
            }
        };
    }, []);

    const { t } = useTranslation("Hubspot");

    const handleGoBack = () => {
        navigate(`/o/${org_uname}/admin/frameworks`);
    };

    return (
        <div className="h-screen w-full p-12 overflow-scroll">
            <FlexRow justifyContent="start" gap="4">
                <Button onClick={handleGoBack}>
                    <Icon name="arrowLeft" />
                    {t("go_back")}
                </Button>
            </FlexRow>
            <div
                className="hs-form-frame"
                data-region="eu1"
                data-form-id="840e0050-9809-4e07-b742-7e234a0d6d10"
                data-portal-id="26313404"
                key={scriptRef.current?.src}
            />
        </div>
    );
}
