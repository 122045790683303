import { nameNormalized } from "@app/shared/utils/tableSortingFns";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import {
    CheckboxCell,
    CheckboxHeader,
    type ColumnDef,
} from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "../../../i18n";

import {
    lastUrlUpdateByClickTimestampAtom,
    questionnaireParamsArgs,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/ctx";
import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { questionnaireTabs } from "@app/screens/ExternalQuestionnaires/services";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { AnswerStatus } from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { useQueryParam } from "use-query-params";
import { EditOrDeleteDropdown } from "../../EditOrDeleteDropdown";
import { answerColumn } from "./answerCol";
import { evidenceColumn } from "./evidenceCol";
import { groupsCol } from "./groupsCol";
import { ownerCol } from "./ownerCol";
import { statusColumn } from "./statusCol";

const selectCol: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "select",
    header: ({ table }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const can = true;
        return can ? (
            <CheckboxHeader table={table} />
        ) : (
            <Tooltip>
                <Tooltip.Trigger>
                    <CheckboxHeader table={table} disabled />
                </Tooltip.Trigger>
                <Tooltip.Content>
                    {t(keys.table_header_checkbox_disabled)}
                </Tooltip.Content>
            </Tooltip>
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const isQuestionValidated =
            row.original.answer?.status == AnswerStatus.Validated;
        return isQuestionValidated ? (
            <Tooltip>
                <Tooltip.Trigger>
                    <CheckboxCell row={row} disabled />
                </Tooltip.Trigger>
                <Tooltip.Content>
                    {t(keys.question_checkbox_disabled)}
                </Tooltip.Content>
            </Tooltip>
        ) : (
            <CheckboxCell row={row} />
        );
    },
    size: 50,
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[50px] max-w-[50px] border-r-0",
        cellClassName: "min-w-[50px] max-w-[50px] border-r-0",
    },
};

const QuestionDependentIcon = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <Tooltip>
            <Tooltip.Trigger>
                <Box className="rounded bg-tertiary" color="secondary">
                    <Icon name="cornerDownRight" size="sm" />
                </Box>
            </Tooltip.Trigger>
            <Tooltip.Content>
                {t(keys.question_dependent_tooltip)}
            </Tooltip.Content>
        </Tooltip>
    );
};

const nameColumn: ColumnDef<QuestionnaireOverviewData[number]> = {
    accessorKey: "name",
    sortingFn: nameNormalized,
    id: "question",
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.table_header_question);
    },
    cell: ({ row }) => {
        const text = row.original.name;
        const isQuestionDependant =
            !!row.original.metadata?.dependsOn?.questionId;
        const [, setActiveTabParam] = useQueryParam(
            ...questionnaireParamsArgs.tab,
        );
        const [, setGroupParam] = useQueryParam(
            ...questionnaireParamsArgs.group,
        );
        const [, setQuestionIdParam] = useQueryParam(
            ...questionnaireParamsArgs.questionId,
        );
        const setLastUrlUpdateByClickTimestamp = useSetAtom(
            lastUrlUpdateByClickTimestampAtom,
        );

        const handleQuestionClick = () => {
            setActiveTabParam(questionnaireTabs.questions);
            setLastUrlUpdateByClickTimestamp(new Date());
            if (row.original.group?.id) {
                setGroupParam(row.original.group?.id);
            }
            setQuestionIdParam(row.original.id);
        };

        return (
            <FlexRow gap="2" alignItems="center">
                {isQuestionDependant && <QuestionDependentIcon />}
                <a className="[all:unset]" onClick={handleQuestionClick}>
                    <Ellipsis
                        lineClamp={2}
                        hasTooltip
                        asChild
                        className={"cursor-pointer"}
                    >
                        <Text> {text}</Text>
                    </Ellipsis>
                </a>
            </FlexRow>
        );
    },
    meta: {
        cellClassName: "max-w-[300px]",
        headerClassName: "max-w-[300px]",
        colWidth: "300px",
    },
};

const actionsColumn: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "actions",
    cell: ({ row }: { row: Row<QuestionnaireOverviewData[number]> }) => (
        <Box display="grid" className="place-items-center min-w">
            <EditOrDeleteDropdown
                questionId={row.original.id}
                questionnaireId={row.original.questionnaireId ?? ""}
                questionAnswer={row.original.answer}
            >
                <Button size="sm" variant="outlined">
                    <Button.Icon name="more" />
                </Button>
            </EditOrDeleteDropdown>
        </Box>
    ),
    maxSize: 80,
    meta: {
        cellClassName: "min-w-[72px] max-w-[72px]",
        headerClassName: "min-w-[72px] max-w-[72px]",
        colWidth: "72px",
    },
};

export const useColumns = ({
    editable,
    showGroups,
}: {
    editable: boolean;
    showGroups?: boolean;
}): ColumnDef<QuestionnaireOverviewData[number]>[] => {
    return useMemo(() => {
        return [
            selectCol,
            statusColumn,
            nameColumn,
            answerColumn,
            ...(showGroups ? [groupsCol] : []),
            evidenceColumn,
            ownerCol,
            ...(editable ? [actionsColumn] : []),
        ];
    }, [editable, showGroups]);
};
