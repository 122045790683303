import {
    FrameworkActivation,
    FrameworkType,
    GetFrameworksQuery,
} from "@generated/client/graphql";
import {
    FrameworkItem,
    type NonQuestionnaireFramework,
    type ReleaseStatus,
} from "./types";

export const NON_QUESTIONNAIRE_CMS_IDS: NonQuestionnaireFramework[] = [
    "csrd",
    "evs-2023",
];

export function isQuestionnaireFramework(
    cmsId: string,
): cmsId is NonQuestionnaireFramework {
    return !NON_QUESTIONNAIRE_CMS_IDS.includes(
        cmsId as NonQuestionnaireFramework,
    );
}

export const frameworksColorsBycmsId: Record<
    string,
    { primary: string; secondary: string }
> = {
    "evs-2023": {
        primary: "#03A365",
        secondary: "#FFC700",
    },
    csrd: {
        primary: "#0051AF",
        secondary: "#87C6AE",
    },
    "cdp-main": {
        primary: "#b31c2c",
        secondary: "#b31c2c",
    },
    ungc: {
        primary: "#4c6b8b",
        secondary: "#4c6b8b",
    },
};

export const releaseStatus: Record<string, ReleaseStatus> = {
    released: "released",
    beta: "beta",
    comingSoon: "coming_soon",
    backlog: "backlog",
};

const releaseStatusOrder = {
    [releaseStatus.released]: 1,
    [releaseStatus.beta]: 2,
    [releaseStatus.comingSoon]: 3,
    [releaseStatus.backlog]: 4,
};

export const sortByReleaseStatusCb = (a: FrameworkItem, b: FrameworkItem) => {
    return (
        releaseStatusOrder[a.releaseStatus] -
        releaseStatusOrder[b.releaseStatus]
    );
};

export const betaFrameworks = ["csrd"];
export const comingSoonFrameworks = ["iso-14001", "iso-45001", "iso-50001"];

export function getReleaseStatus(released: boolean, cmsId: string) {
    if (released) return releaseStatus.released;
    if (betaFrameworks.includes(cmsId)) return releaseStatus.beta;
    if (comingSoonFrameworks.includes(cmsId)) return releaseStatus.comingSoon;
    return releaseStatus.backlog;
}

export function canBeAccessed(framework: FrameworkItem): boolean {
    return (
        framework.releaseStatus === releaseStatus.released &&
        framework.activation === FrameworkActivation.Active
    );
}

export const isSupportedFrameworkType = (
    type: FrameworkType | null | undefined,
): boolean => {
    return type != FrameworkType.InvestorRequest;
};

export function getFrameworks(
    gqlFrameworks?: GetFrameworksQuery["frameworks"],
): FrameworkItem[] | undefined {
    if (!gqlFrameworks) return undefined;

    const frameworks = gqlFrameworks.map(({ released, ...framework }) => {
        const color = frameworksColorsBycmsId[framework.cmsId]
            ? frameworksColorsBycmsId[framework.cmsId]
            : undefined;

        return {
            ...framework,
            color,
            releaseStatus: getReleaseStatus(!!released, framework.cmsId),
        };
    });

    const orderedFrameworks = frameworks
        .sort((a, b) => {
            // order by order
            return a.order === null ||
                a.order === undefined ||
                b.order === null ||
                b.order === undefined
                ? 0
                : a.order - b.order;
        })
        .sort(sortByReleaseStatusCb); // show active ones first, then beta, then coming soon

    const filteredFrameworks = orderedFrameworks.filter((f) =>
        isSupportedFrameworkType(f.type),
    );
    return filteredFrameworks;
}

export function getFrameworkColor(cmsId: string | undefined | null) {
    return cmsId
        ? frameworksColorsBycmsId[cmsId]?.primary || "#000000"
        : "#000000";
}

export function makeDummyData() {
    return [
        {
            cmsId: "evs-2023",
            id: "evs-2023",
            name: "EcoVadis",
            description: "EcoVadis sustainability assessment",
            active: true,
            logoUrl:
                "https://beavr-static.s3.eu-west-3.amazonaws.com/public/frameworks/evs.png",
            stats: {
                total: 100,
                validated: 50,
                aiAnswered: 20,
                missing: 30,
            },
            assignments: 10.3,
        },
        {
            cmsId: "evs-2023",
            id: "evs-2023-1",
            name: "EcoVadis",
            description: "EcoVadis sustainability assessment",
            active: false,
            logoUrl:
                "https://beavr-static.s3.eu-west-3.amazonaws.com/public/frameworks/evs.png",
            stats: {
                total: 100,
                validated: 50,
                aiAnswered: 20,
                missing: 30,
            },
            assignments: 10.3,
        },
    ];
}
