import { useSetToast } from "@app/components/Toast";
import {
    type QuestionCreateInput,
    type QuestionUpdateInput,
} from "@generated/client/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCreateQuestion } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { keys, RESOURCE_NAME } from "../../i18n";
import { QuestionModal } from "../QuestionModal";

export const CreateQuestionsModal = ({
    questionnaireId,
}: {
    questionnaireId?: string;
}) => {
    const { createQuestion, isPending } = useCreateQuestion(questionnaireId);
    const { setToastSuccess, setToastError } = useSetToast();
    const { t } = useTranslation(RESOURCE_NAME);
    const [open, setOpen] = useState(false);

    const handleConfirm = (
        question: QuestionCreateInput | QuestionUpdateInput,
    ) => {
        createQuestion(question as QuestionCreateInput, {
            onSuccess: () => {
                setToastSuccess(t(keys.create_questions_modal_success_message));
            },
            onError: () => {
                setToastError(t(keys.create_questions_modal_error_message));
            },
        });
    };

    const openModal = () => {
        setOpen(true);
    };

    return (
        <Box alignItems="center" justifyContent="center">
            <Button variant="flatPrimary" onClick={openModal}>
                <Button.Icon name="plus" />
                {t(keys.create_questions_modal_trigger)}
            </Button>
            {questionnaireId && (
                <QuestionModal
                    hasKeepOpenOnConfirmOption
                    loading={isPending}
                    onConfirm={handleConfirm}
                    open={open}
                    setOpen={setOpen}
                    questionnaireId={questionnaireId}
                />
            )}
        </Box>
    );
};
