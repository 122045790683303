import {
    Header,
    HeaderFullSkeleton,
} from "@app/shared/components/BackgroundedHeader";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Badge } from "@design-system/DataDisplay/Badge";
import { Gauge } from "@design-system/DataDisplay/Gauge";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { questionnaireLocaleAtom } from "../../ctx";
import { useQuestionnaireHeaderData } from "../../data";
import "../../i18n";
import { QuestionnaireLocaleDropdown } from "./QuestionnaireLanguageDropdown";
import { useQuestionnaireHeaderTranslations } from "./i18n";

export const QuestionnaireHeader = ({
    questionnaireId,
    bgColor = "#FAFAFA",
}: {
    questionnaireId?: string;
    bgColor?: string;
}) => {
    const { t, keys } = useQuestionnaireHeaderTranslations();
    const { questionnaireHeaderData, stats, isPending } =
        useQuestionnaireHeaderData(questionnaireId);
    const setCurrentLocale = useSetAtom(questionnaireLocaleAtom);

    useEffect(() => {
        setCurrentLocale(
            questionnaireHeaderData?.locale?.default as SupportedLocales,
        );
    }, [questionnaireHeaderData?.locale?.default]);

    const hasDeadline = typeof stats?.deadline?.remainingWeeks === "number";

    const variant = chroma(bgColor).luminance() > 0.5 ? "light" : "dark";

    if (!questionnaireHeaderData) return <HeaderFullSkeleton />;

    const remainingWeeks = stats?.deadline?.remainingWeeks;
    const remainingWeeksText =
        remainingWeeks === 1 || remainingWeeks === 0
            ? t(keys.stats_remaining_singular_week, {
                  count: remainingWeeks,
              })
            : t(keys.stats_remaining_weeks, {
                  count: remainingWeeks,
              });
    const statsTotalText =
        stats?.totalQuestions === 1 || stats?.totalQuestions === 0
            ? t(keys.stats_total_singular, {
                  count: stats?.totalQuestions,
              })
            : t(keys.stats_total, {
                  count: stats?.totalQuestions,
              });
    return (
        <Header bgColor={bgColor} className="border border-tertiary rounded-xl">
            <Header.LeftColumn>
                <FlexCol gap="2" h="full" pt="2" pb="6">
                    <QuestionnaireLocaleDropdown
                        questionnaireId={questionnaireId}
                        variant={variant}
                    />

                    <Header.Title
                        color={variant === "light" ? "primary" : "white"}
                    >
                        {questionnaireHeaderData.name}
                    </Header.Title>
                </FlexCol>
            </Header.LeftColumn>
            <Header.RightColumn>
                {stats ? (
                    <FlexCol h="full" w="full" justifyContent="center" p="5">
                        <FlexRow alignItems="center" justifyContent="between">
                            <Text
                                color={
                                    variant === "light" ? "primary" : "white"
                                }
                                variant="header2"
                            >
                                {stats.validatedQuestions.percentage}%
                            </Text>
                            {!hasDeadline ? null : stats?.deadline
                                  ?.remainingWeeks &&
                              stats.deadline.remainingWeeks > 0 ? (
                                <Badge
                                    content={remainingWeeksText}
                                    variant="error"
                                />
                            ) : (
                                <Badge
                                    content={t(keys.stats_overdue)}
                                    variant="error"
                                />
                            )}
                        </FlexRow>
                        <Gauge>
                            <Gauge.List>
                                <Gauge.Item
                                    value={stats.validatedQuestions.percentage}
                                    color={chroma.hex("#03A365")}
                                    label={t(keys.stats_validated, {
                                        count: stats.validatedQuestions.count,
                                    })}
                                />
                                <Gauge.Item
                                    value={
                                        stats.nonValidatedQuestions.percentage
                                    }
                                    color={chroma.hex("#E6E6E6")}
                                    label={t(keys.stats_non_validated, {
                                        count: stats.nonValidatedQuestions
                                            .count,
                                    })}
                                />
                            </Gauge.List>
                        </Gauge>
                        <FlexRow justifyContent="between">
                            <Text
                                color={
                                    variant === "light" ? "primary" : "white"
                                }
                            >
                                {t(keys.stats_validated, {
                                    count: stats.validatedQuestions.count,
                                })}
                            </Text>
                            <Text
                                color={
                                    variant === "light" ? "primary" : "white"
                                }
                            >
                                {statsTotalText}
                            </Text>
                        </FlexRow>
                    </FlexCol>
                ) : isPending ? (
                    <FlexCol
                        h="full"
                        w="full"
                        justifyContent="center"
                        p="5"
                        gap="2"
                    >
                        <Skeleton h="6" w="1/6" />
                        <Skeleton h="2" w="full" />
                        <FlexRow justifyContent="between">
                            <Skeleton h="5" w="1/4" />
                            <Skeleton h="5" w="1/4" />
                        </FlexRow>
                    </FlexCol>
                ) : null}
            </Header.RightColumn>
        </Header>
    );
};
