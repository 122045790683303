import { questionnaireLocaleAtom } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/ctx";
import { useQuestionnaireLocale } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { useCurrentUserLocale } from "@app/shared/utils/currentUserLocale";
import { SupportedLocales } from "@app/shared/utils/locales";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexRow } from "@design-system/Layout/Flex";
import { useAtom } from "jotai";
import { useEffect, type FC } from "react";
import { useQueryParam } from "use-query-params";
import { filterParamArgs } from "../../ctx";
import { useSupplierQuestionsHeaderTranslations } from "./i18n";

export const QuestionsHeader: FC<{ questionnaireId?: string }> = ({
    questionnaireId,
}) => {
    const [search, setSearch] = useQueryParam(...filterParamArgs.search);

    const { availableLocales, defaultLocale } =
        useQuestionnaireLocale(questionnaireId);

    const [currentLocale, setCurrentLocale] = useAtom(questionnaireLocaleAtom);
    const userLocale = useCurrentUserLocale(SupportedLocales.EN);

    useEffect(() => {
        setCurrentLocale(defaultLocale);
    }, [availableLocales]);

    const { t, keys } = useSupplierQuestionsHeaderTranslations();

    return (
        <FlexRow gap="2" alignItems="center">
            <LocaleSelect
                value={currentLocale ?? userLocale}
                onChange={setCurrentLocale}
                options={availableLocales}
                hasPortal
            />
            <SearchBar
                searchString={search}
                setSearchString={setSearch}
                placeholder={t(keys.search)}
            />
        </FlexRow>
    );
};
