import { SectionSkeleton } from "@app/screens/Frameworks/framework/components/Section";
import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { QuestionFilter } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useQueryParam } from "use-query-params";
import { questionnaireLocaleAtom, questionnaireParamsArgs } from "../../../ctx";
import { useQuestions } from "../../../data";
import { type QuestionList } from "../../../types";
import { useQuestionnaireGroups } from "../data";
import { useQuestionsTabTranslations } from "./i18n";
import { Question } from "./Question";

export const Questions = ({ filter }: { filter?: QuestionFilter }) => {
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { t, keys } = useQuestionsTabTranslations();
    const { groups, isPending: isGroupsPending } = useQuestionnaireGroups(
        filter?.questionnaireId ?? undefined,
        currentLocale,
    );
    const [activeGroupIdParams] = useQueryParam(
        ...questionnaireParamsArgs.group,
    );

    const { questions, isPending: isQuestionsPending } = useQuestions({
        searchString: filter?.searchString ?? undefined,
        questionnaireId: filter?.questionnaireId ?? undefined,
        groupId: activeGroupIdParams ?? undefined,
        ownerIds: filter?.ownerIds ?? [],
        statuses: filter?.statuses ?? [],
        aiAnswerStatuses: filter?.aiAnswerStatuses ?? [],
    });

    const filterQuestionsByDependency = (questionsToFilter?: QuestionList) => {
        if (!questionsToFilter) return [];

        return questionsToFilter.filter((question) => {
            if (!question.metadata?.dependsOn) return true;

            const parentQuestion = questions?.find(
                (q) => q.id === question.metadata?.dependsOn?.questionId,
            );
            if (!parentQuestion?.answer) return false;

            return question.metadata?.dependsOn?.displayIfSelected?.some(
                (requiredAnswerIndex) =>
                    parentQuestion.answer?.value?.options?.includes(
                        requiredAnswerIndex,
                    ),
            );
        });
    };

    const filteredQuestions = useMemo(() => {
        return filterQuestionsByDependency(questions);
    }, [questions, activeGroupIdParams]);

    const activeGroup = useMemo(() => {
        if (!groups?.length) return null;
        return groups.find((group) => group.id === activeGroupIdParams);
    }, [groups, activeGroupIdParams]);

    if (isGroupsPending || isQuestionsPending) {
        return (
            <FlexCol gap="4" w="full" p="4">
                {Array.from({ length: 2 }).map((_, i) => (
                    <SectionSkeleton key={i} />
                ))}
            </FlexCol>
        );
    }

    if (activeGroup) {
        return (
            <FlexCol
                className={cn("bg-tertiary border border-tertiary")}
                br="lg"
                w="full"
                p="4"
                gap="3"
            >
                <FlexCol gap="3">
                    <Text
                        variant="header2"
                        id={activeGroupIdParams ?? ""}
                        p="3"
                    >
                        {activeGroup.name}
                    </Text>
                    <FlexCol gap="1">
                        {filteredQuestions?.length > 0 ? (
                            filteredQuestions?.map((question) => (
                                <Question
                                    question={question}
                                    key={question.id}
                                    locale={currentLocale}
                                    filter={{ groupId: activeGroupIdParams }}
                                />
                            ))
                        ) : (
                            <FlexCol
                                py="10"
                                justifyContent="center"
                                alignItems="center"
                                w="full"
                                className="bg-neutral-25"
                            >
                                <FlexCol
                                    gap="2"
                                    alignItems="center"
                                    className="my-8"
                                >
                                    <Icon name="search" />
                                    <FlexCol gap="1" alignItems="center">
                                        <Text variant="body-sm-bold">
                                            {t(
                                                keys.empty_questions_section_title,
                                            )}
                                        </Text>
                                        <Text
                                            variant="body-sm"
                                            className="font-light"
                                        >
                                            {t(
                                                keys.empty_questions_section_subtitle,
                                            )}
                                        </Text>
                                    </FlexCol>
                                </FlexCol>
                            </FlexCol>
                        )}
                    </FlexCol>
                </FlexCol>
            </FlexCol>
        );
    } else {
        return (
            <FlexCol
                className={cn("bg-tertiary border border-tertiary")}
                br="lg"
                w="full"
                p="4"
                gap="1"
            >
                {filteredQuestions?.map((question) => (
                    <Question
                        key={question.id}
                        question={question}
                        locale={currentLocale}
                    />
                ))}
            </FlexCol>
        );
    }
};
