import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";
import {
    QuestionnaireUpdateInput,
    type AnswerUpsertInput,
    type CreateQuestionsFromFileInput,
    type QuestionCreateInput,
    type QuestionFilter,
    type QuestionnaireCreateInput,
    type QuestionUpdateInput,
} from "@generated/client/graphql";

const GetQuestionnaireHeaderDataQuery = graphql(`
    query GetQuestionnaireHeaderData($id: ID!) {
        questionnaire(id: $id) {
            id
            cmsId
            name
            deadline
            questions {
                answer {
                    status
                }
            }
            locale {
                availableLocales
                default
            }
        }
    }
`);

const GetQuestionnaireDetailsQuery = graphql(`
    query GetQuestionnaireDetails($id: ID!) {
        questionnaire(id: $id) {
            id
            cmsId
            name
            deadline
            requesterEmail
            companyName
            locale {
                availableLocales
                default
            }
        }
    }
`);

const GetQuestionnaireGenerationStateQuery = graphql(`
    query GetQuestionnaireGenerationState($id: ID!) {
        questionnaire(id: $id) {
            isGenerating
        }
    }
`);

const GetQuestionnairesQuery = graphql(`
    query GetQuestionnaires {
        questionnaires {
            id
            cmsId
            name
            companyName
            requesterEmail
            deadline
            questions {
                answer {
                    status
                }
            }
            creatorId
        }
    }
`);

const CreateExternalQuestionnaireQuery = graphql(`
    mutation CreateExternalQuestionnaire($input: QuestionnaireCreateInput!) {
        createQuestionnaire(input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const UpdateQuestionnaireQuery = graphql(`
    mutation UpdateQuestionnaire($id: ID!, $input: QuestionnaireUpdateInput!) {
        updateQuestionnaire(id: $id, input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const CreateQuestionMutation = graphql(`
    mutation CreateQuestion($input: QuestionCreateInput!) {
        createQuestion(input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const CreateQuestionsMutation = graphql(`
    mutation CreateQuestions($input: [QuestionCreateInput!]!) {
        createQuestions(input: $input) {
            status
        }
    }
`);

const CreateQuestionnaireFromTemplateMutation = graphql(`
    mutation CreateQuestionnaireFromTemplate(
        $templateId: ID!
        $input: QuestionnaireCreateInput!
    ) {
        createQuestionnaireFromTemplate(
            templateId: $templateId
            input: $input
        ) {
            status
            data {
                id
            }
        }
    }
`);

const GetQuestionnaireOverviewDataQuery = graphql(`
    query GetQuestionnaireOverviewData(
        $filter: QuestionFilter!
        $locale: String
    ) {
        questions(filter: $filter, locale: $locale) {
            id
            activation
            questionnaireId
            name
            description
            order
            type
            options
            type
            ownerId
            metadata {
                dependsOn {
                    questionId
                    displayIfSelected
                }
            }
            answer {
                id
                status
                value
                aiSuggestion {
                    success
                    value
                    isGenerating
                }
                lastGeneratedAt
                evidenceFiles {
                    id
                    name
                    signedUrl
                    mimeType
                    documentVersions {
                        document {
                            name
                        }
                    }
                }
            }
            group {
                id
                name
                order
                level
            }
        }
    }
`);

const GetQuestionsQuery = graphql(`
    query GetQuestions($filter: QuestionFilter!, $locale: String) {
        questions(filter: $filter, locale: $locale) {
            id
            activation
            questionnaireId
            order
            name
            type
            options
            type
            description
            metadata {
                options {
                    name {
                        en
                        fr
                    }
                    proof
                }
                unit
                dependsOn {
                    questionId
                    displayIfSelected
                }
            }
            ownerId
            questionnaireId
            guidance
            guidanceExpertise
            answer {
                id
                value
                comment
                status
                aiSuggestion {
                    documentCitations {
                        documentId
                        documentFileName
                        text
                    }
                    indicatorCitations {
                        indicatorId
                    }
                    motivation
                    success
                    value
                    comment
                    isGenerating
                }
                evidenceFiles {
                    id
                    name
                    signedUrl
                    mimeType
                    documentVersions {
                        document {
                            name
                        }
                    }
                }
            }
        }
    }
`);

const GetQuestionQuery = graphql(`
    query GetQuestion($id: ID!) {
        question(id: $id) {
            id
            activation
            answer {
                id
                value
                comment
                status
                aiSuggestion {
                    documentCitations {
                        documentId
                        documentFileName
                        text
                    }
                    indicatorCitations {
                        indicatorId
                    }
                    motivation
                    success
                    value
                    comment
                    isGenerating
                }
                evidenceFiles {
                    id
                    name
                    signedUrl
                    mimeType
                    documentVersions {
                        document {
                            name
                        }
                    }
                }
            }
            name
            nameMultiLocale {
                en
                fr
            }
            description
            descriptionMultiLocale {
                en
                fr
            }
            type
            metadata {
                options {
                    name {
                        en
                        fr
                    }
                    proof
                }
                unit
                dependsOn {
                    questionId
                    displayIfSelected
                }
            }
            ownerId
            questionnaireId
        }
    }
`);

const GetQuestionnairesDataPageQuery = graphql(`
    query GetQuestionnairesDataPage {
        frameworkQuestionnairesDataPage {
            questionnaireDataPages {
                id
                name
                cmsId
                description
                active
                assignment
                stats {
                    total
                    answered
                    aiSuggestionSuccess
                    validated
                    notAnswered
                }
                logoUrl
                faq {
                    question
                    answer
                }
                articleLink
                demoLink
                websiteLink
            }
        }
    }
`);

const UpdateQuestionMutation = graphql(`
    mutation UpdateQuestion($id: ID!, $input: QuestionUpdateInput!) {
        updateQuestion(id: $id, input: $input) {
            status
            error {
                message
            }
            data {
                id
            }
        }
    }
`);

const UpdateQuestionsMutation = graphql(`
    mutation UpdateQuestions($ids: [ID!]!, $input: QuestionUpdateInput!) {
        updateQuestions(ids: $ids, input: $input)
    }
`);

const UpsertAnswerMutation = graphql(`
    mutation UpsertAnswer($input: AnswerUpsertInput!) {
        upsertAnswer(input: $input) {
            id
        }
    }
`);

const DeleteQuestionMutation = graphql(`
    mutation DeleteQuestion($id: ID!) {
        deleteQuestion(id: $id) {
            status
            error {
                message
            }
            data {
                id
            }
        }
    }
`);

const DeleteQuestionsMutation = graphql(`
    mutation DeleteQuestions($ids: [ID!]!) {
        deleteQuestions(ids: $ids) {
            status
            error {
                message
            }
        }
    }
`);

const GenerateQuestionAiSuggestionMutation = graphql(`
    mutation generateQuestionAiSuggestion($id: ID!) {
        generateQuestionAiSuggestion(id: $id) {
            status
            error {
                message
            }
        }
    }
`);

const DeleteAnswerMutation = graphql(`
    mutation DeleteAnswer($id: ID!) {
        deleteAnswer(id: $id) {
            id
        }
    }
`);

const ImportQuestionsFromFileMutation = graphql(`
    mutation ImportQuestionsFromFile(
        $questionnaireId: ID!
        $input: CreateQuestionsFromFileInput!
    ) {
        createQuestionsFromFile(
            questionnaireId: $questionnaireId
            input: $input
        ) {
            status
            error {
                message
            }
        }
    }
`);

export const DeleteExternalQuestionnaireMutation = graphql(`
    mutation DeleteExternalQuestionnaire($id: ID!) {
        deleteQuestionnaire(id: $id) {
            id
        }
    }
`);

const CreateFrameworkQuestionnaireMutation = graphql(`
    mutation CreateFrameworkQuestionnaire(
        $input: CreateFrameworkQuestionnaireInput!
    ) {
        createFrameworkQuestionnaire(input: $input) {
            status
            error {
                code
                message
                i18key
            }
            data {
                id
            }
        }
    }
`);

const GenerateQuestionsAiSuggestionsMutation = graphql(`
    mutation GenerateQuestionsAiSuggestions($id: ID!) {
        generateQuestionsAiSuggestions(questionnaireId: $id) {
            status
            error {
                message
            }
        }
    }
`);

const GetQuestionnaireGroupsQuery = graphql(`
    query GetQuestionnaireGroups($id: ID!, $locale: String!) {
        questionnaire(id: $id) {
            groups(locale: $locale) {
                id
                name
                level
                shortName
            }
        }
    }
`);

const ExportQuestionnaireMutation = graphql(`
    mutation exportQuestionnaire($id: String!, $locale: String!) {
        exportQuestionnaire(id: $id, locale: $locale) {
            data {
                signedUrl
            }
        }
    }
`);

export class GraphQLQuestionnaireRepository {
    getQuestionnaireHeaderData = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionnaireHeaderDataQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnaireDetails = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionnaireDetailsQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnaireGenerationState = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionnaireGenerationStateQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnaireGroups = async (id: string, locale: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionnaireGroupsQuery,
            { id, locale },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnaires = async () => {
        return graphqlClientKoyeb.request(
            GetQuestionnairesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnairesDataPage = async () => {
        return graphqlClientKoyeb.request(
            GetQuestionnairesDataPageQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };
    createExternalQuestionnaire = async (input: QuestionnaireCreateInput) => {
        return graphqlClientKoyeb.request(
            CreateExternalQuestionnaireQuery,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateQuestionnaire = async (
        id: string,
        input: QuestionnaireUpdateInput,
    ) => {
        return graphqlClientKoyeb.request(
            UpdateQuestionnaireQuery,
            { id, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createQuestion = async (input: QuestionCreateInput) => {
        return graphqlClientKoyeb.request(
            CreateQuestionMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createQuestions = async (input: QuestionCreateInput[]) => {
        return graphqlClientKoyeb.request(
            CreateQuestionsMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestion = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestionnaireOverviewData = async (
        filter: QuestionFilter,
        locale?: string,
    ) => {
        return graphqlClientKoyeb.request(
            GetQuestionnaireOverviewDataQuery,
            { filter, locale },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestions = async (filter: QuestionFilter, locale?: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionsQuery,
            { filter, locale },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateQuestion = async (id: string, input: QuestionUpdateInput) => {
        return graphqlClientKoyeb.request(
            UpdateQuestionMutation,
            { id, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateQuestions = async (ids: string[], input: QuestionUpdateInput) => {
        return graphqlClientKoyeb.request(
            UpdateQuestionsMutation,
            { ids, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    upsertAnswer = async (input: AnswerUpsertInput) => {
        return graphqlClientKoyeb.request(
            UpsertAnswerMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteQuestion = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteQuestionMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteQuestions = async (ids: string[]) => {
        return graphqlClientKoyeb.request(
            DeleteQuestionsMutation,
            { ids },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    generateAiSuggestion = async (questionId: string) => {
        return graphqlClientKoyeb.request(
            GenerateQuestionAiSuggestionMutation,
            { id: questionId },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteAnswer = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteAnswerMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    importQuestionsFromFile = async (
        questionnaireId: string,
        input: CreateQuestionsFromFileInput,
    ) => {
        return graphqlClientKoyeb.request(
            ImportQuestionsFromFileMutation,
            { questionnaireId, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteExternalQuestionnaire = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteExternalQuestionnaireMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createFromTemplate = async (
        templateId: string,
        input: QuestionnaireCreateInput,
    ) => {
        return graphqlClientKoyeb.request(
            CreateQuestionnaireFromTemplateMutation,
            { templateId, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createFrameworkQuestionnaire = async (
        frameworkId: string,
        year: number,
        tagIds: string[],
        locale: string,
    ) => {
        return graphqlClientKoyeb.request(
            CreateFrameworkQuestionnaireMutation,
            { input: { frameworkCmsId: frameworkId, year, tagIds, locale } },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    generateAiSuggestions = async (id: string) => {
        return graphqlClientKoyeb.request(
            GenerateQuestionsAiSuggestionsMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    export = async (id: string, locale: string) => {
        return graphqlClientKoyeb.request(
            ExportQuestionnaireMutation,
            { id, locale },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
