import {
    BooleanEnum,
    GetIndicators2Query,
    GetObjectiveQuery,
    GetObjectivesWithDetailsQuery,
    GetThemesAndSubthemesQuery,
    UpdateObjectiveInput,
} from "@generated/client/graphql";

export function makeOptimisticObjectiveUpdate(
    indicators: GetIndicators2Query["indicators2"],
) {
    return function (
        patch: UpdateObjectiveInput,
        previousQuery: GetObjectiveQuery,
    ) {
        const updatedQuery: GetObjectiveQuery = {
            ...previousQuery,
            objective: {
                ...previousQuery.objective,
                ...(patch.themeId !== undefined && patch.themeId !== null
                    ? {
                          themeId: patch.themeId,
                          subthemeId: undefined,
                          indicator: undefined,
                          indicatorId: undefined,
                      }
                    : {}),
                ...(patch.subthemeId !== undefined && patch.subthemeId !== null
                    ? { subthemeId: patch.subthemeId, indicator: undefined }
                    : {}),
                ...(patch.indicatorId !== undefined &&
                patch.indicatorId !== null
                    ? {
                          indicator: indicators?.find(
                              (indicator) => indicator.id === patch.indicatorId,
                          ),
                          indicatorId: patch.indicatorId,
                          subthemeId: indicators?.find(
                              (indicator) => indicator.id === patch.indicatorId,
                          )?.subthemev2?.id,
                      }
                    : {}),
                ...(patch.indicatorId === null
                    ? { indicator: null, indicatorId: null }
                    : {}),
                ...(patch.ownerId !== undefined && patch.ownerId !== null
                    ? { ownerId: patch.ownerId }
                    : {}),
                ...(patch.status !== undefined && patch.status !== null
                    ? { status: patch.status }
                    : {}),
                ...(patch.name !== undefined ? { name: patch.name } : {}),
                ...(patch.description !== undefined
                    ? { description: patch.description }
                    : {}),
                ...(patch.actionPlan !== undefined
                    ? { actionPlan: patch.actionPlan }
                    : {}),
            },
        };
        return updatedQuery;
    };
}

export function makeOptimisticObjectiveDeleteMany() {
    return function (
        ids: string[],
        previousQuery: GetObjectivesWithDetailsQuery,
    ) {
        const updatedQuery: GetObjectivesWithDetailsQuery = {
            ...previousQuery,
            objectives: previousQuery.objectives.filter(
                (objective) => !ids.includes(objective.id),
            ),
        };
        return updatedQuery;
    };
}

export const isActiveTheme = (
    theme: GetThemesAndSubthemesQuery["themes"][number],
) => {
    return theme.active && theme.subthemes.some((subtheme) => subtheme.active);
};

export const isActiveAndObjectiveRequiredSubtheme = (
    subtheme: GetThemesAndSubthemesQuery["themes"][number]["subthemes"][number],
) => {
    if (subtheme.cmsSubThemeId === "environment-general") {
        console.log(subtheme);
    }
    return subtheme.active && subtheme.isObjectiveRequired === BooleanEnum.True;
};

export const makeWorkingPlanThemes = (
    themes: GetThemesAndSubthemesQuery["themes"],
) => {
    return themes
        .filter(isActiveTheme)
        .sort((a, b) => a.order - b.order)
        .map((theme) => {
            return {
                ...theme,
                subthemes: theme.subthemes
                    .filter(isActiveAndObjectiveRequiredSubtheme)
                    .sort((a, b) => a.order - b.order),
            };
        })
        .filter((theme) => !!theme?.subthemes?.length);
};
