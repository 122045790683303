import {
    GetObjectivesWithDetailsQuery,
    GetThemesAndSubthemesQuery,
    ObjectiveStatus,
} from "@generated/client/graphql";

interface ThemeProgressResult {
    progress: number;
    completedSubthemes: number;
    totalSubthemes: number;
}

export const useThemeProgress = (
    theme: GetThemesAndSubthemesQuery["themes"][number],
    objectives: GetObjectivesWithDetailsQuery["objectives"],
): ThemeProgressResult => {
    const isSubthemeCompleted = (
        subtheme: (typeof theme.subthemes)[number],
    ) => {
        const subthemeObjectives = objectives?.filter(
            (obj) =>
                obj.subthemeId === subtheme.id &&
                obj.status === ObjectiveStatus.Defined,
        );
        return (subthemeObjectives?.length || 0) >= 3;
    };

    const calculateProgress = () => {
        if (!theme.subthemes || !objectives) return 0;

        const totalSubthemes = theme.subthemes.length;
        if (totalSubthemes === 0) return 0;

        const completedSubthemes =
            theme.subthemes.filter(isSubthemeCompleted).length;
        return Math.round((completedSubthemes / totalSubthemes) * 100);
    };

    const completedSubthemes =
        theme.subthemes?.filter(isSubthemeCompleted).length || 0;
    const totalSubthemes = theme.subthemes?.length || 0;
    const progress = calculateProgress();

    return {
        progress,
        completedSubthemes,
        totalSubthemes,
    };
};
