import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    type PropsWithChildren,
    type RefObject,
    useEffect,
    useState,
    type FC,
} from "react";

export type SharedProps = {
    hint?: string;
    placeholder?: string;
    success?: boolean;
    error?: string;
};

type WrapperProps = SharedProps & {
    inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
    initialPlaceholderUp?: boolean;
    htmlFor?: string;
    disabled?: boolean;
};

export const FieldWrapper: FC<PropsWithChildren<WrapperProps>> = ({
    children,
    disabled,
    htmlFor,
    hint,
    success,
    error,
    placeholder,
    inputRef,
    initialPlaceholderUp,
}) => {
    const [placeholderUp, setPlaceholderUp] = useState(initialPlaceholderUp);

    // here we handle the placeholder animation, without interfeeing with the props'  callbacks
    useEffect(() => {
        if (inputRef.current?.value) setPlaceholderUp(true);

        const handleInput = () => {
            setPlaceholderUp(!!inputRef?.current?.value);
        };

        const handleFocus = () => {
            setPlaceholderUp(true);
        };

        const handleBlur = () => {
            setPlaceholderUp(!!inputRef?.current?.value);
        };

        inputRef?.current?.addEventListener("input", handleInput);
        inputRef?.current?.addEventListener("blur", handleBlur);
        inputRef?.current?.addEventListener("focus", handleFocus);

        return () => {
            inputRef?.current?.removeEventListener("input", handleInput);
            inputRef?.current?.removeEventListener("blur", handleBlur);
            inputRef?.current?.removeEventListener("focus", handleFocus);
        };
    }, []);

    return (
        <div className="min-w-full">
            <div
                className={cn(
                    "w-full group px-3 py-1.5 rounded-lg border border-teritiary bg-white focus-within:border-primary",
                    success && "border-[2px] !border-green",
                    disabled && "bg-transparent",
                    error && "border-[2px] !border-danger",
                )}
            >
                <label htmlFor={htmlFor}>
                    {placeholder && (
                        <div
                            className={cn(
                                "font-regular text-secondary cursor-text transition translate-y-2.5 group-focus-within:translate-y-0",
                                placeholderUp
                                    ? " text-sm translate-y-0"
                                    : " text-base group-focus-within:text-primary ",
                                error && "!text-danger",
                            )}
                        >
                            {placeholder}
                        </div>
                    )}
                    {children}
                </label>
            </div>
            {hint ? (
                <Text aria-label={hint} variant="body-sm">
                    {hint}
                </Text>
            ) : null}
            {error ? (
                <Text variant="body-sm" color="danger">
                    {error}
                </Text>
            ) : null}
        </div>
    );
};
