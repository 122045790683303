import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-body";

const keys = addTranslations(RESOURCE_NAME, {
    questionnaires: {
        en: "Questionnaires",
        fr: "Questionnaires",
    },
    users: {
        en: "Users",
        fr: "Utilisateurs",
    },
    add_user: {
        en: "Add user",
        fr: "Ajouter un utilisateur",
    },
    send_new_questionnaire_button: {
        en: "Send new questionnaire",
        fr: "Envoyer un nouveau questionnaire",
    },
    send_new_questionnaire_tooltip: {
        en: "This supplier has an open questionnaire. Close it before sending a new one.",
        fr: "Ce fournisseur a un questionnaire ouvert. Fermez-le avant d'envoyer un nouveau questionnaire.",
    },
    send_reminder_button: {
        en: "Send reminder",
        fr: "Envoyer un rappel",
    },
    send_questionnaire_error_message: {
        en: "An error occurred while sending the questionnaire",
        fr: "Une erreur est survenue lors de l'envoi du questionnaire",
    },
    send_questionnaire_success_message: {
        en: "Questionnaire sent successfully",
        fr: "Questionnaire envoyé avec succès",
    },
    send_reminder_error_message: {
        en: "An error occurred while sending the reminder",
        fr: "Une erreur est survenue lors de l'envoi du rappel",
    },
    send_reminder_success_message: {
        en: "Reminder sent successfully",
        fr: "Rappel envoyé avec succès",
    },
});

export function useSupplierBodyTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
