import { useThemes } from "@app/store/themeStore";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import Select from "@design-system/Inputs/Select";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { FC, PropsWithChildren, ReactNode } from "react";
import { ThemeTag } from "../ThemeTag";

export const ThemeTagSelect: FC<
    PropsWithChildren<{
        themeId: string;
        setThemeId?: (themeId: string) => void;
        disabled?: boolean;
        disabledTooltip?: string;
        trigger?: ReactNode;
    }>
> = ({
    themeId,
    setThemeId,
    disabled = false,
    disabledTooltip = "",
    trigger,
}) => {
    const { themes } = useThemes();

    return (
        <Select value={themeId} onValueChange={setThemeId} disabled={disabled}>
            <Tooltip delayDuration={0} defaultOpen={false}>
                <Tooltip.Trigger>
                    <Select.Trigger className="w-fit">
                        {trigger || (
                            <ThemeTag
                                themeId={themeId}
                                className="border border-neutral-200 cursor-default rounded-xl p-0.5 pointer-events-none"
                                disabled={disabled}
                            />
                        )}
                    </Select.Trigger>
                </Tooltip.Trigger>
                {disabled && disabledTooltip && (
                    <Tooltip.Content>{disabledTooltip}</Tooltip.Content>
                )}
            </Tooltip>
            <Select.Content>
                <Box className="max-h-[320px] overflow-auto">
                    {themes?.map((theme) => (
                        <Select.Item
                            key={theme.id}
                            value={theme.id || ""}
                            className="h-max"
                        >
                            <FlexRow
                                alignItems="center"
                                justifyContent="between"
                                className="w-full"
                            >
                                <FlexRow
                                    alignItems="center"
                                    gap="2.5"
                                    style={{ color: theme?.color }}
                                >
                                    <Icon name="tagCircle" size="md" />
                                    <Text
                                        variant="body"
                                        color="primary"
                                        className="group-hover:text-brand_hover text-sm"
                                    >
                                        {theme.name}
                                    </Text>
                                </FlexRow>
                                {themeId === theme.id && (
                                    <Icon
                                        name="check"
                                        size="sm"
                                        className="w-fit text-beavrGreen"
                                    />
                                )}
                            </FlexRow>
                        </Select.Item>
                    ))}
                </Box>
            </Select.Content>
        </Select>
    );
};
