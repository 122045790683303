import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-import-questions-from-file-modal";

const keys = addTranslations(RESOURCE_NAME, {
    trigger_label: {
        en: "Upload file",
        fr: "Télécharger un fichier",
    },
    title: {
        en: "Create a questionnaire from an uploaded file",
        fr: "Créer un questionnaire à partir d'un fichier téléchargé",
    },
    add_tags_label: {
        en: "Select at least one questionnaire.",
        fr: "Sélectionner au moins un questionnaire.",
    },
    dropzone_btn_label: {
        en: "Browse",
        fr: "Parcourir",
    },
    dropzone_placeholder: {
        en: "Upload your questionnaire",
        fr: "Téléversez votre questionnaire",
    },
    dropzone_accepted_file_types: {
        en: "We accept pdf, excel and docx files",
        fr: "Nous acceptons les fichiers pdf, excel et docx",
    },
    dropzone_analyzing_file: {
        en: "Analyzing file...",
        fr: "Analyse du fichier...",
    },
});

export function useImportQuestionsFromFileModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
