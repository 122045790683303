import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { Button } from "@design-system/Inputs/Button";
import {
    Dropdown,
    useDropdownItemTextStyles,
} from "@design-system/Inputs/UniversalDropdown";
import { type ColumnDef } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "../../../i18n";

export const evidenceColumn: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "evidence",
    meta: {
        colWidth: "100px",
        cellClassName: "min-w-[100px] max-w-[100px]",
        headerClassName: "min-w-[100px] max-w-[100px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.table_header_evidence);
    },
    cell: ({ row }) => {
        const files = row.original.answer?.evidenceFiles;
        const className = useDropdownItemTextStyles();

        return (
            <Dropdown>
                <Dropdown.Trigger asChild>
                    <Button size="sm" variant="outlined">
                        <Button.Icon name="document" />
                        {files?.length ?? 0}
                    </Button>
                </Dropdown.Trigger>
                {files?.length ? (
                    <Dropdown.Content hasPortal>
                        {files?.map((file) => (
                            <a
                                className="all:unset"
                                href={file.signedUrl ?? ""}
                                key={file.id}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <Dropdown.Item>
                                    <Dropdown.ItemIcon name="document" />
                                    <Ellipsis hasTooltip asChild>
                                        <Dropdown.ItemText
                                            className={className}
                                        >
                                            {file.name}
                                        </Dropdown.ItemText>
                                    </Ellipsis>
                                </Dropdown.Item>
                            </a>
                        ))}
                    </Dropdown.Content>
                ) : null}
            </Dropdown>
        );
    },
};
