import * as React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import BodyHeader from "../../shared/components/BodyHeader";

import { ButtonType } from "../../components/Button/ButtonShared";

import PreviewTemplateModal from "@app/shared/components/PreviewTemplateModal/PreviewTemplateModal";
import { usePageviewTrack } from "@app/shared/planhat";
import { currentUserRoleAtom } from "@app/store/userStore";
import SearchBar from "@design-system/Inputs/SearchBar";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useAtomValue } from "jotai";
import { Eye, MoreHorizontal } from "lucide-react";
import { DocumentType } from "../../../generated/client/graphql";
import ButtonLarge from "../../components/Button/ButtonLarge";
import { DropdownTrigger } from "../../components/Dropdown/components";
import BaseItem from "../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../components/Dropdown/components/dropdowns/layout";
import ThemeTabFilter from "../../components/Filters/ThemeTabFilter";
import "../Document/components/UpdateDocumentModal/i18n";
import AddDocumentModal from "./components/AddDocumentModal";
import DocumentsTable, {
    useIrrelevantDocuments,
} from "./components/DocumentsTable";
import IrrelevantDocumentsModal from "./components/IrrelevantDocumentsModal";
import { useFilters } from "./hooks/useFilters";
import "./i18n";

export default function Documents2({ type }: { type: DocumentType }) {
    const { search, setSearch, filters, setFilters } = useFilters();

    const { isContributor } = useAtomValue(currentUserRoleAtom);

    const { t } = useTranslation(`Documents`);
    const [open, setOpen] = React.useState(false);

    const dataChameleon = {
        key:
            type === DocumentType.Policy
                ? "policies"
                : type === DocumentType.Procedure
                  ? "measures"
                  : "custom-documents",
        type:
            type && [DocumentType.Policy, DocumentType.Procedure].includes(type)
                ? "tutorial"
                : "help",
    } as const;

    const [openIrrelevantDocumentsModal, setOpenIrrelevantDocumentsModal] =
        React.useState(false);
    const irrelevantDocuments = useIrrelevantDocuments(type);

    usePageviewTrack(
        type === DocumentType.Policy
            ? "pageview_policies"
            : type === DocumentType.Procedure
              ? "pageview_procedures"
              : "pageview_custom_docs",
    );

    const setTheme = (themeId: string | undefined) => {
        setFilters({ ...filters, themeId });
    };

    const setSubthemeIds = (subthemeIds: string[] | undefined) => {
        setFilters({ ...filters, subthemeIds });
    };

    return (
        <Container>
            <BodyHeader
                dataChameleon={dataChameleon}
                title={t(`${type}_title`)}
                subTitle={t(`${type}_subtitle`)}
                action={
                    isContributor ? null : (
                        <>
                            {type === DocumentType.CustomDocument && (
                                <div className="flex space-x-2">
                                    <ButtonLarge
                                        text={t("add_document")}
                                        variant={ButtonType.FLAT_PRIMARY}
                                        onClick={() => setOpen(true)}
                                    />
                                </div>
                            )}
                            <div className="flex space-x-2 items-center justify-end">
                                <DropdownMenu modal={false}>
                                    <DropdownTrigger asChild>
                                        <ButtonLarge
                                            variant={ButtonType.OUTLINED}
                                            IconLeft={MoreHorizontal}
                                        />
                                    </DropdownTrigger>
                                    <DropdownContentLayout
                                        align="end"
                                        side="bottom"
                                        sideOffset={0}
                                    >
                                        <BaseItem
                                            onClick={() =>
                                                setOpenIrrelevantDocumentsModal(
                                                    true,
                                                )
                                            }
                                            IconLeft={() => (
                                                <Eye className="text-green h-4 w-4" />
                                            )}
                                        >
                                            {t("see_not_relevant", {
                                                count: irrelevantDocuments.length,
                                            })}
                                        </BaseItem>
                                    </DropdownContentLayout>
                                </DropdownMenu>
                            </div>
                        </>
                    )
                }
            />
            <div className="p-4 space-y-4">
                <div className="flex space-x-4 justify-between">
                    <ThemeTabFilter
                        themeId={filters.themeId}
                        setThemeId={setTheme}
                        subthemeIds={filters.subthemeIds}
                        setSubthemeIds={setSubthemeIds}
                    />
                    <SearchBar
                        searchString={search}
                        setSearchString={setSearch}
                        className="w-[225px]"
                        placeholder={t("placeholder")}
                    />
                </div>
                <DocumentsTable type={type} />
            </div>
            {irrelevantDocuments && (
                <IrrelevantDocumentsModal
                    documents={irrelevantDocuments}
                    type={type || DocumentType.CustomDocument}
                    open={openIrrelevantDocumentsModal}
                    setOpen={setOpenIrrelevantDocumentsModal}
                />
            )}
            <AddDocumentModal type={type} open={open} setOpen={setOpen} />
            <PreviewTemplateModal />
        </Container>
    );
}
