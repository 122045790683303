import { Box } from "@design-system/Layout/Box";
import { type FC, type HTMLAttributes, type Ref } from "react";

import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { cva, type VariantProps } from "class-variance-authority";

const badgeVariants = cva(
    "transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                neutralTertiary: "bg-neutral-100",
                neutral: "bg-neutral-500",
                success: "bg-green",
                warning: "bg-danger",
                error: "bg-red-500",
            },
        },
        defaultVariants: {
            variant: "success",
        },
    },
);

export type BadgeProps = VariantProps<typeof badgeVariants> &
    HTMLAttributes<HTMLDivElement> & {
        content: string;
    };

export const Badge: FC<BadgeProps & { ref?: Ref<HTMLDivElement | null> }> = ({
    className,
    content,
    variant,
    ref,
    ...props
}) => {
    return (
        <Box
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
            className={cn(badgeVariants({ variant }), className)}
            h="fit"
            minW="4"
            px="1"
            br="xl"
            ref={ref}
            {...props}
        >
            <Text
                align="center"
                as="span"
                variant="body-xs-bold"
                className="text-white"
                w="full"
            >
                {content}
            </Text>
        </Box>
    );
};
