import { PageContainer } from "@design-system/Layout/PageContainer";
import { FlexCol } from "@design-system/Layout/Flex";
import { type FC } from "react";
import { useParams } from "react-router-dom";
import { SupplierHeader } from "../components/SupplierHeader";
import { useSupplierQuestionnairesTranslations } from "./i18n";
import { QuestionnairesTable } from "@app/screens/Supplier/Suppliers/supplier/components/QuestionnairesTable";
import { LastQuestionnaireBox } from "@app/screens/Supplier/Suppliers/supplier/components/LastQuestionnaireBox";

export const Questionnaires: FC = () => {
    const { sup_id } = useParams();
    const { t, keys } = useSupplierQuestionnairesTranslations();

    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                <SupplierHeader
                    description={t(keys.description)}
                    supplierId={sup_id}
                />
                <LastQuestionnaireBox supplierId={sup_id} variant="supplier" />
                <QuestionnairesTable supplierId={sup_id} withCopyLink={false} />
            </FlexCol>
        </PageContainer>
    );
};
