import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Switch } from "@design-system/Inputs/Switch";
import { TagMultiSelect } from "@design-system/Inputs/TagMultiSelect";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { QuestionType } from "@generated/client/graphql";
import { ChangeEvent, useEffect, useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";

import {
    QuestionDetail,
    QuestionList,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { type UpsertQuestionInput } from "../../types";
import { QuestionModalCard } from "./QuestionModalCard";

type DependencyBoxProps = {
    question?: QuestionDetail;
    dependsOnQuestionId?: string;
    dependsOnQuestionAnswers?: number[];
    allQuestions?: QuestionList;
    handleParentQuestionChange: (
        field: keyof UpsertQuestionInput,
        value?: { questionId?: string; displayIfSelected?: number[] },
    ) => void;
};

export const DependencyBox: FC<DependencyBoxProps> = ({
    question,
    dependsOnQuestionId,
    handleParentQuestionChange,
    allQuestions,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const filteredQuestions = useMemo(
        () =>
            allQuestions?.filter(
                (q) =>
                    [
                        QuestionType.SingleSelect,
                        QuestionType.MultiSelect,
                    ].includes(q.type) &&
                    (q.options?.length ?? 0) > 0 &&
                    q.id !== question?.id,
            ) ?? [],
        [allQuestions],
    );

    const [dependsOnOtherQuestion, setDependsOnOtherQuestion] =
        useState<boolean>(
            question?.metadata?.dependsOn?.questionId == null ? false : true,
        );

    const [parentQuestion, setParentQuestion] = useState(
        allQuestions?.find((q) => q.id === dependsOnQuestionId),
    );

    const parentQuestionOptions = parentQuestion?.options ?? [];
    const [answersValues, setAnswersValues] = useState<string[]>(
        question?.metadata?.dependsOn?.displayIfSelected?.map(
            (index) => parentQuestionOptions?.[index] ?? "",
        ) ?? [],
    );

    useEffect(() => {
        setDependsOnOtherQuestion(dependsOnQuestionId == null ? false : true);
        if (dependsOnQuestionId == null) {
            setAnswersValues([]);
        }

        setParentQuestion(
            allQuestions?.find((q) => q.id === dependsOnQuestionId),
        );
    }, [dependsOnQuestionId]);

    const handleDependsOnOtherQuestionChange = () => {
        if (dependsOnOtherQuestion) {
            handleParentQuestionChange("dependsOn", {
                questionId: undefined,
                displayIfSelected: [],
            });
        }
        setDependsOnOtherQuestion((prev) => !prev);
    };

    const handleQuestionIdChange = (value: string[]) => {
        handleParentQuestionChange("dependsOn", {
            ...question?.metadata?.dependsOn,
            questionId: value[0],
        });
        setAnswersValues([]);
    };

    const handleAnswersValueChange = (
        values: (string | null)[] | string | null,
    ) => {
        if (values === null) {
            handleParentQuestionChange("dependsOn", {});
            return;
        }
        if (Array.isArray(values)) {
            setAnswersValues(values.filter((v) => v !== null));
            const indexes = parentQuestionOptions
                ?.map((option, index) =>
                    values.includes(option) ? index : null,
                )
                .filter((v) => v !== null);

            handleParentQuestionChange("dependsOn", {
                questionId: dependsOnQuestionId,
                displayIfSelected: indexes,
            });
            return;
        }

        setAnswersValues([values]);
        handleParentQuestionChange("dependsOn", {
            questionId: dependsOnQuestionId,
            displayIfSelected: [parentQuestionOptions.indexOf(values)],
        });
    };

    const [inputQuery, setInputQuery] = useState("");

    const filteredOptions =
        inputQuery === ""
            ? parentQuestionOptions
            : parentQuestionOptions.filter((option) => {
                  return option
                      .toLowerCase()
                      .includes(inputQuery.toLowerCase());
              });

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputQuery(event.target.value);
    };

    return (
        <QuestionModalCard py="3">
            <FlexCol gap="3" className="items-start">
                <Switch
                    checked={dependsOnOtherQuestion}
                    label={t(
                        keys.create_questions_modal_depends_on_other_question,
                    )}
                    onChange={handleDependsOnOtherQuestionChange}
                    labelPosition="right"
                    type="default"
                />
                {dependsOnOtherQuestion && (
                    <FlexCol gap="3" className="w-full">
                        <Dropdown
                            closeOnValueChange
                            modal
                            onValueChange={handleQuestionIdChange}
                            selectType="single"
                            value={[dependsOnQuestionId ?? ""]}
                        >
                            <Dropdown.Trigger
                                className="w-full"
                                label={t(
                                    keys.create_questions_modal_depends_on_select_question_label,
                                )}
                                asChild
                            >
                                <Button
                                    className="shadow-none font-regular bg-primary"
                                    variant="outlined"
                                >
                                    <div className="h-full flex-grow flex flex-col justify-center overflow-hidden">
                                        <div
                                            className={cn(
                                                "text-left font-regular text-secondary",
                                                dependsOnQuestionId
                                                    ? " text-sm"
                                                    : "text-base",
                                            )}
                                        >
                                            {t(
                                                keys.create_questions_modal_depends_on_select_question_label,
                                            )}
                                        </div>
                                        {dependsOnQuestionId && (
                                            <Ellipsis
                                                asChild
                                                className="text-left"
                                                hasTooltip
                                            >
                                                <span>
                                                    {parentQuestion?.name}
                                                </span>
                                            </Ellipsis>
                                        )}
                                    </div>

                                    <Button.Icon name="angleDown" size="sm" />
                                </Button>
                            </Dropdown.Trigger>
                            <Dropdown.Content
                                align="start"
                                hasPortal
                                className="w-fit max-w-[500px]"
                            >
                                {filteredQuestions.map((question) => (
                                    <Dropdown.SelectableItem
                                        key={question.id}
                                        value={question.id}
                                    >
                                        <Dropdown.SelectedIndicator />
                                        <Dropdown.ItemText className="max-w-[500px]">
                                            <Ellipsis asChild hasTooltip>
                                                <Text>{question.name}</Text>
                                            </Ellipsis>
                                        </Dropdown.ItemText>
                                    </Dropdown.SelectableItem>
                                ))}
                            </Dropdown.Content>
                        </Dropdown>

                        <TagMultiSelect
                            multiple
                            value={answersValues}
                            onChange={handleAnswersValueChange}
                            onClose={() => {
                                setInputQuery("");
                            }}
                            disabled={filteredOptions.length === 0}
                        >
                            <TagMultiSelect.Trigger>
                                <FlexRow gap="2" className="flex-wrap">
                                    {answersValues.map((answer) => (
                                        <TagMultiSelect.Tag
                                            onClick={() => {
                                                handleAnswersValueChange(
                                                    answersValues.filter(
                                                        (v) => v !== answer,
                                                    ),
                                                );
                                            }}
                                            key={answer}
                                            variant="tonal"
                                            size="xs"
                                        >
                                            {answer}
                                            <Button.Icon
                                                className="text-primary"
                                                name="close"
                                                size="xs"
                                            />
                                        </TagMultiSelect.Tag>
                                    ))}
                                    <TagMultiSelect.Input
                                        placeholder={t(
                                            keys.create_questions_modal_depends_on_select_answers_label,
                                        )}
                                        onChange={handleQueryChange}
                                        className="min-w-[200px]"
                                    />
                                </FlexRow>
                                <TagMultiSelect.Button>
                                    <Icon
                                        className="text-primary"
                                        name="angleDown"
                                        size="sm"
                                    />
                                </TagMultiSelect.Button>
                            </TagMultiSelect.Trigger>
                            <TagMultiSelect.Options>
                                {filteredOptions.map((option) => (
                                    <TagMultiSelect.Option
                                        key={option}
                                        value={option}
                                    >
                                        <Ellipsis hasTooltip>{option}</Ellipsis>
                                    </TagMultiSelect.Option>
                                ))}
                            </TagMultiSelect.Options>
                        </TagMultiSelect>
                        <Text className="text-secondary">
                            {t(
                                keys.create_questions_modal_dependency_description,
                            )}
                        </Text>
                    </FlexCol>
                )}
            </FlexCol>
        </QuestionModalCard>
    );
};
