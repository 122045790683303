import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-last-questionnaire-box";

const keys = addTranslations(RESOURCE_NAME, {
    trigger_button: {
        en: "Add users",
        fr: "Ajouter des utilisateurs",
    },
    title: {
        en: "Add new users",
        fr: "Ajouter de nouveaux utilisateurs",
    },
    description: {
        en: "New users will have access to {{- supplierName}}'s questionnaires",
        fr: "Les nouveaux utilisateurs auront accès aux questionnaires de {{- supplierName}}",
    },
    add_user: {
        en: "Add user",
        fr: "Ajouter un utilisateur",
    },
    add_user_one: {
        en: "Add user",
        fr: "Ajouter un utilisateur",
    },
    add_user_other: {
        en: "Add {{count}} users",
        fr: "Ajouter {{count}} utilisateurs",
    },
    add_new_user: {
        en: "Add new ",
        fr: "Ajouter un autre",
    },
    cancel: {
        en: "Cancel",
        fr: "Annuler",
    },
    email: {
        en: "Email",
        fr: "Email",
    },
    email_error: {
        en: "Invalid email",
        fr: "Email invalide",
    },
    first_name: {
        en: "First name",
        fr: "Prénom",
    },
    last_name: {
        en: "Last name",
        fr: "Nom",
    },
    department: {
        en: "Department",
        fr: "Département",
    },
    cannot_submit: {
        en: "Cannot submit",
        fr: "Impossible de soumettre",
    },
    cannot_submit_invalidEmail: {
        en: "Please check that all emails are valid",
        fr: "Veuillez vérifier que tous les emails sont valides",
    },
    cannot_submit_incomplete: {
        en: "Please fill in all fields",
        fr: "Veuillez remplir tous les champs",
    },
    choose_language: {
        en: "Choose the language in which the account will be configured",
        fr: "Choisir la langue dans laquelle le compte sera configuré",
    },
    choose_language_one: {
        en: "Choose the language in which the account will be configured",
        fr: "Choisir la langue dans laquelle le compte sera configuré",
    },
    choose_language_other: {
        en: "Choose the language in which the accounts will be configured",
        fr: "Choisir la langue dans laquelle les comptes seront configurés",
    },
    success_message: {
        en: "Users added successfully",
        fr: "Utilisateurs ajoutés avec succès",
    },
    error_message: {
        en: "Error adding users",
        fr: "Erreur lors de l'ajout des utilisateurs",
    },

    dep_executive: {
        en: "Executive & Leadership",
        fr: "Direction Générale & Leadership",
    },
    dep_external: {
        en: "External - Audit & Consulting",
        fr: "Externe – Audit & Conseil",
    },
    dep_finance: {
        en: "Finance, Legal & Compliance",
        fr: "Finance, Juridique & Conformité",
    },
    dep_hr: {
        en: "Human Resources",
        fr: "Ressources Humaines",
    },
    dep_it: {
        en: "IT & Cybersecurity",
        fr: "Informatique & Cybersécurité",
    },
    dep_operations: {
        en: "Operations & Client Success",
        fr: "Opérations & Gestion Client",
    },
    dep_other: {
        en: "Other",
        fr: "Autre",
    },
    dep_procurement: {
        en: "Procurement, Supply Chain & Logistics",
        fr: "Achats, Supply Chain & Logistique",
    },
    dep_product: {
        en: "Product, R&D & Innovation",
        fr: "Produit, R&D & Innovation",
    },
    dep_quality: {
        en: "Quality, Safety & Risk Management",
        fr: "Qualité, Sécurité & Gestion des Risques",
    },
    dep_sales: {
        en: "Sales, Marketing & Communication",
        fr: "Ventes, Marketing & Communication",
    },
    dep_sustainability: {
        en: "Sustainability, CSR & Environmental",
        fr: "Développement Durable, RSE & Environnement",
    },
});

export function useAddUsersModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
