import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import * as RadixTabs from "@radix-ui/react-tabs";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type TabsContext<T> = {
    activeTab?: T;
    onActiveTabChange: (tab: T) => void;
    size?: "sm" | "md" | "lg";
};

export const TabsContext = createContext<any>(null);

type TriggerProps<T> = {
    value: T;
    label: string;
    count?: number;
    size?: "sm" | "md" | "lg";
};
const Trigger: FC<TriggerProps<any>> = <T extends string>(
    props: TriggerProps<T>,
) => {
    const ctx = useContext(TabsContext);
    if (!ctx) throw new Error("Context must not be null");
    const onClick = () => {
        ctx.onActiveTabChange(props.value);
    };

    const size = props.size ?? ctx.size ?? "sm"; // for legacy reasons, default is "sm"
    const variant =
        size === "sm"
            ? "body-sm-bold"
            : size === "lg"
              ? "body-lg-bold"
              : "body-bold";
    return (
        <RadixTabs.Trigger
            value={props.value}
            onClick={onClick}
            className="border-b-2 border-transparent data-[state=active]:border-beavrGreen group h-10 box-border"
        >
            <FlexRow gap="1.5" alignItems="center" py="4" mt="1">
                <Text
                    variant={variant}
                    className="text-neutral-500 group-data-[state=active]:text-black"
                >
                    {props.label}
                </Text>
                {props.count && (
                    <Text
                        variant="body-sm-bold"
                        className="text-neutral-500 group-data-[state=active]:text-black"
                    >
                        {props.count}
                    </Text>
                )}
            </FlexRow>
        </RadixTabs.Trigger>
    );
};
type TabsProps<T> = {
    activeTab: T;
    onActiveTabChange: (tab: T) => void;
    className?: string;
    size?: "sm" | "md" | "lg";
};

export const Tabs = <T extends string>({
    children,
    activeTab,
    onActiveTabChange,
    className,
    size,
}: PropsWithChildren<TabsProps<T>>) => {
    return (
        <TabsContext.Provider
            value={{
                activeTab,
                onActiveTabChange,
                size,
            }}
        >
            <RadixTabs.Root
                defaultValue={activeTab}
                value={activeTab}
                className={className}
            >
                <RadixTabs.List>
                    <FlexRow
                        gap="4"
                        alignItems="start"
                        h="10"
                        className="border-b border-secondary box-border"
                    >
                        {children}
                    </FlexRow>
                </RadixTabs.List>
            </RadixTabs.Root>
        </TabsContext.Provider>
    );
};

Tabs.Trigger = Trigger;
