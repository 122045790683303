import FullFrameBlock from "@app/components/Layouts/FullFrame/Block";
import InnerLayout from "@app/components/Layouts/FullFrame/Block/InnerLayout";
import { useNavigation } from "@app/lib/navigation";
import { useObjectivesWithDetails } from "@app/screens/Objectives/data";
import { useTheme } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { getPaginationRowModel, Table } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    BooleanEnum,
    Document,
    ObjectiveStatus,
} from "../../../../../generated/client/graphql";
import { useColumns } from "./columns";
import { RESOURCE_NAME } from "./i18n";
import { EnrichedSubtheme } from "./types";

export const ObjectivesBlock = ({ Document }: { Document?: Document }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const themeId = Document?.theme?.id;
    const { objectives } = useObjectivesWithDetails({
        themeId,
    });
    const filteredObjectives = objectives?.filter(
        (obj) => obj.status === ObjectiveStatus.Defined,
    );
    const currentTheme = useTheme(themeId ?? undefined);

    const subthemes = currentTheme?.subthemes;

    const enrichedSubthemes: EnrichedSubtheme[] = Array.from(
        subthemes?.values() ?? [],
    )
        .filter((subtheme) => subtheme.isObjectiveRequired === BooleanEnum.True)
        .map((subtheme) => ({
            subtheme,
            validatedObjectiveCount:
                filteredObjectives?.filter(
                    (obj) => obj.subthemeId === subtheme.id,
                ).length ?? 0,
        }));

    const columns = useColumns();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const tableOptions = {
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        autoResetPageIndex: false,
    };

    const { navigateFromHome } = useNavigation();

    const handleObjectivesClick = () => {
        navigateFromHome(
            `objectives?search=${Document?.theme?.name?.toLowerCase()}`,
        );
    };

    return (
        <FullFrameBlock>
            <div className="flex">
                <InnerLayout className="p-0 border-r border-tertiary space-y-1">
                    <FlexCol>
                        <FlexRow className="p-4">
                            <FlexCol className="pr-6">
                                <Text variant="header3">
                                    {t("title_commitment_block")}
                                </Text>
                                <Text
                                    variant="body-sm"
                                    className="text-neutral-500 pt-2"
                                >
                                    {t("subtitle_commitment_block")}
                                </Text>
                            </FlexCol>
                            <Button
                                className="w-full max-w-[150px]"
                                variant="tonal"
                                size="sm"
                                onClick={handleObjectivesClick}
                            >
                                <FlexRow gap="1" alignItems="center">
                                    <Text variant="body-sm-bold">
                                        {t("button_commitment_block")}
                                    </Text>
                                    <Button.Icon name="arrowRight" size="sm" />
                                </FlexRow>
                            </Button>
                        </FlexRow>
                        <Table
                            columns={columns}
                            data={enrichedSubthemes || []}
                            options={tableOptions}
                            EmptyBody={TableEmptyState}
                            className="w-full border-l-0 border-r-0"
                            variant="grid"
                        />
                    </FlexCol>
                </InnerLayout>
            </div>
        </FullFrameBlock>
    );
};

const TableEmptyState: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            py="4"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" w="full" className="my-8">
                <Icon name="search" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {t("title_commitment_no_results")}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {t("no_commitment_found_subtitle")}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
