import { useCallback, useMemo, useState } from "react";
import { CustomIndicatorForm } from "../types";

const INITIAL_FORM_STATE: CustomIndicatorForm = {
    name: "",
    description: "",
    selectedThemeId: "",
    selectedSubthemesId: "",
    cmsUnitId: "",
};

export const useCustomIndicatorForm = () => {
    const [form, setForm] = useState<CustomIndicatorForm>(INITIAL_FORM_STATE);

    const isValid = useMemo(() => {
        const {
            name,
            description,
            selectedThemeId,
            selectedSubthemesId,
            cmsUnitId,
        } = form;
        return [
            name.trim(),
            description.trim(),
            selectedThemeId,
            selectedSubthemesId,
            cmsUnitId,
        ].every(Boolean);
    }, [form]);

    const handleInputChange = useCallback(
        (key: keyof CustomIndicatorForm, value: string) => {
            setForm((prev) => ({ ...prev, [key]: value }));
        },
        [],
    );

    const resetForm = useCallback(() => {
        setForm(INITIAL_FORM_STATE);
    }, []);

    return {
        form,
        isValid,
        handleInputChange,
        resetForm,
    };
};
