import i18next from "../../i18n";

i18next.addResourceBundle("en", "Frameworks", {
    active_frameworks: "Active frameworks",
    other_frameworks: "Other frameworks",
    title: "Frameworks",
    subtitle: "Frameworks currently supported by the Beavr platform.",
    reqs_ok: "{{count}} approved requirements",
    datapoints_ok: "{{count}} completed datapoints",
    reqs_total: "{{count}} total",
    datapoints_total: "{{count}} total",
    coming_soon: "Coming soon...",
    coming_soon_Q2_2025: "Q2 2025",
    coming_soon_beta: "Almost ready...",
    no_data: "No data available",
    available_sales: "Available",
    create_modal: {
        title: "Create framework questionnaire",
        year_placeholder: "Year",
        locale_placeholder: "Base language",
        locale_description:
            "The language is used to determine in which language the AI will suggest answers to the questions of the framework.",
        framework_placeholder: "Choose framework",
        open_button: "Add framework",
        framework_already_exists: "Framework already exists for this year",
        success: "Framework questionnaire created",
        select_tags: "Select tags",
        tags_description: "Chose topics that are material to your organization",
    },
    see_more: "See more",
    request_access: "Request access",
    datapoints_answered: "Validated items",
    datapoints_ai_success: "AI answers",
    no_progress_bar: "Progress bar not available",
    assignment: "Assignment: {{value}}%",
    faq: "Most frequently asked questions",
    access_framework: "Access this framework with Beavr",
    request_access_description: "We'll notify our team.",
    watch_demo: "Watch our demo",
    watch_demo_description: "Watch how Beavr simplifies the process.",
    read_demo: "Read our demo",
    read_demo_description: "Access our specific guidance.",
    see_framework_website: "See framework website",
    see_framework_website_description: "Access the official information.",
});

i18next.addResourceBundle("fr", "Frameworks", {
    active_frameworks: "Référentiels actifs",
    other_frameworks: "Autres référentiels",
    title: "Référentiels",
    subtitle: "Les référentiels supportés par la plateforme Beavr.",
    reqs_ok: "{{count}} contrôles validés",
    datapoints_ok: "{{count}} datapoints completés",
    reqs_total: "{{count}} total",
    datapoints_total: "{{count}} total",
    coming_soon: "Bientôt disponible...",
    coming_soon_Q2_2025: "T2 2025",
    coming_soon_beta: "Presque prêt...",
    no_data: "Pas de données disponibles",
    available_sales: "Disponible",
    create_modal: {
        title: "Ajouter un référentiel",
        year_placeholder: "Année",
        locale_placeholder: "Langue par défaule",
        locale_description:
            "La langue sert à déterminer dans quelle langue l'IA va suggérer les réponses aux questions du référentiel.",
        framework_placeholder: "Choisir un référentiel",
        open_button: "Ajouter un référentiel",
        framework_already_exists: "Référentiel déjà créé pour cette année",
        success: "Questionnaire de référentiel créé",
        select_tags: "Sélectionner les tags",
        tags_description:
            "Choisissez les sujets qui sont pertinents pour votre organisation",
    },
    see_more: "Voir plus",
    request_access: "Demander l'accès",
    datapoints_answered: "Items validés",
    datapoints_ai_success: "Réponses IA",
    no_progress_bar: "Barre de progression non disponible",
    assignment: "Affectation : {{value}}%",
    faq: "Questions fréquentes",
    access_framework: "Accéder à ce référentiel avec Beavr",
    request_access_description: "Notre équipe sera notifiée.",
    watch_demo: "Regarder notre démo",
    watch_demo_description: "Regardez comment Beavr simplifie le processus.",
    read_demo: "Lire notre démo",
    read_demo_description: "Accédez à nos instructions spécifiques.",
    see_framework_website: "Voir le site web du référentiel",
    see_framework_website_description: "Accédez à l'information officielle.",
});
