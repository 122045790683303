import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAiLoaderTranslations } from "./i18n";

export const Loader = () => {
    const { t, keys } = useAiLoaderTranslations();

    return (
        <FlexRow ml="1" mt="2">
            <Text variant="body" mr="2" className="text-accent-1-300">
                {t(keys.loader)}
            </Text>

            <Icon name="loaderAnimated" className="text-accent-1-300" />
        </FlexRow>
    );
};
