import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { type Dispatch, type FC, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionnaireTemplates } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";
import {
    type QuestionnaireCreationModalStep,
    type QuestionnaireTemplate,
} from "../../types";

export const TemplatesStep: FC<{
    isTemplateOnly?: boolean;
    onStepChange: Dispatch<SetStateAction<QuestionnaireCreationModalStep>>;
    onTemplateChange: Dispatch<SetStateAction<QuestionnaireTemplate | null>>;
}> = ({ isTemplateOnly, onStepChange, onTemplateChange }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const handleTemplateStart = ({
        id,
        name,
        locale,
    }: {
        id: string;
        name: string;
        locale: { availableLocales: string[] };
    }) => {
        onTemplateChange({
            id,
            name,
            locale,
        });
        onStepChange("questionnaireForm");
    };

    const handleBuildMyOwn = () => {
        onTemplateChange(null);
        onStepChange("questionnaireForm");
    };

    const { templates, isPending } = useQuestionnaireTemplates();

    return (
        <Modal.Content>
            <Modal.Header withClose>
                <Modal.Title>
                    {t(keys.create_questionnaire_modal_title)}
                </Modal.Title>
                <Modal.Subtitle>
                    {t(keys.create_questionnaire_modal_subtitle_templates)}
                </Modal.Subtitle>
            </Modal.Header>
            <Modal.Body>
                <FlexCol
                    alignItems="start"
                    className="rounded-lg border border-tertiary"
                >
                    <FlexRow
                        h="8"
                        alignItems="center"
                        alignSelf="stretch"
                        className="border-b border-tertiary bg-secondary"
                    >
                        <FlexRow px="4" alignItems="center">
                            <Text variant="body-bold" color="secondary">
                                {t(keys.select_template)}
                            </Text>
                        </FlexRow>
                    </FlexRow>
                    {isPending && (
                        <Skeleton br="lg" className="w-full h-[50px]" />
                    )}
                    {templates?.map((template) => (
                        <TemplateItem
                            id={template.id}
                            key={template.id}
                            name={template.name ?? ""}
                            locale={template.locale ?? { availableLocales: [] }}
                            questionsCount={template?.questionsCount ?? 0}
                            onStart={handleTemplateStart}
                        />
                    ))}
                </FlexCol>
            </Modal.Body>
            <Modal.Footer className="w-full flex justify-stretch">
                {!isTemplateOnly && (
                    <Button
                        className="w-full"
                        variant="flatPrimary"
                        onClick={handleBuildMyOwn}
                    >
                        {t(keys.build_my_own)}
                    </Button>
                )}
            </Modal.Footer>
        </Modal.Content>
    );
};

const TemplateItem: FC<{
    id: string;
    name: string;
    locale: { availableLocales: string[] };
    questionsCount: number;
    onStart: ({
        id,
        name,
        locale,
    }: {
        id: string;
        name: string;
        locale: { availableLocales: string[] };
    }) => void;
}> = ({ id, name, locale, questionsCount, onStart }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const handleClick = () => {
        onStart({ id, name, locale });
    };
    return (
        <FlexRow
            py="3"
            px="4"
            w="full"
            justifyContent="between"
            alignItems="center"
        >
            <FlexCol alignItems="start" justifyContent="center" gap="1.5">
                <Text variant="body-bold">{name}</Text>
                <Text variant="body-sm">{questionsCount} questions</Text>
            </FlexCol>
            <FlexRow className="cursor-pointer">
                <Button variant="flatPrimary" onClick={handleClick}>
                    {t(keys.start)}
                </Button>
            </FlexRow>
        </FlexRow>
    );
};
