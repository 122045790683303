import { QuestionnaireStatus } from "@generated/client/graphql";
import {
    ArrayParam,
    decodeArray,
    encodeArray,
    StringParam,
    withDefault,
} from "use-query-params";

const QuestionnaireStatusParam = {
    encode: (array: (string | null)[]) => encodeArray(array),

    decode: (arrayStr: string | (string | null)[] | null | undefined) => {
        const decodedValues = decodeArray(arrayStr);
        if (Array.isArray(decodedValues)) {
            const acceptedValues = decodedValues.filter(
                (v) =>
                    !!v &&
                    typeof v === "string" &&
                    Object.values(QuestionnaireStatus).includes(
                        v as QuestionnaireStatus,
                    ),
            );
            return acceptedValues as QuestionnaireStatus[];
        }
        return [];
    },
};

export const activeFilterParamArgs = {
    searchFilter: ["search", withDefault(StringParam, "")],
    tagFilter: ["tag", withDefault(ArrayParam, [])] as const,
    statusFilter: [
        "status",
        withDefault(QuestionnaireStatusParam, []),
    ] as const,
} as const;
