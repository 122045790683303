import { useSetToast } from "@app/components/Toast";
import {
    ReportingDatpoint,
    hasDatapointValue,
    isNumCell,
    isTableCell,
} from "@app/usecases/ReportingUseCases";
import Input from "@design-system/Table/CellInput";
import { ValueInputProps } from "@design-system/Table/CellInput/ValueInput";
import { cn } from "@design-system/Utilities";
import { File, TableEntryValue, Unit } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toFillSwitchAtom } from "../../context";
import { useUpsertDatapoint } from "../../data";
import "../../i18n";

const IndicatorDatapointCellInput = ({
    indicatorId,
    groupId,
    unit,
    datapoint,
    calculation,
    proofs,
}: {
    indicatorId: string;
    groupId: string;
    unit: Unit;
    datapoint: ReportingDatpoint | undefined | null;
    calculation: string | undefined;
    proofs: File[] | undefined;
}) => {
    const { t } = useTranslation("ReportingPage");
    const hasDatapoint = !!datapoint;
    const jsonValue = datapoint?.value;

    const [noDataCheck, setNoDataChecked] = useState<boolean | undefined>(
        jsonValue?.noData,
    );

    const [value, setValue] = useState<string | number | undefined>(
        noDataCheck
            ? undefined
            : isNumCell(unit?.type)
              ? jsonValue?.float
              : jsonValue?.string,
    );

    const [keyValuePairs, setKeyValuePairs] = useState<
        { key: string; value: string }[] | undefined
    >(
        noDataCheck
            ? undefined
            : jsonValue?.table?.map(
                  (row: { key: string; value: { string: string } }) => ({
                      key: row.key,
                      value: row.value.string,
                  }),
              ),
    );

    const onValueChange = (value: string | number | undefined) => {
        setValue(value);
        if (value !== undefined) {
            setNoDataChecked(false);
        }
    };
    const [comment, setComment] = useState<string | undefined>(
        datapoint?.comment || "",
    );
    const [datapointHasValue, setDatapointHasValue] = useState<boolean>(
        hasDatapointValue(
            datapoint,
            unit?.type,
            jsonValue?.noData,
            jsonValue?.float,
            jsonValue?.string,
            jsonValue?.table,
        ),
    );

    useEffect(() => {
        setDatapointHasValue(
            hasDatapointValue(
                datapoint,
                unit?.type,
                noDataCheck,
                value as number,
                value as string,
                keyValuePairs,
            ),
        );
    }, [value, noDataCheck, datapoint, keyValuePairs]);

    const {
        mutate: upsertMutate,
        isPaused: isUpsertPaused,
        submittedAt: upsertSubmittedAt,
    } = useUpsertDatapoint();

    const toFillSwitch = useAtomValue(toFillSwitchAtom);

    const { setToastSuccess, setToastError } = useSetToast();

    const keyValuePairsToJsonTableValue = (
        keyValuePairs: { key: string; value: string }[] | undefined,
    ) => {
        return keyValuePairs?.map((row) => ({
            key: row.key,
            value: { string: row.value },
        }));
    };

    const onBlur = async (
        value: string | number | undefined | TableEntryValue[],
    ) => {
        if (
            (isNumCell(unit?.type) && value === jsonValue?.float) ||
            (!isNumCell && value === jsonValue?.string) ||
            (isTableCell(unit?.type) &&
                _.isEqual(
                    keyValuePairsToJsonTableValue(keyValuePairs),
                    jsonValue?.table,
                ))
        ) {
            return;
        }
        const newValue = isNumCell(unit?.type)
            ? { float: value as number }
            : isTableCell(unit?.type)
              ? { table: keyValuePairsToJsonTableValue(keyValuePairs) }
              : { string: value as string };

        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    value: newValue,
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    const handleCommentBlur = async () => {
        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    comment: comment || null,
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    const handleCheckNodata = async (checked: boolean) => {
        setNoDataChecked(checked);
        setValue(undefined);
        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    value: { noData: checked },
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    useEffect(() => {
        // Tanstack usually pauses the query when there is no internet connection
        if (isUpsertPaused) {
            setToastError(t("update_no_internet"));
        }
        // we need to observe the upsertSubmittedAt to trigger the toast
        // so the toaster apprears every time a datacell is updated and
        // the connection is still paused
    }, [upsertSubmittedAt, isUpsertPaused]);

    const tableCellInput: ValueInputProps = {
        type: "string",
        value: value,
        datapointHasValue,
        datapointHasComment: !!comment,
        datapointHasProofs: !!proofs?.length,
        unit: unit?.shortName || "",
        calculation: calculation || "",
        onValueChange: onValueChange,
        onBlur,
        noDataStateText: t("no_data_available"),
        noData: noDataCheck,
    } as ValueInputProps;
    if (isNumCell(unit?.type)) {
        tableCellInput.type = "number";
    }
    if (isTableCell(unit?.type)) {
        (tableCellInput.type = "table"),
            (tableCellInput.value = keyValuePairs),
            (tableCellInput.onValueChange = setKeyValuePairs);
    }

    return (
        <div className="h-[90px]">
            <Input>
                <Input.ValueInput
                    {...tableCellInput}
                    className={cn(
                        !datapointHasValue && toFillSwitch && "bg-warning-100",
                        !tableCellInput.value && "focus-within:bg-warning-100",
                    )}
                />
                <Input.CommentPopover
                    comment={comment}
                    onCommentChange={setComment}
                    onCommentBlur={handleCommentBlur}
                    checked={noDataCheck}
                    onChecked={handleCheckNodata}
                    checkTextPlaceholder={t("no_data_available_checkbox")}
                    commentTriggerTextPlaceholder={
                        tableCellInput.value
                            ? t("comment_trigger")
                            : t("no_data_available")
                    }
                    noDataTriggerTextPlaceholder={t("no_data_available")}
                    proofs={proofs}
                />
            </Input>
        </div>
    );
};

export default IndicatorDatapointCellInput;
