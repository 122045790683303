import { ThemeTagSelect } from "@app/shared/components/Theme/ThemeTagSelect";
import { useThemes } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { activeEntityIdAtom } from "../../ctx";
import { useIndicatorsFiltered } from "../../objective/data";
import { useColumns } from "./columns";
import {
    selectedIndicatorIdAtom,
    selectedSubthemesAtom,
    selectedThemeIdAtom,
} from "./ctx";
import { keys, RESOURCE_NAME } from "./i18n";

export const SelectIndicatorPage = () => {
    const activeEntityId = useAtomValue(activeEntityIdAtom);
    const { themes } = useThemes();
    const [selectedThemeId, setSelectedThemeId] = useAtom(selectedThemeIdAtom);
    const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
        selectedSubthemesAtom,
    );
    const setSelectedIndicatorId = useSetAtom(selectedIndicatorIdAtom);
    const handleThemeChange = (themeId: string) => {
        if (themeId !== selectedThemeId) {
            setSelectedIndicatorId(undefined);
        }
        setSelectedThemeId(themeId);
        setSelectedSubthemesIds([]);
    };
    const theme = themes?.find((theme) => theme.id === selectedThemeId);
    const subthemeIds = theme?.subthemes?.map((subtheme) => subtheme.id) || [];
    const acceptedUnitTypes = ["INTEGER", "FLOAT"];
    const { indicators, isPending } = useIndicatorsFiltered(
        {
            subthemeIds,
        },
        activeEntityId,
    );
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const filteredIndicators = useMemo(() => {
        const indicatorsActiveOnEntity = indicators?.indicators2?.filter(
            (indicator) => !!indicator.entityConfig?.active,
        );
        const numericIndicators = indicatorsActiveOnEntity?.filter((ind) =>
            acceptedUnitTypes.includes(ind.unit?.type || ""),
        );
        return !searchString
            ? numericIndicators
            : numericIndicators?.filter(
                  (ind) =>
                      ind.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()) ||
                      ind?.subtheme?.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()),
              );
    }, [indicators, searchString]);

    const columns = useColumns();

    const columnFilters = useMemo(() => {
        const filters = [];

        if (!!selectedSubthemesIds.length) {
            filters.push({ id: "subtheme", value: selectedSubthemesIds });
        }

        return filters;
    }, [selectedSubthemesIds]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
            columnFilters,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 5,
        });
    }, [indicators, searchString, selectedSubthemesIds]);

    return (
        <FlexCol w="full" gap="4">
            <FlexRow className="w-full h-7" alignItems="center" gap="3">
                <ThemeTagSelect
                    themeId={selectedThemeId}
                    setThemeId={handleThemeChange}
                    trigger={simpleTrigger(theme)}
                />
                <SearchBar
                    searchString={searchString}
                    setSearchString={setSearchString}
                    className="w-full"
                />
            </FlexRow>
            <Table
                columns={columns}
                data={filteredIndicators || []}
                options={tableOptions}
                loading={isPending}
                EmptyBody={TableEmptyState}
                variant="grid"
            />
        </FlexCol>
    );
};

const simpleTrigger = (theme?: { name: string; color: string }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexRow
            alignItems="center"
            alignSelf="stretch"
            justifyContent="center"
            className="border border-neutral-150 rounded-xl p-1.5 bg-secondary cursor-pointer max-w-full"
        >
            <div
                style={{
                    color: theme?.color,
                }}
            >
                <Icon name="tagCircle" size="md" />
            </div>
            <Ellipsis asChild hasTooltip>
                <Text variant="body-bold" color="primary" px="1">
                    {theme ? theme?.name : t(keys.select_theme)}
                </Text>
            </Ellipsis>
            <Icon name="angleDown" size="sm" />
        </FlexRow>
    );
};

const TableEmptyState: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" w="full" className="my-8">
                <Icon name="search" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {t(keys.title_indicator_no_results)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {t(keys.no_indicator_found_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
