import { graphql } from "@generated/client";
import {
    type CreateEntityMutationVariables,
    type UpdateEntityMutationVariables,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetAllEntitiesQuery = graphql(`
    query GetAllEntities {
        entities {
            id
            name
            type
            isDeletable
            isDefault
            isParent
            parent {
                id
                name
            }
        }
    }
`);

const CreateEntityMutation = graphql(`
    mutation CreateEntity(
        $name: String!
        $type: String!
        $parentId: String
        $isParent: Boolean
    ) {
        createEntity(
            input: {
                name: $name
                type: $type
                parentId: $parentId
                isParent: $isParent
            }
        ) {
            id
        }
    }
`);

const UpdateEntityMutation = graphql(`
    mutation updateEntity(
        $id: String!
        $name: String
        $parentId: String
        $isParent: Boolean
    ) {
        updateEntity(
            id: $id
            set: { name: $name, parentId: $parentId, isParent: $isParent }
        ) {
            id
            name
            type
            isDeletable
            isDefault
            isParent
            parent {
                id
                name
            }
        }
    }
`);

const DeleteEntityMutation = graphql(`
    mutation deleteEntity($id: String!) {
        deleteEntity(id: $id) {
            status
            data {
                id
            }
            error {
                message
            }
        }
    }
`);

export class GraphQLEntityRepository {
    getMany = async () => {
        return graphqlClientKoyeb.request(
            GetAllEntitiesQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    create = async (input: CreateEntityMutationVariables) => {
        return graphqlClientKoyeb.request(
            CreateEntityMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    delete = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteEntityMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    update = async (input: UpdateEntityMutationVariables) => {
        return graphqlClientKoyeb.request(
            UpdateEntityMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
