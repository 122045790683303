import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "edit-or-delete-dropdown";

const keys = addTranslations(RESOURCE_NAME, {
    edit_questionnaire_modal_title: {
        en: "Edit questionnaire",
        fr: "Modifier le questionnaire",
    },
    delete_questionnaire_modal_title: {
        en: "Delete questionnaire",
        fr: "Supprimer le questionnaire",
    },
    delete_questionnaire_modal_description: {
        en: "Are you sure you want to delete this questionnaire?",
        fr: "Êtes-vous sûr de vouloir supprimer ce questionnaire ?",
    },
    delete_questionnaire_modal_cancel_button_label: {
        en: "Cancel",
        fr: "Annuler",
    },
    delete_questionnaire_modal_confirm_button_label: {
        en: "Delete",
        fr: "Supprimer",
    },
    edit_questionnaire_modal_tooltip_no_name: {
        en: "Please enter a name for the questionnaire",
        fr: "Veuillez entrer un nom pour le questionnaire",
    },
    edit_questionnaire_modal_name_placeholder: {
        en: "Name",
        fr: "Nom",
    },
    edit_questionnaire_modal_requester_email_placeholder: {
        en: "Requester email",
        fr: "Email du demandeur",
    },
    edit_questionnaire_modal_company_name_placeholder: {
        en: "Company name",
        fr: "Nom de la société",
    },
    edit_questionnaire_modal_deadline_placeholder: {
        en: "Deadline",
        fr: "Date limite",
    },
    edit_questionnaire_modal_locale_placeholder: {
        en: "Locale",
        fr: "Locale",
    },
    edit_questionnaire_modal_cancel_label: {
        en: "Cancel",
        fr: "Annuler",
    },
    edit_questionnaire_modal_confirm_label: {
        en: "Save",
        fr: "Enregistrer",
    },
});
export function useEditOrDeleteDropdownTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
