import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    useColumns,
    useContributorColumns,
    useIrrelevantColumns,
} from "./columns";

import {
    selectedStatusesAtomFamily,
    selectedSubthemesAtom,
    selectedTagsAtomFamily,
    selectedTierAtomFamily,
    selectedUserIdsAtomFamily,
} from "@app/store/documentStore";
import { currentUserRoleAtom } from "@app/store/userStore";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    Table,
} from "@design-system/Table";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    Document,
    DocumentActivationStatus,
    DocumentType,
    GetDocumentsV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import LoadingTable from "../../../../components/Table/LoadingTable";
import { orderSort } from "../../../../lib/utils";
import { useDocumentPageState } from "../../hooks/pageState";
import { useFilters } from "../../hooks/useFilters";
import ActionBar from "./components/Savebar";
import { fuzzyGlobalFilterFn } from "./globalFilterFn";

import { Icon } from "@design-system/Icon";
import { Text } from "@design-system/Typography/Text";
import { keys, RESOURCE_NAME } from "./i18n";

function DocumentsTableInner({
    type,
    documents,
}: {
    type: DocumentType;
    documents: Document[];
}) {
    const { org_uname } = useParams();
    const { isContributor } = useAtomValue(currentUserRoleAtom);

    const { state, setState } = useDocumentPageState();

    const { search, filters: activeFilters } = useFilters();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const selectedTagIds = useAtomValue(selectedTagsAtomFamily(org_uname));
    const selectedUsersIds = useAtomValue(selectedUserIdsAtomFamily(org_uname));
    const selectedStatuses = useAtomValue(
        selectedStatusesAtomFamily(org_uname),
    );
    const selectedTier = useAtomValue(selectedTierAtomFamily(org_uname));

    const columnFilters = useMemo(() => {
        const filters = [];

        if (activeFilters.themeId) {
            filters.push({ id: "theme", value: activeFilters.themeId });
        }

        if (activeFilters.subthemeIds) {
            filters.push({
                id: "subtheme",
                value: activeFilters.subthemeIds,
            });
        }
        if (selectedStatuses[type]?.length) {
            filters.push({ id: "status", value: selectedStatuses[type] });
        }
        if (selectedTagIds[type]?.length) {
            filters.push({ id: "tags", value: selectedTagIds[type] });
        }
        if (selectedUsersIds[type]?.length) {
            filters.push({ id: "owner", value: selectedUsersIds[type] });
        }
        return filters;
    }, [
        activeFilters,
        selectedStatuses,
        selectedUsersIds,
        selectedTagIds,
        selectedTier,
    ]);

    const columns = isContributor
        ? useContributorColumns(type)
        : useColumns(type);

    const selectedDocuments = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return documents[Number(idx)];
        });

    const setPagination: Dispatch<SetStateAction<PaginationState>> = (
        updater,
    ) => {
        const nextState =
            typeof updater === "function" ? updater(state.pagination) : updater;
        nextState && setState({ pagination: nextState });
    };

    useEffect(() => {
        setState({ pagination: { ...state.pagination, pageIndex: 0 } });
    }, [selectedTagIds, selectedUsersIds, selectedStatuses, selectedTier]);

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        globalFilterFn: fuzzyGlobalFilterFn,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            columnFilters,
            pagination: state.pagination,
            sorting,
            globalFilter: search,

            columnVisibility: { subtheme: false, theme: false, tier: false },
        },
        autoResetPageIndex: false,
    };

    const unset = () => setRowSelection({});

    const renderEmptyBody = useCallback(
        () => <EmptyState type={type} />,
        [type],
    );

    return (
        <>
            <Table
                columns={columns}
                data={documents}
                options={tableOptions}
                EmptyBody={renderEmptyBody}
            />
            <ActionBar documents={selectedDocuments} unset={unset} />
        </>
    );
}

export default function DocumentsTable({ type }: { type: DocumentType }) {
    const { data, isLoading } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.Relevant },
        { enabled: type !== undefined },
    );
    return !data && isLoading ? (
        <LoadingTable />
    ) : data?.documents ? (
        <DocumentsTableInner
            type={type}
            documents={orderSort(data.documents || []) as Document[]}
        />
    ) : (
        <div>Error, refresh ...</div>
    );
}

export const useIrrelevantDocuments = (type?: DocumentType) => {
    const { data } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.NotRelevant },
        { enabled: type !== undefined },
    );

    return (data?.documents || []) as Document[];
};
export const useIrrelevantDocumentsTable = (
    documents: Document[],
    type: DocumentType,
) => {
    const { org_uname } = useParams();

    const { state } = useDocumentPageState();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selecteSubthemesIds, setSelectedSubthemesIds] = useAtom(
        selectedSubthemesAtom,
    );
    const selectedTier = useAtomValue(selectedTierAtomFamily(org_uname));
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 5, //default page size
    });

    const columnFilters = useMemo(() => {
        const filters = [];

        if (selecteSubthemesIds?.length) {
            filters.push({ id: "subtheme", value: selecteSubthemesIds });
        }
        if (selectedTier[type]) {
            filters.push({ id: "tier", value: selectedTier[type] });
        }
        return filters;
    }, [selectedTier, selecteSubthemesIds]);

    const columns = useIrrelevantColumns(type);
    const data = documents;
    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        globalFilterFn: fuzzyGlobalFilterFn,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            columnFilters,
            pagination,
            sorting,
            globalFilter: state.irrelevantSearchFilter,

            columnVisibility: { tier: false },
        },
    };

    useEffect(() => {
        setSelectedSubthemesIds(selecteSubthemesIds);
    }, [selecteSubthemesIds]);
    return { columns, data, tableOptions };
};

const EmptyState = ({ type }: { type: DocumentType }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const { search, filters: activeFilters } = useFilters();

    const { org_uname } = useParams();
    const selectedTagIds = useAtomValue(selectedTagsAtomFamily(org_uname));
    const selectedUsersIds = useAtomValue(selectedUserIdsAtomFamily(org_uname));
    const selectedStatuses = useAtomValue(
        selectedStatusesAtomFamily(org_uname),
    );
    const selectedTier = useAtomValue(selectedTierAtomFamily(org_uname));

    const hasActiveFilters = useMemo(() => {
        return !!(
            search ||
            Object.values(activeFilters).some(
                (value) => value && value.length,
            ) ||
            selectedTagIds[type]?.length ||
            selectedUsersIds[type]?.length ||
            selectedStatuses[type]?.length ||
            selectedTier[type]
        );
    }, [
        search,
        activeFilters,
        selectedTagIds,
        selectedUsersIds,
        selectedStatuses,
        selectedTier,
        type,
    ]);

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" className="my-8">
                {hasActiveFilters && <Icon name="search" />}
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {hasActiveFilters
                            ? t(keys.empty_state_search_title)
                            : t(keys.empty_state_initial_title)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {hasActiveFilters
                            ? t(keys.empty_state_search_subtitle)
                            : t(keys.empty_state_initial_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
