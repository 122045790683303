import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "question-components";

const keys = addTranslations(RESOURCE_NAME, {
    status_tooltip: { en: "Status: {{status}}", fr: "Statut: {{status}}" },
    assign_owner_tooltip: {
        en: "Assign the person from your organization who owns this question.",
        fr: "Associez la personne de votre organisation qui est propriétaire de cette question.",
    },
    answer_placeholder: { en: "Answer", fr: "Réponse" },
    comment_placeholder: { en: "Note", fr: "Note" },
    answer_with_unit_placeholder: {
        en: "Answer ({{unit}})",
        fr: "Réponse ({{unit}})",
    },
    answer_saved: { en: "Answer saved", fr: "Réponse enregistrée" },
    comment_saved: { en: "Note saved", fr: "Note enregistrée" },
    answer_saved_error: {
        en: "Error while saving answer",
        fr: "Erreur lors de l'enregistrement de la réponse",
    },
    comment_saved_error: {
        en: "Error while saving note",
        fr: "Erreur lors de l'enregistrement de la note",
    },
    evidence_files: { en: "Documents:", fr: "Documents:" },
    validate_answer: { en: "Complete", fr: "Compléter" },
    reset_answer: { en: "Reset question", fr: "Réinitialiser la question" },
    show_comment_label: { en: "Add note", fr: "Ajouter une note" },
    show_comment_tooltip: {
        en: "Include additional detail in a note",
        fr: "Inclure des détails supplémentaires dans une note",
    },
    delete_comment: { en: "Remove note", fr: "Supprimer la note" },
    show_upload_label: { en: "Add documents", fr: "Ajouter des documents" },
    delete_upload: { en: "Remove document", fr: "Supprimer le document" },
    show_upload_tooltip: {
        en: "Support answer with reference documents",
        fr: "Prendre en charge la réponse avec des documents de référence",
    },
    validate_answer_tooltip: {
        en: "Complete this question when an answer is provided",
        fr: "Complétez cette question lorsqu'une réponse est fournie",
    },
    reset_validation: { en: "Edit", fr: "Modifier" },
    show_ai_suggestion_label: { en: "AI suggestion", fr: "Suggestion AI" },
    show_ai_suggestion_tooltip: {
        en: "Include AI generated answer",
        fr: "Inclure la réponse générée par AI",
    },
    depends_on_question: {
        en: "This question depends on the answer of the question: {{question}}",
        fr: "Cette question dépend de la réponse de la question: {{question}}",
    },
    view_full_guidance: {
        en: "View full guidance",
        fr: "Afficher la guidance complète",
    },
    full_guidance_title: {
        en: "Question guidance",
        fr: "Guidance de la question",
    },
    expertise_guidance: { en: "BEAVR GUIDANCE", fr: "GUIDANCE BEAVR" },
    framework_guidance: {
        en: "{{frameworkName}} GUIDANCE",
        fr: "GUIDANCE {{frameworkName}}",
    },
    owner_assign_no_owner: { en: "Unassigned", fr: "Non assigné" },
});
export function useQuestionTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
