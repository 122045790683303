import { GraphQLQuickAnswerRepository } from "@app/repositories/GraphQLRepositories/QuickAnswerRepository";
import { useMutation } from "@tanstack/react-query";

const quickAnswerRepository = new GraphQLQuickAnswerRepository();

export const askAiKeys = {
    all: ["askAi"] as const,
};

export function useGenerateQuickAnswer() {
    const { mutate: generateQuickAnswer, ...mutation } = useMutation({
        mutationFn: quickAnswerRepository.generateQuickAnswer,
        mutationKey: askAiKeys.all,
    });
    return { generateQuickAnswer, ...mutation };
}
