import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "create-custom-indicator-modal";

const keys = addTranslations(RESOURCE_NAME, {
    title: {
        en: "Create custom indicator",
        fr: "Créer un indicateur personnalisé",
    },
    save: {
        en: "Create",
        fr: "Créer",
    },
    name: {
        en: "Name",
        fr: "Nom",
    },
    description: {
        en: "Description",
        fr: "Description",
    },
    theme: {
        en: "Theme",
        fr: "Thème",
    },
    subTheme: {
        en: "Subtheme",
        fr: "Sous-thème",
    },
    unit: {
        en: "Unit",
        fr: "Unité",
    },
    selectUnit: {
        en: "Select unit",
        fr: "Sélectionner une unité",
    },
    success: {
        en: "Indicator created successfully",
        fr: "Indicateur créé avec succès",
    },
    error: {
        en: "Failed to create indicator",
        fr: "Erreur lors de la création de l'indicateur",
    },
    selectTheme: {
        en: "Select a theme",
        fr: "Sélectionner un thème",
    },
    selectSubtheme: {
        en: "Select a subtheme",
        fr: "Sélectionner un sous-thème",
    },
});

export function useCreateCustomIndicatorModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
