import { useClerk } from "@clerk/clerk-react";
import { Textfield } from "@design-system/Inputs/Textfield";
import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../../generated/client/gql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../QueryClientWithHeaders";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../components/Button/ButtonShared";
import Checkbox from "../../../components/Checkbox";
import { setSaveBarStateAtom } from "../../../components/SaveBar";

const GetFullProfileDocument = graphql(`
    query GetFullProfile {
        currentUser {
            id
            avatar {
                signedUrl
            }
            firstName
            lastName
            email
            locale
        }
    }
`);

const UpdateProfileDocument = graphql(`
    mutation updateUserProfile(
        $id: String!
        $firstName: String
        $lastName: String
        $locale: Locale
    ) {
        updateUser(
            id: $id
            set: { firstName: $firstName, lastName: $lastName, locale: $locale }
        ) {
            id
            firstName
            lastName
            avatar {
                signedUrl
            }
            email
            locale
        }
    }
`);

export default function ProfileSetting() {
    const { signOut } = useClerk();

    const { t } = useTranslation("Profile");
    const { data } = useGraphQL(GetFullProfileDocument);
    const { mutate, isPending } = useGraphQLMutation(UpdateProfileDocument);

    const [firstName, setFirstName] = useState<string | undefined>(
        data?.currentUser.firstName,
    );
    const [lastName, setLastName] = useState<string | undefined>(
        data?.currentUser.lastName,
    );
    const [locale, setLocale] = useState<string | undefined>(
        data?.currentUser.locale,
    );

    const reset = () => {
        setFirstName(data?.currentUser.firstName);
        setLastName(data?.currentUser.lastName);
        setLocale(data?.currentUser.locale);
    };
    const setSaveBarState = useSetAtom(setSaveBarStateAtom);
    const client = useQueryClient();
    const save = () => {
        data &&
            !isPending &&
            mutate(
                {
                    id: data?.currentUser.id,
                    firstName: firstName,
                    lastName: lastName,
                    locale: locale,
                },
                {
                    onSuccess(updatedData) {
                        data.currentUser = updatedData.updateUser;
                        client.refetchQueries({
                            queryKey: ["GetCurrentUserProfile"],
                        });
                    },
                },
            );
    };

    useEffect(() => {
        reset();
    }, [data]);

    const isModified =
        firstName !== data?.currentUser.firstName ||
        lastName !== data?.currentUser.lastName ||
        locale !== data?.currentUser.locale ||
        false;

    useEffect(() => {
        setSaveBarState({ loading: isPending });
    }, [isPending]);

    useEffect(() => {
        setSaveBarState({
            open: isModified,
            onReset: reset,
            loading: isPending,
            content: (
                <div className=" text-white regular-highlight">
                    {t("message")}
                </div>
            ),
            mainButton: (
                <ButtonRegular
                    text={t("buttonSaveTitle")}
                    onClick={save}
                    loading={isPending}
                />
            ),
        });
    }, [isModified, firstName, lastName, locale]);

    const setFirstNameFromEvent = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };
    const setLastNameFromEvent = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    return (
        <>
            <div className="p-4 space-y-4 w-[480px]">
                <Textfield
                    title={t("firstName")}
                    value={firstName}
                    onChange={setFirstNameFromEvent}
                    id="firstName"
                    placeholder={t("firstName")}
                    type="text"
                />
                <Textfield
                    title={t("lastName")}
                    value={lastName}
                    onChange={setLastNameFromEvent}
                    id="lastName"
                    placeholder={t("lastName")}
                    type="text"
                />
                <Textfield
                    title={t("email")}
                    value={data?.currentUser.email}
                    id="email"
                    placeholder={t("email")}
                    type="text"
                    disabled
                />
                <div className="flex justify-stretch">
                    <div className="w-full font-bold"> {t("language")}</div>
                    <div className="w-full  flex space-x-2">
                        <Checkbox
                            checked={locale === "en"}
                            onClick={() => setLocale("en")}
                        />
                        <div>{t("english")}</div>
                    </div>
                    <div className="w-full flex space-x-2">
                        <Checkbox
                            checked={locale === "fr"}
                            onClick={() => setLocale("fr")}
                        />
                        <div>{t("french")}</div>
                    </div>
                </div>

                <ButtonRegular
                    text={t("logout")}
                    className="w-full"
                    variant={ButtonType.OUTLINED_DANGER}
                    onClick={() => signOut()}
                />
            </div>
        </>
    );
}
