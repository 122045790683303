import { useSetToast } from "@app/components/Toast";
import { QuestionModal } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/OverviewTab/components/QuestionModal";
import { type QuestionModalConfirmArg } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/OverviewTab/types";
import { questionnaireLocaleAtom } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/ctx";
import { useCurrentUserLocale } from "@app/shared/utils/currentUserLocale";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Table } from "@design-system/Table";
import {
    ResponseStatus,
    type SupplierTemplateQuestionFilterInput,
} from "@generated/client/graphql";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, type FC } from "react";
import { useQueryParam } from "use-query-params";
import { editQuestionIdAtom, filterParamArgs, usePagination } from "../../ctx";
import {
    useShouldPollTemplateQuestions,
    useTemplateQuestions,
    useUpdateSupplierTemplateQuestion,
} from "../../data";
import { useColumns } from "./columns";
import { EmptyState } from "./EmptyState";
import { useSupplierQuestionsTableTranslations } from "./i18n";

export const QuestionTable: FC<{
    questionnaireId?: string;
    isLoading?: boolean;
}> = ({ questionnaireId, isLoading }) => {
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const userLocale = useCurrentUserLocale(SupportedLocales.EN);
    const shouldPollTemplateQuestions = useShouldPollTemplateQuestions();

    const [editQuestionId, setEditQuestionId] = useAtom(editQuestionIdAtom);

    const [tagFilter] = useQueryParam(...filterParamArgs.tagFilter);
    const [search] = useQueryParam(...filterParamArgs.search);

    const filter = useMemo(() => {
        const filter: SupplierTemplateQuestionFilterInput = {};

        if (search && currentLocale) {
            filter.search = {
                locale: currentLocale,
                value: search,
            };
        }
        if (tagFilter && Array.isArray(tagFilter)) {
            filter.tagIds = tagFilter.filter(Boolean) as string[];
        }
        return filter;
    }, [search, currentLocale, tagFilter]);

    const { pagination, ...apiPagination } = usePagination();

    const { templateQuestions, isPending } = useTemplateQuestions(
        filter,
        apiPagination,
        {
            pollingInterval: shouldPollTemplateQuestions ? 1000 : undefined,
        },
    );

    const { updateQuestion, isPending: isUpdateQuestionPending } =
        useUpdateSupplierTemplateQuestion();

    const { setToastSuccess, setToastError } = useSetToast();

    const { t, keys } = useSupplierQuestionsTableTranslations();

    const handleEditModalConfirm = ({
        nameMultiLocale,
        descriptionMultiLocale,
        metadata,
        type,
    }: QuestionModalConfirmArg) => {
        if (!editQuestionId) return;

        updateQuestion(
            {
                id: editQuestionId,
                input: {
                    nameMultiLocale,
                    descriptionMultiLocale,
                    metadata,
                    type,
                },
            },
            {
                onSettled(data, error) {
                    if (
                        error ||
                        data?.updateSupplierTemplateQuestion?.status ===
                            ResponseStatus.Error
                    ) {
                        setToastError(t(keys.toast_error_update_question));
                        return;
                    }
                    setToastSuccess(t(keys.toast_success_update_question));
                },
            },
        );
    };

    const onEditModalOpenChange = (open: boolean) => {
        if (!open) {
            setEditQuestionId(undefined);
        }
    };

    const columns = useColumns(currentLocale ?? userLocale);

    const hasTemplate = !!questionnaireId;
    const isTableLoading = hasTemplate ? isPending || isLoading : isLoading;

    useEffect(() => {
        pagination.onPaginationChange(({ pageSize }) => ({
            pageIndex: 0,
            pageSize,
        }));
    }, [filter]);

    return (
        <>
            <Table
                columns={columns}
                data={templateQuestions?.list ?? []}
                loading={isTableLoading}
                EmptyBody={EmptyState}
                pagination={pagination}
                rowCount={templateQuestions?.count}
            />
            {questionnaireId && editQuestionId && (
                <QuestionModal
                    open={!!editQuestionId}
                    setOpen={onEditModalOpenChange}
                    questionnaireId={questionnaireId}
                    questionId={editQuestionId}
                    loading={isUpdateQuestionPending}
                    onConfirm={handleEditModalConfirm}
                />
            )}
        </>
    );
};
