import i18next from "i18next";

const enResources = {
    status: "Status",
    status_UNKNOWN: "Unknown",
    status_DRAFT: "Draft",
    status_DEFINED: "Validated",
    status_COMMITTED: "Committed",
    status_ARCHIVED: "Archived",
    name_placeholder: "Click to set name",
    disabled_targets_tooltip: "Cannot be edited if targets are set",
    unassigned: "Unassigned",
    success_update: "Objective updated successfully",
    error_update: "Error updating objective",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    status: "Statut",
    status_UNKNOWN: "Inconnu",
    status_DRAFT: "Brouillon",
    status_DEFINED: "Validé",
    status_COMMITTED: "Engagé",
    status_ARCHIVED: "Archivé",
    name_placeholder: "Cliquez pour définir le nom",
    disabled_targets_tooltip:
        "Ne peut pas être modifié si des objectifs chiffrés sont définis",
    unassigned: "Non assigné",
    success_update: "Objectif mis à jour avec succès",
    error_update: "Erreur lors de la mise à jour de l'objectif",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "ObjectiveHeader";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
