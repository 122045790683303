import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import {
    QuestionType,
    type QuestionUpdateInput,
} from "@generated/client/graphql";

import { useCurrentUserLocale } from "@app/shared/utils/currentUserLocale";
import { SupportedLocales } from "@app/shared/utils/locales";
import { cn } from "@design-system/Utilities";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import {
    useQuestion,
    useQuestionnaireLocale,
    useQuestions,
} from "../../../../data";
import {
    canSubmitQuestion,
    computeMetadata,
    computeQuestionInput,
    isSelectType,
} from "../../../../services";
import { keys, RESOURCE_NAME } from "../../i18n";
import {
    getQuestionTypeLabel,
    QuestionTypeSelect,
} from "../QuestionTypeSelect";

import { QuestionDetail } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import {
    type QuestionModalConfirmArg,
    type UpsertQuestionInput,
} from "../../types";
import { DependencyBox } from "./DependencyBox";
import LocaleTabs from "./LocaleTabs";
import { NameAndDescriptionBlock } from "./NameAndDescriptionBlock";
import { OptionsBlock } from "./OptionsBlock";

type QuestionModalProps = {
    title?: string;
    questionId?: string;
    loading: boolean;
    onConfirm: (question: QuestionModalConfirmArg) => void;
    hasKeepOpenOnConfirmOption?: boolean;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    questionnaireId: string;
};

const useQuestionForm = (
    initialQuestion?: QuestionDetail,
    questionnaireId?: string,
) => {
    const curentUserlocale = useCurrentUserLocale(SupportedLocales.FR);
    const { availableLocales, defaultLocale } =
        useQuestionnaireLocale(questionnaireId);
    const [locale, setLocale] = useState<SupportedLocales>(
        defaultLocale ?? curentUserlocale,
    );

    const [questionInput, setQuestionInput] = useState<UpsertQuestionInput>(
        computeQuestionInput(initialQuestion),
    );

    const updateField = (
        field: keyof UpsertQuestionInput,
        value?:
            | string
            | string[]
            | QuestionType
            | Record<string, string>[]
            | { questionId?: string; displayIfSelected?: number[] },
    ) => {
        setQuestionInput((prev) => ({ ...prev, [field]: value }));
    };

    const updateLocaleField = (
        field: "nameMultiLocale" | "descriptionMultiLocale",
        value: string,
    ) => {
        setQuestionInput((prev) => ({
            ...prev,
            [field]: { ...prev[field], [locale]: value },
        }));
    };

    const resetForm = () => {
        setQuestionInput({
            nameMultiLocale: { en: "", fr: "" },
            descriptionMultiLocale: { en: "", fr: "" },
            type: undefined,
            unit: undefined,
            options: [],
            dependsOn: {
                questionId: undefined,
                displayIfSelected: undefined,
            },
        });
    };

    useEffect(() => {
        if (availableLocales.includes(locale)) return;
        setLocale(defaultLocale);
    }, [defaultLocale, availableLocales, locale]);

    useEffect(() => {
        setQuestionInput(computeQuestionInput(initialQuestion));
    }, [initialQuestion]);

    return {
        availableLocales,
        questionInput,
        locale,
        setLocale,
        updateField,
        updateLocaleField,
        resetForm,
    };
};

export const QuestionModal: FC<QuestionModalProps> = ({
    title,
    questionId,
    onConfirm,
    loading,
    hasKeepOpenOnConfirmOption,
    open,
    questionnaireId,
    setOpen,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { questions: allQuestions } = useQuestions({
        questionnaireId,
    });
    const { question } = useQuestion(questionnaireId, questionId);
    const {
        questionInput,
        locale,
        setLocale,
        updateField,
        updateLocaleField,
        resetForm,
        availableLocales,
    } = useQuestionForm(question, questionnaireId);

    const filteredQuestions = useMemo(() => {
        return (
            allQuestions?.filter(
                (q) =>
                    [
                        QuestionType.SingleSelect,
                        QuestionType.MultiSelect,
                    ].includes(q.type) && (q?.options?.length ?? 0) > 0,
            ) ?? []
        );
    }, [allQuestions]);

    const showOpts = isSelectType(questionInput.type);
    const disableConfirm = !canSubmitQuestion(
        questionInput,
        locale,
        question as QuestionDetail,
    );

    const handleQuestionTypeChange = (type?: QuestionType) => {
        updateField("type", type);
    };

    const handleOptionsChange = (
        opts:
            | Record<string, string>[]
            | ((opts: Record<string, string>[]) => Record<string, string>[]),
    ) => {
        const updatedInput = {
            ...questionInput,
        };

        if (typeof opts === "function") {
            return updateField("options", opts(updatedInput.options));
        }
        updateField("options", opts);
    };

    const handleConfirm = () => {
        if (!questionInput.type) return;

        const input: QuestionUpdateInput = {
            name: questionInput.nameMultiLocale?.[locale] || "",
            nameMultiLocale: isEmpty(questionInput.nameMultiLocale)
                ? null
                : questionInput.nameMultiLocale,
            description: questionInput.descriptionMultiLocale?.[locale] || null,
            descriptionMultiLocale: isEmpty(
                questionInput.descriptionMultiLocale,
            )
                ? null
                : questionInput.descriptionMultiLocale,
            type: questionInput.type,
            metadata: computeMetadata({
                type: questionInput.type,
                options: questionInput.options,
                unit: questionInput.unit,
                parentQuestionId: questionInput?.dependsOn?.questionId,
                displayIfSelected: questionInput?.dependsOn?.displayIfSelected,
            }),
            ...(question?.id
                ? {}
                : {
                      questionnaireId,
                      order: (allQuestions?.length ?? 0) + 1,
                  }),
        };
        onConfirm(input);
        resetForm();
    };

    const handleConfirmAndCreateMore = () => {
        handleConfirm();
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay centerContent>
                <Modal.Content className="max-w-[600px] w-full">
                    <Modal.Header withClose>
                        <Modal.Title>
                            {title ?? t(keys.create_questions_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="flex flex-col gap-4 max-h-[600px] overflow-y-auto">
                        <FlexCol gap="4">
                            <FlexCol gap="2">
                                <label className="text-sm font-bold">
                                    {t(
                                        keys.create_questions_modal_question_type_label,
                                    )}
                                </label>
                                <QuestionTypeSelect
                                    closeOnValueChange
                                    onChange={handleQuestionTypeChange}
                                    value={questionInput.type}
                                >
                                    <Button
                                        active={!!questionInput?.type}
                                        className="w-full flex flex-row items-center justify-between shadow-none font-regular bg-primary"
                                        variant="outlined"
                                    >
                                        {questionInput?.type
                                            ? t(
                                                  getQuestionTypeLabel(
                                                      questionInput?.type,
                                                  ),
                                              )
                                            : t(
                                                  keys.create_questions_modal_question_type_dd_label,
                                              )}
                                        <Button.Icon name="angleDown" />
                                    </Button>
                                </QuestionTypeSelect>
                            </FlexCol>
                            <Box>
                                <LocaleTabs
                                    availableLocales={availableLocales}
                                    locale={locale}
                                    onLocaleChange={setLocale}
                                />
                                <NameAndDescriptionBlock
                                    questionInput={questionInput}
                                    locale={locale}
                                    questionType={questionInput?.type}
                                    onUpdate={updateField}
                                    onUpdateLocale={updateLocaleField}
                                    className={
                                        showOpts
                                            ? "rounded-bl-none rounded-b-none"
                                            : ""
                                    }
                                />
                                {showOpts && (
                                    <OptionsBlock
                                        locale={locale}
                                        opts={questionInput.options}
                                        onChange={handleOptionsChange}
                                    />
                                )}
                            </Box>
                        </FlexCol>
                        {filteredQuestions.length > 0 && (
                            <DependencyBox
                                question={question}
                                allQuestions={allQuestions}
                                dependsOnQuestionId={
                                    questionInput?.dependsOn?.questionId
                                }
                                dependsOnQuestionAnswers={
                                    questionInput?.dependsOn?.displayIfSelected
                                }
                                handleParentQuestionChange={updateField}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(keys.create_questions_modal_cancel)}
                        </Modal.CancelCTA>
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Box>
                                    <Modal.ConfirmCTA
                                        className={cn(
                                            hasKeepOpenOnConfirmOption &&
                                                "text-primary bg-secondary hover:bg-white active:bg-tertiary border border-secondary",
                                        )}
                                        onClick={handleConfirm}
                                        disabled={disableConfirm || loading}
                                    >
                                        {t(keys.create_questions_modal_confirm)}
                                    </Modal.ConfirmCTA>
                                </Box>
                            </Tooltip.Trigger>
                            {disableConfirm && (
                                <Tooltip.Content>
                                    {t(
                                        keys.create_questions_modal_confirm_disabled_tooltip,
                                    )}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                        {hasKeepOpenOnConfirmOption && (
                            <Tooltip>
                                <Tooltip.Trigger asChild>
                                    <Box>
                                        <Modal.ConfirmCTA
                                            preventClose
                                            onClick={handleConfirmAndCreateMore}
                                            disabled={disableConfirm || loading}
                                        >
                                            {t(
                                                keys.create_questions_modal_create_more_questions,
                                            )}
                                        </Modal.ConfirmCTA>
                                    </Box>
                                </Tooltip.Trigger>
                                {disableConfirm && (
                                    <Tooltip.Content>
                                        {t(
                                            keys.create_questions_modal_confirm_disabled_tooltip,
                                        )}
                                    </Tooltip.Content>
                                )}
                            </Tooltip>
                        )}
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
