const quickAnswerBackgroundSvgStr = `
    <svg width="256" height="216" viewBox="0 0 256 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.1">
            <g clip-path="url(#clip0_14983_58999)">
                <path d="M59.5883 -43.5585L77.2481 -22.2993L72.2763 -10.9836M59.5883 -43.5585L54.6166 -32.2428M59.5883 -43.5585L31.9851 -42.1862L27.0133 -30.8705M54.6166 -32.2428L72.2763 -10.9836M54.6166 -32.2428L49.6448 -20.927M54.6166 -32.2428L27.0133 -30.8705M72.2763 -10.9836L67.3046 0.332163M49.6448 -20.927L67.3046 0.332163M49.6448 -20.927L44.6731 -9.61128M49.6448 -20.927L22.0416 -19.5547M67.3046 0.332163L62.3329 11.6479L44.6731 -9.61128M44.6731 -9.61128L17.0699 -8.23897L22.0416 -19.5547M22.0416 -19.5547L27.0133 -30.8705" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip1_14983_58999)">
                <path d="M49.6448 -20.9277L67.3046 0.33153L62.3329 11.6473M49.6448 -20.9277L44.6731 -9.61191M49.6448 -20.9277L22.0416 -19.5553L17.0699 -8.2396M44.6731 -9.61191L62.3329 11.6473M44.6731 -9.61191L39.7014 1.70384M44.6731 -9.61191L17.0699 -8.2396M62.3329 11.6473L57.3611 22.963M39.7014 1.70384L57.3611 22.963M39.7014 1.70384L34.7296 13.0196M39.7014 1.70384L12.0981 3.07614M57.3611 22.963L52.3894 34.2788L34.7296 13.0196M34.7296 13.0196L7.12643 14.3919L12.0981 3.07614M12.0981 3.07614L17.0699 -8.2396" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip2_14983_58999)">
                <path d="M39.7014 1.7032L57.3612 22.9624L52.3895 34.2781M39.7014 1.7032L34.7297 13.019M39.7014 1.7032L12.0982 3.07551L7.12651 14.3913M34.7297 13.019L52.3895 34.2781M34.7297 13.019L29.758 24.3347M34.7297 13.019L7.12651 14.3913M52.3895 34.2781L47.4178 45.5939M29.758 24.3347L47.4178 45.5939M29.758 24.3347L24.7863 35.6504M29.758 24.3347L2.15479 25.707M47.4178 45.5939L42.4461 56.9096L24.7863 35.6504M24.7863 35.6504L-2.81693 37.0228L2.15479 25.707M2.15479 25.707L7.12651 14.3913" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip3_14983_58999)">
                <path d="M29.7578 24.3341L47.4175 45.5932L42.4458 56.909M29.7578 24.3341L24.7861 35.6498M29.7578 24.3341L2.15456 25.7064L-2.81716 37.0221M24.7861 35.6498L42.4458 56.909M24.7861 35.6498L19.8143 46.9656M24.7861 35.6498L-2.81716 37.0221M42.4458 56.909L37.4741 68.2247M19.8143 46.9656L37.4741 68.2247M19.8143 46.9656L14.8426 58.2813M19.8143 46.9656L-7.78888 48.3379M37.4741 68.2247L32.5024 79.5405L14.8426 58.2813M14.8426 58.2813L-12.7606 59.6536L-7.78888 48.3379M-7.78888 48.3379L-2.81716 37.0221" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip4_14983_58999)">
                <path d="M19.8145 46.9669L37.4742 68.2261L32.5025 79.5418M19.8145 46.9669L14.8428 58.2826M19.8145 46.9669L-7.78874 48.3392L-12.7605 59.6549M14.8428 58.2826L32.5025 79.5418M14.8428 58.2826L9.87104 69.5984M14.8428 58.2826L-12.7605 59.6549M32.5025 79.5418L27.5308 90.8576M9.87104 69.5984L27.5308 90.8576M9.87104 69.5984L4.89932 80.9141M9.87104 69.5984L-17.7322 70.9707M27.5308 90.8576L22.5591 102.173L4.89932 80.9141M4.89932 80.9141L-22.7039 82.2864L-17.7322 70.9707M-17.7322 70.9707L-12.7605 59.6549" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip5_14983_58999)">
                <path d="M9.87112 69.5977L27.5309 90.8569L22.5592 102.173M9.87112 69.5977L4.8994 80.9135M9.87112 69.5977L-17.7321 70.97L-22.7038 82.2858M4.8994 80.9135L22.5592 102.173M4.8994 80.9135L-0.0723225 92.2292M4.8994 80.9135L-22.7038 82.2858M22.5592 102.173L17.5875 113.488M-0.0723225 92.2292L17.5875 113.488M-0.0723225 92.2292L-5.04404 103.545M-0.0723225 92.2292L-27.6755 93.6015M17.5875 113.488L12.6157 124.804L-5.04404 103.545M-5.04404 103.545L-32.6473 104.917L-27.6755 93.6015M-27.6755 93.6015L-22.7038 82.2858" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip6_14983_58999)">
                <path d="M-0.0722431 92.2286L17.5875 113.488L12.6158 124.804M-0.0722431 92.2286L-5.04396 103.544M-0.0722431 92.2286L-27.6755 93.6009L-32.6472 104.917M-5.04396 103.544L12.6158 124.804M-5.04396 103.544L-10.0157 114.86M-5.04396 103.544L-32.6472 104.917M12.6158 124.804L7.64409 136.119M-10.0157 114.86L7.64409 136.119M-10.0157 114.86L-14.9874 126.176M-10.0157 114.86L-37.6189 116.232M7.64409 136.119L2.67237 147.435L-14.9874 126.176M-14.9874 126.176L-42.5906 127.548L-37.6189 116.232M-37.6189 116.232L-32.6472 104.917" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip7_14983_58999)">
                <path d="M-10.0159 114.861L7.64387 136.121L2.67215 147.436M-10.0159 114.861L-14.9876 126.177M-10.0159 114.861L-37.6191 116.234L-42.5908 127.549M-14.9876 126.177L2.67215 147.436M-14.9876 126.177L-19.9593 137.493M-14.9876 126.177L-42.5908 127.549M2.67215 147.436L-2.29957 158.752M-19.9593 137.493L-2.29957 158.752M-19.9593 137.493L-24.9311 148.809M-19.9593 137.493L-47.5626 138.865M-2.29957 158.752L-7.27129 170.068L-24.9311 148.809M-24.9311 148.809L-52.5343 150.181L-47.5626 138.865M-47.5626 138.865L-42.5908 127.549" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip8_14983_58999)">
                <path d="M104.851 -23.6718L122.511 -2.41261L117.539 8.90314M104.851 -23.6718L99.8797 -12.356M104.851 -23.6718L77.2482 -22.2995L72.2765 -10.9837M99.8797 -12.356L117.539 8.90314M99.8797 -12.356L94.908 -1.0403M99.8797 -12.356L72.2765 -10.9837M117.539 8.90314L112.568 20.2189M94.908 -1.0403L112.568 20.2189M94.908 -1.0403L89.9362 10.2754M94.908 -1.0403L67.3048 0.332004M112.568 20.2189L107.596 31.5346L89.9362 10.2754M89.9362 10.2754L62.333 11.6478L67.3048 0.332004M67.3048 0.332004L72.2765 -10.9837" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip9_14983_58999)">
                <path d="M94.9082 -1.04094L112.568 20.2182L107.596 31.534M94.9082 -1.04094L89.9364 10.2748M94.9082 -1.04094L67.305 0.331371L62.3332 11.6471M89.9364 10.2748L107.596 31.534M89.9364 10.2748L84.9647 21.5906M89.9364 10.2748L62.3332 11.6471M107.596 31.534L102.624 42.8497M84.9647 21.5906L102.624 42.8497M84.9647 21.5906L79.993 32.9063M84.9647 21.5906L57.3615 22.9629M102.624 42.8497L97.6528 54.1655L79.993 32.9063M79.993 32.9063L52.3898 34.2786L57.3615 22.9629M57.3615 22.9629L62.3332 11.6471" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip10_14983_58999)">
                <path d="M84.9648 21.5899L102.625 42.8491L97.6529 54.1649M84.9648 21.5899L79.9931 32.9057M84.9648 21.5899L57.3616 22.9622L52.3899 34.278M79.9931 32.9057L97.6529 54.1649M79.9931 32.9057L75.0214 44.2214M79.9931 32.9057L52.3899 34.278M97.6529 54.1649L92.6811 65.4806M75.0214 44.2214L92.6811 65.4806M75.0214 44.2214L70.0496 55.5372M75.0214 44.2214L47.4182 45.5937M92.6811 65.4806L87.7094 76.7963L70.0496 55.5372M70.0496 55.5372L42.4464 56.9095L47.4182 45.5937M47.4182 45.5937L52.3899 34.278" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip11_14983_58999)">
                <path d="M75.0211 44.2208L92.6809 65.48L87.7091 76.7957M75.0211 44.2208L70.0494 55.5365M75.0211 44.2208L47.4179 45.5931L42.4461 56.9088M70.0494 55.5365L87.7091 76.7957M70.0494 55.5365L65.0776 66.8523M70.0494 55.5365L42.4461 56.9088M87.7091 76.7957L82.7374 88.1115M65.0776 66.8523L82.7374 88.1115M65.0776 66.8523L60.1059 78.168M65.0776 66.8523L37.4744 68.2246M82.7374 88.1115L77.7657 99.4272L60.1059 78.168M60.1059 78.168L32.5027 79.5403L37.4744 68.2246M37.4744 68.2246L42.4461 56.9088" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip12_14983_58999)">
                <path d="M65.0777 66.8536L82.7375 88.1128L77.7658 99.4285M65.0777 66.8536L60.106 78.1693M65.0777 66.8536L37.4745 68.2259L32.5028 79.5416M60.106 78.1693L77.7658 99.4285M60.106 78.1693L55.1343 89.4851M60.106 78.1693L32.5028 79.5416M77.7658 99.4285L72.7941 110.744M55.1343 89.4851L72.7941 110.744M55.1343 89.4851L50.1626 100.801M55.1343 89.4851L27.5311 90.8574M72.7941 110.744L67.8223 122.06L50.1626 100.801M50.1626 100.801L22.5594 102.173L27.5311 90.8574M27.5311 90.8574L32.5028 79.5416" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip13_14983_58999)">
                <path d="M55.1345 89.4845L72.7943 110.744L67.8225 122.059M55.1345 89.4845L50.1628 100.8M55.1345 89.4845L27.5313 90.8568L22.5596 102.173M50.1628 100.8L67.8225 122.059M50.1628 100.8L45.191 112.116M50.1628 100.8L22.5596 102.173M67.8225 122.059L62.8508 133.375M45.191 112.116L62.8508 133.375M45.191 112.116L40.2193 123.432M45.191 112.116L17.5878 113.488M62.8508 133.375L57.8791 144.691L40.2193 123.432M40.2193 123.432L12.6161 124.804L17.5878 113.488M17.5878 113.488L22.5596 102.173" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip14_14983_58999)">
                <path d="M45.1909 112.115L62.8507 133.374L57.8789 144.69M45.1909 112.115L40.2192 123.431M45.1909 112.115L17.5877 113.488L12.6159 124.803M40.2192 123.431L57.8789 144.69M40.2192 123.431L35.2474 134.747M40.2192 123.431L12.6159 124.803M57.8789 144.69L52.9072 156.006M35.2474 134.747L52.9072 156.006M35.2474 134.747L30.2757 146.063M35.2474 134.747L7.64423 136.119M52.9072 156.006L47.9355 167.322L30.2757 146.063M30.2757 146.063L2.67251 147.435L7.64423 136.119M7.64423 136.119L12.6159 124.803" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip15_14983_58999)">
                <path d="M35.2474 134.748L52.9072 156.007L47.9355 167.323M35.2474 134.748L30.2757 146.064M35.2474 134.748L7.64419 136.12L2.67247 147.436M30.2757 146.064L47.9355 167.323M30.2757 146.064L25.304 157.38M30.2757 146.064L2.67247 147.436M47.9355 167.323L42.9637 178.639M25.304 157.38L42.9637 178.639M25.304 157.38L20.3322 168.695M25.304 157.38L-2.29925 158.752M42.9637 178.639L37.992 189.955L20.3322 168.695M20.3322 168.695L-7.27097 170.068L-2.29925 158.752M-2.29925 158.752L2.67247 147.436" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip16_14983_58999)">
                <path d="M25.3039 157.379L42.9637 178.638L37.992 189.954M25.3039 157.379L20.3322 168.695M25.3039 157.379L-2.2993 158.751L-7.27101 170.067M20.3322 168.695L37.992 189.954M20.3322 168.695L15.3605 180.01M20.3322 168.695L-7.27101 170.067M37.992 189.954L33.0203 201.27M15.3605 180.01L33.0203 201.27M15.3605 180.01L10.3888 191.326M15.3605 180.01L-12.2427 181.383M33.0203 201.27L28.0485 212.585L10.3888 191.326M10.3888 191.326L-17.2145 192.699L-12.2427 181.383M-12.2427 181.383L-7.27101 170.067" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip17_14983_58999)">
                <path d="M15.3606 180.012L33.0203 201.271L28.0486 212.587M15.3606 180.012L10.3888 191.328M15.3606 180.012L-12.2427 181.384L-17.2144 192.7M10.3888 191.328L28.0486 212.587M10.3888 191.328L5.41712 202.643M10.3888 191.328L-17.2144 192.7M28.0486 212.587L23.0769 223.902M5.41712 202.643L23.0769 223.902M5.41712 202.643L0.445399 213.959M5.41712 202.643L-22.1861 204.016M23.0769 223.902L18.1052 235.218L0.445399 213.959M0.445399 213.959L-27.1578 215.331L-22.1861 204.016M-22.1861 204.016L-17.2144 192.7" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip18_14983_58999)">
                <path d="M160.058 -26.4179L177.718 -5.1587L172.746 6.15704M160.058 -26.4179L155.086 -15.1021M160.058 -26.4179L132.455 -25.0456L127.483 -13.7298M155.086 -15.1021L172.746 6.15704M155.086 -15.1021L150.114 -3.7864M155.086 -15.1021L127.483 -13.7298M172.746 6.15704L167.774 17.4728M150.114 -3.7864L167.774 17.4728M150.114 -3.7864L145.143 7.52935M150.114 -3.7864L122.511 -2.41409M167.774 17.4728L162.802 28.7885L145.143 7.52935M145.143 7.52935L117.54 8.90166L122.511 -2.41409M122.511 -2.41409L127.483 -13.7298" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip19_14983_58999)">
                <path d="M150.114 -3.78703L167.774 17.4722L162.802 28.7879M150.114 -3.78703L145.143 7.52872M150.114 -3.78703L122.511 -2.41472L117.539 8.90102M145.143 7.52872L162.802 28.7879M145.143 7.52872L140.171 18.8445M145.143 7.52872L117.539 8.90102M162.802 28.7879L157.831 40.1036M140.171 18.8445L157.831 40.1036M140.171 18.8445L135.199 30.1602M140.171 18.8445L112.568 20.2168M157.831 40.1036L152.859 51.4194L135.199 30.1602M135.199 30.1602L107.596 31.5325L112.568 20.2168M112.568 20.2168L117.539 8.90102" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip20_14983_58999)">
                <path d="M140.171 18.8458L157.831 40.105L152.859 51.4207M140.171 18.8458L135.199 30.1615M140.171 18.8458L112.568 20.2181L107.596 31.5338M135.199 30.1615L152.859 51.4207M135.199 30.1615L130.228 41.4773M135.199 30.1615L107.596 31.5338M152.859 51.4207L147.887 62.7365M130.228 41.4773L147.887 62.7365M130.228 41.4773L125.256 52.793M130.228 41.4773L102.624 42.8496M147.887 62.7365L142.916 74.0522L125.256 52.793M125.256 52.793L97.6526 54.1653L102.624 42.8496M102.624 42.8496L107.596 31.5338" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip21_14983_58999)">
                <path d="M130.228 41.4766L147.887 62.7358L142.916 74.0516M130.228 41.4766L125.256 52.7924M130.228 41.4766L102.624 42.8489L97.6527 54.1647M125.256 52.7924L142.916 74.0516M125.256 52.7924L120.284 64.1081M125.256 52.7924L97.6527 54.1647M142.916 74.0516L137.944 85.3673M120.284 64.1081L137.944 85.3673M120.284 64.1081L115.312 75.4239M120.284 64.1081L92.681 65.4804M137.944 85.3673L132.972 96.6831L115.312 75.4239M115.312 75.4239L87.7093 76.7962L92.681 65.4804M92.681 65.4804L97.6527 54.1647" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip22_14983_58999)">
                <path d="M120.284 64.1075L137.944 85.3667L132.972 96.6824M120.284 64.1075L115.312 75.4232M120.284 64.1075L92.6808 65.4798L87.7091 76.7956M115.312 75.4232L132.972 96.6824M115.312 75.4232L110.341 86.739M115.312 75.4232L87.7091 76.7956M132.972 96.6824L128 107.998M110.341 86.739L128 107.998M110.341 86.739L105.369 98.0547M110.341 86.739L82.7374 88.1113M128 107.998L123.029 119.314L105.369 98.0547M105.369 98.0547L77.7656 99.427L82.7374 88.1113M82.7374 88.1113L87.7091 76.7956" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip23_14983_58999)">
                <path d="M110.341 86.7403L128 107.999L123.029 119.315M110.341 86.7403L105.369 98.0561M110.341 86.7403L82.7374 88.1126L77.7657 99.4284M105.369 98.0561L123.029 119.315M105.369 98.0561L100.397 109.372M105.369 98.0561L77.7657 99.4284M123.029 119.315L118.057 130.631M100.397 109.372L118.057 130.631M100.397 109.372L95.4255 120.688M100.397 109.372L72.794 110.744M118.057 130.631L113.085 141.947L95.4255 120.688M95.4255 120.688L67.8223 122.06L72.794 110.744M72.794 110.744L77.7657 99.4284" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip24_14983_58999)">
                <path d="M100.397 109.371L118.057 130.63L113.085 141.946M100.397 109.371L95.4256 120.687M100.397 109.371L72.7941 110.743L67.8224 122.059M95.4256 120.687L113.085 141.946M95.4256 120.687L90.4539 132.003M95.4256 120.687L67.8224 122.059M113.085 141.946L108.114 153.262M90.4539 132.003L108.114 153.262M90.4539 132.003L85.4821 143.318M90.4539 132.003L62.8506 133.375M108.114 153.262L103.142 164.578L85.4821 143.318M85.4821 143.318L57.8789 144.691L62.8506 133.375M62.8506 133.375L67.8224 122.059" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip25_14983_58999)">
                <path d="M90.4538 132.002L108.114 153.261L103.142 164.577M90.4538 132.002L85.482 143.318M90.4538 132.002L62.8505 133.374L57.8788 144.69M85.482 143.318L103.142 164.577M85.482 143.318L80.5103 154.634M85.482 143.318L57.8788 144.69M103.142 164.577L98.1701 175.893M80.5103 154.634L98.1701 175.893M80.5103 154.634L75.5386 165.949M80.5103 154.634L52.9071 156.006M98.1701 175.893L93.1984 187.208L75.5386 165.949M75.5386 165.949L47.9354 167.322L52.9071 156.006M52.9071 156.006L57.8788 144.69" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip26_14983_58999)">
                <path d="M80.5103 154.635L98.17 175.894L93.1983 187.21M80.5103 154.635L75.5386 165.951M80.5103 154.635L52.9071 156.007L47.9353 167.323M75.5386 165.951L93.1983 187.21M75.5386 165.951L70.5668 177.266M75.5386 165.951L47.9353 167.323M93.1983 187.21L88.2266 198.526M70.5668 177.266L88.2266 198.526M70.5668 177.266L65.5951 188.582M70.5668 177.266L42.9636 178.639M88.2266 198.526L83.2549 209.841L65.5951 188.582M65.5951 188.582L37.9919 189.954L42.9636 178.639M42.9636 178.639L47.9353 167.323" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip27_14983_58999)">
                <path d="M70.5669 177.266L88.2266 198.525L83.2549 209.841M70.5669 177.266L65.5951 188.581M70.5669 177.266L42.9636 178.638L37.9919 189.954M65.5951 188.581L83.2549 209.841M65.5951 188.581L60.6234 199.897M65.5951 188.581L37.9919 189.954M83.2549 209.841L78.2832 221.156M60.6234 199.897L78.2832 221.156M60.6234 199.897L55.6517 211.213M60.6234 199.897L33.0202 201.27M78.2832 221.156L73.3115 232.472L55.6517 211.213M55.6517 211.213L28.0485 212.585L33.0202 201.27M33.0202 201.27L37.9919 189.954" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip28_14983_58999)">
                <path d="M60.6234 199.899L78.2832 221.158L73.3115 232.473M60.6234 199.899L55.6517 211.214M60.6234 199.899L33.0202 201.271L28.0485 212.587M55.6517 211.214L73.3115 232.473M55.6517 211.214L50.68 222.53M55.6517 211.214L28.0485 212.587M73.3115 232.473L68.3398 243.789M50.68 222.53L68.3398 243.789M50.68 222.53L45.7083 233.846M50.68 222.53L23.0768 223.902M68.3398 243.789L63.3681 255.105L45.7083 233.846M45.7083 233.846L18.1051 235.218L23.0768 223.902M23.0768 223.902L28.0485 212.587" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip29_14983_58999)">
                <path d="M215.264 -29.162L232.924 -7.90285L227.952 3.4129M215.264 -29.162L210.292 -17.8463M215.264 -29.162L187.661 -27.7897L182.689 -16.474M210.292 -17.8463L227.952 3.4129M210.292 -17.8463L205.321 -6.53054M210.292 -17.8463L182.689 -16.474M227.952 3.4129L222.98 14.7286M205.321 -6.53054L222.98 14.7286M205.321 -6.53054L200.349 4.78521M205.321 -6.53054L177.717 -5.15823M222.98 14.7286L218.009 26.0444L200.349 4.78521M200.349 4.78521L172.746 6.15752L177.717 -5.15823M177.717 -5.15823L182.689 -16.474" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip30_14983_58999)">
                <path d="M205.321 -6.53117L222.98 14.728L218.009 26.0438M205.321 -6.53117L200.349 4.78458M205.321 -6.53117L177.717 -5.15886L172.746 6.15688M200.349 4.78458L218.009 26.0438M200.349 4.78458L195.377 16.1003M200.349 4.78458L172.746 6.15688M218.009 26.0438L213.037 37.3595M195.377 16.1003L213.037 37.3595M195.377 16.1003L190.406 27.4161M195.377 16.1003L167.774 17.4726M213.037 37.3595L208.065 48.6753L190.406 27.4161M190.406 27.4161L162.802 28.7884L167.774 17.4726M167.774 17.4726L172.746 6.15688" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip31_14983_58999)">
                <path d="M195.377 16.0997L213.037 37.3589L208.065 48.6746M195.377 16.0997L190.405 27.4154M195.377 16.0997L167.774 17.472L162.802 28.7877M190.405 27.4154L208.065 48.6746M190.405 27.4154L185.434 38.7312M190.405 27.4154L162.802 28.7877M208.065 48.6746L203.093 59.9904M185.434 38.7312L203.093 59.9904M185.434 38.7312L180.462 50.0469M185.434 38.7312L157.83 40.1035M203.093 59.9904L198.122 71.3061L180.462 50.0469M180.462 50.0469L152.859 51.4192L157.83 40.1035M157.83 40.1035L162.802 28.7877" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip32_14983_58999)">
                <path d="M185.434 38.7325L203.094 59.9917L198.122 71.3074M185.434 38.7325L180.462 50.0482M185.434 38.7325L157.831 40.1048L152.859 51.4206M180.462 50.0482L198.122 71.3074M180.462 50.0482L175.49 61.364M180.462 50.0482L152.859 51.4206M198.122 71.3074L193.15 82.6232M175.49 61.364L193.15 82.6232M175.49 61.364L170.519 72.6797M175.49 61.364L147.887 62.7363M193.15 82.6232L188.178 93.9389L170.519 72.6797M170.519 72.6797L142.915 74.052L147.887 62.7363M147.887 62.7363L152.859 51.4206" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip33_14983_58999)">
                <path d="M175.49 61.3634L193.15 82.6225L188.178 93.9383M175.49 61.3634L170.519 72.6791M175.49 61.3634L147.887 62.7357L142.915 74.0514M170.519 72.6791L188.178 93.9383M170.519 72.6791L165.547 83.9949M170.519 72.6791L142.915 74.0514M188.178 93.9383L183.207 105.254M165.547 83.9949L183.207 105.254M165.547 83.9949L160.575 95.3106M165.547 83.9949L137.944 85.3672M183.207 105.254L178.235 116.57L160.575 95.3106M160.575 95.3106L132.972 96.6829L137.944 85.3672M137.944 85.3672L142.915 74.0514" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip34_14983_58999)">
                <path d="M165.547 83.9942L183.206 105.253L178.235 116.569M165.547 83.9942L160.575 95.31M165.547 83.9942L137.943 85.3665L132.972 96.6823M160.575 95.31L178.235 116.569M160.575 95.31L155.603 106.626M160.575 95.31L132.972 96.6823M178.235 116.569L173.263 127.885M155.603 106.626L173.263 127.885M155.603 106.626L150.631 117.941M155.603 106.626L128 107.998M173.263 127.885L168.291 139.201L150.631 117.941M150.631 117.941L123.028 119.314L128 107.998M128 107.998L132.972 96.6823" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip35_14983_58999)">
                <path d="M155.603 106.627L173.263 127.886L168.291 139.202M155.603 106.627L150.632 117.943M155.603 106.627L128 107.999L123.028 119.315M150.632 117.943L168.291 139.202M150.632 117.943L145.66 129.259M150.632 117.943L123.028 119.315M168.291 139.202L163.32 150.518M145.66 129.259L163.32 150.518M145.66 129.259L140.688 140.574M145.66 129.259L118.057 130.631M163.32 150.518L158.348 161.833L140.688 140.574M140.688 140.574L113.085 141.947L118.057 130.631M118.057 130.631L123.028 119.315" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip36_14983_58999)">
                <path d="M145.66 129.258L163.32 150.517L158.348 161.833M145.66 129.258L140.688 140.574M145.66 129.258L118.057 130.63L113.085 141.946M140.688 140.574L158.348 161.833M140.688 140.574L135.717 151.889M140.688 140.574L113.085 141.946M158.348 161.833L153.376 173.149M135.717 151.889L153.376 173.149M135.717 151.889L130.745 163.205M135.717 151.889L108.113 153.262M153.376 173.149L148.405 184.464L130.745 163.205M130.745 163.205L103.142 164.577L108.113 153.262M108.113 153.262L113.085 141.946" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip37_14983_58999)">
                <path d="M135.717 151.891L153.376 173.15L148.405 184.466M135.717 151.891L130.745 163.206M135.717 151.891L108.113 153.263L103.142 164.579M130.745 163.206L148.405 184.466M130.745 163.206L125.773 174.522M130.745 163.206L103.142 164.579M148.405 184.466L143.433 195.781M125.773 174.522L143.433 195.781M125.773 174.522L120.801 185.838M125.773 174.522L98.1699 175.895M143.433 195.781L138.461 207.097L120.801 185.838M120.801 185.838L93.1982 187.21L98.1699 175.895M98.1699 175.895L103.142 164.579" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip38_14983_58999)">
                <path d="M125.773 174.522L143.433 195.781L138.461 207.096M125.773 174.522L120.801 185.837M125.773 174.522L98.1698 175.894L93.198 187.21M120.801 185.837L138.461 207.096M120.801 185.837L115.83 197.153M120.801 185.837L93.198 187.21M138.461 207.096L133.489 218.412M115.83 197.153L133.489 218.412M115.83 197.153L110.858 208.469M115.83 197.153L88.2263 198.525M133.489 218.412L128.518 229.728L110.858 208.469M110.858 208.469L83.2546 209.841L88.2263 198.525M88.2263 198.525L93.198 187.21" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip39_14983_58999)">
                <path d="M115.83 197.152L133.489 218.412L128.518 229.727M115.83 197.152L110.858 208.468M115.83 197.152L88.2265 198.525L83.2547 209.84M110.858 208.468L128.518 229.727M110.858 208.468L105.886 219.784M110.858 208.468L83.2547 209.84M128.518 229.727L123.546 241.043M105.886 219.784L123.546 241.043M105.886 219.784L100.915 231.1M105.886 219.784L78.283 221.156M123.546 241.043L118.574 252.359L100.915 231.1M100.915 231.1L73.3113 232.472L78.283 221.156M78.283 221.156L83.2547 209.84" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip40_14983_58999)">
                <path d="M260.527 -9.27531L278.187 11.9839L273.215 23.2996M260.527 -9.27531L255.555 2.04043M260.527 -9.27531L232.924 -7.903L227.952 3.41274M255.555 2.04043L273.215 23.2996M255.555 2.04043L250.584 13.3562M255.555 2.04043L227.952 3.41274M273.215 23.2996L268.243 34.6154M250.584 13.3562L268.243 34.6154M250.584 13.3562L245.612 24.6719M250.584 13.3562L222.98 14.7285M268.243 34.6154L263.272 45.9311L245.612 24.6719M245.612 24.6719L218.009 26.0442L222.98 14.7285M222.98 14.7285L227.952 3.41274" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip41_14983_58999)">
                <path d="M250.584 13.3555L268.244 34.6147L263.272 45.9305M250.584 13.3555L245.612 24.6713M250.584 13.3555L222.981 14.7279L218.009 26.0436M245.612 24.6713L263.272 45.9305M245.612 24.6713L240.64 35.987M245.612 24.6713L218.009 26.0436M263.272 45.9305L258.3 57.2462M240.64 35.987L258.3 57.2462M240.64 35.987L235.669 47.3028M240.64 35.987L213.037 37.3593M258.3 57.2462L253.328 68.562L235.669 47.3028M235.669 47.3028L208.065 48.6751L213.037 37.3593M213.037 37.3593L218.009 26.0436" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip42_14983_58999)">
                <path d="M240.64 35.9864L258.3 57.2456L253.328 68.5613M240.64 35.9864L235.668 47.3022M240.64 35.9864L213.037 37.3587L208.065 48.6745M235.668 47.3022L253.328 68.5613M235.668 47.3022L230.697 58.6179M235.668 47.3022L208.065 48.6745M253.328 68.5613L248.356 79.8771M230.697 58.6179L248.356 79.8771M230.697 58.6179L225.725 69.9336M230.697 58.6179L203.093 59.9902M248.356 79.8771L243.385 91.1928L225.725 69.9336M225.725 69.9336L198.122 71.306L203.093 59.9902M203.093 59.9902L208.065 48.6745" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip43_14983_58999)">
                <path d="M230.697 58.6192L248.357 79.8784L243.385 91.1942M230.697 58.6192L225.725 69.935M230.697 58.6192L203.094 59.9915L198.122 71.3073M225.725 69.935L243.385 91.1942M225.725 69.935L220.753 81.2507M225.725 69.935L198.122 71.3073M243.385 91.1942L238.413 102.51M220.753 81.2507L238.413 102.51M220.753 81.2507L215.782 92.5665M220.753 81.2507L193.15 82.623M238.413 102.51L233.441 113.826L215.782 92.5665M215.782 92.5665L188.178 93.9388L193.15 82.623M193.15 82.623L198.122 71.3073" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip44_14983_58999)">
                <path d="M220.754 81.2501L238.413 102.509L233.442 113.825M220.754 81.2501L215.782 92.5658M220.754 81.2501L193.15 82.6224L188.179 93.9381M215.782 92.5658L233.442 113.825M215.782 92.5658L210.81 103.882M215.782 92.5658L188.179 93.9381M233.442 113.825L228.47 125.141M210.81 103.882L228.47 125.141M210.81 103.882L205.838 115.197M210.81 103.882L183.207 105.254M228.47 125.141L223.498 136.457L205.838 115.197M205.838 115.197L178.235 116.57L183.207 105.254M183.207 105.254L188.179 93.9381" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip45_14983_58999)">
                <path d="M210.81 103.881L228.47 125.14L223.498 136.456M210.81 103.881L205.838 115.197M210.81 103.881L183.207 105.253L178.235 116.569M205.838 115.197L223.498 136.456M205.838 115.197L200.866 126.512M205.838 115.197L178.235 116.569M223.498 136.456L218.526 147.772M200.866 126.512L218.526 147.772M200.866 126.512L195.895 137.828M200.866 126.512L173.263 127.885M218.526 147.772L213.554 159.087L195.895 137.828M195.895 137.828L168.291 139.2L173.263 127.885M173.263 127.885L178.235 116.569" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip46_14983_58999)">
                <path d="M200.866 126.514L218.526 147.773L213.554 159.089M200.866 126.514L195.895 137.829M200.866 126.514L173.263 127.886L168.291 139.202M195.895 137.829L213.554 159.089M195.895 137.829L190.923 149.145M195.895 137.829L168.291 139.202M213.554 159.089L208.583 170.404M190.923 149.145L208.583 170.404M190.923 149.145L185.951 160.461M190.923 149.145L163.32 150.518M208.583 170.404L203.611 181.72L185.951 160.461M185.951 160.461L158.348 161.833L163.32 150.518M163.32 150.518L168.291 139.202" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip47_14983_58999)">
                <path d="M190.923 149.145L208.583 170.404L203.611 181.72M190.923 149.145L185.951 160.46M190.923 149.145L163.32 150.517L158.348 161.833M185.951 160.46L203.611 181.72M185.951 160.46L180.98 171.776M185.951 160.46L158.348 161.833M203.611 181.72L198.639 193.035M180.98 171.776L198.639 193.035M180.98 171.776L176.008 183.092M180.98 171.776L153.376 173.148M198.639 193.035L193.668 204.351L176.008 183.092M176.008 183.092L148.405 184.464L153.376 173.148M153.376 173.148L158.348 161.833" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip48_14983_58999)">
                <path d="M180.98 171.777L198.639 193.037L193.668 204.352M180.98 171.777L176.008 183.093M180.98 171.777L153.376 173.15L148.405 184.465M176.008 183.093L193.668 204.352M176.008 183.093L171.036 194.409M176.008 183.093L148.405 184.465M193.668 204.352L188.696 215.668M171.036 194.409L188.696 215.668M171.036 194.409L166.065 205.725M171.036 194.409L143.433 195.781M188.696 215.668L183.724 226.984L166.065 205.725M166.065 205.725L138.461 207.097L143.433 195.781M143.433 195.781L148.405 184.465" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip49_14983_58999)">
                <path d="M171.036 194.408L188.696 215.667L183.724 226.983M171.036 194.408L166.064 205.724M171.036 194.408L143.433 195.781L138.461 207.096M166.064 205.724L183.724 226.983M166.064 205.724L161.093 217.04M166.064 205.724L138.461 207.096M183.724 226.983L178.752 238.299M161.093 217.04L178.752 238.299M161.093 217.04L156.121 228.356M161.093 217.04L133.49 218.412M178.752 238.299L173.781 249.615L156.121 228.356M156.121 228.356L128.518 229.728L133.49 218.412M133.49 218.412L138.461 207.096" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip50_14983_58999)">
                <path d="M285.903 55.8731L303.563 77.1323L298.591 88.4481M285.903 55.8731L280.932 67.1889M285.903 55.8731L258.3 57.2454L253.328 68.5612M280.932 67.1889L298.591 88.4481M280.932 67.1889L275.96 78.5046M280.932 67.1889L253.328 68.5612M298.591 88.4481L293.62 99.7638M275.96 78.5046L293.62 99.7638M275.96 78.5046L270.988 89.8204M275.96 78.5046L248.357 79.8769M293.62 99.7638L288.648 111.08L270.988 89.8204M270.988 89.8204L243.385 91.1927L248.357 79.8769M248.357 79.8769L253.328 68.5612" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip51_14983_58999)">
                <path d="M275.96 78.5059L293.619 99.7651L288.648 111.081M275.96 78.5059L270.988 89.8217M275.96 78.5059L248.356 79.8782L243.385 91.194M270.988 89.8217L288.648 111.081M270.988 89.8217L266.016 101.137M270.988 89.8217L243.385 91.194M288.648 111.081L283.676 122.397M266.016 101.137L283.676 122.397M266.016 101.137L261.044 112.453M266.016 101.137L238.413 102.51M283.676 122.397L278.704 133.712L261.044 112.453M261.044 112.453L233.441 113.825L238.413 102.51M238.413 102.51L243.385 91.194" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip52_14983_58999)">
                <path d="M266.016 101.137L283.676 122.396L278.704 133.712M266.016 101.137L261.045 112.453M266.016 101.137L238.413 102.509L233.441 113.825M261.045 112.453L278.704 133.712M261.045 112.453L256.073 123.768M261.045 112.453L233.441 113.825M278.704 133.712L273.733 145.027M256.073 123.768L273.733 145.027M256.073 123.768L251.101 135.084M256.073 123.768L228.47 125.141M273.733 145.027L268.761 156.343L251.101 135.084M251.101 135.084L223.498 136.456L228.47 125.141M228.47 125.141L233.441 113.825" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip53_14983_58999)">
                <path d="M256.073 123.768L273.733 145.027L268.761 156.343M256.073 123.768L251.101 135.083M256.073 123.768L228.47 125.14L223.498 136.456M251.101 135.083L268.761 156.343M251.101 135.083L246.13 146.399M251.101 135.083L223.498 136.456M268.761 156.343L263.789 167.658M246.13 146.399L263.789 167.658M246.13 146.399L241.158 157.715M246.13 146.399L218.526 147.771M263.789 167.658L258.818 178.974L241.158 157.715M241.158 157.715L213.555 159.087L218.526 147.771M218.526 147.771L223.498 136.456" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip54_14983_58999)">
                <path d="M246.13 146.4L263.789 167.66L258.818 178.975M246.13 146.4L241.158 157.716M246.13 146.4L218.526 147.773L213.555 159.089M241.158 157.716L258.818 178.975M241.158 157.716L236.186 169.032M241.158 157.716L213.555 159.089M258.818 178.975L253.846 190.291M236.186 169.032L253.846 190.291M236.186 169.032L231.214 180.348M236.186 169.032L208.583 170.404M253.846 190.291L248.874 201.607L231.214 180.348M231.214 180.348L203.611 181.72L208.583 170.404M208.583 170.404L213.555 159.089" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip55_14983_58999)">
                <path d="M236.186 169.031L253.846 190.291L248.874 201.606M236.186 169.031L231.214 180.347M236.186 169.031L208.583 170.404L203.611 181.719M231.214 180.347L248.874 201.606M231.214 180.347L226.242 191.663M231.214 180.347L203.611 181.719M248.874 201.606L243.902 212.922M226.242 191.663L243.902 212.922M226.242 191.663L221.271 202.979M226.242 191.663L198.639 193.035M243.902 212.922L238.931 224.238L221.271 202.979M221.271 202.979L193.668 204.351L198.639 193.035M198.639 193.035L203.611 181.719" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip56_14983_58999)">
                <path d="M226.243 191.664L243.902 212.923L238.931 224.239M226.243 191.664L221.271 202.98M226.243 191.664L198.639 193.036L193.668 204.352M221.271 202.98L238.931 224.239M221.271 202.98L216.299 214.296M221.271 202.98L193.668 204.352M238.931 224.239L233.959 235.555M216.299 214.296L233.959 235.555M216.299 214.296L211.327 225.611M216.299 214.296L188.696 215.668M233.959 235.555L228.987 246.871L211.327 225.611M211.327 225.611L183.724 226.984L188.696 215.668M188.696 215.668L193.668 204.352" stroke="white" stroke-width="1.23598"/>
            </g>
            <g clip-path="url(#clip57_14983_58999)">
                <path d="M216.299 214.293L233.959 235.552L228.987 246.868M216.299 214.293L211.328 225.609M216.299 214.293L188.696 215.665L183.724 226.981M211.328 225.609L228.987 246.868M211.328 225.609L206.356 236.925M211.328 225.609L183.724 226.981M228.987 246.868L224.016 258.184M206.356 236.925L224.016 258.184M206.356 236.925L201.384 248.24M206.356 236.925L178.753 238.297M224.016 258.184L219.044 269.499L201.384 248.24M201.384 248.24L173.781 249.613L178.753 238.297M178.753 238.297L183.724 226.981" stroke="white" stroke-width="1.23598"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(31.9852 -42.1855) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip1_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(22.0417 -19.5547) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip2_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(12.0983 3.07617) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip3_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(2.15472 25.709) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip4_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-7.78864 48.3398) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip5_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-17.732 70.9707) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip6_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-27.6754 93.6035) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip7_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-37.619 116.234) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip8_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(77.2482 -22.2988) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip9_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(67.3049 0.332031) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip10_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(57.3615 22.9629) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip11_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(47.4178 45.5957) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip12_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(37.4745 68.2266) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip13_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(27.5312 90.8574) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip14_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(17.5876 113.49) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip15_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(7.64417 136.121) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip16_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-2.29932 158.752) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip17_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(-12.2427 181.385) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip18_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(132.455 -25.0449) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip19_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(122.511 -2.41211) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip20_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(112.568 20.2188) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip21_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(102.624 42.8496) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip22_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(92.6808 65.4824) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip23_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(82.7374 88.1133) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip24_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(72.7941 110.744) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip25_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(62.8505 133.377) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip26_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(52.907 156.008) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip27_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(42.9636 178.639) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip28_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(33.0202 201.271) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip29_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(187.661 -27.7891) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip30_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(177.718 -5.1582) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip31_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(167.774 17.4746) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip32_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(157.831 40.1055) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip33_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(147.887 62.7363) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip34_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(137.944 85.3691) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip35_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(128 108) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip36_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(118.057 130.631) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip37_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(108.113 153.264) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip38_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(98.1699 175.895) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip39_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(88.2265 198.525) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip40_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(232.924 -7.90234) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip41_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(222.981 14.7285) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip42_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(213.037 37.3613) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip43_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(203.094 59.9922) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip44_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(193.15 82.623) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip45_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(183.207 105.256) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip46_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(173.263 127.887) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip47_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(163.32 150.518) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip48_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(153.377 173.15) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip49_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(143.433 195.781) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip50_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(258.3 57.248) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip51_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(248.357 79.8789) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip52_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(238.413 102.51) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip53_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(228.47 125.143) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip54_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(218.526 147.773) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip55_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(208.583 170.404) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip56_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(198.64 193.037) rotate(23.7189)"/>
            </clipPath>
            <clipPath id="clip57_14983_58999">
                <rect width="49.4391" height="24.7196" fill="white" transform="translate(188.696 215.668) rotate(23.7189)"/>
            </clipPath>
        </defs>
    </svg>
`;

const uploadFileBackgroundSvgStr = `
    <svg width="256" height="216" viewBox="0 0 256 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.15">
            <rect x="123.123" y="3.48438" width="1.68125" height="11.4325" transform="rotate(45 123.123 3.48438)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 96.04 7.2793)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 80.5863 3.48438)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 67.0869 2.4043)" fill="white"/>
            <rect x="45.1184" y="12.7441" width="1.68125" height="11.4325" transform="rotate(-135 45.1184 12.7441)" fill="white"/>
            <rect x="25.0361" y="8.9707" width="1.68125" height="11.4325" transform="rotate(-90 25.0361 8.9707)" fill="white"/>
            <rect x="8.24933" y="4.67188" width="1.68125" height="11.4325" transform="rotate(-45 8.24933 4.67188)" fill="white"/>
            <rect x="87.4172" y="39.834" width="1.68125" height="11.4325" transform="rotate(45 87.4172 39.834)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 71.834 45.3125)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 51.7786 49.1055)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 31.3821 38.7559)" fill="white"/>
            <rect x="9.41235" y="49.0977" width="1.68125" height="11.4325" transform="rotate(-135 9.41235 49.0977)" fill="white"/>
            <rect x="-10.6697" y="45.3223" width="1.68125" height="11.4325" transform="rotate(-90 -10.6697 45.3223)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 122.9 49.0684)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 98.2188 57.1582)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 125.397 62.6348)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 102.507 38.7148)" fill="white"/>
            <rect x="105.271" y="21.6602" width="1.68125" height="11.4325" transform="rotate(45 105.271 21.6602)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 89.6877 27.1367)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(1 0 0 -1 118.768 32.0137)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 62.7369 21.6602)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 49.2369 20.5801)" fill="white"/>
            <rect x="27.2665" y="30.9219" width="1.68125" height="11.4325" transform="rotate(-135 27.2665 30.9219)" fill="white"/>
            <rect x="7.18451" y="27.1465" width="1.68125" height="11.4325" transform="rotate(-90 7.18451 27.1465)" fill="white"/>
            <rect x="69.7307" y="58.0117" width="1.68125" height="11.4325" transform="rotate(45 69.7307 58.0117)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 54.1467 63.4883)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 84.9069 56.9316)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 34.0911 67.2852)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 13.6962 56.9316)" fill="white"/>
            <rect x="123.123" y="76.2109" width="1.68125" height="11.4325" transform="rotate(45 123.123 76.2109)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 96.04 80.0078)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 80.5863 76.2109)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 67.0869 75.1309)" fill="white"/>
            <rect x="45.1184" y="85.4707" width="1.68125" height="11.4325" transform="rotate(-135 45.1184 85.4707)" fill="white"/>
            <rect x="25.0361" y="81.6973" width="1.68125" height="11.4325" transform="rotate(-90 25.0361 81.6973)" fill="white"/>
            <rect x="8.24933" y="77.3984" width="1.68125" height="11.4325" transform="rotate(-45 8.24933 77.3984)" fill="white"/>
            <rect x="87.4172" y="112.561" width="1.68125" height="11.4325" transform="rotate(45 87.4172 112.561)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 71.834 118.039)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 51.7786 121.834)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 31.3821 111.482)" fill="white"/>
            <rect x="9.41235" y="121.822" width="1.68125" height="11.4325" transform="rotate(-135 9.41235 121.822)" fill="white"/>
            <rect x="-10.6697" y="118.047" width="1.68125" height="11.4325" transform="rotate(-90 -10.6697 118.047)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 122.9 121.793)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 98.2188 129.883)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 125.397 135.359)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 102.507 111.439)" fill="white"/>
            <rect x="105.271" y="94.3848" width="1.68125" height="11.4325" transform="rotate(45 105.271 94.3848)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 89.6877 99.8613)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(1 0 0 -1 118.768 104.738)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 62.7369 94.3848)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 49.2369 93.3047)" fill="white"/>
            <rect x="27.2665" y="103.646" width="1.68125" height="11.4325" transform="rotate(-135 27.2665 103.646)" fill="white"/>
            <rect x="7.18451" y="99.8711" width="1.68125" height="11.4325" transform="rotate(-90 7.18451 99.8711)" fill="white"/>
            <rect x="69.7307" y="130.738" width="1.68125" height="11.4325" transform="rotate(45 69.7307 130.738)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 54.1467 136.215)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 84.9069 129.658)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 34.0911 140.012)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 13.6962 129.658)" fill="white"/>
            <rect x="123.123" y="148.938" width="1.68125" height="11.4325" transform="rotate(45 123.123 148.938)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 96.04 152.734)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 80.5863 148.938)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 67.0869 147.857)" fill="white"/>
            <rect x="45.1184" y="158.197" width="1.68125" height="11.4325" transform="rotate(-135 45.1184 158.197)" fill="white"/>
            <rect x="25.0361" y="154.424" width="1.68125" height="11.4325" transform="rotate(-90 25.0361 154.424)" fill="white"/>
            <rect x="8.24933" y="150.125" width="1.68125" height="11.4325" transform="rotate(-45 8.24933 150.125)" fill="white"/>
            <rect x="87.4172" y="185.289" width="1.68125" height="11.4325" transform="rotate(45 87.4172 185.289)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 71.834 190.768)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 51.7786 194.562)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 31.3821 184.211)" fill="white"/>
            <rect x="9.41235" y="194.551" width="1.68125" height="11.4325" transform="rotate(-135 9.41235 194.551)" fill="white"/>
            <rect x="-10.6697" y="190.777" width="1.68125" height="11.4325" transform="rotate(-90 -10.6697 190.777)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 122.9 194.521)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 98.2188 202.611)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 125.397 208.088)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 102.507 184.17)" fill="white"/>
            <rect x="105.271" y="167.115" width="1.68125" height="11.4325" transform="rotate(45 105.271 167.115)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 89.6877 172.592)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(1 0 0 -1 118.767 177.469)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 62.7369 167.115)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 49.2369 166.035)" fill="white"/>
            <rect x="27.2665" y="176.375" width="1.68125" height="11.4325" transform="rotate(-135 27.2665 176.375)" fill="white"/>
            <rect x="7.18451" y="172.6" width="1.68125" height="11.4325" transform="rotate(-90 7.18451 172.6)" fill="white"/>
            <rect x="69.7307" y="203.467" width="1.68125" height="11.4325" transform="rotate(45 69.7307 203.467)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 54.1467 208.943)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 84.9069 202.387)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 34.0911 212.74)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 13.6962 202.387)" fill="white"/>
            <rect x="263.974" y="3.48438" width="1.68125" height="11.4325" transform="rotate(45 263.974 3.48438)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 236.891 7.2793)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 221.437 3.48438)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 207.938 2.4043)" fill="white"/>
            <rect x="135.112" y="2.36133" width="1.68125" height="11.4325" fill="white"/>
            <rect x="185.969" y="12.7441" width="1.68125" height="11.4325" transform="rotate(-135 185.969 12.7441)" fill="white"/>
            <rect x="165.887" y="8.9707" width="1.68125" height="11.4325" transform="rotate(-90 165.887 8.9707)" fill="white"/>
            <rect x="149.1" y="4.67188" width="1.68125" height="11.4325" transform="rotate(-45 149.1 4.67188)" fill="white"/>
            <rect x="228.268" y="39.834" width="1.68125" height="11.4325" transform="rotate(45 228.268 39.834)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 212.685 45.3125)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 192.629 49.1055)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 172.233 38.7559)" fill="white"/>
            <rect x="150.263" y="49.0977" width="1.68125" height="11.4325" transform="rotate(-135 150.263 49.0977)" fill="white"/>
            <rect x="130.181" y="45.3223" width="1.68125" height="11.4325" transform="rotate(-90 130.181 45.3223)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 263.751 49.0684)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 239.07 57.1582)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 266.248 62.6348)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 243.357 38.7148)" fill="white"/>
            <rect x="246.122" y="21.6602" width="1.68125" height="11.4325" transform="rotate(45 246.122 21.6602)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 230.539 27.1367)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 203.588 21.6602)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 190.088 20.5801)" fill="white"/>
            <rect x="168.118" y="30.9219" width="1.68125" height="11.4325" transform="rotate(-135 168.118 30.9219)" fill="white"/>
            <rect x="148.035" y="27.1465" width="1.68125" height="11.4325" transform="rotate(-90 148.035 27.1465)" fill="white"/>
            <rect x="131.249" y="22.8496" width="1.68125" height="11.4325" transform="rotate(-45 131.249 22.8496)" fill="white"/>
            <rect x="131.249" y="22.8496" width="1.68125" height="11.4325" transform="rotate(-45 131.249 22.8496)" fill="white"/>
            <rect x="210.581" y="58.0117" width="1.68125" height="11.4325" transform="rotate(45 210.581 58.0117)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 194.997 63.4883)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 225.758 56.9316)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 174.942 67.2852)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 154.547 56.9316)" fill="white"/>
            <rect x="132.575" y="67.2734" width="1.68125" height="11.4325" transform="rotate(-135 132.575 67.2734)" fill="white"/>
            <rect x="263.974" y="76.2109" width="1.68125" height="11.4325" transform="rotate(45 263.974 76.2109)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 236.891 80.0078)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 221.437 76.2109)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 207.938 75.1309)" fill="white"/>
            <rect x="135.112" y="75.0879" width="1.68125" height="11.4325" fill="white"/>
            <rect x="185.969" y="85.4707" width="1.68125" height="11.4325" transform="rotate(-135 185.969 85.4707)" fill="white"/>
            <rect x="165.887" y="81.6973" width="1.68125" height="11.4325" transform="rotate(-90 165.887 81.6973)" fill="white"/>
            <rect x="149.1" y="77.3984" width="1.68125" height="11.4325" transform="rotate(-45 149.1 77.3984)" fill="white"/>
            <rect x="228.268" y="112.561" width="1.68125" height="11.4325" transform="rotate(45 228.268 112.561)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 212.685 118.039)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 192.629 121.834)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 172.233 111.482)" fill="white"/>
            <rect x="150.263" y="121.822" width="1.68125" height="11.4325" transform="rotate(-135 150.263 121.822)" fill="white"/>
            <rect x="130.181" y="118.047" width="1.68125" height="11.4325" transform="rotate(-90 130.181 118.047)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 263.751 121.793)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 239.07 129.883)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 266.248 135.359)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 243.357 111.439)" fill="white"/>
            <rect x="246.122" y="94.3848" width="1.68125" height="11.4325" transform="rotate(45 246.122 94.3848)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 230.539 99.8613)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 203.588 94.3848)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 190.088 93.3047)" fill="white"/>
            <rect x="168.118" y="103.646" width="1.68125" height="11.4325" transform="rotate(-135 168.118 103.646)" fill="white"/>
            <rect x="148.035" y="99.8711" width="1.68125" height="11.4325" transform="rotate(-90 148.035 99.8711)" fill="white"/>
            <rect x="131.249" y="95.5742" width="1.68125" height="11.4325" transform="rotate(-45 131.249 95.5742)" fill="white"/>
            <rect x="131.249" y="95.5742" width="1.68125" height="11.4325" transform="rotate(-45 131.249 95.5742)" fill="white"/>
            <rect x="210.581" y="130.738" width="1.68125" height="11.4325" transform="rotate(45 210.581 130.738)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 194.997 136.215)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 225.758 129.658)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 174.942 140.012)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 154.547 129.658)" fill="white"/>
            <rect x="132.575" y="139.998" width="1.68125" height="11.4325" transform="rotate(-135 132.575 139.998)" fill="white"/>
            <rect x="263.974" y="148.938" width="1.68125" height="11.4325" transform="rotate(45 263.974 148.938)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 1 1 0 236.891 152.734)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 221.437 148.938)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 207.938 147.857)" fill="white"/>
            <rect x="135.112" y="147.814" width="1.68125" height="11.4325" fill="white"/>
            <rect x="185.969" y="158.197" width="1.68125" height="11.4325" transform="rotate(-135 185.969 158.197)" fill="white"/>
            <rect x="165.887" y="154.424" width="1.68125" height="11.4325" transform="rotate(-90 165.887 154.424)" fill="white"/>
            <rect x="149.1" y="150.125" width="1.68125" height="11.4325" transform="rotate(-45 149.1 150.125)" fill="white"/>
            <rect x="228.268" y="185.289" width="1.68125" height="11.4325" transform="rotate(45 228.268 185.289)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 212.685 190.768)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 192.629 194.562)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 172.233 184.211)" fill="white"/>
            <rect x="150.263" y="194.551" width="1.68125" height="11.4325" transform="rotate(-135 150.263 194.551)" fill="white"/>
            <rect x="130.181" y="190.777" width="1.68125" height="11.4325" transform="rotate(-90 130.181 190.777)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 263.751 194.521)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 239.07 202.611)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 266.248 208.088)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 243.357 184.17)" fill="white"/>
            <rect x="246.122" y="167.115" width="1.68125" height="11.4325" transform="rotate(45 246.122 167.115)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 230.539 172.592)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-0.707107 0.707107 0.707107 0.707107 203.588 167.115)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 190.088 166.035)" fill="white"/>
            <rect x="168.118" y="176.375" width="1.68125" height="11.4325" transform="rotate(-135 168.118 176.375)" fill="white"/>
            <rect x="148.035" y="172.6" width="1.68125" height="11.4325" transform="rotate(-90 148.035 172.6)" fill="white"/>
            <rect x="131.249" y="168.303" width="1.68125" height="11.4325" transform="rotate(-45 131.249 168.303)" fill="white"/>
            <rect x="131.249" y="168.303" width="1.68125" height="11.4325" transform="rotate(-45 131.249 168.303)" fill="white"/>
            <rect x="210.581" y="203.467" width="1.68125" height="11.4325" transform="rotate(45 210.581 203.467)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0 -1 -1 0 194.997 208.943)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 225.758 202.387)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 174.942 212.74)" fill="white"/>
            <rect width="1.68125" height="11.4325" transform="matrix(-1 0 0 1 154.547 202.387)" fill="white"/>
            <rect x="132.575" y="212.727" width="1.68125" height="11.4325" transform="rotate(-135 132.575 212.727)" fill="white"/>
        </g>
    </svg>
`;

export const quickAnswerBackgroundSvgDataUrl = `data:image/svg+xml;base64,${btoa(quickAnswerBackgroundSvgStr)}`;
export const uploadFileBackgroundSvgDataUrl = `data:image/svg+xml;base64,${btoa(uploadFileBackgroundSvgStr)}`;
