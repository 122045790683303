import { activeFrameworkBackgroundSvgDataUrl } from "./framework-card-backgrounds";

export function useNewFrameworkCard() {
    const newHeaderStyle = {
        backgroundImage: `url(${activeFrameworkBackgroundSvgDataUrl}), linear-gradient(to right, rgba(3, 163, 101, 1), rgba(3, 163, 101, 0.8))`,
        backgroundSize: "auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
    };

    return {
        newHeaderStyle,
    };
}
