import { useSupplier } from "@app/screens/Supplier/Suppliers/supplier/data";
import {
    getPaginationRowModel,
    Table,
    usePagination,
} from "@design-system/Table";
import { type FC } from "react";
import { useColumns } from "./columns";

export const UserTable: FC<{ supplierId?: string }> = ({ supplierId }) => {
    const { supplier, isPending } = useSupplier(supplierId);

    const { pagination } = usePagination({ manualPagination: false });

    const columns = useColumns();

    return (
        <Table
            columns={columns}
            data={supplier?.users ?? []}
            loading={isPending}
            pagination={pagination}
            options={{
                getPaginationRowModel: getPaginationRowModel(),
            }}
            rowCount={supplier?.users?.length ?? 0}
        />
    );
};
