import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "question-section";

const keys = addTranslations(RESOURCE_NAME, {
    question: {
        en: "Question",
        fr: "Question",
    },
});

export function useQuestionSectionTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
