import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "NewUserModal", {
    title: "Add new users",
    subtitle: "New users will have access to your org",
    create_zero: "Add",
    create_one: "Add user",
    create_other: "Add {{count}} new users",
    email: "Email",
    email_placeholder: "john.doe@org.com",
    firstname: "First name",
    firstname_placeholder: "John",
    lastname: "Last name",
    lastname_placeholder: "Doe",
    department: "Department",
    valid_message: "The following users will be created and invited to join :",
    add_user: "Add new",

    choose_language:
        "Choose the language in which invitation will be sent and account configured",
    tooltip_help_no_user: "Add at least one user",
    tooltip_help_no_locale: "Select a language",
    tooltip_help_wrong_user: "Enter valid users",
    executive: "Executive & Leadership",
    external: "External - Audit & Consulting",
    finance: "Finance, Legal & Compliance",
    hr: "Human Resources",
    it: "IT & Cybersecurity",
    operations: "Operations & Client Success",
    other: "Other",
    procurement: "Procurement, Supply Chain & Logistics",
    product: "Product, R&D & Innovation",
    quality: "Quality, Safety & Risk Management",
    sales: "Sales, Marketing & Communication",
    sustainability: "Sustainability, CSR & Environmental",
});

i18next.addResourceBundle("fr", "NewUserModal", {
    title: "Ajouter de nouveaux utilisateurs",
    subtitle: "Les nouveaux utilisateurs auront accès à votre organisation",
    create: "Ajouter",
    create_one: "Ajouter un utilisateur",
    create_other: "Ajouter {{count}} nouveaux utilisateurs",
    email: "Email",
    email_placeholder: "alice.bob@org.com",
    firstname: "Prénom",
    firstname_placeholder: "Alice",
    lastname: "Nom",
    lastname_placeholder: "Bob",
    department: "Département",
    valid_message:
        "Les utilisateurs suivants seront créés et invités à rejoindre votre organisation :",
    add_user: "Ajouter un utilisateur",
    choose_language:
        "Choisissez la langue dans laquelle l'invitation sera envoyée et le compte configuré",
    tooltip_help_no_user: "Ajoutez au moins un utilisateur",
    tooltip_help_no_locale: "Choisissez la langue",
    tooltip_help_wrong_user: "Ajoutez des utilisateurs valides",
    executive: "Direction Générale & Leadership",
    external: "Externe – Audit & Conseil",
    finance: "Finance, Juridique & Conformité",
    hr: "Ressources Humaines",
    it: "Informatique & Cybersécurité",
    operations: "Opérations & Gestion Client",
    other: "Autre",
    procurement: "Achats, Supply Chain & Logistique",
    product: "Produit, R&D & Innovation",
    quality: "Qualité, Sécurité & Gestion des Risques",
    sales: "Ventes, Marketing & Communication",
    sustainability: "Développement Durable, RSE & Environnement",
});
