import { useUser } from "@app/store/userStore";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { FC } from "react";

type UserTagProps = {
    userId: string | undefined;
    noUserText?: string;
    className?: string;
    displayFullName?: boolean;
};

export const UserTag: FC<UserTagProps> = ({
    userId,
    noUserText,
    className,
    displayFullName = true,
}) => {
    const user = useUser(userId);
    const nameToDisplay = `${user?.firstName} ${displayFullName ? user?.lastName : user?.lastName?.slice(0, 1)}${displayFullName ? "" : "."}`;

    return (
        <FlexRow
            alignItems="center"
            justifyContent="center"
            br="xl"
            p="0.5"
            className={cn(
                "border border-secondary max-h-[26px] shadow-b-100",
                className,
            )}
        >
            <FlexRow
                className="h-5 w-5"
                justifyContent="center"
                alignItems="center"
            >
                <Avatar
                    size="sm"
                    variant="circle"
                    hexColor={!user ? "#FFFFFF" : undefined}
                    letter={user?.firstName?.[0]}
                    className="h-4 w-4"
                />
            </FlexRow>

            {user && (
                <Ellipsis asChild hasTooltip>
                    <Text
                        variant="body-sm"
                        color="primary"
                        px="1"
                        className="capitalize"
                    >
                        {nameToDisplay}
                    </Text>
                </Ellipsis>
            )}
            {!user && noUserText && (
                <Ellipsis asChild hasTooltip>
                    <Text variant="body-sm" color="primary" px="1">
                        {noUserText}
                    </Text>
                </Ellipsis>
            )}
        </FlexRow>
    );
};
