import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import { GraphQLQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/QuestionnaireRepository";

import {
    type AnswerUpsertInput,
    type CreateFileInput,
    GetQuestionsQuery,
    QuestionFilter,
    ValidationStatus
} from "@generated/client/graphql";
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";

import { questionnairesKeys } from "@app/screens/ExternalQuestionnaires/data";
import { tanstackUnitaryOptimisticUpdateOptionsMaker } from "@app/shared/utils/optimisticUpdates";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { questionnaireLocaleAtom } from "../../ctx";
import { makeUpsertAnswerOptimisticUpdateToGetQuestions } from "./service";

export const externalQuestionnairesRepository =
    new GraphQLQuestionnaireRepository();
const DocumentRepository = new GraphQLDocumentRepository();

export function useAnswerUpsert(
    questionnaireId?: string,
    filter?: QuestionFilter,
) {
    const queryClient = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);

    const {
        mutate: upsertAnswer,
        mutateAsync: upsertAnswerAsync,
        ...mutation
    } = useMutation({
        mutationFn: (input: AnswerUpsertInput) =>
            externalQuestionnairesRepository.upsertAnswer(input),
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () =>
                questionnairesKeys.questionsFiltered(
                    questionnaireId,
                    currentLocale,
                    undefined,
                    filter?.groupId ?? undefined,
                    filter?.ownerIds ?? [],
                    filter?.statuses ?? [],
                    filter?.aiAnswerStatuses ?? [],
                ),
            (input: AnswerUpsertInput, previousQuery: GetQuestionsQuery) => {
                return makeUpsertAnswerOptimisticUpdateToGetQuestions(
                    input,
                    previousQuery,
                );
            },
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.headerData(questionnaireId),
            });
        },
    });
    return { upsertAnswer, upsertAnswerAsync, ...mutation };
}

export function useAnswerDelete(questionnaireId?: string) {
    const queryClient = useQueryClient();
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { mutate: deleteAnswer, ...mutation } = useMutation({
        mutationFn: (id: string) =>
            externalQuestionnairesRepository.deleteAnswer(id),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.overViewData(
                    questionnaireId,
                    currentLocale,
                ),
            });
        },
    });
    return { deleteAnswer, ...mutation };
}

export function useQuestionAndAnswerUpdate(
    questionId: string | undefined,
    answerId?: string,
    questionnaireId?: string | undefined | null,
    filter?: QuestionFilter,
) {
    const answerMutation = useAnswerUpsert(questionnaireId ?? "", filter);
    const answerDeleteMutation = useAnswerDelete(questionnaireId ?? "");

    const deleteAnswer = () => {
        if (!answerId) return;
        return answerDeleteMutation.deleteAnswer(answerId);
    };

    const updateTextAnswer = (
        answer: string,
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                value: { text: answer },
                questionId,
            },
            opts,
        );
    };

    const updateSelectAnswer = (
        options: number[],
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                value: { options },
                questionId,
            },
            opts,
        );
    };

    const updateValueAnswer = (
        value: number,
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                value: { num: value },
                questionId,
            },
            opts,
        );
    };

    const updateComment = (
        comment: string,
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                comment,
                questionId,
            },
            opts,
        );
    };

    const validateAnswer = (status: ValidationStatus) => {
        if (!questionId || answerMutation.isPending) return;

        return answerMutation.upsertAnswer({
            id: answerId,
            status,
            questionId,
        });
    };

    const updateEvidenceFilesAsync = ({
        fileIds,
        files,
    }: {
        fileIds?: string[];
        files?: CreateFileInput[];
    }) => {
        if (!questionId) return;
        return answerMutation.upsertAnswerAsync({
            id: answerId,
            questionId,
            connectEvidenceFileIds: fileIds,
            createAndConnectEvidenceFiles: files,
        });
    };

    const removeEvidenceFiles = (fileIds: string[]) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer({
            id: answerId,
            questionId,
            disconnectEvidenceFileIds: fileIds,
        });
    };

    return {
        deleteAnswer,
        validateAnswer,
        updateTextAnswer,
        updateValueAnswer,
        updateComment,
        updateSelectAnswer,
        updateEvidenceFilesAsync,
        removeEvidenceFiles,
        isPending: answerMutation.isPending,
    };
}

export function useGenerateAiSuggestion(
    questionnaireId?: string,
    questionId?: string,
) {
    const { mutate: generateAiSuggestion, ...mutation } = useMutation({
        mutationFn: externalQuestionnairesRepository.generateAiSuggestion,
        mutationKey: questionnairesKeys.generateAiSuggestion(
            questionnaireId,
            questionId,
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: questionnairesKeys.all,
            });
        },
    });
    return { generateAiSuggestion, ...mutation };
}

export function useGetDocuments(documentIds: string[]) {
    const { data, ...query } = useQuery({
        queryKey: ["getDocuments", documentIds],
        queryFn: () => DocumentRepository.getDocumentsByIds(documentIds),
    });

    return {
        ...query,
        documents: data?.documents ?? [],
    };
}

export const useQuestionnaireGroups = (
    questionnaireId?: string,
    currentLocale?: string,
) => {
    const { data, ...query } = useQuery({
        queryKey: questionnairesKeys.questionnaireGroups(questionnaireId),
        queryFn: questionnaireId
            ? () =>
                  externalQuestionnairesRepository.getQuestionnaireGroups(
                      questionnaireId,
                      currentLocale ?? "en",
                  )
            : skipToken,
    });

    const groups = useMemo(() => data?.questionnaire.groups ?? [], [data]);

    return {
        ...query,
        groups,
    };
};
