import { useS3FilesManagement } from "@app/hooks/createFileHook";
import DropZone from "@design-system/Inputs/DropZone";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useImportQuestionsFromFile } from "../data";
import { keys, RESOURCE_NAME } from "../i18n";
import "./index.css";

const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const EmptyQuestionnairePlaceholder: FC<{
    isGeneratingQuestions: boolean;
}> = ({ isGeneratingQuestions }) => {
    const { external_questionnaire_id: questionnaireId } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);
    const { uploadFiletoS3 } = useS3FilesManagement();
    const [isUploadingToS3, setIsUploadingToS3] = useState(false);

    const { importQuestionsFromFileAsync, isPending } =
        useImportQuestionsFromFile(questionnaireId);

    const handleFileDrop = async (file: File) => {
        if (!questionnaireId) return;
        setIsUploadingToS3(true);
        const uploadedFile = await uploadFiletoS3(file);
        importQuestionsFromFileAsync({
            questionnaireId,
            s3File: uploadedFile,
        });
        setTimeout(function () {
            setIsUploadingToS3(false);
        }, 1000);
    };
    const isLoading = isUploadingToS3 || isPending || isGeneratingQuestions;

    return (
        <Box
            as="section"
            display="grid"
            className={cn(
                "place-items-center",
                "bg-secondary border border-tertiary",
                "h-[500px]",
            )}
            br="sm"
            py="8"
            px="11"
        >
            {isLoading ? (
                <FlexCol alignItems="center" justifyContent="center" gap="2">
                    <Text className="animate-pulse" color="brand">
                        {t(keys.analyzing_file)}
                    </Text>
                    <div className="loader" />
                </FlexCol>
            ) : (
                <FlexCol
                    className="w-full h-[250px]"
                    alignItems="center"
                    gap="4"
                >
                    <DropZone
                        accept={acceptedFileTypes}
                        buttonText={t(keys.browse)}
                        multiple={false}
                        onChange={handleFileDrop}
                        size={"lg"}
                        loading={isLoading}
                        className="w-full h-full"
                    >
                        {t(keys.upload_your_questionnaire)}
                        <br />
                        {t(keys.accepted_file_types)}
                    </DropZone>
                </FlexCol>
            )}
        </Box>
    );
};
