import UserChipSelect from "@app/shared/components/UserChipSelect";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { FlexRow } from "@design-system/Layout/Flex";
import { type ColumnDef } from "@design-system/Table";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { keys, RESOURCE_NAME } from "../../../i18n";

import { useQuestionAssignmentUpdate } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { AnswerStatus } from "@generated/client/graphql";
import { columnFiltersAtom } from "../ctx";
import { extractValueFromFilter, updateFilter } from "./statusCol";

export const ownerCol: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "owner",
    meta: {
        colWidth: "180px",
        cellClassName: "min-w-[180px] max-w-[180px]",
        headerClassName: "min-w-[180px] max-w-[180px]",
    },
    enableSorting: false,
    accessorKey: "ownerId",
    filterFn: (row, columnId, filterValue) => {
        if (columnId !== "owner" || !Array.isArray(filterValue)) {
            return true;
        }
        return (
            filterValue.length === 0 ||
            filterValue.includes(row.original.ownerId)
        );
    },
    header: () => {
        const [filterValue, setFilterValue] = useAtom(columnFiltersAtom);
        const selectedUserIds: string[] = useMemo(() => {
            return extractValueFromFilter(filterValue, "owner");
        }, [filterValue]);
        const handleValueChange = (value: (string | null)[]) => {
            setFilterValue(updateFilter("owner", value));
        };
        const [searchFilter, setSearchFilter] = useState<string>();
        return (
            <UserMultiSelect
                isHeader
                values={selectedUserIds}
                onValuesChange={handleValueChange}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
                includeNone={true}
            />
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const { id } = row.original;
        const [searchFilter, setSearchFilter] = useState<string>();
        const [ownerId, setOwnerId] = useState<string | undefined>(
            row.original.ownerId ?? undefined,
        );

        const { updateQuestion } = useQuestionAssignmentUpdate(
            row.original?.questionnaireId ?? undefined,
        );

        useEffect(() => {
            setOwnerId(row.original.ownerId ?? undefined);
        }, [row.original.ownerId]);

        const onUserIdChange = (userId: string) => {
            setOwnerId(userId);
            updateQuestion({ id, input: { ownerId: userId } });
        };

        const isQuestionValidated =
            row.original.answer?.status == AnswerStatus.Validated;

        return (
            <div>
                <UserChipSelect
                    userId={ownerId}
                    setUserId={onUserIdChange}
                    searchFilter={searchFilter}
                    onSearchFilterChange={setSearchFilter}
                    disabled={isQuestionValidated}
                    trigger={
                        <FlexRow maxW="full" w="fit">
                            <UserTag
                                className={
                                    isQuestionValidated ? "" : "cursor-pointer"
                                }
                                userId={ownerId ?? undefined}
                                noUserText={t(keys.table_owner_assign_no_owner)}
                            />
                        </FlexRow>
                    }
                />
            </div>
        );
    },
};
