import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-questionnaire-question";

const keys = addTranslations(RESOURCE_NAME, {
    unavailable_translation: {
        en: "Unavailable translation",
        fr: "Traduction non disponible",
    },
    ai_suggestion_modal_title: {
        en: "AI Suggestion",
        fr: "Suggestion IA",
    },
    ai_suggestion_modal_content: {
        en: "Get in touch with our team to enable AI suggestions",
        fr: "Contactez notre équipe pour activer les suggestions IA",
    },

    ai_suggestion_modal_confirm: {
        en: "Let's get in touch",
        fr: "Contactez-nous",
    },

    ai_suggestion_modal_cancel: {
        en: "Continue without AI suggestions",
        fr: "Continuer sans suggestions IA",
    },

    comment_placeholder: {
        en: "Comment",
        fr: "Commentaire",
    },

    text_placeholder: {
        en: "Type your answer here",
        fr: "Répondez ici",
    },

    value_placeholder: {
        en: "Type your answer here",
        fr: "Répondez ici",
    },

    value_unit_placeholder: {
        en: "Type your answer here ({{unit}})",
        fr: "Répondez ici ({{unit}})",
    },

    successfully_updated: {
        en: "Successfully updated",
        fr: "Modifications enregistrées",
    },

    update_error: {
        en: "Failed to update",
        fr: "Erreur lors de la modification",
    },

    option_non_available_in_locale: {
        en: "Option non available in current locale",
        fr: "Option non disponible dans la langue courante",
    },
});

export function useSupplierQuestionnaireQuestionTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}