import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { cn, useIsOverflown, type ClassValue } from "@design-system/Utilities";
import { Slot } from "@radix-ui/react-slot";
import {
    useImperativeHandle,
    useRef,
    type FC,
    type PropsWithChildren,
    type Ref,
} from "react";

export type EllipsisProps = PropsWithChildren<{
    /**
     * The text element to render as.
     */
    asChild?: boolean;
    className?: ClassValue;
    lineClamp?: number;
    /**
     * Show or not a tooltip when the text is truncated.
     * The tooltip will show the full text.
     * @default false
     */
    hasTooltip?: boolean;
    ref?: Ref<HTMLParagraphElement | null>;
}>;

export const Ellipsis: FC<EllipsisProps> = ({
    asChild,
    children,
    className,
    hasTooltip = false,
    lineClamp,
    ref,
}) => {
    const Comp = asChild ? Slot : "p";
    const ellipsisRef = useRef<HTMLParagraphElement>(null);
    const isOverflown = useIsOverflown(ellipsisRef);

    const innerText = ellipsisRef.current?.textContent;
    useImperativeHandle(ref, () => ellipsisRef.current!);

    return (
        <Tooltip>
            <Wrapper
                className={className}
                show={hasTooltip && isOverflown}
                txt={innerText}
            >
                <Comp
                    className={cn(
                        "max-w-full",
                        !lineClamp && "truncate",
                        className,
                    )}
                    ref={ellipsisRef}
                    style={
                        lineClamp
                            ? {
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: lineClamp,
                              }
                            : undefined
                    }
                >
                    {children}
                </Comp>
            </Wrapper>
        </Tooltip>
    );
};

const Wrapper: FC<
    PropsWithChildren<{
        className?: ClassValue;
        txt?: string | null;
        show: boolean;
    }>
> = ({ children, className, txt, show }) => {
    return show ? (
        <>
            <Tooltip.Trigger
                asChild
                className={cn("overflow-hidden w-full", className)}
            >
                {children}
            </Tooltip.Trigger>
            <Tooltip.Content className="break-words max-w-[600px]">
                {txt}
            </Tooltip.Content>
        </>
    ) : (
        children
    );
};
