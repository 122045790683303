import { BoxProps } from "@design-system/Layout/Box";

import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { FC } from "react";

export const QuestionModalCard: FC<BoxProps> = ({ className, ...props }) => {
    return (
        <Box
            className={cn("bg-secondary border border-tertiary", className)}
            p="4"
            w="full"
            br="lg"
            {...props}
        />
    );
};
