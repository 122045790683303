import { useSetToast } from "@app/components/Toast";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ResponseStatus } from "@generated/client/graphql";
import chroma from "chroma-js";
import { type FC } from "react";
import { useParams } from "react-router-dom";
import { makeSupplierQuestionnaireLink } from "../../../services";
import { useCloseQuestionnaire, useLastQuestionnaire } from "../../data";
import { QuestionnaireLink } from "../QuestionnaireLink";
import { BoxSkeleton } from "./BoxSkeleton";
import { EmptyState } from "./EmptyState";
import { useLastQuestionnaireBoxTranslations } from "./i18n";

export const LastQuestionnaireBox: FC<{
    supplierId?: string;
    variant?: "supplier" | "admin";
}> = ({ supplierId, variant = "admin" }) => {
    const { org_uname } = useParams();

    const { lastQuestionnaire, isPending } = useLastQuestionnaire(supplierId);

    const { closeQuestionnaire, isPending: isCloseQuestionnairePending } =
        useCloseQuestionnaire();

    const { setToastSuccess, setToastError } = useSetToast();

    const answeredQuestions = lastQuestionnaire?.stats?.answered;

    const totalQuestions = lastQuestionnaire?.stats?.total;

    const sender = lastQuestionnaire?.sentBy;

    const { t, keys } = useLastQuestionnaireBoxTranslations();

    const handleCloseClick = () => {
        if (!lastQuestionnaire?.id) return;
        closeQuestionnaire(lastQuestionnaire?.id, {
            onSettled: (data, error) => {
                if (
                    error ||
                    data?.closeQuestionnaire?.status !== ResponseStatus.Success
                )
                    return setToastError(t(keys.questionnaire_closed_error));
                setToastSuccess(t(keys.questionnaire_closed));
            },
        });
    };

    const link =
        org_uname && supplierId
            ? makeSupplierQuestionnaireLink({
                  orgUname: org_uname,
                  supplierId,
                  questionnaireId: lastQuestionnaire?.id,
              })
            : undefined;

    const handleCopyClick = () => {
        if (!link) {
            setToastError(t(keys.copy_link_error));
            return;
        }
        navigator.clipboard
            .writeText(link)
            .then(() => {
                setToastSuccess(t(keys.copy_link_success));
            })
            .catch(() => {
                setToastError(t(keys.copy_link_error));
            });
    };

    return isPending ? (
        <BoxSkeleton />
    ) : lastQuestionnaire ? (
        <FlexCol br="lg" w="full" className="border border-brand">
            <FlexRow
                px="4"
                h="8"
                alignItems="center"
                className="bg-[#EEFFF2] rounded-t-lg border-b border-secondary"
            >
                <Text variant="body-sm-bold" color="brand">
                    {t(keys.last_questionnaire)}
                </Text>
            </FlexRow>
            <FlexRow px="5" py="4" gap="5" alignItems="center">
                <FlexCol gap="3" w="full" alignItems="center">
                    <FlexRow
                        w="full"
                        alignItems="center"
                        justifyContent="between"
                    >
                        <QuestionnaireLink
                            name={lastQuestionnaire.name}
                            toQuestionnaireLink={
                                variant === "supplier" ? link : undefined
                            }
                        />
                        <QuestionnaireDetail
                            label={t(keys.progress)}
                            value={`${lastQuestionnaire.progress}%`}
                        />
                        <QuestionnaireDetail
                            label={t(keys.sent)}
                            value={lastQuestionnaire.sentDate ?? "-"}
                        />
                        <QuestionnaireDetail
                            label={t(keys.due_date)}
                            value={lastQuestionnaire.dueDate ?? "-"}
                        />
                        <FlexRow gap="1.5" alignItems="center">
                            <Text color="secondary">{t(keys.sent_by)}</Text>
                            <Avatar
                                imageUrl={sender?.avatar?.signedUrl}
                                variant="circle"
                                letter={sender?.firstName[0]}
                            />
                            <Text>{`${sender?.firstName} ${sender?.lastName}`}</Text>
                        </FlexRow>
                    </FlexRow>
                    <FlexRow w="full" className="[&>div]:w-full">
                        <ProgressBar
                            segments={[
                                {
                                    label: t(keys.answered),
                                    labelValue: `${answeredQuestions} / ${totalQuestions}`,
                                    value: answeredQuestions || 0,
                                    color: chroma("rgba(3, 163, 101, 1)"),
                                },
                            ]}
                            total={totalQuestions}
                            className="w-full"
                        />
                    </FlexRow>
                </FlexCol>
                {variant === "admin" && (
                    <FlexRow gap="1.5" alignItems="center">
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Button
                                    size="sm"
                                    variant="outlined"
                                    onClick={handleCopyClick}
                                    className="max-h-[26px]"
                                >
                                    <Button.Icon name="link" size="sm" />
                                </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t(keys.copy_link)}
                            </Tooltip.Content>
                        </Tooltip>
                        <Button
                            onClick={handleCloseClick}
                            loading={isCloseQuestionnairePending}
                            variant="outlined"
                            size="sm"
                        >
                            {t(keys.close)}
                        </Button>
                    </FlexRow>
                )}
            </FlexRow>
        </FlexCol>
    ) : (
        <EmptyState />
    );
};

const QuestionnaireDetail: FC<{ label: string; value?: string }> = ({
    label,
    value,
}) => (
    <FlexRow gap="0.5" alignItems="center">
        <Text variant="body" color="secondary">
            {label}
        </Text>
        <Text variant="body" color="primary">
            {value}
        </Text>
    </FlexRow>
);
