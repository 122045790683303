import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { Link } from "react-router-dom";

export const QuestionnaireLink: FC<{
    name: string;
    toQuestionnaireLink?: string;
}> = ({ name, toQuestionnaireLink }) => {
    return toQuestionnaireLink ? (
        <Link
            className="text-primary hover:text-primary"
            to={toQuestionnaireLink}
        >
            <FlexRow gap="0.5" alignItems="center">
                <Icon name="document" />
                <Ellipsis asChild hasTooltip>
                    <Text>{name}</Text>
                </Ellipsis>
            </FlexRow>
        </Link>
    ) : (
        <FlexRow gap="0.5" alignItems="center">
            <Icon name="document" />
            <Ellipsis asChild hasTooltip>
                <Text>{name}</Text>
            </Ellipsis>
        </FlexRow>
    );
};
