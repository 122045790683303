import { reportingKeys } from "@app/pages/Reporting/data";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { GraphQLObjectiveRepository } from "@app/repositories/GraphQLRepositories/ObjectiveRepository";
import { GraphQLThemeRepository } from "@app/repositories/GraphQLRepositories/ThemeRepository";
import { tanstackUnitaryOptimisticUpdateOptionsMaker } from "@app/shared/utils/optimisticUpdates";
import {
    GetObjectiveQuery,
    Indicator2Filter,
    ObjectivesFilter,
    ObjectiveStatus,
} from "@generated/client/graphql";
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    makeOptimisticObjectiveDeleteMany,
    makeOptimisticObjectiveUpdate,
    makeWorkingPlanThemes,
} from "./services";

export const objectiveKeys = {
    all: () => ["objectives"] as const,
    objectiveDetails: (id?: string) =>
        [...objectiveKeys.all(), "objectiveDetails", id] as const,
    filteredObjectives: (filter?: ObjectivesFilter) =>
        [...objectiveKeys.all(), "filter", filter] as const,
    filteredObjectivesWithDetails: (filter?: ObjectivesFilter) =>
        [...objectiveKeys.all(), "filterWithDetails", filter] as const,
    indicatorsFiltered: (filter: Indicator2Filter, entityId: string) =>
        [
            ...objectiveKeys.all(),
            "indicatorsFiltered",
            filter,
            entityId,
        ] as const,
    objectiveIndicator: (id: string) =>
        [...objectiveKeys.all(), "objectiveIndicator", id] as const,
    objectiveTargets: (id: string) =>
        [...objectiveKeys.all(), "objectiveTargets", id] as const,
    createObjective: () => [...objectiveKeys.all(), "createObjective"] as const,
    chooseIndicator: () => [...objectiveKeys.all(), "chooseIndicator"] as const,
    generateObjective: () =>
        [...objectiveKeys.all(), "generateObjective"] as const,
    createObjectives: () =>
        [...objectiveKeys.all(), "createObjectives"] as const,
    updateObjective: (id: string) =>
        [...objectiveKeys.all(), "updateObjective", id] as const,
    updateManyObjectives: (ids: string[]) =>
        [...objectiveKeys.all(), "updateManyObjectives", ids] as const,
    deleteObjective: (id: string) =>
        [...objectiveKeys.all(), "deleteObjective", id] as const,
    deleteManyObjectives: (ids: string[]) =>
        [...objectiveKeys.all(), "deleteManyObjectives", ids] as const,
};

const objectiveRepository = new GraphQLObjectiveRepository();
const indicatorRepository = new GraphQLIndicatorRepository();
const themeRepository = new GraphQLThemeRepository();

export const useObjective = (id?: string) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.objectiveDetails(id),
        queryFn: id ? () => objectiveRepository.getObjective(id) : skipToken,
    });
    return { objective: data?.objective, isPending };
};

export const useObjectives = (
    filter?: ObjectivesFilter,
    { skip = false }: { skip?: boolean } = {},
) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.filteredObjectives(filter),
        queryFn: skip
            ? skipToken
            : () => objectiveRepository.getObjectives(filter),
    });
    return { objectives: data?.objectives, isPending };
};

export const useObjectivesWithDetails = (
    filter?: ObjectivesFilter,
    { skip = false }: { skip?: boolean } = {},
) => {
    const { data, isPending } = useQuery({
        queryKey: objectiveKeys.filteredObjectivesWithDetails(filter),
        queryFn: skip
            ? skipToken
            : () => objectiveRepository.getObjectivesWithDetails(filter),
    });
    return { objectives: data?.objectives, isPending };
};

export const useCreateObjective = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.createObjective()],
        mutationFn: objectiveRepository.create,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useChooseIndicator = () => {
    const { mutate: chooseIndicator, ...mutation } = useMutation({
        mutationKey: [...objectiveKeys.chooseIndicator()],
        mutationFn: objectiveRepository.chooseIndicator,
    });

    return { chooseIndicator, ...mutation };
};

export const useGenerateObjective = () => {
    const { mutate: generateObjective, ...mutation } = useMutation({
        mutationKey: [...objectiveKeys.generateObjective()],
        mutationFn: objectiveRepository.generate,
    });

    return { generateObjective, ...mutation };
};

export const useCreateObjectives = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.createObjective()],
        mutationFn: objectiveRepository.createMany,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useEntityIndicators = (entityId: string | undefined) => {
    const { data } = useQuery({
        queryKey: reportingKeys.indicatorsByEntityId(entityId || ""),
        queryFn: entityId ? () => indicatorRepository.get(entityId) : skipToken,
    });
    return data?.indicators2;
};

export const useUpdateObjective = (
    id: string | undefined,
    entityId: string | undefined,
) => {
    const queryClient = useQueryClient();
    const indicators = useEntityIndicators(entityId);

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.updateObjective(id ?? "")],
        mutationFn: objectiveRepository.update,
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => objectiveKeys.objectiveDetails(id ?? ""),
            makeOptimisticObjectiveUpdate(indicators),
        ),

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useUpdateObjectiveStatus = (id: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.updateObjective(id)],
        mutationFn: (status: ObjectiveStatus) =>
            objectiveRepository.update({ id, status }),

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useUpdateObjectiveOwner = (id: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.updateObjective(id ?? "")],
        mutationFn: (ownerId: string) =>
            objectiveRepository.update({ id, ownerId }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useUpdateObjectives = (ids: string[]) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.updateManyObjectives(ids)],
        mutationFn: objectiveRepository.updateMany,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.all(),
            });
        },
    });

    return mutation;
};

export const useDeleteObjective = (
    objective: GetObjectiveQuery["objective"],
) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.deleteObjective(objective.id)],
        mutationFn: objectiveRepository.delete,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.filteredObjectivesWithDetails({
                    entityId: objective.entityId,
                }),
            });
        },
    });

    return mutation;
};

export const useDeleteObjectives = (ids: string[], entityId: string) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationKey: [...objectiveKeys.deleteManyObjectives(ids)],
        mutationFn: objectiveRepository.deleteMany,

        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => objectiveKeys.filteredObjectivesWithDetails({ entityId }),
            makeOptimisticObjectiveDeleteMany(),
        ),

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: objectiveKeys.filteredObjectivesWithDetails({
                    entityId,
                }),
            });
        },
    });

    return mutation;
};

export const useThemes = () => {
    const { data, isPending } = useQuery({
        queryKey: ["themes"],
        queryFn: themeRepository.get,
    });

    return {
        themes: makeWorkingPlanThemes(data?.themes ?? []),
        isPending,
    };
};
