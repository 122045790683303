import { useControllableState } from "@design-system/Utilities";
import { useEffect, useCallback, Dispatch, SetStateAction } from "react";

export type SelectType = "single" | "multi";
export type SelectProps = {
    closeOnValueChange?: boolean;
    defaultValue?: string[];
    onValueChange?: (value: string[]) => void;
    selectType?: SelectType;
    value?: string[];
};

function useSelectTypeChangeEffect(
    selectType: SelectType,
    setValue: Dispatch<SetStateAction<string[] | undefined>>,
    value: string[] | undefined,
) {
    useEffect(() => {
        if (selectType !== "single") {
            return;
        }

        if (!value || value.length <= 1) {
            return;
        }

        setValue(value.slice(-1));
    }, [setValue, selectType, value]);
}
export function useSelect({
    close,
    closeOnValueChange,
    defaultValue,
    onValueChange,
    selectType = "single",
    value: propsValue,
}: SelectProps & { close?: () => void }) {
    const [value, setValue] = useControllableState({
        defaultProp: defaultValue,
        onChange: onValueChange,
        prop: propsValue,
    });

    useSelectTypeChangeEffect(selectType, setValue, value);

    const handleSelect = useCallback(
        (val: string) => {
            const isSelected = !!value?.includes(val);
            let res;

            if (selectType === "single" && isSelected) res = [];
            if (selectType === "single" && !isSelected) res = [val];

            if (selectType === "multi" && isSelected) {
                res = value?.filter((v) => v !== val);
            }
            if (selectType === "multi" && !isSelected) {
                res = [...(value ?? []), val];
            }

            setValue(res);

            if (closeOnValueChange) close?.();
        },
        [closeOnValueChange, setValue, selectType, close, value],
    );

    return {
        handleSelect,
        setValue,
        value,
    };
}