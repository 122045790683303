import BodyHeader from "@app/shared/components/BodyHeader";
import { Icon } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import {
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { useEffect, useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { QuestionnairesDashboard } from "./components/QuestionnairesDashboard";
import { useQuestionnaires } from "./data";
import "./i18n";
import { keys, RESOURCE_NAME } from "./i18n";

export const ExternalQuestionnaires: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { externalQuestionnaires, isPending } = useQuestionnaires();
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const questionnairesFiltered = useMemo(
        () =>
            searchString
                ? externalQuestionnaires?.filter((questionnaire) => {
                      const search = searchString.toLowerCase();
                      return questionnaire?.name
                          ?.toLowerCase()
                          .includes(search);
                  })
                : externalQuestionnaires,
        [externalQuestionnaires, searchString],
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 });
    }, [setPagination, searchString]);

    const tableOptions = {
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        autoResetPageIndex: false,
    };

    return (
        <FlexCol gap="4" p="3">
            <QuestionnairesDashboard />
            <PageContainer>
                <BodyHeader
                    title={t(keys.page_title)}
                    subTitle={t(keys.page_subtitle)}
                />
                <Box p="4">
                    <FlexCol gap="4">
                        <SearchBar
                            placeholder={t(keys.search_bar_placeholder)}
                            searchString={searchString}
                            setSearchString={setSearchString}
                            className="w-[250px]"
                        />
                        <Table
                            columns={columns}
                            data={questionnairesFiltered}
                            loading={isPending}
                            options={tableOptions}
                            EmptyBody={EmptyState}
                            variant="grid"
                        />
                    </FlexCol>
                </Box>
            </PageContainer>
        </FlexCol>
    );
};

const EmptyState = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" className="my-8">
                <Icon name="search" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {t(keys.empty_state_title)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {t(keys.empty_state_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
