import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "questions-tab";

const keys = addTranslations(RESOURCE_NAME, {
    //Questions
    owner_assign_no_owner: {
        en: "Unassigned",
        fr: "Non assigné",
    },

    empty_questions_section_title: {
        en: "No questions found",
        fr: "Aucune question trouvée",
    },
    empty_questions_section_subtitle: {
        en: "Check that your filters are correctly defined",
        fr: "Vérifiez que vos filtres sont correctement définis",
    },

    //Answer AI suggestion
    ai_suggestion_button: { en: "Suggest", fr: "Suggestion" },
    ai_suggestion_open: { en: "Show", fr: "Afficher" },
    ai_suggestion_close: { en: "Hide", fr: "Masquer" },
    ai_suggestion_title: { en: "AI suggestion", fr: "Suggestion AI" },
    ai_suggestion_motivation: {
        en: "AI Explanation of the answer",
        fr: "Explication de la réponse par AI",
    },
    ai_suggestion_comment: { en: "Note", fr: "Note" },
    ai_suggestion_answer: { en: "Answer", fr: "Réponse" },
    ai_suggestion_reporting: { en: "Reporting", fr: "Reporting" },
    ai_suggestion_references: { en: "References", fr: "Références" },
    ai_suggestion_re_generate: { en: "Re-generate", fr: "Régénérer" },
    ai_suggestion_confirm: { en: "Confirm", fr: "Confirmer" },
    ai_suggestion_unsuccessful: {
        en: "AI couldn't generate a suggestion for this question",
        fr: "AI n'a pas pu générer une suggestion pour cette question",
    },
    ai_suggestion_error: {
        en: "AI unable to generate suggestion",
        fr: "L'IA n'a pas pu générer une suggestion",
    },

    //Document citation tooltip
    insert_tooltip: { en: "Insert", fr: "Insérer" },
    document_citation_tooltip_document_name: { en: "Document", fr: "Document" },
    document_citation_tooltip_content: { en: "Content", fr: "Contenu" },
    document_citation_tooltip_edited: { en: "Edited", fr: "Modifié" },
    last_edited_by: {
        en: "On {{date}} by {{firstName}} {{lastName}}",
        fr: "Le {{date}} par {{firstName}} {{lastName}}",
    },

    question_status_not_started: { en: "Not started", fr: "Non commencé" },
    question_status_in_progress: { en: "In Progress", fr: "En cours" },
    question_status_ai_suggestion_success: {
        en: "AI suggestion success",
        fr: "Succès de la suggestion AI",
    },
    question_status_ai_suggestion_failed: {
        en: "AI suggestion failed",
        fr: "Échec de la suggestion AI",
    },
});
export function useQuestionsTabTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
