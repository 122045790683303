import { addTranslations } from "@app/i18n";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getFrameworkColor } from "../../services";
import { FrameworkQuestionnaire } from "../../types";
import { headerBgSvgDataUrl } from "../FrameworkCard/utils";

const NAMESPACE = "QuestionnaireFrameworkCard";
const keys = addTranslations(NAMESPACE, {
    questions_with_answers: {
        en: "{{count}} ready",
        fr: "{{count}} prêtes",
    },
    questions_total: {
        en: "{{count}} questions",
        fr: "{{count}} questions",
    },

    request_access: {
        en: "Request access",
        fr: "Demander l'accès",
    },

    datapoints_answered: {
        en: "Validated items",
        fr: "Items validés",
    },

    datapoints_ai_success: {
        en: "AI answers",
        fr: "Réponses IA",
    },
    no_data: {
        en: "Progress bar not available",
        fr: "Barre de progression non disponible",
    },
});

export const QuestionnaireFrameworkCard = ({
    framework,
}: {
    framework: FrameworkQuestionnaire;
}) => {
    const { t } = useTranslation(NAMESPACE);
    const progress =
        ((framework.stats.answered + framework.stats.aiSuggestionSuccess) /
            framework.stats.total) *
        100;
    return (
        <Link
            to={`${framework.id}`}
            className="[all:unset] no-underline ![text-decoration:none]"
        >
            <FlexCol
                as="article"
                br="lg"
                elevation="b-200"
                h="full"
                className="border border-secondary"
            >
                {/* Header */}
                <FlexRow
                    as="header"
                    h="fit"
                    p="6"
                    className="bg-beavrGreen h-[104px]"
                    style={{
                        backgroundColor: getFrameworkColor(framework.cmsId),
                        backgroundImage: `url(${headerBgSvgDataUrl})`,
                        backgroundSize: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right",
                    }}
                >
                    <Text as="h6" variant="hero6" color="white">
                        {framework.name}
                    </Text>
                </FlexRow>

                {/* Body */}
                <FlexCol p="4" gap="2">
                    <Text variant="header2">{progress.toFixed(0)}%</Text>

                    <ProgressBar
                        className="w-full rounded-sm"
                        segments={[
                            {
                                color: chroma("#03A365"),
                                value:
                                    framework.stats.answered +
                                    framework.stats.validated,
                                labelValue: `${(((framework.stats.answered + framework.stats.validated) / framework.stats.total) * 100).toFixed(0)}%`,
                                label: "Answered",
                            },
                            {
                                color: chroma("#B554F2"),
                                value: framework.stats.aiSuggestionSuccess,
                                labelValue: `${((framework.stats.aiSuggestionSuccess / framework.stats.total) * 100).toFixed(0)}%`,
                                label: "AI Suggestions",
                            },
                        ]}
                        total={framework.stats.total}
                    />
                    <FlexRow justifyContent="between">
                        <Text>
                            {t(keys.questions_with_answers, {
                                count:
                                    framework.stats.answered +
                                    framework.stats.aiSuggestionSuccess,
                            })}
                        </Text>
                        <Text>
                            {t(keys.questions_total, {
                                count: framework.stats.total,
                            })}
                        </Text>
                    </FlexRow>
                </FlexCol>
            </FlexCol>
        </Link>
    );
};
