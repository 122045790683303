import { Button } from "@design-system/Inputs/Button";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import {
    createContext,
    type FC,
    type Ref,
    type RefAttributes,
    useContext,
} from "react";
import { cn } from "../../Utilities";

const SegmentButtonCtx = createContext<{ size: "sm" | "md" } | null>(null);

const Item: FC<
    ToggleGroupPrimitive.ToggleGroupItemProps & {
        ref?: Ref<HTMLButtonElement>;
    }
> = ({ children, ref, ...props }) => {
    const ctx = useContext(SegmentButtonCtx);
    const size = ctx?.size;

    if (!size) {
        throw new Error(
            "SegmentButton.Item should be used within a SegmentButton. If you need a button, use a <Button /> componenet instead.",
        );
    }
    return (
        <ToggleGroupPrimitive.Item asChild ref={ref} {...props}>
            <Button __segment variant="plain" size={size}>
                {children}
            </Button>
        </ToggleGroupPrimitive.Item>
    );
};

export type SegmentButtonProps = {
    size?: "sm" | "md";
} & Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, "type"> &
    RefAttributes<HTMLDivElement>;

type SegmentButtonType = FC<
    SegmentButtonProps & { ref?: Ref<HTMLDivElement> }
> & {
    Item: typeof Item;
};

export const SegmentButton: SegmentButtonType = ({
    className,
    size,
    ref,
    ...props
}) => {
    return (
        <SegmentButtonCtx.Provider value={{ size: size ?? "md" }}>
            <ToggleGroupPrimitive.Root
                className={cn(
                    "inline-flex border border-secondary rounded-lg overflow-hidden w-full",
                    className,
                )}
                ref={ref}
                {...props}
                type="single"
            />
        </SegmentButtonCtx.Provider>
    );
};

SegmentButton.Item = Item;
