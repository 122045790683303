import { type ColumnDef } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";

import { useSetToast } from "@app/components/Toast";
import TagListEditor from "@app/shared/components/TagListEditor";
import { TagsMultiSelect } from "@app/shared/components/TagsMultiSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { useOptimistic } from "@app/shared/utils/optimisticUpdates";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { ResponseStatus, TagType } from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { useQueryParam } from "use-query-params";
import { editQuestionIdAtom, filterParamArgs } from "../../ctx";
import {
    useDeleteTemplateQuestion,
    useUpdateSupplierTemplateQuestion,
} from "../../data";
import { getFirstAvailableTranslation } from "../../services";
import { type QuestionListItem } from "../../types";
import { DeleteQuestionModal } from "./DeleteQuestionModal";
import { useSupplierQuestionsTableTranslations } from "./i18n";

const questionCol = (
    selectedLocale: SupportedLocales,
): ColumnDef<QuestionListItem> => ({
    id: "question",
    meta: {
        colWidth: "60%",
        headerClassName: "max-w-[700px]",
        cellClassName: "max-w-[700px]",
    },
    header: () => {
        const { t, keys } = useSupplierQuestionsTableTranslations();
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.question)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const { nameMultiLocale, descriptionMultiLocale } = row.original;

        const { t, keys } = useSupplierQuestionsTableTranslations();

        const translatedQuestion = nameMultiLocale?.[selectedLocale];
        const translatedDescription = descriptionMultiLocale?.[selectedLocale];

        const firstAvailableTranslation = getFirstAvailableTranslation(
            row.original,
        );

        return (
            <FlexRow justifyContent="between" w="full">
                {translatedQuestion ? (
                    <Ellipsis asChild hasTooltip>
                        <Text variant="body" color="primary">
                            {translatedQuestion}
                        </Text>
                    </Ellipsis>
                ) : (
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Text variant="body" color="secondary">
                                {t(keys.question_not_translated)}
                            </Text>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {firstAvailableTranslation}
                        </Tooltip.Content>
                    </Tooltip>
                )}
                {translatedDescription && (
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Icon name="info" size="sm" />
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {translatedDescription}
                        </Tooltip.Content>
                    </Tooltip>
                )}
            </FlexRow>
        );
    },
});

const questionnaireCol: ColumnDef<QuestionListItem> = {
    id: "questionnaire",
    meta: {
        colWidth: "30%",
    },
    header: () => {
        const [tagFilter, setTagFilter] = useQueryParam(
            ...filterParamArgs.tagFilter,
        );
        return (
            <TagsMultiSelect
                tagsIds={tagFilter}
                setTagsIds={setTagFilter}
                type={TagType.Questionnaire}
                isHeader
            />
        );
    },
    cell: ({ row }) => {
        const tagIds = useMemo(
            () => row.original.tags?.map((tag) => tag.id),
            [row.original.tags],
        );
        const { updateQuestion } = useUpdateSupplierTemplateQuestion();

        const { setToastError } = useSetToast();

        const { t, keys } = useSupplierQuestionsTableTranslations();

        const [optimisticTagIds, setOptimisticTagIds] = useOptimistic(tagIds);

        const handleTagChange = (tagIds: string[]) => {
            setOptimisticTagIds(tagIds);
            updateQuestion(
                {
                    id: row.original.id,
                    input: {
                        tagIds,
                    },
                },
                {
                    onSettled(data, error) {
                        if (
                            error ||
                            data?.updateSupplierTemplateQuestion?.status ===
                                ResponseStatus.Error
                        ) {
                            setToastError(t(keys.toast_error_update_question));
                        }
                    },
                },
            );
        };

        return (
            <TagListEditor
                values={optimisticTagIds}
                onValuesChange={handleTagChange}
                tagType={TagType.Questionnaire}
            />
        );
    },
};

const actionCol: ColumnDef<QuestionListItem> = {
    id: "action",
    meta: {
        colWidth: "10%",
    },
    cell: ({ row }) => {
        const { deleteQuestion } = useDeleteTemplateQuestion();
        const { t, keys } = useSupplierQuestionsTableTranslations();
        const { setToastSuccess, setToastError } = useSetToast();

        const setEditQuestionId = useSetAtom(editQuestionIdAtom);

        const [isDeleteQuestionModalOpen, setIsDeleteQuestionModalOpen] =
            useState<boolean>(false);

        const handleModalDelete = () => {
            deleteQuestion(row.original.id, {
                onSettled(data, error) {
                    if (
                        error ||
                        data?.deleteSupplierTemplateQuestion?.status ===
                            ResponseStatus.Error
                    ) {
                        setToastError(t(keys.toast_error_delete_question));
                        return;
                    }
                    setToastSuccess(t(keys.toast_success_delete_question));
                },
            });
        };

        const handleDelete = () => {
            setIsDeleteQuestionModalOpen(true);
        };

        const handleEdit = () => {
            return setEditQuestionId(row.original.id);
        };

        return (
            <>
                <Dropdown>
                    <Dropdown.Trigger asChild>
                        <Button variant="outlined" size="sm">
                            <Button.Icon name="more" size="sm" />
                        </Button>
                    </Dropdown.Trigger>
                    <Dropdown.Content hasPortal align="end">
                        <Dropdown.Item onClick={handleEdit}>
                            <Dropdown.ItemIcon name="edit" />
                            <Dropdown.ItemText>
                                {t(keys.edit_question)}
                            </Dropdown.ItemText>
                        </Dropdown.Item>
                        <Dropdown.Item
                            variant="negative"
                            onClick={handleDelete}
                        >
                            <Dropdown.ItemIcon name="trash" />
                            <Dropdown.ItemText>
                                {t(keys.delete_question)}
                            </Dropdown.ItemText>
                        </Dropdown.Item>
                    </Dropdown.Content>
                </Dropdown>
                <DeleteQuestionModal
                    open={isDeleteQuestionModalOpen}
                    onOpenChange={setIsDeleteQuestionModalOpen}
                    onDelete={handleModalDelete}
                />
            </>
        );
    },
};

export const useColumns = (
    selectedLocale: SupportedLocales,
): ColumnDef<QuestionListItem>[] => {
    const columns = useMemo(
        () => [questionCol(selectedLocale), questionnaireCol, actionCol],
        [selectedLocale],
    );
    return columns;
};
