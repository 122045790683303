import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAdminNavigation } from "./hooks";

import {
    activeNavigationButtonKey,
    navigationButtonType,
    type NavigationButtonTypeKey,
} from "@app/store/routingStore";
import { Navigation } from "@design-system/Navigation/NavBar";
import { useTranslation } from "react-i18next";
import { useDocumentPageState } from "../../screens/Documents2/hooks/pageState";

import { resetContextAtom } from "@app/pages/Requirements/context";
import { FlexRow } from "@design-system/Layout/Flex";
import { isSaveBarOpenAtom, setSaveBarWarningAtom } from "../SaveBar";
import { Logo as BeavrLogo } from "./components/Logo";
import OrganizationSelect from "./components/OrganizationSelect";
import { openGroupsAtom } from "./ctx";
import "./i18n";
import { navigationButtons } from "./utils";

export default function AdminNavbar() {
    // Capture page views
    const { t } = useTranslation("Navbar");
    const { items: navItems, isPending } = useAdminNavigation();

    const { org_uname } = useParams();
    const navigate = useNavigate();

    const [activeButtonKey, setActiveNavigationButtonKey] = useAtom(
        activeNavigationButtonKey,
    );
    const saveBarIsOpen = useAtomValue(isSaveBarOpenAtom);
    const setSaveBarWarning = useSetAtom(setSaveBarWarningAtom);
    const [openGroups, setOpenGroups] = useAtom(openGroupsAtom);

    const { resetDefaultState: resetDocumentPageState } =
        useDocumentPageState();
    const resetRequirementPageState = useSetAtom(resetContextAtom);

    const handleMenuChange = (id: string) => {
        const key = id as NavigationButtonTypeKey;
        const item = navigationButtons[key];
        if (saveBarIsOpen) {
            setSaveBarWarning(true);
            return;
        }
        setActiveNavigationButtonKey(key);
        navigate(item.path);

        if (
            (
                [
                    navigationButtonType.policies,
                    navigationButtonType.measures,
                    navigationButtonType.custom_documents,
                ] as NavigationButtonTypeKey[]
            ).includes(key)
        ) {
            resetDocumentPageState();
        }
        if (key === navigationButtonType.requirements) {
            resetRequirementPageState();
        }
    };

    const handleGroupTitleClick = (title: string | undefined) => {
        return () => {
            if (!title) return;
            return setOpenGroups((prev) => ({
                ...prev,
                [title]: prev[title] === undefined ? false : !prev[title],
            }));
        };
    };

    return (
        <Navigation
            className="relative"
            selectedId={activeButtonKey}
            onSelectedIdChange={handleMenuChange}
        >
            <Navigation.Header>
                <BeavrLogo />
            </Navigation.Header>
            <Navigation.Content isLoading={isPending}>
                {org_uname &&
                    navItems?.map((group, index) => (
                        <Fragment key={index}>
                            {group.i18Key ? (
                                <Navigation.Group
                                    title={t(group.i18Key)}
                                    isOpen={openGroups[group.i18Key]}
                                    setIsOpen={handleGroupTitleClick(
                                        group.i18Key,
                                    )}
                                    className="mt-2"
                                >
                                    {group.items.map((key, idx) => {
                                        const item = navigationButtons[key];
                                        return (
                                            <Navigation.Button
                                                key={idx}
                                                id={key}
                                                iconName={item.icon}
                                                text={t(item.i18Key)}
                                            />
                                        );
                                    })}
                                </Navigation.Group>
                            ) : (
                                <>
                                    {group.items.map((key, idx) => {
                                        const item = navigationButtons[key];
                                        return (
                                            <Navigation.Button
                                                key={idx}
                                                id={key}
                                                iconName={item.icon}
                                                text={t(item.i18Key)}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </Fragment>
                    ))}
                <div className="min-h-10" />
            </Navigation.Content>

            <FlexRow
                className="absolute bottom-0 right-0"
                justifyContent="end"
                px="6"
                py="4"
                w="fit"
            >
                <OrganizationSelect />
            </FlexRow>
        </Navigation>
    );
}
