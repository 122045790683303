import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-questionnaire-header";

const keys = addTranslations(RESOURCE_NAME, {
    description_default: {
        en: "Fill the questionnaire and don't hesitate to contact us if you have any questions.",
        fr: "Remplissez le questionnaire et n'hésitez pas à nous contacter si vous avez des questions.",
    },
    stats_total: {
        en: "{{count}} questions",
        fr: "{{count}} questions",
    },
    stats_total_singular: {
        en: "1 question",
        fr: "1 question",
    },
    stats_answered: {
        en: "{{count}} answered",
        fr: "{{count}} répondues",
    },
    stats_answered_singular: {
        en: "1 answered",
        fr: "1 répondu",
    },
    stats_not_answered: {
        en: "{{count}} not answered",
        fr: "{{count}} non répondues",
    },
    stats_not_answered_singular: {
        en: "1 not answered",
        fr: "1 non répondu",
    },
});

export function useSupplierQuestionnaireHeaderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
