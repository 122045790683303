import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { useOrg } from "./data";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "@app/store/userStore";
import { useSyncCurrOrgUname } from "@app/shared/utils/storeRouterSync";
import { currOrgUnameAtom } from "@app/store/orgStore";
import { OrgType } from "@generated/client/graphql";
import { useInitPlanhat } from "@app/shared/planhat";

/**
 * Makes the given path an absolute URL.
 *
 * @param {string} path The path to make absolute.
 * @return {string} The absolute URL.
 */
export function makeAbsoluteUrl(path: string) {
    return new URL(path, window.location.origin).href;
}

/**
 * Returns a memoized callback function that navigates to the homepage when called.
 *
 * @return {Function} The function that navigates to the homepage.
 */
export function useGoToHomepage() {
    const navigate = useNavigate();

    return useCallback(() => {
        navigate("/o");
    }, [navigate]);
}

/**
 * Checks if the current page is in edit mode.
 *
 * @return {boolean} True if the current page is in edit mode, false otherwise.
 */
export function isEditMode() {
    return !!window?.location.pathname.endsWith("/edit");
}

/**
 * Type of the handle property of a router item with a crumb and page.
 * Used for composing breadcrumb components.
 */
type HandleWithCrumb = {
    crumb: typeof Link;
    page: string;
};

/**
 * Type of the handle property of a router item.
 * It is a union of several types, according on
 * the use case of the handle.
 *
 * So far, it only includes the usecase for the breadcrumb.
 * React-router sets it to `unknown`.
 */
export type Handle = HandleWithCrumb | {};

/**
 * Checks if the given item is a handle with a crumb and page.
 *
 * @param {any} item The item to check.
 * @return {boolean} True if the item is a handle, false otherwise.
 */
export function isHandleWithCrumb(item: any): item is HandleWithCrumb {
    return !!item?.crumb && !!item?.page;
}

/**
 * Custom hook for handling non-public routes, handling under the hood
 * the logic for common errors, sync with the store and planat, and returning
 * the data needed for error shwoing and handling.
 *
 * @returns {Object} An object containing the following properties:
 *   - copyErrorToClipboard: Function to copy the error to the clipboard.
 *   - data: The current user data.
 *   - error: The error object.
 *   - errorCode: The error code.
 *   - goToHomepage: Function to navigate to the homepage.
 *   - handleChurnedErrorPageCTA: Function to handle the churned error page CTA.
 *   - isLoading: Whether the data is loading.
 *   - isRefetching: Whether the data is refetching.
 *   - orgIsLoading: Whether the organization is loading.
 *   - org: The current organization.
 *   - showCustomerChurnedErrorPage: Whether the customer churned error page should be shown.
 *   - showInternalServerErrorPage: Whether the internal server error page should be shown.
 */
export const useNonPublicRoutes = () => {
    useSyncCurrOrgUname();
    const orgUname = useAtomValue(currOrgUnameAtom);
    const { data: org, isLoading: orgIsLoading } = useOrg(orgUname);

    const { data, error, isLoading, isRefetching } =
        useAtomValue(currentUserAtom);
    const currentUserRole = data?.currentUser?.permission?.role;
    const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

    const goToHomepage = useGoToHomepage();

    const copyErrorToClipboard = () => {
        const stringifiedError = error ? JSON.stringify(error, null, 2) : "";
        navigator.clipboard.writeText(stringifiedError);
    };

    const showCustomerChurnedErrorPage = org?.type === OrgType.CustomerChurned;
    const showInternalServerErrorPage =
        errorCode === "INTERNAL_SERVER_ERROR" ||
        errorCode === "UNAUTHENTICATED";
    const handleChurnedErrorPageCTA = () => {
        window.location.href = "mailto:contact@beavr.tech";
    };

    useInitPlanhat();

    useEffect(() => {
        try {
            const errorCode =
                // @ts-expect-error error is not typed
                error["response"]["errors"][0]["extensions"]["code"];
            setErrorCode(errorCode);
        } catch (e) {}
    }, [error]);

    const { refetch } = useAtomValue(currentUserAtom);
    useEffect(() => {
        refetch();
    }, []);

    return {
        copyErrorToClipboard,
        error,
        errorCode,
        goToHomepage,
        handleChurnedErrorPageCTA,
        isLoading,
        isRefetching,
        orgIsLoading,
        org,
        showCustomerChurnedErrorPage,
        showInternalServerErrorPage,
        currentUserRole,
    };
};
