import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "no-result-found";

const keys = addTranslations(RESOURCE_NAME, {
    error_title: {
        en: "No results found.",
        fr: "Aucun résultat trouvé.",
    },
    error_description: {
        en: "Sorry, I couldn’t find an answer to your request. Please try rephrasing or providing more details.",
        fr: "Désolé, je n'ai pas trouvé de réponse à votre demande. Veuillez reformuler ou fournir plus de détails.",
    },
});

export function useNoResultFoundTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
