import { type FC, type Ref, type SVGProps } from "react";
import { cn } from "../Utilities";

import { ReactComponent as Add } from "./assets/add.svg";
import { ReactComponent as AngleDown } from "./assets/angle-down.svg";
import { ReactComponent as AngleLeft } from "./assets/angle-left.svg";
import { ReactComponent as AngleRight } from "./assets/angle-right.svg";
import { ReactComponent as AngleUpDown } from "./assets/angle-up-down.svg";
import { ReactComponent as AngleUp } from "./assets/angle-up.svg";
import { ReactComponent as ArrowBottomRight } from "./assets/arrow-bottom-right.svg";
import { ReactComponent as ArrowCircleRight } from "./assets/arrow-circle-right.svg";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRefresh } from "./assets/arrow-refresh.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as ArrowTopRight } from "./assets/arrow-top-right.svg";
import { ReactComponent as ArrowUp } from "./assets/arrow-up.svg";
import { ReactComponent as Beavr } from "./assets/beavr.svg";
import { ReactComponent as Bell } from "./assets/bell.svg";
import { ReactComponent as Building } from "./assets/building.svg";
import { ReactComponent as CalendarArrowUp } from "./assets/calendar-arrow-up.svg";
import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { ReactComponent as CaretDown } from "./assets/caret-down.svg";
import { ReactComponent as CaretRight } from "./assets/caret-right.svg";
import { ReactComponent as Check } from "./assets/check.svg";
import { ReactComponent as CircleBig } from "./assets/circle-big.svg";
import { ReactComponent as CircleCross } from "./assets/circle-cross.svg";
import { ReactComponent as CircleInProgress } from "./assets/circle-in-progress.svg";
import { ReactComponent as CircleLoading } from "./assets/circle-loading.svg";
import { ReactComponent as CirclePlus } from "./assets/circle-plus.svg";
import { ReactComponent as CircleTodo } from "./assets/circle-todo.svg";
import { ReactComponent as CircleValidated } from "./assets/circle-validated.svg";
import { ReactComponent as CircleWarning } from "./assets/circle-warning.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as CloudWithValidateIcon } from "./assets/cloud-with-validate.svg";
import { ReactComponent as CommentPlus } from "./assets/comment-plus.svg";
import { ReactComponent as Comment } from "./assets/comment.svg";
import { ReactComponent as Company } from "./assets/company.svg";
import { ReactComponent as CornerDownRight } from "./assets/corner-down-right.svg";
import { ReactComponent as FlagFrance } from "./assets/countries/france.svg";
import { ReactComponent as FlagUK } from "./assets/countries/uk.svg";
import { ReactComponent as DashboardIcon } from "./assets/dashboard.svg";
import { ReactComponent as Document } from "./assets/document.svg";
import { ReactComponent as DoubleCheck } from "./assets/double-check.svg";
import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Duplicate } from "./assets/duplicate.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Email } from "./assets/email.svg";
import { ReactComponent as External } from "./assets/external.svg";
import { ReactComponent as EyeOff } from "./assets/eye-off.svg";
import { ReactComponent as EyeOn } from "./assets/eye-on.svg";
import { ReactComponent as File } from "./assets/file.svg";
import { ReactComponent as Flag } from "./assets/flag.svg";
import { ReactComponent as Form } from "./assets/form.svg";
import { ReactComponent as Help } from "./assets/help.svg";
import { ReactComponent as Image } from "./assets/image.svg";
import { ReactComponent as Info } from "./assets/info.svg";
import { ReactComponent as Link } from "./assets/link.svg";
import { ReactComponent as ListCheck } from "./assets/list-check.svg";
import { ReactComponent as ListOrder } from "./assets/list-order.svg";
import { ReactComponent as List } from "./assets/list.svg";
import { ReactComponent as LoadData } from "./assets/load-data.svg";
import { ReactComponent as LoaderAnimated } from "./assets/loader-animated.svg";
import { ReactComponent as Loader } from "./assets/loader.svg";
import { ReactComponent as LoadingBeams } from "./assets/loading-beams.svg";
import { ReactComponent as Lock } from "./assets/lock.svg";
import { ReactComponent as MagicWand } from "./assets/magic-wand.svg";
import { ReactComponent as Megaphone } from "./assets/megaphone.svg";
import { ReactComponent as Minus } from "./assets/minus.svg";
import { ReactComponent as More } from "./assets/more.svg";
import { ReactComponent as Palette } from "./assets/palette.svg";
import { ReactComponent as Party } from "./assets/party.svg";
import { ReactComponent as PencilCrossed } from "./assets/pencil-crossed.svg";
import { ReactComponent as Pencil } from "./assets/pencil.svg";
import { ReactComponent as Pilcrow } from "./assets/pilcrow.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import { ReactComponent as Quote } from "./assets/quote.svg";
import { ReactComponent as Redo } from "./assets/redo.svg";
import { ReactComponent as Refresh } from "./assets/refresh.svg";
import { ReactComponent as Ruler } from "./assets/ruler.svg";
import { ReactComponent as Sale } from "./assets/sale.svg";
import { ReactComponent as Scale } from "./assets/scale.svg";
import { ReactComponent as Search } from "./assets/search.svg";
import { ReactComponent as SettingsGear } from "./assets/settings-gear.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as SignOut } from "./assets/sign-out.svg";
import { ReactComponent as Stars } from "./assets/stars.svg";
import { ReactComponent as Table } from "./assets/table.svg";
import { ReactComponent as TagCircle } from "./assets/tag-circle.svg";
import { ReactComponent as TagEmptyCircle } from "./assets/tag-empty-circle.svg";
import { ReactComponent as Tag } from "./assets/tag.svg";
import { ReactComponent as Target } from "./assets/target.svg";
import { ReactComponent as Target2 } from "./assets/target2.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as Type } from "./assets/type.svg";
import { ReactComponent as Undo } from "./assets/undo.svg";
import { ReactComponent as UnfoldVertical } from "./assets/unfold-vertical.svg";
import { ReactComponent as Upload } from "./assets/upload.svg";
import { ReactComponent as Upload2 } from "./assets/upload2.svg";
import { ReactComponent as UserDelete } from "./assets/user-delete.svg";
import { ReactComponent as UserPlus } from "./assets/user-plus.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Users } from "./assets/users.svg";
import { ReactComponent as Validate } from "./assets/validate.svg";
import { ReactComponent as Warning } from "./assets/warning.svg";

export const iconMap = {
    add: Add,
    angleDown: AngleDown,
    angleLeft: AngleLeft,
    angleRight: AngleRight,
    angleUp: AngleUp,
    angleUpDown: AngleUpDown,
    arrowBottomRight: ArrowBottomRight,
    arrowCircleRight: ArrowCircleRight,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRefresh: ArrowRefresh,
    arrowRight: ArrowRight,
    arrowTopRight: ArrowTopRight,
    arrowUp: ArrowUp,
    beavr: Beavr,
    bell: Bell,
    building: Building,
    calendar: Calendar,
    calendarArrowUp: CalendarArrowUp,
    caretDown: CaretDown,
    caretRight: CaretRight,
    check: Check,
    circleBig: CircleBig,
    circleCross: CircleCross,
    circleInProgress: CircleInProgress,
    circleLoading: CircleLoading,
    circlePlus: CirclePlus,
    circleTodo: CircleTodo,
    circleValidated: CircleValidated,
    circleWarning: CircleWarning,
    close: Close,
    cloudWithValidate: CloudWithValidateIcon,
    comment: Comment,
    commentPlus: CommentPlus,
    company: Company,
    cornerDownRight: CornerDownRight,
    dashboard: DashboardIcon,
    document: Document,
    doubleCheck: DoubleCheck,
    download: Download,
    duplicate: Duplicate,
    edit: Edit,
    editCrossed: PencilCrossed,
    email: Email,
    external: External,
    eyeOff: EyeOff,
    eyeOn: EyeOn,
    file: File,
    flag: Flag,
    form: Form,
    help: Help,
    info: Info,
    image: Image,
    link: Link,
    list: List,
    listCheck: ListCheck,
    listOrder: ListOrder,
    loadData: LoadData,
    loader: Loader,
    loaderAnimated: LoaderAnimated,
    loadingBeams: LoadingBeams,
    lock: Lock,
    magicWand: MagicWand,
    megaphone: Megaphone,
    minus: Minus,
    more: More,
    palette: Palette,
    party: Party,
    pencil: Pencil,
    pilcrow: Pilcrow,
    plus: Plus,
    quote: Quote,
    redo: Redo,
    refresh: Refresh,
    ruler: Ruler,
    sale: Sale,
    scale: Scale,
    search: Search,
    settings: Settings,
    settingsGear: SettingsGear,
    signOut: SignOut,
    stars: Stars,
    table: Table,
    tag: Tag,
    tagCircle: TagCircle,
    tagEmptyCircle: TagEmptyCircle,
    target: Target,
    target2: Target2,
    trash: Trash,
    type: Type,
    undo: Undo,
    unfoldVertical: UnfoldVertical,
    upload: Upload,
    upload2: Upload2,
    user: User,
    userDelete: UserDelete,
    userPlus: UserPlus,
    users: Users,
    validate: Validate,
    warning: Warning,

    // flags
    fr: FlagFrance,
    uk: FlagUK,
} as const;

export type IconName = keyof typeof iconMap;

export interface IconProps extends SVGProps<SVGSVGElement> {
    name: IconName;
    size?: "xs" | "sm" | "md" | "lg";
    ref?: Ref<SVGSVGElement>;
}

export const Icon: FC<IconProps> = ({
    name,
    size,
    className,
    ref,
    ...props
}) => {
    const Comp = iconMap[name];

    // compute width and height based on size prop
    const sizeTw =
        size === "xs"
            ? "w-3 h-3"
            : size === "sm"
              ? "w-4 h-4"
              : size === "lg"
                ? "w-6 h-6"
                : "w-5 h-5"; // md is also the default size
    return (
        <Comp
            ref={ref}
            className={cn(sizeTw, "shrink-0", className)}
            {...props}
        />
    );
};
