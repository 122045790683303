import UserSelect from "@app/components/Dropdown/dropdowns/User";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { Switch } from "@design-system/Inputs/Switch";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    BooleanEnum,
    CsrdDatapointStatus,
    CsrdDatapointType,
    ValidationStatus,
} from "@generated/client/graphql";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { RefObject, useCallback, type FC } from "react";
import { useTranslation } from "react-i18next";

import { UploadedDocumentsList } from "@app/shared/components/documentUpload/UploadedDocumentsList";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import {
    useConditionalScrollTo,
    useOnScrollIntoView,
} from "@app/shared/utils/scroll";
import { intervalToDuration } from "date-fns";
import {
    activeDatapointIdAtom,
    activeDisclosureRequirementIdAtom,
    documentModalActiveDatapoint,
    lastUrlUpdateByClickTimestampAtom,
    setScrollAnchorAtom,
} from "../../ctx";
import { PillarDatapoint } from "../../types";
import { Prefill } from "../Prefill";
import { ActionDropdown } from "./ActionDropdown";
import { DatapointAiSuggestion } from "./DatapointAiSuggestion";
import { DatapointValueInput } from "./DatapointValueInput";
import "./i18n";
import { useDatapoint } from "./useDatapoint";

export const DatapointForm: FC<{
    datapoint: PillarDatapoint;
    pillarBodyContainerRef?: RefObject<HTMLElement | null>;
}> = ({ datapoint, pillarBodyContainerRef }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const { datapointState: state, handleChange } = useDatapoint(datapoint);
    const selectedDatapointId = useAtomValue(activeDatapointIdAtom);
    const selectedDisclosureRequirementId = useAtomValue(
        activeDisclosureRequirementIdAtom,
    );
    const [lastUrlUpdateByClickTimestamp, setLastUrlUpdateByClickTimestamp] =
        useAtom(lastUrlUpdateByClickTimestampAtom);
    const setNavigationAtom = useSetAtom(setScrollAnchorAtom);
    const setDocumentModalDatapoint = useSetAtom(documentModalActiveDatapoint);
    const isNotMaterial = datapoint.status === CsrdDatapointStatus.NotMaterial;
    const materialityComment = datapoint?.materiality?.comment ?? "";
    const commentPlaceholder = isNotMaterial
        ? t("materiality_comment")
        : t("comment", {
              charCount: state.charCount,
          });
    const commentValue = isNotMaterial ? materialityComment : state.comment;

    const scrollToDatapointRef = useConditionalScrollTo(
        selectedDatapointId === datapoint.cmsId &&
            lastUrlUpdateByClickTimestamp &&
            (intervalToDuration({
                start: lastUrlUpdateByClickTimestamp,
                end: new Date(),
            }).seconds ?? 0) <= 3,
    );

    const scrollIntoViewCb = useCallback(() => {
        if (
            lastUrlUpdateByClickTimestamp &&
            (selectedDisclosureRequirementId === datapoint.drId ||
                !selectedDisclosureRequirementId)
        ) {
            setLastUrlUpdateByClickTimestamp(undefined);
        }
        if (
            (!lastUrlUpdateByClickTimestamp ||
                (intervalToDuration({
                    start: lastUrlUpdateByClickTimestamp,
                    end: new Date(),
                }).seconds ?? 0) >= 3) &&
            selectedDisclosureRequirementId !== datapoint.drId
        ) {
            setNavigationAtom({
                drId: datapoint.drId,
            });
        }
    }, [
        lastUrlUpdateByClickTimestamp,
        selectedDisclosureRequirementId,
        datapoint.cmsId,
    ]);

    const scrollIntoViewRef = useOnScrollIntoView(
        scrollIntoViewCb,
        pillarBodyContainerRef?.current,
    );
    const handleRefs = (el: HTMLElement | null) => {
        scrollToDatapointRef(el);
        scrollIntoViewRef.current = el;
    };

    const handleOwnerChange = (userId: string | undefined) => {
        handleChange({ ownerId: userId ?? null });
    };
    const handleValidationChange = (validated: boolean) => {
        handleChange({
            status: validated
                ? ValidationStatus.Validated
                : ValidationStatus.Notvalidated,
        });
    };
    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        state.setComment(event.target.value);
        state.setCharCount(event.target.value.length);
        debounchedUpdateComment(event.target.value);
        if (
            datapoint.type === CsrdDatapointType.Narrative &&
            !event.target.value
        ) {
            state.setCommentError(t("required_comment_error"));
        } else {
            state.setCommentError(undefined);
        }
    };

    const debounchedUpdateComment = useDebouncedStableCallback((comment) => {
        handleChange({ comment: comment });
    }, 300);

    const handleAddDocumentClick = () => setDocumentModalDatapoint(datapoint);

    const handleClickOnCollapsedDatapoint = () => {
        state.setIsCollapsed(!state.isCollapsed);
    };

    const removeDocument = (fileId: string) => {
        handleChange({ disconnectEvidenceFileIds: [fileId] });
    };

    return (
        <Box w="full" ref={handleRefs}>
            {state.isCollapsed ? (
                <FlexRow
                    px="4"
                    alignItems="start"
                    gap="4"
                    alignSelf="stretch"
                    className={cn(
                        "border-b border-tertiary min-h-20",
                        isNotMaterial && "bg-neutral-100 hover:bg-secondary",
                    )}
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>
                    <FlexCol
                        w="full"
                        h="full"
                        py="4"
                        className=" cursor-pointer"
                        onClick={handleClickOnCollapsedDatapoint}
                    >
                        <Text variant="body-lg-bold">{datapoint?.name}</Text>
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            ) : (
                <FlexRow
                    gap="4"
                    px="4"
                    alignItems="start"
                    w="full"
                    className={cn(
                        "flex-1 border-b border-tertiary",
                        isNotMaterial && "bg-secondary",
                    )}
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>

                    <FlexCol gap="4" alignItems="start" w="full" py="4">
                        <FlexCol
                            w="full"
                            justifyContent="start"
                            className="cursor-pointer"
                            onClick={() =>
                                state.setIsCollapsed(!state.isCollapsed)
                            }
                        >
                            <Text variant="body-lg-bold">
                                {datapoint?.name}
                            </Text>
                        </FlexCol>
                        {isNotMaterial ? null : (
                            <FlexRow alignItems="center" gap="6">
                                <Text variant="body-sm" color="primary">
                                    {datapoint.mandatory === BooleanEnum.True
                                        ? t("mandatory")
                                        : t("voluntary")}
                                </Text>
                                {datapoint.phaseInAll === 0 &&
                                    datapoint.phaseInLess750 > 0 && (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t(
                                                "phaseInLessThan750",
                                                {
                                                    count: datapoint.phaseInLess750,
                                                },
                                            )}`}
                                        </Text>
                                    )}
                                {datapoint.phaseInAll > 0 &&
                                    datapoint.phaseInLess750 === 0 && (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })}`}
                                        </Text>
                                    )}
                                {datapoint.phaseInAll > 0 &&
                                    datapoint.phaseInLess750 > 0 &&
                                    (datapoint.phaseInAll ===
                                    datapoint.phaseInLess750 ? (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })}`}
                                        </Text>
                                    ) : (
                                        <Text variant="body-sm" color="primary">
                                            {`Phase-in: ${t("phaseInYears", {
                                                count: datapoint.phaseInAll,
                                            })} (${t("phaseInLessThan750", {
                                                count: datapoint.phaseInLess750,
                                            })})`}
                                        </Text>
                                    ))}
                            </FlexRow>
                        )}
                        <FlexRow
                            className="h-[28px]"
                            alignItems="center"
                            justifyContent="between"
                            w="full"
                        >
                            <FlexRow className="gap-[6px]" alignItems="center">
                                <Text variant="body-sm" color="secondary">
                                    {t("owner")}
                                </Text>
                                <UserSelect
                                    selectedUserId={state.ownerId ?? undefined}
                                    onChange={handleOwnerChange}
                                />
                            </FlexRow>
                            <Prefill
                                datapointId={datapoint.id}
                                hasIndicator={!!datapoint.indicatorCmsId}
                                onConfirm={handleChange}
                            />
                        </FlexRow>
                        {!isNotMaterial && (
                            <DatapointValueInput
                                datapoint={datapoint}
                                state={state}
                                handleChange={handleChange}
                            />
                        )}
                        <FlexRow w="full" className="relative">
                            <Textarea
                                placeholder={commentPlaceholder}
                                value={commentValue}
                                onChange={handleCommentChange}
                                className="w-full"
                                disabled={
                                    datapoint?.validationStatus ===
                                        ValidationStatus.Validated ||
                                    isNotMaterial
                                }
                                error={state.commentError}
                            />
                            {state.commentIsUpdating !== undefined && (
                                <Icon
                                    name={
                                        state.commentIsUpdating
                                            ? "circleLoading"
                                            : "check"
                                    }
                                    className={cn(
                                        "absolute right-2 top-2",
                                        state.commentIsUpdating
                                            ? "text-secondary"
                                            : "text-beavrGreen",
                                    )}
                                    size="sm"
                                />
                            )}
                        </FlexRow>
                        {!(
                            datapoint?.validationStatus ===
                                ValidationStatus.Validated || isNotMaterial
                        ) && (
                            <FlexRow
                                alignItems="center"
                                gap="1"
                                className="cursor-pointer"
                                onClick={handleAddDocumentClick}
                            >
                                <Text variant="body-bold" color="brand">
                                    {t("add_documents")}
                                </Text>
                                <Icon
                                    name="circlePlus"
                                    className="text-brand_hover"
                                    size="sm"
                                ></Icon>
                            </FlexRow>
                        )}
                        {!!datapoint?.evidenceFiles?.length && (
                            <UploadedDocumentsList
                                files={datapoint?.evidenceFiles || []}
                                onRemove={removeDocument}
                            />
                        )}
                        <Switch
                            checked={state.validated}
                            onChange={handleValidationChange}
                            label={t("validate")}
                            labelPosition="right"
                            disabled={
                                !!state.valueError || !!state.commentError
                            }
                        />
                        <DatapointAiSuggestion datapoint={datapoint} />
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            )}
        </Box>
    );
};
