import { useTheme } from "@app/store/themeStore";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import Select from "@design-system/Inputs/Select";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { FC, PropsWithChildren, useMemo } from "react";
import { SubthemeTag } from "../SubthemeTag";

export const SubthemeTagSelect: FC<
    PropsWithChildren<{
        themeId: string;
        subthemeId?: string;
        setSubthemeId?: (subthemeId: string) => void;
        disabled?: boolean;
        disabledTooltip?: string;
    }>
> = ({
    themeId,
    subthemeId,
    setSubthemeId,
    disabled = false,
    disabledTooltip = "",
}) => {
    const currentTheme = useTheme(themeId);
    const subthemes = useMemo(
        () => currentTheme?.subthemes || [],
        [subthemeId, themeId, currentTheme],
    );

    return (
        <Select
            value={subthemeId || undefined}
            onValueChange={setSubthemeId}
            disabled={disabled}
        >
            <Tooltip delayDuration={0} defaultOpen={false}>
                <Tooltip.Trigger>
                    <Select.Trigger className="w-fit">
                        <SubthemeTag
                            className="shadow-b-100"
                            themeId={themeId}
                            subthemeId={subthemeId}
                            isTrigger
                            disabled
                        />
                    </Select.Trigger>
                </Tooltip.Trigger>
                {disabled && disabledTooltip && (
                    <Tooltip.Content>{disabledTooltip}</Tooltip.Content>
                )}
            </Tooltip>
            <Select.Content>
                <Box className="max-h-[320px] overflow-auto">
                    {subthemes.map((subtheme) => (
                        <Select.Item
                            key={subtheme.id}
                            value={subtheme.id || ""}
                            className="h-max"
                        >
                            <FlexRow
                                alignItems="center"
                                justifyContent="between"
                                className="w-full"
                            >
                                <FlexRow
                                    alignItems="center"
                                    gap="2.5"
                                    style={{ color: currentTheme?.color }}
                                >
                                    <Icon name="tagEmptyCircle" size="md" />
                                    <Text
                                        variant="body"
                                        color="primary"
                                        className="group-hover:text-brand_hover text-sm"
                                    >
                                        {subtheme.name}
                                    </Text>
                                </FlexRow>
                                {subthemeId === subtheme.id && (
                                    <Icon
                                        name="check"
                                        size="sm"
                                        className="w-fit text-beavrGreen"
                                    />
                                )}
                            </FlexRow>
                        </Select.Item>
                    ))}
                </Box>
            </Select.Content>
        </Select>
    );
};
