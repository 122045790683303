import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { versionKeys } from "@app/store/queryKeys";
import { sendForReviewAtom, versionAtom } from "@app/store/versionStore";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useMutationState } from "@tanstack/react-query";
import { useAtom, useAtomValue } from "jotai";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidationModal, validationModalAtom } from "../../ctx";
import { useUnsendReviewRequest } from "../../data";
import { useEditorContext } from "../../hooks/useEditor";
import { RESOURCE_NAME, keys } from "./i18n";

export const SendForReviewModal: FC = () => {
    const [openModal, setOpenModal] = useAtom(validationModalAtom);
    const isOpen = openModal === ValidationModal.sendForReview;
    const closeModal = () => setOpenModal(null);

    const { showValidationChecklist, version, aiCheckLoadingProgress } =
        useEditorContext();

    const {
        mutate: sendForReviewMutation,
        isPending: isRequestForApprovalPending,
    } = useAtomValue(sendForReviewAtom);

    const aiCheckDocumentMutationState = useMutationState({
        filters: { mutationKey: [...versionKeys.aiCheck(version?.id ?? "")] },
        select: (mutation) => mutation.state.status,
    });
    const isAiCheckPending =
        !!aiCheckDocumentMutationState?.length &&
        aiCheckDocumentMutationState[
            aiCheckDocumentMutationState.length - 1
        ] === "pending";

    const sendForReview = () => sendForReviewMutation();

    const { t } = useTranslation(RESOURCE_NAME);
    const [isDocumentReady, setIsDocumentReady] = useState(false);

    const aiComment = version?.aiDocumentChecks?.comment;

    return (
        <Modal open={isOpen} onOpenChange={closeModal}>
            <Modal.Overlay centerContent>
                <Modal.Content
                    className={"w-[444px] text-primary min-w-[600px]"}
                >
                    <Modal.Header>
                        <Modal.Title>{t(keys.are_you_sure)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            <Text>{t(keys.after_sending)}</Text>
                            {showValidationChecklist && (
                                <AiSuggestion
                                    isOpen={!isAiCheckPending && !!aiComment}
                                    isLoading={isAiCheckPending}
                                >
                                    <AiSuggestion.Header
                                        title={
                                            isAiCheckPending
                                                ? t(keys.ai_title_loading)
                                                : t(keys.ai_title)
                                        }
                                    >
                                        {isAiCheckPending && (
                                            <FlexRow
                                                w="1/2"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <AiSuggestion.Loader
                                                    progress={
                                                        aiCheckLoadingProgress
                                                    }
                                                />
                                            </FlexRow>
                                        )}
                                    </AiSuggestion.Header>
                                    <AiSuggestion.Body className="pb-4">
                                        <Text className="whitespace-pre-wrap">
                                            {aiComment}
                                        </Text>
                                    </AiSuggestion.Body>
                                </AiSuggestion>
                            )}
                            <FlexRow gap="2" alignItems="center">
                                <Checkbox
                                    checked={isDocumentReady}
                                    onChange={setIsDocumentReady}
                                />
                                <Text>{t(keys.checkbox_label)}</Text>
                            </FlexRow>
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isRequestForApprovalPending}
                            onClick={sendForReview}
                            disabled={!isDocumentReady}
                        >
                            {t(keys.confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export const UnsendReviewRequestModal = () => {
    const { data: { version } = { version: undefined } } =
        useAtomValue(versionAtom);

    const [openModal, setOpenModal] = useAtom(validationModalAtom);
    const isOpen = openModal === ValidationModal.unsendReviewRequest;
    const closeModal = () => setOpenModal(null);

    const { mutateAsync, isPending } = useUnsendReviewRequest();
    const unsendReviewRequest = async () => {
        if (!version?.id) throw new Error("Version ID is required");
        await mutateAsync(version.id);
        closeModal();
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Modal open={isOpen} onOpenChange={closeModal}>
            <Modal.Overlay centerContent>
                <Modal.Content className="w-[444px] text-primary">
                    <Modal.Header>
                        <Modal.Title>{t(keys.unsend_title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Text>{t(keys.unsend_body)}</Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isPending}
                            onClick={unsendReviewRequest}
                            variant="flatDanger"
                        >
                            {t(keys.confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
