import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Document,
    DocumentType,
    DocumentVersion,
    DocumentVersionStatus,
    Requirement,
} from "../../../generated/client/graphql";
import {
    DropdownMenu,
    DropdownTrigger,
} from "../../components/Dropdown/components";
import DropdownContentLayout from "../../components/Dropdown/components/dropdowns/layout";
import FullFrameBodyLayout from "../../components/Layouts/FullFrame/BodyLayout";
import Loading from "../../components/Loading";
import CreateVersionBlock from "./components/CreateVersionBlock";
import InfoBlock from "./components/InfoBlock";
import RequirementTable from "./components/RequirementTable";
import ResetWithConfirmation from "./components/ResetWithConfirmation";
import SetRelevanceButton from "./components/SetRelevanceButton";
import UpdateDocumentModal from "./components/UpdateDocumentModal";
import VersionBlock from "./components/VersionBlock";
import { useDocument } from "./hooks";

import FullFrameBlock from "@app/components/Layouts/FullFrame/Block";
import { documentPaths } from "@app/routing/adminPaths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { currentUserRoleAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import { ObjectivesBlock } from "./components/ObjectivesBlock";
import "./i18n";

const DocumentScreen = () => {
    const { t } = useTranslation("DocumentScreen");
    const showObjectives = useFeatureFlagEnabled("legacyObjectives") === false;
    const setBreadcrumb = useBreadcrumb();
    const { isContributor } = useAtomValue(currentUserRoleAtom);
    const [showArchivedVersions, setShowArchivedVersions] = useState(false);
    const toggleArchivedVersions = () => setShowArchivedVersions((s) => !s);

    const { document, getDocumentQuery } = useDocument();

    const lastVersion =
        document?.versions &&
        document?.versions.length > 0 &&
        document?.versions[0];

    const showCreate =
        getDocumentQuery.data &&
        (!lastVersion || lastVersion.status === DocumentVersionStatus.Approved);

    const navigate = useNavigate();
    const goBackToList = () => navigate("..");

    const draftVersion =
        document &&
        lastVersion &&
        lastVersion.status !== DocumentVersionStatus.Approved
            ? lastVersion
            : undefined;
    const approvedVersions = document?.versions.filter(
        (v) => v.status === DocumentVersionStatus.Approved,
    );
    const mostRecentlyApprovedVersion = approvedVersions?.length
        ? approvedVersions[0]
        : undefined;
    const archivedVersions =
        approvedVersions?.filter(
            (v) => v.id !== mostRecentlyApprovedVersion?.id,
        ) ?? [];

    useEffect(() => {
        setBreadcrumb(documentPaths.document_id_param, document?.name ?? "...");
    }, [document?.name]);

    if (!getDocumentQuery.data) {
        return <Loading size="large" />;
    }

    if (!document) {
        return <Loading size="large" />;
    }

    return (
        <div className="flex-grow flex flex-col">
            <FullFrameBodyLayout>
                <FlexRow as="header" justifyContent="between" gap="4" mb="1">
                    <Button
                        className="min-w-fit"
                        variant="tonal"
                        size="sm"
                        onClick={goBackToList}
                    >
                        <Button.Icon name="arrowLeft" /> {t("previous_page")}
                    </Button>

                    <Text className="break-all" variant="header2" as="h3">
                        {document?.name ?? ""}
                    </Text>

                    {isContributor ? (
                        <div />
                    ) : (
                        <DropdownMenu modal={false}>
                            <DropdownTrigger asChild>
                                <Button
                                    className="min-w-fit"
                                    variant="tonal"
                                    size="sm"
                                >
                                    {t("more")}
                                    <Button.Icon name="angleDown" />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContentLayout align="end">
                                {document && (
                                    <ResetWithConfirmation
                                        documentId={document?.id}
                                    />
                                )}
                                {getDocumentQuery.data && (
                                    <SetRelevanceButton
                                        getDocumentQuery={getDocumentQuery}
                                    />
                                )}
                                {getDocumentQuery.data && (
                                    <UpdateDocumentModal
                                        document={
                                            getDocumentQuery.data
                                                .documentByUrl as Document
                                        }
                                        refetch={getDocumentQuery.refetch}
                                    />
                                )}
                            </DropdownContentLayout>
                        </DropdownMenu>
                    )}
                </FlexRow>
                <div className="space-y-5">
                    {showCreate && (
                        <CreateVersionBlock
                            getDocumentQuery={getDocumentQuery}
                            lastVersion={lastVersion as DocumentVersion}
                        />
                    )}
                    {draftVersion && (
                        <VersionBlock
                            documentId={document.id}
                            version={draftVersion as DocumentVersion}
                            getDocumentQuery={getDocumentQuery}
                            isMostRecentlyApprovedVersion={false}
                            isLatest={true}
                        />
                    )}
                    {mostRecentlyApprovedVersion && (
                        <VersionBlock
                            documentId={document.id}
                            version={
                                mostRecentlyApprovedVersion as DocumentVersion
                            }
                            getDocumentQuery={getDocumentQuery}
                            isMostRecentlyApprovedVersion={true}
                            isLatest={!draftVersion}
                        />
                    )}
                    {!showArchivedVersions && archivedVersions.length > 0 && (
                        <FullFrameBlock>
                            <Button
                                className="w-full"
                                variant="text"
                                size="sm"
                                onClick={toggleArchivedVersions}
                            >
                                {t("show_archived_versions", {
                                    count: archivedVersions.length,
                                })}
                                <Button.Icon name="angleDown" />
                            </Button>
                        </FullFrameBlock>
                    )}
                    {showArchivedVersions &&
                        archivedVersions.map((version) => (
                            <VersionBlock
                                documentId={document.id}
                                key={version.id}
                                version={version as DocumentVersion}
                                getDocumentQuery={getDocumentQuery}
                                isMostRecentlyApprovedVersion={false}
                                isLatest={false}
                            />
                        ))}
                </div>

                {showObjectives &&
                    document.type === DocumentType.MasterPolicy && (
                        <ObjectivesBlock Document={document as Document} />
                    )}
                <InfoBlock Document={document as Document} />

                {document?.requirements && (
                    <RequirementTable
                        showUpdateReqsModal={
                            !isContributor &&
                            document?.type === DocumentType.CustomDocument
                        }
                        documentId={document.id}
                        requirements={document?.requirements as Requirement[]}
                    />
                )}
            </FullFrameBodyLayout>
        </div>
    );
};

export default DocumentScreen;
