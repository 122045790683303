import { useNavigation } from "@app/lib/navigation";
import {
    useUpdateObjectiveOwner,
    useUpdateObjectiveStatus,
} from "@app/screens/Objectives/data";
import { StatusTagSelect } from "@app/screens/Objectives/objective/components/ObjectiveHeader/StatusTagSelect";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { useOptimistic } from "@app/shared/utils/optimisticUpdates";
import { getTargetToDisplay } from "@app/usecases/ObjectivesUseCases";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { TableCell, TableRow } from "@design-system/Table/HTMLTable";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import {
    GetObjectivesWithDetailsQuery,
    ObjectiveStatus,
} from "@generated/client/graphql";
import dayjs from "dayjs";
import { useState, type FC } from "react";
import { useObjectiveWorkPlanTranslations } from "../i18n";

interface ObjectiveRowProps {
    objective: NonNullable<GetObjectivesWithDetailsQuery["objectives"]>[number];
}

export const ObjectiveRow: FC<ObjectiveRowProps> = ({ objective }) => {
    const { t, keys } = useObjectiveWorkPlanTranslations();
    const [searchFilter, setSearchFilter] = useState<string>();

    const { navigateFromHome } = useNavigation();

    const targetToDisplay = getTargetToDisplay(objective.targets);
    const displayDate = targetToDisplay?.date
        ? dayjs(targetToDisplay.date).format("MMM YYYY")
        : "-";

    const textToDisplay =
        targetToDisplay?.value?.number !== undefined
            ? `${targetToDisplay.value.number} ${objective.indicator?.unit?.shortName || ""}`
            : "-";

    const [status, setStatus] = useOptimistic(objective.status);

    const { mutate: updateStatus } = useUpdateObjectiveStatus(objective.id);

    const handleStatusChange = (status: ObjectiveStatus) => {
        setStatus(status);
        updateStatus(status);
    };

    const [ownerId, setOwnerId] = useOptimistic(objective.owner?.id);

    const { mutate: updateOwner } = useUpdateObjectiveOwner(objective.id);

    const handleOwnerIdChange = (ownerId: string) => {
        setOwnerId(ownerId);
        updateOwner(ownerId);
    };

    const handleNameClick = () => {
        navigateFromHome(`objectives/${objective.id}`);
    };

    return (
        <TableRow>
            <TableCell className="pl-8">
                <FlexRow
                    gap="2"
                    alignItems="center"
                    onClick={handleNameClick}
                    className="cursor-pointer hover:underline"
                >
                    <StatusTagSelect
                        status={status}
                        setStatus={handleStatusChange}
                        displayStatusName={false}
                        className="shadow-none border-none"
                    />
                    <Text variant="body">{objective.name}</Text>
                </FlexRow>
            </TableCell>
            <TableCell>
                <FlexRow w="fit">
                    <UserChipSelect
                        userId={ownerId}
                        setUserId={handleOwnerIdChange}
                        searchFilter={searchFilter}
                        onSearchFilterChange={setSearchFilter}
                        trigger={
                            <FlexRow w="fit">
                                <UserTag
                                    userId={ownerId || undefined}
                                    noUserText={t(keys.no_owner_placeholder)}
                                />
                            </FlexRow>
                        }
                    />
                </FlexRow>
            </TableCell>
            <TableCell>
                <FlexRow gap="2" alignItems="center" justifyContent="end">
                    <Ellipsis asChild hasTooltip>
                        <Text>{displayDate}</Text>
                    </Ellipsis>
                    {targetToDisplay?.date && (
                        <Icon
                            name="calendar"
                            size="sm"
                            className="text-secondary"
                        />
                    )}
                </FlexRow>
            </TableCell>
            <TableCell>
                <FlexRow gap="2" alignItems="center" justifyContent="end">
                    <Text
                        variant="body"
                        color={targetToDisplay?.value ? "primary" : "secondary"}
                    >
                        {textToDisplay}
                    </Text>
                    {targetToDisplay?.value && (
                        <Icon
                            name="flag"
                            size="sm"
                            className="text-secondary"
                        />
                    )}
                </FlexRow>
            </TableCell>
        </TableRow>
    );
};
