import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "QuickAnswerModal";

const keys = addTranslations(RESOURCE_NAME, {
    title: {
        en: "Get a quick answer",
        fr: "Obtenir une réponse rapide",
    },
    callToAction: {
        en: "Ask AI",
        fr: "Demandez à l'IA",
    },
});
export function useQuickAnswerModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
