import { ReactNode, useState } from "react";
import { AlertModal, AlertModalContent } from "../AlertModal";

const ActionWithConfirmation = ({
    title,
    children,
    TriggerButton,
    confirmButton,
}: {
    title: string;
    children: ReactNode;
    TriggerButton: React.ReactNode;
    confirmButton: React.ReactNode | ((close: () => void) => ReactNode);
}) => {
    const [open, setOpen] = useState(false);

    const confirmButtonNode =
        typeof confirmButton === "function"
            ? confirmButton(() => setOpen(false))
            : confirmButton;

    return (
        <>
            <div onClick={() => setOpen(true)}>{TriggerButton}</div>
            <AlertModal open={open} onOpenChange={setOpen}>
                <AlertModalContent
                    title={title}
                    actions={confirmButtonNode}
                    className="max-w-lg"
                >
                    {children}
                </AlertModalContent>
            </AlertModal>
        </>
    );
};

export default ActionWithConfirmation;
