import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLSupplierRepository } from "@app/repositories/GraphQLRepositories/SupplierRepository";
import { RestLogoRepository } from "@app/repositories/RestRepositories/LogoRepository";
import {
    QuestionnaireStatus,
    type QuestionnaireFilterInput,
} from "@generated/client/graphql";
import { skipToken, useMutation, useQuery } from "@tanstack/react-query";
import { supplierKeys } from "../data";
import { formatQuestionnaire } from "../services";
import { CreateSupplierUserInput } from "./types";

const supplierRepository = new GraphQLSupplierRepository();
const logoRepository = new RestLogoRepository();

export function useSupplier(id?: string | null) {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.one(id),
        queryFn: id ? () => supplierRepository.getSupplier(id) : skipToken,
    });
    return {
        supplier: data?.supplier,
        ...query,
    };
}

export const useUpdateSupplier = () => {
    const { mutate: updateSupplier, ...mutation } = useMutation({
        mutationFn: supplierRepository.updateSupplier,
        onSettled: (_, __, { id }) => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.listAll(),
            });
            queryClient.invalidateQueries({
                queryKey: supplierKeys.one(id),
            });
        },
    });
    return { updateSupplier, ...mutation };
};

export const useSendQuestionnaire = () => {
    const { mutate: sendQuestionnaire, ...mutation } = useMutation({
        mutationFn: supplierRepository.sendSupplierQuestionnaire,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.supplierQuestionnairesListAll(),
            });
        },
    });
    return { sendQuestionnaire, ...mutation };
};

export const useSendQuestionnaireReminder = () => {
    const { mutate: sendQuestionnaireReminder, ...mutation } = useMutation({
        mutationFn: supplierRepository.sendSupplierQuestionnaireReminder,
    });

    return { sendQuestionnaireReminder, ...mutation };
};

export const useCloseQuestionnaire = () => {
    const { mutate: closeQuestionnaire, ...mutation } = useMutation({
        mutationFn: supplierRepository.closeSupplierQuestionnaire,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.supplierQuestionnairesListAll(),
            });
        },
    });
    return { closeQuestionnaire, ...mutation };
};

export const useSupplierQuestionnaires = (
    supplierId?: string,
    filter: QuestionnaireFilterInput = {},
    pagination: {
        skip?: number;
        take?: number;
    } = {},
) => {
    const query = useQuery({
        queryKey: supplierKeys.supplierQuestionnairesList(
            supplierId,
            filter,
            pagination,
        ),
        queryFn: supplierId
            ? () =>
                  supplierRepository.getSupplierQuestionnaires(
                      supplierId,
                      filter,
                      pagination,
                  )
            : skipToken,
    });
    const questionnaires = query.data?.supplierQuestionnaires2;

    const questionnaireList =
        questionnaires?.list.map(formatQuestionnaire) || [];
    return {
        questionnaires: {
            ...questionnaires,
            list: questionnaireList,
        },
        ...query,
    };
};

export const useLastQuestionnaire = (supplierId?: string) => {
    const { questionnaires, ...query } = useSupplierQuestionnaires(
        supplierId,
        { status: QuestionnaireStatus.Open },
        {
            take: 1,
        },
    );
    return {
        lastQuestionnaire: questionnaires?.list[0],
        ...query,
    };
};

export const useCreateSupplierUsers = () => {
    const { mutate: createSupplierUsers, ...mutation } = useMutation({
        mutationFn: ({
            supplierId,
            input,
        }: {
            supplierId: string;
            input: CreateSupplierUserInput[];
        }) => supplierRepository.createSupplierUsers(supplierId, input),
        onSettled: (_, __, { supplierId }) => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.one(supplierId),
            });
            queryClient.invalidateQueries({
                queryKey: supplierKeys.users({ supplierId }),
            });
        },
    });
    return { createSupplierUsers, ...mutation };
};

export const useSupplierIcon = (supplierId?: string, supplierName?: string) => {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.brand(supplierId),
        queryFn:
            supplierName && supplierId
                ? () =>
                      logoRepository.getBrand(
                          supplierName.toLowerCase().replace(/\s+/g, ""),
                      )
                : skipToken,
    });

    const iconUrl = data?.[0]?.icon;

    return { iconUrl, ...query };
};
