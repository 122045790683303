import Loading from "@app/components/Loading";
import { HeaderFullSkeleton } from "@app/shared/components/BackgroundedHeader";
import { SupportedLocales } from "@app/shared/utils/locales";
import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { cn } from "@design-system/Utilities";
import { useEffect, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { QuestionnaireHeader } from "./components/Header";
import { Question } from "./components/Question";
import {
    useSupplierQuestionnaire,
    useSupplierQuestionnaireQuestions,
} from "./data";
import { defaultStats } from "./services";
import { Banner } from "@app/shared/components/Banner";
import { QuestionnaireStatus } from "@generated/client/graphql";
import { useSupplierQuestionnaireTranslations } from "./i18n";

export const Questionnaire: FC = () => {
    const { t, keys } = useSupplierQuestionnaireTranslations();
    const { questionnaire_id, sup_id } = useParams();
    const { questionnaire, isPending: isQuestionnairePending } =
        useSupplierQuestionnaire(questionnaire_id, sup_id);
    const [currentLocale, setCurrentLocale] = useCurrentLocale(
        questionnaire?.locale,
    );
    const isQuestionnaireOpen =
        questionnaire?.status === QuestionnaireStatus.Open;

    const { questions, isPending: isQuestionsPending } =
        useSupplierQuestionnaireQuestions(questionnaire_id, sup_id);

    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                {isQuestionnairePending ? (
                    <HeaderFullSkeleton />
                ) : (
                    <QuestionnaireHeader
                        title={questionnaire?.name ?? ""}
                        stats={questionnaire?.stats ?? defaultStats}
                        locale={{
                            currentLocale,
                            onLocaleChange: setCurrentLocale,
                            availableLocales:
                                questionnaire?.locale.availableLocales ?? [],
                        }}
                    />
                )}
            </FlexCol>
            <Banner
                className="sticky top-0"
                show={!isQuestionnairePending && !isQuestionnaireOpen}
            >
                <Banner.Text>{t(keys.banner_questionnaire_closed)}</Banner.Text>
            </Banner>
            <FlexCol p="4">
                <FlexCol
                    className={cn("bg-tertiary border border-tertiary")}
                    br="lg"
                    w="full"
                    p="4"
                    gap="1"
                >
                    {isQuestionsPending ? (
                        <Loading size="large" />
                    ) : (
                        questions?.map((question) => (
                            <Question
                                key={question.id}
                                currentLocale={currentLocale}
                                question={question}
                                isOpen={isQuestionnaireOpen}
                            />
                        ))
                    )}
                </FlexCol>
            </FlexCol>
        </PageContainer>
    );
};

function useCurrentLocale({
    defaultLocale = SupportedLocales.EN,
    availableLocales = [SupportedLocales.EN, SupportedLocales.FR],
}: {
    defaultLocale?: SupportedLocales;
    availableLocales?: SupportedLocales[];
} = {}) {
    const [currentLocale, setCurrentLocale] = useState<SupportedLocales>(
        defaultLocale ?? availableLocales?.[0] ?? SupportedLocales.EN,
    );

    useEffect(() => {
        if (!availableLocales.includes(currentLocale)) {
            setCurrentLocale(defaultLocale);
        }
    }, [availableLocales, currentLocale, defaultLocale]);

    return [currentLocale, setCurrentLocale] as const;
}
