import { calculatePercentage, roundNumber } from "@app/shared/utils/math";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import {
    CheckboxCell,
    CheckboxHeader,
    type ColumnDef,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { type SuppliersListItem } from "../../types";

import { useSetToast } from "@app/components/Toast";
import { TagsMultiSelect } from "@app/shared/components/TagsMultiSelect";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { QuestionnaireStatus, TagType } from "@generated/client/graphql";
import chroma from "chroma-js";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { Tag } from "../../../components/Tag";
import { activeFilterParamArgs } from "../../ctx";
import { makeSupplierQuestionnaireLink } from "../../services";
import { StatusMultiSelect } from "../StatusMultiSelect";
import { useSupplierTableTranslations } from "./i18n";

const checkboxCol: ColumnDef<SuppliersListItem> = {
    id: "checkbox",
    header: ({ table }) => <CheckboxHeader table={table} />,
    cell: ({ row }) => <CheckboxCell row={row} />,
};

const supplierCol: ColumnDef<SuppliersListItem> = {
    accessorKey: "name",
    id: "name",
    meta: {
        cellClassName: "max-w-[400px]",
        headerClassName: "max-w-[400px]",
        colWidth: "400px",
    },
    header: () => {
        const { t, keys } = useSupplierTableTranslations();
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.supplier)}
            </Text>
        );
    },
    cell: ({ row }) => {
        return (
            <Link
                className="[all:unset] hover:no-underline"
                to={row.original.id}
            >
                <FlexRow
                    alignItems="center"
                    gap="2.5"
                    className="cursor-pointer group"
                >
                    <FlexRow
                        className="h-6 w-6"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Avatar
                            size="sm"
                            variant="circle"
                            letter={row.original.name?.[0]}
                            className="h-6 w-6"
                        />
                    </FlexRow>
                    <Ellipsis asChild hasTooltip>
                        <Text
                            variant="body"
                            color="primary"
                            className="group-hover:underline"
                        >
                            {row.original.name}
                        </Text>
                    </Ellipsis>
                </FlexRow>
            </Link>
        );
    },
};

const tagsCol: ColumnDef<SuppliersListItem> = {
    id: "tags",
    meta: {
        cellClassName: "max-w-[400px]",
        headerClassName: "max-w-[400px]",
        colWidth: "400px",
    },
    header: () => {
        const [tagFilter, setTagFilter] = useQueryParam(
            ...activeFilterParamArgs.tagFilter,
        );
        return (
            <TagsMultiSelect
                tagsIds={tagFilter}
                setTagsIds={setTagFilter}
                type={TagType.Supplier}
                isHeader
            />
        );
    },
    cell: ({ row }) => {
        return (
            <FlexRow alignItems="center" gap="3" className="flex-wrap">
                {row.original.tags.map((tag) => (
                    <Tag key={tag.id}>
                        <Tag.ColorIcon name="tagCircle" color={tag.color} />
                        {tag.name}
                    </Tag>
                ))}
            </FlexRow>
        );
    },
};

const lastQuestionnaireCol: ColumnDef<SuppliersListItem> = {
    id: "lastQuestionnaire",
    header: () => {
        const { t, keys } = useSupplierTableTranslations();
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.lastQuestionnaire)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const questionnaire = row.original.lastQuestionnaire;
        const { t, keys } = useSupplierTableTranslations();
        return (
            <Text
                variant="body"
                color={questionnaire ? "primary" : "secondary"}
            >
                {questionnaire?.name || t(keys.noQuestionnaire)}
            </Text>
        );
    },
};

const lastQuestionnaireProgressCol: ColumnDef<SuppliersListItem> = {
    id: "lastQuestionnaireProgress",
    header: () => {
        const { t, keys } = useSupplierTableTranslations();
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.lastQuestionnaireProgress)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const questionnaire = row.original.lastQuestionnaire;
        const progress = roundNumber(
            calculatePercentage(
                questionnaire?.stats?.answered || 0,
                questionnaire?.stats?.total || 0,
            ),
        );

        const { t, keys } = useSupplierTableTranslations();

        return (
            <FlexRow alignItems="center" gap="2">
                <ProgressBar
                    segments={[
                        {
                            value: questionnaire?.stats?.answered || 0,
                            label: t(keys.answered_questions),
                            labelValue: `${questionnaire?.stats?.answered || 0} / ${questionnaire?.stats?.total || 0}`,
                            color: chroma("rgba(3, 163, 101, 1)"),
                        },
                    ]}
                    total={questionnaire?.stats?.total || 0}
                    noDataPlaceholder=""
                />
                {questionnaire && (
                    <Text variant="body-sm" color="primary">
                        {progress}%
                    </Text>
                )}
            </FlexRow>
        );
    },
};

const lastQuestionnaireStatusCol: ColumnDef<SuppliersListItem> = {
    id: "lastQuestionnaireStatus",
    header: () => {
        const [statusFilter, setStatusFilter] = useQueryParam(
            ...activeFilterParamArgs.statusFilter,
        );
        return (
            <StatusMultiSelect
                values={statusFilter}
                onValuesChange={setStatusFilter}
            />
        );
    },
    cell: ({ row }) => {
        const questionnaire = row.original.lastQuestionnaire;

        if (
            !questionnaire ||
            questionnaire?.status === QuestionnaireStatus.Unknown
        ) {
            return null;
        }

        const { t, keys } = useSupplierTableTranslations();

        return (
            <Tag
                variant={
                    questionnaire?.status === QuestionnaireStatus.Open
                        ? "success"
                        : "regular"
                }
            >
                {t(keys.status, {
                    context: questionnaire.status,
                })}
            </Tag>
        );
    },
};

const lastUpdatedCol: ColumnDef<SuppliersListItem> = {
    id: "lastQuestionnaireUpdatedAt",
    accessorKey: "updatedAt",
    header: () => {
        const { t, keys } = useSupplierTableTranslations();
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.lastUpdated)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const questionnaire = row.original.lastQuestionnaire;
        dayjs.extend(LocalizedFormat);

        const displayDate = questionnaire?.updatedAt
            ? dayjs(questionnaire.updatedAt).format("LL")
            : "-";
        return (
            <FlexRow alignItems="center" gap="2">
                <Text variant="body" color="primary">
                    {displayDate}
                </Text>
                <Icon name="calendar" size="sm" className="text-secondary" />
            </FlexRow>
        );
    },
};

const actionCol: ColumnDef<SuppliersListItem> = {
    id: "action",
    cell: ({ row }) => {
        const { id: supplierId, lastQuestionnaire } = row.original;
        const { org_uname } = useParams();

        const { setToastSuccess, setToastError } = useSetToast();

        const { t, keys } = useSupplierTableTranslations();

        if (!lastQuestionnaire?.id || !org_uname) {
            return null;
        }

        const link = makeSupplierQuestionnaireLink({
            orgUname: org_uname,
            supplierId: supplierId,
            questionnaireId: lastQuestionnaire?.id,
        });

        const handleCopyClick = () => {
            navigator.clipboard
                .writeText(link)
                .then(() => {
                    setToastSuccess(t(keys.copy_link_success));
                })
                .catch(() => {
                    setToastError(t(keys.copy_link_error));
                });
        };

        return (
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <Button size="sm" variant="tonal" onClick={handleCopyClick}>
                        <Button.Icon name="link" />
                    </Button>
                </Tooltip.Trigger>
                <Tooltip.Content>{t(keys.copy_link)}</Tooltip.Content>
            </Tooltip>
        );
    },
};

export const useColumns = (): ColumnDef<SuppliersListItem>[] => {
    const columns = useMemo(
        () => [
            checkboxCol,
            supplierCol,
            tagsCol,
            lastQuestionnaireCol,
            lastQuestionnaireProgressCol,
            lastQuestionnaireStatusCol,
            lastUpdatedCol,
            actionCol,
        ],
        [],
    );
    return columns;
};
