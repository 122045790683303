import { StringParam, withDefault } from "use-query-params";

export const supplierTabs = {
    questionnaires: "questionnaires",
    users: "users",
} as const;

export const activeTabParamArgs = {
    activeTab: [
        "activeTab",
        withDefault(StringParam, supplierTabs.questionnaires),
    ] as const,
} as const;
