import {
    useDropdownItemStyles,
    useDropdownItemTextStyles,
} from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { LoadingSideBar } from "@design-system/Navigation/SideBar";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtomValue } from "jotai";
import { useEffect, type FC } from "react";
import { useQueryParam } from "use-query-params";
import { questionnaireLocaleAtom, questionnaireParamsArgs } from "../../../ctx";
import { useQuestionnaireGroups } from "../data";

const SidebarItemLevel2: FC<{ name: string }> = ({ name }) => {
    return (
        <div className="px-2 py-1.5 [&:not(:first-child)]:mt-4">
            <Ellipsis asChild className="max-w-full" hasTooltip>
                <Text
                    variant="body-xs"
                    className="uppercase font-bold"
                    color="secondary"
                >
                    {name}
                </Text>
            </Ellipsis>
        </div>
    );
};

const SidebarItemLevel1: FC<{ active: boolean; id: string; name: string }> = ({
    active,
    id,
    name,
}) => {
    const [, setActiveGroupId] = useQueryParam(
        ...questionnaireParamsArgs.group,
    );
    const itemClassName = useDropdownItemStyles({ active });
    const textClassName = useDropdownItemTextStyles({
        active,
    });
    const handleClick = () => {
        setActiveGroupId(id);
    };
    return (
        <Box
            className={cn(
                itemClassName,
                active && "bg-tertiary",
                "min-w-[232px]",
            )}
            onClick={handleClick}
        >
            <Ellipsis
                asChild
                className="min-w-[226px] max-w-[226px]"
                hasTooltip
            >
                <Text
                    className={cn(textClassName, active && "text-brand_hover")}
                >
                    {name}
                </Text>
            </Ellipsis>
        </Box>
    );
};
export const Sidebar: FC<{
    questionnaireId?: string;
    activeGroupId?: string | null;
}> = ({ questionnaireId, activeGroupId }) => {
    const currentLocale = useAtomValue(questionnaireLocaleAtom);
    const { groups, isPending, refetch, isRefetching } = useQuestionnaireGroups(
        questionnaireId,
        currentLocale,
    );

    useEffect(() => {
        refetch();
    }, [currentLocale, groups]);

    if (!groups.length) {
        return null;
    }

    return (
        <FlexCol className="h-window w-[232px] sticky top-0">
            <FlexCol className="h-full w-full">
                {isPending || isRefetching ? (
                    <LoadingSideBar />
                ) : (
                    groups.map((group) =>
                        group.level === 1 ? (
                            <SidebarItemLevel1
                                key={group.id}
                                id={group.id}
                                name={group.shortName ?? group.name}
                                active={activeGroupId === group.id}
                            />
                        ) : group.level === 2 ? (
                            <SidebarItemLevel2
                                key={group.id}
                                name={group.shortName ?? group.name}
                            />
                        ) : null,
                    )
                )}
            </FlexCol>
        </FlexCol>
    );
};
