import { useSetToast } from "@app/components/Toast";
import { useDisplayedPeriod } from "@app/pages/Reporting/hooks";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { orderPeriods } from "@app/usecases/ReportingUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery, ResponseStatus } from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isIndicatorSelectModalOpenAtom } from "../../ctx";
import { RESOURCE_NAME, keys } from "./i18n";

type IndicatorDisplayProps = {
    objective?: GetObjectiveQuery["objective"];
};

export const IndicatorDisplay: FC<IndicatorDisplayProps> = ({ objective }) => {
    const setIsIndicatorSelectModalOpen = useSetAtom(
        isIndicatorSelectModalOpenAtom,
    );
    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate } = useUpdateObjective(objective?.id, objective?.entityId);
    const handleEditIndicatorClick = () => {
        setIsIndicatorSelectModalOpen(true);
    };

    const latestDatapoint = useMemo(() => {
        const entityDatapoints = objective?.indicator?.datapoints?.filter(
            (dp) => dp.datapointGroup?.entityId === objective?.entityId,
        );
        const sortedDatapoints = entityDatapoints
            ?.sort((d1, d2) =>
                orderPeriods(
                    d2.datapointGroup?.period,
                    d1.datapointGroup?.period,
                ),
            )
            .filter((dp) => !dp.value.noData);
        return !!sortedDatapoints?.length ? sortedDatapoints[0] : undefined;
    }, [objective?.indicator]);

    const latestValue = latestDatapoint?.value?.float ?? "-";

    const { getDetailedDisplayedPeriod } = useDisplayedPeriod();
    const latestPeriod = latestDatapoint?.datapointGroup?.period
        ? getDetailedDisplayedPeriod(latestDatapoint?.datapointGroup?.period)
        : undefined;

    const handleDeleteIndicatorClick = () => {
        if (!objective) return;
        mutate(
            {
                id: objective.id,
                indicatorId: null,
            },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.update_success));
                    }
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return objective?.indicator ? (
        <FlexCol
            w="full"
            px="5"
            py="4"
            gap="2"
            alignItems="start"
            className="border-b border-secondary"
        >
            <FlexRow w="full" justifyContent="between">
                <FlexCol>
                    <Text variant="body" color="secondary">
                        {t(keys.indicator)}
                    </Text>
                    <Text variant="body" className="pt-2 max-w-[500px]">
                        {objective?.indicator.name}
                    </Text>
                </FlexCol>

                <FlexCol className="pl-5 justify-center">
                    <Text variant="body">{t(keys.latest_value)}</Text>
                    {!!latestDatapoint ? (
                        <FlexRow className="pt-2">
                            <Text variant="body-bold">
                                {`${latestValue} ${objective?.indicator?.unit?.shortName}`}
                            </Text>
                            <Text className="text-secondary pl-1">
                                ({latestPeriod})
                            </Text>
                        </FlexRow>
                    ) : (
                        <Text className="text-secondary pt-2">
                            {t(keys.no_latest_period)}
                        </Text>
                    )}
                </FlexCol>
                {!!objective?.targets?.length ? (
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger className="h-full">
                            <Icon
                                name="more"
                                size="sm"
                                className="text-tertiary cursor-default"
                            />
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t(keys.disabled_targets_tooltip)}
                        </Tooltip.Content>
                    </Tooltip>
                ) : (
                    <DropdownMenu>
                        <DropdownMenu.Trigger>
                            <Icon
                                name="more"
                                size="sm"
                                className="text-primary"
                            />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content align="end">
                            <DropdownMenu.Item
                                onClick={handleEditIndicatorClick}
                            >
                                <FlexRow w="full" h="full" gap="2">
                                    <DropdownMenu.ItemIcon
                                        name="edit"
                                        size="sm"
                                    />
                                    <Text variant="body-sm">
                                        {t(keys.edit)}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                className="data-[highlighted]:bg-[#FEEFEC] data-[highlighted]:text-error-500"
                                onClick={handleDeleteIndicatorClick}
                            >
                                <FlexRow w="full" h="full" gap="2">
                                    <DropdownMenu.ItemIcon
                                        name="trash"
                                        size="sm"
                                    />

                                    <Text variant="body-sm">
                                        {t(keys.delete)}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu>
                )}
            </FlexRow>
        </FlexCol>
    ) : (
        <FlexCol
            w="full"
            px="8"
            py="5"
            gap="4"
            className="bg-neutral-25"
            br="lg"
            alignItems="center"
        >
            <FlexCol gap="1" alignItems="center">
                <Text variant="body-sm" color="primary" className="font-[500]">
                    {t(keys.no_indicator_selected)}
                </Text>
                <Text variant="body-sm" color="secondary">
                    {t(keys.no_indicator_selected_hint)}
                </Text>
            </FlexCol>
            <Button
                variant="outlined"
                size="xs"
                className="w-fit rounded-xl"
                onClick={handleEditIndicatorClick}
                disabled={!!objective?.targets.length}
            >
                <FlexRow gap="1" alignItems="center">
                    <Text variant="body-sm">
                        {t(keys.no_indicator_selected_button)}
                    </Text>
                    <Icon name="plus" size="sm" />
                </FlexRow>
            </Button>
        </FlexCol>
    );
};
