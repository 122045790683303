import { type PropsWithChildren, type FC, type Ref } from "react";
import { Box, type BoxProps } from "../Box";

export type FlexProps = Omit<BoxProps, "display">;
export type OrientedFlexProps = Omit<FlexProps, "direction">;

/**Component for creating flex layouts. */
export const Flex: FC<
    PropsWithChildren<FlexProps & { ref?: Ref<HTMLDivElement> }>
> = ({ ref, ...props }) => {
    return <Box display="flex" {...props} ref={ref} />;
};

/**Flex container with horizontal layout.
 */
export const FlexRow: FC<
    PropsWithChildren<OrientedFlexProps & { ref?: Ref<HTMLDivElement> }>
> = ({ ref, ...props }) => {
    return <Flex direction="row" {...props} ref={ref} />;
};

/**Flex container with vertical layout.
 */
export const FlexCol: FC<
    PropsWithChildren<OrientedFlexProps & { ref?: Ref<HTMLDivElement> }>
> = ({ ref, ...props }) => {
    return <Flex direction="col" {...props} ref={ref} />;
};
