import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { useGraphQL } from "../../../../../QueryClientWithHeaders";
import InputSimple from "../../../../../components/InputSimple";
import { useSetToast } from "../../../../../components/Toast";

import { useEntities } from "@app/store/entityStore";
import { Button } from "@design-system/Inputs/Button";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { GetAllEntitiesDocument } from "../../../../../../generated/client/graphql";
import { useCreateEntity } from "../../data";
import { RESOURCE_NAME, keys } from "./i18n";

export const newSitesModalOpenAtom = atom<boolean>(false);

export default function NewSiteModal() {
    const { t } = useTranslation(RESOURCE_NAME);
    const { entities } = useEntities();

    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate, isPending } = useCreateEntity();
    const client = useQueryClient();
    const [open, setOpen] = useAtom(newSitesModalOpenAtom);
    const [name, setName] = useState<string | undefined>(undefined);
    const [parentId, setParentId] = useState<string | undefined>(undefined);
    const [isParent, setIsParent] = useState<boolean | undefined>(undefined);

    const parentEntity = entities?.find((entity) => entity.isParent);

    const canSave = name;
    const handleSave = () => {
        if (canSave) {
            mutate(
                { name: name, type: "site", parentId: parentId },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setOpen(false);
                        setToastSuccess(t(keys.site_create_success));
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setToastError(t(keys.site_create_error));
                    },
                },
            );
        }
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger asChild>
                <Button variant="flatPrimary" size="sm">
                    <Text variant="body-sm-bold">{t(keys.open_modal)}</Text>
                    <Button.Icon name="plus" size="sm" />
                </Button>
            </Modal.Trigger>
            <Modal.Overlay centerContent>
                <Modal.Content className="max-w-[500px]">
                    <Modal.Header className="gap-2" withClose>
                        <Modal.Title className="text-lg font-bold">
                            {t(keys.title)}
                        </Modal.Title>
                        <Modal.Subtitle>{t(keys.subtitle)}</Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol w="full" gap="3">
                            <InputSimple
                                title={t(keys.name)}
                                value={name || ""}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                            {!parentEntity ? (
                                <FlexRow h="6" alignItems="center" gap="1.5">
                                    <Checkbox
                                        checked={isParent}
                                        onChange={setIsParent}
                                    />
                                    <Text className="text-primary">
                                        {t("set_as_parent")}
                                    </Text>
                                </FlexRow>
                            ) : (
                                <FlexRow h="6" alignItems="center" gap="1.5">
                                    <Checkbox
                                        checked={parentId === parentEntity?.id}
                                        onChange={(checked) =>
                                            setParentId(
                                                checked
                                                    ? parentEntity?.id
                                                    : undefined,
                                            )
                                        }
                                    />
                                    <Text className="text-primary">
                                        {t("set_as_children", {
                                            entity: parentEntity?.name,
                                        })}
                                    </Text>
                                </FlexRow>
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            onClick={handleSave}
                            disabled={!canSave}
                            loading={isPending}
                        >
                            {t(keys.save)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
}
