import { useSetToast } from "@app/components/Toast";
import { useThemes } from "@app/store/themeStore";
import { useUnits } from "@app/store/unitStore";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { createCustomIndicatorModalIsOpenAtom } from "../../context";
import { SelectDropdown } from "../SelectDropdown";
import { useCreateIndicator } from "./data";
import { useCustomIndicatorForm } from "./hooks/useCustomIndicatorForm";
import { useCreateCustomIndicatorModalTranslations } from "./i18n";

export const CreateCustomIndicatorModal = () => {
    const [open, setOpen] = useAtom(createCustomIndicatorModalIsOpenAtom);

    const { t, keys } = useCreateCustomIndicatorModalTranslations();

    const { setToastSuccess, setToastError } = useSetToast();

    const { mutate, isPending } = useCreateIndicator();

    const { themes } = useThemes();
    const { units } = useUnits();

    const { form, isValid, handleInputChange, resetForm } =
        useCustomIndicatorForm();

    const theme = useMemo(() => {
        return themes?.find((theme) => theme.id === form.selectedThemeId);
    }, [themes, form.selectedThemeId]);

    const subThemes = useMemo(() => theme?.subthemes || [], [theme]);

    if (!themes || !units) return null;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChange("name", e.target.value);
    };

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        handleInputChange("description", e.target.value);
    };

    const handleThemeChange = (value: string) => {
        handleInputChange("selectedThemeId", value);
    };

    const handleSubthemeChange = (value: string) => {
        handleInputChange("selectedSubthemesId", value);
    };

    const handleUnitChange = (value: string) => {
        handleInputChange("cmsUnitId", value);
    };

    const onSubmit = () => {
        mutate(
            {
                themeId: form.selectedThemeId,
                subthemeId: form.selectedSubthemesId,
                name: form.name,
                description: form.description,
                cmsUnitId: form.cmsUnitId,
            },
            {
                onSuccess: () => {
                    setOpen(false);
                    resetForm();
                    setToastSuccess(t(keys.success));
                },
                onError: (error) => {
                    console.error("Failed to create indicator:", error);
                    setToastError(t(keys.error));
                },
            },
        );
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay centerContent className="py-5">
                <Modal.Content className="max-w-md overflow-hidden">
                    <Modal.Header className="py-4 px-5" withClose>
                        <FlexRow w="full" gap="2">
                            <Text variant="header3">{t(keys.title)}</Text>
                        </FlexRow>
                    </Modal.Header>

                    <Modal.Body>
                        <FlexCol gap="3">
                            <Textfield
                                placeholder={t(keys.name)}
                                value={form.name}
                                onChange={handleNameChange}
                            />

                            <Textfield
                                placeholder={t(keys.description)}
                                value={form.description}
                                onChange={handleDescriptionChange}
                            />

                            <SelectDropdown
                                items={themes}
                                selectedValue={form.selectedThemeId}
                                setSelectedValue={handleThemeChange}
                                placeholder={t(keys.selectTheme)}
                            />

                            {subThemes.length > 0 && (
                                <SelectDropdown
                                    items={subThemes}
                                    selectedValue={form.selectedSubthemesId}
                                    setSelectedValue={handleSubthemeChange}
                                    placeholder={t(keys.selectSubtheme)}
                                />
                            )}

                            <SelectDropdown
                                items={units}
                                selectedValue={form.cmsUnitId}
                                setSelectedValue={handleUnitChange}
                                placeholder={t(keys.selectUnit)}
                            />
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.ConfirmCTA
                            type="submit"
                            disabled={isPending || !isValid}
                            onClick={onSubmit}
                        >
                            {t(keys.save)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
