import { Table, usePagination } from "@design-system/Table";
import { useAtomValue } from "jotai";
import { useCsrdDatapoints } from "../../data";
import { columns } from "./columns";
import { filterAtom } from "./ctx";

export const DataTab = () => {
    const { pagination, skip, take } = usePagination({ initialSize: 50 });

    const filter = useAtomValue(filterAtom);

    const { enrichedDatapoints, count, isPending } = useCsrdDatapoints(filter, {
        skip,
        take,
    });

    return (
        <Table
            columns={columns}
            data={enrichedDatapoints ?? []}
            rowCount={count}
            pagination={pagination}
            loading={isPending}
            variant="compact"
        />
    );
};
