import { useQuestionnaireHeaderData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import {
    canBeAccessed,
    isQuestionnaireFramework,
    releaseStatus,
} from "@app/screens/Frameworks/services";
import { type FrameworkItem } from "@app/screens/Frameworks/types";
import { cn } from "@design-system/Utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCsrdStats } from "../../framework/Csrd/data";
import { headerBgSvgDataUrl } from "./utils";

export type FrameworkCardProps = {
    framework: FrameworkItem;
};

export function useFrameworkCard({ framework }: FrameworkCardProps) {
    const { t } = useTranslation("Frameworks");

    const betaOrReleased = [
        releaseStatus.released,
        releaseStatus.beta,
    ].includes(framework.releaseStatus);
    const { completionStats: csrdCompletionStats } = useCsrdStats();

    const isReleasedQuestionnaire =
        isQuestionnaireFramework(framework.cmsId) &&
        framework.releaseStatus === releaseStatus.released &&
        canBeAccessed(framework);

    const { stats: questionnaireStats } = useQuestionnaireHeaderData(
        isReleasedQuestionnaire ? framework.id : undefined,
    );
    const formattedQuestionnaireStats = {
        percentage: questionnaireStats?.validatedQuestions?.percentage || 0,
        total: questionnaireStats?.totalQuestions || 0,
        completed: questionnaireStats?.validatedQuestions?.count || 0,
    };

    const progress = useMemo(() => {
        if (framework?.cmsId === "csrd") {
            return csrdCompletionStats?.percentage || 0;
        }
        if (isQuestionnaireFramework(framework.cmsId)) {
            return formattedQuestionnaireStats.percentage || 0;
        }
        return framework.stats.reqTotal > 0
            ? (100 * framework.stats.reqOk) / framework.stats.reqTotal
            : 0;
    }, [framework.stats.reqOk, framework.stats.reqTotal, csrdCompletionStats]);

    const wrapperClass = cn(
        canBeAccessed(framework)
            ? "no-underline cursor-pointer"
            : "cursor-not-allowed",
        (framework.releaseStatus === releaseStatus.beta ||
            (framework.releaseStatus === releaseStatus.released &&
                !canBeAccessed(framework))) &&
            "opacity-50",
        "border border-secondary",
        "overflow-hidden",
    );

    const headerBgClassColor = framework.color?.primary || "bg-accent-900";
    const headerClass = betaOrReleased ? headerBgClassColor : "bg-tertiary";
    const headerStyle = betaOrReleased
        ? {
              backgroundImage: `url(${headerBgSvgDataUrl}), linear-gradient(to right, rgba(3, 163, 101, 1), rgba(3, 163, 101, 0.8))`,
              backgroundSize: "auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
          }
        : undefined;

    const titleClass = betaOrReleased ? "text-neutral-25" : "text-secondary";

    const subtitleClass = betaOrReleased
        ? "text-neutral-25 opacity-50"
        : "text-tertiary";

    return {
        betaOrReleased,
        headerClass,
        headerStyle,
        progress,
        subtitleClass,
        t,
        titleClass,
        wrapperClass,
        completionStats: isQuestionnaireFramework(framework.cmsId)
            ? formattedQuestionnaireStats
            : csrdCompletionStats,
    };
}
