import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetThemesAndSubthemesQuery } from "@generated/client/graphql";
import chroma from "chroma-js";
import { FC } from "react";
import { useObjectiveWorkPlanTranslations } from "../i18n";

interface ThemeHeaderProps {
    theme: GetThemesAndSubthemesQuery["themes"][number];
    progress: number;
    completedSubthemes: number;
    totalSubthemes: number;
}

export const ThemeHeader: FC<ThemeHeaderProps> = ({
    theme,
    progress,
    completedSubthemes,
    totalSubthemes,
}) => {
    const { t, keys } = useObjectiveWorkPlanTranslations();
    return (
        <FlexCol w="full" gap="4" p="2">
            <FlexRow w="full" justifyContent="between" alignItems="start">
                <FlexCol gap="2" w="full">
                    <FlexRow alignItems="center" gap="2">
                        <Icon name="tagCircle" size="sm" color={theme.color} />
                        <Text variant="body-bold">{theme.name}</Text>
                    </FlexRow>
                    <ProgressBar
                        className="w-full ml-1"
                        segments={[
                            {
                                value: progress,
                                labelValue: `${progress}%`,
                                color: chroma("#03A365"),
                                label: "Progress",
                            },
                        ]}
                        total={100}
                    />
                </FlexCol>
            </FlexRow>
            <FlexRow className="ml-1">
                <Text variant="body" className="text-neutral-600">
                    {t(keys.subtheme_completed, {
                        completed: completedSubthemes,
                        total: totalSubthemes,
                    })}
                </Text>
            </FlexRow>
        </FlexCol>
    );
};
