import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useLastQuestionnaireBoxTranslations } from "./i18n";

export const BoxSkeleton = () => {
    const { t, keys } = useLastQuestionnaireBoxTranslations();

    return (
        <FlexCol br="lg" w="full" className="border border-brand">
            <FlexRow
                px="4"
                h="8"
                alignItems="center"
                className="bg-[#EEFFF2] rounded-t-lg border-b border-secondary"
            >
                <Text variant="body-sm-bold" color="brand">
                    {t(keys.last_questionnaire)}
                </Text>
            </FlexRow>
            <FlexRow px="5" py="4" gap="5" alignItems="center">
                <FlexCol gap="3" w="full" alignItems="center">
                    <FlexRow
                        w="full"
                        alignItems="center"
                        justifyContent="between"
                    >
                        <FlexRow gap="1.5" alignItems="center">
                            <Skeleton w="5" h="4" />
                            <Skeleton w="20" h="4" />
                        </FlexRow>
                        <FlexRow gap="1.5" alignItems="center">
                            <Skeleton w="20" h="4" />
                            <Skeleton w="5" h="4" />
                        </FlexRow>
                        <FlexRow gap="1.5" alignItems="center">
                            <Skeleton w="5" h="4" />
                            <Skeleton w="20" h="4" />
                        </FlexRow>
                        <FlexRow gap="1.5" alignItems="center">
                            <Skeleton w="5" h="4" />
                            <Skeleton w="20" h="4" />
                        </FlexRow>
                        <FlexRow gap="1.5" alignItems="center">
                            <Skeleton w="5" h="4" />
                            <Skeleton w="20" h="4" />
                        </FlexRow>
                    </FlexRow>
                    <FlexRow w="full" className="[&>div]:w-full">
                        <Skeleton w="full" h="2" br="xl" />
                    </FlexRow>
                </FlexCol>
                <FlexRow gap="1.5" alignItems="center">
                    <Button
                        size="sm"
                        variant="outlined"
                        className="max-h-[26px]"
                    >
                        <Button.Icon name="link" size="sm" />
                    </Button>
                    <Button variant="outlined" size="sm">
                        {t(keys.close)}
                    </Button>
                </FlexRow>
            </FlexRow>
        </FlexCol>
    );
};
