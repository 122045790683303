import { SupportedLocales } from "@app/shared/utils/locales";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { ClassValue, cn } from "@design-system/Utilities";
import { QuestionType } from "@generated/client/graphql";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import { type UpsertQuestionInput } from "../../types";
import { QuestionModalCard } from "./QuestionModalCard";

type NameAndDescriptionBlockProps = {
    questionInput: UpsertQuestionInput;
    locale: SupportedLocales;
    questionType?: QuestionType;
    onUpdate: (field: keyof UpsertQuestionInput, value: string) => void;
    onUpdateLocale: (
        field: "nameMultiLocale" | "descriptionMultiLocale",
        value: string,
    ) => void;
    className?: ClassValue;
};
export const NameAndDescriptionBlock = ({
    questionInput,
    locale,
    questionType,
    onUpdate,
    onUpdateLocale,
    className,
}: NameAndDescriptionBlockProps) => {
    const handleQuestionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onUpdateLocale("nameMultiLocale", e.target.value);
    };

    const handleUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
        onUpdate("unit", e.target.value);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onUpdateLocale("descriptionMultiLocale", e.target.value);
    };

    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <QuestionModalCard
            className={cn(
                "flex flex-col gap-3 rounded-tl-none -mt-px",
                className,
            )}
        >
            <Box>
                <Textarea
                    className="resize-y"
                    onChange={handleQuestionChange}
                    rows={2}
                    value={questionInput.nameMultiLocale?.[locale] ?? ""}
                    placeholder={t(
                        keys.create_questions_modal_question_name_label,
                    )}
                />
            </Box>
            {questionType === QuestionType.Value && (
                <Box>
                    <Textfield
                        placeholder={t(
                            keys.create_questions_modal_question_unit_label,
                        )}
                        onChange={handleUnitChange}
                        value={questionInput?.unit ?? ""}
                    />
                </Box>
            )}
            <Box>
                <Textarea
                    className="resize-y"
                    onChange={handleDescriptionChange}
                    rows={3}
                    value={questionInput.descriptionMultiLocale?.[locale] ?? ""}
                    placeholder={t(
                        keys.create_questions_modal_question_description_label,
                    )}
                />
            </Box>
        </QuestionModalCard>
    );
};
