import { Trash } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResetDocumentDocument } from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import ActionWithConfirmation from "../../../../components/Modal/ActionWithConfirmation";

const ResetWithConfirmation = ({ documentId }: { documentId: string }) => {
    const { t } = useTranslation("DocumentScreen");
    const resetDocumentMutation = useGraphQLMutation(ResetDocumentDocument);
    const navigate = useNavigate();

    const onReset = () => {
        documentId &&
            resetDocumentMutation.mutate(
                {
                    documentId: documentId,
                },
                { onSuccess: () => navigate("..") },
            );
    };
    return (
        <ActionWithConfirmation
            title={t("reset_title")}
            confirmButton={
                <ButtonRegular
                    variant={ButtonType.FLAT_DANGER}
                    loading={resetDocumentMutation.isPending}
                    onClick={onReset}
                >
                    {t("reset_confirmation")}
                </ButtonRegular>
            }
            TriggerButton={
                <BaseItem
                    IconLeft={() => <Trash className="text-red-600 w-4 h-4" />}
                >
                    {t("reset")}
                </BaseItem>
            }
        >
            <span>{t("reset_description_line1")}</span>
            <br />
            <b>{t("reset_description_line2")}</b>
        </ActionWithConfirmation>
    );
};

export default ResetWithConfirmation;
