import { FlexCol } from "@design-system/Layout/Flex";
import {
    Table,
    usePaginatedSelection,
    usePagination,
    useSorting,
} from "@design-system/Table";
import { useEffect, type FC } from "react";
import { useQueryParam } from "use-query-params";
import { activeFilterParamArgs } from "../../ctx";
import { useSuppliersList, useSuppliersMinimal } from "../../data";
import { type SupplierSortKey } from "../../types";
import { ActionBar } from "./ActionBar";
import { useColumns } from "./columns";

export const SupplierTable: FC = () => {
    const columns = useColumns();

    const { pagination, take, skip } = usePagination();
    const { sorting, field, order } = useSorting<SupplierSortKey>(
        "createdAt",
        "desc",
    );

    const [statusFilter] = useQueryParam(...activeFilterParamArgs.statusFilter);
    const [tagFilter] = useQueryParam(...activeFilterParamArgs.tagFilter);
    const [searchFilter] = useQueryParam(...activeFilterParamArgs.searchFilter);

    const { suppliers, isPending } = useSuppliersList(
        {
            search: searchFilter,
            tags: tagFilter?.filter(Boolean) as string[],
            lastQuestionnaireStatus: statusFilter,
        },
        {
            skip,
            take,
        },
        {
            [field]: order,
        } as const,
    );

    const {
        suppliers: suppliersMinimal,
        isPending: isSuppliersMinimalPending,
    } = useSuppliersMinimal(
        {
            search: searchFilter,
            tags: tagFilter?.filter(Boolean) as string[],
            lastQuestionnaireStatus: statusFilter,
        },
        {
            [field]: order,
        } as const,
    );

    const { paginatedSelection, resetSelection } = usePaginatedSelection(
        suppliers?.list,
    );

    useEffect(() => {
        pagination.onPaginationChange({
            pageIndex: 0,
            pageSize: pagination.state.pageSize,
        });
        resetSelection();
    }, [searchFilter, tagFilter, statusFilter]);

    return (
        <FlexCol w="full">
            <Table
                columns={columns}
                data={suppliers?.list ?? []}
                pagination={pagination}
                loading={isPending}
                sorting={sorting}
                paginatedSelection={paginatedSelection}
                rowCount={suppliers?.count}
            />
            <ActionBar
                unset={resetSelection}
                paginatedSelectedRows={paginatedSelection.paginatedSelectedRows}
                rowCount={suppliers?.count}
                suppliers={suppliersMinimal ?? []}
                isSuppliersPending={isSuppliersMinimalPending}
            />
        </FlexCol>
    );
};
