import { Header } from "@app/screens/Supplier/Suppliers/supplier/components/SupplierHeader/Header";
import {
    useSupplier,
    useSupplierIcon,
} from "@app/screens/Supplier/Suppliers/supplier/data";
import { FlexCol } from "@design-system/Layout/Flex";
import { type FC } from "react";

export const SupplierHeader: FC<{
    description?: string;
    supplierId?: string;
}> = ({ description, supplierId }) => {
    const { supplier, isPending } = useSupplier(supplierId);

    const { iconUrl, isPending: isIconPending } = useSupplierIcon(
        supplierId,
        supplier?.name,
    );

    return (
        <Header>
            {isPending ? (
                <>
                    <Header.LogoSkeleton />
                    <FlexCol gap="3">
                        <Header.TitleSkeleton />
                        <Header.SubtitleSkeleton />
                    </FlexCol>
                </>
            ) : (
                <>
                    {isIconPending ? (
                        <Header.LogoSkeleton />
                    ) : (
                        <Header.Logo
                            imageUrl={iconUrl}
                            letter={supplier?.name?.[0] ?? ""}
                        />
                    )}
                    <FlexCol gap="3" w="full">
                        <Header.Title>{supplier?.name}</Header.Title>
                        {description && (
                            <Header.Subtitle>{description}</Header.Subtitle>
                        )}
                    </FlexCol>
                </>
            )}
        </Header>
    );
};
