import BodyHeader from "@app/shared/components/BodyHeader";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Icon, IconName } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { FC } from "react";
import { useQuestionnaireTemplates } from "../../data";
import { QuestionnaireTemplate } from "../../types";
import { CreateQuestionnaireModal } from "../CreateQuestionnaireModal";
import { QuickAnswerModal } from "../QuickAnswerModal";
import { CreateQuestionnaireFromUploadModal } from "./CreateQuestionnaireFromUploadModal";
import {
    quickAnswerBackgroundSvgDataUrl,
    uploadFileBackgroundSvgDataUrl,
} from "./dahsboard-card-backgrounds";
import { useQuestionnairesDashboardTranslations } from "./i18n";
export const QuestionnairesDashboard: FC = () => {
    const { t, keys } = useQuestionnairesDashboardTranslations();
    const { templates, isPending } = useQuestionnaireTemplates();

    const dashboardCardsContent: DashboardCardContent[] = [
        {
            title: t(keys.quick_answer_card_title),
            description: t(keys.quick_answer_card_description),
            backgroundImg: `url(${quickAnswerBackgroundSvgDataUrl}), linear-gradient(#C238CC, #B554F2)`,
            icon: "quote" as IconName,
            iconColor: "#C238CC",
            action: "quick-answer" as CardAction,
        },
        {
            title: t(keys.upload_file_card_title),
            description: t(keys.upload_file_card_description),
            backgroundImg: `url(${uploadFileBackgroundSvgDataUrl}), linear-gradient(#03A365, #03A365)`,
            icon: "upload2" as IconName,
            iconColor: "#03A365",
            action: "upload-file" as CardAction,
        },
    ];
    const dashboardTemplateCardsContent: DashboardCardContent[] = [
        ...(templates ?? []).slice(0, 3).map(
            (template) =>
                ({
                    title: template.name ?? t(keys.template_card_title),
                    description: t(keys.template_card_description, {
                        templateName: template.name,
                    }),
                    icon: "file" as IconName,
                    action: "template" as CardAction,
                    template: template,
                }) as DashboardCardContent,
        ),
        {
            title: t(keys.template_card_title),
            description: t(keys.all_templates_card_description),
            icon: "file" as IconName,
            action: "all-templates" as CardAction,
        },
    ];

    return (
        <PageContainer>
            <BodyHeader
                title={t(keys.page_title)}
                subTitle={t(keys.page_subtitle)}
                action={
                    <FlexRow gap="4">
                        <CreateQuestionnaireModal />
                    </FlexRow>
                }
            />
            <FlexRow gap="4" p="4" className="overflow-x-scroll scrollbar-hide">
                <QuestionnaireCards
                    dashboardCardsContent={dashboardCardsContent}
                />
                <QuestionnaireCards
                    loading={isPending}
                    dashboardCardsContent={dashboardTemplateCardsContent}
                />
            </FlexRow>
        </PageContainer>
    );
};

const QuestionnaireCards: FC<{
    loading?: boolean;
    dashboardCardsContent: DashboardCardContent[];
}> = ({ loading, dashboardCardsContent }) => {
    return (
        <FlexRow gap="4">
            {loading ? (
                <Skeleton className="w-[256px]" />
            ) : (
                dashboardCardsContent.map((card) => (
                    <FlexCol
                        p="4"
                        alignItems="start"
                        justifyContent="between"
                        gap="2"
                        className={cn(
                            "min-w-[256px] w-[256px] border rounded-xl box-shadow-b-100",
                        )}
                        style={{
                            backgroundImage: card.backgroundImg,
                        }}
                        key={card.title}
                    >
                        <FlexRow gap="2" alignItems="center">
                            <span
                                className={cn(
                                    !card.backgroundImg &&
                                        "border border-secondary",
                                    "bg-white rounded-full p-2",
                                )}
                            >
                                <Icon
                                    name={card.icon}
                                    size="sm"
                                    style={{
                                        color: card.iconColor || "#404040",
                                    }}
                                />
                            </span>
                            <Text
                                variant="body-lg-bold"
                                className={cn(
                                    card.backgroundImg
                                        ? "text-white"
                                        : "text-primary",
                                )}
                            >
                                {card.title}
                            </Text>
                        </FlexRow>
                        <Text
                            className={cn(
                                card.backgroundImg
                                    ? "text-white"
                                    : "text-secondary",
                            )}
                        >
                            {card.description}
                        </Text>
                        <QuestionnaireCardAction
                            action={card.action}
                            template={card.template}
                        />
                    </FlexCol>
                ))
            )}
        </FlexRow>
    );
};

const QuestionnaireCardAction: FC<{
    action: CardAction;
    template?: QuestionnaireTemplate;
}> = ({ action, template }) => {
    return action === "quick-answer" ? (
        <QuickAnswerModal />
    ) : action === "upload-file" ? (
        <CreateQuestionnaireFromUploadModal />
    ) : action === "all-templates" ? (
        <CreateQuestionnaireModal isTemplateOnly />
    ) : (
        action === "template" && (
            <CreateQuestionnaireModal isTemplateOnly templateToUse={template} />
        )
    );
};

type DashboardCardContent = {
    title: string;
    description: string;
    icon: IconName;
    action: CardAction;
    backgroundImg?: string;
    iconColor?: string;
    template?: QuestionnaireTemplate;
};

type CardAction = "quick-answer" | "upload-file" | "template" | "all-templates";
