import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { useCurrentUserLocale } from "@app/shared/utils/currentUserLocale";
import "@app/shared/utils/locales";
import {
    getFlagByLocale,
    keys,
    RESOURCE_NAME,
    SupportedLocales,
} from "@app/shared/utils/locales";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom } from "jotai";
import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { questionnaireLocaleAtom } from "../../ctx";
import { useQuestionnaireLocale } from "../../data";

export const QuestionnaireLocaleDropdown: FC<
    PropsWithChildren<{
        questionnaireId?: string;
        variant?: "light" | "dark";
    }>
> = ({ questionnaireId, children, variant = "light" }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { availableLocales } = useQuestionnaireLocale(questionnaireId);

    const [currentLocale, setCurrentLocale] = useAtom(questionnaireLocaleAtom);
    const userLocale = useCurrentUserLocale(SupportedLocales.EN);

    const isOnlyOneLocale = availableLocales.length === 1;

    if (children && isOnlyOneLocale)
        return (
            <Text
                className="flex items-center gap-2 p-2"
                color={variant === "dark" ? "white" : "primary"}
                variant="body-sm-bold"
            >
                <Icon
                    name={getFlagByLocale(currentLocale ?? userLocale)}
                    size="sm"
                />
                {t(keys[currentLocale ?? userLocale])}
            </Text>
        );

    return (
        <LocaleSelect
            value={currentLocale ?? userLocale}
            onChange={setCurrentLocale}
            options={availableLocales}
        >
            {children ?? (
                <Button
                    className={cn(
                        "w-fit",
                        variant === "dark" &&
                            "text-white hover:bg-opacity-30 active:bg-opacity-30",
                    )}
                    variant="text"
                    size="sm"
                >
                    <Button.Icon
                        className="mr-2"
                        name={getFlagByLocale(currentLocale ?? userLocale)}
                    />
                    {t(keys[currentLocale ?? userLocale])}
                    <Button.Icon name="angleDown" />
                </Button>
            )}
        </LocaleSelect>
    );
};
