import { useSetToast } from "@app/components/Toast";
import TagListEditor from "@app/shared/components/TagListEditor";
import { useOptimistic } from "@app/shared/utils/optimisticUpdates";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { ResponseStatus, TagType } from "@generated/client/graphql";
import { useMemo, type FC } from "react";
import { useParams } from "react-router-dom";
import { useSupplier, useSupplierIcon, useUpdateSupplier } from "../../data";
import { Header } from "./Header";
import { useSupplierHeaderTranslations } from "./i18n";

export const SupplierHeader: FC = () => {
    const { supplier_id } = useParams();
    const { supplier, isPending } = useSupplier(supplier_id);

    const { t, keys } = useSupplierHeaderTranslations();

    const { updateSupplier } = useUpdateSupplier();
    const tagIds = useMemo(
        () => supplier?.tags?.map((tag) => tag.id),
        [supplier],
    );

    const [optimisticTagIds, setOptimisticTagIds] = useOptimistic(tagIds);

    const { setToastError } = useSetToast();

    const handleTagChange = (tagIds: string[]) => {
        if (!supplier_id) return;
        setOptimisticTagIds(tagIds);
        updateSupplier(
            {
                id: supplier_id,
                set: {
                    tagIds,
                },
            },
            {
                onSettled(data, error) {
                    if (
                        error ||
                        data?.updateSupplier?.status === ResponseStatus.Error
                    ) {
                        setToastError(t(keys.toast_error_update_supplier));
                    }
                },
            },
        );
    };

    const { iconUrl, isPending: isIconPending } = useSupplierIcon(
        supplier_id,
        supplier?.name,
    );

    return (
        <Header>
            {isPending ? (
                <>
                    <Header.LogoSkeleton />
                    <FlexCol gap="3">
                        <Header.TitleSkeleton />
                        <Header.SubtitleSkeleton />
                    </FlexCol>
                </>
            ) : (
                <>
                    {isIconPending ? (
                        <Header.LogoSkeleton />
                    ) : (
                        <Header.Logo
                            imageUrl={iconUrl}
                            letter={supplier?.name?.[0] ?? ""}
                        />
                    )}
                    <FlexCol gap="3" w="full">
                        <Header.Title>{supplier?.name}</Header.Title>
                        <Header.Subtitle>{t(keys.subtitle)}</Header.Subtitle>
                        <Box w="1/2">
                            <TagListEditor
                                values={optimisticTagIds}
                                onValuesChange={handleTagChange}
                                tagType={TagType.Supplier}
                            />
                        </Box>
                    </FlexCol>
                </>
            )}
        </Header>
    );
};
