import { reportingTableColumns } from "@app/usecases/ReportingUseCases";
import { Button } from "@design-system/Inputs/Button";
import SearchBar from "@design-system/Inputs/SearchBar";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { activeReportingTableColumnsAtom } from "../../context";
import "./i18n";

const ReportingFilters: FC<PropsWithChildren> = (props) => {
    const { t } = useTranslation("ReportingFilters");
    const [activeReportingTableColumns, setActiveReportingTableColumns] =
        useAtom(activeReportingTableColumnsAtom);
    const [urlSearchString, setUrlSearchString] = useQueryParam(
        "search",
        StringParam,
    );
    return (
        <FlexRow
            justifyContent="between"
            alignItems="center"
            alignSelf="stretch"
        >
            <FlexRow alignItems="center" gap="5">
                {props?.children}
            </FlexRow>
            <FlexRow gap="4">
                <SearchBar
                    searchString={urlSearchString || undefined}
                    setSearchString={setUrlSearchString}
                    placeholder={t("search_placeholder")}
                />
                <Dropdown
                    selectType="multi"
                    value={activeReportingTableColumns.filter((v) => v != null)}
                    onValueChange={setActiveReportingTableColumns}
                    modal
                    closeOnValueChange={false}
                >
                    <Dropdown.Trigger asChild>
                        <Button size="sm" variant="tonal">
                            {t("select_columns")}
                            <Button.Icon name="angleDown" />
                        </Button>
                    </Dropdown.Trigger>
                    <Dropdown.Content hasPortal>
                        {reportingTableColumns.map((column) => (
                            <Dropdown.SelectableItem
                                key={column}
                                value={column}
                            >
                                <FlexRow
                                    alignItems="center"
                                    alignSelf="stretch"
                                    gap="3"
                                >
                                    <Dropdown.Checkbox />
                                    <Dropdown.ItemText>
                                        <Text className="capitalize">
                                            {t(column)}
                                        </Text>
                                    </Dropdown.ItemText>
                                </FlexRow>
                            </Dropdown.SelectableItem>
                        ))}
                    </Dropdown.Content>
                </Dropdown>
            </FlexRow>
        </FlexRow>
    );
};

export default ReportingFilters;
