import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-questions-header";

const keys = addTranslations(RESOURCE_NAME, {
    search: {
        en: "Search question",
        fr: "Rechercher une question",
    },
});

export function useSupplierQuestionsHeaderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
