import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    Table,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useColumnPinning,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { Indicator, User } from "generated/client/graphql";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import {
    selectedActiveFrameworkIdsAtomFamily,
    selectedSubthemesAtomFamily,
    selectedTagsAtomFamily,
    selectedUsersAtomFamily,
} from "../../context";
import { useActiveReportingIndicators } from "../../data";
import "../../i18n";
import ReportingTableActionBar from "./actionBar";
import { pinnedColumnsIds, useColumns } from "./columns";
import { selectedTierAtom } from "./ctx";
import SendReminderModal from "./sendReminderModal";

const IndicatorsTable: FC = () => {
    const { org_uname } = useParams();
    const { t } = useTranslation("ReportingPage");
    const columns = useColumns();
    const { data, isPending } = useActiveReportingIndicators();
    const [selectedUserIds] = useAtom(selectedUsersAtomFamily(org_uname));
    const [selectedSubthemesIds] = useAtom(
        selectedSubthemesAtomFamily(org_uname),
    );
    const [selecteTagIds] = useAtom(selectedTagsAtomFamily(org_uname));
    const [selectedFrameworkIds] = useAtom(
        selectedActiveFrameworkIdsAtomFamily(org_uname),
    );
    const [urlSearchString] = useQueryParam("search", StringParam);
    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const { columnPinning } = useColumnPinning({
        initialColumnPinningState: {
            left: pinnedColumnsIds,
        },
    });

    const selectedTier = useAtomValue(selectedTierAtom);

    const columnFilters = useMemo(() => {
        const filters = [];

        if (selectedSubthemesIds.length) {
            filters.push({ id: "subtheme", value: selectedSubthemesIds });
        }

        if (selectedFrameworkIds.length) {
            filters.push({ id: "frameworks", value: selectedFrameworkIds });
        }

        if (selectedUserIds.length) {
            filters.push({ id: "assignedTo", value: selectedUserIds });
        }

        if (selecteTagIds.length) {
            filters.push({ id: "tags", value: selecteTagIds });
        }

        if (selectedTier) {
            filters.push({ id: "tier", value: selectedTier });
        }

        return filters;
    }, [
        selectedUserIds,
        selectedSubthemesIds,
        selecteTagIds,
        selectedFrameworkIds,
        selectedTier,
    ]);

    const memoData = useMemo(
        () =>
            (data?.sort((i1, i2) => i1?.order - i2?.order) ||
                []) as Indicator[],
        [data],
    );

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            columnFilters,
            pagination,
            columnVisibility: { tier: false },
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 });
    }, [
        selectedSubthemesIds,
        selectedFrameworkIds,
        selectedUserIds,
        selecteTagIds,
        setPagination,
        urlSearchString,
        selectedTier,
    ]);

    const selectedIndicators = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return memoData[Number(idx)];
        });

    const selectedUsers = selectedIndicators
        .map((elt) => elt?.entityConfig?.assignedTo)
        .filter((elt) => elt?.id) as User[];
    const uniqueSelectedUsers = [
        ...new Map(selectedUsers.map((item) => [item.id, item])).values(),
    ];

    const unset = () => setRowSelection({});

    return (
        <FlexCol w="full">
            <Table
                columnPinning={columnPinning}
                columns={columns}
                data={memoData}
                loading={isPending}
                options={tableOptions}
                variant="grid"
                className={cn(
                    memoData.length === 0 &&
                        !isPending &&
                        "rounded-b-none border-b-0",
                    "mb-0 pb-0",
                )}
            />
            {memoData.length === 0 && !isPending && (
                <FlexCol
                    py="10"
                    justifyContent="center"
                    alignItems="center"
                    w="full"
                    className="bg-neutral-25 rounded-sm border-b border-r border-l border-neutral-150 rounded-t-none"
                >
                    <FlexCol gap="2" alignItems="center" className="my-8">
                        <Icon name="search" />
                        <FlexCol gap="1" alignItems="center">
                            <Text variant="body-sm-bold">
                                {t("no_indicator_found_title")}
                            </Text>
                            <Text variant="body-sm" className="font-light">
                                {t("no_indicator_found_subtitle")}
                            </Text>
                        </FlexCol>
                    </FlexCol>
                </FlexCol>
            )}
            <ReportingTableActionBar
                indicators={selectedIndicators || []}
                unset={unset}
            />
            <SendReminderModal users={uniqueSelectedUsers}></SendReminderModal>
        </FlexCol>
    );
};

export default IndicatorsTable;
