import { Search } from "iconoir-react";
import { atom } from "jotai";
import { useTranslation } from "react-i18next";
import { cn } from "../../../lib/utils";
import "./i18n";

export const searchFilterStringAtom = atom<string | undefined>(undefined);
export default function SearchFilter({
    searchString,
    setSearchString,
    placeholder,
    className,
}: {
    searchString: string | undefined;
    setSearchString: (searchString: string | undefined) => void;
    placeholder?: string;
    className?: string;
}) {
    const { t } = useTranslation("SearchFilter");
    return (
        <div
            className={cn(
                "w-[160px] rounded-lg border border-secondary h-[28px] px-1 box-content flex space-x-1 items-center",
                searchString ? "border-beavrGreen" : "border-secondary",
                className,
            )}
        >
            <div className="p-0.5">
                <Search
                    className={cn(
                        "w-3 h-3",
                        searchString ? "text-beavrGreen" : "text-secondary",
                    )}
                />
            </div>
            <input
                className="text-sm w-full border-0 p-0 focus:outline-none focus:ring-0 m-0 "
                value={searchString || ""}
                onChange={(e) => {
                    const newVal = e.target.value.trimStart();
                    setSearchString(newVal ? newVal : undefined);
                }}
                placeholder={placeholder ? placeholder : t("placeholder")}
            />
        </div>
    );
}
