import { GraphQLSupplierRepository } from "@app/repositories/GraphQLRepositories/SupplierRepository";
import { Pagination } from "@app/shared/types/API.types";
import { skipToken, useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { supplierQuestionnairesKeys } from "../data";
import { makeQuesitonnaire } from "./services";
import { queryClient } from "@app/QueryClientWithHeaders";

const supplierRepository = new GraphQLSupplierRepository();

export function useSupplierQuestionnaire(
    questionnaireId?: string,
    supplierId?: string,
) {
    const { data, ...query } = useQuery({
        queryKey: supplierQuestionnairesKeys.one(questionnaireId, supplierId),
        queryFn:
            questionnaireId && supplierId
                ? () =>
                      supplierRepository.getSupplierQuestionnaire(
                          questionnaireId,
                          supplierId,
                      )
                : skipToken,
    });

    const questionnaire = useMemo(() => {
        return makeQuesitonnaire(data?.supplierQuestionnaire2);
    }, [data]);

    return { questionnaire, ...query };
}

export function useSupplierQuestionnaireQuestions(
    questionnaireId?: string,
    supplierId?: string,
    pagination?: Pagination,
) {
    const { data, ...query } = useQuery({
        queryKey: supplierQuestionnairesKeys.questions(
            questionnaireId,
            supplierId,
            pagination,
        ),
        queryFn:
            questionnaireId && supplierId
                ? () =>
                      supplierRepository.getSupplierQuestionnaireQuestions(
                          questionnaireId,
                          supplierId,
                          pagination,
                      )
                : skipToken,
    });

    return { questions: data?.questionnaire?.questions?.data, ...query };
}

export function useUpsertSupplierQuestionnaireQuestionAnswer() {
    return useMutation({
        mutationFn: supplierRepository.upsertSupplierQuestionnaireQuestionAnswer,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierQuestionnairesKeys.oneAll(),
            });
        },
    });
}