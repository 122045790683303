import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { FlexRow } from "@design-system/Layout/Flex";
import { GetObjectiveQuery, ResponseStatus } from "@generated/client/graphql";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableText } from "../EditableText";
import { RESOURCE_NAME, keys } from "./i18n";

type NameInputProps = {
    objective: GetObjectiveQuery["objective"];
};

export const NameInput: FC<NameInputProps> = ({ objective }) => {
    const [name, setName] = useState<string | undefined>(
        objective?.name || undefined,
    );
    const { mutate } = useUpdateObjective(objective?.id, objective?.entityId);

    const { setToastSuccess, setToastError } = useSetToast();
    const handleNameSave = () => {
        if (name !== objective.name) {
            const newName = name || null;
            mutate(
                { id: objective.id, name: newName },
                {
                    onSettled: (data) => {
                        if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Error
                        ) {
                            setToastError(
                                `${data.updateObjective.error?.message}`,
                            );
                        } else if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Success
                        ) {
                            setToastSuccess(t(keys.success_update));
                        }
                    },
                    onError: () => {
                        setToastError(t(keys.error_update));
                    },
                },
            );
        }
    };

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setName(event.target.value || undefined);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    useEffect(() => {
        setName(objective?.name || undefined);
    }, [objective]);

    return (
        <FlexRow py="1.5" className="min-h-8">
            <EditableText
                value={name}
                onInput={handleNameChange}
                onBlur={handleNameSave}
                placeholder={t(keys.name_placeholder)}
                variant="header1"
                className="resize-y"
                rows={1}
            />
        </FlexRow>
    );
};
