// inspired by this wonderful article 😍 https://medium.com/@aylo.srd/server-side-pagination-and-sorting-with-tanstack-table-and-react-bd493170125e
import { useStableCallback } from "@app/shared/utils/useStableCallback";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Icon } from "@design-system/Icon";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn, type ClassValue } from "@design-system/Utilities";
import {
    ColumnPinningState,
    flexRender,
    getCoreRowModel,
    useReactTable,
    type Column,
    type ColumnDef,
    type PaginationState,
    type Row,
    type RowSelectionState,
    type SortingState,
    type TableOptions,
    type Table as TableType,
} from "@tanstack/react-table";
import {
    createContext,
    HTMLAttributes,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
    type ComponentType,
    type CSSProperties,
    type Dispatch,
    type FC,
    type Ref,
    type SetStateAction,
} from "react";
import {
    Table as HTMLTable,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    Variant,
} from "./HTMLTable";
import { Pagination } from "./Pagination";
export {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    type ColumnDef,
    type ColumnFiltersState,
    type PaginationState,
    type Row,
    type RowSelectionState,
    type SortingFn,
    type SortingState,
    type TableOptions,
} from "@tanstack/react-table";

type Options<TData> = Omit<
    TableOptions<TData>,
    "columns" | "data" | "getCoreRowModel"
>;
type PaginationType = {
    state: PaginationState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPaginationChange: TableOptions<any>["onPaginationChange"];
    manualPagination?: boolean;
};
type SortingType = {
    state: SortingState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSortingChange: TableOptions<any>["onSortingChange"];
};

type ColumnPinningType = {
    state: ColumnPinningState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onColumnPinningChange: TableOptions<any>["onColumnPinningChange"];
};

export type PaginatedSelectedRows = {
    all_token: boolean;
    exclude_ids: Record<string, boolean>;
    include_ids: Record<string, boolean>;
};

type PaginatedSelectionType<TData> = {
    rowSelection: RowSelectionState;
    onRowSelectionChange: Dispatch<SetStateAction<RowSelectionState>>;
    onToggleAllRowsSelection: (isSelectingAll: boolean) => void;
    onToggleRowSelection: (rowId: string) => void;
    getRowId: (row: TData) => string;
    paginatedSelectedRows: PaginatedSelectedRows;
};

type TableContext = {
    paginatedSelection?: {
        onToggleAllRowsSelection: (isSelectingAll: boolean) => void;
        onToggleRowSelection: (rowId: string) => void;
        paginatedSelectedRows: PaginatedSelectedRows;
    };
};

const TableContext = createContext<TableContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type TableProps<TData extends unknown, TValue = unknown> = {
    /*
     * The class name for the table.
     * It applies to the outermost container of the table (a div).
     * To target the innermost table element, use the .htmltable class name.
     */
    className?: ClassValue;
    /*
     * The column definitions for the table, based on tanstack's table definition.
     */
    columns: ColumnDef<TData, TValue>[];
    /*
     * The data for the table.
     */
    data: TData[];
    /**
     * The component to display when the table is empty.
     */
    EmptyBody?: ComponentType;
    /*
     * Whether the table is currently loading data.
     */
    loading?: boolean;
    /*
     * Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
     */
    options?: Options<TData>;
    /*
     * The pagination state and change handler for the table.
     */
    pagination?: PaginationType;
    /*
     * The sorting state and change handler for the table.
     */
    sorting?: SortingType;
    /*
     * The column pinning state and change handler for the table.
     */
    columnPinning?: ColumnPinningType;
    /*
     * The number of rows in the table.  It's primarily used for managing pagination. Typically, this count is retrieved from an API, which also depends on the data that the 'usePagination' hook uses. However, since the 'rowCount' is usually dependent on the data from the API, it's not possible to pass it to the 'usePagination' hook for it to include it in the pagination object. To maintain clarity and avoid circular dependencies, we've chosen to define 'rowCount' separately.
     */
    rowCount?: number;
    /*
     * Whether the table is compact or regular. Compact will have smaller padding and font size.
     */
    variant?: Variant;
    /*
     * The paginated selection state and handlers for managing row selection when the data comes from a server side paginated query.
     */
    paginatedSelection?: PaginatedSelectionType<TData>;
};
/**
 * Table component that uses the react-table library for rendering and functionality.
 *
 * @template TData The type of data that the table will be displaying.
 * @template TValue The type of value that the table cells will be displaying.
 *
 * @param {Object} props The properties for the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns The column definitions for the table.
 * @param {ClassValue} [props.className] The class name for the table. It applies to the outermost container of the table (a div). To target the innermost table element, use the .htmltable class name.
 * @param {TData[]} props.data The data for the table.
 * @param {boolean} [props.loading=false] Whether the table is currently loading data.
 * @param {Options<TData>} [props.options={}] Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
 * @param {PaginationType} [props.pagination] The pagination state and change handler for the table.
 * @param {SortingType} [props.sorting] The sorting state and change handler for the table.
 * @param {ColumnPinningType} [props.columnPinning] The column pinning state and change handler for the table.
 * @param {number} [props.rowCount] The number of rows in the table.
 * @param {Variant} [props.variant="regular"] Whether the table is compact or regular. Compact will have smaller padding and font size.
 * @param {PaginatedSelectionType<TData>} [props.paginatedSelection] The paginated selection state and handlers for managing row selection when the data comes from a server side paginated query.
 *
 * @returns {JSX.Element} The table component.
 */
export const Table = <TData, TValue>({
    className,
    columns,
    data,
    EmptyBody,
    loading,
    options = {},
    columnPinning,
    pagination,
    sorting,
    rowCount,
    variant = "regular",
    paginatedSelection,
}: TableProps<TData, TValue>) => {
    const tableOptions: TableOptions<TData> = useMemo(() => {
        const { state: optsState, ...optionsProps } = options;
        let state = optsState;
        let columnPinningOpts;
        let paginationOpts;
        let sortingOpts;
        let paginatedSelectionOpts;

        if (pagination) {
            const {
                manualPagination: manualPaginationState,
                state: paginationState,
                onPaginationChange,
            } = pagination;
            state = { ...(state ?? {}), pagination: paginationState };
            paginationOpts = {
                manualPagination: manualPaginationState ?? true,
                onPaginationChange,
            };
        }

        if (sorting) {
            const { state: sortingState, onSortingChange } = sorting;
            state = { ...(state ?? {}), sorting: sortingState };
            sortingOpts = { manualSorting: true, onSortingChange };
        }

        if (columnPinning) {
            const { state: columnPinningState, onColumnPinningChange } =
                columnPinning;
            state = { ...(state ?? {}), columnPinning: columnPinningState };
            columnPinningOpts = { onColumnPinningChange };
        }

        if (paginatedSelection) {
            const { rowSelection, onRowSelectionChange, getRowId } =
                paginatedSelection;
            state = { ...(state ?? {}), rowSelection };
            paginatedSelectionOpts = {
                onRowSelectionChange,
                getRowId,
            };
        }

        const opts: TableOptions<TData> = {
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
            ...(state ? { state } : {}),
            ...(paginationOpts ?? {}),
            ...(sortingOpts ?? {}),
            ...(columnPinningOpts ?? {}),
            ...(paginatedSelectionOpts ?? {}),
            rowCount,
            ...optionsProps,
        };
        return opts;
    }, [
        data,
        columns,
        getCoreRowModel,
        pagination,
        sorting,
        columnPinning,
        rowCount,
        options,
    ]);

    const table = useReactTable(tableOptions);
    const isTableEmpty = table.getRowCount() === 0;

    const {
        onToggleAllRowsSelection,
        onToggleRowSelection,
        paginatedSelectedRows,
    } = paginatedSelection ?? {};

    const ctxPaginatedSelection = useMemo(
        () =>
            onToggleAllRowsSelection &&
            onToggleRowSelection &&
            paginatedSelectedRows
                ? {
                      onToggleAllRowsSelection,
                      onToggleRowSelection,
                      paginatedSelectedRows,
                  }
                : undefined,
        [onToggleAllRowsSelection, onToggleRowSelection, paginatedSelectedRows],
    );

    // all the folloing just for handling the pinned column shadow 😅
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [scrollIsZero, setScrollIsZero] = useState(true);
    const hasPinnedColumns = useMemo(() => {
        return (
            (columnPinning?.state.left?.length ?? 0) > 0 ||
            (columnPinning?.state.right?.length ?? 0) > 0
        );
    }, [columnPinning]);

    useEffect(() => {
        if (!containerRef.current || !hasPinnedColumns) {
            return;
        }

        function handleScroll() {
            if (!containerRef.current) {
                return;
            }
            const { scrollLeft } = containerRef.current;
            setScrollIsZero(scrollLeft === 0);
        }

        containerRef.current.addEventListener("scroll", handleScroll);
        containerRef.current.addEventListener("scrollend", handleScroll);

        return () => {
            containerRef.current?.removeEventListener("scroll", handleScroll);
            containerRef.current?.removeEventListener(
                "scrollend",
                handleScroll,
            );
        };
    }, [containerRef, columnPinning, hasPinnedColumns, setScrollIsZero]);

    return (
        <TableContext.Provider
            value={{ paginatedSelection: ctxPaginatedSelection }}
        >
            <FlexCol gap="4" w="full">
                <HTMLTable
                    className={hasPinnedColumns ? "relative" : undefined}
                    containerClassName={cn(
                        hasPinnedColumns ? "sticky z-0" : undefined,
                        className,
                    )}
                    containerRef={containerRef}
                >
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const style = getCommonStyles(
                                        header.column,
                                    );
                                    return (
                                        <TableHead
                                            className={cn(
                                                header.column.columnDef.meta
                                                    ?.headerClassName,
                                                header.column.getIsPinned() &&
                                                    "bg-secondary",
                                                getCommonPinningClasses(
                                                    header.column,
                                                    {
                                                        parentScrollIsZero:
                                                            scrollIsZero,
                                                    },
                                                ),
                                            )}
                                            key={header.id}
                                            {...(Object.keys(style).length > 0
                                                ? { style }
                                                : {})}
                                            variant={variant}
                                        >
                                            {header.column.getCanSort() ? (
                                                <FlexRow
                                                    alignItems="center"
                                                    className="sortable-header-wrapper text-sm font-bold"
                                                    gap="2"
                                                    h="full"
                                                    w="full"
                                                >
                                                    <button
                                                        className={cn(
                                                            "cursor-pointer rounded-full border min-h-5 min-w-5 grid place-items-center",
                                                            "text-primary",
                                                            "bg-secondary border-secondary",
                                                            "hover:bg-tertiary hover:border-primary",
                                                        )}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                    >
                                                        {!header.column.getIsSorted() && (
                                                            <Icon
                                                                name="angleUpDown"
                                                                size="xs"
                                                            />
                                                        )}
                                                        {header.column.getIsSorted() ===
                                                            "desc" && (
                                                            <Icon
                                                                name="arrowUp"
                                                                size="xs"
                                                            />
                                                        )}
                                                        {header.column.getIsSorted() ===
                                                            "asc" && (
                                                            <Icon
                                                                name="arrowDown"
                                                                size="xs"
                                                            />
                                                        )}
                                                    </button>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </FlexRow>
                                            ) : (
                                                flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )
                                            )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {loading ? (
                            Array.from({
                                length: table.getState().pagination.pageSize,
                            }).map((_, i) => (
                                <TableRow key={i}>
                                    {table
                                        .getHeaderGroups()
                                        .map((headerGroup) =>
                                            headerGroup.headers.map(
                                                (cell, idx) => {
                                                    const style =
                                                        getCommonWidthStyles(
                                                            cell.column,
                                                        );
                                                    return (
                                                        <TableCell
                                                            {...(Object.keys(
                                                                style,
                                                            ).length > 0
                                                                ? { style }
                                                                : {})}
                                                            className={cn(
                                                                cell.column
                                                                    .columnDef
                                                                    .meta
                                                                    ?.cellClassName ??
                                                                    "",
                                                            )}
                                                            key={idx}
                                                            variant={variant}
                                                        >
                                                            <Skeleton
                                                                br="xl"
                                                                h="2"
                                                                w="full"
                                                            />
                                                        </TableCell>
                                                    );
                                                },
                                            ),
                                        )}
                                </TableRow>
                            ))
                        ) : isTableEmpty && EmptyBody ? (
                            <TableRow>
                                <TableCell
                                    colSpan={
                                        table.getVisibleLeafColumns().length
                                    }
                                    className="p-0"
                                >
                                    <EmptyBody />
                                </TableCell>
                            </TableRow>
                        ) : (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={computeRowKey(row)}>
                                    {row.getVisibleCells().map((cell) => {
                                        const style = getCommonStyles(
                                            cell.column,
                                        );
                                        return (
                                            <TableCell
                                                className={cn(
                                                    cell.column.columnDef.meta
                                                        ?.cellClassName ?? "",
                                                    cell.column.getIsPinned() &&
                                                        "bg-primary",
                                                    getCommonPinningClasses(
                                                        cell.column,
                                                        {
                                                            parentScrollIsZero:
                                                                scrollIsZero,
                                                        },
                                                    ),
                                                )}
                                                key={cell.id}
                                                {...(Object.keys(style).length >
                                                0
                                                    ? { style }
                                                    : {})}
                                                variant={variant}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </HTMLTable>
                <Pagination loading={loading} table={table} />
            </FlexCol>
        </TableContext.Provider>
    );
};

/**
 * HeaderFilter component for the table header.
 * This component is used to display a filter in the table header.
 * It is used as a trigger for any selection component (e.g. MultiSelect).
 * In this way the user has full control over the selection component, and
 * can customize it as needed (type of selection, dropdown content etc.).
 */
export const HeaderFilter: FC<
    HTMLAttributes<HTMLDivElement> & {
        /**
         * Whether the filter is active.
         * If active, the text will be green.
         * If not active, the text will be the primary color.
         * @default false
         * */
        active?: boolean;
    } & { ref?: Ref<HTMLDivElement> }
> = ({ children, active, className, ...props }) => {
    return (
        <div
            {...props}
            className={cn(
                "h-full w-full",
                "flex items-center justify-between",
                "text-primary text-sm font-bold",
                "hover:bg-tertiary hover:rounded-lg cursor-pointer",
                active && "text-green",
                className,
            )}
        >
            {children}
            <Icon className="text-primary" name="angleDown" size="sm" />
        </div>
    );
};

export const CheckboxHeader = <T,>({
    table,
    title,
    ref,
    disabled,
}: {
    table: TableType<T>;
    title?: string;
    ref?: Ref<HTMLDivElement | null>;
    disabled?: boolean;
}) => {
    const { paginatedSelection } = useContext(TableContext) ?? {};

    const { all_token, exclude_ids, include_ids } =
        paginatedSelection?.paginatedSelectedRows ?? {};

    const isExcludingRows = Object.values(exclude_ids ?? {}).some(Boolean);
    const isIncludingRows = Object.values(include_ids ?? {}).some(Boolean);
    const excludedRowsCount = Object.values(exclude_ids ?? {}).filter(
        Boolean,
    ).length;
    const includedRowsCount = Object.values(include_ids ?? {}).filter(
        Boolean,
    ).length;
    const isIncludingAllRows = includedRowsCount === table.getRowCount();
    const isExcludingAllRows = excludedRowsCount === table.getRowCount();

    const isIndeterminate = paginatedSelection
        ? ((all_token && isExcludingRows && !isExcludingAllRows) ||
              (!all_token && isIncludingRows && !isIncludingAllRows)) &&
          !!table.getRowCount()
        : table.getIsSomeRowsSelected();

    const isChecked = paginatedSelection
        ? ((all_token && !isExcludingRows) || isIncludingAllRows) &&
          !!table.getRowCount()
        : table.getIsAllRowsSelected();

    const handleChange = () => {
        if (paginatedSelection) {
            paginatedSelection.onToggleAllRowsSelection(!isChecked);
        }
        table.toggleAllRowsSelected(!isChecked);
    };

    return (
        <FlexRow alignItems="center" gap="2" ref={ref}>
            <Checkbox
                indeterminate={isIndeterminate}
                checked={isChecked}
                onChange={handleChange}
                disabled={disabled}
                aria-label="Select all"
            />
            {title && <Text variant="body-sm-bold">{title}</Text>}
        </FlexRow>
    );
};

export const CheckboxCell = <T,>({
    row,
    text,
    ref,
    disabled,
}: {
    row: Row<T>;
    text?: string;
    ref?: Ref<HTMLDivElement | null>;
    disabled?: boolean;
}) => {
    const { paginatedSelection } = useContext(TableContext) ?? {};

    const handleChange = () => {
        if (paginatedSelection) {
            paginatedSelection.onToggleRowSelection(row.id);
        }
        row.toggleSelected(!row.getIsSelected());
    };

    return (
        <FlexRow alignItems="center" gap="2" ref={ref}>
            <Checkbox
                disabled={disabled}
                checked={row.getIsSelected()}
                onChange={handleChange}
                aria-label="Select row"
            />
            {text && <Text variant="body-sm">{text}</Text>}
        </FlexRow>
    );
};

/**
 * Hook for managing pagination state and providing API-friendly values.
 *
 * @template T The type of items being paginated
 * @param {Object} options Configuration options for pagination
 * @param {number} [options.initialSize=10] Initial number of items per page
 * @param {number} [options.initialIndex=0] Starting page index (zero-based)
 * @param {boolean} [options.manualPagination=true] Whether pagination is manually controlled
 *
 * @returns {Object} Pagination state and API parameters
 * @returns {Object} pagination.state Current pagination configuration
 * @returns {Function} pagination.onPaginationChange Handler function to update pagination state
 * @returns {number} pagination.pageSize Current number of items per page
 * @returns {number} pagination.pageIndex Current zero-based page index
 * @returns {boolean} pagination.manualPagination Whether pagination is manually controlled
 * @returns {number} take Number of items to include in current page
 * @returns {number} skip Number of items to skip before current page
 */
export function usePagination({
    initialSize = 10,
    initialIndex = 0,
    manualPagination = true,
}: {
    initialSize?: number;
    initialIndex?: number;
    manualPagination?: boolean;
} = {}) {
    const [paginationState, setPaginationState] = useState({
        pageSize: initialSize,
        pageIndex: initialIndex,
    });
    const { pageSize, pageIndex } = paginationState;

    return {
        // table state
        pagination: {
            state: paginationState,
            onPaginationChange: setPaginationState,
            manualPagination,
        },
        // API
        take: pageSize,
        skip: pageSize * pageIndex,
    };
}

/**
 * `usePaginatedSelection` is a hook for managing row selection in a paginated table.
 *
 * ## Core Behavior:
 * - Selection persists across pages.
 * - Supports selecting/deselecting individual rows.
 * - Allows selecting all rows across all pages with exclusions.
 * - Keeps track of selections when navigating between pages.
 *
 * ## Key Details:
 * - The `data` prop represents the rows currently visible on the active page.
 * - Selection state applies across all pages, not just the current one.
 * - Selecting all rows affects all pages, not just the current one.
 *
 * @template T The type of data items, requiring an `id` field.
 * @param {T[]} data - The list of rows displayed on the current page.
 *
 * @returns {Object} Selection state and handlers.
 *
 * @returns {Object} paginatedSelection - The main selection state and utilities.
 * @returns {Record<string, boolean>} paginatedSelection.selectedRowIds - A record of row IDs with `true` if selected, `false` otherwise.
 * @returns {Function} paginatedSelection.setSelectedRowIds - Manually update the selection state.
 * @returns {Function} paginatedSelection.onToggleAllRowsSelection - Toggle selection for all rows across all pages.
 * @returns {Function} paginatedSelection.onToggleRowSelection - Toggle selection for an individual row.
 * @returns {Function} paginatedSelection.getRowId - Extracts the unique ID from a row object.
 * @returns {Object} paginatedSelection.paginatedSelectedRows Internal state that tracks selected/excluded rows:
 *    - `all_token`: If true, all rows are considered selected except those in `exclude_ids`.
 *    - `exclude_ids`: A record of row IDs explicitly excluded when selecting all.
 *    - `include_ids`: A record of row IDs explicitly included when not selecting all.
 * @returns {Function} resetSelection - Clears all selections.
 */
export function usePaginatedSelection<T extends { id: string }>(data: T[]) {
    const [paginatedSelectedRows, setPaginantedSelectedRows] =
        useState<PaginatedSelectedRows>({
            all_token: false,
            exclude_ids: {},
            include_ids: {},
        });

    const [selectedRowIds, setSelectedRowIds] = useState<RowSelectionState>({});

    const onToggleAllRowsSelection = useStableCallback(
        (isSelectingAll: boolean) => {
            if (isSelectingAll) {
                setPaginantedSelectedRows({
                    all_token: true,
                    exclude_ids: {},
                    include_ids: {},
                });
                return;
            }
            setPaginantedSelectedRows({
                all_token: false,
                exclude_ids: {},
                include_ids: {},
            });
            setSelectedRowIds({});
        },
    );

    const onToggleRowSelection = useStableCallback((rowId: string) => {
        const isAllSelected = paginatedSelectedRows.all_token;

        if (!isAllSelected) {
            setPaginantedSelectedRows((prev) => ({
                all_token: false,
                exclude_ids: {},
                include_ids: {
                    ...prev.include_ids,
                    [rowId]: !prev.include_ids[rowId],
                },
            }));
            return;
        }

        setPaginantedSelectedRows((prev) => ({
            all_token: true,
            exclude_ids: {
                ...prev.exclude_ids,
                [rowId]: !prev.exclude_ids[rowId],
            },
            include_ids: {},
        }));
    });

    const resetSelection = () => {
        setPaginantedSelectedRows({
            all_token: false,
            exclude_ids: {},
            include_ids: {},
        });
        setSelectedRowIds({});
    };

    useEffect(() => {
        if (paginatedSelectedRows.all_token) {
            setSelectedRowIds((prev) => ({
                ...prev,
                ...Object.fromEntries(
                    data.map((d) => [
                        d.id,
                        !paginatedSelectedRows.exclude_ids[d.id],
                    ]),
                ),
            }));
            return;
        }
        setSelectedRowIds(paginatedSelectedRows.include_ids);
    }, [data]);

    return useMemo(
        () => ({
            paginatedSelection: {
                rowSelection: selectedRowIds,
                onRowSelectionChange: setSelectedRowIds,
                onToggleAllRowsSelection,
                onToggleRowSelection,
                getRowId: (row: T) => row.id,
                paginatedSelectedRows,
            },
            resetSelection,
        }),
        [
            selectedRowIds,
            setSelectedRowIds,
            onToggleAllRowsSelection,
            onToggleRowSelection,
            paginatedSelectedRows,
            resetSelection,
        ],
    );
}

/**
 * Hook for managing sorting state according to the Table component.
 *
 * @template TField The type of field that the sorting is based on (cooresponds to the accessorKey of the column).
 *
 * @param {TField} initialField The initial field to sort by.
 * @param {"asc" | "desc"} [initialOrder="asc"] The initial order of the sorting.
 *
 * @returns {Object} The sorting state and change handler, and the current order and field.
 */
export function useSorting<TField extends string>(
    initialField: TField,
    initialOrder: "asc" | "desc" = "asc",
) {
    const [sortingState, setSorting] = useState([
        { id: initialField, desc: initialOrder === "desc" },
    ]);

    const order: "asc" | "desc" = !sortingState.length
        ? initialOrder
        : sortingState[0].desc
          ? "desc"
          : "asc";

    const sorting: SortingType = {
        state: sortingState,
        // @ts-expect-error: Mystery type error
        onSortingChange: setSorting,
    };
    return {
        sorting,
        order,
        field: sortingState.length ? sortingState[0].id : initialField,
    };
}

/**
 * `useColumnPinning` is a custom React hook for managing the state of column pinning in a table.
 *
 * Column pinning is a feature that allows the user to "pin" or "freeze" a column in place.
 * This is useful in a table with horizontal scrolling, as the pinned column will stay in place while other columns can be scrolled horizontally.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Partial<ColumnPinningState>} params.initialColumnPinningState - An object representing the initial state of column pinning. This object can have two properties: `left` and `right`, which are arrays of column identifiers. The `left` array represents the columns pinned to the left side of the table, and the `right` array represents the columns pinned to the right side of the table.
 *
 * @returns {Object} An object with a single property `columnPinning`, which is an object with two properties: `state` and `onColumnPinningChange`.
 * - `state` is an object representing the current state of column pinning. It has the same structure as `initialColumnPinningState`.
 * - `onColumnPinningChange` is a function used by the table to change the state of column pinning. It accepts a new state object as its parameter.
 */
export function useColumnPinning({
    initialColumnPinningState,
}: {
    initialColumnPinningState: Partial<ColumnPinningState>;
}) {
    const [columnPinningState, setColumnPinningState] =
        useState<ColumnPinningState>({
            ...{
                left: [],
                right: [],
            },
            ...initialColumnPinningState,
        });

    return {
        columnPinning: {
            state: columnPinningState,
            onColumnPinningChange: setColumnPinningState,
        },
    };
}

/**
 * Returns the common styles (for headers and cells) for columns with a fixed width.
 * If the column has a fixed width, it will be applied to the column.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 *
 * @returns {CSSProperties} The common styles for columns with a fixed width, or an empty object if thre's nothing to apply.
 */
function getCommonWidthStyles<TData>(column: Column<TData>): CSSProperties {
    const style =
        typeof column.columnDef.meta?.colWidth === "string"
            ? {
                  width: column.columnDef.meta.colWidth,
              }
            : {};
    return style;
}

type GetCommonPinningClassesOptions = {
    /**
     * Whether the parent scroll is zero (if not, it means it is being scrolled).
     * This is used to determine if the column should have a shadow.
     */
    parentScrollIsZero?: boolean;
};
/**
 * Returns the common styles (for headers and cells) for pinned columns.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 * @param {GetCommonPinningClassesOptions} [options={}] An object containing options for getting the common pinning styles.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if there's nothing to apply.
 */
function getCommonPinningStyles<TData>(column: Column<TData>): CSSProperties {
    const isPinned = column.getIsPinned();

    if (!isPinned) {
        return {};
    }

    return {
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right:
            isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        position: "sticky",
        width: column.getSize(),
        zIndex: 1,
    };
}

/**
 * Returns the common classes (for headers and cells) for pinned columns.
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 * @param {GetCommonPinningClassesOptions} [options={}] An object containing options for getting the common pinning styles.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if there's nothing to apply.
 */
function getCommonPinningClasses<TData>(
    column: Column<TData>,
    { parentScrollIsZero }: GetCommonPinningClassesOptions = {},
): ClassValue {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
        isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn =
        isPinned === "right" && column.getIsFirstColumn("right");

    if (!isPinned) {
        return;
    }

    const classes = [];

    if (isLastLeftPinnedColumn) {
        classes.push(
            "after:block after:content-['_'] after:absolute after:top-0 after:right-[-4px] after:w-1 after:h-[calc(100%+1px)] after:transition-shadow after:duration-500",
        );
    }
    if (isLastLeftPinnedColumn && !parentScrollIsZero) {
        classes.push("after:shadow-[inset_2px_0px_4px_0px_rgba(0,0,0,0.3)] ");
    }
    if (isFirstRightPinnedColumn) {
        classes.push(
            "before:block before:content-['_'] before:absolute before:top-0 before:left-[-4px] before:w-1 before:h-[calc(100%+1px)]  before:transition-shadow before:duration-500",
        );
    }
    if (isFirstRightPinnedColumn && !parentScrollIsZero) {
        classes.push("before:shadow-[inset_-2px_0px_4px_0px_rgba(0,0,0,0.3)]");
    }

    return cn(...classes);
}
/**
 * Returns the Returns the common styles for headers and cells
 *
 * @template TData The type of data that the table will be displaying.
 *
 * @param {Column<TData>} column The column object.
 *
 * @returns {CSSProperties} The common styles for pinned columns, or an empty object if thre's nothing to apply.
 */
function getCommonStyles<TData>(column: Column<TData>): CSSProperties {
    const commonStyles = {
        ...getCommonWidthStyles(column),
        ...getCommonPinningStyles(column),
    };

    return commonStyles;
}

/**
 * The basic comparison function for creating
 * custom sorting functions in the colum definition.
 */
export function compareBasic<T>(a: T, b: T) {
    return a === b ? 0 : a > b ? 1 : -1;
}

function computeRowKey<TData>(row: Row<TData>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const originalId = (row.original as any)?.id;
    if (typeof originalId === "string") {
        return originalId;
    }

    if (typeof originalId === "number") {
        return originalId.toString();
    }

    return row.id;
}
