import { Accordion } from "@design-system/DataDisplay/Accordion";
import { Icon, IconName } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useNavigate, useParams } from "react-router-dom";
import "../../i18n";

export const Card = ({
    icon,
    title,
    description,
    onClick,
}: {
    icon: IconName;
    title: string;
    description: string;
    onClick?: () => void;
}) => {
    return (
        <FlexCol
            gap="2"
            className="bg-neutral-25 p-4 rounded-lg border border-neutral-200 overflow-hidden cursor-pointer"
            onClick={onClick}
        >
            <Icon name={icon} className="text-beavrGreen" />
            <Text variant="body-bold" className="text-neutral-700">
                {title}
            </Text>
            <Text variant="body" color="secondary" className="text-neutral-700">
                {description}
            </Text>
        </FlexCol>
    );
};

export const FaqQuestion = ({
    question,
    answer,
}: {
    question: string;
    answer: string;
}) => {
    return (
        <FlexCol
            gap="2"
            className="w-full border border-neutral-200 rounded-lg overflow-hidden bg-neutral-25 px-4"
        >
            <Accordion type="multiple" title={question}>
                <Accordion.Item value={question}>
                    <Accordion.Trigger>
                        <Text variant="body-bold" className="text-neutral-700">
                            {question}
                        </Text>
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <Text variant="body" className="text-neutral-700">
                            {answer}
                        </Text>
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion>
        </FlexCol>
    );
};

export const FaqList = ({
    faq,
}: {
    faq: {
        question: string;
        answer: string;
    }[];
}) => {
    return (
        <FlexCol gap="2" className="py-5">
            {faq.map((question, index) => (
                <FaqQuestion
                    key={index}
                    question={question.question}
                    answer={question.answer}
                />
            ))}
        </FlexCol>
    );
};

export const CardGrid = ({
    demoLink,
    articleLink,
    websiteLink,
}: {
    demoLink: string;
    articleLink: string;
    websiteLink: string;
}) => {
    const navigate = useNavigate();
    const { org_uname } = useParams();
    const handleClick = (link: string) => () => {
        if (link) {
            window.open(link, "_blank", "noopener,noreferrer");
        }
    };

    const handleHubSpotForm = () => {
        navigate(`/o/${org_uname}/request-access`);
    };

    return (
        <>
            <Box className="grid grid-cols-2 gap-5 py-5">
                <Card
                    icon="sale"
                    title="Request access"
                    description="We'll notify our team."
                    onClick={handleHubSpotForm}
                />

                <Card
                    icon="image"
                    title="Watch our demo"
                    description="Watch how Beavr simplifies the process."
                    onClick={handleClick(demoLink)}
                />
                <Card
                    icon="form"
                    title="Read our demo"
                    description="Access our specific guidance."
                    onClick={handleClick(articleLink)}
                />
                <Card
                    icon="external"
                    title="See framework website"
                    description="Access the official information."
                    onClick={handleClick(websiteLink)}
                />
            </Box>
        </>
    );
};
