import { useNavigation } from "@app/lib/navigation";
import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { RadioGroup } from "@design-system/Inputs/RadioGroup";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ChooseIndicatorMutation } from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { objectiveKeys } from "../../data";
import { isGenerateObjectiveModalOpenAtom } from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
export const SelectIndicatorPage: FC<{
    selectedIndicatorId?: string;
    setSelectedIndicator: (indicator: {
        id: string;
        name: string;
        themeId: string;
        unitShortName: string;
    }) => void;
}> = ({ selectedIndicatorId, setSelectedIndicator }) => {
    const chooseIndicatorMutationState = useMutationState({
        filters: { mutationKey: [...objectiveKeys.chooseIndicator()] },
        select: (mutation) => mutation.state,
    });

    const isChooseIndicatorPending =
        !!chooseIndicatorMutationState?.length &&
        chooseIndicatorMutationState[chooseIndicatorMutationState.length - 1]
            .status === "pending";

    const indicators: ChooseIndicatorMutation["chooseIndicator"] | undefined =
        chooseIndicatorMutationState?.length
            ? (
                  chooseIndicatorMutationState[
                      chooseIndicatorMutationState.length - 1
                  ].data as ChooseIndicatorMutation
              )?.chooseIndicator
            : undefined;

    const { t } = useTranslation(RESOURCE_NAME);

    const handleSelectIndicator = (indicatorId: string) => {
        const indicator = indicators?.find((i) => i.id === indicatorId);
        if (!indicator) return;
        setSelectedIndicator({
            id: indicator.id,
            name: indicator.name,
            themeId: indicator.themeId,
            unitShortName: indicator.unitShortName ?? "",
        });
    };

    return (
        <AiSuggestion isOpen={true} isLoading={isChooseIndicatorPending}>
            <AiSuggestion.Header
                title={
                    isChooseIndicatorPending
                        ? t(keys.choosing_indicator)
                        : t(keys.suggested_indicators)
                }
            />
            <AiSuggestion.Body className="pb-4 text-primary">
                {isChooseIndicatorPending ? (
                    <>
                        <LoadingAiExplanation />
                        <LoadingRadioGroup />
                    </>
                ) : !indicators?.length ? (
                    <EmptyState />
                ) : (
                    <>
                        <AiSuggestion.Block
                            title={t(keys.ai_explanation)}
                            content={t(keys.indicator_description, {
                                subthemeNames: indicators
                                    ?.map((indicator) => indicator.subthemeName)
                                    .join(", "),
                            })}
                        />
                        <RadioGroup
                            value={selectedIndicatorId}
                            onChange={handleSelectIndicator}
                            className="border border-accent-1-200 p-4 rounded-lg shadow-md"
                        >
                            {indicators?.map((indicator) => (
                                <RadioGroup.Field key={indicator.id}>
                                    <RadioGroup.Radio value={indicator.id} />
                                    <RadioGroup.Label>
                                        <Text variant="body" className="inline">
                                            {indicator.name}
                                        </Text>
                                        <Text
                                            variant="body"
                                            className="italic inline"
                                        >
                                            {`(${indicator.subthemeName})`}
                                        </Text>
                                    </RadioGroup.Label>
                                </RadioGroup.Field>
                            ))}
                        </RadioGroup>
                    </>
                )}
            </AiSuggestion.Body>
        </AiSuggestion>
    );
};

const LoadingAiExplanation = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol gap="2" w="full">
            <Text
                variant="body-sm-bold"
                color="secondary"
                className="uppercase"
            >
                {t(keys.ai_explanation)}
            </Text>
            <FlexCol gap="2">
                <Skeleton
                    h="3"
                    w="1/2"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
                <Skeleton
                    h="3"
                    w="5/6"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
                <Skeleton
                    h="3"
                    w="3/4"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
            </FlexCol>
        </FlexCol>
    );
};

const LoadingRadioGroup = () => {
    return (
        <RadioGroup className="border border-accent-1-100 p-4 rounded-lg shadow-md">
            <RadioGroup.Field>
                <RadioGroup.Radio value="1" />
                <Skeleton
                    h="4"
                    w="1/3"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
            </RadioGroup.Field>
            <RadioGroup.Field>
                <RadioGroup.Radio value="2" />
                <Skeleton
                    h="4"
                    w="40"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
            </RadioGroup.Field>
            <RadioGroup.Field>
                <RadioGroup.Radio value="3" />
                <Skeleton
                    h="4"
                    w="1/2"
                    br="lg"
                    className="from-accent-1-100 to-accent-1-50"
                />
            </RadioGroup.Field>
        </RadioGroup>
    );
};

const EmptyState: FC = () => {
    const { navigateFromHome } = useNavigation();
    const setGenerateObjectiveModalOpen = useSetAtom(
        isGenerateObjectiveModalOpenAtom,
    );

    const handleGoToReportingClick = () => {
        setGenerateObjectiveModalOpen(false);
        navigateFromHome("reporting");
    };

    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <FlexCol
            p="6"
            gap="4"
            br="lg"
            elevation="b-100"
            className="border border-secondary bg-secondary"
            justifyContent="center"
            alignItems="center"
        >
            <FlexRow
                w="1/2"
                gap="2"
                alignItems="center"
                justifyContent="center"
            >
                <Icon name="warning" size="lg" />
                <Text variant="body">{t(keys.no_indicator_found)}</Text>
            </FlexRow>
            <Button size="md" onClick={handleGoToReportingClick}>
                {t(keys.go_to_reporting)}
            </Button>
        </FlexCol>
    );
};
