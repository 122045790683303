import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useMemo, type FC } from "react";

import { Spinner } from "@app/components/TipTap/components/ui/Spinner";
import { makeIndicatorLink } from "@app/screens/Global/AskAIModal/services";
import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { Button } from "@design-system/Inputs/Button";
import { cn } from "@design-system/Utilities";

import { questionnairesKeys } from "@app/screens/ExternalQuestionnaires/data";
import { isSelectType } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/services";
import { useGetIndicators } from "@app/screens/Frameworks/framework/Csrd/pillar/data";
import { ExtensionTag } from "@app/shared/components/ExtensionTag";
import { SupportedLocales } from "@app/shared/utils/locales";
import { getFileFormatCategoryFromMimeType } from "@app/usecases/VersionUseCases/utils";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Box } from "@design-system/Layout/Box";
import {
    AnswerStatus,
    QuestionType,
    type GetQuestionQuery,
} from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
    useGenerateAiSuggestion,
    useGetDocuments,
    useQuestionAndAnswerUpdate,
} from "../data";
import { DocumentCitationTooltip } from "./DocumentCitationTooltip";
import { useQuestionsTabTranslations } from "./i18n";

export const AnswerAiSuggestion: FC<{
    question: GetQuestionQuery["question"];
    locale: SupportedLocales;
    isAiSuggestionOpen?: boolean;
    setIsAiSuggestionOpen?: (isOpen: boolean) => void;
}> = ({ question, locale, isAiSuggestionOpen, setIsAiSuggestionOpen }) => {
    const { org_uname } = useParams();
    const {
        updateTextAnswer,
        updateSelectAnswer,
        updateEvidenceFilesAsync,
        updateValueAnswer,
        updateComment,
    } = useQuestionAndAnswerUpdate(
        question.id,
        question.answer?.id,
        question.questionnaireId,
    );
    const { t, keys } = useQuestionsTabTranslations();

    const aiSuggestion = question?.answer?.aiSuggestion;

    const aiSuggestionMutationState = useMutationState({
        filters: {
            mutationKey: questionnairesKeys.generateAiSuggestion(
                question.questionnaireId ?? undefined,
                question.id,
            ),
        },
        select: (mutation) => mutation.state.status,
    });
    const isSuggestPending =
        !!aiSuggestionMutationState?.length &&
        aiSuggestionMutationState[aiSuggestionMutationState.length - 1] ===
            "pending";

    const isSuggestError = aiSuggestion?.success === false;

    const {
        answerText,
        documentCitations,
        indicatorCitations,
        motivation,
        comment,
        value,
        aiSuggestionSuccess,
    } = useMemo(() => {
        const documentCitations = aiSuggestion?.documentCitations ?? [];
        const indicatorCitations = aiSuggestion?.indicatorCitations ?? [];
        const success = aiSuggestion?.success;
        const comment = aiSuggestion?.comment;
        const motivation = aiSuggestion?.motivation;
        const value = aiSuggestion?.value;

        const answerText =
            typeof success === "boolean" && !success
                ? t(keys.ai_suggestion_unsuccessful)
                : question?.type === QuestionType.MultiSelect
                  ? question?.metadata?.options
                        ?.filter((_, idx) => value?.includes(idx))
                        ?.map((option) => option?.name?.[locale])
                        .join(", ")
                  : question?.type === QuestionType.SingleSelect
                    ? question?.metadata?.options?.[value]?.name?.[locale]
                    : question?.type === QuestionType.Text
                      ? value
                      : value?.toString();

        return {
            answerText,
            documentCitations,
            indicatorCitations,
            motivation,
            comment,
            value,
            aiSuggestionSuccess: success ?? false,
        };
    }, [question?.answer?.aiSuggestion, t]);

    const indicatorIds = indicatorCitations
        .map((c: { indicatorId: string }) => {
            const split = c.indicatorId.split("_");
            return split?.length ? split[0] : null;
        })
        .filter((id) => typeof id === "string");

    const { indicators: suggestedIndicators } = useGetIndicators(indicatorIds);

    const { documents: suggestedDocuments } = useGetDocuments(
        documentCitations?.map((c: { documentId: string }) => c.documentId) ??
            [],
    );

    const { generateAiSuggestion } = useGenerateAiSuggestion(
        question.questionnaireId ?? undefined,
        question.id,
    );

    const handleSuggestButtonClick = () => {
        generateAiSuggestion(question.id, {
            onSettled: () => {
                setIsAiSuggestionOpen?.(true);
            },
        });
    };

    const handleToggleOpen = () => {
        setIsAiSuggestionOpen?.(!isAiSuggestionOpen);
    };

    const handleInsertAnswerLinkClick = () => {
        if (question?.type === QuestionType.Text) {
            updateTextAnswer(answerText ?? "");
        }

        if (question?.type === QuestionType.Value) {
            updateValueAnswer(value);
        }

        if (question?.type === QuestionType.MultiSelect) {
            updateSelectAnswer(value ?? []);
        }

        if (question?.type === QuestionType.SingleSelect) {
            updateSelectAnswer([value]);
        }
    };

    const handleInsertCommentLinkClick = () => {
        updateComment(comment ?? "");
    };

    const handleInsertDocumentLinkClick = (documentId: string) => {
        return () => {
            updateEvidenceFilesAsync({
                fileIds: suggestedDocuments
                    .filter((doc) => doc.id === documentId)
                    ?.map(
                        (document) =>
                            document?.lastApprovedVersion?.finalFile?.id,
                    )
                    .filter((fileId) => typeof fileId === "string"),
            });
        };
    };

    const handleConfirmClick = () => {
        if (isSelectType(question?.type)) {
            const updatedValue = Array.isArray(value) ? value : [value];
            updateSelectAnswer(updatedValue);
        }

        if (question?.type === QuestionType.Text) {
            updateTextAnswer(answerText ?? "");
        }

        if (question?.type === QuestionType.Value) {
            updateValueAnswer(value);
        }

        if (comment) {
            updateComment(comment);
        }

        updateEvidenceFilesAsync({
            fileIds: suggestedDocuments
                ?.map(
                    (document) => document?.lastApprovedVersion?.finalFile?.id,
                )
                .filter((fileId) => typeof fileId === "string"),
        });
        setIsAiSuggestionOpen?.(false);
    };

    return (
        <AiSuggestion
            isOpen={isAiSuggestionOpen ?? false}
            isLoading={isSuggestPending}
            isError={isSuggestError}
            className="flex flex-col justify-center align-middle"
        >
            <AiSuggestion.Header
                title={
                    isSuggestError
                        ? t(keys.ai_suggestion_error)
                        : t(keys.ai_suggestion_title)
                }
                isError={isSuggestError}
            >
                {isSuggestPending ? (
                    <FlexRow
                        justifyContent="center"
                        alignItems="center"
                        w="7"
                        h="7"
                    >
                        <Spinner className="border-t-accent-1-400 w-5 h-5" />
                    </FlexRow>
                ) : !question?.answer?.aiSuggestion &&
                  !isAiSuggestionOpen &&
                  !isSuggestPending ? (
                    <Button
                        size="sm"
                        onClick={handleSuggestButtonClick}
                        variant="flatAccent"
                    >
                        {t(keys.ai_suggestion_button)}
                    </Button>
                ) : (
                    <Button
                        size="sm"
                        variant="plain"
                        className="w-fit h-fit p-1.5"
                        onClick={handleToggleOpen}
                    >
                        <FlexRow
                            alignItems="center"
                            className="text-primary"
                            gap="1"
                        >
                            <Text variant="body-sm-bold">
                                {isAiSuggestionOpen
                                    ? t(keys.ai_suggestion_close)
                                    : t(keys.ai_suggestion_open)}
                            </Text>
                            <Button.Icon
                                name={
                                    isAiSuggestionOpen ? "angleUp" : "angleDown"
                                }
                                size="sm"
                            />
                        </FlexRow>
                    </Button>
                )}
            </AiSuggestion.Header>

            <AiSuggestion.Body
                className={cn(!question?.answer?.aiSuggestion && "pb-5")}
            >
                {!isSuggestError && (
                    <AiSuggestion.Block
                        title={t(keys.ai_suggestion_answer)}
                        content={answerText ?? ""}
                        withCopy={aiSuggestionSuccess}
                        withInsert={
                            aiSuggestionSuccess &&
                            question?.answer?.status !== AnswerStatus.Validated
                        }
                        handleInsertLinkClick={handleInsertAnswerLinkClick}
                    />
                )}
                {!!comment && (
                    <AiSuggestion.Block
                        title={t(keys.ai_suggestion_comment)}
                        content={comment ?? ""}
                        withCopy={aiSuggestionSuccess}
                        withInsert={
                            aiSuggestionSuccess &&
                            aiSuggestionSuccess &&
                            question?.answer?.status !== AnswerStatus.Validated
                        }
                        handleInsertLinkClick={handleInsertCommentLinkClick}
                    />
                )}
                {!!motivation && (
                    <AiSuggestion.Block
                        title={t(keys.ai_suggestion_motivation)}
                        content={motivation ?? ""}
                    />
                )}

                {!!suggestedDocuments?.length && (
                    <AiSuggestion.ReferenceBlock
                        title={t(keys.ai_suggestion_references)}
                    >
                        <FlexCol>
                            {suggestedDocuments?.map((document) => (
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="between"
                                    gap="3"
                                    alignSelf="start"
                                    key={document.id}
                                >
                                    <ExtensionTag
                                        name={getFileFormatCategoryFromMimeType(
                                            document?.lastApprovedVersion
                                                ?.finalFile?.mimeType ||
                                                "application/pdf",
                                        )}
                                    />

                                    <DocumentCitationTooltip
                                        document={document}
                                        documentCitation={documentCitations.find(
                                            (citation) =>
                                                citation.documentId ===
                                                document.id,
                                        )}
                                    />

                                    {aiSuggestionSuccess && (
                                        <Box
                                            onClick={handleInsertDocumentLinkClick(
                                                document.id,
                                            )}
                                            className="cursor-pointer p-1 text-primary"
                                        >
                                            <Tooltip>
                                                <Tooltip.Trigger asChild>
                                                    <Box>
                                                        <Icon
                                                            name="check"
                                                            size="sm"
                                                        />
                                                    </Box>
                                                </Tooltip.Trigger>
                                                <Tooltip.Content>
                                                    {t(keys.insert_tooltip)}
                                                </Tooltip.Content>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </FlexRow>
                            ))}
                        </FlexCol>
                    </AiSuggestion.ReferenceBlock>
                )}
                {suggestedIndicators?.length > 0 && (
                    <AiSuggestion.ReferenceBlock
                        title={t(keys.ai_suggestion_reporting)}
                    >
                        <FlexCol gap="2">
                            {suggestedIndicators?.map(
                                (indicator: { id: string; name: string }) => (
                                    <FlexRow key={indicator?.id}>
                                        <a
                                            href={makeIndicatorLink(
                                                indicator?.name,
                                                org_uname,
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary hover:text-beavrGreen"
                                        >
                                            <FlexRow
                                                gap="2"
                                                alignItems="center"
                                            >
                                                <Icon name="ruler" size="sm" />
                                                <Text variant="body-bold">
                                                    {indicator?.name}
                                                </Text>
                                            </FlexRow>
                                        </a>
                                    </FlexRow>
                                ),
                            )}
                        </FlexCol>
                    </AiSuggestion.ReferenceBlock>
                )}
            </AiSuggestion.Body>

            <AiSuggestion.Footer>
                {isSuggestError ? (
                    <AiSuggestion.Confirm
                        onClick={handleSuggestButtonClick}
                        disabled={
                            question?.answer?.status === AnswerStatus.Validated
                        }
                    >
                        {t(keys.ai_suggestion_re_generate)}
                        <Button.Icon name="arrowCircleRight" size="sm" />
                    </AiSuggestion.Confirm>
                ) : (
                    <>
                        <Button
                            variant="plain"
                            size="sm"
                            className="w-fit"
                            onClick={handleSuggestButtonClick}
                            disabled={
                                isSuggestPending ||
                                question?.answer?.status ===
                                    AnswerStatus.Validated
                            }
                        >
                            {t(keys.ai_suggestion_re_generate)}
                            <Button.Icon name="arrowCircleRight" size="sm" />
                        </Button>
                        <AiSuggestion.Confirm
                            onClick={handleConfirmClick}
                            disabled={
                                question?.answer?.status ===
                                AnswerStatus.Validated
                            }
                        >
                            {t(keys.ai_suggestion_confirm)}
                            <Button.Icon name="check" size="sm" />
                        </AiSuggestion.Confirm>
                    </>
                )}
            </AiSuggestion.Footer>
        </AiSuggestion>
    );
};
