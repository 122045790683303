import { addTranslations } from "../../../../i18n";
export function getDepartmentsList() {
    return [
        "quality",
        "sustainability",
        "finance",
        "hr",
        "procurement",
        "sales",
        "it",
        "product",
        "operations",
        "external",
        "executive",
        "other",
    ] as const;
}

export const I18_DEPARTMENTS_NAMESPACE = "user_departments";
addTranslations(I18_DEPARTMENTS_NAMESPACE, {
    quality: {
        en: "Quality, Safety & Risk Management",
        fr: "Qualité, Sécurité & Gestion des Risques",
    },
    sustainability: {
        en: "Sustainability, CSR & Environmental",
        fr: "Développement Durable, RSE & Environnement",
    },
    finance: {
        en: "Finance, Legal & Compliance",
        fr: "Finance, Juridique & Conformité",
    },
    hr: {
        en: "Human Resources",
        fr: "Ressources Humaines",
    },
    procurement: {
        en: "Procurement, Supply Chain & Logistics",
        fr: "Achats, Supply Chain & Logistique",
    },
    sales: {
        en: "Sales, Marketing & Communication",
        fr: "Ventes, Marketing & Communication",
    },
    it: {
        en: "IT & Cybersecurity",
        fr: "Informatique & Cybersécurité",
    },
    product: {
        en: "Product, R&D & Innovation",
        fr: "Produit, R&D & Innovation",
    },
    operations: {
        en: "Operations & Client Success",
        fr: "Opérations & Gestion Client",
    },
    external: {
        en: "External - Audit & Consulting",
        fr: "Externe – Audit & Conseil",
    },
    executive: {
        en: "Executive & Leadership",
        fr: "Direction Générale & Leadership",
    },
    other: {
        en: "Other",
        fr: "Autre",
    },
});
