import { type ClassValue, cn } from "@design-system/Utilities";

export function useGroupStyles(className?: ClassValue) {
    return cn("flex flex-col gap-2", className);
}

export function useFieldStyles(className?: ClassValue) {
    return cn("flex flex-row gap-2 w-full items-center", className);
}

export function useLabelStyles(className?: ClassValue) {
    return cn("cursor-pointer", className);
}
