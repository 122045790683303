import i18next from "@app/i18n";

const enResources = {
    are_you_sure: "Are you sure?",
    after_sending:
        "Once you send your document for review, our team will be notified. Please make sure your document is ready before proceeding.",
    unsend_title: "Edit document",
    unsend_body:
        "Are you sure you want to edit your document? Our team has already been notified of your review request, and frequent back-and-forth changes may delay the review process.",
    cancel: "Cancel",
    confirm: "Confirm",
    ai_title: "Ai review",
    ai_title_loading: "Reviewing your document...",
    checkbox_label:
        "I confirm the document contains all the necessary elements and is ready for review",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    are_you_sure: "Êtes-vous sûr(e) ?",
    after_sending:
        "Une fois votre document envoyé pour révision, notre équipe sera notifiée. Assurez-vous que votre document est prêt avant de continuer.",
    unsend_title: "Modifier le document",
    unsend_body:
        "Êtes-vous sûr(e) de vouloir modifier votre document ? Notre équipe a déjà été notifiée de votre demande de révision, et des modifications fréquentes pourraient ralentir le processus de révision.",
    cancel: "Annuler",
    confirm: "Confirmer",
    ai_title: "Révision par l'IA",
    ai_title_loading: "Révision par l'IA...",
    checkbox_label:
        "Je confirme que le document contient tous les éléments nécessaires et est prêt pour révision",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "SendForReviewModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
