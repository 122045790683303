import { SupportedLocales } from "@app/shared/utils/locales";
import { type QuestionListItem } from "./types";
import { SupplierTemplateQuestionCreateInput } from "@generated/client/graphql";
import { QuestionModalConfirmArg } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/OverviewTab/types";

export const getFirstAvailableTranslation = (question: QuestionListItem) => {
    const availableLocales = Object.values(SupportedLocales);
    const foundLocale = availableLocales.find(
        (locale) => question.nameMultiLocale?.[locale],
    );
    return foundLocale ? question.nameMultiLocale?.[foundLocale] : undefined;
};

export function makeTemplateQuestionCreateInput(
    question: QuestionModalConfirmArg,
): SupplierTemplateQuestionCreateInput {
    if (!question?.nameMultiLocale || !question?.type)
        throw new Error("Invalid template question input");

    return {
        nameMultiLocale: question.nameMultiLocale,
        type: question.type,
        tagIds: question.tagIds,
        descriptionMultiLocale: question.descriptionMultiLocale,
        metadata: question.metadata,
        order: question.order,
    };
}
