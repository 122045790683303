import { currentUserAtom } from "@app/store/userStore";
import Intercom from "@intercom/messenger-js-sdk";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useGetIntercomToken } from "./data";

const INTERCOM_APP_ID = import.meta.env.VITE_REACT_APP_INTERCOM_APP_ID;

export const useIntercom = () => {
    const { data } = useAtomValue(currentUserAtom);
    const { data: tokenData, isPending } = useGetIntercomToken();

    useEffect(() => {
        if (!tokenData?.intercomToken?.token || isPending || !data) return;
        Intercom({
            app_id: INTERCOM_APP_ID,
            api_base: "https://api-iam.eu.intercom.io",
            user_id: data?.currentUser?.id,
            name: `${data?.currentUser?.firstName} ${data?.currentUser?.lastName}`,
            email: data?.currentUser?.email,
            user_hash: tokenData?.intercomToken?.token,
        });
    }, [data, isPending, tokenData]);
};
