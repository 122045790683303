import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex/index.tsx";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "../../i18n";
import { FaqFrameworkModal } from "../FaqFrameworkModal";
import { useNewFrameworkCard } from "./useNewFrameworkCard";

export const NewActiveFrameworkCard = ({
    frameworkName,
    frameworkDescription,
    logoUrl,
    stats,
    assignments,
}: {
    frameworkName: string;
    frameworkDescription: string;
    logoUrl: string;
    stats: {
        answered: number;
        aiSuggestionSuccess: number;
        missing: number;
        total: number;
    };
    assignments: number;
}) => {
    const { t } = useTranslation("Frameworks");
    const { newHeaderStyle } = useNewFrameworkCard();
    return (
        <FlexCol
            as="article"
            br="lg"
            elevation="b-200"
            h="full"
            className="border border-neutral-200 overflow-hidden cursor-pointer"
        >
            {/* Header */}
            <FlexCol
                as="header"
                h="40"
                py="5"
                px="5"
                className="bg-beavrGreen h-40"
                style={newHeaderStyle}
            >
                <FlexRow className="gap-[16px]">
                    <img
                        src={logoUrl}
                        alt={frameworkName}
                        className="w-7 h-7 rounded-full left-0"
                    />
                    <Text as="h6" variant="header2" className="text-white">
                        {frameworkName}
                    </Text>
                </FlexRow>
                <FlexCol py="4" gap="6">
                    <Text className="text-white line-clamp-2">
                        {frameworkDescription}
                    </Text>
                    <Text className="text-white">
                        {t("assignment", {
                            value: assignments,
                        })}
                    </Text>
                </FlexCol>
            </FlexCol>

            <FlexCol p="4" gap="2">
                <ProgressBar
                    className="w-full rounded-sm"
                    segments={[
                        {
                            color: chroma("#03A365"),
                            value: stats.answered,
                            labelValue: `${((stats.answered / (stats.answered + stats.aiSuggestionSuccess + stats.missing)) * 100).toFixed(0)}%`,
                            label: t("datapoints_answered", {
                                count: stats.answered,
                            }),
                        },
                        {
                            color: chroma("#B554F2"),
                            value: stats.aiSuggestionSuccess,
                            labelValue: `${((stats.aiSuggestionSuccess / (stats.answered + stats.aiSuggestionSuccess + stats.missing)) * 100).toFixed(0)}%`,
                            label: t("datapoints_ai_success", {
                                count: stats.aiSuggestionSuccess,
                            }),
                        },
                    ]}
                    total={
                        stats.answered +
                        stats.aiSuggestionSuccess +
                        stats.missing
                    }
                    noDataPlaceholder={t("no_data")}
                />
            </FlexCol>
        </FlexCol>
    );
};

export const NewInactiveFrameworkCard = ({
    frameworkName,
    frameworkDescription,
    logoUrl,
    faq,
    articleLink,
    demoLink,
    websiteLink,
}: {
    frameworkName: string;
    frameworkDescription: string;
    logoUrl: string;
    faq: {
        question: string;
        answer: string;
    }[];
    articleLink: string;
    demoLink: string;
    websiteLink: string;
}) => {
    const { t } = useTranslation("Frameworks");
    const { org_uname } = useParams();
    const navigate = useNavigate();
    const handleHubSpotForm = () => {
        navigate(`/o/${org_uname}/request-access`);
    };
    return (
        <FlexCol
            as="article"
            br="lg"
            elevation="b-200"
            h="full"
            className="border border-neutral-200 overflow-hidden"
        >
            {/* Header */}
            <FlexCol
                as="header"
                h="40"
                py="5"
                px="5"
                className="bg-neutral-100 h-40"
            >
                <FlexRow className="gap-[16px]">
                    {logoUrl !== "logoURL" && (
                        <img
                            src={logoUrl}
                            alt={frameworkName}
                            className="w-7 h-7 rounded-full left-0"
                        />
                    )}
                    <Text
                        as="h6"
                        variant="header2"
                        className="text-neutral-700"
                    >
                        {frameworkName}
                    </Text>
                </FlexRow>
                <FlexRow py="4">
                    <Text className="text-neutral-700 line-clamp-3">
                        {frameworkDescription}
                    </Text>
                </FlexRow>
            </FlexCol>

            {/* Body */}

            <FlexRow justifyContent="start" px="4" py="4" gap="4">
                <Button
                    size="sm"
                    color="beavrGreen"
                    className="text-white border-[#028C64] w-140 h-32 hover:bg-[#028C64]"
                    onClick={handleHubSpotForm}
                >
                    {t("request_access")}
                    <Icon name="sale" />
                </Button>
                <FaqFrameworkModal
                    frameworkName={frameworkName}
                    frameworkDescription={frameworkDescription}
                    logoUrl={logoUrl}
                    faq={faq}
                    articleLink={articleLink}
                    demoLink={demoLink}
                    websiteLink={websiteLink}
                />
            </FlexRow>
        </FlexCol>
    );
};
