import { Icon, IconName } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    AiAnswerStatusFilter,
    QuestionStatusFilter,
} from "@generated/client/graphql";
import { useQuestionsTabTranslations } from "./i18n";

type StatusItem = {
    label: string;
    value: string;
    icon: IconName;
    iconColor?: string;
};

const questionStatuses: StatusItem[] = [
    {
        label: "question_status_not_started",
        value: QuestionStatusFilter.NotStarted,
        icon: "circleTodo",
    },
    {
        label: "question_status_in_progress",
        value: QuestionStatusFilter.InProgress,
        icon: "circleInProgress",
    },
    {
        label: "question_status_validated",
        value: QuestionStatusFilter.Validated,
        icon: "circleValidated",
    },
];

const aiAnswerStatuses: StatusItem[] = [
    {
        label: "question_status_not_started",
        value: AiAnswerStatusFilter.NotStarted,
        icon: "minus",
    },
    {
        label: "question_status_ai_suggestion_success",
        value: AiAnswerStatusFilter.AiSuggestionSuccess,
        icon: "stars",
        iconColor: "text-accent-1-400",
    },
    {
        label: "question_status_ai_suggestion_failed",
        value: AiAnswerStatusFilter.AiSuggestionFailed,
        icon: "warning",
        iconColor: "text-warning-600",
    },
];

export const QuestionStatusMultiSelect = <
    T extends QuestionStatusFilter | AiAnswerStatusFilter,
>({
    selectedStatuses,
    setStatuses,
    statusType,
    triggerLabel,
}: {
    selectedStatuses: T[];
    setStatuses: (selectedStatuses: T[]) => void;
    statusType: "question" | "aiAnswer";
    triggerLabel?: string;
}) => {
    const handleStatusChange = (values: (string | null)[]) => {
        setStatuses(values.filter((id) => id !== null) as T[]);
    };

    const { t } = useQuestionsTabTranslations();
    const statuses =
        statusType === "question" ? questionStatuses : aiAnswerStatuses;

    const active = !!selectedStatuses?.length;
    return (
        <MultiSelect
            values={selectedStatuses || []}
            onValuesChange={handleStatusChange}
        >
            <MultiSelect.Trigger>
                <Button active={active} variant="outlined" size="sm">
                    {triggerLabel}
                    <Button.Icon name={"angleDown"} />
                </Button>
            </MultiSelect.Trigger>
            <MultiSelect.Content>
                {statuses?.map((status) => (
                    <MultiSelect.Item key={status.value} value={status.value}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                gap="3"
                                br="lg"
                                className="flex-1"
                                w="full"
                            >
                                <FlexRow alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </FlexRow>
                                <Icon
                                    name={status.icon}
                                    className={status.iconColor}
                                />
                                <Text>{t(status.label)}</Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};
