import { atom } from "jotai";
import { Dispatch, SetStateAction } from "react";
import {
    ArrayParam,
    NumberParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params";

export const editQuestionIdAtom = atom<string | undefined>(undefined);

export const filterParamArgs = {
    tagFilter: ["tagFilter", withDefault(ArrayParam, [])] as const,
    search: ["search", withDefault(StringParam, undefined)] as const,
} as const;

const paginationParamArgs = {
    pageSize: ["pageSize", withDefault(NumberParam, 10)] as const,
    pageIndex: ["pageIndex", withDefault(NumberParam, 0)] as const,
} as const;

export const usePagination = () => {
    const [pageSize, setPageSize] = useQueryParam(
        ...paginationParamArgs.pageSize,
    );
    const [pageIndex, setPageIndex] = useQueryParam(
        ...paginationParamArgs.pageIndex,
    );

    const onPaginationChange: Dispatch<
        SetStateAction<{ pageSize: number; pageIndex: number }>
    > = (newState) => {
        if (typeof newState === "function") {
            const currentState = { pageSize, pageIndex };
            const updatedState = newState(currentState);
            setPageSize(updatedState.pageSize);
            setPageIndex(updatedState.pageIndex);
            return;
        }
        setPageSize(newState.pageSize);
        setPageIndex(newState.pageIndex);
    };

    return {
        // table state
        pagination: {
            state: {
                pageSize,
                pageIndex,
            },
            onPaginationChange,
            manualPagination: true,
        },
        // API
        take: pageSize,
        skip: pageSize * pageIndex,
    };
};
