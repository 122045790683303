import i18next from "../../../../../i18n";

const enResources = {
    title: "Modify entity",
    subtitle: "Modify name, email, role or department",
    name: "Name",
    shortName: "Short name",
    type: "Type",
    site_update_success: "Entity updated successfully",
    site_update_error: "Error updating entity",
    cancel: "Cancel",
    save: "Save",
    set_as_parent: "Set as parent entity",
    set_as_children: "Set as children of {{entity}}",
};

type ResourcesKeys = keyof typeof enResources;
const frResources = {
    title: "Modifier l'entité",
    subtitle: "Modifier le nom, l'email, le rôle ou le département",
    name: "Nom",
    shortName: "Nom court",
    type: "Type",
    site_update_success: "Entité mise à jour avec succès",
    site_update_error: "Erreur lors de la mise à jour de l'entité",
    cancel: "Annuler",
    save: "Enregistrer",
    set_as_parent: "Configurer en tant qu'entité parente",
    set_as_children: "Configurer en tant qu'entité enfant de {{entity}}",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "UpdateSiteModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
