import Checkbox from "@app/components/Checkbox";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useSupplierQuestionnaires } from "./data";
import "./i18n";

export const SupplierQuestionnaireMultiSelect: FC<{
    isHeader?: boolean;
    questionnaireIds: (string | null)[] | undefined;
    setQuestionnaireIds: (questionnaireIds: (string | null)[]) => void;
    side?: "left" | "right" | "top" | "bottom";
}> = ({ isHeader, questionnaireIds, setQuestionnaireIds, side }) => {
    const { t } = useTranslation("SupplierQuestionnaireMultiSelect");

    const { questionnaires } = useSupplierQuestionnaires();

    const active = !!questionnaireIds?.length;
    return (
        <MultiSelect
            values={questionnaireIds || []}
            onValuesChange={setQuestionnaireIds}
        >
            <MultiSelect.Trigger>
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("questionnaire_title", {
                            context: active && "active",
                            count: questionnaireIds?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <Button active={active} variant="outlined" size="sm">
                        {t("questionnaire_title")}
                        <Button.Icon name={"angleDown"} />
                    </Button>
                )}
            </MultiSelect.Trigger>
            <MultiSelect.Content side={side}>
                {questionnaires?.map((questionnaire) => (
                    <MultiSelect.Item
                        key={questionnaire.id}
                        value={questionnaire.id}
                    >
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                gap="3"
                                br="lg"
                                className="flex-1"
                            >
                                <FlexRow alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </FlexRow>
                                <Text>{questionnaire.name}</Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};
