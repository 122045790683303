import { Icon as IconBase, type IconProps } from "@design-system/Icon";
import { cn } from "@design-system/Utilities";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import {
    createContext,
    useContext,
    type ButtonHTMLAttributes,
    type FC,
    type Ref,
} from "react";
import "./index.css";

const buttonVariants = cva(
    "inline-flex items-center justify-center font-bold box-border border border-transparent whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:text-disabled disabled:bg-tertiary disabled:border-tertiary rounded-lg h-fit",
    {
        variants: {
            variant: {
                flatPrimary: [
                    "bg-green border-brand_secondary text-white shadow-b-100",
                    "hover:bg-brand_secondary",
                    "active:bg-brand_tertiary active:shadow-none",
                ],
                flatDanger: [
                    "bg-danger border-danger text-white shadow-b-100",
                    "hover:bg-danger_secondary hover:border-error-400",
                    "active:bg-error-600 active:border-error-600 active:shadow-none",
                ],
                flatAccent: [
                    "[&:not(:disabled)]:bg-gradient-to-tr from-[#C238CC] to-[#B554F2] border-accent-1-500 text-white shadow-b-100",
                    "hover:from-accent-1-400 hover:to-accent-1-400",
                    "active:from-accent-1-500 active:to-accent-1-500 active:shadow-none",
                ],

                tonal: [
                    "bg-secondary border-secondary text-primary shadow-b-100",
                    "hover:bg-primary",
                    "active:bg-tertiary active:shadow-none",
                ],
                outlined: [
                    "text-primary border-secondary bg-white shadow-b-100",
                    "hover:bg-secondary",
                    "active:bg-tertiary active:border-secondary active:shadow-none",
                ],
                outlinedDanger: [
                    "text-primary border-error-500 bg-white",
                    "hover:bg-secondary",
                    "active:bg-tertiary active:shadow-none",
                ],
                text: [
                    "text-primary shadow-none",
                    "hover:bg-secondary hover:shadow-b-100",
                    "active:bg-tertiary active:shadow-none",
                ],
                /*
                 * Plain is also used for SegmentButton,
                 * when changing this, change the __segment variant too
                 */
                plain: [
                    "text-secondary shadow-none",
                    "hover:bg-secondary hover:shadow-b-100 hover:text-primary",
                    "active:bg-tertiary active:shadow-none",
                ],
            },

            size: {
                xs: "px-1.5 py-0.5 gap-1 text-sm leading-none min-h-5 min-w-5",
                sm: "p-1.5 gap-1 text-sm leading-none max-h-7 min-w-7 box-border",
                md: "p-2 gap-1 text-base min-h-8 min-w-8",
                lg: "p-3 gap-2 text-lg leading-none min-h-[48px] min-w-[48px]",
            },

            active: {
                true: "",
            },

            loading: {
                true: "cursor-wait pointer-events-none",
            },
            /**
             * SegmentButton variant, used for <SegmentButton.Item.
             * I didn't want to create a new variant for this, as it would appear
             * in ts suggestions, and it's not a real variant.
             */
            __segment: {
                true: "w-full shadow-none rounded-none hover:shadow-none border-0 border-r border-r-secondary __SegmentButton__item",
            },
        },
        /*
         * loading props must be flagged with "!" as a loading button
         * is also disabled and the disabled selector is more specific
         */
        compoundVariants: [
            {
                variant: "flatPrimary",
                loading: true,
                className: "!bg-brand_tertiary !text-white",
            },
            {
                variant: ["flatPrimary", "outlinedDanger"],
                active: true,
                className:
                    "bg-primary border-green Beavr_btn__inner_border text-primary",
            },
            {
                variant: "flatDanger",
                loading: true,
                className: "!bg-error-600 !border-error-600 !text-white",
            },
            {
                variant: ["flatDanger", "tonal", "text", "plain"],
                active: true,
                className: "bg-secondary text-primary",
            },
            {
                variant: "flatAccent",
                loading: true,
                className:
                    "!bg-gradient-to-tr !from-accent-1-500 !to-accent-1-500 !text-white",
            },
            {
                variant: ["tonal", "outlined"],
                loading: true,
                className: "!bg-secondary !border-tertiary !text-green",
            },
            {
                variant: "outlined",
                active: true,
                className:
                    "bg-tertiary border-green Beavr_btn__inner_border text-primary",
            },
            {
                variant: "outlinedDanger",
                loading: true,
                className: "!text-error-500 !bg-secondary !text-error-500",
            },
            {
                variant: ["text", "plain"],
                loading: true,
                className: "!bg-secondary !text-green !shadow-b-100",
            },
            {
                variant: ["text", "plain"],
                active: true,
                className: "bg-secondary shadow-b-100 text-brand",
            },
        ],
        defaultVariants: {
            variant: "flatPrimary",
            size: "md",
        },
    },
);

export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}
type ButtonContextType = VariantProps<typeof buttonVariants>;

const buttonContext = createContext<ButtonContextType | null>(null);

const ButtonIcon: FC<IconProps & { ref?: Ref<SVGSVGElement> }> = ({
    ref,
    ...props
}) => {
    const ctx = useContext(buttonContext);

    if (!ctx) {
        throw new Error("Button.Icon must be used within a Button");
    }

    const size = props.size ?? ctx.size ?? "md";

    return <IconBase {...props} size={size} ref={ref} />;
};

type ButtonComponentType = FC<
    ButtonProps & { ref?: Ref<HTMLButtonElement | null> }
> & {
    Icon: typeof ButtonIcon;
};

const Button: ButtonComponentType = ({
    __segment,
    active,
    className,
    loading,
    variant,
    size,
    asChild = false,
    children,
    ref,
    ...props
}) => {
    const Comp = asChild ? Slot : "button";
    return (
        <buttonContext.Provider value={{ active, size, variant }}>
            <Comp
                className={cn(
                    buttonVariants({
                        variant,
                        size,
                        active,
                        loading,
                        className,
                        __segment,
                    }),
                )}
                ref={ref}
                {...props}
                disabled={loading || props.disabled}
            >
                {loading ? (
                    <ButtonIcon name="loader" className="animate-spin" />
                ) : (
                    children
                )}
            </Comp>
        </buttonContext.Provider>
    );
};

Button.Icon = ButtonIcon;

export { Button, buttonVariants };
