import { CloudUpload } from "iconoir-react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

interface ClickDragNDropProps {
    onFilesChange?: (files: File[]) => void;
    onFileChange?: (file: File) => void;
    multiple?: boolean;
    required?: boolean;
}

export default function ClickDragNDrop({
    onFilesChange,
    onFileChange,
    multiple,
    required,
}: ClickDragNDropProps) {
    const { t } = useTranslation("ClickDragNDrop");
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            multiple &&
                onFilesChange &&
                onFilesChange([...e.dataTransfer.files]);
            !multiple && onFileChange && onFileChange(e.dataTransfer.files[0]);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            multiple && onFilesChange && onFilesChange([...e.target.files]);
            !multiple && onFileChange && onFileChange(e.target.files[0]);
        }
        if (inputRef.current) {
            inputRef.current.files = null;
            inputRef.current.value = "";
        }
    };

    return (
        <div
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            className={`border w-full px-6 py-5 rounded  border-dashed ${
                dragActive
                    ? "bg-green-50 border-green-500"
                    : "border-secondary bg-secondary"
            }`}
        >
            <label className={`flex space-x-5 items-center `}>
                <input
                    ref={inputRef}
                    type="file"
                    multiple={multiple || false}
                    onChange={handleChange}
                    className="hidden"
                />
                <CloudUpload className="w-5 h-5 text-secondary" />
                <div className="text-secondary text-sm font-regulat">
                    {required && (
                        <span className="text-danger">
                            {t("must_provide_document")}
                            <br />
                        </span>
                    )}
                    {multiple ? t("line_1_multiple") : t("line_1_single")}
                    <br />
                    {t("line_2")}
                </div>
            </label>
        </div>
    );
}
