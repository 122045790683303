import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { CsrdDatapointStatusMultiSelect } from "./components/DatapointStatusMultiSelect";
import { DocumentsModal } from "@app/shared/components/documentUpload/DocumentsModal";
import { MaterialityModal } from "./components/MaterialityModal";
import { PillarBody } from "./components/PillarBody";
import { PillarHeader } from "./components/PillarHeader";
import { PillarSidebar } from "./components/PillarSidebar";
import {
    ownerIdsFilterAtom,
    searchStringAtom,
    statusesFilterAtom,
    userMultiSelectSearchAtom,
} from "./ctx";
import { useCsrdBreadcrumb } from "./hooks/useCsrdBreadcrumb";

export const Pillar = () => {
    const { pillar_id } = useParams();
    const [searchString, setSearchString] = useAtom(searchStringAtom);
    const [ownerIds, setOwnerIds] = useAtom(ownerIdsFilterAtom);
    const [userMultiSelectSearch, setUserMultiSelectSearch] = useAtom(
        userMultiSelectSearchAtom,
    );
    const [statuses, setStatuses] = useAtom(statusesFilterAtom);

    useCsrdBreadcrumb();

    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                <PillarHeader pillarId={pillar_id} />
                <FlexRow alignItems="start">
                    <FlexRow alignItems="center" gap="4">
                        <SearchBar
                            searchString={searchString}
                            setSearchString={setSearchString}
                            placeholder="Search datapoint"
                            className="w-[222px]"
                        />
                        <CsrdDatapointStatusMultiSelect
                            values={statuses}
                            onValuesChange={setStatuses}
                        />
                        <UserMultiSelect
                            values={ownerIds}
                            onValuesChange={setOwnerIds}
                            searchFilter={userMultiSelectSearch}
                            onSearchFilterChange={setUserMultiSelectSearch}
                            includeNone={true}
                        />
                    </FlexRow>
                </FlexRow>
                <FlexRow gap="4" alignItems="start" h="screen">
                    <PillarSidebar />
                    <PillarBody pillarId={pillar_id} />
                </FlexRow>
            </FlexCol>
            <DocumentsModal />
            <MaterialityModal />
        </PageContainer>
    );
};
