import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import {
    ObjectiveStatus,
    ResponseStatus,
    UpdateObjectiveInput,
} from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { activeEntityIdAtom } from "../../ctx";
import { useDeleteObjectives, useUpdateObjectives } from "../../data";
import "../../i18n";
import { StatusTagSelect } from "../../objective/components/ObjectiveHeader/StatusTagSelect";
import { keys, RESOURCE_NAME } from "./i18n";
import { Objective } from "./types";

enum Actions {
    ASSIGN_OWNER = "ASSIGN_OWNER",
    ASSIGN_STATUS = "ASSIGN_STATUS",
    DELETE = "DELETE",
}

type ActionType = (typeof Actions)[keyof typeof Actions];

const ObjectiveTableActionBar = ({
    objectives,
    unset,
}: {
    objectives: Objective[];
    unset: () => void;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [activeAction, setActiveAction] = useState<ActionType | undefined>(
        undefined,
    );
    const [ownerId, setOwnerId] = useState<string | null | undefined>(null);
    const [status, setStatus] = useState<ObjectiveStatus>(
        ObjectiveStatus.Defined,
    );

    const [searchOwnerFilter, setSearchOwnerFilter] = useState<string>();
    const { mutate, isPending } = useUpdateObjectives(
        objectives.map((o) => o.id),
    );
    const activeEntityId = useAtomValue(activeEntityIdAtom);

    const { mutateAsync: mutateAsyncDelete, isPending: isPendingDelete } =
        useDeleteObjectives(
            objectives.map((o) => o.id),
            activeEntityId,
        );

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const { setToastSuccess, setToastError } = useSetToast();

    const onActionValueChange = (actionId: ActionType) => {
        setActiveAction(actionId);
    };

    const onOwnerApply = () => {
        const ids = objectives.map((o) => o.id);

        if (!ids.length) {
            return;
        }
        const inputs: UpdateObjectiveInput[] = objectives.map((o) => ({
            id: o.id,
            ownerId: ownerId || null,
        }));

        mutate(inputs, {
            onSuccess: () => {
                setToastSuccess(t(keys.update_success));
                unset();
                setActiveAction(undefined);
                setOwnerId(null);
            },
            onError: () => {
                setToastError(t(keys.update_error));
            },
        });
    };

    const onStatusApply = () => {
        const ids = objectives.map((o) => o.id);
        if (!ids.length) {
            return;
        }
        const inputs: UpdateObjectiveInput[] = objectives.map((o) => ({
            id: o.id,
            status: status,
        }));

        mutate(inputs, {
            onSuccess: () => {
                setToastSuccess(t(keys.update_success));
                unset();
                setActiveAction(undefined);
                setStatus(ObjectiveStatus.Defined);
            },
            onError: () => {
                setToastError(t(keys.update_error));
            },
        });
    };

    const onDeleteApply = async () => {
        const ids = objectives.map((o) => o.id);

        if (!ids.length) {
            return;
        }

        const result = await mutateAsyncDelete(ids);

        const { deleteObjectives } = result;

        if (deleteObjectives.status === ResponseStatus.Error) {
            setToastError(t(keys.delete_error));
        }

        unset();
        setActiveAction(undefined);
        setOpenDeleteModal(false);
    };

    const triggerDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    return (
        <>
            <Floatbar
                unset={unset}
                text={t(keys.objective_table_action_selection, {
                    count: objectives.length,
                })}
                open={objectives.length > 0}
                primary={
                    <Select
                        value={activeAction}
                        onValueChange={onActionValueChange}
                    >
                        <Select.Trigger asChild>
                            <Button variant="outlined" size="sm">
                                <Text variant="body-sm-bold">
                                    {t(
                                        keys.objective_table_action_select_action,
                                    )}
                                </Text>
                                <Button.Icon name="angleDown" size="sm" />
                            </Button>
                        </Select.Trigger>
                        <Select.Content>
                            <Select.Item
                                value={Actions.ASSIGN_OWNER}
                                className="text-beavrGreen"
                            >
                                <Icon name="userPlus" size="sm"></Icon>
                                <Text
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(
                                        keys.objective_table_action_assign_owner_action,
                                    )}
                                </Text>
                            </Select.Item>
                            <Select.Item value={Actions.ASSIGN_STATUS}>
                                <Icon
                                    name="circleLoading"
                                    size="sm"
                                    className="p-0.5"
                                />
                                <Text
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(
                                        keys.objective_table_action_assign_status_action,
                                    )}
                                </Text>
                            </Select.Item>
                            <Select.Item value={Actions.DELETE}>
                                <Icon name="trash" size="sm" />
                                <Text
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(
                                        keys.objective_table_action_delete_action,
                                    )}
                                </Text>
                            </Select.Item>
                        </Select.Content>
                    </Select>
                }
                secondary={<></>}
                actions={[
                    {
                        id: Actions.ASSIGN_OWNER,
                        text: t(keys.objective_table_action_selection, {
                            count: objectives.length,
                        }),
                        selector: (
                            <UserChipSelect
                                userId={ownerId}
                                setUserId={setOwnerId}
                                searchFilter={searchOwnerFilter}
                                onSearchFilterChange={setSearchOwnerFilter}
                                showUnsetByDefault={true}
                            />
                        ),
                        apply: (
                            <Button
                                size="sm"
                                onClick={onOwnerApply}
                                disabled={ownerId === null}
                                loading={isPending}
                            >
                                {t(keys.apply)}
                            </Button>
                        ),
                    },
                    {
                        id: Actions.ASSIGN_STATUS,
                        text: t(keys.objective_table_action_assign_status, {
                            count: objectives.length,
                        }),
                        selector: (
                            <StatusTagSelect
                                status={status}
                                setStatus={setStatus}
                                className="bg-primary"
                            />
                        ),
                        apply: (
                            <Button
                                size="sm"
                                onClick={onStatusApply}
                                disabled={status === null}
                                loading={isPending}
                            >
                                {t(keys.apply)}
                            </Button>
                        ),
                    },
                    {
                        id: Actions.DELETE,
                        text: t(keys.objective_table_action_delete, {
                            count: objectives.length,
                        }),
                        apply: (
                            <Button size="sm" onClick={triggerDeleteModal}>
                                {t(keys.apply)}
                            </Button>
                        ),
                    },
                ]}
                activeAction={activeAction}
                setActiveAction={setActiveAction}
            />

            <Modal
                open={openDeleteModal}
                onOpenChange={setOpenDeleteModal}
                aria-label={t(keys.delete_objectives_modal_title)}
            >
                <Modal.Overlay centerContent>
                    <Modal.Content>
                        <Modal.Header>
                            <Modal.Title>
                                {t(keys.delete_objectives_modal_title)}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Text>
                                {t("delete_objectives_modal_description", {
                                    count: objectives.length,
                                })}
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                            <Modal.ConfirmCTA
                                onClick={onDeleteApply}
                                variant="flatDanger"
                                loading={isPendingDelete}
                            >
                                {t(keys.delete_objectives_modal_confirm)}
                            </Modal.ConfirmCTA>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Overlay>
            </Modal>
        </>
    );
};

export default ObjectiveTableActionBar;
