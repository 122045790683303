import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "error-boundary";

const keys = addTranslations(RESOURCE_NAME, {
    error: {
        en: "Oups something went wrong !",
        fr: "Oups, une erreur est survenue !",
    },
    errorDescription: {
        en: "Please reload the page or contact support if the issue persists.",
        fr: "Veuillez recharger la page ou contacter le support si le problème persiste.",
    },
    reloadPage: {
        en: "Reload page",
        fr: "Recharger la page",
    },
});

export function useErrorBoundaryTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
