import { useNavigation } from "@app/lib/navigation";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Tabs } from "@design-system/Tabs";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { questionnaireTabs } from "../services";

import { useSetToast } from "@app/components/Toast";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Text } from "@design-system/Typography/Text";
import { useParams } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { DeleteQuestionnaireModal } from "../components/EditOrDeleteDropdown/DeleteQuestionnaireModal";
import { EditQuestionnaireModal } from "../components/EditOrDeleteDropdown/EditQuestionnaireModal";
import { useDeleteExternalQuestionnaire } from "../data";
import { OverviewTab } from "./components/OverviewTab";
import { CreateQuestionsModal } from "./components/OverviewTab/components/CreateQuestionsModal";
import { QuestionnaireHeader } from "./components/QuestionnaireHeader";
import { QuestionsTab } from "./components/QuestionsTab";
import { questionnaireParamsArgs } from "./ctx";
import { useExportQuestionnaire } from "./data";
import { useExternalQuestionnaireBreadcrumb } from "./hooks/useExternalQuestionnairesBreadcrumb";
import { keys, RESOURCE_NAME } from "./i18n";
import { ExternalQuestionnaireOptions } from "./types";

interface ExternalQuestionnaireProps {
    id: string | undefined;
    options: ExternalQuestionnaireOptions;
}

export const ExternalQuestionnaire: FC<ExternalQuestionnaireProps> = ({
    id,
    options,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const [activeTabParam, setActiveTabParam] = useQueryParam(
        ...questionnaireParamsArgs.tab,
    );

    const [, setGroupParam] = useQueryParam(...questionnaireParamsArgs.group);
    const { setToastSuccess, setToastError } = useSetToast();
    const activeTab = activeTabParam || questionnaireTabs.overview;
    const { navigateFromHome } = useNavigation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const {
        mutate: exportQuestionnaire,
        isPending: isExportingQuestionnairePending,
    } = useExportQuestionnaire();
    const handleDeleteQuestionnaireClick = () => {
        setOpenDeleteModal(true);
    };

    const handleEditQuestionnaireClick = () => {
        setOpenEditModal(true);
    };

    const handleExportQuestionnaireClick = () => {
        if (!id) return;
        exportQuestionnaire(
            {
                questionnaireId: id,
            },
            {
                onError: () => {
                    setToastError(t(keys.export_questionnaire_error));
                },
            },
        );
    };

    const { deleteExternalQuestionnaire, isPending } =
        useDeleteExternalQuestionnaire();

    const handleDeleteApply = (id: string | undefined) => {
        return () => {
            if (!id) return;
            deleteExternalQuestionnaire(id, {
                onSuccess: () => {
                    setToastSuccess(t(keys.delete_questionnaire_success));
                },
                onError: () => {
                    setToastError(t(keys.delete_questionnaire_error));
                },
            });
            navigateFromHome(
                options?.isFrameworkQuestionnaire
                    ? "frameworks"
                    : "external_questionnaires",
            );
        };
    };

    const handleActiveTabChange = (tab: string) => {
        setActiveTabParam(tab);
        setGroupParam(undefined);
    };

    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                <QuestionnaireHeader
                    questionnaireId={id}
                    bgColor={options?.themeColor}
                />
                <FlexCol gap="4">
                    <FlexRow
                        justifyContent="between"
                        alignItems="end"
                        gap="3"
                        w="full"
                    >
                        <Tabs
                            activeTab={activeTab}
                            onActiveTabChange={handleActiveTabChange}
                            size="md"
                            className="w-full"
                        >
                            <Tabs.Trigger
                                value={questionnaireTabs.overview}
                                label={t(keys.tabs_tab_overview)}
                            />
                            <Tabs.Trigger
                                value={questionnaireTabs.questions}
                                label={t(keys.tabs_tab_questions)}
                            />
                        </Tabs>
                        {activeTab === questionnaireTabs.overview &&
                        options?.canEditQuestions ? (
                            <CreateQuestionsModal questionnaireId={id} />
                        ) : null}
                        <Dropdown>
                            <Dropdown.Trigger asChild>
                                <Button
                                    variant="tonal"
                                    loading={isExportingQuestionnairePending}
                                >
                                    <Icon name="more" />
                                </Button>
                            </Dropdown.Trigger>
                            <Dropdown.Content hasPortal>
                                <Dropdown.Item
                                    onClick={handleExportQuestionnaireClick}
                                >
                                    <Dropdown.ItemIcon name="download" />
                                    <Text variant="body">
                                        {t(keys.export_questionnaire)}
                                    </Text>
                                </Dropdown.Item>
                                {options.canEditQuestionnaire && (
                                    <Dropdown.Item
                                        onClick={handleEditQuestionnaireClick}
                                    >
                                        <Dropdown.ItemIcon name="edit" />
                                        <Text variant="body">
                                            {t(keys.edit_questionnaire)}
                                        </Text>
                                    </Dropdown.Item>
                                )}
                                {options.canDeleteQuestionnaire && (
                                    <Dropdown.Item
                                        variant="negative"
                                        onClick={handleDeleteQuestionnaireClick}
                                    >
                                        <Dropdown.ItemIcon name="trash" />
                                        <Text variant="body">
                                            {t(keys.delete_questionnaire)}
                                        </Text>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Content>
                        </Dropdown>
                    </FlexRow>

                    {activeTab === questionnaireTabs.overview ? (
                        <OverviewTab questionnaireId={id} options={options} />
                    ) : activeTab === questionnaireTabs.questions ? (
                        <QuestionsTab questionnaireId={id} />
                    ) : null}
                </FlexCol>
            </FlexCol>
            <DeleteQuestionnaireModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                onDeleteApply={handleDeleteApply(id)}
                isPendingDelete={isPending}
            />
            <EditQuestionnaireModal
                questionnaireId={id}
                open={openEditModal}
                onOpenChange={setOpenEditModal}
            />
        </PageContainer>
    );
};

export const ExternalQuestionnairePage = () => {
    const { external_questionnaire_id } = useParams();
    useExternalQuestionnaireBreadcrumb(external_questionnaire_id);

    return (
        <ExternalQuestionnaire
            id={external_questionnaire_id}
            options={{
                canEditQuestions: true,
                canDeleteQuestionnaire: true,
                canEditQuestionnaire: true,
                isFrameworkQuestionnaire: false,
            }}
        />
    );
};
