import { ClassValue, cn } from "@design-system/Utilities";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { cva } from "class-variance-authority";
import { PropsWithChildren, Ref, type FC } from "react";

export type TooltipVariant = "dark" | "wideLight";

const tooltipVariants = cva("z-50 rounded-lg min-h-5", {
    variants: {
        variant: {
            dark: "bg-neutral-900 text-white font-bold text-sm py-1 px-2",
            wideLight:
                "bg-white text-primary p-3 border border-tertiary shadow-b-300",
        },
    },
    defaultVariants: {
        variant: "dark",
    },
});

const arrowVariants = cva("", {
    variants: {
        variant: {
            dark: "w-[18px] h-[6px] fill-neutral-900",
            wideLight: "w-[0px] h-[0px]",
        },
    },
    defaultVariants: {
        variant: "dark",
    },
});
/**
 * It's a separate component because we need to wrap the tooltip
 * and
 * ```
 * <Tooltip.Provider>
 *      <Tooltip> ...</Tooltip>
 * </Tooltip.Provider>
 * ```
 * is ugly.
 */
export const TooltipProvider = RadixTooltip.Provider;

const Trigger = RadixTooltip.Trigger;

type TooltipContentProps = {
    className?: string;
    sideOffset?: number;
    arrowClassName?: ClassValue;
    variant?: TooltipVariant;
    ref?: Ref<HTMLDivElement>;
} & RadixTooltip.TooltipContentProps;

const Content: FC<PropsWithChildren<TooltipContentProps>> = ({
    children,
    className,
    sideOffset = 5,
    arrowClassName,
    ref,
    variant,
    ...props
}) => (
    <RadixTooltip.Portal>
        <RadixTooltip.Content
            ref={ref}
            {...props}
            sideOffset={sideOffset}
            className={cn(tooltipVariants({ variant }), className)}
        >
            {children}
            <RadixTooltip.Arrow
                className={cn(arrowVariants({ variant }), arrowClassName)}
            />
        </RadixTooltip.Content>
    </RadixTooltip.Portal>
);

type TooltipType = FC<RadixTooltip.TooltipProps> & {
    Trigger: typeof Trigger;
    Content: typeof Content;
};
export const Tooltip: TooltipType = ({ delayDuration, ...props }) => (
    <RadixTooltip.Root delayDuration={delayDuration ?? 0} {...props} />
);
Tooltip.Trigger = Trigger;
Tooltip.Content = Content;
