import { Icon, type IconProps } from "@design-system/Icon";
import { useSelect, type SelectProps } from "@design-system/internal/select";
import { Box, type BoxProps } from "@design-system/Layout/Box";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { cn, useControllableState } from "@design-system/Utilities";
import * as Popover from "@radix-ui/react-popover";
import {
    createContext,
    forwardRef,
    Fragment,
    useCallback,
    useContext,
    type FC,
    type KeyboardEvent,
    type PointerEvent,
    type PropsWithChildren,
    type RefObject,
} from "react";
import { Button } from "../Button";
import { Checkbox, CheckboxProps } from "../Checkbox";
import {
    useDropdownContentStyles,
    useDropdownItemIconStyles,
    useDropdownItemStyles,
    useDropdownItemTextStyles,
} from "./styles";

/* -------------------------------------------------------------------------------------------------
 * Dropdown Context
 * -----------------------------------------------------------------------------------------------*/
type DropdownCtxType = {
    value?: string[];
    handleSelect?: (value: string) => void;
};

const DropdownCtx = createContext<DropdownCtxType | null>(null);
const useDropdownCtx = () => {
    const ctx = useContext(DropdownCtx);
    if (!ctx)
        throw new Error(
            "useDropdownCtx must be used within a DropdownCtx provider",
        );
    return ctx;
};

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Context
 * -----------------------------------------------------------------------------------------------*/
type DropdownItemCtxType = {
    selected: boolean;
    negative?: boolean;
};

const DropdownItemCtx = createContext<DropdownItemCtxType | null>(null);
const useDropdownItemCtx = () => {
    const ctx = useContext(DropdownItemCtx);
    if (!ctx)
        throw new Error(
            "useDropdownItemCtx must be used within a DropdownItemCtx provider",
        );
    return ctx;
};

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Base
 * -----------------------------------------------------------------------------------------------*/
type DropdownItemProps = BoxProps & { active?: boolean; variant?: "negative" };
const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
    ({ active, variant, ...props }, ref) => {
        const className = useDropdownItemStyles(
            { active, negative: variant === "negative" },
            props.className,
        );
        return <Box ref={ref} tabIndex={1} {...props} className={className} />;
    },
);

/* -------------------------------------------------------------------------------------------------
 * Dropdown Selectable Item
 * -----------------------------------------------------------------------------------------------*/

const DropdownSelectableItem = forwardRef<
    HTMLDivElement,
    DropdownItemProps & { value: string }
>(({ children, onKeyDown, onClick, value, variant, ...props }, ref) => {
    const { handleSelect, value: selectedValue } = useDropdownCtx();
    const isSelected = !!selectedValue?.includes(value);
    const active =
        typeof props.active === "boolean" ? props.active : isSelected;

    const handleClick = useCallback(
        (evt: PointerEvent<HTMLDivElement>) => {
            handleSelect?.(value);
            onClick?.(evt);
        },
        [handleSelect, value, onClick],
    );

    const handleKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter" || evt.key === " ") {
            handleSelect?.(value);
        }
        return onKeyDown?.(evt);
    };

    return (
        <DropdownItemCtx.Provider
            value={{
                selected: isSelected,
                negative: variant === "negative",
            }}
        >
            <DropdownItem
                {...props}
                active={active}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                ref={ref}
                variant={variant}
            >
                {children}
            </DropdownItem>
        </DropdownItemCtx.Provider>
    );
});

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Text
 * -----------------------------------------------------------------------------------------------*/
const DropdownItemText = forwardRef<HTMLDivElement, TextProps>(
    ({ className: propsClassName, ...props }, ref) => {
        const className = useDropdownItemTextStyles(propsClassName);
        return <Text ref={ref} {...props} className={className} />;
    },
);

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Icon
 * -----------------------------------------------------------------------------------------------*/
const DropdownItemIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className: propsClassName, ...props }, ref) => {
        const className = useDropdownItemIconStyles(propsClassName);
        return <Icon ref={ref} {...props} className={className} />;
    },
);

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Single Select Indicator
 * -----------------------------------------------------------------------------------------------*/
const DropdownItemSelectedIndicator = () => {
    const { selected } = useDropdownItemCtx();
    return (
        <Box className="grid place-content-center">
            {selected ? <Icon className="text-brand" name="check" /> : null}
        </Box>
    );
};

/* -------------------------------------------------------------------------------------------------
 * Dropdown Checkbox
 * -----------------------------------------------------------------------------------------------*/
const DropdownCheckbox: FC<
    CheckboxProps & { ref?: RefObject<HTMLButtonElement> }
> = ({ ref, ...props }) => {
    const { selected } = useDropdownItemCtx();
    return <Checkbox checked={selected} {...props} ref={ref} />;
};

/* -------------------------------------------------------------------------------------------------
 * Dropdown Anchor
 * -----------------------------------------------------------------------------------------------*/
const DropdownAnchor = Popover.Anchor;

/* -------------------------------------------------------------------------------------------------
 * Dropdown Content
 * -----------------------------------------------------------------------------------------------*/

type DropdownContentProps = PropsWithChildren<Popover.PopoverContentProps> & {
    hasPortal?: boolean;
};
const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
    (
        { className: propsClassName, hasPortal, sideOffset = 4, ...props },
        ref,
    ) => {
        const Wrapper = hasPortal ? Popover.Portal : Fragment;
        const className = useDropdownContentStyles(propsClassName);
        return (
            <Wrapper>
                <Popover.Content
                    ref={ref}
                    {...props}
                    className={className}
                    sideOffset={sideOffset}
                    tabIndex={0}
                />
            </Wrapper>
        );
    },
);

/* -------------------------------------------------------------------------------------------------
 * Dropdown Trigger
 * -----------------------------------------------------------------------------------------------*/
const DropdownTrigger = forwardRef<
    HTMLButtonElement,
    Popover.PopoverTriggerProps & { label?: string }
>(({ asChild, children, label, ...props }, ref) => {
    const { value } = useDropdownCtx();
    const hasValue = !!value && !!value.length;
    return (
        <Popover.Trigger
            asChild={children ? asChild : true}
            ref={ref}
            {...props}
        >
            {children ?? (
                <Button active={hasValue} size="sm" variant="outlined">
                    {label}
                    <Button.Icon
                        className={cn("text-primary", hasValue && "text-brand")}
                        name="angleDown"
                    />
                </Button>
            )}
        </Popover.Trigger>
    );
});

/* -------------------------------------------------------------------------------------------------
 * Dropdown
 * -----------------------------------------------------------------------------------------------*/

export type DropdownProps = Popover.PopoverProps & SelectProps;
type DropdownComponentType = FC<DropdownProps> & {
    Trigger: typeof DropdownTrigger;
    Content: typeof DropdownContent;
    Item: typeof DropdownItem;
    ItemText: typeof DropdownItemText;
    ItemIcon: typeof DropdownItemIcon;
    SelectedIndicator: typeof DropdownItemSelectedIndicator;
    SelectableItem: typeof DropdownSelectableItem;
    Anchor: typeof DropdownAnchor;
    Checkbox: typeof DropdownCheckbox;
};

export const Dropdown: DropdownComponentType = ({
    children,
    closeOnValueChange = true,
    defaultOpen,
    defaultValue,
    onValueChange,
    onOpenChange,
    open: propsOpen,
    selectType,
    value: propsValue,
    ...props
}) => {
    const [open, setOpen] = useControllableState({
        defaultProp: defaultOpen,
        onChange: onOpenChange,
        prop: propsOpen,
    });

    const { handleSelect, value } = useSelect({
        close: () => setOpen(false),
        closeOnValueChange,
        defaultValue,
        onValueChange,
        selectType,
        value: propsValue,
    });

    return (
        <DropdownCtx.Provider value={{ handleSelect, value }}>
            <Popover.Root open={open} onOpenChange={setOpen} {...props}>
                {children}
            </Popover.Root>
        </DropdownCtx.Provider>
    );
};

Dropdown.Trigger = DropdownTrigger;
Dropdown.Content = DropdownContent;
Dropdown.Item = DropdownItem;
Dropdown.ItemText = DropdownItemText;
Dropdown.ItemIcon = DropdownItemIcon;
Dropdown.SelectedIndicator = DropdownItemSelectedIndicator;
Dropdown.SelectableItem = DropdownSelectableItem;
Dropdown.Anchor = DropdownAnchor;
Dropdown.Checkbox = DropdownCheckbox;
export {
    useDropdownItemIconStyles,
    useDropdownItemStyles,
    useDropdownItemTextStyles,
};
