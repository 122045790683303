import { useQueryClient } from "@tanstack/react-query";
import {
    CloseOngoingQuestionnairesDocument,
    CreateQuestionnairesDocument,
    GetSuppliers_DeprecatedQuery,
    Supplier,
    SupplierPatch,
    UpdateSuppliersDocument,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import { useSetToast } from "../../../../components/Toast";
import { supplierKeys } from "../data";

export const useUpdateSuppliers = () => {
    const client = useQueryClient();

    const updateSuppliersMutation = useGraphQLMutation(UpdateSuppliersDocument);

    const updateSuppliersCache = (
        suppliersIds: string[],
        patch: SupplierPatch,
    ) => {
        client.setQueryData<GetSuppliers_DeprecatedQuery>(
            supplierKeys.all,
            () => {
                const data = client.getQueryData<GetSuppliers_DeprecatedQuery>(
                    supplierKeys.all,
                );
                if (!data?.suppliers_deprecated) {
                    return undefined;
                }
                const updatedSuppliers = data.suppliers_deprecated?.map((s) => {
                    if (suppliersIds.includes(s.id)) {
                        return {
                            ...s,
                            ...(patch.tagIds
                                ? {
                                      tags: patch.tagIds.map((id) => ({
                                          ...(s.tags?.find(
                                              (t) => t.id === id,
                                          ) || {
                                              name: "...",
                                          }),
                                          id,
                                      })),
                                  }
                                : undefined),
                        } as Supplier;
                    } else {
                        return s;
                    }
                });
                return {
                    suppliers_deprecated: updatedSuppliers,
                } as GetSuppliers_DeprecatedQuery;
            },
        );
    };

    const updateSuppliers = (suppliersIds: string[], patch: SupplierPatch) => {
        updateSuppliersCache(suppliersIds, patch);

        updateSuppliersMutation.mutate(
            {
                inputs: suppliersIds.map((id) => ({
                    id,
                    set: patch,
                })),
            },
            {
                onSuccess: (data) => {
                    client.invalidateQueries({ queryKey: supplierKeys.all });
                    const oldSuppliersData =
                        client.getQueryData<GetSuppliers_DeprecatedQuery>(
                            supplierKeys.all,
                        );
                    if (!oldSuppliersData?.suppliers_deprecated) {
                        return undefined;
                    }
                    const updatedSuppliers =
                        oldSuppliersData.suppliers_deprecated?.map((s) => {
                            const updatedSupplier =
                                data.updateSuppliers.findIndex(
                                    (su) => su.id === s.id,
                                );
                            return updatedSupplier || s;
                        });
                    return {
                        suppliers_deprecated: updatedSuppliers,
                    } as GetSuppliers_DeprecatedQuery;
                },
                onError: () => {
                    // Add error handling
                },
            },
        );
    };

    return { updateSuppliers };
};

export const useCreateQuestionnaires = () => {
    const client = useQueryClient();
    const createQuestionnaireMutation = useGraphQLMutation(
        CreateQuestionnairesDocument,
    );
    const { setToastError } = useSetToast();

    const createQuestionnaire = (supplierIds: string[], templateId: string) => {
        createQuestionnaireMutation.mutate(
            {
                inputs: supplierIds.map((id) => ({
                    supplierId: id,
                    questionnaireTemplateId: templateId,
                    year: new Date().getFullYear(),
                })),
            },
            {
                onSuccess: () => {
                    client.invalidateQueries({ queryKey: supplierKeys.all });
                },
                onError: (e) => {
                    // @ts-ignore
                    const error = e.response.errors[0].message as string;
                    if (error === "QUESTIONNAIRE_ALREADY_OPEN") {
                        setToastError("Please close open questionnaires first");
                    } else {
                        setToastError("Something went wrong");
                    }
                },
            },
        );
    };

    return { ...createQuestionnaireMutation, mutate: createQuestionnaire };
};

export const useCloseOngoingQuestionnaires = () => {
    const client = useQueryClient();
    const closeOngoingQuestionnairesMutation = useGraphQLMutation(
        CloseOngoingQuestionnairesDocument,
    );
    const { setToastError } = useSetToast();

    const closeOngoingQuestionnaires = (supplierIds: string[]) => {
        closeOngoingQuestionnairesMutation.mutate(
            {
                supplierIds: supplierIds,
            },
            {
                onSuccess: () => {
                    client.invalidateQueries({ queryKey: supplierKeys.all });
                },
                onError: (e) => {
                    // @ts-ignore
                    const error = e.response.errors[0].message as string;
                    setToastError("Something went wrong: " + error);
                },
            },
        );
    };

    return {
        ...closeOngoingQuestionnairesMutation,
        mutate: closeOngoingQuestionnaires,
    };
};
