import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { SubthemeTagSelect } from "@app/shared/components/Theme/SubthemeTagSelect";
import { ThemeTagSelect } from "@app/shared/components/Theme/ThemeTagSelect";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { useOptimistic } from "@app/shared/utils/optimisticUpdates";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import {
    GetObjectiveQuery,
    ObjectiveStatus,
    ResponseStatus,
} from "@generated/client/graphql";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";
import { NameInput } from "./NameInput";
import { StatusTagSelect } from "./StatusTagSelect";

type ObjectiveHeaderProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveHeader: FC<ObjectiveHeaderProps> = ({ objective }) => {
    const { mutate } = useUpdateObjective(objective?.id, objective?.entityId);
    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastSuccess, setToastError } = useSetToast();

    const [ownerId, setOwnerId] = useOptimistic(objective.ownerId);
    const [themeId, setThemeId] = useOptimistic(objective.themeId);
    const [subthemeId, setSubthemeId] = useOptimistic(objective.subthemeId);
    const [status, setStatus] = useOptimistic(objective.status);

    const handleUserIdChange = (userId: string) => {
        const newOwnerId = !!userId ? userId : null;
        if (objective.ownerId === newOwnerId) return;
        setOwnerId(newOwnerId);
        mutate(
            { id: objective.id, ownerId: newOwnerId },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.success_update));
                    }
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleStatusChange = (status: ObjectiveStatus) => {
        if (objective.status === status) return;
        setStatus(status);
        mutate(
            { id: objective.id, status },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.success_update));
                    }
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleThemeChange = (themeId: string) => {
        if (objective.themeId === themeId) return;
        setThemeId(themeId);
        mutate(
            { id: objective.id, themeId },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.success_update));
                    }
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    const handleSubThemeChange = (subthemeId: string) => {
        if (objective.subthemeId === subthemeId) return;
        setSubthemeId(subthemeId);
        mutate(
            { id: objective.id, subthemeId },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.success_update));
                    }
                },
                onError: () => {
                    setToastError(t(keys.error_update));
                },
            },
        );
    };

    return (
        <FlexCol gap="1">
            <NameInput objective={objective} key={objective.id} />
            <FlexRow gap="4" alignItems="center">
                <ThemeTagSelect
                    themeId={themeId}
                    setThemeId={handleThemeChange}
                    disabled={!!objective?.targets.length}
                    disabledTooltip={t(keys.disabled_targets_tooltip)}
                />
                <SubthemeTagSelect
                    themeId={themeId}
                    subthemeId={subthemeId || undefined}
                    setSubthemeId={handleSubThemeChange}
                    disabled={!!objective?.targets.length}
                    disabledTooltip={t(keys.disabled_targets_tooltip)}
                />
                <StatusTagSelect
                    status={status}
                    setStatus={handleStatusChange}
                />
                <UserChipSelect
                    userId={ownerId}
                    setUserId={handleUserIdChange}
                    trigger={
                        <FlexRow w="fit">
                            <UserTag
                                userId={objective.ownerId || undefined}
                                noUserText={t(keys.unassigned)}
                            />
                        </FlexRow>
                    }
                />
            </FlexRow>
        </FlexCol>
    );
};
