import { GaugeCircular } from "@app/components/Progress/ProgressCircular/GaugeCircular";
import { modalRequirementIdAtom } from "@app/pages/Requirements/components/modal/modal-hooks";
import { Requirement } from "@generated/client/graphql";
import { useSetAtom } from "jotai";

const RequirementRow = ({ requirement }: { requirement: Requirement }) => {
    const setRequirementId = useSetAtom(modalRequirementIdAtom);

    const handleClick = () => {
        setRequirementId(requirement.id);
    };

    return (
        <div
            className="bg-white border-b border-tertiary last:border-none cursor-pointer flex items-center justify-between w-full"
            onClick={handleClick}
        >
            <div className="p-4 flex-shrink truncate">
                <div className="truncate font-bold">{requirement.name}</div>
                <div className="truncate text-sm">
                    {requirement.description}
                </div>
            </div>
            <div className="p-4 flex items-center space-x-3 shrink-0">
                <div>
                    {requirement.proofStat?.ok!}/{requirement.proofStat?.total!}
                </div>

                <GaugeCircular
                    size={16}
                    progress={
                        requirement.proofStat?.total
                            ? (100 * requirement.proofStat?.ok!) /
                              requirement.proofStat?.total
                            : 0
                    }
                />
            </div>
        </div>
    );
};

export default RequirementRow;
