import { useEditorContext } from "@app/screens/Document/Edit/hooks/useEditor";
import { useObjectivesWithDetails } from "@app/screens/Objectives/data";
import { useTheme } from "@app/store/themeStore";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { ColorTag } from "@design-system/DataDisplay/Tag/ColorTag";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import { BooleanEnum, ThemeQuery } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../i18n";
import { isOpenInsertObjectivesModalAtom } from "./ctx";
import { generateObjectiveContent } from "./services";
import { Objective } from "./types";

type InsertObjsModalProps = {
    className?: ClassValue;
};

export const InsertObjectivesModal: FC<InsertObjsModalProps> = ({
    className,
}) => {
    const [open, setOpen] = useAtom(isOpenInsertObjectivesModalAtom);
    const { t } = useTranslation(RESOURCE_NAME);
    const { version, editor } = useEditorContext();
    const currentTheme = useTheme(version?.document?.theme?.id || undefined);
    const { objectives } = useObjectivesWithDetails({
        themeId: version?.document?.theme?.id,
    });

    const relevantSubthemes = currentTheme?.subthemes?.filter(
        (subtheme) => subtheme.isObjectiveRequired === BooleanEnum.True,
    );

    const filterObjectivesBySubtheme = (subthemeId: string) => {
        return (
            objectives?.filter(
                (objective) => objective.subthemeId === subthemeId,
            ) || []
        );
    };

    const [indexesToInsert, setIndexesToInsert] = useState<boolean[]>(
        relevantSubthemes?.map(() => false) || [],
    );

    useEffect(() => {
        setIndexesToInsert(relevantSubthemes?.map(() => false) || []);
    }, [currentTheme]);

    const updateIndexesToInsert = (idx: number) => {
        return () => {
            setIndexesToInsert(
                indexesToInsert.map((elt, idx2) => (idx === idx2 ? !elt : elt)),
            );
        };
    };

    const handleInsertObjectiveClick = () => {
        return () => {
            if (!editor) return;
            relevantSubthemes?.forEach((subtheme, idx) => {
                if (indexesToInsert[idx]) {
                    const objectivesToInsert = filterObjectivesBySubtheme(
                        subtheme.id,
                    );
                    const objectiveContent = generateObjectiveContent(
                        objectivesToInsert,
                        subtheme,
                        t,
                    );
                    editor.commands.insertContent(objectiveContent || "{}");
                    editor
                        .chain()
                        .insertContentAt(editor.state.doc.content.size, "\n")
                        .focus()
                        .run();
                }
            });
        };
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay>
                <Modal.Content className={cn("w-[760px]", className)}>
                    <Modal.Header withClose className="gap-2">
                        <Modal.Title className="text-primary">
                            {t(keys.insert_objectives_modal_title)}
                        </Modal.Title>
                        <Modal.Subtitle>
                            {t(keys.insert_objectives_modal_subtitle)}
                        </Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body className="h-[500px] overflow-scroll">
                        <FlexCol gap="5">
                            {relevantSubthemes?.map((subtheme, idx) => (
                                <FlexRow
                                    key={subtheme.id}
                                    w="full"
                                    p="4"
                                    elevation="b-100"
                                    className="bg-secondary"
                                    gap="3"
                                    justifyContent="between"
                                >
                                    <Checkbox
                                        checked={indexesToInsert[idx]}
                                        onChange={updateIndexesToInsert(idx)}
                                        className="cursor-pointer"
                                    />
                                    <SubThemeBlockContent
                                        subtheme={subtheme}
                                        objectives={filterObjectivesBySubtheme(
                                            subtheme.id,
                                        )}
                                    />
                                </FlexRow>
                            ))}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer className="justify-center">
                        <Modal.ConfirmCTA
                            className="w-full"
                            onClick={handleInsertObjectiveClick()}
                        >
                            {t(keys.cta, {
                                selected:
                                    indexesToInsert.filter(Boolean).length,
                                total: relevantSubthemes?.length,
                            })}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

const SubThemeBlockContent = ({
    subtheme,
    objectives,
}: {
    subtheme: ThemeQuery["theme"]["subthemes"][number];
    objectives: Objective[];
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const objectivesCount = objectives.length;
    const iconName = () => {
        switch (objectivesCount) {
            case 0:
            case 1:
                return "circleWarning";
            default:
                return "circleValidated";
        }
    };

    const indicatorProgressMessage = () => {
        switch (objectivesCount) {
            case 0:
                return t("objectives_progression_insufficient");
            case 1:
                return t("objectives_progression_weak");
            case 2:
                return t("objectives_progression_good");
            default:
                return t("objectives_progression_excellent", {
                    count: objectivesCount,
                });
        }
    };

    return (
        <FlexCol w="full" gap="2">
            <FlexRow w="full" alignItems="center" justifyContent="between">
                <FlexRow alignItems="center" gap="2">
                    <Icon name={iconName()} size="sm" />
                    <Text variant="body-bold" color="primary">
                        {subtheme.name}
                    </Text>
                </FlexRow>
                {objectivesCount > 0 ? (
                    <Tooltip>
                        <Tooltip.Trigger>
                            <ColorTag
                                color={objectivesCount > 1 ? "green" : "orange"}
                                text={t("objectives_display_button", {
                                    count: objectivesCount,
                                })}
                                size="xs"
                                iconName="angleDown"
                            />
                        </Tooltip.Trigger>
                        <Tooltip.Content className="max-w-[600px]">
                            <FlexCol>
                                {objectives?.map((objective) => (
                                    <FlexRow
                                        key={objective.id}
                                        className="p-1 [&:not(:last-child)]:border-b border-neutral-700"
                                        gap="1"
                                        color="primary"
                                    >
                                        <StatusTagCircular status="validated" />
                                        <Text
                                            variant="body"
                                            className="line-clamp-2"
                                        >
                                            {objective.name}
                                        </Text>
                                    </FlexRow>
                                ))}
                            </FlexCol>
                        </Tooltip.Content>
                    </Tooltip>
                ) : (
                    <ColorTag
                        color={objectivesCount > 1 ? "green" : "orange"}
                        text={t("objectives_display_button", {
                            count: objectivesCount,
                        })}
                        size="xs"
                    />
                )}
            </FlexRow>
            <FlexRow>
                <Text>{indicatorProgressMessage()}</Text>
            </FlexRow>
        </FlexCol>
    );
};
