export const adminPaths = {
    external_questionnaires: "external_questionnaires",
    external_questionnaire_id_param: ":external_questionnaire_id",
    tasks: "tasks",
    reporting_documents: "reporting_documents",
    policies: "policies",
    objectives: "objectives",
    objective_id_param: ":objective_id",
    measures: "measures",
    custom_documents: "custom_documents",
    all_files: "all_files",
    actions: "actions",
    settings: "settings",
    profile: "profile",
    reporting: "reporting",
    supplier: "supplier",
    requirements: "requirements",
    frameworks: "frameworks",
    framework_id_param: ":framework_id",
    framework_csrd: "csrd",
    framework_csrd_pillar_id_param: ":pillar_id",
    sustainability_center: "sustainability_center",
} as const;

export const documentPaths = {
    document_id_param: ":document_id",
} as const;

export const reportingPaths = {
    indicators: "indicators",
    overview: "overview",
    progress: "progress",
} as const;

export const supplierPaths = {
    questionnaires: "questionnaires",
    questions: "questions",
    suppliers: "suppliers",
    datapoints: "datapoints",
    supplier_id_param: ":supplier_id",
} as const;

export const settingPaths = {
    general: "general",
    themes: "themes",
    sites: "sites",
    users: "users",
    units: "units",
} as const;

export type AdminPath = (typeof adminPaths)[keyof typeof adminPaths];
export type ReportingPath =
    (typeof reportingPaths)[keyof typeof reportingPaths];
export type SupplierPath = (typeof supplierPaths)[keyof typeof supplierPaths];
export type SettingPaths = (typeof settingPaths)[keyof typeof settingPaths];
