import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { HeaderFilter } from "@design-system/Table";
import { TagType } from "@generated/client/graphql";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import "../../../pages/Reporting/i18n";
import NewTagDropdownItem from "../TagListEditor/NewTagDropdownItem";
import TagDropdownItem from "../TagListEditor/TagDropdownItem";
import { useTags } from "../TagListEditor/data";
export const TagsMultiSelect: FC<{
    isHeader?: boolean;
    setTagsIds: (tagsIds: (string | null)[]) => void;
    tagsIds: (string | null)[] | undefined;
    type: TagType;
    side?: "left" | "right" | "top" | "bottom";
}> = ({ isHeader, tagsIds, setTagsIds, type, side }) => {
    const { t } = useTranslation("ReportingPage");
    const tags = useTags(type);

    const active = !!tagsIds?.length;

    const onToggleTag = (id: string) => {
        if (tagsIds?.includes(id)) {
            const newValues = [...tagsIds];
            newValues.splice(tagsIds.indexOf(id), 1);
            setTagsIds(newValues);
        } else {
            setTagsIds([...(tagsIds || []), id]);
        }
    };

    const onSaveNewTagSettled = (name: string) => {
        const newTag = tags?.find((tag) => tag.name === name);
        if (newTag) {
            onToggleTag(newTag.id);
        }
    };

    return (
        <Dropdown
            selectType="multi"
            value={tagsIds?.filter((id) => id !== null) || []}
            onValueChange={setTagsIds}
            closeOnValueChange={false}
        >
            <Dropdown.Trigger
                label={t("indicator_table_tags_column_title")}
                className="w-full h-full"
            >
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("indicator_table_tags_column_title", {
                            context: active && "active",
                            count: tagsIds?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <Button active={active} variant="outlined" size="sm">
                        {t("indicator_table_tags_column_title")}
                        <Button.Icon name={"angleDown"} />
                    </Button>
                )}
            </Dropdown.Trigger>
            <Dropdown.Content side={side} hasPortal>
                <div>
                    <NewTagDropdownItem
                        type={type}
                        onSaveNewTagSettled={onSaveNewTagSettled}
                    />
                    {tags?.map((tag) => (
                        <TagDropdownItem
                            key={tag.id}
                            tagId={tag.id}
                            tagName={tag.name}
                            tagType={type}
                            checked={tagsIds?.includes(tag.id)}
                            onToggle={onToggleTag}
                        />
                    ))}
                </div>
            </Dropdown.Content>
        </Dropdown>
    );
};
