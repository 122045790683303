import { Modal } from "@design-system/Modal";
import { useAtom, useAtomValue } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { activeEntityIdAtom } from "../../ctx";
import { useGenerateObjective } from "../../data";
import { isGenerateObjectiveModalOpenAtom } from "./ctx";
import { GenerateObjectivePage } from "./generateObjectivePage";
import { RESOURCE_NAME, keys } from "./i18n";
import { SelectIndicatorPage } from "./selectIndicatorPage";

const modalPages = {
    selectIndicator: "SelectIndicator",
    generateObjective: "GenerateObjective",
} as const;

type ModalPage = (typeof modalPages)[keyof typeof modalPages];

export const GenerateObjectiveModal: FC = () => {
    const [isOpen, setIsOpen] = useAtom(isGenerateObjectiveModalOpenAtom);
    const [generationProgress, setGenerationProgress] = useState(0);
    const [currentPage, setCurrentPage] = useState<ModalPage>(
        modalPages.selectIndicator,
    );
    const activeEntityId = useAtomValue(activeEntityIdAtom);
    const [selectedIndicator, setSelectedIndicator] = useState<
        | { id: string; name: string; themeId: string; unitShortName: string }
        | undefined
    >(undefined);

    const { generateObjective } = useGenerateObjective();

    const handleOpenChange = (open: boolean) => {
        setCurrentPage(modalPages.selectIndicator);
        setSelectedIndicator(undefined);
        setIsOpen(open);
    };

    const handleConfirm = () => {
        if (!selectedIndicator) return;
        setGenerationProgress(0);
        generateObjective({
            entityId: activeEntityId,
            indicatorId: selectedIndicator.id,
        });
        setCurrentPage(modalPages.generateObjective);
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <Modal open={isOpen} onOpenChange={handleOpenChange}>
            <Modal.Overlay scrollable>
                <Modal.Content className="w-full max-w-3xl">
                    <Modal.Header>
                        <Modal.Title className="text-primary">
                            {t(keys.modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentPage === modalPages.selectIndicator && (
                            <SelectIndicatorPage
                                selectedIndicatorId={selectedIndicator?.id}
                                setSelectedIndicator={setSelectedIndicator}
                            />
                        )}
                        {currentPage === modalPages.generateObjective && (
                            <GenerateObjectivePage
                                generationProgress={generationProgress}
                                setGenerationProgress={setGenerationProgress}
                                selectedIndicator={selectedIndicator}
                            />
                        )}
                    </Modal.Body>
                    {currentPage === modalPages.selectIndicator && (
                        <Modal.Footer>
                            <Modal.CancelCTA>
                                {t(keys.cancel_button)}
                            </Modal.CancelCTA>
                            <Modal.ConfirmCTA
                                onClick={handleConfirm}
                                disabled={!selectedIndicator}
                                variant="flatAccent"
                                preventClose
                            >
                                {t(keys.confirm_button)}
                            </Modal.ConfirmCTA>
                        </Modal.Footer>
                    )}
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
