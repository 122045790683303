import {
    computeStatus,
    Status,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/services";
import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import {
    HeaderFilter,
    type ColumnDef,
    type ColumnFiltersState,
} from "@design-system/Table";
import { AnswerStatus } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../../i18n";
import { columnFiltersAtom } from "../ctx";

export const extractValueFromFilter = (
    colFilter: ColumnFiltersState,
    colId: string,
) => {
    const { value: rawValue } = colFilter?.find(
        (filter) => filter.id === colId,
    ) ?? { value: [] };

    if (!Array.isArray(rawValue)) {
        return [];
    }

    return rawValue;
};

export const updateFilter = (id: string, value: (string | null)[]) => {
    return (prevFilters: ColumnFiltersState) => {
        const columnFilter = prevFilters.find((filter) => filter.id === id) ?? {
            id,
            value: [],
        };
        return [
            ...prevFilters.filter((filter) => filter.id !== id),
            { ...columnFilter, value },
        ];
    };
};

export const statusColumn: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "status",
    accessorFn: ({ answer }) => {
        const status = answer?.status ?? AnswerStatus.Unknown;
        const value = answer?.value;

        return computeStatus({ status, value });
    },
    enableSorting: false,
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        const [open, setOpen] = useState(false);
        const [filterValue, setFilterValue] = useAtom(columnFiltersAtom);
        const value = useMemo(() => {
            const rawValue = extractValueFromFilter(filterValue, "status");
            return rawValue.filter((v) => v !== null);
        }, [filterValue]);
        const handleValueChange = (value: string[]) => {
            setFilterValue(updateFilter("status", value));
        };
        const dropdownItems = useMemo(
            () => [
                {
                    value: Status.Validated,
                    label: t(keys.table_header_status_dropdown_item_validated),
                },
                {
                    value: Status.Todo,
                    label: t(keys.table_header_status_dropdown_item_todo),
                },
                {
                    value: Status.InProgress,
                    label: t(
                        keys.table_header_status_dropdown_item_in_progress,
                    ),
                },
            ],
            [t],
        );

        return (
            <Dropdown
                closeOnValueChange={false}
                selectType="multi"
                value={value}
                onValueChange={handleValueChange}
                open={open}
                onOpenChange={setOpen}
            >
                <Dropdown.Trigger asChild>
                    <HeaderFilter
                        active={value.length > 0}
                        className="text-center flex items-center justify-center"
                    />
                </Dropdown.Trigger>
                <Dropdown.Content hasPortal>
                    {dropdownItems.map((item) => (
                        <Dropdown.SelectableItem
                            key={item.value}
                            value={item.value}
                        >
                            <FlexRow alignItems="center" gap="2" w="full">
                                <StatusTagCircular
                                    status={item.value}
                                    size="sm"
                                />
                                <Dropdown.ItemText>
                                    {item.label}
                                </Dropdown.ItemText>
                            </FlexRow>
                            <Dropdown.Checkbox />
                        </Dropdown.SelectableItem>
                    ))}
                </Dropdown.Content>
            </Dropdown>
        );
    },
    cell: ({ row }) => {
        const { status = AnswerStatus.Unknown, value } =
            row.original?.answer ?? {};

        const icon = useMemo(
            () => computeStatus({ status, value }),
            [status, value],
        );

        return (
            <Box className="place-content-center" display="grid">
                <StatusTagCircular status={icon} size="md" />
            </Box>
        );
    },
    meta: {
        cellClassName: "min-w-[50px] max-w-[50px] border-r-0",
        headerClassName: "min-w-[50px] max-w-[50px] border-r-0",
        colWidth: "50px",
    },
    filterFn: (row, columnId, filterValue) => {
        if (
            columnId !== "status" ||
            (Array.isArray(filterValue) && filterValue.length === 0)
        ) {
            return true;
        }

        const status = row.original.answer?.status ?? AnswerStatus.Unknown;
        const value = row.original.answer?.value;

        return filterValue.includes(computeStatus({ status, value }));
    },
};
