import { adminPaths } from "@app/routing/adminPaths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { usePillars } from "../../data";
import "../../i18n";

export function useCsrdBreadcrumb() {
    const { t } = useTranslation("Csrd");
    const { pillar_id } = useParams();
    const {
        pillars: { byId: pillarsById, list: pillarList },
    } = usePillars();

    const breadcrumbArgs = useMemo(() => {
        const options =
            pillarList?.map((pillar) => ({
                name: pillar.name,
                param: pillar.cmsId,
            })) ?? [];
        const currentPillar =
            pillar_id && pillar_id in pillarsById
                ? pillarsById[pillar_id]
                : null;

        return [
            adminPaths.framework_csrd_pillar_id_param,
            currentPillar?.name ?? "...",
            options,
        ] as const;
    }, [pillarsById, pillarList, pillar_id, t]);

    const setBreadcumb = useBreadcrumb();

    useEffect(() => {
        const [path, name, options] = breadcrumbArgs;
        setBreadcumb(path, name, options);
    }, [breadcrumbArgs]);
}
