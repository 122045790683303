import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "sup-user-table";

const keys = addTranslations(RESOURCE_NAME, {
    user: {
        en: "User",
        fr: "Utilisateur",
    },
    role: {
        en: "Role",
        fr: "Rôle",
    },
    dep: {
        en: "Department",
        fr: "Département",
    },
    department: {
        en: "{{context}}",
        fr: "{{context}}",
    },
    departement_audit: {
        en: "Audit & Consulting",
        fr: "Audit & Conseil",
    },
    department_operations: {
        en: "Operations & Client Success",
        fr: "Opérations & Support Client",
    },
    department_product: {
        en: "Product, R&D & Innovation",
        fr: "Produit, R&D & Innovation",
    },
    department_sales: {
        en: "Sales, Marketing & Communication",
        fr: "Ventes, Marketing & Communication",
    },
    department_it: {
        en: "IT & Cybersecurity",
        fr: "IT & Cybersécurité",
    },
    department_finance: {
        en: "Finance, Legal & Compliance",
        fr: "Finance, Juridique & Conformité",
    },
    department_quality: {
        en: "Quality, Safety & Risk Management",
        fr: "Qualité, Sécurité & Gestion des Risques",
    },
    department_sustainability: {
        en: "Sustainability, CSR & Environmental",
        fr: "Durabilité, RSE & Environnement",
    },
    department_supply: {
        en: "Supply Chain & Logistics",
        fr: "Supply Chain & Logistique",
    },
    department_executive: {
        en: "Executive & Leadership",
        fr: "Direction & Leadership",
    },
    department_hr: {
        en: "Human Resources & Administration",
        fr: "Ressources Humaines & Administration",
    },
    department_procurement: {
        en: "Procurement",
        fr: "Achats",
    },
    edit_user: {
        en: "Edit user",
        fr: "Modifier l'utilisateur",
    },
    delete_user: {
        en: "Delete user",
        fr: "Supprimer l'utilisateur",
    },
});

export function useSupplierUserTableTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
