export const MAIN_PATH = "sup";

export const supPaths = {
    sup_id_param: ":sup_id",
    questionnaires: "questionnaires",
    questionnaire_id_param: ":questionnaire_id",
    users: "users",
} as const;

export type SupPath = (typeof supPaths)[keyof typeof supPaths];
