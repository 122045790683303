import { LocaleSelect } from "@app/shared/components/Locale/LocaleSelect";
import { SupportedLocales } from "@app/shared/utils/locales";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import {
    useState,
    type ChangeEvent,
    type Dispatch,
    type FC,
    type PropsWithChildren,
    type SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateExternalQuestionnaire } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";
import {
    QuestionnaireTemplate,
    type QuestionnaireCreationModalStep,
} from "../../types";

export const QuestionnaireFormStep: FC<{
    isTemplateOnly?: boolean;
    template: QuestionnaireTemplate | null;
    onStepChange: Dispatch<SetStateAction<QuestionnaireCreationModalStep>>;
    onTemplateChange: Dispatch<SetStateAction<QuestionnaireTemplate | null>>;
}> = ({ isTemplateOnly, template, onStepChange, onTemplateChange }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { createAsync, isPending } = useCreateExternalQuestionnaire();
    const navigate = useNavigate();

    const [name, setName] = useState<string | undefined>(
        template?.name ?? undefined,
    );
    const [email, setEmail] = useState<string | undefined>();
    const [companyName, setCompanyName] = useState<string | undefined>();
    const [deadline, setDeadline] = useState<Date | undefined>();
    const [defaultLocale, setDefaultLocale] = useState<SupportedLocales>(
        (template?.locale?.availableLocales?.[0] as SupportedLocales) ??
            SupportedLocales.FR,
    );

    const handleBack = () => {
        onTemplateChange(null);
        onStepChange("templates");
    };
    const makeEventHandler =
        (setter: Dispatch<SetStateAction<string | undefined>>) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setter(e.target.value);
        };

    const canConfirm = !!name && !!defaultLocale;
    const createQuestionnaire = async () => {
        if (!canConfirm) return;

        const { createQuestionnaire } = await createAsync({
            templateId: template?.id,
            input: {
                name,
                requesterEmail: email,
                companyName,
                deadline,
                locale: {
                    availableLocales: [defaultLocale],
                    default: defaultLocale,
                },
            },
        });

        navigate(`${createQuestionnaire.data?.id}`);
    };

    return (
        <Modal.Content>
            <Modal.Header withClose>
                <FlexRow gap="4">
                    {!isTemplateOnly && (
                        <Button variant="text" size="sm" onClick={handleBack}>
                            <Button.Icon name="arrowLeft" />
                        </Button>
                    )}
                    <FlexCol gap="2">
                        <Modal.Title>
                            {t(keys.create_questionnaire_modal_title)}
                        </Modal.Title>
                        <Modal.Subtitle>
                            {template
                                ? t(
                                      keys.create_questionnaire_modal_subtitle_templates,
                                  )
                                : t(
                                      keys.create_questionnaire_modal_subtitle_from_scratch,
                                  )}
                        </Modal.Subtitle>
                    </FlexCol>
                </FlexRow>
            </Modal.Header>
            <Modal.Body>
                <FlexCol gap="4">
                    <QuestionnaireField
                        label={t(
                            keys.create_questionnaire_modal_name_placeholder,
                        )}
                    >
                        <Textfield
                            className="h-6"
                            value={name}
                            onChange={makeEventHandler(setName)}
                        />
                    </QuestionnaireField>
                    <QuestionnaireField
                        label={t(
                            keys.create_questionnaire_modal_requester_email_placeholder,
                        )}
                    >
                        <Textfield
                            className="h-6"
                            value={email}
                            onChange={makeEventHandler(setEmail)}
                        />
                    </QuestionnaireField>
                    <QuestionnaireField
                        label={t(
                            keys.create_questionnaire_modal_company_name_placeholder,
                        )}
                    >
                        <Textfield
                            className="h-6"
                            value={companyName}
                            onChange={makeEventHandler(setCompanyName)}
                        />
                    </QuestionnaireField>
                    <QuestionnaireField
                        label={t(
                            keys.create_questionnaire_modal_deadline_placeholder,
                        )}
                    >
                        <Popover>
                            <PopoverTrigger
                                className="w-full flex items-stretch justify-stretch"
                                asChild
                            >
                                <Button
                                    variant="text"
                                    className={cn(
                                        "w-full min-h-8",
                                        "px-4",
                                        "font-normal text-primary",
                                        "border border-tertiary",
                                        "hover:bg-transparent hover:shadow-none",
                                        "rounded-lg",
                                    )}
                                >
                                    {deadline ? (
                                        format(deadline, "PPP")
                                    ) : (
                                        <Text as="span" color="secondary">
                                            {t(keys.set_a_deadline)}
                                        </Text>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                align="start"
                                alignOffset={-2}
                                sideOffset={9}
                                className="absolute transform -translate-x-3 shadow-lg bg-white"
                            >
                                <Calendar
                                    mode="single"
                                    defaultMonth={
                                        deadline
                                            ? new Date(deadline)
                                            : new Date()
                                    }
                                    selected={deadline}
                                    onSelect={setDeadline}
                                    className="rounded-md border"
                                />
                            </PopoverContent>
                        </Popover>
                    </QuestionnaireField>
                    {!template && (
                        <QuestionnaireField
                            label={t(
                                keys.create_questionnaire_modal_locale_placeholder,
                            )}
                        >
                            <LocaleSelect
                                value={defaultLocale}
                                options={Object.values(SupportedLocales)}
                                onChange={setDefaultLocale}
                            />
                        </QuestionnaireField>
                    )}
                </FlexCol>
            </Modal.Body>
            <Modal.Footer>
                <Modal.CancelCTA onClick={handleBack}>
                    {t(keys.create_questionnaire_modal_cancel_label)}
                </Modal.CancelCTA>
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        {/* This Box below is necessary because the tooltip doesn't open when the button is
                         * disabled but we want to display the tooltip just when the button is disabled
                         */}
                        <Box className="w-fit self-end">
                            <Modal.ConfirmCTA
                                onClick={createQuestionnaire}
                                disabled={!canConfirm}
                                loading={isPending}
                                preventClose
                            >
                                {t(
                                    keys.create_questionnaire_modal_confirm_label,
                                )}
                            </Modal.ConfirmCTA>
                        </Box>
                    </Tooltip.Trigger>
                    {!canConfirm && (
                        <Tooltip.Content>
                            {t(keys.create_questionnaire_modal_tooltip_no_name)}
                        </Tooltip.Content>
                    )}
                </Tooltip>
            </Modal.Footer>
        </Modal.Content>
    );
};

export const QuestionnaireField: FC<PropsWithChildren<{ label: string }>> = ({
    children,
    label,
}) => {
    return (
        <FlexCol gap="1.5">
            <Text variant="body-bold" color="primary">
                {label}
            </Text>
            {children}
        </FlexCol>
    );
};
