import {
    GetQuestionsQuery,
    type Answer,
    type AnswerUpsertInput,
} from "@generated/client/graphql";

export const makeUpsertAnswerOptimisticUpdateToGetQuestions = (
    input: AnswerUpsertInput,
    previousQuery: GetQuestionsQuery,
): GetQuestionsQuery => {
    const prevQuestion = previousQuery.questions.find(
        (question) => question.id === input.questionId,
    );
    if (prevQuestion?.id !== input.questionId) {
        return previousQuery;
    }

    if (prevQuestion?.answer) {
        const newQuestion = {
            ...prevQuestion!,
            answer: {
                ...prevQuestion?.answer,
                ...(input.value && { value: input.value }),
                ...(input.comment && { comment: input.comment }),
            } as Answer,
        };
        return {
            ...previousQuery,
            questions: previousQuery.questions.map((question) =>
                question.id === input.questionId ? newQuestion : question,
            ),
        };
    }

    return previousQuery;
};

export function filterGroups<
    G extends { level: number; id: string },
    Q extends { group?: { id?: string } | null } | null | undefined,
>(groups: G[], questions: Q[]) {
    /**
     * Logic to filter groups :
     * If a group is of level other than 1, we keep it.
     * If a group is of level 1, we keep it only if it has questions.
     */
    return groups.filter((group) => {
        return (
            group.level !== 1 ||
            questions?.some((question) => question?.group?.id === group.id)
        );
    });
}
