import i18next from "../../../../../i18n";

const enResources = {
    open_modal: "Create new entity",
    title: "Add new entity",
    subtitle: "New entity will be created",
    name: "Name",
    type: "Type",
    parent: "Parent",
    create: "Create",
    cancel: "Cancel",
    save: "Save",
    site_create_success: "Entity created successfully",
    site_create_error: "Error creating entity",
    select_parent: "Select a parent entity",
    set_as_parent: "Set as parent entity",
    set_as_children: "Set as children of {{entity}}",
};

type ResourcesKeys = keyof typeof enResources;

const frResources = {
    open_modal: "Créer une nouvelle entité",
    title: "Ajouter une nouvelle entité",
    subtitle: "une nouvelle entité sera créée",
    name: "Nom",
    type: "Type",
    parent: "Parent",
    create: "Créer",
    cancel: "Annuler",
    save: "Enregistrer",
    site_create_success: "Entité créée avec succès",
    site_create_error: "Erreur lors de la création de l'entité: {{error}}",
    select_parent: "Sélectionner une entité parent",
    set_as_parent: "Set as parent entity",
    set_as_children: "Set as children of {{entity}}",
};

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "NewSiteModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
