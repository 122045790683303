import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Textarea } from "@design-system/Inputs/Textarea";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import { QuestionModalCard } from "./QuestionModalCard";

export const OptionsBlock: FC<{
    opts: Record<string, string>[];
    onChange: (opts: Record<string, string>[]) => void;
    locale: string;
}> = ({ opts = [], onChange, locale }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [localOpts, setLocalOpts] = useState<string[]>(
        opts.map((opt) => opt[locale]),
    );

    const addOption = () => {
        onChange([...opts, { [locale]: "" }]);
    };

    const makeChangeHandler =
        (index: number) => (e: ChangeEvent<HTMLTextAreaElement>) => {
            onChange(
                opts.map((opt, i) =>
                    i === index ? { ...opt, [locale]: e.target.value } : opt,
                ),
            );
        };

    useEffect(() => {
        setLocalOpts(opts.map((opt) => opt[locale]));
    }, [opts, locale]);

    return (
        <FlexCol>
            {opts.length > 0 && (
                <FlexCol>
                    {localOpts.map((opt, index) => (
                        <QuestionModalCard
                            className={cn(
                                "flex flex-col gap-2 border rounded-b-none",
                                index && "rounded-none",
                                "first:rounded-t-none border-t-0",
                                "last:rounded-b-lg last:border-b",
                            )}
                            key={index}
                        >
                            <FlexRow alignItems="center" gap="2">
                                <Textarea
                                    placeholder={t(
                                        keys.create_questions_modal_options_block_label,
                                        {
                                            index: index + 1,
                                        },
                                    )}
                                    className="resize-y"
                                    rows={1}
                                    onChange={makeChangeHandler(index)}
                                    value={opt ?? ""}
                                />
                                <Button
                                    variant="text"
                                    className="text-secondary"
                                    size="sm"
                                    onClick={() => {
                                        onChange(
                                            opts.filter((_, i) => i !== index),
                                        );
                                    }}
                                >
                                    <Icon name="close" size="sm" />
                                </Button>
                            </FlexRow>
                        </QuestionModalCard>
                    ))}
                </FlexCol>
            )}
            <FlexRow alignSelf="end" pt="4">
                <Button onClick={addOption} size="sm" variant="tonal">
                    {t(keys.create_questions_modal_options_block_add_option)}
                    <Button.Icon name="circlePlus" />
                </Button>
            </FlexRow>
        </FlexCol>
    );
};
