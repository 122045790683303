import { currentUserAtom } from "@app/store/userStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

type AddTargetFormProps = {
    handleCancelButtonClick: (
        setNewDate: (date: Date | undefined) => void,
        setNewValue: (value: number | undefined) => void,
    ) => void;
    handleCreateButtonClick: (
        newDate: Date,
        newValue: number,
        setNewDate: (date: Date | undefined) => void,
        setNewValue: (value: number | undefined) => void,
    ) => void;
    unitShortname: string;
};

export const AddTargetForm: FC<AddTargetFormProps> = ({
    handleCancelButtonClick,
    handleCreateButtonClick,
    unitShortname,
}) => {
    const currentUser = useAtomValue(currentUserAtom);
    const [newDate, setNewDate] = useState<Date | undefined>(undefined);
    const [newValue, setNewValue] = useState<number | undefined>(undefined);

    dayjs.extend(LocalizedFormat);
    const displayDate = newDate ? dayjs(newDate).format("LL") : undefined;

    const handleCreateClick = () => {
        if (newValue === undefined) return;

        const date = newDate || new Date();
        handleCreateButtonClick(date, newValue, setNewDate, setNewValue);
    };

    const handleCancelClick = () => {
        handleCancelButtonClick(setNewDate, setNewValue);
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value?.trim() === "") {
            setNewValue(undefined);
            return;
        }
        if (!isNaN(Number(value))) {
            setNewValue(Number(value));
        } else {
            setNewValue(undefined);
        }
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            gap="5"
            p="3"
            br="lg"
            className={cn("bg-neutral-25 border border-secondary")}
        >
            <FlexRow alignItems="center">
                <input
                    className={cn(
                        "p-0 bg-transparent border-none focus:ring-0 focus:text-secondary outline-none whitespace-pre-wrap resize-none",
                        "rounded-lg py-1 hover:bg-neutral-100",
                        "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
                        "w-fit",
                    )}
                    type="number"
                    placeholder={t(keys.define_value)}
                    value={newValue}
                    onChange={handleValueChange}
                />
                <Text variant="body-sm" px="1">
                    {unitShortname}
                </Text>
            </FlexRow>
            <FlexRow alignItems="center" justifyContent="between">
                <Popover>
                    <PopoverTrigger asChild>
                        <FlexRow
                            alignItems="center"
                            justifyContent="center"
                            p="0.5"
                            br="xl"
                            elevation="b-100"
                            className="border border-secondary bg-white cursor-pointer"
                        >
                            <FlexRow
                                h="5"
                                w="5"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Icon
                                    name="calendar"
                                    size="sm"
                                    className={
                                        displayDate
                                            ? "text-primary"
                                            : "text-secondary"
                                    }
                                />
                            </FlexRow>
                            <Text variant="body-sm" px="1">
                                {displayDate || t(keys.add_date)}
                            </Text>
                        </FlexRow>
                    </PopoverTrigger>
                    <PopoverContent
                        align="start"
                        side="bottom"
                        className="shadow-lg bg-white"
                    >
                        <Calendar
                            mode="single"
                            defaultMonth={
                                newDate ? new Date(newDate) : new Date()
                            }
                            selected={newDate ? new Date(newDate) : undefined}
                            onSelect={setNewDate}
                            className="rounded-md border"
                            locale={currentUser.data?.currentUser.locale}
                        />
                    </PopoverContent>
                </Popover>
                <FlexRow gap="3">
                    <Button
                        variant="text"
                        size="sm"
                        onClick={handleCancelClick}
                    >
                        {t(keys.cancel)}
                    </Button>
                    <Button
                        variant="flatPrimary"
                        size="sm"
                        onClick={handleCreateClick}
                        disabled={newValue === undefined}
                    >
                        {t(keys.create)}
                    </Button>
                </FlexRow>
            </FlexRow>
        </FlexCol>
    );
};
