import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useQuestionSectionTranslations } from "./i18n";

type QuestionSectionProps = {
    question: string;
};

export const QuestionSection = ({ question }: QuestionSectionProps) => {
    const { t, keys } = useQuestionSectionTranslations();
    return (
        <FlexRow className="border-b border-tertiary p-2">
            <FlexCol>
                <Text
                    variant="body-sm-bold"
                    color="secondary"
                    className="uppercase"
                >
                    {t(keys.question)}
                </Text>
                <Text>{question}</Text>
            </FlexCol>
        </FlexRow>
    );
};
