import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { useSupplierQuestionsTableTranslations } from "./i18n";

export const DeleteQuestionModal: FC<{
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDelete: () => void;
}> = ({ open, onOpenChange, onDelete }) => {
    const { t, keys } = useSupplierQuestionsTableTranslations();

    return (
        <Modal open={open} onOpenChange={onOpenChange}>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>
                            {t(keys.delete_question_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Text variant="body" color="primary">
                            {t(keys.delete_question_modal_body)}
                        </Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(keys.delete_question_modal_cancel_button)}
                        </Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            variant="flatDanger"
                            onClick={onDelete}
                        >
                            {t(keys.delete_question_modal_confirm_button)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
