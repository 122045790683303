import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtom, useSetAtom } from "jotai";
import { FC } from "react";
import {
    selectedSubthemesAtom,
    selectedThemeIdAtom,
} from "../../../components/CreateObjectiveModal/ctx";
import { isCreateObjectiveModalOpenAtom } from "../../../ctx";
import { useObjectiveWorkPlanTranslations } from "../i18n";

interface SubthemeHeaderProps {
    name: string;
    color: string;
    completedObjectives: number;
    totalObjectives: number;
    themeId: string;
    subthemeId: string;
}

export const SubthemeHeader: FC<SubthemeHeaderProps> = ({
    name,
    color,
    completedObjectives,
    totalObjectives,
    themeId,
    subthemeId,
}) => {
    const { t, keys } = useObjectiveWorkPlanTranslations();
    const setIsCreateObjectiveModalOpen = useSetAtom(
        isCreateObjectiveModalOpenAtom,
    );
    const setSelectedThemeId = useSetAtom(selectedThemeIdAtom);
    const [_, setSelectedSubthemesIds] = useAtom(selectedSubthemesAtom);
    const handleOpenCreateObjectiveModal = () => {
        setSelectedThemeId(themeId);
        setSelectedSubthemesIds([subthemeId]);
        setIsCreateObjectiveModalOpen(true);
    };

    return (
        <FlexRow gap="2" justifyContent="between">
            <FlexRow gap="2" alignItems="center">
                <Icon
                    name="tagEmptyCircle"
                    size="sm"
                    style={{
                        color: color,
                    }}
                />
                <Text variant="body-bold">{name}</Text>
            </FlexRow>

            <FlexRow gap="2" alignItems="center">
                {completedObjectives < 3 ? (
                    <>
                        <Text variant="body" className="text-neutral-600">
                            {t(keys.objectives_completed)}
                        </Text>
                        <Text variant="body-bold">
                            {`${completedObjectives}/${totalObjectives}`}
                        </Text>
                    </>
                ) : (
                    <Text variant="body-bold">Subtheme completed ✔️</Text> // TODO: find better design for this
                )}

                <Button size="xs" onClick={handleOpenCreateObjectiveModal}>
                    +
                </Button>
            </FlexRow>
        </FlexRow>
    );
};
