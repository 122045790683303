import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, MoreHoriz, Trash } from "iconoir-react";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { useSetToast } from "@app/components/Toast";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Entity } from "../../../../../../generated/client/graphql";
import {
    ActionDropdownMenu,
    ActionDropdownMenuContent,
    ActionDropdownMenuItem,
    ActionDropdownMenuItemDanger,
    ActionDropdownMenuTrigger,
} from "../../../../../components/ActionDropdown";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../components/Button/ButtonSmall";
import Header from "../../../../../components/Table/Header";
import { useDeleteEntity } from "../../data";
import { toUpdateSiteAtom } from "../ctx";
import "./i18n";
import { keys, RESOURCE_NAME } from "./i18n";

export const columns: ColumnDef<Entity>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            return <Header title={t(keys.site)} column={column} />;
        },
        cell: ({ row, getValue }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            return (
                <FlexRow gap="2" className="items-center">
                    <span className=" font-bold">{getValue() as string}</span>
                    {row.original.isParent && (
                        <Tooltip>
                            <Tooltip.Trigger className="h-full">
                                <Icon name="building" size="sm" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t(keys.parent_entity_tooltip)}
                            </Tooltip.Content>
                        </Tooltip>
                    )}
                </FlexRow>
            );
        },
        meta: {
            colWidth: "60%",
        },
    },
    {
        accessorKey: "parent.id",
        header: ({ column }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            return <Header title={t(keys.parent)} column={column} />;
        },
        cell: ({ row }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            return (
                <span>{row.original.parent?.name ?? t(keys.no_parent)}</span>
            );
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            return <Header title={t(keys.type)} column={column} />;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const { t } = useTranslation(RESOURCE_NAME);
            const setToUpdateSite = useSetAtom(toUpdateSiteAtom);
            const { mutate } = useDeleteEntity();
            const { setToastError } = useSetToast();

            const { isDeletable } = row.original;

            const handleDelete = () => {
                if (!isDeletable) return;
                mutate(row.original.id, {
                    onSettled: (data) => {
                        if (data?.deleteEntity?.error) {
                            setToastError(
                                `${t(keys.delete_error)} : ${data.deleteEntity.error.message}`,
                            );
                        }
                    },
                });
            };

            return (
                <ActionDropdownMenu modal={false}>
                    <ActionDropdownMenuTrigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconLeft={MoreHoriz}
                        />
                    </ActionDropdownMenuTrigger>
                    <ActionDropdownMenuContent>
                        <ActionDropdownMenuItem
                            onSelect={() => {
                                setToUpdateSite(row.original);
                            }}
                            Icon={({ className }) => (
                                <EditPencil className={className} />
                            )}
                            text={t(keys.edit_site)}
                        />
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <ActionDropdownMenuItemDanger
                                    disabled={!isDeletable}
                                    onSelect={handleDelete}
                                    Icon={({ className }) => (
                                        <Trash className={className} />
                                    )}
                                    text={t(keys.delete_site)}
                                />
                            </Tooltip.Trigger>
                            {isDeletable ? null : (
                                <Tooltip.Content>
                                    {t(keys.delete_tooltip)}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </ActionDropdownMenuContent>
                </ActionDropdownMenu>
            );
        },
        meta: {
            colWidth: "60px",
        },
    },
];
