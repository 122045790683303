import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import {
    AiAnswerStatusFilter,
    QuestionStatusFilter,
} from "@generated/client/graphql";
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryParam } from "use-query-params";
import { questionnaireParamsArgs } from "../../ctx";
import { Questions } from "./components/Questions";
import { QuestionStatusMultiSelect } from "./components/QuestionStatusMultiSelect";
import { Sidebar } from "./components/Sidebar";
import { useQuestionnaireGroups } from "./data";
import { keys, RESOURCE_NAME } from "./i18n";

export const QuestionsTab: FC<{ questionnaireId?: string }> = ({
    questionnaireId,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );
    const [userSearchString, setUserSearchString] = useState<
        string | undefined
    >(undefined);
    const [ownerIds, setOwnerIds] = useState<string[]>([]);

    const [activeGroupIdParams, setActiveGroupIdParams] = useQueryParam(
        ...questionnaireParamsArgs.group,
    );
    const [questionStatuses, setQuestionStatuses] = useState<
        QuestionStatusFilter[]
    >([]);
    const [aiAnswerStatuses, setAiAnswerStatuses] = useState<
        AiAnswerStatusFilter[]
    >([]);

    const { groups } = useQuestionnaireGroups(questionnaireId);
    useEffect(() => {
        if (groups.length && !activeGroupIdParams) {
            setActiveGroupIdParams(
                groups.filter((group) => group.level === 1)?.[0]?.id,
            );
        }
    }, [groups, activeGroupIdParams, setActiveGroupIdParams]);

    const handleStatusChange = (value: QuestionStatusFilter[]) => {
        setQuestionStatuses(value);
    };

    const handleAiAnswerStatusChange = (value: AiAnswerStatusFilter[]) => {
        setAiAnswerStatuses(value);
    };

    const handleOwnerChange = (value: (string | null)[]) => {
        setOwnerIds(value.filter((id) => id !== null) as string[]);
    };

    return (
        <FlexCol gap="4">
            <FlexRow gap="3">
                <SearchBar
                    placeholder={t(keys.search_bar_placeholder)}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    className="w-[232px]"
                />
                <UserMultiSelect
                    values={ownerIds}
                    onValuesChange={handleOwnerChange}
                    searchFilter={userSearchString}
                    onSearchFilterChange={setUserSearchString}
                    includeNone={false}
                />
                <QuestionStatusMultiSelect
                    selectedStatuses={questionStatuses}
                    setStatuses={handleStatusChange}
                    statusType="question"
                    triggerLabel={t(keys.question_status_filter)}
                />
                <QuestionStatusMultiSelect
                    selectedStatuses={aiAnswerStatuses}
                    setStatuses={handleAiAnswerStatusChange}
                    statusType="aiAnswer"
                    triggerLabel={t(keys.ai_answer_status_filter)}
                />
            </FlexRow>
            <FlexRow gap="4" h="full" w="full">
                <Sidebar
                    questionnaireId={questionnaireId}
                    activeGroupId={activeGroupIdParams}
                />
                <Questions
                    filter={{
                        questionnaireId,
                        searchString,
                        ownerIds,
                        statuses: questionStatuses,
                        aiAnswerStatuses,
                    }}
                />
            </FlexRow>
        </FlexCol>
    );
};
