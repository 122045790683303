import { queryClient } from "@app/QueryClientWithHeaders";
import {
    SupplierFilter,
    SupplierTemplateQuestionFilterInput,
    type GetSuppliers_DeprecatedQuery,
    type QuestionnaireFilterInput,
    type SupplierSort,
    type UserFilter,
} from "@generated/client/graphql";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GraphQLSupplierRepository } from "../../../repositories/GraphQLRepositories/SupplierRepository";

const supplierRepository = new GraphQLSupplierRepository();

export type Supplier = Exclude<
    GetSuppliers_DeprecatedQuery["suppliers_deprecated"],
    null | undefined
>[number];

export const supplierKeys = {
    all: ["suppliers"] as const,
    listAll: () => [...supplierKeys.all, "list"] as const,
    list: (
        filter: SupplierFilter = {},
        pagination: {
            skip?: number;
            take?: number;
        } = {},
        orderBy: SupplierSort = {},
    ) =>
        [
            ...supplierKeys.listAll(),
            JSON.stringify(filter),
            JSON.stringify(pagination),
            JSON.stringify(orderBy),
        ] as const,
    suppliersMinimal: (
        filter: SupplierFilter = {},
        pagination: {
            skip?: number;
            take?: number;
        } = {},
        orderBy: SupplierSort = {},
    ) =>
        [
            ...supplierKeys.listAll(),
            "minimal",
            JSON.stringify(filter),
            JSON.stringify(pagination),
            JSON.stringify(orderBy),
        ] as const,
    oneAll: () => [...supplierKeys.all, "one"] as const,
    one: (id?: string | null) => [...supplierKeys.oneAll(), id] as const,
    usersAll: () => [...supplierKeys.all, "users"] as const,
    users: (filter?: UserFilter) =>
        [...supplierKeys.usersAll(), JSON.stringify(filter)] as const,
    questionsAll: () => [...supplierKeys.all, "questions"] as const,
    brand: (id?: string) => [...supplierKeys.one(id), "brand"] as const,
    templateQuestionnaire: () =>
        [...supplierKeys.questionsAll(), "template"] as const,
    templateQuestionsListAll: () =>
        [...supplierKeys.questionsAll(), "templateQuestionsList"] as const,
    templateQuestionsList: (
        filter: SupplierTemplateQuestionFilterInput = {},
        pagination: {
            skip?: number;
            take?: number;
        } = {},
    ) =>
        [
            ...supplierKeys.templateQuestionsListAll(),
            JSON.stringify(filter),
            JSON.stringify(pagination),
        ] as const,
    supplierQuestionnairesListAll: () =>
        [...supplierKeys.oneAll(), "supplierQuestionnairesList"] as const,
    supplierQuestionnairesList: (
        supplierId?: string,
        filter: QuestionnaireFilterInput = {},
        pagination: {
            skip?: number;
            take?: number;
        } = {},
    ) =>
        [
            ...supplierKeys.supplierQuestionnairesListAll(),
            supplierId,
            JSON.stringify(filter),
            JSON.stringify(pagination),
        ] as const,
};

export const useSuppliers = () => {
    const query = useQuery({
        queryKey: supplierKeys.all,
        queryFn: () => supplierRepository.getSuppliers_deprecated(),
    });
    return {
        suppliers: query.data?.suppliers_deprecated,
        isLoading: query.isLoading,
        refetch: query.refetch,
    };
};

export function useSuppliersList(
    filter: SupplierFilter = {},
    pagination: {
        skip?: number;
        take?: number;
    } = {},
    orderBy: SupplierSort = {},
) {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.list(filter, pagination, orderBy),
        queryFn: () =>
            supplierRepository.getSuppliers(filter, pagination, orderBy),
    });
    return {
        suppliers: data?.suppliers ?? { list: [], count: 0 },
        ...query,
    };
}

export const useSuppliersMinimal = (
    filter: SupplierFilter = {},
    orderBy: SupplierSort = {},
) => {
    const { data, ...query } = useQuery({
        queryKey: supplierKeys.suppliersMinimal(filter, {}, orderBy),
        queryFn: () =>
            supplierRepository.getSuppliersMinimal(filter, {}, orderBy),
    });
    return {
        suppliers: data?.suppliers?.list ?? [],
        ...query,
    };
};

export const useCloseQuestionnaires = () => {
    const { mutate: closeQuestionnaires, ...mutation } = useMutation({
        mutationFn: supplierRepository.closeSupplierQuestionnaires,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.all,
            });
        },
    });
    return { closeQuestionnaires, ...mutation };
};

export const useSendQuestionnaires = () => {
    const { mutate: sendQuestionnaires, ...mutation } = useMutation({
        mutationFn: supplierRepository.sendSupplierQuestionnaires,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: supplierKeys.all,
            });
        },
    });
    return { sendQuestionnaires, ...mutation };
};
