import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLEntityRepository } from "@app/repositories/GraphQLRepositories/EntityRepository";
import { entityKeys } from "@app/store/entityStore";
import { useMutation } from "@tanstack/react-query";

const entityRepository = new GraphQLEntityRepository();

export const useCreateEntity = () => {
    return useMutation({
        mutationFn: (input: {
            name: string;
            type: string;
            parentId?: string;
        }) => entityRepository.create(input),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: entityKeys.all });
        },
    });
};

export const useDeleteEntity = () => {
    return useMutation({
        mutationFn: (id: string) => entityRepository.delete(id),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: entityKeys.all });
        },
    });
};

export const useUpdateEntity = () => {
    return useMutation({
        mutationFn: (input: {
            id: string;
            name?: string;
            parentId?: string;
            isParent?: boolean;
        }) => {
            return entityRepository.update(input);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: entityKeys.all });
        },
    });
};
