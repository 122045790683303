import { versionKeys } from "@app/store/queryKeys";
import { FloatingBadge } from "@design-system/DataDisplay/Badge";
import { Icon } from "@design-system/Icon";
import { SegmentButton } from "@design-system/Inputs/SegmentButton";
import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { useMutationState } from "@tanstack/react-query";
import { type FC } from "react";
import {
    EditorMode,
    useEditorContext,
} from "../../../../screens/Document/Edit/hooks/useEditor";
import { useCheckDocument } from "./data";
import "./i18n";
import { useSidebarHeader } from "./useSidebarHeader";

const SideBarHeader: FC = () => {
    const {
        editorMode,
        handleValueChange,
        activeThreadsNumber,
        hasActiveThreads,
        showValidationChecklist,
        t,
        RegularTabs,
        regularModeActiveTab,
        withEditor,
        version,
    } = useSidebarHeader();

    const { setAiCheckLoadingProgress } = useEditorContext();

    const { checkDocument } = useCheckDocument(version?.id ?? "");
    const mutationState = useMutationState({
        filters: { mutationKey: [...versionKeys.aiCheck(version?.id ?? "")] },
        select: (mutation) => mutation.state.status,
    });
    const isAiCheckPending =
        !!mutationState?.length &&
        mutationState[mutationState.length - 1] === "pending";
    const handleAiCoachTabClick = () => {
        if (!version?.aiDocumentChecks && version?.id && !isAiCheckPending) {
            setAiCheckLoadingProgress(0);
            checkDocument();
        }
    };
    return (
        <div>
            {editorMode === EditorMode.REGULAR ? (
                <Box as="header" p="4">
                    <SegmentButton
                        onValueChange={handleValueChange}
                        size="sm"
                        value={regularModeActiveTab}
                    >
                        <SegmentButton.Item value={RegularTabs.INFO}>
                            <Icon name="info" size="sm" />
                        </SegmentButton.Item>
                        <FloatingBadge
                            data-chameleon="comments-badge"
                            content={`${activeThreadsNumber}`}
                            show={hasActiveThreads}
                            variant="error"
                        >
                            <SegmentButton.Item value={RegularTabs.COMMENTS}>
                                <Icon name="comment" size="sm" />
                            </SegmentButton.Item>
                        </FloatingBadge>
                        {showValidationChecklist ? (
                            <SegmentButton.Item
                                value={RegularTabs.AI_COACH}
                                onClick={handleAiCoachTabClick}
                                className="hover:text-accent-1-300 aria-checked:hover:text-accent-1-300 aria-checked:text-accent-1-300"
                            >
                                <Icon
                                    name="magicWand"
                                    className={cn(
                                        isAiCheckPending &&
                                            "animate-pulse text-accent-1-300",
                                    )}
                                    size="sm"
                                />
                            </SegmentButton.Item>
                        ) : null}
                        {withEditor && (
                            <SegmentButton.Item value={RegularTabs.CONTENT}>
                                <Icon name="listOrder" size="sm" />
                            </SegmentButton.Item>
                        )}
                    </SegmentButton>
                </Box>
            ) : (
                <div className="flex px-4 pt-4 pb-2 flex-col items-start gap-4 self-stretch">
                    <div className="font-bold text-xs uppercase">
                        {t("version_history_title")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideBarHeader;
