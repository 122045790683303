import ImageUpload from "@app/shared/components/Upload/ImageUpload";

import {
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useEffect, useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { File } from "../../../../generated/client/graphql";
import Container from "../../../components/Container";
import InputSimple from "../../../components/InputSimple";
import BodyHeader from "../../../shared/components/BodyHeader";
import { useOrgCustomization, useUpdateOrgCustomization } from "./data";
import "./i18n";

export default function General() {
    const { t } = useTranslation("General");
    const { data, refetch, isRefetching } = useOrgCustomization();

    const { mutate: updateOrg, isPending: isMutating } =
        useUpdateOrgCustomization();

    const [name, setName] = useState<string | undefined>(
        data?.org?.tenant?.name || undefined,
    );
    const [color, setColor] = useState<string | undefined>(
        data?.org?.customization?.hexColor || "",
    );

    const [newS3File, setNewS3File] = useState<NewS3File>();

    const canSave =
        color !== data?.org?.customization?.hexColor ||
        (name !== data?.org?.tenant?.name && name !== "") ||
        newS3File;

    const [isOpen, toggle] = useState(false);

    const toggleColorPicker = () => {
        toggle(!isOpen);
    };

    const setEmptyColor = (e: any) => {
        setColor("");
        e.stopPropagation();
    };

    useEffect(() => {
        setName(data?.org?.tenant?.name || undefined);
        setColor(data?.org?.customization?.hexColor || "");
    }, [data]);

    const colorPickerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    const handleClickOutside = (event: any) => {
        if (colorPickerRef && !colorPickerRef.current?.contains(event.target)) {
            toggle(false);
        }
    };

    const handleSaveClick = () => {
        updateOrg(
            {
                name: name || "",
                hexColor: color || "",
                tenantId: data?.org?.tenantId || "",
                logoFile: newS3File
                    ? newS3FileToCreateFileInput(newS3File)
                    : undefined,
            },
            {
                onSuccess: () => {
                    setNewS3File(undefined);
                    refetch();
                },
            },
        );
    };

    return (
        <Container>
            <BodyHeader title={t("title")} subTitle={t("subtitle")} />
            <div className="p-4">
                <div className="max-w-[540px] space-y-3">
                    <FlexCol
                        gap="4"
                        px="6"
                        py="5"
                        br="lg"
                        className="justify-center items-center w-full border border-secondary bg-secondary"
                    >
                        <ImageUpload
                            file={data?.org?.customization?.logo as File}
                            newS3File={newS3File}
                            setNewS3File={setNewS3File}
                        ></ImageUpload>
                    </FlexCol>
                    <InputSimple
                        title={t("organization_name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FlexRow
                        alignItems="center"
                        br="lg"
                        p="3"
                        className="border border-secondary focus-within:border-primary"
                        onClick={toggleColorPicker}
                        ref={colorPickerRef}
                    >
                        <div className="relative">
                            <div
                                className="w-6 h-6 rounded border border-secondary content-center cursor-pointer"
                                style={{ backgroundColor: color || "#ffffff" }}
                            />

                            {isOpen && (
                                <div className="absolute top-9">
                                    <HexColorPicker
                                        color={color}
                                        onChange={setColor}
                                        className="absolute"
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="w-full pl-3">
                            <Text className="text-xs text-secondary">
                                {t("color")}
                            </Text>
                            <HexColorInput
                                color={color}
                                onChange={setColor}
                                prefixed
                                alpha
                                placeholder={t("color")}
                                className="w-full h-full p-0 text-base text-primary border-none focus-within:outline-none focus-within:ring-0"
                            />
                        </div>
                        <Button
                            variant="text"
                            onClick={setEmptyColor}
                            size="xs"
                        >
                            <Button.Icon name="close" size="xs" />
                        </Button>
                    </FlexRow>
                    <Button
                        size="lg"
                        onClick={handleSaveClick}
                        loading={isRefetching || isMutating}
                        variant="flatPrimary"
                        disabled={!canSave}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </Container>
    );
}
