const activeFrameworkBackgroundSvgStr = `<svg width="313" height="196" viewBox="0 0 313 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.1">
<g clip-path="url(#clip0_15592_28911)">
<path d="M117.772 -34.4789L135.432 -13.2197L130.46 -1.90399M117.772 -34.4789L112.8 -23.1632M117.772 -34.4789L90.1689 -33.1066L85.1972 -21.7909M112.8 -23.1632L130.46 -1.90399M112.8 -23.1632L107.829 -11.8474M112.8 -23.1632L85.1972 -21.7909M130.46 -1.90399L125.488 9.41175M107.829 -11.8474L125.488 9.41175M107.829 -11.8474L102.857 -0.531686M107.829 -11.8474L80.2255 -10.4751M125.488 9.41175L120.517 20.7275L102.857 -0.531686M102.857 -0.531686L75.2537 0.840621L80.2255 -10.4751M80.2255 -10.4751L85.1972 -21.7909" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip1_15592_28911)">
<path d="M107.831 -11.8471L125.49 9.4121L120.519 20.7278M107.831 -11.8471L102.859 -0.531342M107.831 -11.8471L80.2275 -10.4748L75.2558 0.840965M102.859 -0.531342L120.519 20.7278M102.859 -0.531342L97.8873 10.7844M102.859 -0.531342L75.2558 0.840965M120.519 20.7278L115.547 32.0436M97.8873 10.7844L115.547 32.0436M97.8873 10.7844L92.9156 22.1001M97.8873 10.7844L70.2841 12.1567M115.547 32.0436L110.575 43.3593L92.9156 22.1001M92.9156 22.1001L65.3123 23.4725L70.2841 12.1567M70.2841 12.1567L75.2558 0.840965" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip2_15592_28911)">
<path d="M97.8854 10.7838L115.545 32.043L110.573 43.3587M97.8854 10.7838L92.9137 22.0995M97.8854 10.7838L70.2822 12.1561L65.3105 23.4718M92.9137 22.0995L110.573 43.3587M92.9137 22.0995L87.942 33.4153M92.9137 22.0995L65.3105 23.4718M110.573 43.3587L105.602 54.6744M87.942 33.4153L105.602 54.6744M87.942 33.4153L82.9702 44.731M87.942 33.4153L60.3387 34.7876M105.602 54.6744L100.63 65.9902L82.9702 44.731M82.9702 44.731L55.367 46.1033L60.3387 34.7876M60.3387 34.7876L65.3105 23.4718" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip3_15592_28911)">
<path d="M87.944 33.4156L105.604 54.6748L100.632 65.9905M87.944 33.4156L82.9723 44.7314M87.944 33.4156L60.3408 34.7879L55.3691 46.1037M82.9723 44.7314L100.632 65.9905M82.9723 44.7314L78.0006 56.0471M82.9723 44.7314L55.3691 46.1037M100.632 65.9905L95.6603 77.3063M78.0006 56.0471L95.6603 77.3063M78.0006 56.0471L73.0288 67.3628M78.0006 56.0471L50.3973 57.4194M95.6603 77.3063L90.6886 88.622L73.0288 67.3628M73.0288 67.3628L45.4256 68.7352L50.3973 57.4194M50.3973 57.4194L55.3691 46.1037" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip4_15592_28911)">
<path d="M77.9987 56.0474L95.6585 77.3066L90.6867 88.6224M77.9987 56.0474L73.027 67.3632M77.9987 56.0474L50.3955 57.4198L45.4237 68.7355M73.027 67.3632L90.6867 88.6224M73.027 67.3632L68.0552 78.6789M73.027 67.3632L45.4237 68.7355M90.6867 88.6224L85.715 99.9381M68.0552 78.6789L85.715 99.9381M68.0552 78.6789L63.0835 89.9947M68.0552 78.6789L40.452 80.0512M85.715 99.9381L80.7433 111.254L63.0835 89.9947M63.0835 89.9947L35.4803 91.367L40.452 80.0512M40.452 80.0512L45.4237 68.7355" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip5_15592_28911)">
<path d="M68.0573 78.6773L85.717 99.9365L80.7453 111.252M68.0573 78.6773L63.0856 89.9931M68.0573 78.6773L40.4541 80.0496L35.4823 91.3654M63.0856 89.9931L80.7453 111.252M63.0856 89.9931L58.1138 101.309M63.0856 89.9931L35.4823 91.3654M80.7453 111.252L75.7736 122.568M58.1138 101.309L75.7736 122.568M58.1138 101.309L53.1421 112.625M58.1138 101.309L30.5106 102.681M75.7736 122.568L70.8019 133.884L53.1421 112.625M53.1421 112.625L25.5389 113.997L30.5106 102.681M30.5106 102.681L35.4823 91.3654" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip6_15592_28911)">
<path d="M58.112 101.309L75.7717 122.568L70.8 133.884M58.112 101.309L53.1402 112.625M58.112 101.309L30.5087 102.681L25.537 113.997M53.1402 112.625L70.8 133.884M53.1402 112.625L48.1685 123.941M53.1402 112.625L25.537 113.997M70.8 133.884L65.8283 145.2M48.1685 123.941L65.8283 145.2M48.1685 123.941L43.1968 135.256M48.1685 123.941L20.5653 125.313M65.8283 145.2L60.8566 156.516L43.1968 135.256M43.1968 135.256L15.5936 136.629L20.5653 125.313M20.5653 125.313L25.537 113.997" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip7_15592_28911)">
<path d="M48.1706 123.941L65.8303 145.2L60.8586 156.516M48.1706 123.941L43.1988 135.257M48.1706 123.941L20.5673 125.313L15.5956 136.629M43.1988 135.257L60.8586 156.516M43.1988 135.257L38.2271 146.572M43.1988 135.257L15.5956 136.629M60.8586 156.516L55.8869 167.832M38.2271 146.572L55.8869 167.832M38.2271 146.572L33.2554 157.888M38.2271 146.572L10.6239 147.945M55.8869 167.832L50.9152 179.147L33.2554 157.888M33.2554 157.888L5.65219 159.261L10.6239 147.945M10.6239 147.945L15.5956 136.629" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip8_15592_28911)">
<path d="M38.2252 146.574L55.885 167.833L50.9133 179.149M38.2252 146.574L33.2535 157.89M38.2252 146.574L10.622 147.946L5.65031 159.262M33.2535 157.89L50.9133 179.149M33.2535 157.89L28.2818 169.205M33.2535 157.89L5.65031 159.262M50.9133 179.149L45.9416 190.464M28.2818 169.205L45.9416 190.464M28.2818 169.205L23.3101 180.521M28.2818 169.205L0.678592 170.578M45.9416 190.464L40.9699 201.78L23.3101 180.521M23.3101 180.521L-4.29313 181.893L0.678592 170.578M0.678592 170.578L5.65031 159.262" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip9_15592_28911)">
<path d="M172.979 -37.224L190.639 -15.9649L185.667 -4.64911M172.979 -37.224L168.007 -25.9083M172.979 -37.224L145.376 -35.8517L140.404 -24.536M168.007 -25.9083L185.667 -4.64911M168.007 -25.9083L163.036 -14.5925M168.007 -25.9083L140.404 -24.536M185.667 -4.64911L180.695 6.66664M163.036 -14.5925L180.695 6.66664M163.036 -14.5925L158.064 -3.2768M163.036 -14.5925L135.432 -13.2202M180.695 6.66664L175.724 17.9824L158.064 -3.2768M158.064 -3.2768L130.461 -1.9045L135.432 -13.2202M135.432 -13.2202L140.404 -24.536" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip10_15592_28911)">
<path d="M163.034 -14.5922L180.694 6.66698L175.722 17.9827M163.034 -14.5922L158.062 -3.27646M163.034 -14.5922L135.431 -13.2199L130.459 -1.90415M158.062 -3.27646L175.722 17.9827M158.062 -3.27646L153.09 8.03929M158.062 -3.27646L130.459 -1.90415M175.722 17.9827L170.75 29.2985M153.09 8.03929L170.75 29.2985M153.09 8.03929L148.119 19.355M153.09 8.03929L125.487 9.41159M170.75 29.2985L165.778 40.6142L148.119 19.355M148.119 19.355L120.515 20.7273L125.487 9.41159M125.487 9.41159L130.459 -1.90415" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip11_15592_28911)">
<path d="M153.092 8.03963L170.752 29.2988L165.78 40.6146M153.092 8.03963L148.121 19.3554M153.092 8.03963L125.489 9.41194L120.517 20.7277M148.121 19.3554L165.78 40.6146M148.121 19.3554L143.149 30.6711M148.121 19.3554L120.517 20.7277M165.78 40.6146L160.809 51.9303M143.149 30.6711L160.809 51.9303M143.149 30.6711L138.177 41.9869M143.149 30.6711L115.546 32.0434M160.809 51.9303L155.837 63.2461L138.177 41.9869M138.177 41.9869L110.574 43.3592L115.546 32.0434M115.546 32.0434L120.517 20.7277" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip12_15592_28911)">
<path d="M143.147 30.6705L160.807 51.9297L155.835 63.2454M143.147 30.6705L138.175 41.9862M143.147 30.6705L115.544 32.0428L110.572 43.3585M138.175 41.9862L155.835 63.2454M138.175 41.9862L133.204 53.302M138.175 41.9862L110.572 43.3585M155.835 63.2454L150.863 74.5612M133.204 53.302L150.863 74.5612M133.204 53.302L128.232 64.6177M133.204 53.302L105.6 54.6743M150.863 74.5612L145.892 85.8769L128.232 64.6177M128.232 64.6177L100.629 65.99L105.6 54.6743M105.6 54.6743L110.572 43.3585" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip13_15592_28911)">
<path d="M133.206 53.3023L150.865 74.5615L145.894 85.8773M133.206 53.3023L128.234 64.6181M133.206 53.3023L105.602 54.6746L100.631 65.9904M128.234 64.6181L145.894 85.8773M128.234 64.6181L123.262 75.9338M128.234 64.6181L100.631 65.9904M145.894 85.8773L140.922 97.193M123.262 75.9338L140.922 97.193M123.262 75.9338L118.291 87.2496M123.262 75.9338L95.6591 77.3061M140.922 97.193L135.95 108.509L118.291 87.2496M118.291 87.2496L90.6873 88.6219L95.6591 77.3061M95.6591 77.3061L100.631 65.9904" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip14_15592_28911)">
<path d="M123.26 75.9342L140.92 97.1933L135.948 108.509M123.26 75.9342L118.289 87.2499M123.26 75.9342L95.6572 77.3065L90.6855 88.6222M118.289 87.2499L135.948 108.509M118.289 87.2499L113.317 98.5657M118.289 87.2499L90.6855 88.6222M135.948 108.509L130.977 119.825M113.317 98.5657L130.977 119.825M113.317 98.5657L108.345 109.881M113.317 98.5657L85.7137 99.938M130.977 119.825L126.005 131.141L108.345 109.881M108.345 109.881L80.742 111.254L85.7137 99.938M85.7137 99.938L90.6855 88.6222" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip15_15592_28911)">
<path d="M113.319 98.564L130.979 119.823L126.007 131.139M113.319 98.564L108.347 109.88M113.319 98.564L85.7158 99.9364L80.7441 111.252M108.347 109.88L126.007 131.139M108.347 109.88L103.376 121.196M108.347 109.88L80.7441 111.252M126.007 131.139L121.035 142.455M103.376 121.196L121.035 142.455M103.376 121.196L98.4038 132.511M103.376 121.196L75.7723 122.568M121.035 142.455L116.064 153.77L98.4038 132.511M98.4038 132.511L70.8006 133.884L75.7723 122.568M75.7723 122.568L80.7441 111.252" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip16_15592_28911)">
<path d="M103.374 121.196L121.033 142.455L116.062 153.771M103.374 121.196L98.402 132.512M103.374 121.196L75.7705 122.568L70.7987 133.884M98.402 132.512L116.062 153.771M98.402 132.512L93.4302 143.827M98.402 132.512L70.7987 133.884M116.062 153.771L111.09 165.087M93.4302 143.827L111.09 165.087M93.4302 143.827L88.4585 155.143M93.4302 143.827L65.827 145.2M111.09 165.087L106.118 176.402L88.4585 155.143M88.4585 155.143L60.8553 156.515L65.827 145.2M65.827 145.2L70.7987 133.884" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip17_15592_28911)">
<path d="M93.4323 143.828L111.092 165.087L106.12 176.403M93.4323 143.828L88.4606 155.144M93.4323 143.828L65.8291 145.201L60.8573 156.516M88.4606 155.144L106.12 176.403M88.4606 155.144L83.4888 166.46M88.4606 155.144L60.8573 156.516M106.12 176.403L101.149 187.719M83.4888 166.46L101.149 187.719M83.4888 166.46L78.5171 177.775M83.4888 166.46L55.8856 167.832M101.149 187.719L96.1769 199.035L78.5171 177.775M78.5171 177.775L50.9139 179.148L55.8856 167.832M55.8856 167.832L60.8573 156.516" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip18_15592_28911)">
<path d="M83.487 166.461L101.147 187.72L96.175 199.035M83.487 166.461L78.5152 177.776M83.487 166.461L55.8837 167.833L50.912 179.149M78.5152 177.776L96.175 199.035M78.5152 177.776L73.5435 189.092M78.5152 177.776L50.912 179.149M96.175 199.035L91.2033 210.351M73.5435 189.092L91.2033 210.351M73.5435 189.092L68.5718 200.408M73.5435 189.092L45.9403 190.464M91.2033 210.351L86.2316 221.667L68.5718 200.408M68.5718 200.408L40.9686 201.78L45.9403 190.464M45.9403 190.464L50.912 179.149" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip19_15592_28911)">
<path d="M228.186 -39.9672L245.846 -18.708L240.874 -7.39227M228.186 -39.9672L223.214 -28.6515M228.186 -39.9672L200.583 -38.5949L195.611 -27.2792M223.214 -28.6515L240.874 -7.39227M223.214 -28.6515L218.243 -17.3357M223.214 -28.6515L195.611 -27.2792M240.874 -7.39227L235.903 3.92347M218.243 -17.3357L235.903 3.92347M218.243 -17.3357L213.271 -6.01997M218.243 -17.3357L190.64 -15.9634M235.903 3.92347L230.931 15.2392L213.271 -6.01997M213.271 -6.01997L185.668 -4.64766L190.64 -15.9634M190.64 -15.9634L195.611 -27.2792" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip20_15592_28911)">
<path d="M218.245 -17.3368L235.905 3.92235L230.933 15.2381M218.245 -17.3368L213.273 -6.02109M218.245 -17.3368L190.642 -15.9645L185.67 -4.64878M213.273 -6.02109L230.933 15.2381M213.273 -6.02109L208.301 5.29466M213.273 -6.02109L185.67 -4.64878M230.933 15.2381L225.961 26.5538M208.301 5.29466L225.961 26.5538M208.301 5.29466L203.33 16.6104M208.301 5.29466L180.698 6.66696M225.961 26.5538L220.989 37.8696L203.33 16.6104M203.33 16.6104L175.726 17.9827L180.698 6.66696M180.698 6.66696L185.67 -4.64878" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip21_15592_28911)">
<path d="M208.299 5.295L225.959 26.5542L220.988 37.8699M208.299 5.295L203.328 16.6107M208.299 5.295L180.696 6.66731L175.725 17.9831M203.328 16.6107L220.988 37.8699M203.328 16.6107L198.356 27.9265M203.328 16.6107L175.725 17.9831M220.988 37.8699L216.016 49.1857M198.356 27.9265L216.016 49.1857M198.356 27.9265L193.384 39.2422M198.356 27.9265L170.753 29.2988M216.016 49.1857L211.044 60.5014L193.384 39.2422M193.384 39.2422L165.781 40.6145L170.753 29.2988M170.753 29.2988L175.725 17.9831" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip22_15592_28911)">
<path d="M198.358 27.9268L216.018 49.186L211.046 60.5018M198.358 27.9268L193.386 39.2426M198.358 27.9268L170.755 29.2991L165.783 40.6149M193.386 39.2426L211.046 60.5018M193.386 39.2426L188.415 50.5583M193.386 39.2426L165.783 40.6149M211.046 60.5018L206.074 71.8175M188.415 50.5583L206.074 71.8175M188.415 50.5583L183.443 61.8741M188.415 50.5583L160.811 51.9306M206.074 71.8175L201.103 83.1333L183.443 61.8741M183.443 61.8741L155.84 63.2464L160.811 51.9306M160.811 51.9306L165.783 40.6149" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip23_15592_28911)">
<path d="M188.413 50.5572L206.073 71.8164L201.101 83.1321M188.413 50.5572L183.441 61.873M188.413 50.5572L160.81 51.9295L155.838 63.2453M183.441 61.873L201.101 83.1321M183.441 61.873L178.469 73.1887M183.441 61.873L155.838 63.2453M201.101 83.1321L196.129 94.4479M178.469 73.1887L196.129 94.4479M178.469 73.1887L173.498 84.5044M178.469 73.1887L150.866 74.561M196.129 94.4479L191.157 105.764L173.498 84.5044M173.498 84.5044L145.894 85.8768L150.866 74.561M150.866 74.561L155.838 63.2453" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip24_15592_28911)">
<path d="M178.471 73.1895L196.131 94.4487L191.159 105.764M178.471 73.1895L173.5 84.5053M178.471 73.1895L150.868 74.5618L145.896 85.8776M173.5 84.5053L191.159 105.764M173.5 84.5053L168.528 95.821M173.5 84.5053L145.896 85.8776M191.159 105.764L186.188 117.08M168.528 95.821L186.188 117.08M168.528 95.821L163.556 107.137M168.528 95.821L140.925 97.1933M186.188 117.08L181.216 128.396L163.556 107.137M163.556 107.137L135.953 108.509L140.925 97.1933M140.925 97.1933L145.896 85.8776" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip25_15592_28911)">
<path d="M168.526 95.8214L186.186 117.081L181.214 128.396M168.526 95.8214L163.554 107.137M168.526 95.8214L140.923 97.1937L135.951 108.509M163.554 107.137L181.214 128.396M163.554 107.137L158.583 118.453M163.554 107.137L135.951 108.509M181.214 128.396L176.242 139.712M158.583 118.453L176.242 139.712M158.583 118.453L153.611 129.769M158.583 118.453L130.979 119.825M176.242 139.712L171.271 151.028L153.611 129.769M153.611 129.769L126.008 131.141L130.979 119.825M130.979 119.825L135.951 108.509" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip26_15592_28911)">
<path d="M158.585 118.451L176.244 139.71L171.273 151.026M158.585 118.451L153.613 129.767M158.585 118.451L130.981 119.824L126.01 131.139M153.613 129.767L171.273 151.026M153.613 129.767L148.641 141.083M153.613 129.767L126.01 131.139M171.273 151.026L166.301 162.342M148.641 141.083L166.301 162.342M148.641 141.083L143.669 152.398M148.641 141.083L121.038 142.455M166.301 162.342L161.329 173.658L143.669 152.398M143.669 152.398L116.066 153.771L121.038 142.455M121.038 142.455L126.01 131.139" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip27_15592_28911)">
<path d="M148.639 141.084L166.299 162.343L161.327 173.659M148.639 141.084L143.668 152.399M148.639 141.084L121.036 142.456L116.064 153.772M143.668 152.399L161.327 173.659M143.668 152.399L138.696 163.715M143.668 152.399L116.064 153.772M161.327 173.659L156.356 184.974M138.696 163.715L156.356 184.974M138.696 163.715L133.724 175.031M138.696 163.715L111.093 165.087M156.356 184.974L151.384 196.29L133.724 175.031M133.724 175.031L106.121 176.403L111.093 165.087M111.093 165.087L116.064 153.772" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip28_15592_28911)">
<path d="M138.698 163.715L156.358 184.975L151.386 196.29M138.698 163.715L133.726 175.031M138.698 163.715L111.095 165.088L106.123 176.403M133.726 175.031L151.386 196.29M133.726 175.031L128.754 186.347M133.726 175.031L106.123 176.403M151.386 196.29L146.414 207.606M128.754 186.347L146.414 207.606M128.754 186.347L123.783 197.663M128.754 186.347L101.151 187.719M146.414 207.606L141.443 218.922L123.783 197.663M123.783 197.663L96.1795 199.035L101.151 187.719M101.151 187.719L106.123 176.403" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip29_15592_28911)">
<path d="M128.753 186.347L146.412 207.606L141.441 218.922M128.753 186.347L123.781 197.663M128.753 186.347L101.149 187.72L96.1777 199.035M123.781 197.663L141.441 218.922M123.781 197.663L118.809 208.979M123.781 197.663L96.1777 199.035M141.441 218.922L136.469 230.238M118.809 208.979L136.469 230.238M118.809 208.979L113.837 220.294M118.809 208.979L91.2059 210.351M136.469 230.238L131.497 241.554L113.837 220.294M113.837 220.294L86.2342 221.667L91.2059 210.351M91.2059 210.351L96.1777 199.035" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip30_15592_28911)">
<path d="M283.393 -42.7123L301.053 -21.4531L296.081 -10.1374M283.393 -42.7123L278.421 -31.3966M283.393 -42.7123L255.79 -41.34L250.818 -30.0243M278.421 -31.3966L296.081 -10.1374M278.421 -31.3966L273.45 -20.0808M278.421 -31.3966L250.818 -30.0243M296.081 -10.1374L291.11 1.17835M273.45 -20.0808L291.11 1.17835M273.45 -20.0808L268.478 -8.76508M273.45 -20.0808L245.847 -18.7085M291.11 1.17835L286.138 12.4941L268.478 -8.76508M268.478 -8.76508L240.875 -7.39278L245.847 -18.7085M245.847 -18.7085L250.818 -30.0243" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip31_15592_28911)">
<path d="M273.452 -20.08L291.112 1.17919L286.14 12.4949M273.452 -20.08L268.48 -8.76425M273.452 -20.08L245.849 -18.7077L240.877 -7.39195M268.48 -8.76425L286.14 12.4949M268.48 -8.76425L263.508 2.55149M268.48 -8.76425L240.877 -7.39195M286.14 12.4949L281.168 23.8107M263.508 2.55149L281.168 23.8107M263.508 2.55149L258.537 13.8672M263.508 2.55149L235.905 3.9238M281.168 23.8107L276.196 35.1264L258.537 13.8672M258.537 13.8672L230.933 15.2395L235.905 3.9238M235.905 3.9238L240.877 -7.39195" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip32_15592_28911)">
<path d="M263.506 2.54988L281.166 23.8091L276.195 35.1248M263.506 2.54988L258.535 13.8656M263.506 2.54988L235.903 3.92219L230.932 15.2379M258.535 13.8656L276.195 35.1248M258.535 13.8656L253.563 25.1814M258.535 13.8656L230.932 15.2379M276.195 35.1248L271.223 46.4406M253.563 25.1814L271.223 46.4406M253.563 25.1814L248.591 36.4971M253.563 25.1814L225.96 26.5537M271.223 46.4406L266.251 57.7563L248.591 36.4971M248.591 36.4971L220.988 37.8694L225.96 26.5537M225.96 26.5537L230.932 15.2379" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip33_15592_28911)">
<path d="M253.561 25.1817L271.221 46.4409L266.249 57.7567M253.561 25.1817L248.589 36.4975M253.561 25.1817L225.958 26.554L220.986 37.8698M248.589 36.4975L266.249 57.7567M248.589 36.4975L243.618 47.8132M248.589 36.4975L220.986 37.8698M266.249 57.7567L261.278 69.0724M243.618 47.8132L261.278 69.0724M243.618 47.8132L238.646 59.129M243.618 47.8132L216.015 49.1855M261.278 69.0724L256.306 80.3881L238.646 59.129M238.646 59.129L211.043 60.5013L216.015 49.1855M216.015 49.1855L220.986 37.8698" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip34_15592_28911)">
<path d="M243.62 47.8136L261.28 69.0727L256.308 80.3885M243.62 47.8136L238.648 59.1293M243.62 47.8136L216.017 49.1859L211.045 60.5016M238.648 59.1293L256.308 80.3885M238.648 59.1293L233.676 70.445M238.648 59.1293L211.045 60.5016M256.308 80.3885L251.336 91.7042M233.676 70.445L251.336 91.7042M233.676 70.445L228.705 81.7608M233.676 70.445L206.073 71.8174M251.336 91.7042L246.364 103.02L228.705 81.7608M228.705 81.7608L201.101 83.1331L206.073 71.8174M206.073 71.8174L211.045 60.5016" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip35_15592_28911)">
<path d="M233.674 70.4434L251.334 91.7026L246.363 103.018M233.674 70.4434L228.703 81.7592M233.674 70.4434L206.071 71.8157L201.1 83.1315M228.703 81.7592L246.363 103.018M228.703 81.7592L223.731 93.0749M228.703 81.7592L201.1 83.1315M246.363 103.018L241.391 114.334M223.731 93.0749L241.391 114.334M223.731 93.0749L218.759 104.391M223.731 93.0749L196.128 94.4472M241.391 114.334L236.419 125.65L218.759 104.391M218.759 104.391L191.156 105.763L196.128 94.4472M196.128 94.4472L201.1 83.1315" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip36_15592_28911)">
<path d="M223.733 93.0763L241.393 114.335L236.421 125.651M223.733 93.0763L218.761 104.392M223.733 93.0763L196.13 94.4486L191.158 105.764M218.761 104.392L236.421 125.651M218.761 104.392L213.79 115.708M218.761 104.392L191.158 105.764M236.421 125.651L231.449 136.967M213.79 115.708L231.449 136.967M213.79 115.708L208.818 127.023M213.79 115.708L186.186 117.08M231.449 136.967L226.478 148.283L208.818 127.023M208.818 127.023L181.215 128.396L186.186 117.08M186.186 117.08L191.158 105.764" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip37_15592_28911)">
<path d="M213.788 115.708L231.448 136.967L226.476 148.283M213.788 115.708L208.816 127.024M213.788 115.708L186.185 117.08L181.213 128.396M208.816 127.024L226.476 148.283M208.816 127.024L203.844 138.34M208.816 127.024L181.213 128.396M226.476 148.283L221.504 159.599M203.844 138.34L221.504 159.599M203.844 138.34L198.873 149.655M203.844 138.34L176.241 139.712M221.504 159.599L216.532 170.915L198.873 149.655M198.873 149.655L171.269 151.028L176.241 139.712M176.241 139.712L181.213 128.396" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip38_15592_28911)">
<path d="M203.846 138.34L221.506 159.599L216.534 170.915M203.846 138.34L198.875 149.656M203.846 138.34L176.243 139.712L171.271 151.028M198.875 149.656L216.534 170.915M198.875 149.656L193.903 160.971M198.875 149.656L171.271 151.028M216.534 170.915L211.563 182.231M193.903 160.971L211.563 182.231M193.903 160.971L188.931 172.287M193.903 160.971L166.3 162.344M211.563 182.231L206.591 193.546L188.931 172.287M188.931 172.287L161.328 173.659L166.3 162.344M166.3 162.344L171.271 151.028" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip39_15592_28911)">
<path d="M193.901 160.97L211.561 182.229L206.589 193.545M193.901 160.97L188.929 172.286M193.901 160.97L166.298 162.342L161.326 173.658M188.929 172.286L206.589 193.545M188.929 172.286L183.958 183.601M188.929 172.286L161.326 173.658M206.589 193.545L201.617 204.86M183.958 183.601L201.617 204.86M183.958 183.601L178.986 194.917M183.958 183.601L156.354 184.974M201.617 204.86L196.646 216.176L178.986 194.917M178.986 194.917L151.383 196.289L156.354 184.974M156.354 184.974L161.326 173.658" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip40_15592_28911)">
<path d="M183.96 183.602L201.619 204.861L196.648 216.177M183.96 183.602L178.988 194.918M183.96 183.602L156.356 184.974L151.385 196.29M178.988 194.918L196.648 216.177M178.988 194.918L174.016 206.234M178.988 194.918L151.385 196.29M196.648 216.177L191.676 227.493M174.016 206.234L191.676 227.493M174.016 206.234L169.044 217.549M174.016 206.234L146.413 207.606M191.676 227.493L186.704 238.809L169.044 217.549M169.044 217.549L141.441 218.922L146.413 207.606M146.413 207.606L151.385 196.29" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip41_15592_28911)">
<path d="M328.655 -22.8256L346.315 -1.56642L341.343 9.74933M328.655 -22.8256L323.683 -11.5099M328.655 -22.8256L301.052 -21.4533L296.08 -10.1376M323.683 -11.5099L341.343 9.74933M323.683 -11.5099L318.711 -0.194112M323.683 -11.5099L296.08 -10.1376M341.343 9.74933L336.371 21.0651M318.711 -0.194112L336.371 21.0651M318.711 -0.194112L313.74 11.1216M318.711 -0.194112L291.108 1.1782M336.371 21.0651L331.4 32.3808L313.74 11.1216M313.74 11.1216L286.137 12.4939L291.108 1.1782M291.108 1.1782L296.08 -10.1376" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip42_15592_28911)">
<path d="M318.71 -0.19328L336.369 21.0659L331.398 32.3817M318.71 -0.19328L313.738 11.1225M318.71 -0.19328L291.106 1.17903L286.135 12.4948M313.738 11.1225L331.398 32.3817M313.738 11.1225L308.766 22.4382M313.738 11.1225L286.135 12.4948M331.398 32.3817L326.426 43.6974M308.766 22.4382L326.426 43.6974M308.766 22.4382L303.794 33.754M308.766 22.4382L281.163 23.8105M326.426 43.6974L321.454 55.0131L303.794 33.754M303.794 33.754L276.191 35.1263L281.163 23.8105M281.163 23.8105L286.135 12.4948" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip43_15592_28911)">
<path d="M308.768 22.4366L326.428 43.6958L321.456 55.0115M308.768 22.4366L303.796 33.7523M308.768 22.4366L281.165 23.8089L276.193 35.1247M303.796 33.7523L321.456 55.0115M303.796 33.7523L298.825 45.0681M303.796 33.7523L276.193 35.1247M321.456 55.0115L316.485 66.3273M298.825 45.0681L316.485 66.3273M298.825 45.0681L293.853 56.3838M298.825 45.0681L271.222 46.4404M316.485 66.3273L311.513 77.643L293.853 56.3838M293.853 56.3838L266.25 57.7561L271.222 46.4404M271.222 46.4404L276.193 35.1247" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip44_15592_28911)">
<path d="M298.823 45.0684L316.483 66.3276L311.511 77.6434M298.823 45.0684L293.851 56.3842M298.823 45.0684L271.22 46.4407L266.248 57.7565M293.851 56.3842L311.511 77.6434M293.851 56.3842L288.879 67.6999M293.851 56.3842L266.248 57.7565M311.511 77.6434L306.539 88.9591M288.879 67.6999L306.539 88.9591M288.879 67.6999L283.908 79.0157M288.879 67.6999L261.276 69.0722M306.539 88.9591L301.568 100.275L283.908 79.0157M283.908 79.0157L256.305 80.388L261.276 69.0722M261.276 69.0722L266.248 57.7565" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip45_15592_28911)">
<path d="M288.881 67.7003L306.541 88.9595L301.57 100.275M288.881 67.7003L283.91 79.016M288.881 67.7003L261.278 69.0726L256.307 80.3883M283.91 79.016L301.57 100.275M283.91 79.016L278.938 90.3318M283.91 79.016L256.307 80.3883M301.57 100.275L296.598 111.591M278.938 90.3318L296.598 111.591M278.938 90.3318L273.966 101.648M278.938 90.3318L251.335 91.7041M296.598 111.591L291.626 122.907L273.966 101.648M273.966 101.648L246.363 103.02L251.335 91.7041M251.335 91.7041L256.307 80.3883" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip46_15592_28911)">
<path d="M278.936 90.3302L296.596 111.589L291.624 122.905M278.936 90.3302L273.964 101.646M278.936 90.3302L251.333 91.7025L246.361 103.018M273.964 101.646L291.624 122.905M273.964 101.646L268.993 112.962M273.964 101.646L246.361 103.018M291.624 122.905L286.653 134.221M268.993 112.962L286.653 134.221M268.993 112.962L264.021 124.277M268.993 112.962L241.39 114.334M286.653 134.221L281.681 145.537L264.021 124.277M264.021 124.277L236.418 125.65L241.39 114.334M241.39 114.334L246.361 103.018" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip47_15592_28911)">
<path d="M268.995 112.963L286.655 134.222L281.683 145.538M268.995 112.963L264.023 124.279M268.995 112.963L241.392 114.335L236.42 125.651M264.023 124.279L281.683 145.538M264.023 124.279L259.051 135.594M264.023 124.279L236.42 125.651M281.683 145.538L276.711 156.854M259.051 135.594L276.711 156.854M259.051 135.594L254.08 146.91M259.051 135.594L231.448 136.967M276.711 156.854L271.739 168.169L254.08 146.91M254.08 146.91L226.476 148.283L231.448 136.967M231.448 136.967L236.42 125.651" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip48_15592_28911)">
<path d="M259.049 135.595L276.709 156.854L271.738 168.17M259.049 135.595L254.078 146.911M259.049 135.595L231.446 136.967L226.475 148.283M254.078 146.911L271.738 168.17M254.078 146.911L249.106 158.226M254.078 146.911L226.475 148.283M271.738 168.17L266.766 179.485M249.106 158.226L266.766 179.485M249.106 158.226L244.134 169.542M249.106 158.226L221.503 159.599M266.766 179.485L261.794 190.801L244.134 169.542M244.134 169.542L216.531 170.914L221.503 159.599M221.503 159.599L226.475 148.283" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip49_15592_28911)">
<path d="M249.108 158.227L266.768 179.486L261.796 190.802M249.108 158.227L244.136 169.542M249.108 158.227L221.505 159.599L216.533 170.915M244.136 169.542L261.796 190.802M244.136 169.542L239.165 180.858M244.136 169.542L216.533 170.915M261.796 190.802L256.824 202.117M239.165 180.858L256.824 202.117M239.165 180.858L234.193 192.174M239.165 180.858L211.561 182.23M256.824 202.117L251.853 213.433L234.193 192.174M234.193 192.174L206.59 193.546L211.561 182.23M211.561 182.23L216.533 170.915" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip50_15592_28911)">
<path d="M239.163 180.857L256.823 202.116L251.851 213.432M239.163 180.857L234.191 192.173M239.163 180.857L211.56 182.229L206.588 193.545M234.191 192.173L251.851 213.432M234.191 192.173L229.219 203.489M234.191 192.173L206.588 193.545M251.851 213.432L246.879 224.748M229.219 203.489L246.879 224.748M229.219 203.489L224.248 214.804M229.219 203.489L201.616 204.861M246.879 224.748L241.907 236.063L224.248 214.804M224.248 214.804L196.644 216.177L201.616 204.861M201.616 204.861L206.588 193.545" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip51_15592_28911)">
<path d="M344.089 64.9556L361.748 86.2148L356.777 97.5306M344.089 64.9556L339.117 76.2714M344.089 64.9556L316.485 66.328L311.514 77.6437M339.117 76.2714L356.777 97.5306M339.117 76.2714L334.145 87.5871M339.117 76.2714L311.514 77.6437M356.777 97.5306L351.805 108.846M334.145 87.5871L351.805 108.846M334.145 87.5871L329.173 98.9029M334.145 87.5871L306.542 88.9594M351.805 108.846L346.833 120.162L329.173 98.9029M329.173 98.9029L301.57 100.275L306.542 88.9594M306.542 88.9594L311.514 77.6437" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip52_15592_28911)">
<path d="M334.147 87.5875L351.807 108.847L346.835 120.162M334.147 87.5875L329.175 98.9032M334.147 87.5875L306.544 88.9598L301.572 100.276M329.175 98.9032L346.835 120.162M329.175 98.9032L324.204 110.219M329.175 98.9032L301.572 100.276M346.835 120.162L341.863 131.478M324.204 110.219L341.863 131.478M324.204 110.219L319.232 121.535M324.204 110.219L296.6 111.591M341.863 131.478L336.892 142.794L319.232 121.535M319.232 121.535L291.629 122.907L296.6 111.591M296.6 111.591L301.572 100.276" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip53_15592_28911)">
<path d="M324.202 110.217L341.862 131.477L336.89 142.792M324.202 110.217L319.23 121.533M324.202 110.217L296.599 111.59L291.627 122.905M319.23 121.533L336.89 142.792M319.23 121.533L314.258 132.849M319.23 121.533L291.627 122.905M336.89 142.792L331.918 154.108M314.258 132.849L331.918 154.108M314.258 132.849L309.287 144.165M314.258 132.849L286.655 134.221M331.918 154.108L326.946 165.424L309.287 144.165M309.287 144.165L281.683 145.537L286.655 134.221M286.655 134.221L291.627 122.905" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip54_15592_28911)">
<path d="M314.26 132.85L331.92 154.109L326.948 165.425M314.26 132.85L309.289 144.165M314.26 132.85L286.657 134.222L281.685 145.538M309.289 144.165L326.948 165.425M309.289 144.165L304.317 155.481M309.289 144.165L281.685 145.538M326.948 165.425L321.977 176.74M304.317 155.481L321.977 176.74M304.317 155.481L299.345 166.797M304.317 155.481L276.714 156.853M321.977 176.74L317.005 188.056L299.345 166.797M299.345 166.797L271.742 168.169L276.714 156.853M276.714 156.853L281.685 145.538" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip55_15592_28911)">
<path d="M304.315 155.482L321.975 176.741L317.003 188.057M304.315 155.482L299.343 166.798M304.315 155.482L276.712 156.854L271.74 168.17M299.343 166.798L317.003 188.057M299.343 166.798L294.372 178.114M299.343 166.798L271.74 168.17M317.003 188.057L312.031 199.373M294.372 178.114L312.031 199.373M294.372 178.114L289.4 189.429M294.372 178.114L266.768 179.486M312.031 199.373L307.06 210.688L289.4 189.429M289.4 189.429L261.797 190.802L266.768 179.486M266.768 179.486L271.74 168.17" stroke="white" stroke-width="1.23598"/>
</g>
<g clip-path="url(#clip56_15592_28911)">
<path d="M294.374 178.114L312.033 199.373L307.062 210.689M294.374 178.114L289.402 189.43M294.374 178.114L266.77 179.486L261.799 190.802M289.402 189.43L307.062 210.689M289.402 189.43L284.43 200.745M289.402 189.43L261.799 190.802M307.062 210.689L302.09 222.005M284.43 200.745L302.09 222.005M284.43 200.745L279.459 212.061M284.43 200.745L256.827 202.118M302.09 222.005L297.118 233.32L279.459 212.061M279.459 212.061L251.855 213.433L256.827 202.118M256.827 202.118L261.799 190.802" stroke="white" stroke-width="1.23598"/>
</g>
</g>
<defs>
<clipPath id="clip0_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(90.168 -33.1055) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip1_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(80.2266 -10.4741) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip2_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(70.2812 12.1577) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip3_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(60.3398 34.7891) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip4_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(50.3945 57.4204) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip5_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(40.4531 80.0518) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip6_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(30.5078 102.683) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip7_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(20.5664 125.314) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip8_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(10.6211 147.946) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip9_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(145.375 -35.8501) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip10_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(135.43 -13.2188) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip11_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(125.488 9.4126) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip12_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(115.543 32.0444) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip13_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(105.602 54.6758) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip14_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(95.6562 77.3071) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip15_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(85.7148 99.9385) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip16_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(75.7695 122.57) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip17_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(65.8281 145.202) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip18_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(55.8828 167.833) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip19_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(200.582 -38.5947) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip20_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(190.641 -15.9629) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip21_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(180.695 6.66846) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip22_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(170.754 29.2998) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip23_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(160.809 51.9316) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip24_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(150.867 74.563) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip25_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(140.922 97.1943) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip26_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(130.98 119.826) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip27_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(121.035 142.458) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip28_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(111.094 165.089) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip29_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(101.148 187.72) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip30_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(255.789 -41.3394) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip31_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(245.848 -18.7075) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip32_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(235.902 3.92383) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip33_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(225.957 26.5552) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip34_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(216.016 49.1865) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip35_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(206.07 71.8179) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip36_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(196.129 94.4497) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip37_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(186.184 117.081) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip38_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(176.242 139.712) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip39_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(166.297 162.344) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip40_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(156.355 184.976) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip41_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(301.051 -21.4526) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip42_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(291.105 1.1792) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip43_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(281.164 23.8105) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip44_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(271.219 46.4419) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip45_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(261.277 69.0732) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip46_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(251.332 91.7046) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip47_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(241.391 114.336) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip48_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(231.445 136.968) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip49_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(221.504 159.599) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip50_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(211.559 182.231) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip51_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(316.484 66.3291) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip52_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(306.543 88.9604) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip53_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(296.598 111.592) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip54_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(286.656 134.224) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip55_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(276.711 156.855) rotate(23.7189)"/>
</clipPath>
<clipPath id="clip56_15592_28911">
<rect width="49.4391" height="24.7196" fill="white" transform="translate(266.77 179.486) rotate(23.7189)"/>
</clipPath>
</defs>
</svg>
`;

export const activeFrameworkBackgroundSvgDataUrl = `data:image/svg+xml;base64,${btoa(activeFrameworkBackgroundSvgStr)}`;
