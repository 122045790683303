import i18next from "@app/i18n";

const enResources = {
    search_bar_placeholder: "Search questions",
    question_status_filter: "Status",
    ai_answer_status_filter: "AI answer status",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    search_bar_placeholder: "Rechercher une question",
    question_status_filter: "Statut",
    ai_answer_status_filter: "Statut de la réponse AI",
};

export const RESOURCE_NAME = "ExternalQuestionnaireQuestion";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
