import { graphql } from "@generated/client";
import {
    type AnswerUpsertInput,
    type CreateSupplierTemplateQuestionsFromFileInput,
    type CreateSupplierUserInput,
    type PaginationInput,
    type QuestionnaireFilterInput,
    type QuestionnaireLocaleInput,
    type SendQuestionnaireInput,
    type SendQuestionnairesInput,
    type SupplierFilter,
    type SupplierSort,
    type SupplierTemplateQuestionCreateInput,
    type SupplierTemplateQuestionFilterInput,
    type SupplierTemplateQuestionUpdateInput,
    type UpdateSupplierInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetSupplierQuery = graphql(`
    query GetSupplier($id: String!) {
        supplier(id: $id) {
            id
            name
            tags {
                id
                name
                color
            }
            users {
                id
                email
                firstName
                lastName
                department
                avatar {
                    signedUrl
                }
            }
        }
    }
`);

const GetSupplierQuestionnaireTemplatesQuery = graphql(`
    query getSupplierQuestionnaireTemplates {
        supplierQuestionnaireTemplates {
            id
            name
        }
    }
`);

const GetSupplierQuestionnairesQuery = graphql(`
    query GetSupplierQuestionnaires(
        $supplierId: ID!
        $filter: QuestionnaireFilterInput
        $pagination: PaginationInput!
    ) {
        supplierQuestionnaires2(
            supplierId: $supplierId
            filter: $filter
            pagination: $pagination
        ) {
            list: data {
                id
                name
                createdAt
                deadline
                stats {
                    total
                    answered
                }
                sentBy {
                    firstName
                    lastName
                    avatar {
                        signedUrl
                    }
                }
            }
            count
            skip
            take
        }
    }
`);

const GetSupplierQuestionnaireQuery = graphql(`
    query GetSupplierQuestionnaire($questionnaireId: ID!, $supplierId: ID!) {
        supplierQuestionnaire2(
            questionnaireId: $questionnaireId
            supplierId: $supplierId
        ) {
            id
            name
            createdAt
            deadline
            status
            stats {
                total
                answered
            }
            locale {
                availableLocales
                default
            }
        }
    }
`);

const GetSupplierQuestionnaireQuestionsQuery = graphql(`
    query GetSupplierQuestionnaireQuestions(
        $questionnaireId: ID!
        $supplierId: ID!
        $pagination: PaginationInput
    ) {
        questionnaire: supplierQuestionnaire2(
            questionnaireId: $questionnaireId
            supplierId: $supplierId
        ) {
            id
            questions(pagination: $pagination) {
                data {
                    id
                    nameMultiLocale {
                        en
                        fr
                    }
                    descriptionMultiLocale {
                        en
                        fr
                    }
                    type
                    metadata {
                        options {
                            name {
                                en
                                fr
                            }
                            proof
                        }
                        unit
                        dependsOn {
                            questionId
                            displayIfSelected
                        }
                    }
                    answer {
                        id
                        questionId
                        value
                        comment
                        status
                    }
                }
            }
        }
    }
`);

const UpsertSupplierQuestionnaireQuestionAnswerMutation = graphql(`
    mutation UpsertSupplierQuestionnaireQuestionAnswer(
        $input: AnswerUpsertInput!
    ) {
        upsertSupplierQuestionnaireAnswer(input: $input) {
            status
        }
    }
`);

const GetSuppliersQuery_deprecated = graphql(`
    query GetSuppliers_deprecated {
        suppliers_deprecated {
            id
            name
            tags {
                id
                name
            }
            users {
                id
                email
                firstName
                lastName
            }
            lastQuestionnaire {
                id
                status
                createdAt
                closedAt
                lastUpdatedAt
                lastReminderSentAt
                template {
                    id
                    name
                }
                stats {
                    total
                    answered
                    progress
                }
            }
        }
    }
`);

const GetSuppliersQuery = graphql(`
    query GetSuppliers(
        $filter: SupplierFilter
        $pagination: PaginationInput
        $orderBy: SupplierSort
    ) {
        suppliers(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            list: data {
                id
                name
                tags {
                    id
                    name
                    color
                }
                users {
                    id
                    email
                    firstName
                    lastName
                }
                lastQuestionnaire: lastQuestionnaire2 {
                    id
                    name
                    createdAt
                    updatedAt
                    deadline
                    status
                    stats {
                        total
                        answered
                    }
                }
            }
            count
        }
    }
`);

const GetSuppliersMinimalQuery = graphql(`
    query GetSuppliersMinimal(
        $filter: SupplierFilter
        $pagination: PaginationInput
        $orderBy: SupplierSort
    ) {
        suppliers(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
            list: data {
                id
                lastQuestionnaire: lastQuestionnaire2 {
                    id
                    status
                }
            }
        }
    }
`);

const GetTemplateQuestionsQuery = graphql(`
    query GetTemplateQuestions(
        $filter: SupplierTemplateQuestionFilterInput
        $pagination: PaginationInput
    ) {
        supplierTemplateQuestions(filter: $filter, pagination: $pagination) {
            list: data {
                id
                nameMultiLocale {
                    en
                    fr
                }
                descriptionMultiLocale {
                    en
                    fr
                }
                type
                tags {
                    id
                    name
                }
            }
            count
        }
    }
`);

const UpdateSupplierMutation = graphql(`
    mutation UpdateSupplier($input: UpdateSupplierInput!) {
        updateSupplier(input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const GetSupplierTemplateQuestionnaireQuery = graphql(`
    query GetSupplierTemplateQuestionnaire {
        supplierTemplate {
            id
            isGenerating
        }
    }
`);

const CreateSupplierTemplateQuestionMutation = graphql(`
    mutation CreateSupplierTemplateQuestion(
        $input: SupplierTemplateQuestionCreateInput!
    ) {
        createSupplierTemplateQuestion(input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const CreateSupplierTemplateQuestionFromFileMutation = graphql(`
    mutation CreateSupplierTemplateQuestionFromFile(
        $input: CreateSupplierTemplateQuestionsFromFileInput!
    ) {
        createSupplierTemplateQuestionsFromFile(input: $input) {
            status
            error {
                code
                message
            }
        }
    }
`);

const CreateSupplierTemplateMutation = graphql(`
    mutation CreateSupplierTemplate($input: QuestionnaireLocaleInput!) {
        createSupplierTemplate(input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const UpdateSupplierTemplateQuestionMutation = graphql(`
    mutation UpdateSupplierTemplateQuestion(
        $id: String!
        $input: SupplierTemplateQuestionUpdateInput!
    ) {
        updateSupplierTemplateQuestion(id: $id, input: $input) {
            status
            data {
                id
            }
        }
    }
`);

const DeleteSupplierTemplateQuestionMutation = graphql(`
    mutation DeleteSupplierTemplateQuestion($id: ID!) {
        deleteSupplierTemplateQuestion(id: $id) {
            status
        }
    }
`);

const SendSupplierQuestionnaireMutation = graphql(`
    mutation SendQuestionnaire($input: SendQuestionnaireInput!) {
        sendQuestionnaire(input: $input) {
            status
        }
    }
`);

const SendSupplierQuestionnairesMutation = graphql(`
    mutation SendQuestionnaires($input: SendQuestionnairesInput!) {
        sendQuestionnaires(input: $input) {
            status
        }
    }
`);

const SendSupplierQuestionnaireReminderMutation = graphql(`
    mutation SendSupplierQuestionnaireReminder($supplierId: ID!) {
        sendSupplierQuestionnaireReminder(supplierId: $supplierId) {
            status
        }
    }
`);

const CloseSupplierQuestionnaireMutation = graphql(`
    mutation CloseSupplierQuestionnaire($questionnaireId: ID!) {
        closeQuestionnaire(questionnaireId: $questionnaireId) {
            status
        }
    }
`);

const CloseSupplierQuestionnairesMutation = graphql(`
    mutation CloseSupplierQuestionnaires($questionnaireIds: [ID!]!) {
        closeQuestionnaires(questionnaireIds: $questionnaireIds) {
            status
        }
    }
`);

const CreateSupplierUsersMutation = graphql(`
    mutation CreateSupplierUsers(
        $supplierId: ID!
        $input: [CreateSupplierUserInput!]!
    ) {
        createSupplierUsers(supplierId: $supplierId, input: $input) {
            status
        }
    }
`);

export class GraphQLSupplierRepository {
    async getSupplier(id: string) {
        return graphqlClientKoyeb.request(
            GetSupplierQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuppliers(
        filter: SupplierFilter,
        pagination: PaginationInput,
        orderBy: SupplierSort,
    ) {
        return graphqlClientKoyeb.request(
            GetSuppliersQuery,
            { filter, pagination, orderBy },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuppliersMinimal(
        filter: SupplierFilter,
        pagination: PaginationInput,
        orderBy: SupplierSort,
    ) {
        return graphqlClientKoyeb.request(
            GetSuppliersMinimalQuery,
            { filter, pagination, orderBy },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSupplierQuestionnaireTemplates_deprecated() {
        return graphqlClientKoyeb.request(
            GetSupplierQuestionnaireTemplatesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSupplierTemplateQuestionnaire() {
        return graphqlClientKoyeb.request(
            GetSupplierTemplateQuestionnaireQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSupplierQuestionnaire(
        questionnaireId: string,
        supplierId: string,
    ) {
        return graphqlClientKoyeb.request(
            GetSupplierQuestionnaireQuery,
            { questionnaireId, supplierId },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSupplierQuestionnaireQuestions(
        questionnaireId: string,
        supplierId: string,
        pagination?: PaginationInput,
    ) {
        return graphqlClientKoyeb.request(
            GetSupplierQuestionnaireQuestionsQuery,
            { questionnaireId, supplierId, pagination },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSupplierQuestionnaires(
        supplierId: string,
        filter: QuestionnaireFilterInput,
        pagination: PaginationInput,
    ) {
        return graphqlClientKoyeb.request(
            GetSupplierQuestionnairesQuery,
            { supplierId, filter, pagination },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getTemplateQuestions(
        filter?: SupplierTemplateQuestionFilterInput,
        pagination?: PaginationInput,
    ) {
        return graphqlClientKoyeb.request(
            GetTemplateQuestionsQuery,
            { filter, pagination },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async getSuppliers_deprecated() {
        return graphqlClientKoyeb.request(
            GetSuppliersQuery_deprecated,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createSupplierTemplate(input: QuestionnaireLocaleInput) {
        return graphqlClientKoyeb.request(
            CreateSupplierTemplateMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateSupplier(input: UpdateSupplierInput) {
        return graphqlClientKoyeb.request(
            UpdateSupplierMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createSupplierTemplateQuestion(
        input: SupplierTemplateQuestionCreateInput,
    ) {
        return graphqlClientKoyeb.request(
            CreateSupplierTemplateQuestionMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createSupplierTemplateQuestionsFromFile(
        input: CreateSupplierTemplateQuestionsFromFileInput,
    ) {
        return graphqlClientKoyeb.request(
            CreateSupplierTemplateQuestionFromFileMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async updateSupplierTemplateQuestion(
        id: string,
        input: SupplierTemplateQuestionUpdateInput,
    ) {
        return graphqlClientKoyeb.request(
            UpdateSupplierTemplateQuestionMutation,
            { id, input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async deleteSupplierTemplateQuestion(id: string) {
        return graphqlClientKoyeb.request(
            DeleteSupplierTemplateQuestionMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async sendSupplierQuestionnaire(input: SendQuestionnaireInput) {
        return graphqlClientKoyeb.request(
            SendSupplierQuestionnaireMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async sendSupplierQuestionnaires(input: SendQuestionnairesInput) {
        return graphqlClientKoyeb.request(
            SendSupplierQuestionnairesMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async sendSupplierQuestionnaireReminder(supplierId: string) {
        return graphqlClientKoyeb.request(
            SendSupplierQuestionnaireReminderMutation,
            { supplierId },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async closeSupplierQuestionnaire(questionnaireId: string) {
        return graphqlClientKoyeb.request(
            CloseSupplierQuestionnaireMutation,
            { questionnaireId },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async closeSupplierQuestionnaires(questionnaireIds: string[]) {
        return graphqlClientKoyeb.request(
            CloseSupplierQuestionnairesMutation,
            { questionnaireIds },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async upsertSupplierQuestionnaireQuestionAnswer(input: AnswerUpsertInput) {
        return graphqlClientKoyeb.request(
            UpsertSupplierQuestionnaireQuestionAnswerMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    }

    async createSupplierUsers(
        supplierId: string,
        input: CreateSupplierUserInput[],
    ) {
        return graphqlClientKoyeb.request(
            CreateSupplierUsersMutation,
            { supplierId, input },
            await AccessHeadersHolder.getHeaders(),
        );
    }
}
