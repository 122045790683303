import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAnswerSectionTranslations } from "./i18n";

type AnswerSectionProps = {
    value: string;
};

export const AnswerSection = ({ value }: AnswerSectionProps) => {
    const { t, keys } = useAnswerSectionTranslations();
    return (
        <FlexRow className="border-b border-tertiary p-2">
            <FlexCol>
                <Text
                    variant="body-sm-bold"
                    color="secondary"
                    className="uppercase"
                >
                    {t(keys.answer)}
                </Text>
                <Text>{value}</Text>
            </FlexCol>
        </FlexRow>
    );
};
