import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "status-multi-select";

const keys = addTranslations(RESOURCE_NAME, {
    status: {
        en: "Status",
        fr: "Statut",
    },
    status_active: {
        en: "Status ({{count}})",
        fr: "Statut ({{count}})",
    },
    status_OPEN: {
        en: "Open",
        fr: "Ouvert",
    },
    status_CLOSED: {
        en: "Closed",
        fr: "Fermé",
    },
});

export function useStatusMultiSelectTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
