import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Text } from "@design-system/Typography/Text";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

import {
    useDeleteQuestions,
    useQuestionsUpdate,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { keys, RESOURCE_NAME } from "../../i18n";

const Actions = {
    ASSIGN_OWNER: "ASSIGN_OWNER",
    DELETE: "DELETE",
} as const;

type ActionType = (typeof Actions)[keyof typeof Actions];

export const ActionBar = ({
    questionnaireId,
    questionsIds,
    unset,
}: {
    questionnaireId: string;
    questionsIds: string[];
    unset: () => void;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [activeAction, setActiveAction] = useState<ActionType | undefined>(
        undefined,
    );
    const [ownerId, setOwnerId] = useState<string | null | undefined>(null);

    const [searchOwnerFilter, setSearchOwnerFilter] = useState<string>();
    const { updateQuestions, isPending } = useQuestionsUpdate(questionnaireId);
    const { deleteQuestions, isPending: isDeleting } =
        useDeleteQuestions(questionnaireId);

    const { setToastSuccess, setToastError } = useSetToast();

    const onActionValueChange = ([actionId]: string[]) => {
        if (!(actionId in Actions)) {
            return;
        }

        setActiveAction(actionId as ActionType);
    };

    const onOwnerApply = () => {
        updateQuestions(
            {
                ids: questionsIds,
                input: {
                    ownerId: ownerId,
                },
            },
            {
                onSuccess: () => {
                    setToastSuccess(
                        t(keys.question_table_action_assign_success),
                    );
                    unset();
                    setActiveAction(undefined);
                    setOwnerId(null);
                },
                onError: () => {
                    setToastError(t(keys.question_table_action_assign_error));
                },
            },
        );
    };

    const onDelete = () => {
        deleteQuestions(questionsIds, {
            onSuccess: () => {
                setToastSuccess(t(keys.question_table_action_delete_success));
                unset();
                setActiveAction(undefined);
            },
            onError: () => {
                setToastError(t(keys.question_table_action_delete_error));
            },
        });
    };

    return (
        <Floatbar
            unset={unset}
            text={t(keys.question_table_action_selection, {
                count: questionsIds.length,
            })}
            open={questionsIds.length > 0}
            primary={
                <Dropdown
                    selectType="single"
                    value={activeAction ? [activeAction] : undefined}
                    onValueChange={onActionValueChange}
                >
                    <Dropdown.Trigger asChild>
                        <Button variant="outlined" size="sm">
                            <Text variant="body-sm-bold">
                                {t(keys.question_table_action_select_action)}
                            </Text>
                            <Button.Icon name="angleDown" size="sm" />
                        </Button>
                    </Dropdown.Trigger>
                    <Dropdown.Content>
                        <Dropdown.SelectableItem
                            value={Actions.ASSIGN_OWNER}
                            className="text-beavrGreen"
                        >
                            <Dropdown.ItemIcon
                                name="userPlus"
                                size="sm"
                            ></Dropdown.ItemIcon>
                            <Dropdown.ItemText
                                variant="body-sm"
                                className="text-primary"
                            >
                                {t(keys.question_table_action_assign_owner, {
                                    count: questionsIds.length,
                                })}
                            </Dropdown.ItemText>
                        </Dropdown.SelectableItem>
                        <Dropdown.SelectableItem
                            value={Actions.DELETE}
                            className="text-beavrRed"
                        >
                            <Dropdown.ItemIcon name="trash" size="sm" />
                            <Dropdown.ItemText variant="body-sm">
                                {t(keys.question_table_action_delete, {
                                    count: questionsIds.length,
                                })}
                            </Dropdown.ItemText>
                        </Dropdown.SelectableItem>
                    </Dropdown.Content>
                </Dropdown>
            }
            secondary={<></>}
            actions={[
                {
                    id: Actions.ASSIGN_OWNER,
                    text: t(keys.question_table_action_selection, {
                        count: questionsIds.length,
                    }),
                    selector: (
                        <UserChipSelect
                            userId={ownerId}
                            setUserId={setOwnerId}
                            searchFilter={searchOwnerFilter}
                            onSearchFilterChange={setSearchOwnerFilter}
                            showUnsetByDefault={true}
                        />
                    ),
                    apply: (
                        <Button
                            size="sm"
                            onClick={onOwnerApply}
                            disabled={ownerId === null}
                            loading={isPending}
                        >
                            {t(keys.question_table_action_apply)}
                        </Button>
                    ),
                },
                {
                    id: Actions.DELETE,
                    text: t(keys.question_table_action_delete, {
                        count: questionsIds.length,
                    }),
                    apply: (
                        <Button
                            size="sm"
                            onClick={onDelete}
                            loading={isDeleting}
                        >
                            {t(keys.question_table_action_apply)}
                        </Button>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        />
    );
};
