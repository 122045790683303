import {
    StatusTagCircular,
    type TagStatus,
} from "@design-system/DataDisplay/StatusTagCircular";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, type FlexProps, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { type FC, type Ref } from "react";
import { MultiSelectSection } from "./MultiSelectSection";
import { ShowSectionsButtons } from "./ShowSectionsButtons";
import { SingleSelectSection } from "./SingleSelectSection";
import { useQuestionTranslations } from "./i18n";

const QuestionCardHeader: FC<FlexProps & { ref?: Ref<HTMLDivElement> }> = ({
    ref,
    ...props
}) => {
    return (
        <FlexRow
            justifyContent="between"
            alignItems="center"
            ref={ref}
            {...props}
        />
    );
};

const QuestionCardStatus: FC<
    FlexProps & { status: TagStatus } & { ref?: Ref<HTMLDivElement> }
> = ({ status, ref, ...props }) => {
    const { t, keys } = useQuestionTranslations();
    return (
        <FlexCol
            justifyContent="start"
            alignItems="center"
            ref={ref}
            {...props}
        >
            <Tooltip delayDuration={0}>
                <Tooltip.Trigger>
                    <StatusTagCircular
                        className="mt-1"
                        status={status}
                        size="lg"
                    />
                </Tooltip.Trigger>
                <Tooltip.Content>
                    {t(keys.status_tooltip, { status })}
                </Tooltip.Content>
            </Tooltip>
        </FlexCol>
    );
};

const QuestionCardContent: FC<FlexProps & { ref?: Ref<HTMLDivElement> }> = ({
    ref,
    ...props
}) => {
    return <FlexCol w="full" gap="4" pt="0.5" ref={ref} {...props} />;
};

const QuestionCardTitle: FC<TextProps & { ref?: Ref<HTMLDivElement> }> = ({
    ref,
    ...props
}) => {
    return <Text as="h3" variant="body-lg-bold" ref={ref} {...props} />;
};

const QuestionCardDescription: FC<
    TextProps & { ref?: Ref<HTMLDivElement> }
> = ({ ref, ...props }) => {
    return <Text mb="2" className="text-secondary" ref={ref} {...props} />;
};

const QuestionCardGuidance: FC<
    TextProps & {
        frameworkGuidance?: string;
        expertiseGuidance?: string;
        questionnaireName?: string;
    }
> = ({ frameworkGuidance, expertiseGuidance, questionnaireName }) => {
    const { t, keys } = useQuestionTranslations();

    const frameworkGuidanceLength = frameworkGuidance?.length ?? 0;
    const expertiseGuidanceLength = expertiseGuidance?.length ?? 0;
    const tooLongGuidance =
        frameworkGuidanceLength + expertiseGuidanceLength > 400;
    const shouldShowFrameworkGuidance =
        !tooLongGuidance || expertiseGuidanceLength === 0;

    return (
        <FlexCol className="text-sm w-full">
            <FlexCol gap="4">
                {expertiseGuidanceLength > 0 && (
                    <FlexCol gap="1">
                        <Text variant="body-sm-bold">
                            {t(keys.expertise_guidance)}
                        </Text>
                        <Text variant="body-sm" className="text-secondary">
                            {expertiseGuidance?.slice(0, 400)}
                            {tooLongGuidance && !shouldShowFrameworkGuidance
                                ? "..."
                                : ""}
                        </Text>
                    </FlexCol>
                )}
                {shouldShowFrameworkGuidance && frameworkGuidanceLength > 0 && (
                    <FlexCol gap="1">
                        <Text variant="body-sm-bold">
                            {t(keys.framework_guidance, {
                                frameworkName: questionnaireName,
                            })}
                        </Text>
                        <Text variant="body-sm" className="text-secondary">
                            {frameworkGuidance?.slice(0, 400)}
                            {tooLongGuidance ? "..." : ""}
                        </Text>
                    </FlexCol>
                )}
            </FlexCol>
            {tooLongGuidance && (
                <Modal>
                    <Modal.Trigger asChild>
                        <FlexRow justifyContent="end">
                            <Button
                                variant="text"
                                size="sm"
                                className="text-beavrGreen"
                            >
                                {t(keys.view_full_guidance)}
                                <Button.Icon name="help" />
                            </Button>
                        </FlexRow>
                    </Modal.Trigger>
                    <Modal.Overlay scrollable centerContent>
                        <Modal.Content>
                            <Modal.Header withClose>
                                <Modal.Title>
                                    {t(keys.full_guidance_title)}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-6 max-h-[600px] overflow-y-auto">
                                <FlexCol gap="4">
                                    {expertiseGuidanceLength > 0 && (
                                        <FlexCol gap="1">
                                            <Text variant="body-sm-bold">
                                                {t(keys.expertise_guidance)}
                                            </Text>
                                            <Text
                                                variant="body-sm"
                                                className="text-secondary"
                                            >
                                                {expertiseGuidance}
                                            </Text>
                                        </FlexCol>
                                    )}
                                    {frameworkGuidanceLength > 0 && (
                                        <FlexCol gap="1">
                                            <Text variant="body-sm-bold">
                                                {t(keys.framework_guidance, {
                                                    frameworkName:
                                                        questionnaireName,
                                                })}
                                            </Text>
                                            <Text
                                                variant="body-sm"
                                                className="text-secondary"
                                            >
                                                {frameworkGuidance}
                                            </Text>
                                        </FlexCol>
                                    )}
                                </FlexCol>
                            </Modal.Body>
                        </Modal.Content>
                    </Modal.Overlay>
                </Modal>
            )}
        </FlexCol>
    );
};

type QuestionCardComponent = FC<FlexProps & { ref?: Ref<HTMLDivElement> }> & {
    Header: typeof QuestionCardHeader;
    StatusCol: typeof QuestionCardStatus;
    Content: typeof QuestionCardContent;
    Title: typeof QuestionCardTitle;
    Description: typeof QuestionCardDescription;
    SingleSelect: typeof SingleSelectSection;
    MultiSelect: typeof MultiSelectSection;
    ShowSectionsButtons: typeof ShowSectionsButtons;
    QuestionCardGuidance: typeof QuestionCardGuidance;
};

export const QuestionCard: QuestionCardComponent = ({ ref, ...props }) => {
    return (
        <FlexCol
            className="bg-primary border border-tertiary"
            gap="4"
            p="4"
            pb="5"
            br="lg"
            w="full"
            ref={ref}
            {...props}
        />
    );
};

QuestionCard.StatusCol = QuestionCardStatus;
QuestionCard.Header = QuestionCardHeader;
QuestionCard.Content = QuestionCardContent;
QuestionCard.Title = QuestionCardTitle;
QuestionCard.Description = QuestionCardDescription;
QuestionCard.SingleSelect = SingleSelectSection;
QuestionCard.MultiSelect = MultiSelectSection;
QuestionCard.ShowSectionsButtons = ShowSectionsButtons;
QuestionCard.QuestionCardGuidance = QuestionCardGuidance;

export {
    sections as questionCardShowBtnsSections,
    type SectionType as QuestionCardShowBtnsSectionType,
} from "./ShowSectionsButtons";
