import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { FC } from "react";
import { useEditOrDeleteDropdownTranslations } from "./i18n";

export const DeleteQuestionnaireModal: FC<{
    open?: boolean;
    setOpen?: (open: boolean) => void;
    onDeleteApply: () => void;
    isPendingDelete: boolean;
}> = ({ open, setOpen, onDeleteApply, isPendingDelete }) => {
    const { t, keys } = useEditOrDeleteDropdownTranslations();

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header withClose>
                        <Modal.Title>
                            {t(keys.delete_questionnaire_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Text>
                            {t(keys.delete_questionnaire_modal_description)}
                        </Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(
                                keys.delete_questionnaire_modal_cancel_button_label,
                            )}
                        </Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            onClick={onDeleteApply}
                            variant="flatDanger"
                            loading={isPendingDelete}
                        >
                            {t(
                                keys.delete_questionnaire_modal_confirm_button_label,
                            )}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
