import { DocumentExpirationStatus } from "@generated/client/graphql";
import { PaginationState } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type DocPageFilterState = {
    irrelevantSearchFilter: string | undefined;
    statusFilter?: DocumentExpirationStatus[] | undefined;
    pagination: PaginationState;
};

const defaultState: DocPageFilterState = {
    irrelevantSearchFilter: undefined,
    pagination: { pageIndex: 0, pageSize: 10 },
};
export const DocumentPageFilterStateAtom = atomWithStorage<DocPageFilterState>(
    "documentPageFilterStateAtom",
    defaultState,
);

export const useDocumentPageState = () => {
    const [state, setState] = useAtom(DocumentPageFilterStateAtom);
    const setPartialState = (partialState: Partial<DocPageFilterState>) => {
        setState((state) => {
            const newState = { ...state, ...partialState };
            if (partialState.irrelevantSearchFilter) {
                newState.pagination.pageIndex = 0;
            }
            return newState;
        });
    };

    const resetDefaultState = () => setState(defaultState);

    return { state, setState: setPartialState, resetDefaultState };
};
