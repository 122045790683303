import { graphql } from "@generated/client";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GenerateQuickAnswerQuery = graphql(`
    query GenerateQuickAnswer($question: String!) {
        generateQuickAnswer(question: $question) {
            documentCitations {
                documentId
                documentFileName
                text
            }
            indicatorCitations {
                indicatorId
            }
            motivation
            value
            comment
            success
        }
    }
`);

export class GraphQLQuickAnswerRepository {
    generateQuickAnswer = async (question: string) => {
        return graphqlClientKoyeb.request(
            GenerateQuickAnswerQuery,
            { question },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
