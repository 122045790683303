import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { QuestionType } from "@generated/client/graphql";
import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { isQuestionType } from "../../../../services";
import { keys, RESOURCE_NAME } from "../../i18n";

export const QuestionTypeSelect: FC<
    PropsWithChildren<{
        defaultValue?: QuestionType;
        value?: QuestionType;
        onChange: (value?: QuestionType) => void;
        closeOnValueChange?: boolean;
    }>
> = ({ onChange, children, closeOnValueChange = false, ...props }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const defaultValue = props.defaultValue ? [props.defaultValue] : [];
    const value = props.value ? [props.value] : [];

    const handleChange = ([value]: string[]) => {
        if (!value) onChange();

        if (!isQuestionType(value)) throw new Error("Invalid question type");

        onChange(value);
    };

    return (
        <Dropdown
            closeOnValueChange={closeOnValueChange}
            defaultValue={defaultValue}
            modal
            onValueChange={handleChange}
            selectType="single"
            value={value}
        >
            <Dropdown.Trigger
                asChild
                label={t(keys.create_questions_modal_question_type_dd_label)}
            >
                {children}
            </Dropdown.Trigger>
            <Dropdown.Content align="start" hasPortal>
                {Object.values(QuestionType).map((questionType) => (
                    <Dropdown.SelectableItem
                        key={questionType}
                        value={questionType}
                    >
                        <Dropdown.SelectedIndicator />
                        <Dropdown.ItemText>
                            {t(getQuestionTypeLabel(questionType))}
                        </Dropdown.ItemText>
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};

export function getQuestionTypeLabel(questionType: QuestionType) {
    const suffix = questionType.toLowerCase() as Lowercase<QuestionType>;
    return keys[`create_questions_modal_question_type_dd_item_label_${suffix}`];
}
