import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { AiChat } from "./components/AiChat";
import { useQuickAnswerModalTranslations } from "./i18n";

export const QuickAnswerModal = () => {
    const { t, keys } = useQuickAnswerModalTranslations();

    return (
        <Modal>
            <Modal.Trigger asChild>
                <Button variant="tonal" size="sm" className="mt-2">
                    {t(keys.callToAction)}
                </Button>
            </Modal.Trigger>

            <Modal.Overlay centerContent className="py-5">
                <Modal.Content className="w-[648px] overflow-hidden">
                    <Modal.Header className="py-4 px-5" withClose>
                        <FlexRow justifyContent="center" w="full" gap="2">
                            <Text
                                variant="body-bold"
                                className="text-accent-1-300"
                            >
                                {t(keys.title)}
                            </Text>
                            <Icon
                                name="quote"
                                size="md"
                                className="text-accent-1-300"
                            />
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body className="p-0 flex flex-row border-t border-tertiary min-h-[360px]">
                        <AiChat />
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
