import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "sup-questionnaire";

const keys = addTranslations(RESOURCE_NAME, {
    banner_questionnaire_closed: {
        en: "This questionnaire is closed, you cannot edit it anymore",
        fr: "Ce questionnaire est fermé, vous ne pouvez plus l'éditer",
    },
});

export function useSupplierQuestionnaireTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}