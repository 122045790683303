import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { AiThreadMessageRole } from "@generated/client/graphql";
import { type FC } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ThreadMessage } from "../types";
import { MessageLink } from "./MessageLink";

const AiResponse: FC<{ message: string; appendBall?: boolean }> = ({
    message,
    appendBall,
}) => {
    const displayMessage = appendBall ? message.concat(" ", "⚫") : message;
    return (
        <ReactMarkdown
            className="flex flex-col gap-4 text-base font-regular text-left w-full max-h-fit text-neutral-700"
            remarkPlugins={[remarkGfm]}
            components={{
                table: ({ children }) => (
                    <table className="w-full border rounded-md">
                        {children}
                    </table>
                ),
                th: ({ children }) => (
                    <th className="p-2 text-left font-bold border-b">
                        {children}
                    </th>
                ),
                td: ({ children }) => (
                    <td className="p-2 text-left border-b">{children}</td>
                ),
                p: ({ children }) => <Text variant="body">{children}</Text>,
                strong: ({ children }) => (
                    <Text as="span" variant="body-bold">
                        {children}
                    </Text>
                ),
                ol: ({ children }) => (
                    <ol className="pl-5 list-decimal list-outside">
                        {children}
                    </ol>
                ),
                ul: ({ children }) => (
                    <ul className="pl-5 list-disc list-outside">{children}</ul>
                ),
                li: ({ children }) => (
                    <li className="text-base list-item mb-2 last:mb-0">
                        {children}
                    </li>
                ),
                a: ({ href }) => {
                    if (!href || href.startsWith("file-")) return null;
                    return <MessageLink href={href} />;
                },
                // we don't trust images, so we don't render them
                img: ({ alt }) => <Box>{alt} - img</Box>,
                code: (codeProps) => {
                    const position = codeProps.node?.position;
                    const startLine = position?.start?.line;
                    const endLine = position?.end?.line;
                    const isInline = startLine === endLine;

                    return isInline ? (
                        <code className="inline-block">
                            {codeProps.children}
                        </code>
                    ) : (
                        <Box className="w-full border border-secondary px-1 py-0.5 overflow-scroll">
                            <code>{codeProps.children}</code>
                        </Box>
                    );
                },
            }}
        >
            {displayMessage}
        </ReactMarkdown>
    );
};

export const MessageBlock: FC<{
    message: ThreadMessage;
    scrollIntoView: boolean;
    appendBall?: boolean;
}> = ({ message, appendBall }) => {
    return (
        <FlexCol>
            <FlexRow
                {...(message.role === AiThreadMessageRole.User
                    ? { justifyContent: "end" }
                    : {})}
            >
                {message.role === AiThreadMessageRole.User && (
                    <FlexRow
                        px="3"
                        py="3"
                        alignItems="center"
                        justifyContent="end"
                        className="rounded-b-[16px] rounded-tl-[16px] border border-tertiary bg-primary shadow-sm max-w-[250px]"
                    >
                        <Text variant="body">{message.content}</Text>
                    </FlexRow>
                )}
                {message.role === AiThreadMessageRole.Assistant && (
                    <AiResponse
                        message={message.content}
                        appendBall={appendBall}
                    />
                )}
            </FlexRow>
        </FlexCol>
    );
};
