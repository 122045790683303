import {
    AnswerStatus,
    AnswerValueInput,
    type GetQuestionnaireOverviewDataQuery,
    type GetQuestionQuery,
    type QuestionCreateInput,
    type QuestionMetadataInput,
    QuestionType,
    type QuestionUpdateInput,
} from "@generated/client/graphql";

import { SupportedLocales } from "@app/shared/utils/locales";
import { getRemainingWeeks } from "@app/usecases/DashboardUseCases";
import { isEqual } from "lodash";
import { type UpsertQuestionInput } from "./components/OverviewTab/types";
import { QuestionnaireOverviewData } from "./types";

export function computeQuestionInput(
    question?: GetQuestionQuery["question"],
): UpsertQuestionInput {
    return {
        nameMultiLocale: {
            en: question?.nameMultiLocale?.en ?? "",
            fr: question?.nameMultiLocale?.fr ?? "",
        },
        descriptionMultiLocale: {
            en: question?.descriptionMultiLocale?.en ?? "",
            fr: question?.descriptionMultiLocale?.fr ?? "",
        },
        type: question?.type,
        unit: question?.metadata?.unit ?? undefined,
        options:
            question?.metadata?.options?.map((o) => ({
                en: o?.name?.en ?? "",
                fr: o?.name?.fr ?? "",
            })) ?? [],
        dependsOn: {
            questionId: question?.metadata?.dependsOn?.questionId,
            displayIfSelected: question?.metadata?.dependsOn?.displayIfSelected,
        },
    };
}

export function computeMetadata(question: {
    type: QuestionType;
    options?: Record<string, string>[];
    unit?: string | null;
    parentQuestionId?: string;
    displayIfSelected?: number[];
}): QuestionMetadataInput | null {
    let metadata: QuestionMetadataInput | null = null;
    if (isSelectType(question.type) && question?.options) {
        metadata = {
            options: question.options?.map((o) => ({
                name: { en: o["en"], fr: o["fr"] },
                proof: "UNKNOWN",
            })),
        };
    }

    if (question.type === QuestionType.Value) {
        metadata = {
            ...(metadata ?? {}),
            unit: question.unit,
        };
    }

    if (question.parentQuestionId) {
        metadata = {
            ...(metadata ?? {}),
            dependsOn: {
                questionId: question.parentQuestionId,
                displayIfSelected: question.displayIfSelected ?? [],
            },
        };
    }
    return metadata;
}

export function isQuestionCreateInput(
    question: Partial<QuestionCreateInput>,
): question is QuestionCreateInput {
    return (
        typeof question.name === "string" &&
        typeof question.type !== "undefined"
    );
}

export function isQuestionType(value: string): value is QuestionType {
    return Object.values(QuestionType).includes(value as QuestionType);
}

export function canSubmitQuestion(
    input: UpsertQuestionInput,
    locale: SupportedLocales,
    question?: Question,
) {
    const isUpdateScenario = question !== undefined;
    if (isUpdateScenario) {
        const typeChanged = input.type !== question?.type;
        const descriptionChanged = !isEqual(
            input.descriptionMultiLocale,
            question?.descriptionMultiLocale ?? { en: "", fr: "" },
        );

        const nameChanged = !isEqual(
            input.nameMultiLocale,
            question?.nameMultiLocale,
        );
        const unitChanged =
            input.type === QuestionType.Value &&
            input.unit !== question?.metadata?.unit;

        const shouldCheckOptions =
            input.type === QuestionType.SingleSelect ||
            input.type === QuestionType.MultiSelect;

        const optionsChanged = shouldCheckOptions
            ? input?.options?.length > 0 &&
              !isEqual(
                  input.options?.map((o) => o?.[locale]),
                  question?.metadata?.options?.map((o) => o.name?.[locale]) ??
                      [],
              )
            : false;

        const dependsOnQuestionIdChange = !isEqual(
            input.dependsOn?.questionId,
            question?.metadata?.dependsOn?.questionId,
        );
        const dependsOnQuestionAnswersChange = !isEqual(
            input.dependsOn?.displayIfSelected,
            question?.metadata?.dependsOn?.displayIfSelected,
        );

        return (
            typeChanged ||
            descriptionChanged ||
            nameChanged ||
            unitChanged ||
            optionsChanged ||
            dependsOnQuestionIdChange ||
            dependsOnQuestionAnswersChange
        );
    } else {
        const hasType = input.type !== undefined;
        const hasName = input.nameMultiLocale.en || input.nameMultiLocale.fr;

        const shouldCheckOptions =
            input.type === QuestionType.SingleSelect ||
            input.type === QuestionType.MultiSelect;
        const optionsCheck = shouldCheckOptions
            ? input.options?.length > 0 &&
              input.options.some((o) => o.en || o.fr)
            : true;

        return hasType && hasName && optionsCheck;
    }
}

export function isSelectType(questionType?: QuestionType) {
    return (
        !!questionType &&
        [QuestionType.SingleSelect, QuestionType.MultiSelect].includes(
            questionType,
        )
    );
}
type Question = Exclude<GetQuestionQuery["question"], null>;

export function shouldDisplayQuestion(
    question: Question,
    parentQuestion?: Question,
) {
    if (parentQuestion == null) {
        return true;
    }
    let shouldDisplay = true;
    const isDependent = question?.metadata?.dependsOn?.questionId != null;
    if (isDependent) {
        const options =
            parentQuestion?.answer?.value?.options?.filter((index: number) =>
                question?.metadata?.dependsOn?.displayIfSelected?.includes(
                    index,
                ),
            ) ?? [];
        shouldDisplay = options.length > 0;
    }
    return shouldDisplay;
}

export const Status = {
    Validated: "validated",
    InProgress: "inProgress",
    Todo: "todo",
} as const;

export const computeStatus = ({
    status,
    value,
}: {
    status: AnswerStatus;
    value?: AnswerValueInput;
}) => {
    if (status === AnswerStatus.Validated) {
        return Status.Validated;
    }

    if (value?.text || value?.options?.length || value?.table || value?.num) {
        return Status.InProgress;
    }

    return Status.Todo;
};

export function optimisticUpdateQuestionInMany(
    { id, input }: { id: string; input: QuestionUpdateInput },
    previousQuery: GetQuestionnaireOverviewDataQuery,
) {
    const questions = previousQuery.questions.map((question) => {
        if (id === question.id)
            return {
                ...question,
                ...input,
            } as GetQuestionnaireOverviewDataQuery["questions"][number];
        return question;
    });
    return {
        ...previousQuery,
        questions,
    };
}

export function optimisticUpdateQuestion(
    { input }: { input: QuestionUpdateInput },
    previousQuery: GetQuestionQuery,
) {
    const { name, type, ...updatedQuestion } = input;

    return {
        ...previousQuery,
        question: {
            ...previousQuery.question,
            ...updatedQuestion,
            ...(name ? { name } : {}),
            ...(type ? { type } : {}),
        },
    };
}

export function getQuestionnaireStats<
    T extends
        | {
              deadline?: Date | null;
              questions: {
                  ownerId?: string | null;
                  answer?: {
                      status?: AnswerStatus;
                  } | null;
              }[];
          }
        | undefined,
>(questionnaire: T) {
    if (!questionnaire) return;

    const remainingWeeks = questionnaire.deadline
        ? getRemainingWeeks(new Date(questionnaire.deadline))
        : undefined;

    const validatedQuestionsCount = questionnaire?.questions?.filter(
        (question) => question.answer?.status === AnswerStatus.Validated,
    ).length;
    const validatedQuestionsPercentage =
        questionnaire?.questions?.length > 0
            ? Math.round(
                  (validatedQuestionsCount / questionnaire?.questions?.length) *
                      100,
              )
            : 0;

    return {
        totalQuestions: questionnaire?.questions?.length,
        validatedQuestions: {
            count: validatedQuestionsCount,
            percentage: validatedQuestionsPercentage,
        },
        nonValidatedQuestions: {
            count: questionnaire?.questions?.length - validatedQuestionsCount,
            percentage: 100 - validatedQuestionsPercentage,
        },
        deadline: remainingWeeks ? { remainingWeeks } : undefined,
    };
}

export function isQuestionGenerating(
    question: QuestionnaireOverviewData[number],
) {
    const acceptableTimeToGenerate = 9000 * 10; // 90 seconds

    return (
        !!question?.answer?.aiSuggestion?.isGenerating &&
        (question?.answer?.lastGeneratedAt == null ||
            acceptableTimeToGenerate >
                Date.now() -
                    new Date(question?.answer?.lastGeneratedAt).getTime())
    );
}
