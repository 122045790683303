import { currentUserRoleAtom } from "@app/store/userStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue, useSetAtom } from "jotai";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AiChat } from "./components/AiChat";
import { ThreadsHistory } from "./components/ThreadsHistory";
import { isAiChatModalOpenAtom } from "./ctx";
import { useThreads } from "./data";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";

export const AskAIModal = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    const { org_uname } = useParams();

    const { isBeavrAdmin, isAdmin } = useAtomValue(currentUserRoleAtom);
    const isAtLeastAdmin = isAdmin || isBeavrAdmin;

    const isEnabledOnTenant =
        useFeatureFlagEnabled("ask-ai") ?? org_uname === "airbus";

    const skipGetThreadsQuery = !isEnabledOnTenant || !isAtLeastAdmin;
    const { threads } = useThreads({ skip: skipGetThreadsQuery });

    const show = isAtLeastAdmin && isEnabledOnTenant;

    const setIsOpen = useSetAtom(isAiChatModalOpenAtom);

    const handleEscapeKeyDown = () => {
        setIsOpen(false);
    };

    return show ? (
        <Modal onOpenChange={setIsOpen}>
            <Modal.Trigger asChild>
                <Button size="sm">
                    <FlexRow
                        p="1.5"
                        gap="2"
                        alignItems="center"
                        className="h-[26px]"
                    >
                        <Text variant="body-bold">Ask AI</Text>
                        <Button.Icon name="magicWand" />
                    </FlexRow>
                </Button>
            </Modal.Trigger>
            <Modal.Overlay centerContent className="py-5">
                <Modal.Content
                    className="w-[860px]"
                    onEscapeKeyDown={handleEscapeKeyDown}
                >
                    <Modal.Header className="py-4 px-5" withClose>
                        <FlexRow justifyContent="center" w="full" gap="2">
                            <Text variant="body-bold" color="brand">
                                {t(keys.ask_ai)}
                            </Text>
                            <Icon
                                name="stars"
                                className="text-beavrGreen"
                                size="md"
                            />
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body className="p-0 h-[550px] flex flex-row border-t border-tertiary">
                        {threads?.length ? <ThreadsHistory /> : null}
                        <AiChat />
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    ) : null;
};
