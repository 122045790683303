import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { type SupplierUser } from "../../types";
import { useUserCardTranslations } from "./i18n";

export const UserCard: FC<{ user: SupplierUser }> = ({
    user: { avatar, firstName, lastName, department, email },
}) => {
    const { t, keys } = useUserCardTranslations();

    return (
        <FlexCol p="4" br="xl" gap="2" className="border border-secondary">
            <Avatar
                variant="circle"
                imageUrl={avatar?.signedUrl}
                letter={firstName[0]}
                size="md"
                className="h-7 w-7"
            />
            <Ellipsis asChild hasTooltip>
                <Text variant="body-lg-bold">{`${firstName} ${lastName}`}</Text>
            </Ellipsis>
            <Text variant="body" color="secondary" className="uppercase">
                {department || t(keys.no_department)}
            </Text>
            <FlexRow gap="1" alignItems="center">
                <Icon name="email" />
                <Ellipsis asChild hasTooltip>
                    <Text variant="body-bold">{email}</Text>
                </Ellipsis>
            </FlexRow>
        </FlexCol>
    );
};
