import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { FlexRow } from "@design-system/Layout/Flex";
import { type ColumnDef } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../../i18n";

export const groupsCol: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "groups",
    meta: {
        colWidth: "150px",
        cellClassName: "min-w-[180px] max-w-[180px]",
        headerClassName: "min-w-[180px] max-w-[180px]",
    },
    accessorKey: "group.name",
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.table_header_groups);
    },
    cell: ({ row }) => {
        const { name = "" } = row.original.group ?? {};

        return name ? (
            <FlexRow
                alignItems="center"
                justifyContent="center"
                br="xl"
                p="0.5"
                className={
                    "border border-secondary w-fit max-w-[150px] max-h-[26px]"
                }
            >
                <Ellipsis asChild hasTooltip>
                    <Text
                        variant="body-sm"
                        color="primary"
                        px="1"
                        className="capitalize"
                    >
                        {name}
                    </Text>
                </Ellipsis>
            </FlexRow>
        ) : null;
    },
};
