import { GaugeCircular } from "@app/components/Progress/ProgressCircular/GaugeCircular";
import FrameworkSection from "@app/screens/Frameworks/framework/components/Section";
import { UserAssign } from "@app/shared/components/UserAssign";
import {
    useConditionalScrollTo,
    useOnScrollIntoView,
} from "@app/shared/utils/scroll";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { RefObject, useCallback, type FC } from "react";
import { useTranslation } from "react-i18next";
import { computeDatapointStats } from "../../../services";
import {
    activeDatapointIdAtom,
    activeDisclosureRequirementIdAtom,
    lastUrlUpdateByClickTimestampAtom,
    openMaterialityModalAtom,
    setScrollAnchorAtom,
} from "../../ctx";
import { useUpdateDisclosureRequirementOwner } from "../../data";
import { type PillarDisclosureRequirement } from "../../types";
import { DatapointForm } from "../DatapointForm";
import { intervalToDuration } from "date-fns";

type DisclosureRequirementProps = {
    disclosureRequirement: PillarDisclosureRequirement;
    pillarBodyContainerRef?: RefObject<HTMLElement | null>;
};

export const DisclosureRequirement: FC<DisclosureRequirementProps> = ({
    disclosureRequirement: {
        id,
        cmsId,
        datapoints,
        name,
        ownerIds,
        materiality: { status, comment },
    },
    pillarBodyContainerRef,
}) => {
    const { t } = useTranslation("FrameworkDatapoint");

    const selectedDisclosureRequirementId = useAtomValue(
        activeDisclosureRequirementIdAtom,
    );

    const selectedDatapointId = useAtomValue(activeDatapointIdAtom);

    const [lastUrlUpdateByClickTimestamp, setLastUrlUpdateByClickTimestamp] =
        useAtom(lastUrlUpdateByClickTimestampAtom);

    const { notMaterial, total, validated } = computeDatapointStats(datapoints);
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);

    const handleMaterialityModalSelect = () => {
        openMaterialityModal(status, comment || "", undefined, id, name);
    };
    const done = validated + notMaterial;

    const { mutate: handleSelect } = useUpdateDisclosureRequirementOwner(id);

    const scrollToDisclosureRequirementOnSelection = useConditionalScrollTo(
        typeof cmsId === "string" &&
            cmsId === selectedDisclosureRequirementId &&
            !selectedDatapointId &&
            !!lastUrlUpdateByClickTimestamp,
    );

    const setNavigationAtom = useSetAtom(setScrollAnchorAtom);

    const scrollIntoViewCb = useCallback(() => {
        if (
            lastUrlUpdateByClickTimestamp &&
            (selectedDisclosureRequirementId === cmsId ||
                !selectedDisclosureRequirementId)
        ) {
            setLastUrlUpdateByClickTimestamp(undefined);
        }
        if (
            (!lastUrlUpdateByClickTimestamp ||
                (intervalToDuration({
                    start: lastUrlUpdateByClickTimestamp,
                    end: new Date(),
                }).seconds ?? 0) >= 3) &&
            selectedDisclosureRequirementId !== cmsId
        ) {
            setNavigationAtom({
                drId: cmsId,
            });
        }
    }, [lastUrlUpdateByClickTimestamp, selectedDisclosureRequirementId, cmsId]);

    const scrollIntoViewRef = useOnScrollIntoView(
        scrollIntoViewCb,
        pillarBodyContainerRef?.current,
    );

    const handleRefs = (el: HTMLElement | null) => {
        scrollToDisclosureRequirementOnSelection(el);
        scrollIntoViewRef.current = el;
    };

    return (
        <FrameworkSection.Block className="w-full">
            <FrameworkSection.BlockHeader id={cmsId} ref={handleRefs}>
                <FlexRow
                    gap="7"
                    py="2"
                    pl="2"
                    justifyContent="between"
                    alignItems="center"
                >
                    <Text variant="header2">{name}</Text>
                    <FlexRow
                        gap="6"
                        justifyContent="between"
                        alignItems="center"
                    >
                        <UserAssign
                            enableNone
                            selectedUsers={ownerIds}
                            onSelect={handleSelect}
                            selectMode="single"
                        />
                        <FlexRow
                            gap="2.5"
                            justifyContent="between"
                            alignItems="center"
                            minW="fit"
                        >
                            <Text variant="body">
                                {done} / {total}
                            </Text>
                            <GaugeCircular
                                size={20}
                                progress={(done / total) * 100}
                            />
                        </FlexRow>
                        <DropdownMenu modal={false}>
                            <DropdownMenu.Trigger>
                                <FlexCol
                                    className="hover:bg-neutral-150 active:bg-neutral-150 h-6 w-6"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon name="more" />
                                </FlexCol>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content align="end">
                                <DropdownMenu.Item
                                    onSelect={handleMaterialityModalSelect}
                                >
                                    <DropdownMenu.ItemIcon
                                        name="eyeOff"
                                        className="text-black"
                                    />
                                    <FlexRow alignItems="center">
                                        <Text variant="body" color="dark">
                                            {t("manage_materiality")}
                                        </Text>
                                    </FlexRow>
                                </DropdownMenu.Item>
                            </DropdownMenu.Content>
                        </DropdownMenu>
                    </FlexRow>
                </FlexRow>
            </FrameworkSection.BlockHeader>
            <FrameworkSection.BlockBody className="w-full">
                {datapoints.map((datapoint, idx) => (
                    <DatapointForm
                        datapoint={datapoint}
                        key={idx}
                        pillarBodyContainerRef={pillarBodyContainerRef}
                    />
                ))}
            </FrameworkSection.BlockBody>
        </FrameworkSection.Block>
    );
};
