import { validateEmail } from "@app/utils/emailRegex";
import { type CreateSupplierUserInput } from "./types";

export const getSubmitUsersError = (
    users: Omit<CreateSupplierUserInput, "locale">[],
): "invalidEmail" | "incomplete" | null => {
    if (users.some((user) => !validateEmail(user.email))) {
        return "invalidEmail";
    }

    if (
        users.some(
            (user) =>
                !user.email ||
                !user.firstName ||
                !user.lastName ||
                !user.department,
        )
    ) {
        return "incomplete";
    }

    return null;
};
