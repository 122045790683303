import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "answer-section";

const keys = addTranslations(RESOURCE_NAME, {
    answer: {
        en: "Answer",
        fr: "Réponse",
    },
});

export function useAnswerSectionTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
