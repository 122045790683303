import i18next, { ResourceKey, ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
    resources: {},
    lng: "en",
});

interface Item {
    [key: string]: LanguageItem;
}
interface LanguageItem {
    [key: string]: ResourceKey;
}

export const addTranslations = <T extends Item>(
    namespace: string,
    items: T,
) => {
    const resources: Map<string, ResourceLanguage> = new Map();
    type ResourceKeys = keyof T;

    Object.entries(items).forEach(([key, item]) => {
        Object.entries(item).forEach(([locale, resource]) => {
            const existingResource = resources.get(locale);
            if (existingResource) {
                existingResource[key] = resource;
            } else {
                resources.set(locale, { [key]: resource });
            }
        });
    });

    resources.forEach((resource, locale) => {
        i18next.addResourceBundle(locale, namespace, resource);
    });

    return Object.fromEntries(Object.keys(items).map((k) => [k, k])) as Record<
        ResourceKeys,
        ResourceKeys
    >;
};

export default i18next;
