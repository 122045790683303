import {
    ArrayParam,
    StringParam,
    useQueryParam,
    useQueryParams,
    withDefault,
} from "use-query-params";

type Filters = {
    themeId?: string;
    subthemeIds?: string[];
};

export function useFilters() {
    const [search, setSearch] = useQueryParam("search", StringParam);
    const [searchIrrelevant, setSearchIrrelevant] = useQueryParam(
        "searchIrrelevant",
        StringParam,
    );

    const [query, setQuery] = useQueryParams(
        {
            themeId: withDefault(StringParam, undefined),
            subthemeIds: withDefault(ArrayParam, undefined),
        },
        {
            removeDefaultsFromUrl: true,
        },
    );

    const setFilters = (newFilters: Partial<Filters>) => {
        setQuery(newFilters);
    };

    const clearFilters = () => {
        setQuery({ themeId: undefined, subthemeIds: undefined });
    };

    // prevent subthemeIds to be array of null
    const safeFilters: Filters = {
        themeId: query.themeId || undefined,
        subthemeIds: query.subthemeIds?.filter(
            (id): id is string => id !== null,
        ),
    };

    return {
        filters: safeFilters,
        search: search || undefined,
        setSearch,
        searchIrrelevant: searchIrrelevant || undefined,
        setSearchIrrelevant,
        setFilters,
        clearFilters,
    };
}
