import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { type FC } from "react";

import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Text } from "@design-system/Typography/Text";
import { useQuestionTranslations } from "./i18n";

export const sections = ["ai_suggestion", "upload", "comment"] as const;
export type SectionType = (typeof sections)[number];

export const ShowSectionsButtons: FC<{
    sectionBtns: SectionType[];
    onSectionBtnClick: (sectionBtn: SectionType) => void;
    disabled?: boolean;
}> = ({ sectionBtns, onSectionBtnClick, disabled = false }) => {
    const { t, keys } = useQuestionTranslations();
    if (!sectionBtns.length) return null;

    const handleSectionBtnClick = (sectionBtn: SectionType) => () => {
        onSectionBtnClick(sectionBtn);
    };

    const icons = {
        ai_suggestion: "stars",
        upload: "document",
        comment: "commentPlus",
    } as const;

    const isAiSuggestionBtn = (sectionBtn: SectionType) =>
        sectionBtn === "ai_suggestion";

    return (
        <FlexCol gap="4">
            <FlexRow gap="3">
                {sectionBtns
                    .filter((sectionBtn) => !isAiSuggestionBtn(sectionBtn))
                    .map((sectionBtn) => (
                        <Tooltip key={sectionBtn}>
                        <Tooltip.Trigger asChild>
                            <Button
                                size="sm"
                                variant="tonal"
                                onClick={handleSectionBtnClick(sectionBtn)}
                                disabled={disabled}
                            >
                                {t(keys[`show_${sectionBtn}_label`])}
                                <Button.Icon name={icons[sectionBtn]} />
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t(keys[`show_${sectionBtn}_tooltip`])}
                        </Tooltip.Content>
                    </Tooltip>
                    ))}
            </FlexRow>
            {sectionBtns.some(isAiSuggestionBtn) && (
                <FlexRow
                    onClick={handleSectionBtnClick("ai_suggestion")}
                    alignItems="center"
                    justifyContent="center"
                    gap="1"
                    className="px-4 py-1.5 bg-accent-1-50 rounded-xl w-full border border-accent-1-400 hover:cursor-pointer"
                >
                    <Icon
                        name="stars"
                        size="sm"
                        className="text-accent-1-400"
                    />

                    <Text
                        variant="body-sm-bold"
                        className="bg-gradient-to-r from-[#C238CC] to-[#B554F2] bg-clip-text text-transparent"
                    >
                        {t(keys[`show_ai_suggestion_label`])}
                    </Text>
                </FlexRow>
            )}
        </FlexCol>
    );
};
