import "@app/shared/utils/locales";
import {
    getFlagByLocale,
    keys,
    RESOURCE_NAME,
    SupportedLocales,
} from "@app/shared/utils/locales";
import { Button, type ButtonProps } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { cn } from "@design-system/Utilities";
import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export const LocaleSelect: FC<
    PropsWithChildren<{
        value: SupportedLocales;
        onChange: (value: SupportedLocales) => void;
        options: SupportedLocales[];
        hasPortal?: boolean;
        triggerButtonProps?: ButtonProps;
    }>
> = ({
    value,
    onChange,
    options,
    hasPortal = false,
    children,
    triggerButtonProps,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const handleLangDropdownChange = (value: string[]) => {
        if (!value[0]) return;
        onChange(value[0] as SupportedLocales);
    };

    return (
        <Dropdown
            value={[value]}
            onValueChange={handleLangDropdownChange}
            selectType="single"
        >
            <Dropdown.Trigger asChild>
                {children ?? (
                    <Button
                        variant="tonal"
                        {...(triggerButtonProps ?? {})}
                        className={cn("w-fit", triggerButtonProps?.className)}
                    >
                        <Button.Icon
                            className="mr-2"
                            name={getFlagByLocale(value)}
                        />
                        {t(keys[value])}
                        <Button.Icon name="angleDown" />
                    </Button>
                )}
            </Dropdown.Trigger>
            <Dropdown.Content align="start" hasPortal={hasPortal}>
                {options.map((itemLocale) => (
                    <Dropdown.SelectableItem
                        key={itemLocale}
                        value={itemLocale}
                    >
                        <Dropdown.ItemIcon name={getFlagByLocale(itemLocale)} />
                        <Dropdown.ItemText>
                            {t(keys[itemLocale])}
                        </Dropdown.ItemText>
                        <Dropdown.SelectedIndicator />
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
