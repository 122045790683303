import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "ai-error-message";

const keys = addTranslations(RESOURCE_NAME, {
    error_title: {
        en: "Request error.",
        fr: "Une erreur est survenue.",
    },
    error_description: {
        en: "The requested query did not return any data. Please check your input and try again. If the issue persists, contact support.",
        fr: "La requête demandée n'a pas retourné de données. Veuillez vérifier votre entrée et réessayer. Si le problème persiste, contactez le support.",
    },
    error_cta: {
        en: "{{count}} non validated",
        fr: "{{count}} non validé",
    },
});

export function useAiErrorMessageTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
