import ColorTag from "@app/components/Tags/ColorTag";
import TagListEditor from "@app/shared/components/TagListEditor";
import SubthemeMultiSelect from "@app/shared/components/Theme/SubthemeMultiSelect";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import {
    compareUser,
    firstTagAlphabetical,
    nameNormalized,
} from "@app/shared/utils/tableSortingFns";
import { currentUserRoleAtom } from "@app/store/userStore";
import { getFilteredSortedDatapointGroupsIds } from "@app/usecases/ReportingUseCases";
import { normalizeString } from "@app/utils/string";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { compareBasic, type ColumnDef } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom, useAtomValue } from "jotai";
import { Rocket } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    DatapointGroup,
    Indicator,
    TagType,
    Unit,
    UserRole,
} from "../../../../../generated/client/graphql";
import CheckboxCell from "../../../../components/Table/CheckboxCell";
import CheckboxHeader from "../../../../components/Table/Header/Checkbox";
import { TagsMultiSelect } from "../../../../shared/components/TagsMultiSelect";
import {
    activeReportingEntityIdAtom,
    activeReportingTableColumnsAtom,
    searchThemesActiveFilterAtom,
    searchThemesFilterAtom,
    selectedActiveFrameworkIdsAtomFamily,
    selectedInactiveFrameworkIdsAtomFamily,
    selectedInactiveSubthemesAtomFamily,
    selectedSubthemesAtomFamily,
    selectedTagsAtomFamily,
    selectedUsersAtomFamily,
    toFillSwitchAtom,
} from "../../context";
import {
    useActiveReportingIndicators,
    useDatapointReportingGroups,
    useUpdateIndicator,
    useUpdateIndicatorEntityConfig,
} from "../../data";
import { useActiveDatatpointGroupIds, useDisplayedPeriod } from "../../hooks";
import "../../i18n";
import FrameworkMultiSelect from "../FrameworkMultiSelect";
import IndicatorDatapointCellInput from "../IndicatorDatapointCellInput";
import { selectedTierAtom } from "./ctx";

const selectCol: ColumnDef<Indicator> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    size: 50,
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[50px] max-w-[50px] border-r-0",
        cellClassName:
            "min-w-[50px] max-w-[50px] group-hover/tablerow:bg-primary border-r-0",
    },
};

const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    size: 350,
    sortingFn: nameNormalized,
    meta: {
        colWidth: "350px",
        headerClassName: "min-w-[350px] max-w-[350px]",
        cellClassName:
            "min-w-[350px] max-w-[350px] group-hover/tablerow:bg-primary",
    },
    header: () => {
        const { t } = useTranslation("ReportingPage");
        const [selectedTier, setSelectedTier] = useAtom(selectedTierAtom);
        const handleRocketClick = () => {
            setSelectedTier((prev) => (prev === 1 ? 0 : 1));
        };

        return (
            <FlexRow alignItems="center" gap="2">
                <Text variant="body-sm-bold">
                    {t("indicator_table_indicator_column_title")}
                </Text>
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Button variant="text" onClick={handleRocketClick}>
                            <Rocket
                                className={cn(
                                    "w-4 h-4",
                                    selectedTier && " text-green ",
                                )}
                            />
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {t("rocket_header_tooltip")}
                    </Tooltip.Content>
                </Tooltip>
            </FlexRow>
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation("ReportingPage");

        return (
            <span className="text-primary">
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        <span className="text-sm text-primary">
                            {row.original.name}
                        </span>
                    </Tooltip.Trigger>
                    <Tooltip.Content className="max-w-[500px]">
                        {row.original.description}
                    </Tooltip.Content>
                </Tooltip>
                <span className="inline-flex align-middle ">
                    {row.original.tier === 1 && (
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Rocket className="min-w-[14px] h-[14px]" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t("text_tier_1")}
                            </Tooltip.Content>
                        </Tooltip>
                    )}
                </span>
            </span>
        );
    },
};

const frameworksCol = (active: boolean): ColumnDef<Indicator> => {
    return {
        id: "frameworks",
        meta: {
            colWidth: "250px",
            cellClassName:
                "min-w-[250px] max-w-[250px] group-hover/tablerow:bg-primary",
            headerClassName: "min-w-[250px] max-w-[250px]",
        },
        accessorFn: (original) => original.frameworks?.map((f) => f.cmsId),
        enableSorting: false,
        filterFn: "arrIncludesSome",
        header: () => {
            const { org_uname } = useParams();
            const [selectedFrameworkIds, setSelectedFrameworkIds] = useAtom(
                active
                    ? selectedActiveFrameworkIdsAtomFamily(org_uname)
                    : selectedInactiveFrameworkIdsAtomFamily(org_uname),
            );
            return (
                <FrameworkMultiSelect
                    frameworkIds={selectedFrameworkIds}
                    setFrameworkIds={setSelectedFrameworkIds}
                />
            );
        },
        cell: ({ row }) => {
            const frameworks = row.original.frameworks?.map((f) => f.name);
            return (
                <FlexCol alignItems="start">
                    {frameworks?.map((framework, idx) => (
                        <Text
                            variant="body-sm"
                            key={idx}
                            className="cursor-default"
                        >
                            {framework}
                        </Text>
                    ))}
                </FlexCol>
            );
        },
    };
};

const requirementsCol: ColumnDef<Indicator> = {
    id: "requirements",
    accessorKey: "requirements",
    meta: {
        colWidth: "250px",
        cellClassName:
            "min-w-[250px] max-w-[250px] group-hover/tablerow:bg-primary",
        headerClassName: "min-w-[250px] max-w-[250px]",
    },
    header: () => {
        const { t } = useTranslation("ReportingPage");
        return t("indicator_table_requirements_column_title");
    },
    cell: ({ row }) => {
        return (
            <FlexCol alignItems="start">
                {row.original.requirements?.map((req) => (
                    <Tooltip key={req.id}>
                        <Tooltip.Trigger asChild>
                            <Text
                                variant="body-sm"
                                key={req.id}
                                className="cursor-default"
                            >
                                {req.cmsId}
                            </Text>
                        </Tooltip.Trigger>
                        <Tooltip.Content>{req.name}</Tooltip.Content>
                    </Tooltip>
                ))}
            </FlexCol>
        );
    },
};

const subthemeCol = (active: boolean): ColumnDef<Indicator> => {
    return {
        id: "subtheme",
        accessorKey: "subthemev2.id",
        filterFn: "arrIncludesSome",
        sortingFn: (rowA, rowB) => {
            const subthemeA = rowA.original.subthemev2?.name ?? "";
            const subthemeB = rowB.original.subthemev2?.name ?? "";

            return compareBasic(
                normalizeString(subthemeA),
                normalizeString(subthemeB),
            );
        },
        meta: {
            colWidth: "250px",
            cellClassName:
                "min-w-[250px] max-w-[250px] group-hover/tablerow:bg-primary",
            headerClassName: "min-w-[250px] max-w-[250px]",
        },
        header: () => {
            const { org_uname } = useParams();
            const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
                active
                    ? selectedSubthemesAtomFamily(org_uname)
                    : selectedInactiveSubthemesAtomFamily(org_uname),
            );
            const [searchFilter, setSearchFilter] = useAtom(
                active ? searchThemesFilterAtom : searchThemesActiveFilterAtom,
            );

            return (
                <SubthemeMultiSelect
                    hasPortal
                    isHeader
                    isModal={false}
                    values={selectedSubthemesIds}
                    onValuesChange={setSelectedSubthemesIds}
                    searchFilter={searchFilter}
                    onSearchFilterChange={setSearchFilter}
                />
            );
        },
        cell: ({ row }) => {
            return (
                <ColorTag
                    color={row.original.subthemev2?.theme?.color || "green"}
                    text={row.original.subthemev2?.name || ""}
                />
            );
        },
    };
};

const tagsCol: ColumnDef<Indicator> = {
    id: "tags",
    accessorKey: "tags",
    meta: {
        colWidth: "250px",
        cellClassName:
            "min-w-[250px] max-w-[250px] group-hover/tablerow:bg-primary",
        headerClassName: "min-w-[250px] max-w-[250px]",
    },
    filterFn: (row, _, filterValue) => {
        const tagIds = row.original.tags?.map((tag) => tag.id);
        return (
            filterValue.filter((elt: string) => tagIds.includes(elt)).length > 0
        );
    },
    sortingFn: firstTagAlphabetical,
    header: () => {
        const { org_uname } = useParams();
        const [selectedTagsIds, setSelectedTagsIds] = useAtom(
            selectedTagsAtomFamily(org_uname),
        );
        return (
            <TagsMultiSelect
                isHeader
                tagsIds={selectedTagsIds}
                setTagsIds={setSelectedTagsIds}
                type={TagType.Indicator}
            />
        );
    },
    cell: ({ row }) => {
        const { mutate } = useUpdateIndicator();
        const [selectedTagIds, setSelectedTagsIds] = useState<string[]>(
            row.original.tags.map((tag) => tag.id) || [],
        );
        const updateIndicatorTags = (tagIds: string[]) => {
            setSelectedTagsIds(tagIds);
            mutate({
                id: row.original.id,
                patch: {
                    tagIds,
                },
            });
        };
        useEffect(() => {
            setSelectedTagsIds(row.original.tags.map((tag) => tag.id));
        }, [row.original.tags]);

        return (
            <Box py="1">
                <TagListEditor
                    values={selectedTagIds}
                    onValuesChange={updateIndicatorTags}
                    tagType={TagType.Indicator}
                />
            </Box>
        );
    },
};

const assignedToCol: ColumnDef<Indicator> = {
    id: "assignedTo",
    accessorKey: "entityConfig.assignedTo.id",
    meta: {
        colWidth: "250px",
        cellClassName:
            "min-w-[250px] max-w-[250px] group-hover/tablerow:bg-primary",
        headerClassName: "min-w-[250px] max-w-[250px]",
    },
    filterFn: (row, _, filterValue) => {
        return (
            filterValue.filter((elt: string) => {
                if (elt === null) {
                    return !row.original.entityConfig?.assignedTo;
                } else {
                    return row.original.entityConfig?.assignedTo?.id === elt;
                }
            }).length > 0
        );
    },
    sortingFn: (rowA, rowB) => {
        const ownerA = rowA.original.entityConfig?.assignedTo;
        const ownerB = rowB.original.entityConfig?.assignedTo;

        return compareUser(ownerA, ownerB);
    },

    header: () => {
        const { org_uname } = useParams();
        const [selectedUserIds, setSelectedUserIds] = useAtom(
            selectedUsersAtomFamily(org_uname),
        );
        const [searchFilter, setSearchFilter] = useState<string>();
        return (
            <UserMultiSelect
                isHeader
                values={selectedUserIds}
                onValuesChange={setSelectedUserIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
                includeNone={true}
            />
        );
    },
    cell: ({ row }) => {
        const { mutate } = useUpdateIndicatorEntityConfig();
        const [searchFilter, setSearchFilter] = useState<string>();
        const [isPending, setIsPending] = useState<boolean>(false);
        const setAssignedTo = async (userId: string | undefined) => {
            if (!row.original.entityConfig?.id) return;
            setIsPending(true);
            mutate(
                {
                    indicatorEntityConfigId: row.original.entityConfig.id,
                    set: {
                        active: undefined,
                        userId: userId || null,
                    },
                },
                {
                    onSettled: () => {
                        setIsPending(false);
                    },
                },
            );
        };
        return (
            <div>
                <UserChipSelect
                    userId={
                        row.original?.entityConfig?.assignedTo?.id || undefined
                    }
                    setUserId={setAssignedTo}
                    searchFilter={searchFilter}
                    onSearchFilterChange={setSearchFilter}
                    isPending={isPending}
                />
            </div>
        );
    },
};

const assignedToColContributor: ColumnDef<Indicator> = {
    id: "assignedTo",
    accessorKey: "entityConfig.assignedTo.id",
    meta: {
        colWidth: "150px",
        cellClassName: "group-hover/tablerow:bg-primary",
    },
    enableSorting: false,

    header: () => {
        const { t } = useTranslation("ReportingPage");
        return t("indicator_table_assigned_to_column_title");
    },
    cell: ({ row }) => {
        const user = row.original?.entityConfig?.assignedTo;
        return `${user?.firstName} ${user?.lastName}`;
    },
};

const dataCol = (
    datapointGroupId: string,
    datapointGroups: any[],
): ColumnDef<Indicator> => {
    return {
        id: `data_${datapointGroupId}`,
        accessorKey: `data_${datapointGroupId}`,
        enableSorting: false,
        meta: {
            colWidth: "200px",
            headerClassName: "min-w-[200px]",
            cellClassName:
                "min-w-[200px] py-0 px-0 group-hover/tablerow:bg-primary",
            enableColumnEdit: false,
        },
        header: () => {
            const period = datapointGroups.find(
                (elt) => elt.id === datapointGroupId,
            )?.period;
            const { getDetailedDisplayedPeriod } = useDisplayedPeriod();
            return getDetailedDisplayedPeriod(period);
        },
        cell: ({ row }) => {
            const datapoint = row.original.datapoints?.find(
                (elt) => elt?.datapointGroup?.id === datapointGroupId,
            );

            return (
                <IndicatorDatapointCellInput
                    indicatorId={row.original.id}
                    groupId={datapointGroupId}
                    unit={row.original.unit as Unit}
                    datapoint={datapoint}
                    calculation={row.original?.calculation || ""}
                    proofs={datapoint?.proofs || []}
                />
            );
        },
    } as ColumnDef<Indicator>;
};

export const useColumns = (): ColumnDef<Indicator>[] => {
    const activeDatapointGroupsIds = useActiveDatatpointGroupIds();
    const { data: datapointGroupsData } = useDatapointReportingGroups();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);
    const toFillSwitch = useAtomValue(toFillSwitchAtom);
    const { data } = useActiveReportingIndicators();
    const activeReportingTableColumns = useAtomValue(
        activeReportingTableColumnsAtom,
    );
    const { role } = useAtomValue(currentUserRoleAtom);
    return useMemo(() => {
        return [
            ...(role === UserRole.Contributor ? [] : [selectCol]),
            nameCol,
            {
                id: "tier",
                filterFn: "equals",
                accessorKey: "tier",
            },
            ...[
                requirementsCol,
                tagsCol,
                frameworksCol(true),
                subthemeCol(true),
                role === UserRole.Contributor
                    ? assignedToColContributor
                    : assignedToCol,
            ].filter((col) => {
                return activeReportingTableColumns.includes(col.id || "");
            }),
            ...getFilteredSortedDatapointGroupsIds(
                activeDatapointGroupsIds,
                activeReportingEntityId,
                (datapointGroupsData?.datapointGroups as DatapointGroup[]) ||
                    [],
            ).map((id) => {
                return dataCol(id, datapointGroupsData?.datapointGroups || []);
            }),
        ];
    }, [
        activeDatapointGroupsIds,
        datapointGroupsData?.datapointGroups,
        toFillSwitch,
        activeReportingEntityId,
        data?.length,
        activeReportingTableColumns,
    ]);
};

const subthemeColWithActiveFalse = subthemeCol(false);

export const inactiveIndicatorsTableColumns = (): ColumnDef<Indicator>[] => {
    return [
        selectCol,
        nameCol,
        {
            id: "tier",
            filterFn: "equals",
            accessorKey: "tier",
        },
        subthemeColWithActiveFalse,
        requirementsCol,
        frameworksCol(false),
    ];
};

export const pinnedColumnsIds: string[] = [selectCol.id, nameCol.id ?? ""];
