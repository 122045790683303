import { ArrowLeft, Xmark } from "iconoir-react";
import FloatbarLayout from "./Layout";
import "./i18n";

interface SaveBarState<T> {
    text?: string;
    open: boolean;
    activeAction?: T | undefined;
    setActiveAction?: (state: T | undefined) => void;
    primary: React.ReactNode;
    secondary?: React.ReactNode;
    actions?: {
        id: T;
        text: string;
        selector?: React.ReactNode;
        apply: React.ReactNode;
    }[];
    unset?: () => void;
}

export default function Floatbar<T>(props: SaveBarState<T>) {
    const action = props.actions?.find((a) => {
        return a.id === props.activeAction;
    });

    return (
        <FloatbarLayout open={props.open}>
            {action === undefined ? (
                <>
                    {props.unset && (
                        <button onClick={props.unset}>
                            <Xmark className="h-5 w-5 text-white" />
                        </button>
                    )}
                    <div className="text-white ">
                        {props.text || "Selection"}
                    </div>
                    <div>{props.primary}</div>
                    {props.secondary && <div>{props.secondary}</div>}
                </>
            ) : (
                <>
                    <button onClick={() => props.setActiveAction?.(undefined)}>
                        <ArrowLeft className="h-4 w-4 text-white" />
                    </button>
                    <div className="text-white whitespace-nowrap ">
                        {action.text}
                    </div>
                    <div>{action.selector}</div>
                    <div>{action.apply}</div>
                </>
            )}
        </FloatbarLayout>
    );
}
