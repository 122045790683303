import { useSetToast } from "@app/components/Toast";
import { computeStatus } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/services";
import { DocumentsModal } from "@app/shared/components/documentUpload/DocumentsModal";
import { UploadedDocumentsList } from "@app/shared/components/documentUpload/UploadedDocumentsList";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    AnswerStatus,
    GetQuestionsQuery,
    QuestionFilter,
    QuestionType,
    ValidationStatus,
} from "@generated/client/graphql";
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
} from "react";

import {
    useQuestionAssignmentUpdate,
    useQuestionnaireDetails,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/data";
import { SupportedLocales } from "@app/shared/utils/locales";
import {
    useConditionalScrollTo,
    useOnScrollIntoView,
} from "@app/shared/utils/scroll";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { intervalToDuration } from "date-fns";
import { useAtom } from "jotai";
import { useQueryParam } from "use-query-params";
import {
    lastUrlUpdateByClickTimestampAtom,
    questionnaireParamsArgs,
} from "../../../../ctx";
import {
    useGenerateAiSuggestion,
    useQuestionAndAnswerUpdate,
} from "../../data";
import { AnswerAiSuggestion } from "../AnswerAiSuggestion";
import { useQuestionTranslations } from "./i18n";
import {
    QuestionCard,
    questionCardShowBtnsSections,
    type QuestionCardShowBtnsSectionType,
} from "./QuestionCard";

export const Question: FC<{
    question: GetQuestionsQuery["questions"][number];
    filter?: QuestionFilter;
    locale?: SupportedLocales;
}> = ({ question, filter, locale }) => {
    const { t, keys } = useQuestionTranslations();
    const { setToastSuccess, setToastError } = useSetToast();
    const {
        deleteAnswer,
        validateAnswer,
        updateTextAnswer,
        updateEvidenceFilesAsync,
        removeEvidenceFiles,
        updateSelectAnswer,
        updateValueAnswer,
        updateComment,
        isPending: isUpdatePending,
    } = useQuestionAndAnswerUpdate(
        question?.id,
        question?.answer?.id,
        question?.questionnaireId,
        filter,
    );

    const { updateQuestion: assignQuestion } = useQuestionAssignmentUpdate(
        question?.questionnaireId ?? undefined,
    );

    const { generateAiSuggestion } = useGenerateAiSuggestion(
        question?.questionnaireId ?? undefined,
        question?.id,
    );

    const { questionnaire } = useQuestionnaireDetails(
        question?.questionnaireId ?? undefined,
    );
    const questionnaireName = questionnaire?.name;

    const answerInputRef = useRef<HTMLTextAreaElement>(null);
    const commentInputRef = useRef<HTMLTextAreaElement>(null);
    const valueInputRef = useRef<HTMLInputElement>(null);
    const [answerSelectedValues, setAnswerSelectedValues] = useState<number[]>(
        question?.answer?.value?.options ?? [],
    );

    const [answerTextValue, setAnswerTextValue] = useState<string>(
        question?.answer?.value?.text ?? "",
    );

    const [answerNumValue, setAnswerNumValue] = useState<number>(
        question?.answer?.value?.num ?? 0,
    );

    const [isAiSuggestionOpen, setIsAiSuggestionOpen] =
        useState<boolean>(false);

    const [activeQuestionIdParams, setActiveQuestionIdParams] = useQueryParam(
        ...questionnaireParamsArgs.questionId,
    );
    const [lastUrlUpdateByClickTimestamp, setLastUrlUpdateByClickTimestamp] =
        useAtom(lastUrlUpdateByClickTimestampAtom);
    const scrollToQuestionRef = useConditionalScrollTo(
        activeQuestionIdParams === question?.id &&
            lastUrlUpdateByClickTimestamp &&
            (intervalToDuration({
                start: lastUrlUpdateByClickTimestamp,
                end: new Date(),
            }).seconds ?? 0) <= 2,
    );
    const onScrollIntoViewCb = useCallback(() => {
        setLastUrlUpdateByClickTimestamp(undefined);
        setActiveQuestionIdParams(question?.id, "replaceIn");
    }, [
        setActiveQuestionIdParams,
        question?.id,
        setLastUrlUpdateByClickTimestamp,
    ]);
    const onScrollIntoViewRef = useOnScrollIntoView(onScrollIntoViewCb);
    const handleRefs = (el: HTMLDivElement) => {
        scrollToQuestionRef(el);
        onScrollIntoViewRef.current = el;
    };
    const [isValidated, setIsValidated] = useState<boolean>(
        question?.answer?.status === AnswerStatus.Validated,
    );
    const [ownerId, setOwnerId] = useState<string | undefined | null>(
        question?.ownerId,
    );
    const [ownerSearchFilter, setOwnerSearchFilter] = useState<string>();

    const status = useMemo(() => {
        return computeStatus({
            status: question?.answer?.status ?? AnswerStatus.Unknown,
            value: question?.answer?.value,
        });
    }, [question?.answer?.status, question?.answer?.value]);
    const selectOptions: string[] =
        question?.metadata?.options
            ?.filter((option) => Object.keys(option).length > 0)
            ?.map((option) => option.name?.[locale ?? "en"])
            ?.filter((option) => option != null) ?? [];

    const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
    const [openSections, setOpenSections] = useState<
        QuestionCardShowBtnsSectionType[]
    >([
        ...(question?.answer?.comment ? (["comment"] as const) : []),
        ...(question?.answer?.aiSuggestion ? (["ai_suggestion"] as const) : []),
    ]);
    // include only the sections that are not open
    const sectionBtns = questionCardShowBtnsSections.filter(
        (btn) => !openSections.includes(btn),
    );

    const isCommentSectionOpen =
        !!question?.answer?.comment || openSections.includes("comment");
    const isAiSuggestionSectionOpen =
        !!question?.answer?.aiSuggestion ||
        openSections.includes("ai_suggestion");

    useEffect(() => {
        const answerInputValue = answerTextValue;
        const commentInputValue = question?.answer?.comment;
        const valueInputValue = answerNumValue?.toString();

        if (answerInputRef.current) {
            answerInputRef.current.value = answerInputValue ?? "";
        }
        if (commentInputRef.current) {
            commentInputRef.current.value = commentInputValue ?? "";
        }
        if (valueInputRef.current) {
            valueInputRef.current.value = valueInputValue ?? "";
        }
        setIsValidated(question?.answer?.status === AnswerStatus.Validated);
        setOwnerId(question?.ownerId);
        setAnswerSelectedValues(question?.answer?.value?.options ?? []);
    }, [
        answerTextValue,
        question?.answer?.comment,
        answerNumValue,
        question?.answer?.status,
        question?.ownerId,
        question?.answer?.value?.options,
    ]);

    const openDocumentsModal = () => {
        setIsDocumentsModalOpen(true);
    };

    const closeDocumentsModal = () => {
        setIsDocumentsModalOpen(false);
    };

    const handleSectionBtnClick = (
        sectionBtn: QuestionCardShowBtnsSectionType,
    ) => {
        if (sectionBtn === "upload") return openDocumentsModal();
        if (sectionBtn === "ai_suggestion") {
            generateAiSuggestion(question?.id);
        }

        setOpenSections((prev) => [...prev, sectionBtn]);
    };

    const handleDeleteCommentClick = () => {
        updateComment("");
        setOpenSections((prev) =>
            prev.filter((section) => section !== "comment"),
        );
    };

    const canBeValidated = question?.answer?.value != null && !isValidated;

    const handleValidationClick = () => {
        if (!canBeValidated) return;

        setIsValidated(true);
        validateAnswer(ValidationStatus.Validated);
        setIsAiSuggestionOpen(false);
    };

    const handleResetValidationClick = () => {
        if (!isValidated) return;
        setIsValidated(false);
        validateAnswer(ValidationStatus.Notvalidated);
    };

    const canBeReset =
        !isValidated &&
        (question?.answer?.value != null ||
            question?.answer?.comment != null ||
            (question?.answer?.evidenceFiles?.length ?? 0) > 0);

    const handleResetClick = () => {
        if (!canBeReset) return;

        if (question?.type === QuestionType.Text) {
            setAnswerTextValue("");
        }
        if (question?.type === QuestionType.Value) {
            setAnswerNumValue(0);
        }
        deleteAnswer();
        removeEvidenceFiles(
            question?.answer?.evidenceFiles?.map((file) => file.id) ?? [],
        );
        handleDeleteCommentClick();
    };

    const saveAnswer = () => {
        const opts = {
            onSuccess: () => {
                setToastSuccess(t(keys.answer_saved));
            },
            onError: () => {
                setToastError(t(keys.answer_saved_error));
            },
        };

        if (question?.type === QuestionType.Value) {
            const value = Number(valueInputRef.current?.value);
            if (answerNumValue === value) {
                return;
            }
            setAnswerNumValue(value);
            return updateValueAnswer(value, opts);
        }

        if (answerTextValue === answerInputRef.current?.value) {
            return;
        }
        setAnswerTextValue(answerInputRef.current?.value ?? "");
        updateTextAnswer(answerInputRef.current?.value ?? "", opts);
    };

    const saveComment = () => {
        if (question?.answer?.comment === commentInputRef.current?.value)
            return;

        updateComment(commentInputRef.current?.value ?? "", {
            onSuccess: () => {
                setToastSuccess(t(keys.comment_saved));
            },
            onError: () => {
                setToastError(t(keys.comment_saved_error));
            },
        });
    };

    const updateOwner = (ownerId?: string) => {
        if (!question?.id) return;
        setOwnerId(ownerId);

        assignQuestion({
            id: question?.id,
            input: {
                ownerId: ownerId ?? null,
            },
        });
    };

    const removeDocument = (fileId: string) => {
        removeEvidenceFiles([fileId]);
    };

    const onSingleSelectChange = (value: number) => {
        if (answerSelectedValues?.includes(value)) {
            setAnswerSelectedValues([]);
            updateSelectAnswer([]);
        } else {
            setAnswerSelectedValues([value]);
            updateSelectAnswer([value]);
        }
    };

    const onMultiSelectChange = (values: number[]) => {
        setAnswerSelectedValues(values);
        updateSelectAnswer(values);
    };
    return (
        <QuestionCard ref={handleRefs}>
            <QuestionCard.Header>
                <QuestionCard.StatusCol status={status} />
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger>
                        <UserChipSelect
                            userId={ownerId}
                            setUserId={updateOwner}
                            searchFilter={ownerSearchFilter}
                            onSearchFilterChange={setOwnerSearchFilter}
                            disabled={isValidated}
                            trigger={
                                <FlexRow maxW="full" w="fit">
                                    <UserTag
                                        className={
                                            isValidated ? "" : "cursor-pointer"
                                        }
                                        userId={ownerId ?? undefined}
                                        noUserText={t(
                                            keys.owner_assign_no_owner,
                                        )}
                                    />
                                </FlexRow>
                            }
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {t(keys.assign_owner_tooltip)}
                    </Tooltip.Content>
                </Tooltip>
            </QuestionCard.Header>
            <QuestionCard.Content>
                <FlexCol gap="1">
                    <QuestionCard.Title>{question?.name}</QuestionCard.Title>
                    {question?.description && (
                        <QuestionCard.Description>
                            {question.description}
                        </QuestionCard.Description>
                    )}
                </FlexCol>
                <QuestionCard.QuestionCardGuidance
                    frameworkGuidance={question.guidance ?? undefined}
                    expertiseGuidance={question.guidanceExpertise ?? undefined}
                    questionnaireName={questionnaireName ?? undefined}
                />
                {question?.type === QuestionType.SingleSelect ? (
                    <QuestionCard.SingleSelect
                        options={selectOptions}
                        value={answerSelectedValues?.[0] ?? ""}
                        onChange={onSingleSelectChange}
                        disabled={isValidated}
                    />
                ) : question?.type === QuestionType.MultiSelect ? (
                    <QuestionCard.MultiSelect
                        options={selectOptions}
                        value={answerSelectedValues ?? []}
                        onChange={onMultiSelectChange}
                        disabled={isValidated}
                    />
                ) : question?.type === QuestionType.Text ? (
                    <Textarea
                        className="resize-y"
                        ref={answerInputRef}
                        defaultValue={answerTextValue}
                        placeholder={t(keys.answer_placeholder)}
                        onBlur={saveAnswer}
                        disabled={isValidated}
                    />
                ) : question?.type === QuestionType.Value ? (
                    <Textfield
                        defaultValue={answerNumValue}
                        placeholder={
                            question?.metadata?.unit
                                ? t(keys.answer_with_unit_placeholder, {
                                      unit: question?.metadata?.unit,
                                  })
                                : t(keys.answer_placeholder)
                        }
                        ref={valueInputRef}
                        onBlur={saveAnswer}
                        disabled={isValidated}
                    />
                ) : null}
                {isCommentSectionOpen && (
                    <FlexRow
                        pl="4"
                        className="border-l border-secondary"
                        alignItems="center"
                        gap="4"
                    >
                        <Textarea
                            ref={commentInputRef}
                            defaultValue={question?.answer?.comment ?? ""}
                            placeholder={t(keys.comment_placeholder)}
                            onBlur={saveComment}
                            disabled={isValidated}
                        />
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Icon
                                    className="text-primary"
                                    name="trash"
                                    size="sm"
                                    onClick={handleDeleteCommentClick}
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t(keys.delete_comment)}
                            </Tooltip.Content>
                        </Tooltip>
                    </FlexRow>
                )}
                {!!question?.answer?.evidenceFiles?.length && (
                    <FlexCol gap="1">
                        <Text color="secondary">{t(keys.evidence_files)}</Text>
                        <UploadedDocumentsList
                            files={question?.answer?.evidenceFiles ?? []}
                            onRemove={removeDocument}
                        />
                    </FlexCol>
                )}
                <QuestionCard.ShowSectionsButtons
                    sectionBtns={sectionBtns}
                    onSectionBtnClick={handleSectionBtnClick}
                    disabled={isValidated}
                />

                {isAiSuggestionSectionOpen && question && (
                    <AnswerAiSuggestion
                        question={question}
                        locale={locale ?? SupportedLocales.EN}
                        isAiSuggestionOpen={isAiSuggestionOpen}
                        setIsAiSuggestionOpen={setIsAiSuggestionOpen}
                    />
                )}

                <FlexRow
                    w="full"
                    justifyContent="end"
                    className="border-t"
                    gap="1"
                    pt="4"
                >
                    {isValidated ? (
                        <Button size="md" onClick={handleResetValidationClick}>
                            {t(keys.reset_validation)}
                        </Button>
                    ) : (
                        <FlexRow alignItems="center" gap="4">
                            <Button
                                size="md"
                                variant="text"
                                disabled={!canBeReset}
                                onClick={handleResetClick}
                            >
                                {t(keys.reset_answer)}
                            </Button>
                            <Tooltip delayDuration={0}>
                                <Tooltip.Trigger>
                                    <Button
                                        size="md"
                                        disabled={!canBeValidated}
                                        onClick={handleValidationClick}
                                    >
                                        {t(keys.validate_answer)}
                                    </Button>
                                </Tooltip.Trigger>
                                {!canBeValidated && (
                                    <Tooltip.Content>
                                        {t(keys.validate_answer_tooltip)}
                                    </Tooltip.Content>
                                )}
                            </Tooltip>
                        </FlexRow>
                    )}
                </FlexRow>
            </QuestionCard.Content>
            <DocumentsModal
                onConfirm={updateEvidenceFilesAsync}
                open={isDocumentsModalOpen}
                onClose={closeDocumentsModal}
                loading={isUpdatePending}
            />
        </QuestionCard>
    );
};
