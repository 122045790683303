import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { versionKeys } from "@app/store/queryKeys";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { GetLastVersionByDocumentUrlQuery } from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCheckDocument } from "./data";

export const AiCheckTab: FC<{
    versionId?: string;
    aiCheck?: NonNullable<
        GetLastVersionByDocumentUrlQuery["lastVersion"]
    >["aiDocumentChecks"];
    aiCheckLoadingProgress: number;
    setAiCheckLoadingProgress: (progress: number) => void;
}> = ({
    versionId,
    aiCheck,
    aiCheckLoadingProgress,
    setAiCheckLoadingProgress,
}) => {
    const { checkDocument } = useCheckDocument(versionId ?? "");
    const mutationState = useMutationState({
        filters: { mutationKey: [...versionKeys.aiCheck(versionId)] },
        select: (mutation) => mutation.state.status,
    });

    const { t } = useTranslation("DocumentEditorSideBar");

    const isAiCheckPending =
        !!mutationState?.length &&
        mutationState[mutationState.length - 1] === "pending";
    const handleGenerateClick = () => {
        if (!versionId || isAiCheckPending) {
            return;
        }
        setAiCheckLoadingProgress(0);
        checkDocument();
    };

    const invalidChecks = aiCheck?.checks?.filter((check) => !check.success);
    const successfulChecks = aiCheck?.checks?.filter((check) => check.success);
    const orderedChecks = [
        ...(invalidChecks ?? []),
        ...(successfulChecks ?? []),
    ];

    return (
        <FlexCol className="px-4 pb-4">
            <AiSuggestion
                isOpen={!!aiCheck && !isAiCheckPending}
                isLoading={isAiCheckPending}
            >
                <AiSuggestion.Header
                    title={
                        isAiCheckPending
                            ? t("ai_coach_generating")
                            : t("ai_coach_title")
                    }
                >
                    {!isAiCheckPending && (
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <Button
                                    variant="plain"
                                    size="sm"
                                    className="hover:bg-opacity-30 active:bg-opacity-30"
                                    onClick={handleGenerateClick}
                                >
                                    <Button.Icon
                                        name="arrowCircleRight"
                                        size="sm"
                                    />
                                </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                <Text variant="body">
                                    {t("ai_coach_regenerate")}
                                </Text>
                            </Tooltip.Content>
                        </Tooltip>
                    )}
                </AiSuggestion.Header>
                {isAiCheckPending && (
                    <FlexRow className="px-4 pb-4 w-full">
                        <AiSuggestion.Loader
                            progress={aiCheckLoadingProgress}
                            setProgress={setAiCheckLoadingProgress}
                            maxProgress={99}
                            totalDuration={20000}
                            slowdownFactor={5}
                        />
                    </FlexRow>
                )}
                <AiSuggestion.Body className="pb-4">
                    <Text variant="body">
                        {t("ai_coach_description", {
                            count: invalidChecks?.length,
                        })}
                    </Text>
                    {orderedChecks?.map((check) => (
                        <CheckBlock key={check.name} check={check} />
                    ))}
                </AiSuggestion.Body>
            </AiSuggestion>
        </FlexCol>
    );
};

const CheckBlock: FC<{
    check: NonNullable<
        NonNullable<
            GetLastVersionByDocumentUrlQuery["lastVersion"]
        >["aiDocumentChecks"]
    >["checks"][number];
}> = ({ check }) => {
    const { t } = useTranslation("DocumentEditorSideBar");

    const [isExpanded, setIsExpanded] = useState(!check.success);
    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <FlexCol>
            <FlexRow alignItems="center" justifyContent="between">
                <FlexRow gap="1" alignItems="center">
                    <Box className="grid place-items-center size-5">
                        <Icon
                            name={check.success ? "check" : "close"}
                            className={cn(
                                check.success ? "text-green" : "text-danger",
                            )}
                        />
                    </Box>
                    <Text>{t("ai_coach_check", { context: check.name })}</Text>
                </FlexRow>
                <Button
                    variant="plain"
                    size="sm"
                    onClick={handleExpandClick}
                    className="hover:bg-opacity-30 active:bg-opacity-30"
                >
                    <Button.Icon
                        name={isExpanded ? "angleUp" : "angleDown"}
                        size="sm"
                    />
                </Button>
            </FlexRow>
            {isExpanded && <Text>{check.motivation}</Text>}
        </FlexCol>
    );
};
