import { atom } from "jotai";

export const isAiChatModalOpenAtom = atom<boolean>(false);
export const currentAiThreadIdAtom = atom<string | undefined>(undefined);
export const runStatusAtom = atom<RunStatus | undefined>(undefined);
export const lastRunThreadIdAtom = atom<string | undefined>(undefined);

export const RunStatus = {
    Streaming: "streaming",
    Failed: "failed",
    SearchingFiles: "searchingFiles",
    Writing: "writing",
} as const;

type RunStatus = (typeof RunStatus)[keyof typeof RunStatus];
