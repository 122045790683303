import { useSetToast } from "@app/components/Toast";
import {
    QuestionCard,
    questionCardShowBtnsSections,
    type QuestionCardShowBtnsSectionType,
} from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/QuestionsTab/components/Question/QuestionCard";
import { computeStatus } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/services";
import { SupportedLocales } from "@app/shared/utils/locales";
import { useOptimistic } from "@app/shared/utils/optimisticUpdates";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { AnswerStatus, QuestionType } from "@generated/client/graphql";
import { ChangeEvent, useMemo, useState, type FC } from "react";
import { useUpsertSupplierQuestionnaireQuestionAnswer } from "../../data";
import { answerOptimisticUpdateFn } from "../../services";
import {
    type AnswerInput,
    type AnswerValue,
    type Question as TQuestion,
} from "../../types";
import { useSupplierQuestionnaireQuestionTranslations } from "./i18n";

export const Question: FC<{
    currentLocale: SupportedLocales;
    question: TQuestion;
    isOpen?: boolean;
}> = ({ currentLocale, question, isOpen = true }) => {
    const { t, keys } = useSupplierQuestionnaireQuestionTranslations();
    const options = useMemo(() => {
        return (
            question.metadata?.options?.map(
                (o) =>
                    o.name?.[currentLocale] ??
                    t(keys.option_non_available_in_locale),
            ) ?? []
        );
    }, [question.metadata?.options, currentLocale]);

    const {
        optimisticAnswer,
        handleCommentChange,
        handleValueChange,
        handleSingleSelectChange,
        handleMultiSelectChange,
        handleTextChange,
        handleCommentBlur,
        handleTextOrValueBlur,
    } = useAnswer(question.answer, { isOpen, questionId: question.id });

    const {
        closeAiModal,
        handleSectionBtnClick,
        isAiModalOpen,
        isCommentSectionOpen,
        sectionBtns,
    } = useShowSectionsBtns({
        comment: optimisticAnswer?.comment ?? undefined,
    });

    const status = useMemo(() => {
        return computeStatus({
            status: optimisticAnswer?.status ?? AnswerStatus.NotAnswered,
            value: optimisticAnswer?.value ?? undefined,
        });
    }, [optimisticAnswer?.status, optimisticAnswer?.value]);

    return (
        <QuestionCard>
            <QuestionCard.Header>
                <QuestionCard.StatusCol status={status} />
            </QuestionCard.Header>
            <QuestionCard.Content>
                <QuestionCard.Title>
                    {question.nameMultiLocale?.[currentLocale] ||
                        t(keys.unavailable_translation)}
                </QuestionCard.Title>
                {question.descriptionMultiLocale?.[currentLocale] && (
                    <QuestionCard.Description>
                        {question.descriptionMultiLocale?.[currentLocale]}
                    </QuestionCard.Description>
                )}
                {question.type === QuestionType.Text && (
                    <Textarea
                        disabled={!isOpen}
                        onChange={handleTextChange}
                        placeholder={t(keys.text_placeholder)}
                        value={optimisticAnswer?.value?.text ?? ""}
                        onBlur={handleTextOrValueBlur}
                    />
                )}
                {question.type === QuestionType.Value && (
                    <Textfield
                        disabled={!isOpen}
                        onChange={handleValueChange}
                        placeholder={
                            question.metadata?.unit
                                ? t(keys.value_unit_placeholder, {
                                      unit: question.metadata?.unit,
                                  })
                                : t(keys.value_placeholder)
                        }
                        value={optimisticAnswer?.value?.text ?? ""}
                        onBlur={handleTextOrValueBlur}
                    />
                )}
                {question.type === QuestionType.SingleSelect && (
                    <QuestionCard.SingleSelect
                        disabled={!isOpen}
                        options={options}
                        value={optimisticAnswer?.value?.options?.[0]}
                        onChange={handleSingleSelectChange}
                    />
                )}
                {question.type === QuestionType.MultiSelect && (
                    <QuestionCard.MultiSelect
                        disabled={!isOpen}
                        options={options}
                        value={optimisticAnswer?.value?.options ?? []}
                        onChange={handleMultiSelectChange}
                    />
                )}
                {isCommentSectionOpen && (
                    <Textarea
                        disabled={!isOpen}
                        onChange={handleCommentChange}
                        placeholder={t(keys.comment_placeholder)}
                        value={optimisticAnswer?.comment ?? ""}
                        onBlur={handleCommentBlur}
                    />
                )}
                <QuestionCard.ShowSectionsButtons
                    sectionBtns={sectionBtns}
                    onSectionBtnClick={handleSectionBtnClick}
                    disabled={!isOpen}
                />
            </QuestionCard.Content>
            <Modal open={isAiModalOpen} onOpenChange={closeAiModal}>
                <Modal.Overlay centerContent>
                    <Modal.Content>
                        <Modal.Header>
                            <Modal.Title>
                                {t(keys.ai_suggestion_modal_title)}
                            </Modal.Title>
                            <Modal.Close />
                        </Modal.Header>
                        <Modal.Body>
                            <Text>{t(keys.ai_suggestion_modal_content)}</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Modal.CancelCTA>
                                {t(keys.ai_suggestion_modal_cancel)}
                            </Modal.CancelCTA>
                            <Modal.ConfirmCTA variant="flatAccent" asChild>
                                <a
                                    className="hover:no-underline"
                                    href="https://www.beavr.tech/en/contact"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t(keys.ai_suggestion_modal_confirm)}
                                </a>
                            </Modal.ConfirmCTA>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Overlay>
            </Modal>
        </QuestionCard>
    );
};

function useShowSectionsBtns({ comment }: { comment?: string }) {
    const [openSections, setOpenSections] = useState<
        QuestionCardShowBtnsSectionType[]
    >([
        ...(comment ? (["comment"] as const) : []),
        "upload", // we do not wanna show upload so far, so we pretend it is open and the btn won't be displayed
    ]);

    // include only the sections that are not open
    const sectionBtns = useMemo(
        () =>
            questionCardShowBtnsSections.filter(
                (btn) => !openSections.includes(btn),
            ),
        [openSections],
    );

    const isCommentSectionOpen = !!comment || openSections.includes("comment");

    const [isAiModalOpen, setIsAiModalOpen] = useState(false);
    const closeAiModal = () => {
        setIsAiModalOpen(false);
    };

    const handleSectionBtnClick = (
        sectionBtn: QuestionCardShowBtnsSectionType,
    ) => {
        if (sectionBtn === "ai_suggestion") {
            setIsAiModalOpen(true);
        }
        if (sectionBtn === "comment") {
            setOpenSections([...openSections, "comment"]);
        }
    };

    return {
        closeAiModal,
        handleSectionBtnClick,
        isAiModalOpen,
        isCommentSectionOpen,
        sectionBtns,
    };
}

function useAnswer(
    answer: TQuestion["answer"],
    { isOpen, questionId }: { isOpen?: boolean; questionId: string },
) {
    const { t, keys } = useSupplierQuestionnaireQuestionTranslations();

    const [optimisticAnswer, setOptimisticAnswer] = useOptimistic(answer);
    const { setToastSuccess, setToastError } = useSetToast();
    const mutationOptions = {
        onSuccess: () => {
            setToastSuccess(t(keys.successfully_updated));
        },
        onError: () => {
            setToastError(t(keys.update_error));
        },
    };

    const { mutate } = useUpsertSupplierQuestionnaireQuestionAnswer();
    const saveAnswer = (input: AnswerInput) => {
        mutate(
            {
                id: optimisticAnswer?.id,
                ...input,
                questionId,
            },
            mutationOptions,
        );
    };

    const handleChangeMaker =
        <
            E extends
                | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                | number[]
                | number,
        >(
            onChange: (evt: E) => { value: AnswerValue } | { comment: string },
            save: boolean = false,
        ) =>
        (evt: E) => {
            if (!isOpen) return;

            const input = onChange(evt);
            setOptimisticAnswer((prev) =>
                answerOptimisticUpdateFn(
                    { prevAnswer: prev, questionId },
                    input,
                ),
            );
            if (save) {
                saveAnswer(input);
            }
        };

    return {
        optimisticAnswer,
        handleCommentChange: handleChangeMaker(
            (evt: ChangeEvent<HTMLTextAreaElement>) => ({
                comment: evt.target.value,
            }),
        ),
        handleValueChange: handleChangeMaker(
            (evt: ChangeEvent<HTMLInputElement>) => ({
                value: { text: evt.target.value },
            }),
        ),
        handleTextChange: handleChangeMaker(
            (evt: ChangeEvent<HTMLTextAreaElement>) => ({
                value: { text: evt.target.value },
            }),
        ),
        handleSingleSelectChange: handleChangeMaker(
            (evt: number) => ({
                value: { options: [evt] },
            }),
            true,
        ),

        handleMultiSelectChange: handleChangeMaker(
            (evt: number[]) => ({
                value: { options: evt },
            }),
            true,
        ),

        handleCommentBlur: () => {
            saveAnswer({
                comment: optimisticAnswer?.comment ?? "",
            });
        },

        handleTextOrValueBlur: () => {
            saveAnswer({
                value: optimisticAnswer?.value ?? {},
            });
        },
    };
}
