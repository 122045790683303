import { Icon } from "@design-system/Icon";
import { cn } from "@design-system/Utilities";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { FC } from "react";

// Define the compound component type
type AccordionComponent = typeof AccordionPrimitive.Root & {
    Item: typeof AccordionItem;
    Trigger: typeof AccordionTrigger;
    Content: typeof AccordionContent;
};

// Cast the Accordion as the compound component type
const Accordion = AccordionPrimitive.Root as AccordionComponent;

const AccordionItem: FC<AccordionPrimitive.AccordionItemProps> = ({
    className,
    ...props
}) => (
    <AccordionPrimitive.Item className={cn("border-b", className)} {...props} />
);
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger: FC<AccordionPrimitive.AccordionTriggerProps> = ({
    className,
    children,
    ...props
}) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            className={cn(
                "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all [&[data-state=open]>svg]:rotate-180",
                className,
            )}
            {...props}
        >
            {children}
            <Icon
                className="text-primary shrink-0 text-muted-foreground transition-transform duration-200"
                name="angleDown"
                size="sm"
            />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
);
AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent: FC<AccordionPrimitive.AccordionContentProps> = ({
    className,
    children,
    ...props
}) => (
    <AccordionPrimitive.Content
        className={cn(
            "overflow-hidden text-sm data-[state=closed]:animate-accordionUp data-[state=open]:animate-accordionDown",
            className,
        )}
        {...props}
    >
        <div className="pb-4 pt-0">{children}</div>
    </AccordionPrimitive.Content>
);
AccordionContent.displayName = "AccordionContent";

Accordion.Item = AccordionItem;
Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;

export { Accordion };
