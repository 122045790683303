import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "create-questionnaire-modal";

const keys = addTranslations(RESOURCE_NAME, {
    title: {
        en: "Create Question Base",
        fr: "Créer une base de questions",
    },
    select_locales: {
        en: "Select languages...",
        fr: "Sélectionner les langues...",
    },
    select_languages: {
        en: "Select the languages that will be available when creating questions",
        fr: "Sélectionnez les langues qui seront disponibles lors de la création de questions",
    },
    default_language: {
        en: "Select a default language",
        fr: "Sélectionnez une langue par défaut",
    },
    cancel: {
        en: "Cancel",
        fr: "Annuler",
    },
    create: {
        en: "Create",
        fr: "Créer",
    },
    empty_state_button: {
        en: "Create Your Question Base",
        fr: "Créer votre base de questions",
    },
});

export function useCreateQuestionnaireModalTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
