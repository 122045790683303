import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex/index.tsx";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNewFrameworksPage } from "../../data";
import { useFrameworkPageState } from "../../framework/hooks/pageState";
import { FrameworkCardSkeleton } from "../FrameworkCard/FrameworkCardSkeleton";
import {
    NewActiveFrameworkCard,
    NewInactiveFrameworkCard,
} from "../NewFrameworkCard";

export const NewFrameworksPage = () => {
    const { t } = useTranslation("Frameworks");
    const { activeQuestionnaires, inactiveQuestionnaires, isPending } =
        useNewFrameworksPage();
    const { resetDefaultState } = useFrameworkPageState();

    return (
        <FlexCol py="4">
            <Text variant="header2" px="4">
                {t("active_frameworks")}
            </Text>
            <Box p="4" display="grid" className="grid-cols-3 gap-y-4 gap-x-4">
                {!isPending ? (
                    <>
                        {activeQuestionnaires?.map((framework) => {
                            const to = ["csrd", "evs-2023"].includes(
                                framework.cmsId,
                            )
                                ? framework.cmsId
                                : framework.id;
                            return (
                                <Link
                                    className="[all:unset] no-underline ![text-decoration:none]"
                                    onClick={resetDefaultState}
                                    to={to}
                                >
                                    <NewActiveFrameworkCard
                                        frameworkName={framework.name}
                                        frameworkDescription={
                                            framework.description
                                        }
                                        logoUrl={framework.logoUrl}
                                        assignments={framework.assignment}
                                        stats={{
                                            answered: framework.stats.validated,
                                            aiSuggestionSuccess:
                                                framework.stats
                                                    .aiSuggestionSuccess,
                                            missing:
                                                framework.stats.notAnswered,
                                            total: framework.stats.total,
                                        }}
                                    />
                                </Link>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {new Array(3).fill(null).map((_, index) => (
                            <FrameworkCardSkeleton key={index} />
                        ))}
                    </>
                )}
            </Box>
            <Text variant="header2" px="4">
                {t("other_frameworks")}
            </Text>
            <Box p="4" display="grid" className="grid-cols-3 gap-y-4 gap-x-4">
                {inactiveQuestionnaires?.map((framework) => (
                    <NewInactiveFrameworkCard
                        frameworkName={framework.name}
                        frameworkDescription={framework.description}
                        logoUrl={framework.logoUrl}
                        faq={framework.faq}
                        articleLink={framework.articleLink || ""}
                        demoLink={framework.demoLink || ""}
                        websiteLink={framework.websiteLink || ""}
                    />
                ))}
            </Box>
        </FlexCol>
    );
};
