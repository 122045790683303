import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";
import { supPaths } from "@app/routing/supPaths";

const RESOURCE_NAME = "sup-navbar";

const keys = addTranslations(RESOURCE_NAME, {
    [supPaths.questionnaires]: {
        en: "Questionnaires",
        fr: "Questionnaires",
    },
    [supPaths.users]: {
        en: "Users",
        fr: "Utilisateurs",
    },
});

export function useSupNavbarTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
