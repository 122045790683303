import { Logo as BeavrLogo } from "@app/components/AdminNavbar/components/Logo";
import { supPaths } from "@app/routing/supPaths";
import { Navigation } from "@design-system/Navigation/NavBar";
import { type FC, useMemo } from "react";
import { useMatches, useNavigate, useParams } from "react-router-dom";
import { useSupNavbarTranslations } from "./i18n";

const items = [
    {
        id: supPaths.questionnaires,
        iconName: "document",
    },
    {
        id: supPaths.users,
        iconName: "users",
    },
] as const;

export const SupNavbar: FC = () => {
    const { t, keys } = useSupNavbarTranslations();
    const matches = useMatches();
    const navigate = useNavigate();
    const params = useParams();

    const handleSelectedIdChange = (path: string) => {
        navigate(params.sup_id + "/" + path);
    };

    const selectedId = useMemo(() => {
        for (const item of items) {
            if (matches.find((match) => match.pathname.includes(item.id))) {
                return item.id;
            }
        }

        return undefined;
    }, [matches]);

    return (
        <Navigation
            className="relative "
            selectedId={selectedId}
            onSelectedIdChange={handleSelectedIdChange}
        >
            <Navigation.Header>
                <BeavrLogo />
            </Navigation.Header>
            <Navigation.Content isLoading={false}>
                {items.map((item) => (
                    <Navigation.Button
                        key={item.id}
                        id={item.id}
                        iconName={item.iconName}
                        text={t(keys[item.id])}
                    />
                ))}
            </Navigation.Content>
        </Navigation>
    );
};
