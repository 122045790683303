import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { type FC, useState } from "react";
import { useSupplierTemplateQuestionnaire } from "../../data";
import { CreateQuestionnaireModal } from "../CreateQuestionnaireModal";
import { useSupplierQuestionsTableTranslations } from "./i18n";

export const EmptyState: FC = () => {
    const { t, keys } = useSupplierQuestionsTableTranslations();
    const { templateQuestionnaire } = useSupplierTemplateQuestionnaire();

    const [isCreateQuestionnaireModalOpen, setIsCreateQuestionnaireModalOpen] =
        useState(false);

    return (
        <FlexCol
            p="12"
            gap="6"
            alignItems="center"
            justifyContent="center"
            className="bg-secondary text-center"
        >
            <Text variant="body-lg-bold">
                {t(keys.empty_state_title, {
                    context: templateQuestionnaire && "with_questionnaire",
                })}
            </Text>
            <Text variant="body" color="secondary">
                {t(keys.empty_state_sub_title, {
                    context: templateQuestionnaire && "with_questionnaire",
                })}
            </Text>
            {!templateQuestionnaire && (
                <CreateQuestionnaireModal
                    open={isCreateQuestionnaireModalOpen}
                    setOpen={setIsCreateQuestionnaireModalOpen}
                />
            )}
        </FlexCol>
    );
};
