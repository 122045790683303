import i18next from "../../../i18n";

i18next.addResourceBundle("en", "ClickDragNDrop", {
    line_1_single: "Click or drag & drop to add a file.",
    line_1_multiple: "Click or drag & drop to add files.",
    line_2: "We accept documents PDF, JPG, PNG under 30MB.",
    must_provide_document: "You must provide a document",
});

i18next.addResourceBundle("fr", "ClickDragNDrop", {
    line_1_single: "Cliquer ou déposer pour ajouter un fichier.",
    line_1_multiple: "Cliquer ou déposer pour ajouter des fichiers.",
    line_2: "Nous acceptons les documents PDF, JPG, PNG de moins de 30 Mo.",
    must_provide_document: "Vous devez fournir un document",
});
