import { makeDocumentLink } from "@app/screens/Global/AskAIModal/services";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { DocumentCitation, DocumentsQuery } from "@generated/client/graphql";
import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useQuestionsTabTranslations } from "./i18n";

type Document = NonNullable<DocumentsQuery["documents"]>[number];

export const DocumentCitationTooltip: FC<{
    document?: Document;
    documentCitation?: DocumentCitation;
}> = ({ document, documentCitation }) => {
    if (!document) return null;

    const { org_uname } = useParams();
    const { t, keys } = useQuestionsTabTranslations();

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <a
                    href={
                        makeDocumentLink(
                            {
                                type: document.type,
                                uniqueUrl: document.uniqueUrl,
                            },
                            org_uname,
                        ) || undefined
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-inherit cursor-pointer"
                >
                    <Text
                        variant="body-bold"
                        className="text-primary hover:text-beavrGreen"
                    >
                        {document?.name}
                    </Text>
                </a>
            </Tooltip.Trigger>
            <Tooltip.Content variant="wideLight">
                <FlexCol className="gap-3">
                    <FlexCol>
                        <Text variant="body-xs" className="text-secondary">
                            {t(keys.document_citation_tooltip_document_name)}
                        </Text>
                        <Text variant="body-sm" className="text-primary">
                            {documentCitation?.documentFileName}
                        </Text>
                    </FlexCol>
                    <FlexCol>
                        <Text variant="body-xs" className="text-secondary">
                            {t(keys.document_citation_tooltip_content)}
                        </Text>
                        <Text
                            variant="body-sm"
                            className="text-primary max-w-[400px]"
                        >
                            {documentCitation?.text}
                        </Text>
                    </FlexCol>
                    <FlexCol>
                        <Text variant="body-xs" className="text-secondary">
                            {t(keys.document_citation_tooltip_edited)}
                        </Text>
                        <FlexRow className="gap-1">
                            <Avatar
                                variant="circle"
                                size="sm"
                                imageUrl={
                                    document.lastApprovedVersion?.lastEditedBy
                                        ?.avatar?.signedUrl
                                }
                            />
                            <Text variant="body-sm" className="text-primary">
                                {t(keys.last_edited_by, {
                                    date: dayjs(
                                        document.lastApprovedVersion
                                            ?.lastEditedAt,
                                    ).format("DD MMMM YYYY"),
                                    firstName:
                                        document.lastApprovedVersion
                                            ?.lastEditedBy?.firstName,
                                    lastName:
                                        document.lastApprovedVersion
                                            ?.lastEditedBy?.lastName,
                                })}
                            </Text>
                        </FlexRow>
                    </FlexCol>
                </FlexCol>
            </Tooltip.Content>
        </Tooltip>
    );
};
