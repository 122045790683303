import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { HTMLAttributes, Ref, forwardRef } from "react";

const FilterTrigger = forwardRef(
    (
        {
            children,
            unsetCallback,
            isHeader,
            ...props
        }: {} & HTMLAttributes<HTMLDivElement> & {
                active?: boolean;
                unsetCallback?: () => void;
                isHeader?: boolean;
            },
        ref: Ref<HTMLDivElement>,
    ) => {
        return (
            <DropdownPrimitive.Trigger asChild>
                {isHeader ? (
                    <HeaderFilter active={props.active} ref={ref}>
                        {children}
                    </HeaderFilter>
                ) : (
                    <FlexRow
                        className="cursor-pointer"
                        ref={ref}
                        onClick={props.active ? unsetCallback : undefined}
                        {...props}
                    >
                        <Icon name={props.active ? "close" : "angleDown"} />
                        {children}
                    </FlexRow>
                )}
            </DropdownPrimitive.Trigger>
        );
    },
);

export default FilterTrigger;
