import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { type PaginatedSelectedRows } from "@design-system/Table";
import { QuestionnaireStatus, ResponseStatus } from "@generated/client/graphql";
import { useState, type FC } from "react";
import { useCloseQuestionnaires, useSendQuestionnaires } from "../../data";
import { getSelectedRowsCount, getSelectedSupplierIds } from "../../services";
import { type SuppliersMinimalListItem } from "../../types";
import { SendQuestionnaireModal } from "../SendQuestionnaireModal";
import { useSupplierTableTranslations } from "./i18n";

const actions = {
    SEND_QUESTIONNAIRE: "SEND_QUESTIONNAIRE",
    SEND_REMINDER: "SEND_REMINDER",
    CLOSE_QUESTIONNAIRE: "CLOSE_QUESTIONNAIRE",
} as const;

export const ActionBar: FC<{
    unset: () => void;
    paginatedSelectedRows: PaginatedSelectedRows;
    rowCount: number;
    suppliers: SuppliersMinimalListItem[];
    isSuppliersPending: boolean;
}> = ({
    unset,
    paginatedSelectedRows,
    rowCount,
    suppliers,
    isSuppliersPending,
}) => {
    const { closeQuestionnaires, isPending: isCloseQuestionnairePending } =
        useCloseQuestionnaires();

    const { sendQuestionnaires } = useSendQuestionnaires();
    const { setToastSuccess, setToastError } = useSetToast();

    const [activeAction, setActiveAction] = useState<string | undefined>(
        undefined,
    );

    const [isSendQuestionnaireModalOpen, setIsSendQuestionnaireModalOpen] =
        useState<boolean>(false);

    const selectedRowsCount = getSelectedRowsCount(
        paginatedSelectedRows,
        rowCount,
    );

    const selectedSupplierIds = getSelectedSupplierIds(
        paginatedSelectedRows,
        suppliers.map((supplier) => supplier.id),
    );

    const selectedSuppliers = suppliers.filter((supplier) =>
        selectedSupplierIds.includes(supplier.id),
    );

    const selectedSuppliersWithOpenQuestionnaire = selectedSuppliers.filter(
        (supplier) =>
            supplier.lastQuestionnaire?.status === QuestionnaireStatus.Open,
    );

    const isFloatbarOpen = !!selectedRowsCount;

    const isSendQuestionnaireDisabled =
        !!selectedSuppliersWithOpenQuestionnaire.length || isSuppliersPending;

    const onActionValueChange = (action: string[]) => {
        const newAction = action[0];
        if (newAction === actions.SEND_QUESTIONNAIRE) {
            setIsSendQuestionnaireModalOpen(true);
            setActiveAction("");
            return;
        }
        setActiveAction(newAction);
    };

    const handleSendModalConfirm = (questionnaireTagId: string) => {
        sendQuestionnaires(
            {
                supplierIds: selectedSupplierIds,
                questionnaireTagId,
            },
            {
                onSettled: (data, error) => {
                    if (
                        error ||
                        data?.sendQuestionnaires?.status !==
                            ResponseStatus.Success
                    ) {
                        setToastError(
                            t(keys.action_bar_send_questionnaire_error),
                        );
                        return;
                    }
                    setToastSuccess(
                        t(keys.action_bar_send_questionnaire_success),
                    );
                },
            },
        );

        setActiveAction(undefined);
        unset();
    };

    const handleQuestionnaireClose = () => {
        const questionnaireIds = selectedSuppliersWithOpenQuestionnaire
            .map((supplier) => supplier.lastQuestionnaire?.id)
            .filter((s) => typeof s === "string");

        closeQuestionnaires(questionnaireIds, {
            onSettled(data, error) {
                if (
                    error ||
                    data?.closeQuestionnaires?.status !== ResponseStatus.Success
                ) {
                    setToastError(t(keys.action_bar_close_questionnaire_error));
                    return;
                }
                setToastSuccess(t(keys.action_bar_close_questionnaire_success));
            },
        });

        setActiveAction(undefined);
        unset();
    };

    const { t, keys } = useSupplierTableTranslations();

    return (
        <>
            <Floatbar
                unset={unset}
                text={t(keys.action_bar_selected_suppliers, {
                    count: selectedRowsCount,
                })}
                open={isFloatbarOpen}
                primary={
                    <Dropdown
                        value={[activeAction ?? ""]}
                        selectType="single"
                        onValueChange={onActionValueChange}
                    >
                        <Dropdown.Trigger asChild>
                            <Button variant="outlined" size="sm">
                                {t(keys.action_bar_select_action)}
                                <Button.Icon name="angleDown" size="sm" />
                            </Button>
                        </Dropdown.Trigger>
                        <Dropdown.Content>
                            <Dropdown.SelectableItem
                                value={actions.SEND_QUESTIONNAIRE}
                            >
                                <Dropdown.ItemIcon name="plus" />
                                <Dropdown.ItemText
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(keys.action_bar_send_questionnaire)}
                                </Dropdown.ItemText>
                            </Dropdown.SelectableItem>
                            <Dropdown.SelectableItem
                                value={actions.SEND_REMINDER}
                            >
                                <Dropdown.ItemIcon name="calendar" />
                                <Dropdown.ItemText
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(keys.action_bar_send_reminder)}
                                </Dropdown.ItemText>
                            </Dropdown.SelectableItem>
                            <Dropdown.SelectableItem
                                value={actions.CLOSE_QUESTIONNAIRE}
                            >
                                <Dropdown.ItemIcon name="close" />
                                <Dropdown.ItemText
                                    variant="body-sm"
                                    className="text-primary"
                                >
                                    {t(keys.action_bar_close_questionnaire)}
                                </Dropdown.ItemText>
                            </Dropdown.SelectableItem>
                        </Dropdown.Content>
                    </Dropdown>
                }
                actions={[
                    {
                        id: actions.SEND_REMINDER,
                        apply: (
                            <Button size="sm">{t(keys.action_bar_send)}</Button>
                        ),
                        text: t(keys.action_bar_send_reminder, {
                            count: selectedRowsCount,
                        }),
                    },
                    {
                        id: actions.CLOSE_QUESTIONNAIRE,
                        apply: (
                            <Button
                                size="sm"
                                onClick={handleQuestionnaireClose}
                                disabled={isSuppliersPending}
                                loading={isCloseQuestionnairePending}
                            >
                                {t(keys.action_bar_close)}
                            </Button>
                        ),
                        text: t(keys.action_bar_close_questionnaire, {
                            count: selectedSuppliersWithOpenQuestionnaire.length,
                        }),
                    },
                ]}
                activeAction={activeAction}
                setActiveAction={setActiveAction}
            />
            <SendQuestionnaireModal
                open={isSendQuestionnaireModalOpen}
                onOpenChange={setIsSendQuestionnaireModalOpen}
                onConfirm={handleSendModalConfirm}
                disabled={isSendQuestionnaireDisabled}
                disabledTooltip={t(
                    keys.action_bar_send_questionnaire_disabled,
                    { count: selectedSuppliersWithOpenQuestionnaire.length },
                )}
            />
        </>
    );
};
