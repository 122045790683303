import i18next from "@app/i18n";

const enResources = {
    suggestion: "Suggestion: {{suggestion}}",
    generating_suggestion: "Generating...",
    suggestion_error: "AI could not generate meaningful answer",
    table_empty_state_title: "No questions found",
    table_empty_state_subtitle: "Modify your filters or add questions",
    table_header_question: "Question",
    table_header_answer: "Answer",
    table_header_status: "Status",
    table_header_owner: "Owner",
    table_header_evidence: "Evidence",
    table_header_status_dropdown_item_todo: "Todo",
    table_header_status_dropdown_item_validated: "Validated",
    table_header_status_dropdown_item_in_progress: "In progress",
    table_header_groups: "Groups",

    table_owner_assign_no_owner: "Unassigned",

    table_question_type_text: "Text",
    table_question_type_value: "Value",
    table_question_type_single_select: "Single select",
    table_question_type_multi_select: "Multi select",

    table_header_checkbox_disabled:
        "All questions are validated and cannot be modified",
    question_checkbox_disabled:
        "You can't edit a question that has been validated",
    question_table_action_selection: "{{count}} questions selected",
    question_table_action_select_action: "Select action",
    question_table_action_assign_owner: "Assign owner ({{count}})",
    question_table_action_apply: "Apply",
    question_table_action_assign_success: "Data updated successfully",
    question_table_action_assign_error: "Error updating data",
    question_table_action_delete: "Delete ({{count}})",
    question_table_action_delete_success: "Questions deleted successfully",
    question_table_action_delete_error: "Error deleting questions",
    create_questions_modal_success_message: "Question created successfully",
    create_questions_modal_error_message:
        "There was an error while creating the question",

    create_questions_modal_trigger: "Add question",
    create_questions_modal_title: "Add question",
    create_questions_modal_confirm: "Confirm",
    create_questions_modal_cancel: "Cancel",
    create_questions_modal_confirm_disabled_tooltip:
        "Please fill type, name and options if necessary",
    create_questions_modal_add_question: "Add question",
    create_questions_modal_create_more_questions: "Create more questions",
    create_questions_modal_depends_on_other_question:
        "This question is based on the answer of another question",
    create_questions_modal_depends_on_select_question_label: "If question",
    create_questions_modal_depends_on_select_answers_label: "Select answers",
    create_questions_modal_question_type_label: "Question type",
    create_questions_modal_question_name_label: "Question",
    create_questions_modal_question_name_placeholder:
        "Example: Total CO2 emissions in the current year",
    create_questions_modal_question_description_label: "Description",
    create_questions_modal_question_type_dd_label: "Type",
    create_questions_modal_question_type_dd_item_label_text: "Text",
    create_questions_modal_question_type_dd_item_label_value: "Value",
    create_questions_modal_question_type_dd_item_label_single_select:
        "Single select",
    create_questions_modal_question_type_dd_item_label_multi_select:
        "Multi select",
    create_questions_modal_dependency_description:
        "This question depends on the answer of another question",

    create_questions_modal_options_block_label: "Option {{ index }}",
    create_questions_modal_options_block_add_option: "Add option",

    create_questions_modal_question_unit_label: "Unit",
    edit_question_modal_title: "Edit question",
    question_dependent_tooltip:
        "This question appears based on the answer to the previous question",
    regenerate_answer_button: "Regenerate answer",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    suggestion: "Suggestion: {{suggestion}}",
    generating_suggestion: "Génération...",
    suggestion_error: "Impossible de générer une réponse pertinente",
    table_empty_state_title: "Aucune question trouvée",
    table_empty_state_subtitle: "Modifier vos filtres ou ajouter des questions",
    table_header_question: "Question",
    table_header_answer: "Réponse",
    table_header_status: "Statut",
    table_header_owner: "Propriétaire",
    table_header_evidence: "Justificatifs",
    table_header_status_dropdown_item_todo: "À faire",
    table_header_status_dropdown_item_validated: "Validé",
    table_header_status_dropdown_item_in_progress: "En cours",
    table_header_groups: "Groupes",
    table_owner_assign_no_owner: "Non assigné",

    table_question_type_text: "Texte",
    table_question_type_value: "Valeur",
    table_question_type_single_select: "Choix unique",
    table_question_type_multi_select: "Choix multiple",

    table_header_checkbox_disabled:
        "Toutes les questions sont validées et ne peuvent pas être modifiées",
    question_checkbox_disabled:
        "Vous ne pouvez pas modifier une question qui a été validée",
    question_table_action_selection: "{{count}} questions sélectionnées",
    question_table_action_select_action: "Sélectionner une action",
    question_table_action_assign_owner: "Assigner un propriétaire ({{count}})",
    question_table_action_apply: "Appliquer",
    question_table_action_assign_success: "Données mises à jour avec succès",
    question_table_action_assign_error:
        "Erreur lors de la mise à jour des données",
    question_table_action_delete: "Supprimer ({{count}})",
    question_table_action_delete_success: "Questions supprimées avec succès",
    question_table_action_delete_error:
        "Erreur lors de la suppression des questions",
    question_dependent_tooltip:
        "Cette question apparaît en fonction de la réponse à la question précédente",
    create_questions_modal_success_message: "Question créée avec succès",
    create_questions_modal_error_message:
        "Il y a eu une erreur lors de la création de la question",

    create_questions_modal_trigger: "Ajouter des questions",
    create_questions_modal_title: "Ajouter une question",
    create_questions_modal_confirm: "Confirmer",
    create_questions_modal_cancel: "Annuler",
    create_questions_modal_confirm_disabled_tooltip:
        "Veuillez remplir type, nom et options si nécessaire",
    create_questions_modal_add_question: "Rajouter une question",
    create_questions_modal_create_more_questions:
        "Créer une autre question de suite",
    create_questions_modal_depends_on_other_question:
        "Cette question est basée sur la réponse d'une autre question",
    create_questions_modal_depends_on_select_question_label: "Si question",
    create_questions_modal_depends_on_select_answers_label:
        "Sélectionner les réponses",
    create_questions_modal_question_type_label: "Type de question",
    create_questions_modal_question_name_label: "Question",
    create_questions_modal_question_description_label: "Description",
    create_questions_modal_question_name_placeholder:
        "Exemple: Total des émissions de carbone en l'année en cours",
    create_questions_modal_question_type_dd_label: "Type",
    create_questions_modal_question_type_dd_item_label_text: "Texte",
    create_questions_modal_question_type_dd_item_label_value: "Valeur",
    create_questions_modal_question_type_dd_item_label_single_select:
        "Sélection unique",
    create_questions_modal_question_type_dd_item_label_multi_select:
        "Sélection multiple",
    create_questions_modal_dependency_description:
        "Cette question dépend de la réponse d'une autre question",

    create_questions_modal_options_block_label: "Option {{ index }}",
    create_questions_modal_options_block_add_option: "Ajouter une option",

    edit_question_modal_title: "Modifier la question",

    create_questions_modal_question_unit_label: "Unité",
    regenerate_answer_button: "Re-générer la réponse",
};

export const RESOURCE_NAME = "OverviewTab";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
