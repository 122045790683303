import { useS3FilesManagement } from "@app/hooks/createFileHook";
import TagListEditor from "@app/shared/components/TagListEditor";
import { Button } from "@design-system/Inputs/Button";
import DropZone from "@design-system/Inputs/DropZone";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { TagType } from "@generated/client/graphql";
import { type FC, useState } from "react";
import {
    useCreateSupplierTemplateQuestionsFromFile,
    useSupplierTemplateQuestionnaire,
} from "../../data";
import { useImportQuestionsFromFileModalTranslations } from "./i18n";

const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
];

export const ImportQuestionsFromFileModal: FC = () => {
    const { t, keys } = useImportQuestionsFromFileModalTranslations();
    const { templateQuestionnaire } = useSupplierTemplateQuestionnaire();
    const { uploadFiletoS3 } = useS3FilesManagement();
    const [isOpen, setIsOpen] = useState(false);

    const [tagIds, setTagIds] = useState<string[]>([]);

    const {
        createQuestionsFromFileAsync,
        isPending: isImportingFromFilePending,
    } = useCreateSupplierTemplateQuestionsFromFile();

    const handleFileDrop = async (file: File) => {
        const uploadedFile = await uploadFiletoS3(file);
        await createQuestionsFromFileAsync({
            s3File: uploadedFile,
            tagIds: tagIds,
        });
        setIsOpen(false);
    };

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <Modal.Trigger asChild>
                <Button
                    variant="tonal"
                    loading={
                        isImportingFromFilePending ||
                        !!templateQuestionnaire?.isGenerating
                    }
                >
                    <Button.Icon name="upload" />
                    {t(keys.trigger_label)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay>
                <Modal.Content>
                    <Modal.Header withClose>
                        <FlexRow gap="4">
                            <FlexCol gap="2">
                                <Modal.Title>{t(keys.title)}</Modal.Title>
                            </FlexCol>
                        </FlexRow>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            <FlexCol gap="2">
                                <Text>{t(keys.add_tags_label)}</Text>
                                <TagListEditor
                                    values={tagIds}
                                    onValuesChange={setTagIds}
                                    tagType={TagType.Questionnaire}
                                />
                            </FlexCol>
                            {isImportingFromFilePending ? (
                                <FlexCol
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="2"
                                    className="min-h-[200px]"
                                >
                                    <Text
                                        className="animate-pulse"
                                        color="brand"
                                    >
                                        {t(keys.dropzone_analyzing_file)}
                                    </Text>
                                    <div className="loader" />
                                </FlexCol>
                            ) : tagIds.length ? (
                                <DropZone
                                    accept={acceptedFileTypes}
                                    buttonText={t(keys.dropzone_btn_label)}
                                    multiple={false}
                                    onChange={handleFileDrop}
                                    size={"lg"}
                                    loading={
                                        isImportingFromFilePending ||
                                        !!templateQuestionnaire?.isGenerating
                                    }
                                    className="w-full h-full"
                                >
                                    {t(keys.dropzone_placeholder)}
                                    <br />
                                    {t(keys.dropzone_accepted_file_types)}
                                </DropZone>
                            ) : null}
                        </FlexCol>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
