/**
 * Implements this rfc, which is likley never gonna be implemented in react: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 * inspired by and mixing up the following implementations: 
 *  - https://github.com/Volune/use-event-callback/blob/master/src/index.ts,
 *  - https://github.com/scottrippey/react-use-event-hook/blob/main/src/useEvent.ts
 */
import { useCallback, useInsertionEffect, useRef } from "react";

type Fn<ARGS extends any[], R> = (...args: ARGS) => R;

/**
 * Similar to useCallback, with a few subtle differences:
 * - The returned function is a stable reference, and will always be the same between renders
 * - No dependency lists required
 * - Properties or state accessed within the callback will always be "current"
 */
export function useStableCallback<A extends any[], R>(
    callback: Fn<A, R>,
): Fn<A, R> {
    // Keep track of the latest callback:
    const latestRef = useRef<Fn<A, R>>(
        useStableCallback_shouldNotBeInvokedBeforeMount as any,
    );
    useInsertionEffect(() => {
        latestRef.current = callback;
    }, [callback]);

    // Create a stable callback that always calls the latest callback:
    // using useRef instead of useCallback avoids creating and empty array on every render
    return useCallback<Fn<A, R>>(
        ((...args: A): R => {
            return latestRef.current?.(...args);
        }) as Fn<A, R>,
        [],
    );
}

/**
 * Render methods should be pure, especially when concurrency is used,
 * so we will throw this error if the callback is called while rendering.
 */
function useStableCallback_shouldNotBeInvokedBeforeMount() {
    throw new Error(
        "INVALID_USESTABLECALLBACK_INVOCATION: the callback from useStableCallback cannot be invoked before the component has mounted.",
    );
}
