import { GraphQLSupplierRepository } from "@app/repositories/GraphQLRepositories/SupplierRepository";
import { useQuery } from "@tanstack/react-query";

const supplierRepository = new GraphQLSupplierRepository();
export const useSupplierQuestionnaires = () => {
    const query = useQuery({
        queryKey: ["supplierQuestionnaires"],
        queryFn:
            supplierRepository.getSupplierQuestionnaireTemplates_deprecated,
    });
    return {
        questionnaires: query.data?.supplierQuestionnaireTemplates,
        isLoading: query.isLoading,
    };
};
