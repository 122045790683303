import {
    getFlagByLocale,
    RESOURCE_NAME,
    SupportedLocales,
} from "@app/shared/utils/locales";
import { Icon } from "@design-system/Icon";
import { Flex } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import * as Tabs from "@radix-ui/react-tabs";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

type LocaleTabsProps = {
    locale: SupportedLocales;
    onLocaleChange: (l: SupportedLocales) => void;
    availableLocales: SupportedLocales[];
};

const LocaleTabs: FC<LocaleTabsProps> = ({
    locale,
    onLocaleChange,
    availableLocales,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const handleLocaleChange = (l: string) => {
        onLocaleChange(l as SupportedLocales);
    };

    return (
        <Tabs.Root value={locale} onValueChange={handleLocaleChange}>
            <Tabs.List>
                {availableLocales.map((loc) => (
                    <Tabs.Trigger
                        key={loc}
                        value={loc}
                        className="px-2.5 py-1.5 border-l border-t border-b last:border-r data-[state=active]:border-b-transparent border-tertiary bg-tertiary data-[state=active]:bg-secondary   first:rounded-tl-lg last:rounded-tr-lg"
                    >
                        <Flex className="gap-1.5" alignItems="center">
                            <Icon size="sm" name={getFlagByLocale(loc)} />
                            <Text variant="body-sm">{t(loc)}</Text>
                        </Flex>
                    </Tabs.Trigger>
                ))}
            </Tabs.List>
        </Tabs.Root>
    );
};

export default LocaleTabs;
