import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "user-card";

const keys = addTranslations(RESOURCE_NAME, {
    no_department: {
        en: "No department",
        fr: "Aucun département",
    },
});

export function useUserCardTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
