import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "ai-loader";

const keys = addTranslations(RESOURCE_NAME, {
    loader: {
        en: "Thinking",
        fr: "En réflexion",
    },
});

export function useAiLoaderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
