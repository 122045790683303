import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { type FC, useState } from "react";
import { useSendQuestionnaireModalTranslations } from "./i18n";
import { SelectQuestionnaireDropdown } from "./SelectQuestionnaireDropdown";

export const SendQuestionnaireModal: FC<{
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onConfirm: (questionnaireTagId: string) => void;
    disabled?: boolean;
    disabledTooltip?: string;
}> = ({ onConfirm, open, onOpenChange, disabled, disabledTooltip }) => {
    const [selectedQuestionnaireTagId, setSelectedQuestionnaireTagId] =
        useState<string>("");

    const { t, keys } = useSendQuestionnaireModalTranslations();

    const handleConfirmClick = () => {
        if (!selectedQuestionnaireTagId) return;
        onConfirm(selectedQuestionnaireTagId);
    };

    return (
        <Modal open={open} onOpenChange={onOpenChange}>
            <Modal.Overlay scrollable>
                <Modal.Content className="w-fit min-w-[480px]">
                    <Modal.Header>
                        <Modal.Title>{t(keys.modal_title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="2">
                            <Text>
                                {t(keys.select_questionnaire_placeholder)}
                            </Text>
                            <SelectQuestionnaireDropdown
                                selectedQuestionnaireTagId={
                                    selectedQuestionnaireTagId
                                }
                                setSelectedQuestionnaireTagId={
                                    setSelectedQuestionnaireTagId
                                }
                            />
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(keys.cancel_button)}
                        </Modal.CancelCTA>
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <div>
                                    <Modal.ConfirmCTA
                                        disabled={
                                            !selectedQuestionnaireTagId ||
                                            disabled
                                        }
                                        onClick={handleConfirmClick}
                                    >
                                        {t(keys.send_button)}
                                    </Modal.ConfirmCTA>
                                </div>
                            </Tooltip.Trigger>
                            {disabled && disabledTooltip && (
                                <Tooltip.Content>
                                    {disabledTooltip}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
