import { cn } from "@app/lib/utils";
import { isQuestionGenerating } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/services";
import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon, type IconName } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { type ColumnDef } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { QuestionType } from "@generated/client/graphql";
import { useMemo, useOptimistic, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useGenerateAiSuggestion } from "../../../../QuestionsTab/data";
import { RESOURCE_NAME, keys } from "../../../i18n";

const AnswerIcon: FC<{
    name: IconName;
    answerType: "suggestion" | "answer";
    tooltip?: string;
    color?: string;
    borderColor?: string;
}> = ({ name, answerType, tooltip, color, borderColor }) => {
    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <Box
                    className={cn(
                        "grid place-items-center",
                        "size-5",
                        "rounded-lg",
                        borderColor && `border border-${borderColor}`,
                        color ||
                            (answerType === "suggestion"
                                ? "bg-accent-1-50 text-accent-1-300"
                                : "bg-tertiary text-secondary"),
                    )}
                >
                    <Icon name={name} size="sm" />
                </Box>
            </Tooltip.Trigger>
            {tooltip && <Tooltip.Content>{tooltip}</Tooltip.Content>}
        </Tooltip>
    );
};
const questionTypeToIconName: Record<QuestionType, IconName> = {
    [QuestionType.MultiSelect]: "listCheck",
    [QuestionType.SingleSelect]: "listCheck",
    [QuestionType.Text]: "type",
    [QuestionType.Value]: "pilcrow",
};

const questionTypeToTooltip: Record<QuestionType, string> = {
    [QuestionType.MultiSelect]: keys.table_question_type_multi_select,
    [QuestionType.SingleSelect]: keys.table_question_type_single_select,
    [QuestionType.Text]: keys.table_question_type_text,
    [QuestionType.Value]: keys.table_question_type_value,
};

export const answerColumn: ColumnDef<QuestionnaireOverviewData[number]> = {
    id: "answer",
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.table_header_answer);
    },
    cell: function RowCell({ row }) {
        const { t } = useTranslation(RESOURCE_NAME);
        const aiSuggestion = row.original.answer?.aiSuggestion;
        const rawAnswer = row.original.answer?.value;
        const questionType = row.original.type;

        const isGenerating = isQuestionGenerating(row.original);

        const options = rawAnswer?.options?.map((option: number) => {
            return row.original.options?.[option];
        });

        const answer =
            rawAnswer?.text ?? rawAnswer?.value ?? options?.join(", ");

        const suggestionSuccess = aiSuggestion?.success;
        const textSuggestion =
            row?.original?.type === QuestionType.Text ||
            row?.original?.type === QuestionType.Value
                ? aiSuggestion?.value
                : row?.original?.type === QuestionType.SingleSelect
                  ? (row?.original?.options?.find(
                        (_, idx) => idx == aiSuggestion?.value,
                    ) ?? "")
                  : (row?.original?.options
                        ?.filter((_, idx) => aiSuggestion?.value?.includes(idx))
                        ?.map((option) => option)
                        .join(", ") ?? "");

        const { generateAiSuggestion, isPending } = useGenerateAiSuggestion(
            row.original.id,
        );

        const [
            isOptimisticSuggestionLoading,
            setIsOptimisticSuggestionLoading,
        ] = useOptimistic(isGenerating);

        const handleSuggestButtonClick = () => {
            generateAiSuggestion(row.original.id, {
                onError: () => {
                    setIsOptimisticSuggestionLoading(false);
                },
            });
            setIsOptimisticSuggestionLoading(true);
        };

        const {
            icon,
            text,
            textColor,
            showRetry,
        }: {
            icon: {
                name: IconName;
                answerType: "suggestion" | "answer";
                tooltip?: string;
                color?: string;
                borderColor?: string;
            };
            text: string;
            textColor: string;
            showRetry: boolean;
        } = useMemo(() => {
            if (isOptimisticSuggestionLoading || isPending) {
                return {
                    icon: {
                        name: "magicWand",
                        answerType: "suggestion",
                        tooltip: t(keys.generating_suggestion),
                    },
                    text: t(keys.generating_suggestion),
                    textColor: "text-accent-1-300",
                    showRetry: false,
                };
            }

            if (answer != null) {
                return {
                    icon: {
                        name: questionTypeToIconName[questionType],
                        answerType: "answer",
                        tooltip: t(questionTypeToTooltip[questionType]),
                    },
                    text: answer,
                    textColor: "text-primary",
                    showRetry: false,
                };
            }

            if (suggestionSuccess && textSuggestion != null) {
                return {
                    icon: {
                        name: questionTypeToIconName[questionType],
                        answerType: "suggestion",
                        tooltip: t(questionTypeToTooltip[questionType]),
                    },
                    text: t(keys.suggestion, { suggestion: textSuggestion }),
                    textColor: "text-accent-1-300",
                    showRetry: false,
                };
            }

            if (typeof suggestionSuccess === "boolean" && !suggestionSuccess) {
                return {
                    icon: {
                        name: "warning",
                        answerType: "suggestion",
                        tooltip: t(keys.suggestion_error),
                        color: "bg-[#DBA600]/10 text-[#DBA600]",
                        borderColor: "warning-600",
                    },
                    text: t(keys.regenerate_answer_button),
                    textColor: "text-[#DBA600] font-bold",
                    showRetry: true,
                };
            }

            return {
                icon: {
                    name: questionTypeToIconName[questionType],
                    answerType: "answer",
                },
                text: "-",
                textColor: "text-primary",
                showRetry: false,
            };
        }, [
            answer,
            suggestionSuccess,
            textSuggestion,
            questionType,
            t,
            isOptimisticSuggestionLoading,
            isPending,
        ]);

        return (
            <FlexRow alignItems="center" gap="1">
                <FlexRow alignItems="center" gap="1">
                    <AnswerIcon
                        name={icon.name}
                        answerType={icon.answerType}
                        tooltip={icon.tooltip}
                        color={icon.color}
                        borderColor={icon.borderColor}
                    />
                    <Ellipsis lineClamp={2} hasTooltip asChild>
                        <Text className={textColor}>{text}</Text>
                    </Ellipsis>
                </FlexRow>
                {showRetry && (
                    <Button
                        variant="text"
                        size="sm"
                        onClick={handleSuggestButtonClick}
                        disabled={!!isOptimisticSuggestionLoading}
                        className="text-[#DBA600]"
                    >
                        <Icon name="refresh" size="sm" />
                    </Button>
                )}
            </FlexRow>
        );
    },
    meta: {
        cellClassName: "max-w-[300px]",
        headerClassName: "max-w-[300px]",
        colWidth: "300px",
    },
};
