import { FlexCol } from "@design-system/Layout/Flex";
import { type FC } from "react";
import { LastQuestionnaireBox } from "../LastQuestionnaireBox";
import { QuestionnairesTable } from "../QuestionnairesTable";
import { useParams } from "react-router-dom";

export const QuestionnairesTab: FC = () => {
    const { supplier_id } = useParams();
    return (
        <FlexCol gap="4">
            <LastQuestionnaireBox supplierId={supplier_id} />
            <QuestionnairesTable supplierId={supplier_id} />
        </FlexCol>
    );
};
