import { GraphQLS3FileRepository } from "@app/repositories/GraphQLRepositories/S3FileRepository";
import {
    enrichS3File,
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { FileUsage } from "@generated/client/graphql";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

const fileRepository = new GraphQLS3FileRepository();

const uploadFileContentToS3 = async (putUrl: string, file: File) => {
    return await fetch(putUrl, {
        method: "PUT",
        body: file,
        headers: {
            "Content-Length": file.size.toString(),
            "Access-Control-Allow-Origin": "no-cors",
        },
    });
};

export const useS3FilesManagement = () => {
    const { mutateAsync } = useMutation({
        mutationFn: fileRepository.createOneS3File,
    });
    const { mutateAsync: mutateAsyncUploadManyFiles } = useMutation({
        mutationFn: fileRepository.createManyS3file,
    });
    const { mutateAsync: createManyFilesAsContributorAsync } = useMutation({
        mutationFn: fileRepository.createManyS3fileAsContributor,
    });

    const [isLoading, setIsLoading] = useState(false);

    const uploadFiletoS3 = async (rawFile: File): Promise<NewS3File> => {
        setIsLoading(true);

        const file = sanitizeFile(rawFile);

        const data = await mutateAsync(file.name);
        if (data?.createEmptyS3File.putUrl)
            await uploadFileContentToS3(
                data?.createEmptyS3File.putUrl,
                file,
            ).then(() => {
                setIsLoading(false);
            });

        return enrichS3File(file, data?.createEmptyS3File);
    };

    const uploadFilesToS3 = async (rawFiles: File[]): Promise<NewS3File[]> => {
        setIsLoading(true);

        const files = rawFiles.map(sanitizeFile);

        const data = await mutateAsyncUploadManyFiles(files.map((f) => f.name));
        data?.createManyEmptyS3Files.forEach(async (s3File, index) => {
            if (s3File.putUrl) {
                await uploadFileContentToS3(s3File.putUrl, files[index]);
            }
        });
        const res = await Promise.all(
            files.map((file, idx) =>
                enrichS3File(file, data?.createManyEmptyS3Files[idx]),
            ),
        );
        setIsLoading(false);
        return res;
    };

    const uploadFilesToS3AsContributor = async (
        rawFiles: File[],
        userId?: string,
        questionnaireId?: string,
    ): Promise<NewS3File[]> => {
        setIsLoading(true);

        const files = rawFiles.map(sanitizeFile);

        const data = await createManyFilesAsContributorAsync({
            filenames: files.map((f) => f.name),
            userId: userId,
            questionnaireId: questionnaireId,
        }).then((res) => {
            res.createS3FilesAsContributor.forEach(async (file, index) => {
                await uploadFileContentToS3(file.putUrl, files[index]);
            });
            return res;
        });
        const res = await Promise.all(
            files.map((file, idx) =>
                enrichS3File(file, data?.createS3FilesAsContributor[idx]),
            ),
        );
        setIsLoading(false);
        return res;
    };

    return {
        isLoading,
        uploadFiletoS3,
        uploadFilesToS3,
        uploadFilesToS3AsContributor,
    };
};

export const useCreateFile = () => {
    const { mutateAsync } = useMutation({
        mutationFn: fileRepository.createOneFile,
    });

    const createFile = async (
        newS3File: NewS3File,
        opts: { usage?: FileUsage } = {},
    ) => {
        const input = newS3FileToCreateFileInput(newS3File);
        if (input) {
            return await mutateAsync({ ...input, ...opts });
        }
    };

    return createFile;
};

/**
 * Remove special characters from file name.
 * We do it in the frontend because we call
 * several methods here, and some don't even
 * pass from the backend but call directly
 * the S3 bucket.
 */
function sanitizeFile(file: File) {
    if (!file?.name) {
        throw new Error("Cannot upload file with empty name");
    }
    const escapedName = encodeURIComponent(file.name);
    return new File([file], escapedName, { type: file.type });
}
