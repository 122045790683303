import Loading from "@app/components/Loading";
import { Box } from "@design-system/Layout/Box";
import { type FC } from "react";
import { useSupplier } from "../../data";
import { UserCard } from "../UserCard";

export const UserTab: FC<{ supplierId?: string }> = ({ supplierId }) => {
    const { supplier, isPending } = useSupplier(supplierId);
    return (
        <Box display="grid" className="grid-cols-4 gap-y-4 gap-x-2">
            {isPending ? (
                <Loading size="large" />
            ) : (
                (supplier?.users ?? []).map((user) => (
                    <UserCard user={user} key={user.id} />
                ))
            )}
        </Box>
    );
};
