import { calculatePercentage, roundNumber } from "@app/shared/utils/math";
import { type Question, type Stats, type AnswerValue } from "./types";
import {
    AnswerStatus,
    type GetSupplierQuestionnaireQuery,
} from "@generated/client/graphql";
import { isSupportedLocale } from "@app/shared/utils/locales";

export const defaultStats: Stats = {
    answered: {
        count: 0,
        percentage: 0,
    },
    total: {
        count: 0,
    },
};

export function makeStats(
    {
        total = 0,
        answered = 0,
    }: {
        total?: number;
        answered?: number;
    } = {
        total: 0,
        answered: 0,
    },
) {
    return {
        answered: {
            count: answered,
            percentage: roundNumber(calculatePercentage(answered, total)),
        },
        total: {
            count: total,
        },
    } satisfies Stats;
}

export function makeQuesitonnaire(
    questionnaire: GetSupplierQuestionnaireQuery["supplierQuestionnaire2"],
) {
    if (!questionnaire) {
        return undefined;
    }
    if (!questionnaire.locale) {
        throw new Error("Locale not found");
    }
    if (!isSupportedLocale(questionnaire.locale.default)) {
        throw new Error("Default locale not supported");
    }
    if (!questionnaire.locale.availableLocales.every(isSupportedLocale))
        throw new Error("Available locales not supported");

    return {
        ...questionnaire,
        locale: {
            defaultLocale: questionnaire.locale.default,
            availableLocales: questionnaire.locale.availableLocales,
        },
        stats: makeStats(questionnaire.stats ?? {}),
    };
}

function updateHasValue<
    T extends { value?: AnswerValue | null; comment?: string },
>(update: T): boolean {
    const value = update.value;

    if (value == null) return false;
    if ("text" in value) {
        return !!value.text;
    };
    if ("num" in value) {
        return typeof value.num === "number";
    };

    if ("options" in value) {
        return !!value.options?.length;
    };

    return false;
}

export const answerOptimisticUpdateFn = (
    {
        prevAnswer,
        questionId,
    }: { prevAnswer: Question["answer"]; questionId: string },
    update: { value: AnswerValue } | { comment: string },
) => {
    const hasValue = updateHasValue(update);
    
    const status = hasValue
        ? AnswerStatus.Answered
        : AnswerStatus.Unknown;

    return {
        ...prevAnswer,
        ...update,
        status,
        questionId,
    };
};
