import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "sup-users";

const keys = addTranslations(RESOURCE_NAME, {
    header_title: {
        en: "Users",
        fr: "Utilisateurs",
    },
    header_description: {
        en: "Check, add or remove users for {{supplier_name}}. Added users will be able to answer the questionnaires.",
        fr: "Vérifier, ajouter ou supprimer des utilisateurs pour {{supplier_name}}. Les utilisateurs ajoutés pourront répondre aux questionnaires.",
    },
});

export function useSupplierUsersTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
