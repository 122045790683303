import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import { type ClassValue } from "class-variance-authority/types";
import { SearchIcon } from "lucide-react";
import { FC, PropsWithChildren } from "react";

const SearchBar: FC<
    PropsWithChildren<{
        searchString: string | undefined;
        setSearchString: (value: string | undefined) => void;
        placeholder?: string;
        className?: ClassValue;
    }>
> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setSearchString(e.target.value.trimStart() || undefined);
    };
    const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
        e.stopPropagation();
    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.stopPropagation();
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.stopPropagation();

    return (
        <FlexRow
            p="1"
            br="lg"
            alignItems="center"
            h="7"
            className={cn(
                "border space-x-1 min-w-[160px] transition-all",
                props.searchString ? "border-beavrGreen" : "border-secondary",
                props.className,
            )}
        >
            <Box p="0.5">
                <SearchIcon
                    className={cn(
                        "w-3 h-3",
                        props.searchString
                            ? "text-beavrGreen"
                            : "text-secondary",
                    )}
                />
            </Box>
            <input
                className="w-full border-0 p-0 focus:outline-none focus:ring-0"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                value={props.searchString || ""}
                onChange={onChange}
                placeholder={props.placeholder || ""}
                onClick={handleClick}
            />
        </FlexRow>
    );
};
export default SearchBar;
