import { GetObjectivesWithDetailsQuery } from "@generated/client/graphql";
import { useMemo } from "react";

export const useFilteredObjectives = (
    objectives: GetObjectivesWithDetailsQuery["objectives"],
    searchString: string,
) => {
    return useMemo(() => {
        if (!searchString) return objectives;
        const lowerCaseSearch = searchString.toLowerCase();

        return objectives?.filter(
            ({
                name = "",
                description = "",
                actionPlan = "",
                subtheme,
                theme,
            }) =>
                [name, description, actionPlan, subtheme?.name, theme?.name]
                    .filter(Boolean)
                    .some((field) =>
                        field?.toLowerCase().includes(lowerCaseSearch),
                    ),
        );
    }, [objectives, searchString]);
};
