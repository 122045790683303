import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "external-questionnaire-header";

const keys = addTranslations(RESOURCE_NAME, {
    stats_total: {
        en: "{{count}} questions",
        fr: "{{count}} questions",
    },
    stats_total_singular: {
        en: "{{count}} question",
        fr: "{{count}} question",
    },
    stats_validated: {
        en: "{{count}} validated",
        fr: "{{count}} validé",
    },
    stats_non_validated: {
        en: "{{count}} non validated",
        fr: "{{count}} non validé",
    },
    stats_remaining_weeks: {
        en: "{{count}} weeks remaining",
        fr: "{{count}} semaines restantes",
    },
    stats_remaining_singular_week: {
        en: "{{count}} week remaining",
        fr: "{{count}} semaine restante",
    },
    stats_overdue: {
        en: "Overdue",
        fr: "En retard",
    },
});
export function useQuestionnaireHeaderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);

    return {
        t,
        keys,
    };
}
