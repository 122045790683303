import { type Ref, type FC, type PropsWithChildren } from "react";
import { Text } from "@design-system/Typography/Text";
import { FlexRow, type FlexProps } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";

const BannerText: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text variant="body-sm-bold" color="primary">
            {children}
        </Text>
    );
};

export const Banner: FC<
    FlexProps &
        PropsWithChildren<{
            show: boolean;
            ref?: Ref<HTMLDivElement>;
        }>
> & {
    Text: typeof BannerText;
} = ({ children, className, show, ...props }) => {
    return show ? (
        <FlexRow
            justifyContent="center"
            alignItems="center"
            alignSelf="stretch"
            px="3"
            py="1.5"
            h="8"
            className={cn("bg-warning-500", className)}
            {...props}
        >
            {children}
        </FlexRow>
    ) : null;
};

Banner.Text = BannerText;
