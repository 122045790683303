import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useState, type FC } from "react";

import { Spinner } from "@app/components/TipTap/components/ui/Spinner";
import {
    makeDocumentLink,
    makeIndicatorLink,
} from "@app/screens/Global/AskAIModal/services";
import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { FileIcon } from "@app/shared/components/FileIcon";
import { Button } from "@design-system/Inputs/Button";
import { cn } from "@design-system/Utilities";
import { BooleanEnum } from "@generated/client/graphql";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    useGenerateAiSuggestion,
    useGetDocuments,
    useGetIndicators,
} from "../../data";
import {
    isBoolDatapoint,
    isNarrativeDatapoint,
    isReportingDatapoint,
    isTableDatapoint,
} from "../../services";
import { PillarDatapoint } from "../../types";
import "./i18n";
import { useDatapoint } from "./useDatapoint";

export const DatapointAiSuggestion: FC<{
    datapoint: PillarDatapoint;
}> = ({ datapoint }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const { datapointState: state, handleChange } = useDatapoint(datapoint);
    const [isAiSuggestionOpen, setIsAiSuggestionOpen] = useState(false);
    const { org_uname } = useParams();
    const showAiSuggestion = useFeatureFlagEnabled("ai-suggestions");
    const { mutate: generateAiSuggestion, isPending: isSuggestPending } =
        useGenerateAiSuggestion();
    const handleGenerateAiSuggestion = () => {
        generateAiSuggestion(datapoint.id);
    };
    const handleConfirmClick = () => {
        const newFileIds = (suggestedDocuments
            .map((doc) => doc?.lastApprovedVersion?.finalFile?.id)
            .filter(Boolean) ?? []) as string[];
        const existingFileIds =
            datapoint?.evidenceFiles?.map((f) => f.id) ?? [];
        const evidenceFileIdsChangeArg = datapoint?.aiSuggestion
            ?.documentCitations?.length
            ? {
                  connectEvidenceFileIds: Array.from(
                      new Set([...existingFileIds, ...newFileIds]),
                  ),
              }
            : {};
        if (isNarrativeDatapoint(datapoint)) {
            const cleanText = aiSuggestionContent?.value;
            state.setValueString(cleanText);
            state.setValueNoData(false);
            handleChange({
                value: {
                    noData: BooleanEnum.False,
                    string: cleanText,
                },
                ...evidenceFileIdsChangeArg,
            });
        }
        if (isBoolDatapoint(datapoint)) {
            const cleanText = aiSuggestionContent?.value;
            const boolValue =
                aiSuggestionContent?.value === true
                    ? BooleanEnum.True
                    : aiSuggestionContent?.value === false
                      ? BooleanEnum.False
                      : BooleanEnum.Unknown;
            state.setValueBool(boolValue);
            state.setComment(cleanText);
            handleChange({
                value: {
                    noData: BooleanEnum.False,
                    boolean: boolValue,
                },
                comment: cleanText,
                ...evidenceFileIdsChangeArg,
            });
        }
        if (isReportingDatapoint(datapoint) && isTableDatapoint(datapoint)) {
            const cleanText = aiSuggestionContent?.value ?? "";
            state.setComment(cleanText);
            handleChange({
                comment: cleanText,
                ...evidenceFileIdsChangeArg,
            });
        }
        if (isReportingDatapoint(datapoint) && !isTableDatapoint(datapoint)) {
            const cleanText = aiSuggestionContent?.value ?? "";
            state.setValueNumber(aiSuggestionContent?.value ?? 0);
            state.setComment(cleanText);
            handleChange({
                value: {
                    number: aiSuggestionContent?.value,
                },
                comment: cleanText,
                ...evidenceFileIdsChangeArg,
            });
        }

        setIsAiSuggestionOpen(false);
    };
    const { documents: suggestedDocuments } = useGetDocuments(
        datapoint?.aiSuggestion?.documentCitations?.map(
            (c: { documentId: string }) => c.documentId,
        ) ?? [],
    );

    const aiSuggestionContent = datapoint?.aiSuggestion;

    const suggestedIndicatorsIds = isReportingDatapoint(datapoint)
        ? (aiSuggestionContent?.indicatorCitations
              ?.map(({ indicatorId }) => {
                  const split = indicatorId.split("_");
                  return split?.length ? split[0] : null;
              })
              .filter((id) => typeof id === "string") ?? [])
        : [];

    const { indicators: suggestedIndicators } = useGetIndicators(
        suggestedIndicatorsIds,
    );

    const handleSuggestButtonClick = () => {
        generateAiSuggestion(datapoint.id, {
            onSettled: () => {
                setIsAiSuggestionOpen(true);
            },
        });
    };

    return (
        showAiSuggestion && (
            <AiSuggestion
                isOpen={isAiSuggestionOpen}
                isLoading={isSuggestPending}
                className="flex flex-col justify-center align-middle"
            >
                <AiSuggestion.Header title="AI suggestion">
                    {isSuggestPending ? (
                        <FlexRow
                            justifyContent="center"
                            alignItems="center"
                            w="7"
                            h="7"
                        >
                            <Spinner className="border-t-beavrGreen w-5 h-5" />
                        </FlexRow>
                    ) : !datapoint?.aiSuggestion &&
                      !isAiSuggestionOpen &&
                      !isSuggestPending ? (
                        <Button size="sm" onClick={handleSuggestButtonClick}>
                            Suggest
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            variant="plain"
                            className="w-fit h-fit"
                            onClick={() =>
                                setIsAiSuggestionOpen(!isAiSuggestionOpen)
                            }
                        >
                            <Button.Icon
                                name={
                                    isAiSuggestionOpen ? "angleUp" : "angleDown"
                                }
                                size="sm"
                                className="text-[#01C33B]"
                            />
                        </Button>
                    )}
                </AiSuggestion.Header>

                <AiSuggestion.Body
                    className={cn(!datapoint?.aiSuggestion && "pb-5")}
                >
                    {isNarrativeDatapoint(datapoint) && (
                        <AiSuggestion.Block
                            title={t("ai_suggestion_answer")}
                            content={aiSuggestionContent?.value || ""}
                            withCopy
                        />
                    )}
                    {isBoolDatapoint(datapoint) && (
                        <>
                            <AiSuggestion.Block
                                title={t("ai_suggestion_answer")}
                                content={
                                    aiSuggestionContent?.value
                                        ? "True"
                                        : "False"
                                }
                            />
                            <AiSuggestion.Block
                                title={t("ai_suggestion_comment")}
                                content={aiSuggestionContent?.comment || ""}
                                withCopy
                            />
                        </>
                    )}
                    {isReportingDatapoint(datapoint) && (
                        <>
                            <AiSuggestion.Block
                                title={t("ai_suggestion_answer")}
                                content={
                                    aiSuggestionContent?.success
                                        ? `${aiSuggestionContent?.value} ${datapoint?.unit?.shortName}`
                                        : "-"
                                }
                            />
                            <AiSuggestion.Block
                                title={t("ai_suggestion_comment")}
                                content={aiSuggestionContent?.comment || ""}
                                withCopy
                            />
                        </>
                    )}
                    {!!aiSuggestionContent?.motivation && (
                        <AiSuggestion.Block
                            title={t("ai_suggestion_motivation")}
                            content={aiSuggestionContent?.motivation ?? ""}
                        />
                    )}
                    {!!suggestedDocuments?.length && (
                        <AiSuggestion.ReferenceBlock
                            title={t("ai_suggestion_references")}
                        >
                            <FlexCol>
                                {suggestedDocuments?.map((document) => (
                                    <FlexRow
                                        alignItems="center"
                                        justifyContent="between"
                                        gap="3"
                                        alignSelf="stretch"
                                        key={document.id}
                                    >
                                        <a
                                            href={
                                                makeDocumentLink(
                                                    {
                                                        type: document.type,
                                                        uniqueUrl:
                                                            document.uniqueUrl,
                                                    },
                                                    org_uname,
                                                ) || undefined
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-inherit cursor-pointer"
                                        >
                                            <FlexRow
                                                alignItems="center"
                                                gap="3"
                                                alignSelf="stretch"
                                            >
                                                <FileIcon
                                                    mimeType={
                                                        document
                                                            ?.lastApprovedVersion
                                                            ?.finalFile
                                                            ?.mimeType ||
                                                        "application/pdf"
                                                    }
                                                />
                                                <Text
                                                    variant="body-bold"
                                                    color="brand"
                                                >
                                                    {document?.name}
                                                </Text>
                                            </FlexRow>
                                        </a>
                                    </FlexRow>
                                ))}
                            </FlexCol>
                        </AiSuggestion.ReferenceBlock>
                    )}
                    {suggestedIndicators?.length > 0 && (
                        <AiSuggestion.ReferenceBlock
                            title={t("ai_suggestion_references")}
                        >
                            <FlexCol gap="2">
                                {suggestedIndicators?.map(
                                    (indicator: {
                                        id: string;
                                        name: string;
                                    }) => (
                                        <FlexRow key={indicator?.id}>
                                            <a
                                                href={makeIndicatorLink(
                                                    indicator?.name,
                                                    org_uname,
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FlexRow
                                                    gap="2"
                                                    alignItems="center"
                                                >
                                                    <Icon
                                                        name="pencil"
                                                        size="sm"
                                                    />
                                                    <Text
                                                        variant="body-bold"
                                                        color="brand"
                                                    >
                                                        {indicator?.name}
                                                    </Text>
                                                </FlexRow>
                                            </a>
                                        </FlexRow>
                                    ),
                                )}
                            </FlexCol>
                        </AiSuggestion.ReferenceBlock>
                    )}
                </AiSuggestion.Body>

                <AiSuggestion.Footer>
                    <Button
                        variant="plain"
                        size="sm"
                        className="w-fit"
                        onClick={handleGenerateAiSuggestion}
                        disabled={isSuggestPending}
                    >
                        Re-generate
                    </Button>
                    <AiSuggestion.Confirm onClick={handleConfirmClick}>
                        Confirm
                    </AiSuggestion.Confirm>
                </AiSuggestion.Footer>
            </AiSuggestion>
        )
    );
};
