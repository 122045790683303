import Checkbox from "@app/components/Checkbox";
import CheckboxCell from "@app/components/Table/CheckboxCell";
import CheckboxHeader from "@app/components/Table/Header/Checkbox";
import { FileIcon } from "@app/shared/components/FileIcon";
import { nameNormalized } from "@app/shared/utils/tableSortingFns";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Flex, FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { HeaderFilter } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import {
    CsrdDatapointStatus,
    DocumentType,
    DocumentVersionStatus,
} from "@generated/client/graphql";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtom, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { lastUrlUpdateByClickTimestampAtom } from "../Frameworks/framework/Csrd/pillar/ctx";
import {
    fileSourceType,
    linkType,
    selectedFileSourceAtom,
    selectedUsedInAtom,
} from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
import { EnrichedFile } from "./service";
const nameCol: ColumnDef<EnrichedFile> = {
    id: "name",
    accessorKey: "name",
    meta: {
        headerClassName: "max-w-[370px]",
        cellClassName: "max-w-[370px]",
    },
    sortingFn: nameNormalized,
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);

        return t(keys.name_col_header);
    },
    cell: ({ row }) => {
        const displayName =
            (!!row.original?.documentVersions &&
                row.original?.documentVersions[0]?.document?.name) ||
            row.original?.filename;
        const effectiveMimeType = row.original?.mimeType
            ? row.original.mimeType
            : row.original?.extension === "pdf"
              ? "application/pdf"
              : "application/unknown";
        return (
            <a
                href={row.original?.signedUrl || undefined}
                target="_blank"
                rel="noopener noreferrer"
                className="text-inherit"
            >
                <FlexRow h="6" gap="2" alignItems="center">
                    <FileIcon mimeType={effectiveMimeType} size={7} />
                    <Ellipsis asChild hasTooltip>
                        <Text
                            variant="body-sm-bold"
                            align="start"
                            className="no-underline"
                        >
                            {displayName}
                        </Text>
                    </Ellipsis>
                </FlexRow>
            </a>
        );
    },
};

const createdAtCol: ColumnDef<EnrichedFile> = {
    id: "createdAt",
    accessorKey: "createdAt",
    meta: {
        headerClassName: "max-w-[140px]",
        cellClassName: "max-w-[140px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);

        return t(keys.created_at_col_header);
    },
    cell: ({ row }) => {
        dayjs.extend(LocalizedFormat);
        return (
            <FlexRow h="6" gap="2" alignItems="center" className="w-[150px]">
                <Text variant="body-sm" align="start">
                    {dayjs(row.original?.createdAt).format("LL")}
                </Text>
            </FlexRow>
        );
    },
};

const linksTypeCol: ColumnDef<EnrichedFile> = {
    id: "linksType",
    enableSorting: false,
    filterFn: "arrIncludesSome",
    accessorFn: (original) => {
        let types = [];
        if (!!original?.documentVersions?.length) {
            const documentsTypes = original?.documentVersions.map(
                (version) => version.document?.type,
            );
            if (
                documentsTypes.includes(DocumentType.Policy) ||
                documentsTypes.includes(DocumentType.MasterPolicy)
            ) {
                types.push(linkType.Policy);
            }
            if (documentsTypes.includes(DocumentType.Procedure)) {
                types.push(linkType.Measure);
            }
            if (
                documentsTypes.includes(DocumentType.CustomDocument) ||
                documentsTypes.includes(DocumentType.Misc)
            ) {
                types.push(linkType.Custom);
            }
        }
        if (!!original?.csrdDatapoints?.length) {
            types.push(linkType.Csrd);
        }
        return types;
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        const [selectedUsedIn, setSelectedUsedIn] = useAtom(selectedUsedInAtom);
        return (
            <MultiSelect
                values={selectedUsedIn}
                onValuesChange={setSelectedUsedIn}
            >
                <MultiSelect.Trigger>
                    <HeaderFilter active={!!selectedUsedIn?.length}>
                        {t(keys.type_col_header, {
                            context: !!selectedUsedIn?.length && "active",
                            count: selectedUsedIn?.length,
                        })}
                    </HeaderFilter>
                </MultiSelect.Trigger>
                <MultiSelect.Content>
                    {Object.values(linkType).map((type) => {
                        return (
                            <MultiSelect.Item value={type} key={type}>
                                {({ isSelected }) => (
                                    <FlexRow
                                        alignItems="center"
                                        alignSelf="stretch"
                                        gap="3"
                                        br="lg"
                                        className="flex-1"
                                    >
                                        <Flex alignItems="center">
                                            <Checkbox checked={isSelected} />
                                        </Flex>
                                        <Text>{t(`type_${type}`)}</Text>
                                    </FlexRow>
                                )}
                            </MultiSelect.Item>
                        );
                    })}
                </MultiSelect.Content>
            </MultiSelect>
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const linkedDocTypes = row?.original?.documentVersions?.map(
            (version) => version?.document?.type,
        );
        const isPolicy =
            linkedDocTypes?.includes(DocumentType.Policy) ||
            linkedDocTypes?.includes(DocumentType.MasterPolicy);
        const isMeasure = linkedDocTypes?.includes(DocumentType.Procedure);
        const isCustom =
            linkedDocTypes?.includes(DocumentType.CustomDocument) ||
            linkedDocTypes?.includes(DocumentType.Misc);
        const isCsrd = !!row?.original?.csrdDatapoints?.length;
        return (
            <FlexCol gap="2" alignItems="start">
                {isPolicy && (
                    <Text variant="body-sm" align="start">
                        {t("type", { context: linkType.Policy })}
                    </Text>
                )}
                {isMeasure && (
                    <Text variant="body-sm" align="start">
                        {t("type", { context: linkType.Measure })}
                    </Text>
                )}
                {isCustom && (
                    <Text variant="body-sm" align="start">
                        {t("type", { context: linkType.Custom })}
                    </Text>
                )}
                {isCsrd && (
                    <Text variant="body-sm" align="start">
                        {t("type", { context: linkType.Csrd })}
                    </Text>
                )}
            </FlexCol>
        );
    },
};

const linksCol: ColumnDef<EnrichedFile> = {
    id: "link",
    meta: {
        headerClassName: "max-w-[370px]",
        cellClassName: "max-w-[370px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);

        return t(keys.used_in_links_col_header);
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const navigate = useNavigate();
        const params = useParams();
        const onDocumentVersionLinkClick = (
            uniqueUrl: string | undefined,
            type: DocumentType | undefined,
        ) => {
            if (!uniqueUrl || !type) return;
            return () => {
                const type_url_component =
                    type === DocumentType.MasterPolicy ||
                    type === DocumentType.Policy
                        ? "policies"
                        : type === DocumentType.Procedure
                          ? "measures"
                          : "custom_documents";
                navigate(
                    `/o/${params.org_uname}/admin/${type_url_component}/${uniqueUrl}`,
                );
            };
        };
        const setLastUrlUpdateByClickTimestamp = useSetAtom(
            lastUrlUpdateByClickTimestampAtom,
        );

        const onCsrdDpLinkClick = (
            csmsDpId: string | undefined,
            cmsDrId: string | undefined,
            cmsPillarId: string | undefined,
        ) => {
            if (!csmsDpId || !cmsDrId || !cmsPillarId) return;
            return () => {
                setLastUrlUpdateByClickTimestamp(new Date());
                navigate(
                    `/o/${params.org_uname}/admin/frameworks/csrd/${cmsPillarId}#drId="${cmsDrId}"&dpId="${csmsDpId}"`,
                );
            };
        };

        const uniqueLinkedDocs = [
            ...new Map(
                row?.original?.documentVersions?.map((version) => [
                    version?.document?.name,
                    version,
                ]),
            ).values(),
        ];
        return (
            <FlexCol gap="2" alignItems="start" className="overflow-hidden">
                {uniqueLinkedDocs.map((version) => (
                    <FlexRow
                        key={version.id}
                        alignItems="center"
                        gap="1"
                        className="w-full"
                    >
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Icon
                                    name={
                                        version?.status ===
                                        DocumentVersionStatus.Approved
                                            ? "circleValidated"
                                            : "circleInProgress"
                                    }
                                    size="xs"
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                <Text variant="body-sm">
                                    {version?.status ===
                                    DocumentVersionStatus.Approved
                                        ? t(keys.validated)
                                        : t(keys.ongoing)}
                                </Text>
                            </Tooltip.Content>
                        </Tooltip>

                        <Ellipsis
                            asChild
                            hasTooltip
                            className="cursor-pointer text-beavrGreen hover:underline"
                        >
                            <Text
                                variant="body-sm"
                                onClick={onDocumentVersionLinkClick(
                                    version?.document?.uniqueUrl,
                                    version?.document?.type,
                                )}
                            >
                                {version?.document?.name}
                            </Text>
                        </Ellipsis>
                    </FlexRow>
                ))}
                {row?.original?.csrdDatapoints?.map((dp) => (
                    <FlexRow
                        key={dp.id}
                        alignItems="center"
                        gap="1"
                        className="w-full"
                    >
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Icon
                                    name={
                                        dp.status ===
                                        CsrdDatapointStatus.InProgress
                                            ? "circleInProgress"
                                            : dp.status ===
                                                CsrdDatapointStatus.NotMaterial
                                              ? "eyeOff"
                                              : dp.status ===
                                                  CsrdDatapointStatus.Validated
                                                ? "circleValidated"
                                                : dp.status ===
                                                    CsrdDatapointStatus.NotStarted
                                                  ? "circleTodo"
                                                  : "circleInProgress"
                                    }
                                    size="xs"
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                <Text variant="body-sm">
                                    {dp.status ===
                                    CsrdDatapointStatus.InProgress
                                        ? t(keys.ongoing)
                                        : dp.status ===
                                            CsrdDatapointStatus.NotMaterial
                                          ? t(keys.not_material)
                                          : dp.status ===
                                              CsrdDatapointStatus.Validated
                                            ? t(keys.validated)
                                            : dp.status ===
                                                CsrdDatapointStatus.NotStarted
                                              ? t(keys.not_started)
                                              : t(keys.ongoing)}
                                </Text>
                            </Tooltip.Content>
                        </Tooltip>

                        <Ellipsis
                            asChild
                            hasTooltip
                            className="cursor-pointer text-beavrGreen hover:underline"
                        >
                            <Text
                                variant="body-sm"
                                onClick={onCsrdDpLinkClick(
                                    dp?.cmsId,
                                    dp?.cmsDrId || undefined,
                                    dp?.cmsPillarId || undefined,
                                )}
                            >
                                {dp?.name}
                            </Text>
                        </Ellipsis>
                    </FlexRow>
                ))}
            </FlexCol>
        );
    },
};

const sourceCol: ColumnDef<EnrichedFile> = {
    id: "source",
    enableSorting: false,
    filterFn: "arrIncludesSome",
    accessorFn: (original) => {
        return !!original?.withEditor
            ? fileSourceType.Platform
            : fileSourceType.Uploaded;
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        const [selectedFileSource, setSelectedFileSource] = useAtom(
            selectedFileSourceAtom,
        );
        return (
            <MultiSelect
                values={selectedFileSource}
                onValuesChange={setSelectedFileSource}
            >
                <Tooltip delayDuration={0}>
                    <Tooltip.Trigger className="w-full">
                        <MultiSelect.Trigger>
                            <HeaderFilter active={!!selectedFileSource?.length}>
                                {t(keys.file_source_col, {
                                    context:
                                        !!selectedFileSource?.length &&
                                        "active",
                                    count: selectedFileSource?.length,
                                })}
                            </HeaderFilter>
                        </MultiSelect.Trigger>
                    </Tooltip.Trigger>
                    <Tooltip.Content side="top" sideOffset={10}>
                        <Text variant="body-sm">{t(keys.source_tooltip)}</Text>
                    </Tooltip.Content>
                </Tooltip>

                <MultiSelect.Content>
                    <MultiSelect.Item
                        value={fileSourceType.Platform}
                        key={fileSourceType.Platform}
                    >
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                gap="3"
                                br="lg"
                                className="flex-1"
                            >
                                <Flex alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </Flex>
                                <Text>{t(keys.file_source_platform)}</Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                    <MultiSelect.Item
                        value={fileSourceType.Uploaded}
                        key={fileSourceType.Uploaded}
                    >
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                gap="3"
                                br="lg"
                                className="flex-1"
                            >
                                <Flex alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </Flex>
                                <Text>{t(keys.file_source_uploaded)}</Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                </MultiSelect.Content>
            </MultiSelect>
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        return (
            <FlexCol alignItems="start" className="w-[100px]">
                {!!row?.original?.withEditor ? (
                    <FlexRow gap="2" alignItems="center">
                        <Icon name="beavr" size="sm" />
                        <Text variant="body-sm" align="start">
                            {t(keys.file_source_platform)}
                        </Text>
                    </FlexRow>
                ) : (
                    <FlexRow gap="2" alignItems="center">
                        <Icon name="upload" size="sm" />
                        <Text variant="body-sm" align="start">
                            {t(keys.file_source_uploaded)}
                        </Text>
                    </FlexRow>
                )}
            </FlexCol>
        );
    },
};

const selectCol: ColumnDef<EnrichedFile> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    size: 50,
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[50px] max-w-[50px] border-r-0",
        cellClassName:
            "min-w-[50px] max-w-[50px] group-hover/tablerow:bg-primary border-r-0",
    },
};

export const useColumns = (): ColumnDef<EnrichedFile>[] => {
    const columns = [
        selectCol,
        nameCol,
        linksCol,
        linksTypeCol,
        createdAtCol,
        sourceCol,
    ];
    return columns;
};
