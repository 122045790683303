import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    type Row,
    type RowSelectionState,
    Table,
    type TableOptions,
} from "@design-system/Table";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { type QuestionnaireOverviewData } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/types";
import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { AnswerStatus } from "@generated/client/graphql";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import { ActionBar } from "./ActionBar";
import { useColumns } from "./columns";
import { columnFiltersAtom } from "./ctx";

export const QuestionsTable = ({
    questionnaireId,
    searchString,
    questions,
    editable = false,
    showGroups = false,
    loading = false,
}: {
    questionnaireId: string | undefined;
    searchString?: string | undefined;
    questions: QuestionnaireOverviewData;
    editable?: boolean;
    showGroups?: boolean;
    loading?: boolean;
}) => {
    const columns = useColumns({
        editable,
        showGroups,
    });

    const questionsFiltered = useMemo(
        () =>
            searchString
                ? questions?.filter((question) => {
                      const search = searchString.toLowerCase();
                      return question?.name?.toLowerCase().includes(search);
                  })
                : questions,
        [questions, searchString],
    );

    const columnFilters = useAtomValue(columnFiltersAtom);

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const options: Partial<TableOptions<QuestionnaireOverviewData[number]>> =
        useMemo(
            () => ({
                getPaginationRowModel: getPaginationRowModel(),
                getSortedRowModel: getSortedRowModel(),
                getFilteredRowModel: getFilteredRowModel(),
                onRowSelectionChange: setRowSelection,
                state: {
                    rowSelection,
                    columnFilters,
                },
                autoResetPageIndex: false,
                enableRowSelection: (
                    row: Row<QuestionnaireOverviewData[number]>,
                ) => row.original.answer?.status !== AnswerStatus.Validated,
            }),
            [columnFilters, rowSelection, setRowSelection],
        );

    const selectedQuestionsIds = useMemo(() => {
        return (
            questions
                ?.filter((_, idx) => rowSelection[idx])
                ?.map((question) => question.id) ?? []
        );
    }, [questions, rowSelection]);

    const unsetSelection = () => {
        setRowSelection({});
    };

    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 });
    }, [setPagination, searchString, columnFilters]);

    return (
        <>
            <Table
                columns={columns}
                data={questionsFiltered ?? []}
                pagination={{
                    state: pagination,
                    onPaginationChange: setPagination,
                    manualPagination: false,
                }}
                options={options}
                loading={loading}
                variant="grid"
                EmptyBody={EmptyBody}
            />
            {questionnaireId && (
                <ActionBar
                    questionnaireId={questionnaireId}
                    questionsIds={selectedQuestionsIds}
                    unset={unsetSelection}
                />
            )}
        </>
    );
};

const EmptyBody = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" className="my-8">
                <Icon name="search" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {t(keys.table_empty_state_title)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {t(keys.table_empty_state_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
