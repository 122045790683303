import { useSetToast } from "@app/components/Toast";
import { useNavigation } from "@app/lib/navigation";
import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    GenerateObjectiveMutation,
    ObjectiveSource,
} from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { activeEntityIdAtom } from "../../ctx";
import {
    objectiveKeys,
    useCreateObjective,
    useGenerateObjective,
} from "../../data";
import { isGenerateObjectiveModalOpenAtom } from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
export const GenerateObjectivePage: FC<{
    generationProgress: number;
    setGenerationProgress: (progress: number) => void;
    selectedIndicator?: {
        id: string;
        name: string;
        themeId: string;
        unitShortName: string;
    };
}> = ({ generationProgress, setGenerationProgress, selectedIndicator }) => {
    const { generateObjective } = useGenerateObjective();
    const { mutate: createObjective } = useCreateObjective();
    const { navigateFromHome } = useNavigation();
    const { setToastSuccess, setToastError } = useSetToast();

    const activeEntityId = useAtomValue(activeEntityIdAtom);
    const setIsGenerateObjectiveModalOpen = useSetAtom(
        isGenerateObjectiveModalOpenAtom,
    );

    const generateObjectiveMutationState = useMutationState({
        filters: { mutationKey: [...objectiveKeys.generateObjective()] },
        select: (mutation) => mutation.state,
    });

    const isGenerateObjectivePending =
        !!generateObjectiveMutationState?.length &&
        generateObjectiveMutationState[
            generateObjectiveMutationState.length - 1
        ].status === "pending";

    const lastGeneratedObjective:
        | GenerateObjectiveMutation["generateObjective"]
        | undefined = generateObjectiveMutationState?.length
        ? (
              generateObjectiveMutationState[
                  generateObjectiveMutationState.length - 1
              ].data as GenerateObjectiveMutation
          )?.generateObjective
        : undefined;

    const handleGenerateClick = () => {
        if (!selectedIndicator || !activeEntityId) return;
        setGenerationProgress(0);
        generateObjective({
            indicatorId: selectedIndicator.id,
            entityId: activeEntityId,
        });
    };

    const handleCreateClick = () => {
        if (!selectedIndicator || !activeEntityId) return;
        createObjective(
            {
                indicatorId: selectedIndicator.id,
                entityId: activeEntityId,
                themeId: selectedIndicator.themeId,
                name: lastGeneratedObjective?.name,
                description: `${t(keys.template_warning)} ${lastGeneratedObjective?.description}`,
                actionPlan: `${t(keys.template_warning)} ${lastGeneratedObjective?.actionPlan}`,
                targets: lastGeneratedObjective?.targets?.map((target) => ({
                    value: target.targetNumber ?? 0,
                    date: target.date,
                    source: ObjectiveSource.Ai,
                })),
            },
            {
                onSuccess: (data) => {
                    setToastSuccess(t(keys.toast_success));
                    setIsGenerateObjectiveModalOpen(false);
                    const objectiveId = data.createObjective.id;
                    navigateFromHome(`objectives/${objectiveId}`);
                },
                onError: () => {
                    setToastError(t(keys.toast_error_unknown));
                },
            },
        );
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <AiSuggestion
            isOpen={!isGenerateObjectivePending && !!lastGeneratedObjective}
            isLoading={isGenerateObjectivePending}
        >
            <AiSuggestion.Header
                title={
                    isGenerateObjectivePending
                        ? t(keys.title_loading)
                        : t(keys.title)
                }
            >
                {isGenerateObjectivePending ? (
                    <FlexCol w="1/2">
                        <AiSuggestion.Loader
                            progress={generationProgress}
                            setProgress={setGenerationProgress}
                            maxProgress={99}
                            totalDuration={7500}
                            slowdownFactor={3}
                        />
                    </FlexCol>
                ) : (
                    <Tooltip>
                        <Tooltip.Trigger asChild>
                            <Button
                                variant="plain"
                                size="sm"
                                className="hover:bg-opacity-30 active:bg-opacity-30"
                                onClick={handleGenerateClick}
                            >
                                <Button.Icon
                                    name="arrowCircleRight"
                                    size="sm"
                                />
                            </Button>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            <Text variant="body">{t(keys.regenerate)}</Text>
                        </Tooltip.Content>
                    </Tooltip>
                )}
            </AiSuggestion.Header>
            {lastGeneratedObjective && (
                <>
                    <AiSuggestion.Body>
                        <Text variant="header2" color="primary">
                            {lastGeneratedObjective.name}
                        </Text>
                        <AiSuggestion.Block
                            title={t(keys.indicator)}
                            content={selectedIndicator?.name ?? ""}
                        />
                        <AiSuggestion.Block
                            title={t(keys.description)}
                            content={lastGeneratedObjective.description}
                        />
                        <AiSuggestion.Block
                            title={t(keys.action_plan)}
                            content={lastGeneratedObjective.actionPlan}
                        />
                        <TargetBlock
                            targets={lastGeneratedObjective.targets}
                            unitShortName={
                                selectedIndicator?.unitShortName ?? ""
                            }
                        />
                    </AiSuggestion.Body>
                    <AiSuggestion.Footer>
                        <AiSuggestion.Confirm onClick={handleCreateClick}>
                            {t(keys.create_button)}
                        </AiSuggestion.Confirm>
                    </AiSuggestion.Footer>
                </>
            )}
        </AiSuggestion>
    );
};

const TargetBlock: FC<{
    targets: GenerateObjectiveMutation["generateObjective"]["targets"];
    unitShortName: string;
}> = ({ targets, unitShortName }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol alignItems="start" gap="3" className="text-primary">
            <Text
                variant="body-sm-bold"
                color="secondary"
                className="uppercase"
            >
                {t(keys.targets)}
            </Text>
            <Text variant="body-sm-bold" color="secondary">
                {t(keys.targets_disclosure)}
            </Text>
            {targets.map((target) => (
                <Tooltip
                    key={target.date}
                    delayDuration={0}
                    disableHoverableContent
                >
                    <Tooltip.Trigger className="w-full">
                        <FlexRow
                            gap="8"
                            alignItems="center"
                            elevation="b-200"
                            p="3"
                            w="full"
                            br="lg"
                            className="border border-secondary bg-accent-1-50 hover:bg-accent-1-100 text-primary"
                        >
                            <FlexRow gap="2" alignItems="center" w="2/6">
                                <Icon name="target2" />
                                <Text>
                                    {`${
                                        target.targetNumber ??
                                        target.targetBoolean ??
                                        target.targetString
                                    } ${unitShortName}`}
                                </Text>
                            </FlexRow>
                            <FlexRow gap="2" alignItems="center" w="4/6">
                                <Icon name="calendar" />
                                <Text>
                                    {new Date(target.date).toLocaleString(
                                        "default",
                                        {
                                            month: "long",
                                            year: "numeric",
                                        },
                                    )}
                                </Text>
                            </FlexRow>
                        </FlexRow>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        <Text>{t(keys.targets_disclosure)}</Text>
                    </Tooltip.Content>
                </Tooltip>
            ))}
        </FlexCol>
    );
};
