import { useEntities } from "@app/store/entityStore";
import { Icon } from "@design-system/Icon";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { useObjectivesWithDetails } from "../../data";
import { useFilteredObjectives } from "../../hooks";
import ObjectiveTableActionBar from "./actionBar";
import { useColumns } from "./columns";
import {
    selectedSubthemesAtomFamily,
    selectedThemesAtomFamily,
    selectedUsersAtomFamily,
} from "./ctx";
import { keys, RESOURCE_NAME } from "./i18n";

type ObjectivesTableProps = {
    entityId: string;
};

export const ObjectivesTable: FC<ObjectivesTableProps> = ({ entityId }) => {
    const { entities } = useEntities();
    const columns = useColumns();
    const { objectives, isPending } = useObjectivesWithDetails(
        {
            entityId,
        },
        { skip: entities?.length != 0 && !entityId },
    );

    const [urlSearchString, _] = useQueryParam("search", StringParam);
    const { org_uname } = useParams();
    const selectedThemesIds = useAtomValue(selectedThemesAtomFamily(org_uname));
    const selectedSubthemesIds = useAtomValue(
        selectedSubthemesAtomFamily(org_uname),
    );
    const selectedUserIds = useAtomValue(selectedUsersAtomFamily(org_uname));
    const [rowSelection, setRowSelection] = useState({});

    const objectivesFiltered = useFilteredObjectives(
        objectives || [],
        urlSearchString || "",
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const memoData = useMemo(() => objectives || [], [objectives]);

    const columnFilters = useMemo(() => {
        const filters = [];

        if (!!selectedThemesIds.length) {
            filters.push({ id: "theme", value: selectedThemesIds });
        }

        if (!!selectedSubthemesIds.length) {
            filters.push({ id: "subtheme", value: selectedSubthemesIds });
        }

        if (!!selectedUserIds.length) {
            filters.push({ id: "owner", value: selectedUserIds });
        }

        return filters;
    }, [selectedThemesIds, selectedSubthemesIds, selectedUserIds]);

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            rowSelection,
            pagination,
            columnFilters,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [objectivesFiltered, urlSearchString, columnFilters]);

    const selectedObjectives = Object.keys(rowSelection)
        .filter((k) => rowSelection[k as keyof typeof rowSelection])
        .map((idx) => {
            return memoData[Number(idx)];
        })
        .filter((elt) => elt); // Remove undefined values

    const unset = () => setRowSelection({});

    return (
        <FlexCol w="full">
            <Table
                columns={columns}
                data={objectivesFiltered ?? []}
                options={tableOptions}
                loading={isPending}
                EmptyBody={EmptySearchState}
                variant="grid"
            />
            <ObjectiveTableActionBar
                objectives={selectedObjectives || []}
                unset={unset}
            />
        </FlexCol>
    );
};

const EmptySearchState = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { org_uname } = useParams();
    const [urlSearchString] = useQueryParam("search", StringParam);
    const selectedThemesIds = useAtomValue(selectedThemesAtomFamily(org_uname));
    const selectedSubthemesIds = useAtomValue(
        selectedSubthemesAtomFamily(org_uname),
    );
    const selectedUserIds = useAtomValue(selectedUsersAtomFamily(org_uname));

    const hasActiveFilters =
        !!urlSearchString?.length ||
        selectedSubthemesIds.length > 0 ||
        selectedThemesIds.length > 0 ||
        selectedUserIds.length > 0;

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" className="my-8">
                {hasActiveFilters && <Icon name="search" />}
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {hasActiveFilters
                            ? t(keys.empty_state_search_title)
                            : t(keys.empty_state_initial_title)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {hasActiveFilters
                            ? t(keys.empty_state_search_subtitle)
                            : t(keys.empty_state_initial_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
