import { useTags } from "@app/shared/components/TagListEditor/data";
import { Icon } from "@design-system/Icon";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { TagType } from "@generated/client/graphql";
import { type FC } from "react";
import { useSendQuestionnaireModalTranslations } from "./i18n";

export const SelectQuestionnaireDropdown: FC<{
    selectedQuestionnaireTagId: string;
    setSelectedQuestionnaireTagId: (id: string) => void;
}> = ({ selectedQuestionnaireTagId, setSelectedQuestionnaireTagId }) => {
    const tags = useTags(TagType.Questionnaire);

    const { t, keys } = useSendQuestionnaireModalTranslations();

    const displayedTrigger = selectedQuestionnaireTagId
        ? tags?.find((tag) => tag.id === selectedQuestionnaireTagId)?.name
        : t(keys.select_questionnaire_placeholder);

    const handleTagChange = (value: string[]) => {
        setSelectedQuestionnaireTagId(value[0]);
    };

    return (
        <Dropdown
            value={[selectedQuestionnaireTagId]}
            onValueChange={handleTagChange}
            selectType="single"
        >
            <Dropdown.Trigger>
                <FlexRow
                    w="full"
                    px="2"
                    py="4"
                    br="lg"
                    alignItems="center"
                    justifyContent="between"
                    className="border border-secondary text-primary"
                >
                    <FlexRow alignItems="center" gap="2">
                        <Icon name="document" />
                        <Text>{displayedTrigger}</Text>
                    </FlexRow>
                    <Icon name="angleDown" />
                </FlexRow>
            </Dropdown.Trigger>
            <Dropdown.Content align="start">
                {tags?.map((tag) => (
                    <Dropdown.SelectableItem key={tag.id} value={tag.id}>
                        <Dropdown.SelectedIndicator />
                        {tag.name}
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
