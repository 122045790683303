import { queryClient } from "@app/QueryClientWithHeaders";
import { useFeatureSwitch } from "@app/shared/posthog/useFeatureSwitch";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useQueryParam } from "use-query-params";
import Container from "../../../components/Container";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../components/Filters/Search";
import BodyHeader from "../../../shared/components/BodyHeader";
import NewSupplierModal from "./components/NewSupplierModal";
import { SupplierTable } from "./components/SupplierTable";
import SupplierTableDeprecated from "./components/SupplierTableDeprecated";
import { activeFilterParamArgs } from "./ctx";
import { supplierKeys, useSuppliers } from "./data";
import "./i18n";

export default function Suppliers() {
    const { t } = useTranslation("Suppliers");
    const { suppliers, isLoading, refetch } = useSuppliers();
    const refetchWithFeatureFlag = () => {
        queryClient.invalidateQueries({
            queryKey: supplierKeys.listAll(),
        });
    };

    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);
    const [searchFilter, setSearchFilter] = useQueryParam(
        ...activeFilterParamArgs.searchFilter,
    );

    const supplierRefactoFlag = useFeatureSwitch("supplierRefacto", {
        devOrgUname: "airbus",
    });

    return supplierRefactoFlag ? (
        <PageContainer>
            <BodyHeader
                title={t("title")}
                subTitle={t("subtitle")}
                action={<NewSupplierModal refetch={refetchWithFeatureFlag} />}
            />
            <FlexCol gap="4" p="4" alignItems="center" w="full" h="full">
                <FlexRow w="full" alignItems="center">
                    <SearchBar
                        placeholder={t("search")}
                        searchString={searchFilter}
                        setSearchString={setSearchFilter}
                    />
                </FlexRow>
                <SupplierTable />
            </FlexCol>
        </PageContainer>
    ) : (
        <Container>
            <BodyHeader
                title={t("title")}
                subTitle={t("subtitle")}
                action={<NewSupplierModal refetch={refetch} />}
            />
            <div className="p-4 space-y-4">
                <SearchFilter
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <SupplierTableDeprecated
                    suppliers={suppliers}
                    isLoading={isLoading}
                />
            </div>
        </Container>
    );
}
