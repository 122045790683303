import { Table, usePagination } from "@design-system/Table";
import { QuestionnaireStatus } from "@generated/client/graphql";
import { type FC } from "react";
import { useSupplierQuestionnaires } from "../../data";
import { useColumns } from "./columns";

export const QuestionnairesTable: FC<{
    supplierId?: string;
    withCopyLink?: boolean;
}> = ({ supplierId, withCopyLink = true }) => {
    const columns = useColumns({ withCopyLinkCol: withCopyLink });

    const { pagination, ...apiPagination } = usePagination();

    const { questionnaires, isPending } = useSupplierQuestionnaires(
        supplierId,
        { status: QuestionnaireStatus.Closed },
        apiPagination,
    );

    return (
        <Table
            columns={columns}
            loading={isPending}
            pagination={pagination}
            data={questionnaires?.list ?? []}
            rowCount={questionnaires?.count}
        />
    );
};
