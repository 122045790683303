import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { HeaderFilter } from "@design-system/Table";
import { QuestionnaireStatus } from "@generated/client/graphql";
import { FC } from "react";
import { useStatusMultiSelectTranslations } from "./i18n";

type StatusMultiSelectProps = {
    values: (string | null)[];
    onValuesChange: (values: (string | null)[]) => void;
};

export const StatusMultiSelect: FC<StatusMultiSelectProps> = ({
    values,
    onValuesChange,
}) => {
    const questionnaireStatus = Object.values(QuestionnaireStatus);
    const possibleValues = questionnaireStatus.filter(
        (status) => status !== QuestionnaireStatus.Unknown,
    );
    const { t, keys } = useStatusMultiSelectTranslations();
    return (
        <Dropdown
            onValueChange={onValuesChange}
            selectType="multi"
            closeOnValueChange={false}
        >
            <Dropdown.Trigger>
                <HeaderFilter active={!!values.length}>
                    {t(keys.status, {
                        context: values.length && "active",
                        count: values.length,
                    })}
                </HeaderFilter>
            </Dropdown.Trigger>
            <Dropdown.Content hasPortal>
                {possibleValues.map((value) => (
                    <Dropdown.SelectableItem key={value} value={value}>
                        <Dropdown.Checkbox />
                        <Dropdown.ItemText>
                            {t(keys.status, {
                                context: value,
                            })}
                        </Dropdown.ItemText>
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
