import i18next from "@app/i18n";

const enResources = {
    page_title: "Objectives",
    page_subtitle:
        "Manage your company’s objectives and targets for each  sustainability topic.",
    create_objective: "New objective",
    generate_with_ai: "Generate with Ai",
    toast_success: "Objective created successfully",
    toast_error_entity: "Please select an entity",
    toast_error_theme: "Please select a theme",
    toast_error_unknown: "Failed to create objective",
    empty_state_title: "Objectives",
    empty_state_subtitle: `You have not defined any objective. Setting measurable, 
        ambitious and realistic goals for each sustainability topic is a
        crucial step in building a CSR strategy.`,
    empty_state_button: "Create objective",
    no_objectives_found_title: "No objectives found",
    no_objectives_found_subtitle:
        "Try changing some parameters or adding objectives",
    work_plan: "Work plan",
    list: "Objectives list",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    page_title: "Engagements et objectifs",
    page_subtitle:
        "Définissez et gérez les objectifs de votre entreprise pour chaque enjeu de durabilité.",
    create_objective: "Nouvel objectif",
    generate_with_ai: "Générer avec IA",
    toast_success: "Objectif créé avec succès",
    toast_error_entity: "Veuillez sélectionner une entité",
    toast_error_theme: "Veuillez sélectionner un thème",
    toast_error_unknown: "Impossible de créer l'objectif",
    empty_state_title: "Objectifs",
    empty_state_subtitle: `Vous n’avez pas encore défini d’objectif. Fixer des objectifs quantifiables, 
        ambitieux et réalistes pour chaque enjeu de durabilité constitue une étape
        essentielle pour bâtir une stratégie RSE.`,
    empty_state_button: "Créer un objectif",
    no_objectives_found_title: "Aucun objectif trouvé",
    no_objectives_found_subtitle:
        "Essayez de modifier certains paramètres ou d'ajouter des objectifs",
    work_plan: "Plan de travail",
    list: "Liste d'objectifs",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectivePage";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
