import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { useTheme } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import {
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Table,
} from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery, ResponseStatus } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isIndicatorSelectModalOpenAtom } from "../../ctx";
import { useIndicatorsFiltered } from "../../data";
import { useColumns } from "./columns";
import { keys, RESOURCE_NAME } from "./i18n";
import { type Indicator } from "./types";

type IndicatorSelectModalProps = {
    objective: GetObjectiveQuery["objective"];
};

export const IndicatorSelectModal: FC<IndicatorSelectModalProps> = ({
    objective,
}) => {
    const theme = useTheme(objective.themeId);
    const subthemeIds = theme?.subthemes?.map((subtheme) => subtheme.id) || [];
    const acceptedUnitTypes = ["INTEGER", "FLOAT"];
    const { indicators, isPending } = useIndicatorsFiltered(
        {
            subthemeIds,
        },
        objective.entityId,
    );
    const { t } = useTranslation(RESOURCE_NAME);
    const [searchString, setSearchString] = useState<string | undefined>(
        undefined,
    );

    const filteredIndicators = useMemo(() => {
        const indicatorsActiveOnEntity = indicators?.indicators2?.filter(
            (indicator) => !!indicator.entityConfig?.active,
        );
        const numericIndicators = indicatorsActiveOnEntity?.filter((ind) =>
            acceptedUnitTypes.includes(ind.unit?.type || ""),
        );
        return !searchString
            ? numericIndicators
            : numericIndicators?.filter(
                  (ind) =>
                      ind.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()) ||
                      ind?.subtheme?.name
                          .toLowerCase()
                          .includes(searchString.toLowerCase()),
              );
    }, [indicators, searchString]);

    const [isIndicatorSelectModalOpen, setIsIndicatorSelectModalOpen] = useAtom(
        isIndicatorSelectModalOpenAtom,
    );

    const { mutate, isPending: isMutationPending } = useUpdateObjective(
        objective.id,
        objective.entityId,
    );
    const { setToastSuccess, setToastError } = useSetToast();
    const [selectedIndicatorId, setSelectedIndicatorId] = useState<
        string | null
    >(null);
    const updateObjectiveIndicator = (indicator: Indicator) => {
        setSelectedIndicatorId(indicator.id);
        mutate(
            {
                id: objective.id,
                indicatorId: indicator.id,
                ...(!objective?.name ? { name: indicator.name } : {}),
            },
            {
                onSettled: (data) => {
                    if (
                        data?.updateObjective?.status === ResponseStatus.Error
                    ) {
                        setToastError(`${data.updateObjective.error?.message}`);
                    } else if (
                        data?.updateObjective?.status === ResponseStatus.Success
                    ) {
                        setToastSuccess(t(keys.update_success));
                    }
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
        setIsIndicatorSelectModalOpen(false);
    };

    const columns = useColumns(
        updateObjectiveIndicator,
        isMutationPending,
        selectedIndicatorId,
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const tableOptions = {
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        autoResetPageIndex: false,
    };

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [indicators, searchString]);

    const handleCancelClick = () => {
        setIsIndicatorSelectModalOpen(false);
    };

    return (
        <Modal
            open={isIndicatorSelectModalOpen}
            onOpenChange={setIsIndicatorSelectModalOpen}
        >
            <Modal.Overlay scrollable>
                <Modal.Content className="w-fit">
                    <Modal.Header>
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                        <Modal.Subtitle>{t(keys.subtitle)}</Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol w="full" gap="4">
                            <SearchBar
                                searchString={searchString}
                                setSearchString={setSearchString}
                                className="w-full"
                            />
                            <Table
                                columns={columns}
                                data={filteredIndicators || []}
                                options={tableOptions}
                                loading={isPending}
                                EmptyBody={TableEmptyState}
                                className="w-full"
                            />
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA onClick={handleCancelClick}>
                            {t(keys.cancel)}
                        </Modal.CancelCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

const TableEmptyState: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol
            py="10"
            justifyContent="center"
            alignItems="center"
            w="full"
            className="bg-neutral-25"
        >
            <FlexCol gap="2" alignItems="center" w="full" className="my-8">
                <Icon name="search" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body-sm-bold">
                        {t(keys.title_indicator_no_results)}
                    </Text>
                    <Text variant="body-sm" className="font-light">
                        {t(keys.no_indicator_found_subtitle)}
                    </Text>
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};
