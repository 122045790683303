import AssignButton from "@app/components/Dropdown/dropdowns/User/AssignButton";
import Loading from "@app/components/Loading";
import Avatar from "@app/components/User/Avatar";
import { usersAtom } from "@app/store/userStore";
import Select from "@design-system/Inputs/Select";
import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { UserMinus } from "lucide-react";
import { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

const UserChipSelect: FC<
    PropsWithChildren<{
        userId: string | undefined | null;
        setUserId: (userId: string) => void;
        searchFilter?: string;
        onSearchFilterChange?: (val: string | undefined) => void;
        isPending?: boolean;
        showUnsetByDefault?: boolean;
        trigger?: ReactNode;
        disabled?: boolean;
    }>
> = ({
    userId,
    setUserId,
    searchFilter,
    onSearchFilterChange,
    isPending,
    showUnsetByDefault = false,
    trigger,
    disabled,
}) => {
    const [{ data, isLoading }] = useAtom(usersAtom);
    const currentUser = data?.users?.find((elt) => elt.id === userId);

    const { t } = useTranslation("UserChipSelect");

    const filteredUsers = useMemo(
        () =>
            data?.users.filter(
                ({ firstName, lastName, email }) =>
                    !searchFilter ||
                    [firstName, lastName, email].some(
                        (s) =>
                            s &&
                            s
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase()),
                    ),
            ) || [],
        [data, searchFilter],
    );
    return isLoading || isPending ? (
        <Loading size="small" />
    ) : (
        <Select
            value={userId || undefined}
            onValueChange={setUserId}
            disabled={disabled}
        >
            <Select.Trigger className="w-fit">
                {trigger || (
                    <AssignButton
                        disabled={disabled}
                        size="small"
                        userName={
                            currentUser &&
                            `${currentUser?.firstName} ${currentUser.lastName}`
                        }
                    />
                )}
            </Select.Trigger>
            <Select.Content>
                {onSearchFilterChange && (
                    <Select.Search
                        searchString={searchFilter || ""}
                        setSearchString={onSearchFilterChange}
                        placeholder={t("searchbar_placeholder")}
                    ></Select.Search>
                )}
                <Box className="max-h-[320px] overflow-auto">
                    {(showUnsetByDefault || userId) && (
                        <Select.Item value="" className="h-max">
                            <UserMinus className="w-4 h-4" />
                            <Text
                                variant="body-sm-bold"
                                color="secondary"
                                className="group-hover:text-brand_hover"
                            >
                                {t("unset_user")}
                            </Text>
                        </Select.Item>
                    )}
                    {filteredUsers.map((user) => (
                        <Select.Item
                            key={user.id}
                            value={user.id}
                            className="h-max"
                        >
                            <Avatar
                                avatarUrl={user.avatar?.signedUrl}
                                initials={user.initials}
                                size="small"
                            />
                            <Text
                                variant="body-sm-bold"
                                color="secondary"
                                className="group-hover:text-brand_hover capitalize"
                            >
                                {`${user.firstName} ${user.lastName}`}
                            </Text>
                        </Select.Item>
                    ))}
                </Box>
            </Select.Content>
        </Select>
    );
};

export default UserChipSelect;
