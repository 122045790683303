import { calculatePercentage, roundNumber } from "@app/shared/utils/math";
import { type PaginatedSelectedRows } from "@design-system/Table";
import { GetSupplierQuestionnairesQuery } from "@generated/client/graphql";
import dayjs from "dayjs";

export const formatQuestionnaire = (
    questionnaire: NonNullable<
        GetSupplierQuestionnairesQuery["supplierQuestionnaires2"]
    >["list"][number],
) => ({
    ...questionnaire,
    sentDate: questionnaire.createdAt
        ? dayjs(questionnaire.createdAt).format("DD/MM/YYYY")
        : undefined,
    dueDate: questionnaire.deadline
        ? dayjs(questionnaire.deadline).format("DD/MM/YYYY")
        : undefined,
    progress: roundNumber(
        calculatePercentage(
            questionnaire.stats?.answered || 0,
            questionnaire.stats?.total || 0,
        ),
    ),
});

export function makeSupplierQuestionnaireLink({
    orgUname,
    supplierId,
    questionnaireId,
}: {
    orgUname: string;
    supplierId: string;
    questionnaireId: string;
}) {
    return `${window.origin}/o/${orgUname}/sup/${supplierId}/questionnaires/${questionnaireId}`;
}

export function getSelectedRowsCount(
    paginatedSelection: PaginatedSelectedRows,
    rowCount: number,
) {
    const { all_token, exclude_ids, include_ids } = paginatedSelection;

    const excludedRowsCount = Object.values(exclude_ids ?? {}).filter(
        Boolean,
    ).length;

    const includedRowsCount = Object.values(include_ids ?? {}).filter(
        Boolean,
    ).length;

    const selectedRowsCountWithAllToken = rowCount - excludedRowsCount;
    const selectedRowsCountWithoutAllToken = includedRowsCount;

    const selectedRowsCount = all_token
        ? selectedRowsCountWithAllToken
        : selectedRowsCountWithoutAllToken;

    return selectedRowsCount;
}

export function getSelectedSupplierIds(
    paginatedSelection: PaginatedSelectedRows,
    supplierIds: string[],
) {
    const { all_token, exclude_ids, include_ids } = paginatedSelection;

    if (all_token) {
        return supplierIds.filter((id) => !exclude_ids[id]);
    }

    return supplierIds.filter((id) => include_ids[id]);
}
