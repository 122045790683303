import { CheckboxGroup } from "@design-system/Inputs/CheckboxGroup";

import { type FC } from "react";

export const MultiSelectSection: FC<{
    options: string[];
    value: number[];

    onChange: (value: number[]) => void;
    disabled?: boolean;
}> = ({
    options,
    value: selectedOptions,

    onChange,
    disabled = false,
}) => {
    const selectedOptionsString = selectedOptions.map(String);
    const handleChange = (value: string[]) => {
        const numbers = value.map(Number);
        onChange(numbers);
    };
    const boxes = options.map((option, index) => ({
        label: String(option),
        value: String(index),
    }));

    return (
        <CheckboxGroup value={selectedOptionsString} onChange={handleChange}>
            {boxes.map(({ label, value }) => (
                <CheckboxGroup.Field key={value} disabled={disabled}>
                    <CheckboxGroup.Checkbox value={value} />
                    <CheckboxGroup.Label>{label}</CheckboxGroup.Label>
                </CheckboxGroup.Field>
            ))}
        </CheckboxGroup>
    );
};
