import i18next from "../../i18n";

i18next.addResources("en", "DocumentScreen", {
    previous_page: "Documents",
    more: "More",
    reset: "Reset",
    reset_title: "Reset Document",
    reset_description_line1:
        "Are you sure you want to reset this Document? This will delete all versions and reset the Document to its default state.",
    reset_description_line2: "This action cannot be undone.",
    reset_confirmation: "Delete and reset",

    not_relevant: "Set as not relevant",
    not_relevant_title: "Set as not relevant",
    not_relevant_confirmation: "Confirm",
    not_relevant_description_line1:
        "Are you sure you want to set this Document as not relevant? This will remove the Document from your management system.",
    not_relevant_description_line2:
        "This might impact your compliance posture.",

    set_as_relevant: "Set as relevant",
    show_archived_versions_one: "Show 1 archived version",
    show_archived_versions_other: "Show {{count}} archived versions",
});
i18next.addResources("fr", "DocumentScreen", {
    previous_page: "Documents",
    more: "Plus",
    reset: "Réinitialiser",
    reset_title: "Réinitialiser le document",
    reset_description_line1:
        "Êtes-vous sûr de vouloir réinitialiser ce document ? Cela supprimera toutes les versions et réinitialisera le document à son état par défaut.",
    reset_description_line2: "Cette action est irréversible.",
    reset_confirmation: "Supprimer et réinitialiser",

    not_relevant: "Marquer comme non pertinent",
    not_relevant_title: "Marquer comme non pertinent",
    not_relevant_confirmation: "Confirmer",
    not_relevant_description_line1:
        "Êtes-vous sûr de vouloir marquer ce document comme non pertinent ? Cela supprimera le document de votre système de gestion.",
    not_relevant_description_line2: "Cela pourrait affecter votre conformité.",
    set_as_relevant: "Marquer comme pertinent",
    show_archived_versions_one: "Afficher 1 version archivée",
    show_archived_versions_other: "Afficher {{count}} versions archivées",
});
