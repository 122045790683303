import { useSetToast } from "@app/components/Toast";
import { useNavigation } from "@app/lib/navigation";
import { useDeleteObjective } from "@app/screens/Objectives/data";
import { useEntities } from "@app/store/entityStore";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";
type ObjectiveHeaderProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveSideBar: FC<ObjectiveHeaderProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastSuccess, setToastError } = useSetToast();

    const { mutate } = useDeleteObjective(objective);
    const { entities } = useEntities();
    const { navigateFromHome } = useNavigation();
    const entityDisplayName = entities?.find(
        (entity) => entity.id === objective.entityId,
    )?.name;
    const handleCopyLinkCLick = () => {
        navigator.clipboard.writeText(window.location.href);
        setToastSuccess(t(keys.success_copy));
    };

    const handleDeleteObjectiveClick = () => {
        mutate(objective.id, {
            onSuccess: () => {
                navigateFromHome("objectives");
                setToastSuccess(t(keys.success_delete));
            },
            onError: () => {
                setToastError(t(keys.error_delete));
            },
        });
    };
    const handleCloseClick = () => {
        navigateFromHome("objectives");
    };

    return (
        <FlexCol
            justifyContent="start"
            alignSelf="stretch"
            alignItems="start"
            className="w-[320px]"
            px="4"
        >
            <FlexRow
                py="4"
                justifyContent="between"
                alignItems="center"
                w="full"
            >
                <FlexRow gap="1">
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger>
                            <Box
                                onClick={handleCopyLinkCLick}
                                className="cursor-pointer hover:bg-secondary rounded-sm p-1"
                            >
                                <Icon name="link" size="sm" />
                            </Box>
                        </Tooltip.Trigger>
                        <Tooltip.Content>{t(keys.copy_link)}</Tooltip.Content>
                    </Tooltip>

                    <DropdownMenu>
                        <DropdownMenu.Trigger>
                            <Box className="cursor-pointer hover:bg-secondary rounded-sm p-1">
                                <Icon name="more" size="sm" />
                            </Box>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content align="end">
                            <DropdownMenu.Item
                                className="data-[highlighted]:bg-[#FEEFEC] data-[highlighted]:text-error-500"
                                onSelect={handleDeleteObjectiveClick}
                            >
                                <FlexRow w="full" h="full" gap="2">
                                    <DropdownMenu.ItemIcon
                                        name="trash"
                                        size="sm"
                                    />

                                    <Text variant="body-sm">
                                        {t(keys.delete_objective)}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu>
                </FlexRow>
                <Button variant="outlined" size="sm" onClick={handleCloseClick}>
                    <Text variant="body-sm" px="1">
                        {t(keys.close)}
                    </Text>
                    <Icon name="close" size="sm" />
                </Button>
            </FlexRow>
            <FlexCol gap="2" alignItems="start">
                <Text variant="body" color="secondary">
                    {t(keys.entity)}
                </Text>
                <FlexRow
                    gap="0.5"
                    p="1"
                    alignItems="center"
                    justifyContent="center"
                    elevation="b-100"
                    className="rounded-xl border border-secondary"
                >
                    <Icon name="building" size="sm" />
                    <Text variant="body-sm">{entityDisplayName}</Text>
                </FlexRow>
            </FlexCol>
        </FlexCol>
    );
};
