import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-header";

const keys = addTranslations(RESOURCE_NAME, {
    subtitle: {
        en: "Check sent questionnaires, add users and more.",
        fr: "Vérifier les questionnaires envoyés, ajouter des utilisateurs et plus encore.",
    },
    edit_btn: {
        en: "Edit supplier",
        fr: "Modifier le fournisseur",
    },
    toast_error_update_supplier: {
        en: "There was an error updating the supplier",
        fr: "Il y a eu une erreur lors de la mise à jour du fournisseur",
    },
});

export function useSupplierHeaderTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
