type BrandFetchResponse = {
    brandId?: string;
    domain?: string;
    name?: string;
    icon?: string;
}[];

export class RestLogoRepository {
    getBrand = async (searchValue: string): Promise<BrandFetchResponse> => {
        const BRANDFETCH_CLIENT_ID = import.meta.env
            .VITE_REACT_APP_BRANDFETCH_CLIENT_ID;

        const url = `https://api.brandfetch.io/v2/search/${searchValue}?c=${BRANDFETCH_CLIENT_ID}`;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error("Server error");
        }

        const data = await response.json();

        return data;
    };
}
