import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { FC, PropsWithChildren, useState } from "react";
import { useDeleteExternalQuestionnaire } from "../../data";
import { DeleteQuestionnaireModal } from "./DeleteQuestionnaireModal";
import { EditQuestionnaireModal } from "./EditQuestionnaireModal";
import { useEditOrDeleteDropdownTranslations } from "./i18n";

export const EditOrDeleteDropdown: FC<
    PropsWithChildren<{
        questionnaireId?: string;
    }>
> = ({ questionnaireId, children }) => {
    const { t, keys } = useEditOrDeleteDropdownTranslations();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const openDeleteModalOnClick = () => {
        setOpenDeleteModal(true);
    };

    const openEditModalOnClick = () => {
        setOpenEditModal(true);
    };

    const { deleteExternalQuestionnaire, isPending: isPendingDelete } =
        useDeleteExternalQuestionnaire();
    const deleteQuestionnaire = () => {
        if (!questionnaireId) return;
        deleteExternalQuestionnaire(questionnaireId);
    };

    return (
        <Box display="grid" className="place-items-center">
            <Dropdown>
                <Dropdown.Trigger asChild>{children}</Dropdown.Trigger>
                <Dropdown.Content hasPortal>
                    <Dropdown.Item onClick={openEditModalOnClick}>
                        <Dropdown.ItemIcon name="edit" />
                        <Dropdown.ItemText>
                            {t(keys.edit_questionnaire_modal_title)}
                        </Dropdown.ItemText>
                    </Dropdown.Item>
                    <Dropdown.Item
                        variant="negative"
                        onClick={openDeleteModalOnClick}
                    >
                        <Dropdown.ItemIcon name="trash" />
                        <Dropdown.ItemText>
                            {t(keys.delete_questionnaire_modal_title)}
                        </Dropdown.ItemText>
                    </Dropdown.Item>
                </Dropdown.Content>
            </Dropdown>
            <DeleteQuestionnaireModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                onDeleteApply={deleteQuestionnaire}
                isPendingDelete={isPendingDelete}
            />
            <EditQuestionnaireModal
                questionnaireId={questionnaireId}
                open={openEditModal}
                onOpenChange={setOpenEditModal}
            />
        </Box>
    );
};
