import { QuestionModal } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/OverviewTab/components/QuestionModal";
import { QuestionModalConfirmArg } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/OverviewTab/types";
import BodyHeader from "@app/shared/components/BodyHeader";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { useState, type FC } from "react";
import { QuestionsHeader } from "./components/QuestionsHeader";
import { QuestionTable } from "./components/QuestionTable";
import {
    useCreateTemplateQuestion,
    useSupplierTemplateQuestionnaire,
} from "./data";
import { useSupplierQuestionsPageTranslations } from "./i18n";
import { makeTemplateQuestionCreateInput } from "./services";
import { ImportQuestionsFromFileModal } from "./components/ImportQuestionsFromFileModal";
import { currentUserRoleAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";

export const Questions: FC = () => {
    const { templateQuestionnaire, isPending: isTemplateQuestionnairePending } =
        useSupplierTemplateQuestionnaire();
    const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
    const { createQuestion, isPending: isCreateQuestionPending } =
        useCreateTemplateQuestion();
    const { isBeavrAdmin } = useAtomValue(currentUserRoleAtom);

    const openCreateQuestionModal = () => {
        setIsQuestionModalOpen(true);
    };

    const handleConfirm = (question: QuestionModalConfirmArg) => {
        const input = makeTemplateQuestionCreateInput(question);
        createQuestion(input);
    };

    const { t, keys } = useSupplierQuestionsPageTranslations();
    return (
        <PageContainer>
            <BodyHeader
                title={t(keys.title)}
                subTitle={t(keys.subTitle)}
                action={
                    templateQuestionnaire?.id ? (
                        <FlexRow gap="2">
                            {/* For now, only Beavr admins can import questions from a file.
                                And in the future? Who knows?
                            */}
                            {isBeavrAdmin ? (
                                <ImportQuestionsFromFileModal />
                            ) : undefined}
                            <Button
                                variant="flatPrimary"
                                onClick={openCreateQuestionModal}
                            >
                                {t(keys.addQuestionBtn)}
                            </Button>
                        </FlexRow>
                    ) : undefined
                }
            />
            <FlexCol p="4" gap="4" w="full">
                <QuestionsHeader questionnaireId={templateQuestionnaire?.id} />
                <QuestionTable
                    isLoading={isTemplateQuestionnairePending}
                    questionnaireId={templateQuestionnaire?.id}
                />
            </FlexCol>
            {templateQuestionnaire?.id && (
                <QuestionModal
                    open={isQuestionModalOpen}
                    hasKeepOpenOnConfirmOption
                    setOpen={setIsQuestionModalOpen}
                    loading={isCreateQuestionPending}
                    onConfirm={handleConfirm}
                    questionnaireId={templateQuestionnaire?.id}
                />
            )}
        </PageContainer>
    );
};
