import { FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import {
    type ComboboxInputProps,
    type ComboboxOptionProps,
    type ComboboxOptionsProps,
    type ComboboxProps,
    Combobox as HeadlessCombobox,
    ComboboxButton as HeadlessComboboxButton,
    ComboboxInput as HeadlessComboboxInput,
    ComboboxOption as HeadlessComboboxOption,
    ComboboxOptions as HeadlessComboboxOptions,
} from "@headlessui/react";
import React, { FC, PropsWithChildren, Ref, useContext } from "react";
import { Button, ButtonProps } from "../Button";
import { useDropdownItemStyles } from "../UniversalDropdown";
import { useDropdownContentStyles } from "../UniversalDropdown/styles";

interface TagMultiSelectContextType {
    values?: string[];
}
const TagMultiSelectContext = React.createContext<
    TagMultiSelectContextType | undefined
>(undefined);

type TagMultiSelectComponent = FC<
    PropsWithChildren<ComboboxProps<string, boolean>>
> & {
    Input: typeof TagMultiSelectInput;
    Options: typeof TagMultiSelectOptions;
    Option: typeof TagMultiSelectOption;
    Button: typeof TagMultiSelectButton;
    Trigger: typeof TagMultiSelectTrigger;
    Tag: typeof TagMultiSelectTag;
};

const TagMultiSelectTrigger: FC<
    PropsWithChildren<{ ref?: Ref<HTMLDivElement> }>
> = ({ children, ref, ...props }) => {
    return (
        <FlexRow
            gap="2"
            p="2"
            w="full"
            className="items-center border rounded-lg border-neutral-200 justify-between"
            {...props}
            ref={ref}
        >
            {children}
        </FlexRow>
    );
};

const TagMultiSelectInput: FC<
    PropsWithChildren<ComboboxInputProps & { ref?: Ref<HTMLInputElement> }>
> = ({ className, ref, ...props }) => {
    return (
        <HeadlessComboboxInput
            className={cn(
                "w-min bg-transparent p-0 font-default text-base font-regular leading-[20px] border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500",
                className,
            )}
            {...props}
            ref={ref}
        />
    );
};

const TagMultiSelectOptions: FC<
    PropsWithChildren<
        ComboboxOptionsProps & { className?: string; ref?: Ref<HTMLDivElement> }
    >
> = ({ children, className, ref, ...props }) => {
    const dropDownClassName = useDropdownContentStyles({});

    return (
        <HeadlessComboboxOptions
            {...props}
            ref={ref}
            className={cn(dropDownClassName, className)}
        >
            {children}
        </HeadlessComboboxOptions>
    );
};

const TagMultiSelectOption: FC<
    PropsWithChildren<
        ComboboxOptionProps & {
            value: string;
            ref?: Ref<HTMLDivElement>;
            isSelected?: boolean;
        }
    >
> = ({ value, children, className, ref, ...props }) => {
    const ctx = useContext(TagMultiSelectContext);
    const isSelected = !!ctx?.values?.includes(value);

    const dropDownClassName = useDropdownItemStyles({
        active: isSelected,
    });

    return (
        <HeadlessComboboxOption
            {...props}
            value={value}
            className={cn("bg-primary", dropDownClassName, className)}
            ref={ref}
        >
            {children}
        </HeadlessComboboxOption>
    );
};

const TagMultiSelectButton: FC<
    PropsWithChildren<{ ref?: Ref<HTMLButtonElement>; className?: string }>
> = ({ children, ref, ...props }) => {
    return (
        <HeadlessComboboxButton {...props} ref={ref}>
            {children}
        </HeadlessComboboxButton>
    );
};

const TagMultiSelectTag: FC<
    PropsWithChildren<ButtonProps & { ref?: Ref<HTMLButtonElement> }>
> = ({ children, ref, ...props }) => {
    return (
        <Button {...props} ref={ref}>
            {children}
        </Button>
    );
};

export const TagMultiSelect: TagMultiSelectComponent =
    HeadlessCombobox as unknown as TagMultiSelectComponent;

TagMultiSelect.Input = TagMultiSelectInput;
TagMultiSelect.Options = TagMultiSelectOptions;
TagMultiSelect.Option = TagMultiSelectOption;
TagMultiSelect.Button = TagMultiSelectButton;
TagMultiSelect.Trigger = TagMultiSelectTrigger;
TagMultiSelect.Tag = TagMultiSelectTag;
