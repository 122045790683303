import { addTranslations } from "@app/i18n";
import { useTranslation } from "react-i18next";

const RESOURCE_NAME = "supplier-questions-table";

const keys = addTranslations(RESOURCE_NAME, {
    question: {
        en: "Question",
        fr: "Question",
    },
    questionnaire: {
        en: "Questionnaire",
        fr: "Questionnaire",
    },
    question_not_translated: {
        en: "Question not translated in the selected locale",
        fr: "Question non traduite dans la langue sélectionnée",
    },
    empty_state_title: {
        en: "Get Started with Supplier Evaluations",
        fr: "Commencer avec les évaluations des fournisseurs",
    },
    empty_state_sub_title: {
        en: "Create and manage evaluation questions to ensure your suppliers meet your standards. You haven’t created any questions yet, but it’s easy to get started!",
        fr: "Créez et gérez les questions d'évaluation pour garantir que vos fournisseurs répondent à vos normes. Vous n'avez pas encore créé de questions, mais c'est facile à commencer !",
    },
    empty_state_title_with_questionnaire: {
        en: "No questions found",
        fr: "Aucune question trouvée",
    },
    empty_state_sub_title_with_questionnaire: {
        en: "Create a new question by clicking on the button above",
        fr: "Créez une nouvelle question en cliquant sur le bouton ci-dessus",
    },
    toast_error_update_question: {
        en: "There was an error updating the question",
        fr: "Il y a eu une erreur lors de la mise à jour de la question",
    },
    toast_success_update_question: {
        en: "Question updated successfully",
        fr: "Question mise à jour avec succès",
    },
    delete_question: {
        en: "Delete",
        fr: "Supprimer",
    },
    toast_error_delete_question: {
        en: "There was an error deleting the question",
        fr: "Il y a eu une erreur lors de la suppression de la question",
    },
    toast_success_delete_question: {
        en: "Question deleted successfully",
        fr: "Question supprimée avec succès",
    },
    edit_question: {
        en: "Edit",
        fr: "Modifier",
    },
    delete_question_modal_title: {
        en: "Delete Question",
        fr: "Supprimer la question",
    },
    delete_question_modal_body: {
        en: "Are you sure you want to delete this question? This action cannot be undone. Please note, the question will still appear in any questionnaires that have already been sent.",
        fr: "Êtes-vous sûr de vouloir supprimer cette question ? Cette action ne peut être annulée. Veuillez noter que la question apparaîtra encore dans tous les questionnaires qui ont déjà été envoyés.",
    },
    delete_question_modal_cancel_button: {
        en: "Cancel",
        fr: "Annuler",
    },
    delete_question_modal_confirm_button: {
        en: "Delete",
        fr: "Supprimer",
    },
});

export function useSupplierQuestionsTableTranslations() {
    const { t } = useTranslation(RESOURCE_NAME);
    return { t, keys };
}
