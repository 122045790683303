import { orderSort } from "@app/lib/utils";
import { SubthemeTag } from "@app/shared/components/Theme/SubthemeTag";
import { useTheme } from "@app/store/themeStore";
import { normalizeString } from "@app/utils/string";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Radio } from "@design-system/Inputs/Radio";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { compareBasic, HeaderFilter } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    selectedIndicatorIdAtom,
    selectedSubthemesAtom,
    selectedThemeIdAtom,
} from "./ctx";
import { keys, RESOURCE_NAME } from "./i18n";
import { Indicator } from "./types";

const actionCol: ColumnDef<Indicator> = {
    id: "select",
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[36px] max-w-[36px] border-r-0 gap-3 px-2",
        cellClassName:
            "min-w-[36px] max-w-[36px] group-hover/tablerow:bg-secondary border-r-0 gap-3 pl-4 pr-0",
    },
    header: () => {
        return "";
    },
    cell: ({ row }) => {
        const [selectedIndicatorId, setSelectedIndicatorId] = useAtom(
            selectedIndicatorIdAtom,
        );
        const handleRadioClick = () => {
            if (selectedIndicatorId === row.original.id) {
                setSelectedIndicatorId(undefined);
                return;
            }
            setSelectedIndicatorId(row.original.id);
        };
        return (
            <FlexRow
                alignItems="center"
                justifyContent="center"
                h="full"
                className="cursor-pointer hover:underline"
                onClick={handleRadioClick}
            >
                <Radio checked={selectedIndicatorId === row.original.id} />
            </FlexRow>
        );
    },
};

const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "400px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.indicator);
    },
    cell: ({ row }) => {
        return (
            <FlexRow alignItems="center" justifyContent="start" h="full">
                <Text variant="body-sm">{row.original.name}</Text>
            </FlexRow>
        );
    },
};

const unitCol: ColumnDef<Indicator> = {
    id: "unit",
    meta: {
        colWidth: "120px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.unit);
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm">{row.original.unit?.shortName || ""}</Text>
        );
    },
};

const subthemeCol: ColumnDef<Indicator> = {
    id: "subtheme",
    accessorKey: "subtheme.id",
    filterFn: "arrIncludesSome",
    sortingFn: (rowA, rowB) => {
        const subthemeA = rowA.original.subtheme?.name ?? "";
        const subthemeB = rowB.original.subtheme?.name ?? "";

        return compareBasic(
            normalizeString(subthemeA),
            normalizeString(subthemeB),
        );
    },
    header: () => {
        const [selectedThemeId] = useAtom(selectedThemeIdAtom);
        const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
            selectedSubthemesAtom,
        );
        const theme = useTheme(selectedThemeId);
        const [searchFilter, setSearchFilter] = useState<string>();

        const filteredSubThemes = useMemo(() => {
            return orderSort(theme?.subthemes || []).filter(
                ({ name }) =>
                    !searchFilter ||
                    name.toLowerCase().includes(searchFilter.toLowerCase()),
            );
        }, [theme?.subthemes, searchFilter]);

        const active = !!selectedSubthemesIds?.length;

        const { t } = useTranslation(RESOURCE_NAME);

        return (
            <Dropdown
                selectType="multi"
                value={selectedSubthemesIds?.filter((v) => v != null) || []}
                onValueChange={setSelectedSubthemesIds}
                closeOnValueChange={false}
            >
                <Dropdown.Trigger
                    label={t(keys.subtheme_placeholder)}
                    className="w-full h-full"
                >
                    <HeaderFilter active={active}>
                        {t(keys.subtheme_placeholder, {
                            context: active && "active",
                            count: selectedSubthemesIds?.length,
                        })}
                    </HeaderFilter>
                </Dropdown.Trigger>
                <Dropdown.Content hasPortal>
                    <MultiSelect.Search
                        searchString={searchFilter || ""}
                        setSearchString={setSearchFilter}
                        placeholder={t(keys.searchbar_placeholder)}
                    />
                    {filteredSubThemes
                        ?.filter((st) => st.id != null)
                        .map((st, idx) => (
                            <Dropdown.SelectableItem
                                key={idx}
                                value={st.id || ""}
                            >
                                <Dropdown.Checkbox />
                                <Dropdown.ItemText>
                                    <Ellipsis asChild>
                                        <span>{st.name}</span>
                                    </Ellipsis>
                                </Dropdown.ItemText>
                            </Dropdown.SelectableItem>
                        ))}
                </Dropdown.Content>
            </Dropdown>
        );
    },
    cell: ({ row }) => {
        const subThemeId = row.original.subtheme?.id;

        return (
            <FlexRow className="max-w-fit">
                <SubthemeTag
                    themeId={row.original.subtheme?.theme?.id ?? ""}
                    subthemeId={subThemeId ?? undefined}
                    className="bg-secondary"
                    isTrigger
                    disabled
                />
            </FlexRow>
        );
    },
};

export const useColumns = (): ColumnDef<Indicator>[] => {
    const columns = [actionCol, nameCol, unitCol, subthemeCol];
    return columns;
};
