import { useTheme } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

export const ThemeTag: FC<{
    themeId: string;
    isTrigger?: boolean;
    disabled?: boolean;
    className?: string;
}> = ({ themeId, isTrigger, disabled = false, className }) => {
    const currentTheme = useTheme(themeId);
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexRow
            alignItems="center"
            alignSelf="stretch"
            justifyContent="center"
            className={cn(
                "border border-neutral-150 rounded-xl p-0.5 bg-primary",
                disabled && "bg-secondary",
                disabled && "pointer-events-none cursor-default",
                isTrigger && "cursor-pointer",
                className,
            )}
            style={{
                color: currentTheme?.color,
            }}
        >
            <Icon name="tagCircle" size="sm" />
            <Ellipsis asChild hasTooltip>
                <Text variant="body-sm" color="primary" px="1">
                    {currentTheme
                        ? currentTheme?.name
                        : isTrigger
                          ? t(keys.select_theme)
                          : t(keys.no_theme)}
                </Text>
            </Ellipsis>
        </FlexRow>
    );
};
