import i18next from "@app/i18n";
import { IconName } from "@design-system/Icon";

const enResources = {
    en: "English",
    fr: "French",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    fr: "Français",
    en: "Anglais",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "Locales";

export enum SupportedLocales {
    EN = "en",
    FR = "fr",
}

export const getFlagByLocale = (locale: SupportedLocales): IconName => {
    switch (locale) {
        case SupportedLocales.EN:
            return "uk";
        case SupportedLocales.FR:
            return "fr";
        default:
            throw new Error("Invalid locale");
    }
};


export function isSupportedLocale(
    lang: string,
): lang is SupportedLocales {
    return Object.values(SupportedLocales).includes(lang as SupportedLocales);
}

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
