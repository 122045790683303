import { useSetToast } from "@app/components/Toast";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Tabs } from "@design-system/Tabs";
import { QuestionnaireStatus, ResponseStatus } from "@generated/client/graphql";
import { useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { SendQuestionnaireModal } from "../../../components/SendQuestionnaireModal";
import { activeTabParamArgs, supplierTabs } from "../../ctx";
import {
    useSendQuestionnaire,
    useSendQuestionnaireReminder,
    useSupplierQuestionnaires,
} from "../../data";
import { QuestionnairesTab } from "../QuestionnairesTab";
import { UserTab } from "../UserTab";
import { useSupplierBodyTranslations } from "./i18n";
import { AddUsersModal } from "../AddUsersModal";

export const SupplierBody: FC = () => {
    const [activeTab, setActiveTab] = useQueryParam(
        ...activeTabParamArgs.activeTab,
    );

    const [isSendQuestionnaireModalOpen, setIsSendQuestionnaireModalOpen] =
        useState<boolean>(false);

    const { supplier_id } = useParams();

    const { questionnaires } = useSupplierQuestionnaires(
        supplier_id,
        { status: QuestionnaireStatus.Open },
        {
            take: 1,
        },
    );

    const { sendQuestionnaire } = useSendQuestionnaire();

    const {
        sendQuestionnaireReminder,
        isPending: isSendQuestionnaireReminderPending,
    } = useSendQuestionnaireReminder();

    const { setToastSuccess, setToastError } = useSetToast();

    const lastQuestionnaire = questionnaires?.list[0];

    const { t, keys } = useSupplierBodyTranslations();

    const handleSendQuestionnaireClick = () => {
        setIsSendQuestionnaireModalOpen(true);
    };

    const handleSendModalConfirmClick = (
        selectedQuestionnaireTagId: string,
    ) => {
        if (!supplier_id) return;
        sendQuestionnaire(
            {
                supplierId: supplier_id,
                questionnaireTagId: selectedQuestionnaireTagId,
            },
            {
                onSettled(data, error) {
                    if (
                        error ||
                        data?.sendQuestionnaire?.status !==
                            ResponseStatus.Success
                    ) {
                        setToastError(t(keys.send_questionnaire_error_message));
                        return;
                    }
                    setToastSuccess(t(keys.send_questionnaire_success_message));
                },
            },
        );
    };

    const handleSendReminderClick = () => {
        if (!supplier_id) return;
        sendQuestionnaireReminder(supplier_id, {
            onSettled(data, error) {
                if (
                    error ||
                    data?.sendSupplierQuestionnaireReminder?.status !==
                        ResponseStatus.Success
                ) {
                    setToastError(t(keys.send_reminder_error_message));
                    return;
                }
                setToastSuccess(t(keys.send_reminder_success_message));
            },
        });
    };

    return (
        <FlexCol gap="4">
            <FlexRow justifyContent="between" alignItems="center">
                <Tabs
                    activeTab={activeTab}
                    onActiveTabChange={setActiveTab}
                    size="md"
                >
                    <Tabs.Trigger
                        value={supplierTabs.questionnaires}
                        label={t(keys.questionnaires)}
                    />
                    <Tabs.Trigger
                        value={supplierTabs.users}
                        label={t(keys.users)}
                    />
                </Tabs>
                {activeTab === supplierTabs.questionnaires && (
                    <FlexRow gap="3">
                        <Tooltip>
                            <Tooltip.Trigger asChild>
                                <div>
                                    <Button
                                        disabled={!!lastQuestionnaire}
                                        onClick={handleSendQuestionnaireClick}
                                    >
                                        {t(keys.send_new_questionnaire_button)}
                                    </Button>
                                </div>
                            </Tooltip.Trigger>
                            {lastQuestionnaire && (
                                <Tooltip.Content>
                                    {t(keys.send_new_questionnaire_tooltip)}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                        {lastQuestionnaire && (
                            <Button
                                onClick={handleSendReminderClick}
                                loading={isSendQuestionnaireReminderPending}
                            >
                                {t(keys.send_reminder_button)}
                                <Button.Icon name="bell" />
                            </Button>
                        )}
                        <SendQuestionnaireModal
                            open={isSendQuestionnaireModalOpen}
                            onOpenChange={setIsSendQuestionnaireModalOpen}
                            onConfirm={handleSendModalConfirmClick}
                        />
                    </FlexRow>
                )}
                {activeTab === supplierTabs.users && (
                    <AddUsersModal supplierId={supplier_id} />
                )}
            </FlexRow>
            {activeTab === supplierTabs.questionnaires && <QuestionnairesTab />}
            {activeTab === supplierTabs.users && (
                <UserTab supplierId={supplier_id} />
            )}
        </FlexCol>
    );
};
