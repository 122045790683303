import { Icon, type IconProps } from "@design-system/Icon";
import SearchBar from "@design-system/Inputs/SearchBar";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import React, {
    createContext,
    Fragment,
    HTMLAttributes,
    useContext,
    useState,
    type FC,
    type PropsWithChildren,
    type ReactNode,
    type Ref,
} from "react";
import { useDropdownItemStyles } from "../UniversalDropdown";
import {
    useDropdownContentStyles,
    useDropdownItemIconStyles,
    useDropdownItemTextStyles,
} from "../UniversalDropdown/styles";

type MultiSelectContext = {
    replaceTrigger?: boolean;
    isOpen: boolean;
    onValuesChange: (values: (string | null)[]) => void;
    values: (string | null)[];
};

type MultiSelectComponent = FC<
    PropsWithChildren<
        Omit<MultiSelectContext, "isOpen"> & DropdownPrimitive.DropdownMenuProps
    >
> & {
    Trigger: typeof Trigger;
    Content: typeof MultiSelectContent;
    Search: typeof Search;
    Group: typeof Group;
    ItemIcon: typeof ItemIcon;
    ItemText: typeof ItemText;
    Item: typeof Item;
    Label: typeof Label;
    Sub: typeof Sub;
    SubTrigger: typeof SubTrigger;
    SubContent: typeof SubContent;
};

export const MultiSelectContext = createContext<MultiSelectContext | null>(
    null,
);

interface TriggerProps
    extends HTMLAttributes<HTMLButtonElement>,
        PropsWithChildren {
    ref?: Ref<HTMLButtonElement>;
}

const Trigger: FC<TriggerProps> = ({ ref, ...props }) => {
    const ctx = useContext(MultiSelectContext);
    if (!ctx) throw new Error("Context must not be null");
    const { replaceTrigger, isOpen } = ctx;

    const hideTrigger = replaceTrigger && isOpen;
    const triggerStyleProps = hideTrigger
        ? {
              style: {
                  height: 0,
                  width: 0,
                  padding: 0,
                  border: 0,
                  minHeight: 0,
                  minWidth: 0,
                  display: "block",
                  overflow: "hidden",
              },
          }
        : {};

    const Wrapper = replaceTrigger ? "div" : Fragment;

    return (
        <Wrapper>
            {/**
             * We need to have a trigger because the content position
             * will be calculated based on the trigger position and size.
             * We couldn't find a way for the content to be displayed over
             * the trigger, so we need a non dimentional, but still present,
             * trigger.
             */}
            <DropdownPrimitive.Trigger {...triggerStyleProps} asChild ref={ref}>
                {/**
                 * We need to keep the children elements here because removing
                 * them causes the content to lose its anchor, which results in
                 * a flash or flicker on the screen for a brief moment.
                 */}
                {props.children}
            </DropdownPrimitive.Trigger>
            {/**
             * Nonetheless, we need to make sure that the space previously
             * occupied by the trigger remains occupied (the content in
             * fact is in a portal, it doesn't occupy the space of the
             * trigger). So we still render the trigger's children, but
             * outside it, so it doesn't afct the content position.
             *
             */}

            {hideTrigger && <Box className="invisible">{props.children}</Box>}
        </Wrapper>
    );
};

const Label: FC<{
    value: string | null;
    subItemsValues: (string | null)[];
    children: (props: { isGroupSelected: boolean | "partial" }) => ReactNode;
}> = (props) => {
    const ctx = useContext(MultiSelectContext);
    if (!ctx) throw new Error("Context must not be null");
    const { values, onValuesChange } = ctx;
    const isGroupSelected = props.subItemsValues.every((elt) =>
        values.includes(elt),
    )
        ? true
        : props.subItemsValues.every((elt) => !values.includes(elt))
          ? false
          : "partial";

    const toggleActive = () => {
        if (isGroupSelected === true) {
            onValuesChange(
                values.filter((elt) => !props.subItemsValues.includes(elt)),
            );
            return;
        }

        onValuesChange([...values, ...props.subItemsValues]);
    };

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        toggleActive();
    };
    const className = useDropdownItemStyles({});

    return (
        <div onClick={onClick} className={cn(className)}>
            {props.children({ isGroupSelected })}
        </div>
    );
};

const Group: FC<PropsWithChildren<{ className?: ClassValue }>> = (props) => {
    return (
        <DropdownPrimitive.Group className="cursor-pointer">
            {props.children}
        </DropdownPrimitive.Group>
    );
};

const Sub: FC<PropsWithChildren> = (props) => {
    return <DropdownPrimitive.Sub>{props.children}</DropdownPrimitive.Sub>;
};

const SubTrigger: FC<PropsWithChildren> = (props) => {
    return (
        <DropdownPrimitive.SubTrigger asChild>
            {props.children}
        </DropdownPrimitive.SubTrigger>
    );
};

const SubContent: FC<PropsWithChildren<{ className?: ClassValue }>> = ({
    className,
    children,
    ...props
}) => {
    return (
        <DropdownPrimitive.Portal>
            <DropdownPrimitive.SubContent sideOffset={10}>
                <FlexCol
                    {...props}
                    p="1"
                    elevation="dropdown"
                    br="xl"
                    className={cn(
                        "w-[220px] max-h-[300px] border bg-white overflow-y-auto scrollbar-hide",
                        className,
                    )}
                >
                    {children}
                </FlexCol>
            </DropdownPrimitive.SubContent>
        </DropdownPrimitive.Portal>
    );
};

const Item: FC<{
    value: string | null;
    children: ReactNode | ((props: { isSelected: boolean }) => ReactNode);
    variant?: "negative";
}> = (props) => {
    const ctx = useContext(MultiSelectContext);
    if (!ctx) throw new Error("Context must not null");
    const { values, onValuesChange } = ctx;
    const isSelected = values.includes(props.value);
    const toggleActive = () => {
        if (isSelected) {
            onValuesChange(values.filter((elt) => elt !== props.value));
        } else {
            onValuesChange([...values, props.value]);
        }
    };

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        toggleActive();
    };

    const children =
        typeof props.children === "function"
            ? props.children({ isSelected })
            : props.children;

    const itemClassName = useDropdownItemStyles({
        active: isSelected,
        negative: props.variant === "negative",
    });

    return (
        <DropdownPrimitive.Item
            onClick={onClick}
            className={cn(itemClassName, "cursor-pointer outline-none")}
        >
            {children}
        </DropdownPrimitive.Item>
    );
};

interface ItemTextProps extends TextProps {
    ref?: Ref<HTMLDivElement>;
}

const ItemText: FC<ItemTextProps> = ({
    className: propsClassName,
    ref,
    ...props
}) => {
    const className = useDropdownItemTextStyles(propsClassName);
    return <Text ref={ref} {...props} className={className} />;
};

interface ItemIconProps extends IconProps {
    ref?: Ref<SVGSVGElement>;
}

const ItemIcon: FC<ItemIconProps> = ({
    className: propsClassName,
    ref,
    ...props
}) => {
    const className = useDropdownItemIconStyles(propsClassName);
    return <Icon {...props} className={className} ref={ref} />;
};

const Search: FC<
    PropsWithChildren<{
        searchString: string | undefined;
        setSearchString: (value: string | undefined) => void;
        placeholder?: string;
        className?: ClassValue;
    }>
> = (props) => {
    return <SearchBar {...props} />;
};

const MultiSelectContent: FC<
    PropsWithChildren<{
        avoidCollisions?: boolean;
        className?: ClassValue;
        align?: DropdownPrimitive.DropdownMenuContentProps["align"];
        alignOffset?: DropdownPrimitive.DropdownMenuContentProps["alignOffset"];
        content?: string;
        hasPortal?: boolean;
        side?: DropdownPrimitive.DropdownMenuContentProps["side"];
        sideOffset?: DropdownPrimitive.DropdownMenuContentProps["sideOffset"];
    }>
> = ({
    avoidCollisions,
    align = "start",
    alignOffset = 0,
    hasPortal = true,
    side = "bottom",
    sideOffset = 4,
    children,
    className,
    ...props
}) => {
    const ctx = useContext(MultiSelectContext);
    if (!ctx) throw new Error("Context must not be null");
    const { replaceTrigger } = ctx;
    const Wrapper = hasPortal ? DropdownPrimitive.Portal : Fragment;
    const contentClassName = useDropdownContentStyles();
    return (
        <Wrapper>
            <DropdownPrimitive.Content
                {...props}
                align={replaceTrigger ? "start" : align}
                alignOffset={replaceTrigger ? 0 : alignOffset}
                side={replaceTrigger ? "bottom" : side}
                sideOffset={replaceTrigger ? 0 : sideOffset}
                avoidCollisions={avoidCollisions || false}
            >
                <FlexCol
                    p="1"
                    elevation="dropdown"
                    br="xl"
                    className={cn(contentClassName, className)}
                >
                    {children}
                </FlexCol>
            </DropdownPrimitive.Content>
        </Wrapper>
    );
};

export const MultiSelect: MultiSelectComponent = ({
    children,
    replaceTrigger = false,
    onOpenChange,
    onValuesChange,
    values,
    ...props
}) => {
    const [selectOpen, setSelectOpen] = useState(false);
    const handleOpenChange = (open: boolean) => {
        onOpenChange?.(open);
        setSelectOpen(open);
    };

    return (
        <MultiSelectContext.Provider
            value={{
                replaceTrigger,
                isOpen: selectOpen,
                onValuesChange,
                values,
            }}
        >
            <DropdownPrimitive.Root
                open={selectOpen}
                onOpenChange={handleOpenChange}
                {...props}
            >
                {children}
            </DropdownPrimitive.Root>
        </MultiSelectContext.Provider>
    );
};

MultiSelect.Trigger = Trigger;
MultiSelect.Content = MultiSelectContent;
MultiSelect.Item = Item;
MultiSelect.ItemIcon = ItemIcon;
MultiSelect.ItemText = ItemText;
MultiSelect.Search = Search;
MultiSelect.Label = Label;
MultiSelect.Group = Group;
MultiSelect.Sub = Sub;
MultiSelect.SubTrigger = SubTrigger;
MultiSelect.SubContent = SubContent;
