import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { PropsWithChildren, type FC } from "react";

const Logo: FC<{ imageUrl?: string; letter?: string }> = ({
    imageUrl,
    letter,
}) => {
    return (
        <FlexRow alignItems="center" justifyContent="center" h="full" w="fit">
            <Avatar
                className="w-20 h-20"
                imageUrl={imageUrl}
                letter={letter}
                variant="circle"
            />
        </FlexRow>
    );
};

const LogoSkeleton: FC = () => {
    return (
        <FlexRow alignItems="center" justifyContent="center" h="full" w="fit">
            <Skeleton className="rounded-full" w="20" h="20" />
        </FlexRow>
    );
};

const Title: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text as="h2" color="primary" variant="hero6">
            {children}
        </Text>
    );
};

const TitleSkeleton: FC = () => {
    return <Skeleton className="h-[36px] w-[200px]" />;
};

const Subtitle: FC<PropsWithChildren> = ({ children }) => {
    return <Text color="primary">{children}</Text>;
};

const SubtitleSkeleton: FC = () => {
    return <Skeleton h="6" w="full" />;
};

export const Header: FC<PropsWithChildren> & {
    Logo: typeof Logo;
    Title: typeof Title;
    Subtitle: typeof Subtitle;
    LogoSkeleton: typeof LogoSkeleton;
    TitleSkeleton: typeof TitleSkeleton;
    SubtitleSkeleton: typeof SubtitleSkeleton;
} = ({ children }) => {
    return (
        <FlexRow
            className="bg-secondary border border-tertiary"
            p="6"
            gap="6"
            br="lg"
        >
            {children}
        </FlexRow>
    );
};

Header.Logo = Logo;
Header.Title = Title;
Header.Subtitle = Subtitle;
Header.LogoSkeleton = LogoSkeleton;
Header.TitleSkeleton = TitleSkeleton;
Header.SubtitleSkeleton = SubtitleSkeleton;
