import { DocumentCitationTooltip } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/components/QuestionsTab/components/DocumentCitationTooltip";
import { makeIndicatorLink } from "@app/screens/Global/AskAIModal/services";
import { ExtensionTag } from "@app/shared/components/ExtensionTag";
import { getFileFormatCategoryFromMimeType } from "@app/usecases/VersionUseCases/utils";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    DocumentCitation,
    DocumentsQuery,
    Indicator,
} from "@generated/client/graphql";
import { useParams } from "react-router-dom";
import { useReferenceSectionTranslations } from "./i18n";

type Document = NonNullable<DocumentsQuery["documents"]>[number];

type DocumentReferenceProps = {
    document: Document;
    documentCitations: DocumentCitation[];
    isPending: boolean;
};

const DocumentReference = ({
    document,
    documentCitations,
    isPending,
}: DocumentReferenceProps) => {
    const url = document.lastApprovedVersion?.finalFile?.signedUrl;

    const handleDocumentDownload = () => {
        if (!url) return;
        window.open(url, "_blank");
    };

    return (
        <FlexRow
            alignItems="center"
            justifyContent="between"
            alignSelf="start"
            w="full"
        >
            <ExtensionTag
                className="mr-1"
                name={getFileFormatCategoryFromMimeType(
                    document?.lastApprovedVersion?.finalFile?.mimeType ||
                        "application/pdf",
                )}
            />
            <DocumentCitationTooltip
                document={document}
                documentCitation={documentCitations?.find(
                    (citation) => citation.documentId === document.id,
                )}
            />
            <Button
                size="sm"
                variant="text"
                onClick={handleDocumentDownload}
                loading={isPending}
            >
                <Icon className="cursor-pointer" name="download" size="sm" />
            </Button>
        </FlexRow>
    );
};

type IndicatorReferenceProps = {
    indicator: Pick<Indicator, "id" | "name">;
};

const IndicatorReference = ({ indicator }: IndicatorReferenceProps) => {
    const { org_uname } = useParams();
    const indicatorUrl = makeIndicatorLink(indicator?.name, org_uname);

    return (
        <FlexRow
            alignItems="center"
            justifyContent="between"
            alignSelf="start"
            w="full"
        >
            <a
                href={indicatorUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-beavrGreen"
            >
                <FlexRow gap="2" alignItems="center">
                    <Icon name="ruler" size="sm" />
                    <Text variant="body-bold">{indicator?.name}</Text>
                </FlexRow>
            </a>
        </FlexRow>
    );
};

type ReferencesSectionProps = {
    suggestedDocuments: Document[];
    documentCitations: DocumentCitation[];
    suggestedIndicators: Pick<Indicator, "id" | "name">[];
    isPending: boolean;
};

export const ReferencesSection = ({
    suggestedDocuments,
    documentCitations,
    suggestedIndicators,
    isPending,
}: ReferencesSectionProps) => {
    const { t, keys } = useReferenceSectionTranslations();
    return (
        <FlexRow className="p-2">
            <FlexCol>
                <Text
                    variant="body-sm-bold"
                    color="secondary"
                    className="uppercase"
                >
                    {t(keys.references)}
                </Text>
                {suggestedDocuments?.map((document) => (
                    <DocumentReference
                        key={document.id}
                        document={document}
                        documentCitations={documentCitations}
                        isPending={isPending}
                    />
                ))}
                {suggestedIndicators?.map((indicator) => (
                    <IndicatorReference
                        key={indicator.id}
                        indicator={indicator}
                    />
                ))}
            </FlexCol>
        </FlexRow>
    );
};
