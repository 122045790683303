import { Icon } from "@design-system/Icon";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";
interface SelectDropdownProps<T extends { id: string; name: string }> {
    items: T[];
    selectedValue: string;
    setSelectedValue: (value: string) => void;
    placeholder?: string;
    className?: string;
}

export const SelectDropdown: FC<SelectDropdownProps<any>> = ({
    items,
    selectedValue,
    setSelectedValue,
    placeholder = "Sélectionner une option",
    className,
}) => {
    if (!items) return null;

    const handleChange = (value: string[]) => {
        setSelectedValue(value[0]);
    };

    return (
        <Dropdown
            value={[selectedValue]}
            onValueChange={handleChange}
            selectType="single"
        >
            <Dropdown.Trigger className="w-fit min-w-40">
                <FlexRow
                    p="0.5"
                    br="xl"
                    className={cn(
                        "border border-neutral-200 items-center px-2 justify-between",
                        className,
                    )}
                >
                    <Text
                        variant="body-sm"
                        color="primary"
                        className=" p-2 text-left"
                    >
                        {items.find((item) => item.id === selectedValue)
                            ?.name || placeholder}
                    </Text>
                    <Icon name="angleDown" className="w-4 h-4 text-secondary" />
                </FlexRow>
            </Dropdown.Trigger>
            <Dropdown.Content align="start">
                {items.map((item) => (
                    <Dropdown.SelectableItem
                        key={item.id}
                        value={item.id || ""}
                    >
                        <Dropdown.SelectedIndicator />
                        <Dropdown.ItemText>{item.name}</Dropdown.ItemText>
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
